import Vue from 'vue';
import { TextField } from '@eloomi/components';

Vue.directive('elm-model', {
    bind(element, binding, vnode) {
        const inputHandler = (event: Event) => {
            const fieldName = binding.expression;

            if (vnode.context && fieldName) {
                vnode.context.$data[fieldName] = (event.target as TextField).value;
            }
        };

        if (binding.value) {
            element.setAttribute('value', binding.value);
        } else {
            element.removeAttribute('value');
        }

        element.addEventListener('elm-input', inputHandler);
    },
});
