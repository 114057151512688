import { RouteConfig } from 'vue-router';

import { mobileIsUnsupportedFlag } from '@/common/services/mobile-fallback';
import { guards } from '@/main/multipleGuardsHandler';
import { Permissions } from '@/permissions';
import PlanFeaturesList from '@/plan-features/plan-features-list';
import { checkIfRouteAvailableByPlanFeatures } from '@/plan-features/routes';

const OPEN_SESAME_PAGE = () => import('./open-sesame/pages/OpenSesamePage.vue');
const API_INTEGRATIONS_PAGE = () => import('./pages/ApiIntegrationsPage.vue'); // TODO: Refactor to deep module: https://eloomi.atlassian.net/browse/BLUE-1121
const NATIVE_INTEGRATIONS_PAGE = () => import('./pages/NativeIntegrationsPage.vue'); // TODO: Refactor to deep module: https://eloomi.atlassian.net/browse/BLUE-1121
const SINGLE_SIGN_ON_PAGE = () => import('./pages/SsoRootPage.vue'); // TODO: Can be put directly into existing deep module: https://eloomi.atlassian.net/browse/BLUE-1121

export const enum AdminApiRoutes {
    OpenSesame = 'open-sesame',
    NativeIntegrations = 'native-integrations',
    ApiIntegrations = 'api-integrations',
    SingleSignOn = 'single-sign-on',
}

const routes: RouteConfig[] = [
    {
        path: `${AdminApiRoutes.OpenSesame}`,
        name: AdminApiRoutes.OpenSesame,
        meta: {
            title: 'OpenSesame',
            ...mobileIsUnsupportedFlag,
            showNavigationSidebar: true,
        },
        component: OPEN_SESAME_PAGE,
        beforeEnter: guards([checkIfRouteAvailableByPlanFeatures(PlanFeaturesList.OPEN_SESAME)]),
    },
    {
        path: `${AdminApiRoutes.NativeIntegrations}`,
        name: AdminApiRoutes.NativeIntegrations,
        meta: {
            title: 'Native integrations',
            ...mobileIsUnsupportedFlag,
            showNavigationSidebar: true,
            requiredPermissions: [Permissions.AdminNativeIntegrations],
        },
        component: NATIVE_INTEGRATIONS_PAGE,
        beforeEnter: guards([checkIfRouteAvailableByPlanFeatures(PlanFeaturesList.NATIVE_INTEGRATION)]),
    },
    {
        path: `${AdminApiRoutes.ApiIntegrations}`,
        name: AdminApiRoutes.ApiIntegrations,
        meta: {
            title: 'API integrations',
            ...mobileIsUnsupportedFlag,
            showNavigationSidebar: true,
        },
        component: API_INTEGRATIONS_PAGE,
    },
    {
        path: `${AdminApiRoutes.SingleSignOn}`,
        name: AdminApiRoutes.SingleSignOn,
        meta: {
            title: 'Single sign-on (SSO)',
            ...mobileIsUnsupportedFlag,
            showNavigationSidebar: true,
            requiredPermissions: [Permissions.AdminApi],
        },
        component: SINGLE_SIGN_ON_PAGE,
    },
];

export default routes;
