import { inject, injectable } from 'tsyringe';

import { AuthenticationServiceTokens as Tokens } from '../di-tokens';
import type { JWT, JWTAuthenticationService, VueRouterRedirectionService } from '../services';
import { SessionContainer } from '../session/session-container';

/**
 * User wants to sign in into the application for the first time with temporary password while changing it.
 */
@injectable()
export class LoginWithTemporaryPasswordUseCase {
    public constructor(
        @inject(Tokens.AuthenticationService)
        private authService: JWTAuthenticationService,

        @inject(Tokens.SessionContainer)
        private sessionContainer: SessionContainer<JWT>,

        @inject(Tokens.RedirectService)
        private redirectService: VueRouterRedirectionService
    ) {}

    public async execute(accountId: string, oldPassword: string, password: string) {
        const authData = await this.authService.resetTemporaryPassword(accountId, oldPassword, password);
        this.sessionContainer.startSession(authData.data.token);

        await (this.redirectService.hasRedirectParamInQuery()
            ? this.redirectService.redirectToPagePassedInQuery()
            : this.redirectService.redirectToDefaultPage());
    }
}
