import { inject, injectable } from 'tsyringe';
import { Route } from 'vue-router';

import { getLoginSsoErrorStatus } from '@/authentication/services/login-sso-handler';
import { getDevicePlatform, Platform } from '@/common/services/get-device-platform';
import { ServiceTokens as BaseServiceTokens } from '@/di/tokens';

import { AuthenticationServiceTokens as Tokens } from '../di-tokens';
import type { JWT, PlatformResolver, VueRouterRedirectionService } from '../services';
import { SessionContainer } from '../session/session-container';

export interface SSOOrigin {
    devicePlatform: Platform;
}

/**
 * User wants to sign in into the application by using sso.
 */
@injectable()
export class LoginSsoUseCase {
    public constructor(
        @inject(BaseServiceTokens.PlatformResolutionService)
        private readonly platformService: PlatformResolver,

        @inject(Tokens.RedirectService)
        private readonly redirectService: VueRouterRedirectionService,

        @inject(Tokens.SessionContainer)
        private readonly sessionContainer: SessionContainer<JWT>
    ) {}

    public async logIn() {
        const devicePlatform = getDevicePlatform();
        try {
            const { id } = await this.platformService.getPlatformData(true);
            this.redirectService.redirectToLoginSsoPage(id, { devicePlatform });
        } finally {
            if (devicePlatform === Platform.Web) {
                this.platformService.cleanCache(true);
                this.sessionContainer.stopAllSessions();
            }
        }
    }

    public isSsoEnabled() {
        return this.platformService.isSsoLoginEnabled();
    }

    public isSsoOnlyLoginMode() {
        return this.platformService.isSsoOnlyLoginMode();
    }

    public allowSsoAutomaticLogin(route?: Route) {
        const errorStatusCode = getLoginSsoErrorStatus(route?.query);
        return (
            this.isSsoEnabled() &&
            this.isSsoOnlyLoginMode() &&
            this.platformService.isSsoAutomaticLoginEnabled() &&
            !errorStatusCode
        );
    }
}
