<template>
    <div class="widget-active-switcher">
        <label :class="additionalClasses" class="switch">
            <input v-model="isChecked" type="checkbox" :disabled="disabled" />
            <span class="slider"></span>
        </label>
    </div>
</template>

<script lang="ts">
    import { defineComponent, PropType } from 'vue';
    import { Themes } from '@/common/classes';

    export default defineComponent({
        name: 'CoreToggle',
        props: {
            color: {
                type: String as PropType<Themes>,
                default: 'primary',
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            value: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            additionalClasses() {
                return {
                    disabled: this.disabled,
                    [`color-${this.color}`]: Boolean(this.color),
                };
            },

            isChecked: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.toggle(value);
                },
            },
        },

        methods: {
            toggle(value) {
                this.$emit('input', value);
            },
        },
    });
</script>
<style lang="less" scoped>
    .widget-active-switcher {
        .switch {
            position: relative;
            display: inline-block;
            width: 48px;
            height: 28px;
            background-color: @info-color-40;
            border-radius: @border-radius-16;
            outline: none;
            box-shadow: inset 0 0 5px @bright-color-20;
            cursor: pointer;
            transition: @generic-transition;

            .slider-color(@color) {
                input:checked + .slider {
                    background-color: @color;
                }
            }

            &.color-primary {
                .slider-color(@primary-color);
            }

            &.color-success {
                .slider-color(@success-color);
            }

            &.color-warning {
                .slider-color(@warning-color);
            }

            &.color-danger {
                .slider-color(@danger-color);
            }

            &.color-info {
                .slider-color(@info-color);
            }

            &.color-bright {
                .slider-color(@bright-color);
            }

            &.color-branding {
                .slider-color(@branding-color);
            }

            &.color-dark {
                .slider-color(@dark-color);
            }
        }

        .switch input {
            width: 0;
            height: 0;
            opacity: 0;
        }

        .slider {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: @info-color-40;
            border-radius: @border-radius-16;
            transition: @generic-transition;
        }

        .slider::before {
            position: absolute;
            top: 2px;
            left: 2px;
            width: 24px;
            height: 24px;
            background-color: @bright-color;
            border-radius: @border-round;
            transition: @generic-transition;
            content: '';
        }

        input:checked + .slider::before {
            transform: translateX(20px);
        }

        input:not(:checked, [disabled]):hover {
            background-color: @info-color-60;
        }

        .switch.disabled {
            background-color: @info-color-40;

            .slider::before {
                background-color: @info-color-60;
            }
        }
    }
</style>
