import { NavigationGuard, NavigationGuardNext, Route } from 'vue-router';

import { PredefinedAccessUserRoles } from '@/admin/users/providers/user-access-roles';
import { handlePageNotFound } from '@/common/services/transition-common';
import userDetailsStore from '@/common/store/user-details/store';
import { getBaseStore } from '@/main/get-base-store-router';

export const checkIfRouteAvailableByRole = (): NavigationGuard => {
    return async (_to: Route, _from: Route, next: NavigationGuardNext) => {
        const authUserDetailsModule = userDetailsStore.context(getBaseStore());
        const isAuthUserLoaded = authUserDetailsModule.state.authUserProfile;

        if (!isAuthUserLoaded) {
            await authUserDetailsModule.actions.loadUserProfile();
        }

        const isAuthDetailsAvailable = authUserDetailsModule.getters.userDetails();

        if (isAuthDetailsAvailable?.infiniteRoleKey === PredefinedAccessUserRoles.USER) {
            handlePageNotFound(next);
        } else {
            next();
        }
    };
};
