import type { UserActivationApiFactory, UserForgotPasswordApiFactory } from '@eloomi/eloomi-users-external-client/1.0';
import type { AxiosPromise } from 'axios';
import type { Location, RouteMeta, RouteRecord } from 'vue-router';

export type JWT = string & { __brand: 'JWT' };

export type SSOOrigin = 'web' | 'ios' | 'android';

// This is kind of a functional constructor of a type above (to guarantee invariant)
export function makeSSOOrigin(origin?: unknown): SSOOrigin {
    if (typeof origin === 'string' && ['ios', 'android', 'web'].includes(origin)) {
        return origin as SSOOrigin;
    }

    return 'web';
}

export interface AuthRouteMeta extends RouteMeta {
    requiresAuth?: boolean;
    onlyUnauthorized?: boolean;
}

export interface RouteRecordWithAuthMeta extends RouteRecord {
    meta: AuthRouteMeta;
}

export type LocationQuery = Location['query'];

export type queryStringWithRedirectParam = LocationQuery & {
    redirect?: string;
};

export type queryStringWithTokenParam = LocationQuery & {
    token?: string;
};

export enum ThirdPartyServiceType {
    FULL_STORY = 'FullStory',
    SEGMENT = 'Segment',
    INTERCOM = 'Intercom',
    TWENTY_THREE = 'TwentyThree',
}

export interface PlatformData {
    id: number;
    name: string | null;
    sso_enabled: boolean;
    sso_only_login: boolean;
    third_party_services: {
        FullStory?: boolean;
        Segment?: boolean;
        Intercom?: boolean;
        TwentyThree?: boolean;
    } | null;
    is_internal?: boolean;
    active: boolean;
}

export interface PlatformResolver {
    getPlatformData: (ignoreCache?: boolean) => Promise<PlatformData>;
    getPlatformParentDomain(): string;
    isThirdPartyServiceIntegrationEnabled(serviceType: ThirdPartyServiceType): boolean;
    isSsoLoginEnabled(): boolean;
    isSsoOnlyLoginMode(): boolean;
    isSsoAutomaticLoginEnabled(): boolean;
    forceSetPlatformDomain(subDomain: string): Promise<unknown>;
    resolvePlatform(): Promise<{ isPlatformFound: boolean; isPlatformActive: boolean }>;
    cleanCache(ssoLogin?: boolean): void;
    forceSsoAutomaticLoginEnabled(enable: boolean): void;
}

export interface SessionApiClient {
    getToken(options?: unknown): AxiosPromise<string>;
    logout(options?: unknown): AxiosPromise<void>;
}

export type ForgotPasswordApiClient = ReturnType<typeof UserForgotPasswordApiFactory>;
export type UserActivationApiClient = ReturnType<typeof UserActivationApiFactory>;
