<template>
    <div class="panel" :style="style">
        <header class="panel__header">
            <p class="panel__title">
                {{ panelTitle }}
            </p>
            <elm-button
                :disabled="newNotificationsCount === 0"
                color="secondary"
                size="small"
                @click="markAllNotificationsAsRead"
            >
                {{ trans('spa.notification.panel.mark-all-as-read') }}
            </elm-button>
        </header>
        <div class="panel__content">
            <div class="panel__notifications">
                <slot />
                <div v-observe-visibility="triggerVisibilityChanged" class="panel__trigger" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Mixins } from 'vue-property-decorator';
    import { ObserveVisibility } from 'vue-observe-visibility';
    import { TranslationMixin } from '@/common/mixins';
    import { NotificationsMappedStore } from '../store';

    @Component({
        components: {},
        directives: {
            ObserveVisibility,
        },
    })
    export default class NotificationPanel extends Mixins(TranslationMixin, NotificationsMappedStore) {
        @Prop({ type: String }) title?: string;
        @Prop({ type: String, default: '640px' }) height?: string;

        get style() {
            return { '--panel-height': this.height };
        }

        get panelTitle() {
            return this.title ?? this.trans('spa.notification.panel.title');
        }

        triggerVisibilityChanged(isVisible) {
            if (isVisible) {
                this.$emit('endReached');
            }
        }
    }
</script>

<style lang="less" scoped>
    .panel {
        display: flex;
        flex-direction: column;
        max-height: var(--panel-height);
        background-color: @bright-color;
        border-radius: @border-radius-8;
        box-shadow: 0 2px 40px 0 @dark-color-8;
        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 80px;
            padding: @spacing-26 @spacing-24;
            border-bottom: 1px solid @info-color-8;
        }
        &__content {
            display: flex;
            flex-direction: column;
            padding: 8px;
            overflow-y: hidden;
        }
        &__notifications {
            overflow-y: auto;
            overscroll-behavior: contain;
            .styled-scrollbar(4px);
        }
        &__title {
            .text-paragraph;
            .font-extrabold;
            .dark-color;

            margin: 0;
            font-size: 20px;
        }
        &__trigger {
            width: 100%;
            height: 1px;
        }
    }
</style>
