var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.component,{directives:[{name:"tippy",rawName:"v-tippy",value:({
        content: _vm.tooltip,
        trigger: _vm.tooltip ? 'mouseenter' : 'manual',
    }),expression:"{\n        content: tooltip,\n        trigger: tooltip ? 'mouseenter' : 'manual',\n    }"}],tag:"component",staticClass:"selector-item",class:{
        'is-selected': _vm.isSelected,
        'is-disabled': _vm.isDisabled,
        'with-checkbox': _vm.withCheckbox,
        'is-auto-height': _vm.isAutoHeight,
        danger: _vm.intention === 'danger',
    },style:(_vm.itemHeight),attrs:{"data-testid":"dropdown-item","to":_vm.link},on:{"click":_vm.onClick}},[(_vm.withCheckbox)?_c('CoreCheckbox',{staticClass:"selector-item-checkbox",attrs:{"round":_vm.roundCheckbox,"value":_vm.isSelected}}):_vm._e(),(!!_vm.$slots.icon)?_c('div',{staticClass:"selector-item-icon selector-item-icon-wrapper"},[_vm._t("icon")],2):_vm._e(),(_vm.withAvatar)?_c('CoreShapedImage',{staticClass:"selector-item-avatar",attrs:{"shape":"circle","size":24,"image":_vm.avatar,"altText":_vm.title}}):_vm._e(),_c('div',{staticClass:"selector-item-text"},[_vm._t("content",function(){return [_c('span',{attrs:{"title":_vm.title},domProps:{"innerHTML":_vm._s(_vm.$options.filters !== undefined && _vm.$options.filters.highlight(_vm.title, _vm.search, _vm.highlightColor))}})]})],2),(_vm.label)?_c('CoreCardLabel',{staticClass:"label",attrs:{"color":_vm.labelActive ? 'primary-8' : 'info-8',"value":_vm.label}}):_vm._e(),(_vm.withButton)?_c('span',{staticClass:"selector-item-button",on:{"click":_vm.onButtonClick}},[_c('elm-arrows-arrow-right-bold-icon',{attrs:{"size":"10"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }