import {
    CustomFieldBaseRequest,
    CustomFieldMapItem,
    CustomFieldsAdminApiFactory,
    UserSchemaApiFactory,
    UserSchemaFieldResponse,
} from '@eloomi/eloomi-users-persona-client/1.0';

import { authorizeClient } from '@/api/authorize-client';
import { DataRequestOptions } from '@/common/components/table';
import { FluentFilterCollection, Operator } from '@/common/services';

const client = authorizeClient(CustomFieldsAdminApiFactory);
const customFieldsAdminClient = authorizeClient(CustomFieldsAdminApiFactory);
const userSchemaClient = authorizeClient(UserSchemaApiFactory);

export function getCustomFields(options: DataRequestOptions) {
    const builder = new FluentFilterCollection();

    builder.where('schema').filter(Operator.IsEqualTo, 'custom');
    builder.where('is_hidden').filter(Operator.IsEqualTo, 'false');

    if (options.searchQuery) {
        builder.where('name').filter(Operator.Contains, options.searchQuery);
    }
    const filterStr = builder.build();

    return userSchemaClient
        .getUserSchema(filterStr, undefined, options.page, options.pageSize)
        .then(({ data }) => data.fields as UserSchemaFieldResponse[] | null);
}

export function getExtendedCustomFields() {
    return userSchemaClient.getUserSchema().then(({ data }) => data.fields as UserSchemaFieldResponse[] | null);
}

export function getCustomFieldsLite() {
    return client.getCustomFieldsLite().then(({ data }) => data);
}

export function getCustomFieldById(id: number) {
    return client.getCustomFieldById(id);
}

export function createCustomField(customField: CustomFieldBaseRequest) {
    return client.createCustomField(customField);
}

export function updateCustomField(id: number, customField: CustomFieldBaseRequest) {
    return client.updateCustomField(id, customField);
}

export function deleteCustomField(id: number) {
    return client.deleteCustomField(id);
}

export async function updateCustomUsersFields(fields?: CustomFieldMapItem[]) {
    return customFieldsAdminClient.mapCustomFields(fields);
}

export function updateCustomFieldAuthor(customFieldId: number, userId: number) {
    return customFieldsAdminClient.updateCustomFieldsAuthor(customFieldId, { author_id: userId });
}
