import { RouteConfig } from 'vue-router';

import { mobileIsUnsupportedFlag } from '@/common/services/mobile-fallback';
import { checkIfRouteAvailableByPlanFeatures } from '@/plan-features/routes';
import PlanFeaturesList from '@/plan-features/plan-features-list';
import { Permissions } from '@/permissions';

const PLAYLIST_BUILDER_PAGE = () => import('./pages/PlaylistBuilderPage.vue');
const PLAYLISTS_OVERVIEW_PAGE = () => import('./pages/PlaylistsOverviewPage.vue');

export const enum AdminPlaylistsRoutes {
    PLAYLIST_BUILDER_CREATE = 'playlist-builder-create',
    PLAYLIST_BUILDER = 'playlist-builder',
    PLAYLISTS_OVERVIEW = 'playlists-overview',
}

const routes: RouteConfig[] = [
    {
        path: 'playlists',
        name: AdminPlaylistsRoutes.PLAYLISTS_OVERVIEW,
        meta: {
            title: 'Playlists Overview',
            ...mobileIsUnsupportedFlag,
            showNavigationSidebar: true,
            requiredPermissions: [Permissions.AdminPlaylists],
        },
        component: PLAYLISTS_OVERVIEW_PAGE,
        beforeEnter: checkIfRouteAvailableByPlanFeatures(PlanFeaturesList.PLAYLISTS),
    },
    {
        path: 'playlists/create',
        name: AdminPlaylistsRoutes.PLAYLIST_BUILDER_CREATE,
        meta: {
            title: 'Create playlist',
            ...mobileIsUnsupportedFlag,
            defaultBackRoute: AdminPlaylistsRoutes.PLAYLISTS_OVERVIEW,
            requiredPermissions: [Permissions.AdminPlaylists],
        },
        component: PLAYLIST_BUILDER_PAGE,
        beforeEnter: checkIfRouteAvailableByPlanFeatures(PlanFeaturesList.PLAYLISTS),
    },
    {
        path: 'playlists/:id(\\d+)/edit',
        name: AdminPlaylistsRoutes.PLAYLIST_BUILDER,
        meta: {
            title: 'Edit playlist',
            ...mobileIsUnsupportedFlag,
            defaultBackRoute: AdminPlaylistsRoutes.PLAYLISTS_OVERVIEW,
            requiredPermissions: [Permissions.AdminPlaylists],
        },
        component: PLAYLIST_BUILDER_PAGE,
        beforeEnter: checkIfRouteAvailableByPlanFeatures(PlanFeaturesList.PLAYLISTS),
    },
];

export default routes;
