import { AxiosInstance, AxiosRequestConfig } from 'axios';

import { SessionContainer } from '../session/session-container';
import { JWT } from './interfaces';

export class AxiosSessionInjector {
    constructor(private readonly httpClient: AxiosInstance, private readonly sessionContainer: SessionContainer<JWT>) {}
    private requestInterceptorId?: number;

    private readonly sessionInjector = async (config: AxiosRequestConfig) => {
        const jwt = await this.getToken();

        return {
            ...config,
            headers: {
                ...config.headers,
                Authorization: `Bearer ${jwt}`,
            },
        };
    };

    public install() {
        if (this.requestInterceptorId !== undefined) {
            return;
        }
        this.requestInterceptorId = this.httpClient.interceptors.request.use(this.sessionInjector);
    }

    public uninstall() {
        if (this.requestInterceptorId === undefined) {
            return;
        }
        this.httpClient.interceptors.request.eject(this.requestInterceptorId);
        this.requestInterceptorId = undefined;
    }

    private getToken(): JWT {
        return this.sessionContainer.getData();
    }
}
