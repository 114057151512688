import { Route, RouteConfig } from 'vue-router';

import { redirectIfGuard } from '@/common/services/redirectIf.guard';
import MobileModeStoreModule from '@/common/store/mobile-mode';
import { getBaseStore } from '@/main/get-base-store-router';
import { guards } from '@/main/multipleGuardsHandler';
import { Permissions } from '@/permissions';
import PlanFeaturesList from '@/plan-features/plan-features-list';
import { checkIfRouteAvailableByPlanFeatures } from '@/plan-features/routes';

export const enum Paths {
    REPORTING = 'reporting',
    COURSE = 'course',
    USER = 'user',
    REPORT = 'report',
    ADMIN_REPORT = 'admin-report',
    ALL_COURSES = 'courses',
    ALL_USERS = 'users',
    SEGMENTS = 'segments',
    GROUP = 'group',
    SEGMENT_OVERVIEW = 'segment-overview',
    TOPIC = 'topic',
    MOBILE = 'mobile',
    SKILLS = 'skills',
    EVENTS_REPORT = 'events',
    EVENT_REPORT = 'event',
    PLAYLIST_REPORT = 'playlist',
    SESSION_REPORT = 'session',
    PLAYLISTS_REPORT = 'playlists',
}

export enum Names {
    ROOT_REPORT = 'root-report',
    COURSE_REPORT = 'course-report',
    QUIZ_REPORT = 'quiz-report',
    USER_REPORT = 'user-report',
    MY_REPORT = 'my-report',
    ROOT_ADMIN_REPORT = 'admin-report-root',
    MY_ADMIN_REPORT = 'my-admin-report',
    USERS_REPORT = 'admin-users-report',
    COURSES_REPORT = 'admin-courses-report',
    SEGMENT_GROUP_REPORT = 'segment-group-report',
    SEGMENT_OVERVIEW = 'segment-overview',
    SEGMENT_TOPIC_REPORT = 'admin-topic-report',
    GROUPS_REPORT = 'groups-report',
    GROUP_REPORT = 'group-report',
    MY_GROUP_REPORT = 'my-group-report',
    MOBILE_ROOT = 'mobile-reporting',
    REPORT_MANAGER_MOBILE_ROOT = 'report-manager-mobile-reporting',
    SKILLS_REPORT = 'skills-report',
    SKILL_REPORT = 'skill-report',
    EVENTS_REPORT = 'events-report',
    EVENT_REPORT = 'event-report',
    PLAYLIST_REPORT = 'playlist-report',
    SESSION_REPORT = 'session-report',
    PLAYLISTS_REPORT = 'playlists-report',
    UPLOAD_QUESTION_DOWNLOAD_FILE = 'upload-question-download-file',
}

const COURSE_REPORT_PAGE = () => import('./courses/courses/pages/CourseReportPage.vue');
const QUIZ_REPORT_PAGE = () => import('./courses/quizes/pages/QuizReportPage.vue');
const USER_REPORT_PAGE = () => import('@/reporting/users/pages/UserReportPage.vue');
const MY_REPORT_PAGE = () => import('@/reporting/users/pages/MyReportPage.vue');
const ALL_COURSES_REPORT_PAGE = () => import('./courses/courses/pages/CoursesReportPage.vue');
const ALL_USERS_REPORT_PAGE = () => import('./users/pages/AllUsersReportPage.vue');
const SEGMENT_GROUP_REPORT_PAGE = () => import('./segments/pages/SegmentGroupReportPage.vue');
const SEGMENT_OVERVIEW_PAGE = () => import('./segments/pages/SegmentOverviewPage.vue');
const SEGMENT_TOPIC_REPORT_PAGE = () => import('./segments/pages/SegmentTopicReportPage.vue');
const GROUP_REPORT_PAGE = () => import('./groups/pages/GroupReportPage.vue');
const ADMIN_ROOT_PAGE = () => import('@/admin/pages/AdminRootPage.vue');
const MOBILE_ROOT_PAGE = () => import('@/reporting/common/pages/ReportingRootPage.vue');
const SKILLS_REPORT_PAGE = () => import('@/reporting/skills/pages/SkillsReportPage.vue');
const SKILL_REPORT_PAGE = () => import('@/reporting/skills/pages/SkillReportPage.vue');
const EVENTS_REPORT_PAGE = () => import('./events/events/pages/EventsReportPage.vue');
const EVENT_REPORT_PAGE = () => import('@/reporting/events/events/pages/EventReportPage.vue');
const SESSION_REPORT_PAGE = () => import('@/reporting/events/sessions/pages/SessionReportPage.vue');
const QUIZ_FILE_REDIRECTION_PAGE = () => import('@/reporting/courses/quizes/pages/QuizFileRedirectionPage.vue');
const PLAYLISTS_REPORT_PAGE = () => import('@/reporting/playlists/pages/PlaylistsReportPage.vue');
const PLAYLIST_REPORT_PAGE = () => import('@/reporting/playlists/pages/PlaylistReportPage.vue');

const segmentReportingGuard = guards([
    checkIfRouteAvailableByPlanFeatures(PlanFeaturesList.SEGMENT_REPORTING),
    checkIfRouteAvailableByPlanFeatures(PlanFeaturesList.REPORTING),
]);

const routes: RouteConfig[] = [
    {
        path: `${Paths.REPORT}`,
        name: Names.ROOT_REPORT,
        meta: {
            title: 'Report',
            showNavigationSidebar: true,
            leftSidebarIsCompact: true,
        },
        beforeEnter: checkIfRouteAvailableByPlanFeatures(PlanFeaturesList.REPORTING),
        children: [
            {
                path: ``,
                name: Names.MY_REPORT,
                meta: {
                    title: 'Report',
                    showNavigationSidebar: true,
                    leftSidebarIsCompact: true,
                    titleTranslationKey: 'spa.navigation.sections.reports.my-learning',
                },
                component: MY_REPORT_PAGE,
            },
            {
                path: `${Paths.GROUP}`,
                name: Names.MY_GROUP_REPORT,
                meta: {
                    title: 'Report',
                    showNavigationSidebar: true,
                    leftSidebarIsCompact: true,
                    titleTranslationKey: 'spa.navigation.sections.reports.groups-report',
                },
                component: GROUP_REPORT_PAGE,
            },
            {
                path: `${Paths.MOBILE}`,
                name: Names.REPORT_MANAGER_MOBILE_ROOT,
                meta: {
                    title: 'Reporting',
                    titleTranslationKey: 'spa.navigation.sections.main.reports',
                },
                component: MOBILE_ROOT_PAGE,
                beforeEnter: guards([redirectIfGuard(() => isDesktop(), Names.MY_REPORT)]),
            },
        ],
        component: ADMIN_ROOT_PAGE,
    },
    {
        path: `${Paths.REPORTING}/${Paths.COURSE}/:course_id(\\d+)`,
        name: Names.COURSE_REPORT,
        meta: {
            title: 'Reporting',
            defaultBackRoute: Names.COURSES_REPORT,
            requiredPermissions: [Permissions.Reporting],
        },
        component: COURSE_REPORT_PAGE,
    },
    {
        path: `${Paths.REPORTING}/${Paths.COURSE}/:course_id(\\d+)/quiz/:quiz_id(\\d+)`,
        name: Names.QUIZ_REPORT,
        meta: {
            title: 'Reporting',
            defaultBackRoute: Names.COURSES_REPORT,
            requiredPermissions: [Permissions.Reporting],
        },
        component: QUIZ_REPORT_PAGE,
    },
    {
        path: `${Paths.REPORTING}/${Paths.USER}/:user_id(\\d+)`,
        name: Names.USER_REPORT,
        meta: {
            title: 'Reporting',
            defaultBackRoute: Names.USERS_REPORT,
            requiredPermissions: [],
        },
        component: USER_REPORT_PAGE,
    },
    {
        path: `${Paths.REPORTING}/${Paths.SEGMENTS}/${Paths.GROUP}/:id`,
        name: Names.SEGMENT_GROUP_REPORT,
        meta: {
            title: 'Segment Group Report',
            defaultBackRoute: Names.SEGMENT_OVERVIEW,
            requiredPermissions: [],
        },
        beforeEnter: segmentReportingGuard,
        component: SEGMENT_GROUP_REPORT_PAGE,
    },
    {
        path: `${Paths.REPORT}/${Paths.GROUP}/:id`,
        name: Names.GROUP_REPORT,
        meta: {
            title: 'Group Report',
            defaultBackRoute: Names.GROUPS_REPORT,
        },
        component: SEGMENT_GROUP_REPORT_PAGE,
        beforeEnter: guards([
            checkIfRouteAvailableByPlanFeatures(PlanFeaturesList.USER_PERMISSIONS),
            checkIfRouteAvailableByPlanFeatures(PlanFeaturesList.REPORTING),
        ]),
    },
    {
        path: `${Paths.REPORTING}/${Paths.SEGMENTS}/${Paths.TOPIC}/:id`,
        name: Names.SEGMENT_TOPIC_REPORT,
        meta: {
            title: 'Segment Topic Report',
            defaultBackRoute: Names.SEGMENT_OVERVIEW,
            requiredPermissions: [Permissions.Reporting],
        },
        beforeEnter: segmentReportingGuard,
        component: SEGMENT_TOPIC_REPORT_PAGE,
    },
    {
        path: `${Paths.REPORTING}/${Paths.SKILLS}/:id`,
        name: Names.SKILL_REPORT,
        meta: {
            title: 'Skill Report',
            defaultBackRoute: Names.SKILLS_REPORT,
            requiredPermissions: [Permissions.Reporting, Permissions.AdminSkills],
        },
        beforeEnter: guards([checkIfRouteAvailableByPlanFeatures(PlanFeaturesList.SKILLS)]),
        component: SKILL_REPORT_PAGE,
    },
    {
        path: `${Paths.REPORTING}/${Paths.EVENT_REPORT}/:event_id`,
        name: Names.EVENT_REPORT,
        meta: {
            title: 'Event Report',
            defaultBackRoute: Names.EVENT_REPORT,
            requiredPermissions: [Permissions.Reporting, Permissions.AdminEvents],
        },
        beforeEnter: guards([checkIfRouteAvailableByPlanFeatures(PlanFeaturesList.EVENTS)]),
        component: EVENT_REPORT_PAGE,
    },
    {
        path: `${Paths.REPORTING}/${Paths.EVENT_REPORT}/:event_id/${Paths.SESSION_REPORT}/:session_id`,
        name: Names.SESSION_REPORT,
        meta: {
            title: 'Session Report',
            defaultBackRoute: (currentRoute: Route) => ({
                name: Names.EVENT_REPORT,
                params: { event_id: currentRoute.params.event_id },
            }),
            requiredPermissions: [Permissions.Reporting, Permissions.AdminEvents],
        },
        beforeEnter: guards([checkIfRouteAvailableByPlanFeatures(PlanFeaturesList.EVENTS)]),
        component: SESSION_REPORT_PAGE,
    },
    {
        path: `${Paths.REPORTING}/${Paths.COURSE}/:course_id/quiz/:quiz_id/file/:attempt_id/:content_id`,
        name: Names.UPLOAD_QUESTION_DOWNLOAD_FILE,
        meta: {
            title: 'File',
        },
        component: QUIZ_FILE_REDIRECTION_PAGE,
    },
    {
        path: `${Paths.REPORTING}/${Paths.PLAYLIST_REPORT}/:playlist_id`,
        name: Names.PLAYLIST_REPORT,
        meta: {
            title: 'Playlist Report',
            titleTranslationKey: 'spa.navigation.sections.reports.playlists',
            requiredPermissions: [Permissions.Reporting],
        },
        component: PLAYLIST_REPORT_PAGE,
        beforeEnter: checkIfRouteAvailableByPlanFeatures(PlanFeaturesList.PLAYLISTS),
    },
];

export const adminReportRoutes: RouteConfig[] = [
    {
        path: `${Paths.REPORT}/`,
        name: Names.MY_ADMIN_REPORT,
        meta: {
            title: 'Report',
            showNavigationSidebar: true,
            leftSidebarIsCompact: true,
            titleTranslationKey: 'spa.navigation.sections.reports.my-learning',
            requiredPermissions: [Permissions.Reporting],
        },
        component: MY_REPORT_PAGE,
        beforeEnter: checkIfRouteAvailableByPlanFeatures(PlanFeaturesList.REPORTING),
    },
    {
        path: `${Paths.REPORT}/${Paths.ALL_COURSES}/`,
        name: Names.COURSES_REPORT,
        meta: {
            title: 'Courses Report',
            showNavigationSidebar: true,
            leftSidebarIsCompact: true,
            titleTranslationKey: 'spa.navigation.sections.reports.courses',
            requiredPermissions: [Permissions.Reporting],
        },
        component: ALL_COURSES_REPORT_PAGE,
        beforeEnter: checkIfRouteAvailableByPlanFeatures(PlanFeaturesList.REPORTING),
    },
    {
        path: `${Paths.REPORT}/${Paths.ALL_USERS}/`,
        name: Names.USERS_REPORT,
        meta: {
            title: 'Users Report',
            showNavigationSidebar: true,
            leftSidebarIsCompact: true,
            titleTranslationKey: 'spa.navigation.sections.reports.users',
            requiredPermissions: [Permissions.Reporting],
        },
        component: ALL_USERS_REPORT_PAGE,
        beforeEnter: checkIfRouteAvailableByPlanFeatures(PlanFeaturesList.REPORTING),
    },
    {
        path: `${Paths.REPORT}/${Paths.SEGMENT_OVERVIEW}/`,
        name: Names.SEGMENT_OVERVIEW,
        meta: {
            title: 'Users Report',
            showNavigationSidebar: true,
            leftSidebarIsCompact: true,
            titleTranslationKey: 'spa.navigation.sections.reports.segments',
            requiredPermissions: [Permissions.Reporting],
        },
        component: SEGMENT_OVERVIEW_PAGE,
        beforeEnter: segmentReportingGuard,
    },
    {
        path: `${Paths.REPORT}/${Paths.GROUP}/`,
        name: Names.GROUPS_REPORT,
        meta: {
            title: 'Groups Report',
            showNavigationSidebar: true,
            leftSidebarIsCompact: true,
            titleTranslationKey: 'spa.navigation.sections.reports.groups',
            requiredPermissions: [Permissions.Reporting],
        },
        component: GROUP_REPORT_PAGE,
        beforeEnter: guards([
            checkIfRouteAvailableByPlanFeatures(PlanFeaturesList.USER_PERMISSIONS),
            checkIfRouteAvailableByPlanFeatures(PlanFeaturesList.REPORTING),
        ]),
    },
    {
        path: `${Paths.REPORT}/${Paths.SKILLS}/`,
        name: Names.SKILLS_REPORT,
        meta: {
            title: 'Skills Report',
            showNavigationSidebar: true,
            leftSidebarIsCompact: true,
            titleTranslationKey: 'spa.navigation.sections.reports.skills',
            requiredPermissions: [Permissions.Reporting],
        },
        component: SKILLS_REPORT_PAGE,
        beforeEnter: guards([checkIfRouteAvailableByPlanFeatures(PlanFeaturesList.SKILLS)]),
    },
    {
        path: `${Paths.REPORT}/${Paths.EVENTS_REPORT}/`,
        name: Names.EVENTS_REPORT,
        meta: {
            title: 'Events Report',
            showNavigationSidebar: true,
            leftSidebarIsCompact: true,
            titleTranslationKey: 'spa.navigation.sections.reports.events',
            requiredPermissions: [Permissions.Reporting],
        },
        component: EVENTS_REPORT_PAGE,
        beforeEnter: guards([checkIfRouteAvailableByPlanFeatures(PlanFeaturesList.EVENTS)]),
    },
    {
        path: `${Paths.REPORT}/${Paths.PLAYLISTS_REPORT}/`,
        name: Names.PLAYLISTS_REPORT,
        meta: {
            title: 'Playlists Report',
            showNavigationSidebar: true,
            leftSidebarIsCompact: true,
            titleTranslationKey: 'spa.navigation.sections.reports.playlists',
            requiredPermissions: [Permissions.Reporting],
        },
        component: PLAYLISTS_REPORT_PAGE,
        beforeEnter: guards([checkIfRouteAvailableByPlanFeatures(PlanFeaturesList.PLAYLISTS)]),
    },
];

const isDesktop = () => {
    const store = MobileModeStoreModule.context(getBaseStore());

    return Boolean(!store.getters.isMobile());
};

export default routes;
