import { FeatureTogglesMap } from '@/launch-darkly/models';

const CACHE_KEY = 'feature-toggles';

export function getCachedFeaturesToggleData() {
    const cachedData = localStorage.getItem(CACHE_KEY);

    return cachedData !== null ? (JSON.parse(cachedData) as FeatureTogglesMap) : null;
}

export function cacheFeaturesToggleData(data: FeatureTogglesMap) {
    const stringify = JSON.stringify(data);

    return localStorage.setItem(CACHE_KEY, stringify);
}

export function clearFeaturesToggleDataFromLocalStorage() {
    localStorage.removeItem(CACHE_KEY);
}
