import { RouteConfig } from 'vue-router';
import { mobileIsUnsupportedFlag } from '@/common/services/mobile-fallback';
import { checkIfRouteAvailableByPlanFeatures } from '@/plan-features/routes';
import PlanFeaturesList from '@/plan-features/plan-features-list';
import { Permissions } from '@/permissions';

const skillsLibraryPage = () => import('./pages/SkillsLibraryPage.vue');
const businessTitlesPage = () => import('./pages/BusinessTitlesPage.vue');

export const enum AdminSkillsRoutes {
    BUSINESS_TITLES = 'business-titles',
    SKILLS_LIBRARY = 'skills-library',
}

const routes: RouteConfig[] = [
    {
        path: 'business-titles',
        name: AdminSkillsRoutes.BUSINESS_TITLES,
        meta: {
            title: 'Business Titles',
            ...mobileIsUnsupportedFlag,
            showNavigationSidebar: true,
            requiredPermissions: [Permissions.AdminSkills],
        },
        component: businessTitlesPage,
        beforeEnter: checkIfRouteAvailableByPlanFeatures(PlanFeaturesList.SKILLS),
    },
    {
        path: 'skills-library',
        name: AdminSkillsRoutes.SKILLS_LIBRARY,
        meta: {
            title: 'Skills Library',
            ...mobileIsUnsupportedFlag,
            showNavigationSidebar: true,
            requiredPermissions: [Permissions.AdminSkills],
        },
        component: skillsLibraryPage,
        beforeEnter: checkIfRouteAvailableByPlanFeatures(PlanFeaturesList.SKILLS),
    },
];

export default routes;
