import { Locale } from 'date-fns';
import * as Locales from 'date-fns/locale';

import { Interval } from '@/courses/components/events/use-event-overview-date-selector.composable';
import { Language } from '@/translations';

export type NullableDate = Date | null | undefined;
export type DateInAnyForm = Date | string | number | null | undefined;

export function getDate(date: DateInAnyForm) {
    if (date === null || date === undefined) {
        return null;
    }

    let dateToFormat = date;
    if (!(dateToFormat instanceof Date)) {
        dateToFormat = new Date(date);
    }

    if (Number.isNaN(dateToFormat.getTime())) {
        return null;
    }

    return dateToFormat;
}

export function currentLanguageToDateFNSLocale(currentLanguage: Language): Locale {
    return Locales[currentLanguage.replace('-', '')] ?? Locales[currentLanguage.slice(0, 2)] ?? Locales.enUS;
}

const dayMilliseconds = 24 * 60 * 60 * 1000;

export function getDayStart(date?: DateInAnyForm) {
    date = getDate(date) || new Date();
    const tzOffset = date.getTimezoneOffset() * 60 * 1000;
    const ts = date.getTime();
    return new Date(ts - ((ts - tzOffset) % dayMilliseconds));
}

export function getDayEnd(date?: DateInAnyForm) {
    date = getDate(date) || new Date();
    const tzOffset = date.getTimezoneOffset() * 60 * 1000;
    const ts = date.getTime();
    return new Date(ts - ((ts - tzOffset) % dayMilliseconds) + dayMilliseconds - 1);
}

/**
 * Verifies whether event is in progress.
 * @param eventStart - Event start date
 * @param eventEnd - Event end date
 */
export function isEventNow(eventStart: DateInAnyForm, eventEnd: DateInAnyForm) {
    const currentDate = new Date();
    const startDate = getDate(eventStart);
    const endDate = getDate(eventEnd);
    if (!startDate || !endDate) {
        return false;
    }
    const currentTime = currentDate.getTime();
    const startTime = startDate.getTime();
    const endTime = endDate.getTime();
    const hasEventStarted = startTime < currentTime;
    const hasEventEnded = endTime < currentTime;
    return hasEventStarted && !hasEventEnded;
}

/**
 * Verifies whether event occurrs today regardless it has started or ended.
 * @param eventStart - Event start date
 * @param eventEnd - Event end date
 * @param reference - date of reference
 */
export function doesEventIntersectReferenceInterval(
    eventStart: DateInAnyForm,
    eventEnd: DateInAnyForm,
    referenceInterval: Interval
) {
    let startDate = getDate(eventStart);
    let endDate = getDate(eventEnd);
    if (!startDate && !endDate) {
        return false;
    } else {
        startDate = (startDate || endDate) as Date;
        endDate = endDate || startDate;
    }
    if (!referenceInterval?.start || !referenceInterval?.end) {
        throw new TypeError('Invalid reference interval');
    }
    const referenceDateStart = referenceInterval.start;
    const referenceDateEnd = referenceInterval.end;
    const referenceDayStartTime = referenceDateStart.getTime();
    const referenceDayEndTime = referenceDateEnd.getTime();
    const eventStartTime = startDate.getTime();
    const eventEndTime = endDate.getTime();
    const hasEventStartedWithinReferenceInterval =
        eventStartTime > referenceDayStartTime && eventStartTime < referenceDayEndTime;
    const hasEventEndedWithinReferenceInterval =
        eventEndTime > referenceDayStartTime && eventEndTime < referenceDayEndTime;
    return hasEventStartedWithinReferenceInterval || hasEventEndedWithinReferenceInterval;
}

/**
 * Verifies whether event has already ended.
 * @param eventEnd - Event end date
 */
export function hasEventEnded(eventEnd: DateInAnyForm) {
    const currentDate = new Date();
    const endDate = getDate(eventEnd);
    if (!endDate) {
        throw new TypeError('Invalid end date');
    }
    const currentTime = currentDate.getTime();
    const endTime = endDate.getTime();
    return endTime < currentTime;
}

export function datesAreEqual(date1: NullableDate, date2: NullableDate) {
    if (!date1 || !date2) {
        return !date1 && !date2;
    }
    return date1.getTime() === date2.getTime();
}

export function isDatesSameDay(date1: DateInAnyForm, date2: DateInAnyForm) {
    const normalizedDate1 = getDate(date1);
    const normalizedDate2 = getDate(date2);

    if (!normalizedDate1 || !normalizedDate2) {
        return !normalizedDate1 && !normalizedDate2;
    }

    return (
        normalizedDate1.getFullYear() === normalizedDate2.getFullYear() &&
        normalizedDate1.getMonth() === normalizedDate2.getMonth() &&
        normalizedDate1.getDate() === normalizedDate2.getDate()
    );
}
