/**
 * This is a kind of copy-paste of vueuse 0.8.0 implementation.
 */

import { type Ref, ref, watch } from 'vue';
import { unrefElement, MaybeElement } from '@vueuse/core';
import { useResizeObserver, type ResizeObserverOptions } from './use-resize-observer';

export interface ElementSize {
    width: number;
    height: number;
}

/**
 * Reactive size of an HTML element.
 *
 * @see https://vueuse.org/useElementSize
 * @param target
 * @param callback
 * @param options
 */
export function useElementSize(
    target: Ref<MaybeElement>,
    initialSize: ElementSize = { width: 0, height: 0 },
    options: ResizeObserverOptions = {}
) {
    const width = ref(initialSize.width);
    const height = ref(initialSize.height);

    useResizeObserver(
        target,
        ([entry]) => {
            width.value = entry.contentRect.width;
            height.value = entry.contentRect.height;
        },
        options
    );
    watch(
        () => unrefElement(target),
        ele => {
            width.value = ele ? initialSize.width : 0;
            height.value = ele ? initialSize.height : 0;
        }
    );
    return {
        width,
        height,
    };
}

export type UseElementSizeReturn = ReturnType<typeof useElementSize>;
