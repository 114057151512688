import { NavigationGuardNext, Route } from 'vue-router';

import { handlePageNotFound } from '@/common/services/transition-common';
import { getBaseStore } from '@/main/get-base-store-router';

import { AppFeaturesList } from '../models';
import launchDarklyFeatureStoreModule from '../store';

export const checkIfAvailableByLaunchDarkly = (launchDarklyFeature: AppFeaturesList) => {
    return async (_to: Route, _from: Route, next: NavigationGuardNext) => {
        const launchDarklyFeaturesStore = launchDarklyFeatureStoreModule.context(getBaseStore());
        const isLaunchDarklyFeaturesInitialized = launchDarklyFeaturesStore.state.launchDarklyInitialized;

        if (!isLaunchDarklyFeaturesInitialized) {
            await launchDarklyFeaturesStore.actions.initializeLaunchDarkly();
        }

        const isPageAvailable =
            launchDarklyFeaturesStore.getters.checkIfLaunchDarklyFeatureEnabled(launchDarklyFeature);

        if (isPageAvailable) {
            next();
        } else {
            handlePageNotFound(next);
        }
    };
};
