import { PlaylistApiFactory, PlaylistStepApiFactory } from '@eloomi/eloomi-learning-persona-client/1.0';
import { authorizeClient } from '@/api/authorize-client';

const PlaylistApiClient = authorizeClient(PlaylistApiFactory);
const PlaylistStepApiClient = authorizeClient(PlaylistStepApiFactory);

export function getPlaylist(playlistId: number) {
    return PlaylistApiClient.getUserPlaylist(playlistId).then(({ data }) => data);
}

export function takePlaylistStep(playlistId: number, playlistStepId: number) {
    return PlaylistStepApiClient.takePlaylistStep(playlistId, playlistStepId).then(({ data }) => data);
}
