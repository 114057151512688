import { RouteConfig } from 'vue-router';
import PlanFeaturesList from '@/plan-features/plan-features-list';
import { checkIfRouteAvailableByPlanFeatures } from '@/plan-features/routes';
import { CoursesRouteNames } from '@/courses/route-definitions';

const EVENT_OVERVIEW_PAGE = () => import('./pages/EventOverviewPage.vue');

export const enum EventsRoutesNames {
    EVENT_OVERVIEW = 'event_overview',
}

const routes: RouteConfig[] = [
    {
        path: `events/:event_id(\\d+)/overview`,
        name: EventsRoutesNames.EVENT_OVERVIEW,
        meta: {
            title: 'Event',
            defaultBackRoute: CoursesRouteNames.COURSES_DASHBOARD,
        },
        beforeEnter: checkIfRouteAvailableByPlanFeatures(PlanFeaturesList.EVENTS),
        component: EVENT_OVERVIEW_PAGE,
    },
];

export default routes;
