import { CategoryTopicsResponse, TopicLiteModel, UserTopicsResponse } from '@eloomi/eloomi-learning-persona-client/1.0';
import { SkillResponse } from '@eloomi/eloomi-learning-persona-client/1.0/models/skill-response';
import { UserLearningItemsPaginatedResponse } from '@eloomi/eloomi-learning-persona-client/2.0';
import { UserLearningItemResponse } from '@eloomi/eloomi-learning-persona-client/2.0/models/user-learning-item-response';
import { Vue } from 'vue-property-decorator';
import { Actions, createComposable, createMapper, Getters, Module, Mutations } from 'vuex-smart-module';

import { getUserCategories } from '@/admin/topics/providers';
import { getUserLearningItems } from '@/api/providers/dashboard.provider';
import { SelectedOption } from '@/common/components/table';
import { filterIdsToNumberIds, FluentFilterCollection, Operator, searchTextToSearchTerms } from '@/common/services';
import { CourseTopic } from '@/courses/models';
import {
    availableDurationFilterTypes,
    availableTypeFilterTypes,
    DurationFilter,
    FilterProperties,
    LearningItemType,
    TypeFilter,
    TypeFilterType,
} from '@/courses/pages/all-courses-overview/overview-filters';
import { getUserCoursesSkills, getUserTopics } from '@/courses/providers';

export class LearningOverviewQueryParams {
    page = 1;
    pageSize = 12;
    searchText = '';
    selectedCategory: CategoryTopicsResponse | null = null;
    selectedDuration: DurationFilter = availableDurationFilterTypes[0];
    selectedSkills: SkillResponse[] = [];
    selectedTopics: UserTopicsResponse[] = [];
    selectedStatus: TypeFilter = availableTypeFilterTypes[0];
}

export class LearningOverviewState extends LearningOverviewQueryParams {
    learningItems: UserLearningItemsPaginatedResponse | null = null;
    mobileItemsList: UserLearningItemResponse[] = [];

    coursesCategories: CategoryTopicsResponse[] | null = null;
    coursesTopics: UserTopicsResponse[] | null = null;
    coursesSkills: SkillResponse[] | null = null;
    learningItemType: LearningItemType = 'courses';
    sorting = null as SelectedOption | null;
}

export class LearningOverviewGetters extends Getters<LearningOverviewState> {
    get numberOfFilters() {
        return (
            (this.state.selectedDuration?.from || this.state.selectedDuration?.to ? 1 : 0) +
            (this.state.selectedStatus && this.state.selectedStatus.type !== TypeFilterType.ShowAll ? 1 : 0) +
            (this.state.selectedTopics.length > 0 ? 1 : 0) +
            (this.state.selectedSkills.length > 0 ? 1 : 0)
        );
    }

    get numberOfCourses(): number {
        return this.state.learningItems?.total || 0;
    }

    get filters() {
        const builder = new FluentFilterCollection();

        if (this.state.searchText !== '') {
            builder
                .where(FilterProperties.titleProperty, FilterProperties.descriptionProperty)
                .filter(Operator.Contains, ...searchTextToSearchTerms(this.state.searchText));
        }

        if (this.state.selectedTopics.length > 0) {
            const selectedTopicsIds = this.state.selectedTopics.map(topic => topic.topic_id).join('|');
            builder.where(FilterProperties.topicId).filter(Operator.Have, selectedTopicsIds);
        }

        if (this.state.selectedDuration) {
            const where = builder.where(FilterProperties.durationProperty);

            if (this.state.selectedDuration.from) {
                where.filter(Operator.GreaterThanOrEqual, this.state.selectedDuration.from);
            }

            if (this.state.selectedDuration.to) {
                where.filter(Operator.LessThanOrEqual, this.state.selectedDuration.to);
            }
        }

        if (this.state.selectedStatus) {
            switch (this.state.selectedStatus.type) {
                case TypeFilterType.Required: {
                    builder.where(FilterProperties.requiredProperty).filter(Operator.IsEqualTo, true);
                    break;
                }

                case TypeFilterType.Deadline: {
                    builder.where(FilterProperties.deadlineProperty).filter(Operator.IsNotEqualTo, 'null');
                    break;
                }

                case TypeFilterType.Completed: {
                    builder.where(FilterProperties.completedProperty).filter(Operator.IsEqualTo, true);
                    break;
                }
            }
        }

        if (this.state.selectedSkills.length > 0) {
            const selectedSkillsIds = this.state.selectedSkills.map(skill => skill.id).join('|');
            builder.where(FilterProperties.skillId).filter(Operator.Have, selectedSkillsIds);
        }

        if (this.state.selectedCategory) {
            builder.where(FilterProperties.categoryId).filter(Operator.Have, this.state.selectedCategory.id!);
        }

        return builder;
    }

    get filtersSearchQuery() {
        return this.getters.filters.toQueryStringSearch();
    }

    get filteredCourseTopics() {
        const topics = this.state.coursesTopics || [];

        if (this.state.selectedCategory) {
            const categoryTopicsIds = this.state.selectedCategory.topic_ids;

            return topics.filter(topic => categoryTopicsIds.includes(topic.topic_id!));
        } else {
            return topics;
        }
    }
}

export class LearningOverviewMutations extends Mutations<LearningOverviewState> {
    setSearchQueryFromQuery(filtersCollection: FluentFilterCollection) {
        const titleFilters = filtersCollection.getFilterTermForProperty(
            FilterProperties.titleProperty,
            Operator.Contains
        );

        if (titleFilters.length > 0) {
            this.state.searchText = titleFilters.join(' ');
        }
    }

    setDurationFromQuery(filtersCollection: FluentFilterCollection) {
        const greaterThanDurationFilter =
            filtersCollection.getFilterTermForProperty(
                FilterProperties.durationProperty,
                Operator.GreaterThanOrEqual
            )[0] ?? undefined;
        const lessThanDurationFilter =
            filtersCollection.getFilterTermForProperty(
                FilterProperties.durationProperty,
                Operator.LessThanOrEqual
            )[0] ?? undefined;

        this.state.selectedDuration =
            availableDurationFilterTypes
                .filter(x => x.from === greaterThanDurationFilter)
                .find(x => x.to === lessThanDurationFilter) ?? availableDurationFilterTypes[0];
    }

    setTypeFromQuery(filtersCollection: FluentFilterCollection) {
        if (filtersCollection.getFilterTermForProperty(FilterProperties.completedProperty)[0]) {
            this.state.selectedStatus = availableTypeFilterTypes[1];
        }
        if (filtersCollection.getFilterTermForProperty(FilterProperties.requiredProperty)[0]) {
            this.state.selectedStatus = availableTypeFilterTypes[2];
        }
        if (filtersCollection.getFilterTermForProperty(FilterProperties.deadlineProperty)[0]) {
            this.state.selectedStatus = availableTypeFilterTypes[3];
        }
    }

    setTopicFilterFromQuery(filtersCollection: FluentFilterCollection) {
        const topicsIdsString = filtersCollection.getFilterTermForProperty(FilterProperties.topicId)[0];

        if (topicsIdsString && this.state.coursesTopics) {
            const topicsIds = filterIdsToNumberIds(topicsIdsString);
            const topics = this.state.coursesTopics.filter(topic => topicsIds.includes(topic.topic_id!));
            this.state.selectedTopics = topics;
        }
    }

    setSkillsFilterFromQuery(filtersCollection: FluentFilterCollection) {
        const skillIdsString = filtersCollection.getFilterTermForProperty(FilterProperties.skillId)[0];

        if (skillIdsString && this.state.coursesSkills) {
            const skillIds = filterIdsToNumberIds(skillIdsString);
            const skills = this.state.coursesSkills.filter(skill => skillIds.includes(skill.id));
            this.state.selectedSkills = skills;
        }
    }

    setCategoryFilterFromQuery(filtersCollection: FluentFilterCollection) {
        const ids = filtersCollection.getFilterTermForProperty(FilterProperties.categoryId)[0] ?? false;

        if (ids && this.state.coursesCategories) {
            const formattedIds = filterIdsToNumberIds(ids);
            const selectedCategory =
                this.state.coursesCategories.find(category => formattedIds.includes(category.id!)) || null;

            this.state.selectedCategory = selectedCategory;
        }
    }

    clearData() {
        this.state.learningItems = null;
        this.state.mobileItemsList = [];
        this.state.page = 1;
        this.state.pageSize = 12;
        this.state.sorting = null;
    }

    setData({ data, appendNextPage = false }: { data: UserLearningItemsPaginatedResponse; appendNextPage?: boolean }) {
        this.state.learningItems = data;
        if (this.state.page === 1) {
            this.state.mobileItemsList = [...data.data];
        } else if (appendNextPage) {
            this.state.mobileItemsList = [...this.state.mobileItemsList, ...data.data];
        }
    }

    updateFilter(newFilter: Partial<LearningOverviewQueryParams>) {
        for (const key in newFilter) {
            if (newFilter[key] !== undefined) {
                this.state[key] = newFilter[key];
            }
        }
    }

    setCategories(categories: CategoryTopicsResponse[]) {
        this.state.coursesCategories = categories;
    }

    setTopics(topics: UserTopicsResponse[]) {
        this.state.coursesTopics = topics;
    }

    setSkills(skills: SkillResponse[]) {
        this.state.coursesSkills = skills;
    }

    setSelectedCourseTopics(data: UserTopicsResponse[] | CourseTopic | TopicLiteModel) {
        if (Array.isArray(data)) {
            this.state.selectedTopics = data;
        } else {
            const topic = this.state.coursesTopics?.find(t => t.topic_id === data.id) || {
                topic_name: data.name,
                topic_id: data.id,
                assigned_courses_count: 0,
                assigned_playlists_count: 0,
            };
            this.state.selectedTopics = [topic];
        }
    }

    setSelectedCourseSkills(data: SkillResponse[]) {
        this.state.selectedSkills = data;
    }

    setLearningItemType(learningItemType: LearningItemType) {
        this.state.learningItemType = learningItemType;
    }
    setSorting(value: SelectedOption | null) {
        this.state.sorting = value;
        this.state.page = 1;
    }
}

export class LearningOverviewActions extends Actions<
    LearningOverviewState,
    LearningOverviewGetters,
    LearningOverviewMutations
> {
    async getLearningItems({ appendNextPage = false }: { appendNextPage: boolean } = { appendNextPage: false }) {
        const data = await getUserLearningItems(
            { page: this.state.page, pageSize: this.state.pageSize },
            this.getters.filtersSearchQuery,
            this.state.sorting
        );
        this.mutations.setData({ data, appendNextPage });
    }

    async getCoursesCategoriesAndTopics() {
        const [categories, topics] = await Promise.all([getUserCategories(), getUserTopics()]);
        this.mutations.setCategories(categories.data);
        this.mutations.setTopics(topics.data);
    }

    async getCourseSkills() {
        this.mutations.setSkills(await getUserCoursesSkills());
    }

    setFilters(query: string | null) {
        const filtersCollection = FluentFilterCollection.deserialize(query || '');

        this.mutations.setSearchQueryFromQuery(filtersCollection);
        this.mutations.setDurationFromQuery(filtersCollection);
        this.mutations.setTypeFromQuery(filtersCollection);
        this.mutations.setCategoryFilterFromQuery(filtersCollection);
        this.mutations.setTopicFilterFromQuery(filtersCollection);
        this.mutations.setSkillsFilterFromQuery(filtersCollection);
    }

    setSelectedCategory(category: CategoryTopicsResponse) {
        const updates: {
            selectedCategory: CategoryTopicsResponse | null;
            selectedTopics?: UserTopicsResponse[];
        } = { selectedCategory: null };

        if (!category || this.state.selectedCategory?.id === category.id) {
            updates.selectedCategory = null;
        } else {
            updates.selectedCategory = category;
            updates.selectedTopics = this.state.selectedTopics.filter(topic =>
                category.topic_ids.includes(topic.topic_id!)
            );
        }

        this.mutations.updateFilter(updates);
    }

    setSelectedDuration(filter: DurationFilter) {
        this.mutations.updateFilter({ selectedDuration: filter });
    }

    setSelectedStatus(filter: TypeFilter) {
        this.mutations.updateFilter({ selectedStatus: filter });
    }

    clearStore() {
        this.mutations.updateFilter({
            searchText: '',
            selectedCategory: null,
            selectedTopics: [],
            selectedSkills: [],
            selectedDuration: availableDurationFilterTypes[0],
            selectedStatus: availableTypeFilterTypes[0],
        });
        this.mutations.clearData();
    }
}

export const learningOverviewStoreModule = new Module({
    state: LearningOverviewState,
    getters: LearningOverviewGetters,
    mutations: LearningOverviewMutations,
    actions: LearningOverviewActions,
});

export const useLearningOverviewStore = createComposable(learningOverviewStoreModule);

export const LearningOverviewStoreMapper = createMapper(learningOverviewStoreModule);

export const LearningOverviewMappedStore = Vue.extend({
    computed: {
        ...LearningOverviewStoreMapper.mapGetters([
            'filters',
            'filtersSearchQuery',
            'filteredCourseTopics',
            'numberOfFilters',
            'numberOfCourses',
        ]),
        ...LearningOverviewStoreMapper.mapState([
            'coursesCategories',
            'coursesTopics',
            'coursesSkills',
            'learningItems',
            'learningItemType',
            'mobileItemsList',
            'page',
            'pageSize',
            'searchText',
            'sorting',
            'selectedCategory',
            'selectedDuration',
            'selectedSkills',
            'selectedTopics',
            'selectedStatus',
        ]),
    },
    methods: {
        ...LearningOverviewStoreMapper.mapActions([
            'clearStore',
            'getLearningItems',
            'getCoursesCategoriesAndTopics',
            'getCourseSkills',
            'setFilters',
            'setSelectedCategory',
            'setSelectedDuration',
            'setSelectedStatus',
        ]),
        ...LearningOverviewStoreMapper.mapMutations([
            'updateFilter',
            'setSelectedCourseTopics',
            'setSelectedCourseSkills',
            'setLearningItemType',
            'setSorting',
        ]),
    },
});
