<template>
    <div class="option-toggle" :class="{ disabled: isDisabled }" @click.self="handleClick">
        <p class="description" @click.self="handleClick">{{ description }}</p>
        <CoreToggle
            v-tippy="{ content: disabledNote, onShow: () => isDisabled }"
            :value="value"
            class="toggle"
            :disabled="isDisabled"
            @input="handleClick"
        />
    </div>
</template>
<script lang="ts">
    import { defineComponent, PropType } from 'vue';

    import CoreToggle from '@/common/components/controls/toggle/CoreToggle.vue';

    export default defineComponent({
        components: { CoreToggle },
        props: {
            description: {
                type: String,
                required: true,
            },
            value: {
                type: Boolean,
                required: true,
            },
            disabledNote: {
                type: String as PropType<string | undefined>,
                default: undefined,
            },
            isDisabled: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['input'],
        setup(props, { emit }) {
            return {
                handleClick: () => {
                    !props.isDisabled && emit('input', !props.value);
                },
            };
        },
    });
</script>
<style lang="less" scoped>
    .option-toggle {
        display: flex;
        align-items: center;
        min-height: 78px;
        padding: @spacing-24;
        background-color: @info-color-8;
        border-radius: @border-radius-8;
        cursor: pointer;

        &.disabled {
            cursor: default;
        }
    }

    .description {
        margin: 0;
        padding: 0;
        .paragraph-semibold();

        line-height: 1.4em;
    }

    .toggle {
        margin-left: auto;
    }
</style>
