import { RouteConfig } from 'vue-router';

import { mobileIsUnsupportedFlag } from '@/common/services/mobile-fallback';
import { checkIfRouteAvailableByPlanFeatures } from '@/plan-features/routes';
import PlanFeaturesList from '@/plan-features/plan-features-list';
import { Permissions } from '@/permissions';

const EVENT_BUILDER_PAGE = () => import('./pages/EventBuilderPage.vue');
const EVENTS_OVERVIEW_PAGE = () => import('./pages/EventsOverviewPage.vue');

export const enum AdminEventsRoutes {
    EVENT_BUILDER_CREATE = 'event-builder-create',
    EVENT_BUILDER = 'event-builder',
    EVENTS_OVERVIEW = 'events-overview',
}

const routes: RouteConfig[] = [
    {
        path: 'events',
        name: AdminEventsRoutes.EVENTS_OVERVIEW,
        meta: {
            title: 'Events Overview',
            ...mobileIsUnsupportedFlag,
            showNavigationSidebar: true,
            requiredPermissions: [Permissions.AdminEvents],
        },
        component: EVENTS_OVERVIEW_PAGE,
        beforeEnter: checkIfRouteAvailableByPlanFeatures(PlanFeaturesList.EVENTS),
    },
    {
        path: 'events/create',
        name: AdminEventsRoutes.EVENT_BUILDER_CREATE,
        meta: {
            title: 'Create event',
            ...mobileIsUnsupportedFlag,
            defaultBackRoute: AdminEventsRoutes.EVENTS_OVERVIEW,
            requiredPermissions: [Permissions.AdminEvents],
        },
        component: EVENT_BUILDER_PAGE,
        beforeEnter: async (_to, _from, next) => {
            await checkIfRouteAvailableByPlanFeatures(PlanFeaturesList.EVENTS)(_to, _from, next);
        },
    },
    {
        path: 'events/:id(\\d+)/edit',
        name: AdminEventsRoutes.EVENT_BUILDER,
        meta: {
            title: 'Edit event',
            ...mobileIsUnsupportedFlag,
            defaultBackRoute: AdminEventsRoutes.EVENTS_OVERVIEW,
            requiredPermissions: [Permissions.AdminEvents],
            showNavigationSidebar: false,
        },
        component: EVENT_BUILDER_PAGE,
        beforeEnter: async (_to, _from, next) => {
            await checkIfRouteAvailableByPlanFeatures(PlanFeaturesList.EVENTS)(_to, _from, next);
        },
    },
];

export default routes;
