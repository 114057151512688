<template>
    <Selector
        :options="options"
        :class="{ disabled: isDisabled, locked: isLocked }"
        :renderToPortal="renderToPortal"
        :preventOutsideClickPropagation="preventOutsideClickPropagation"
        :closeOnSelect="closeOnSelect"
        :popupTestName="testName"
        @select="$emit('select', $event)"
    >
        <template #input="{ toggle, isOpen }">
            <slot name="input" :toggle="toggle" :isOpen="isOpen">
                <TextField
                    :size="size"
                    class="selector"
                    :value="stringValue"
                    :iconRight="getIconRight(isOpen)"
                    :placeholder="placeholder"
                    isReadOnly
                    :iconLeft="iconLeft"
                    :isSelectable="false"
                    :color="color"
                    :isDisabled="isDisabled"
                    :testId="`selector-field-${testName}`"
                    @click.native.stop.prevent="toggle"
                />
            </slot>
        </template>
        <template #option="{ option, select, index }">
            <DropdownItem
                :key="option"
                :isSelected="option === value"
                :title="getItemTitle(option, index)"
                @click="select(option)"
            />
        </template>
    </Selector>
</template>
<script lang="ts">
    import '@eloomi/icons/arrows/arrows-chevron-up-regular';
    import '@eloomi/icons/arrows/arrows-chevron-down-regular';
    import '@eloomi/icons/products-pages/products-and-pages-course-builder-lock-bold-small';

    import { computed, defineComponent, PropType } from 'vue';

    import { Theme } from '@/reporting/utils';
    import DropdownItem from '@/ui-kit/dropdown/items/DropdownItem.vue';
    import Selector from '@/ui-kit/selector/Selector.vue';
    import TextField from '@/ui-kit/text-field/TextField.vue';

    export type DisplayOptionConverter = (option: any, index: number, options: { inList: boolean }) => string;

    export default defineComponent({
        name: 'BasicSelector',
        components: {
            DropdownItem,
            Selector,
            TextField,
        },
        props: {
            options: { type: Array, default: () => [] },
            displayOptionConverter: { type: Function as PropType<DisplayOptionConverter>, default: null },
            value: { default: null },
            placeholder: { type: String, default: '' },
            closeOnSelect: { default: false, type: Boolean },
            isDisabled: { default: false, type: Boolean },
            color: { type: String as PropType<Theme>, default: 'default' },
            size: { type: String as PropType<'small' | 'large'>, default: 'large' },
            testName: { type: String, default: null },
            isLocked: { default: false, type: Boolean },
            renderToPortal: { default: false, type: Boolean },
            preventOutsideClickPropagation: { default: true, type: Boolean },
            iconLeft: { type: String, default: null },
        },
        emits: ['select'],
        setup(props) {
            const stringValue = computed(() => {
                if (props.displayOptionConverter) {
                    const index = props.options?.indexOf(props.value) ?? -1;
                    return props.displayOptionConverter(props.value, index, { inList: false });
                } else {
                    return props.value ? `${props.value}` : '';
                }
            });

            const getItemTitle = (option: any, index: number | string) => {
                return props.displayOptionConverter
                    ? props.displayOptionConverter(option, Number(index), { inList: true })
                    : option;
            };

            const getIconRight = (isOpen: boolean) => {
                if (props.isLocked) {
                    return 'elm-products-and-pages-course-builder-lock-bold-small-icon';
                }

                return isOpen ? 'elm-arrows-chevron-up-regular-icon' : 'elm-arrows-chevron-down-regular-icon';
            };

            return {
                stringValue,
                getItemTitle,
                getIconRight,
            };
        },
    });
</script>

<style scoped lang="less">
    .selector {
        cursor: pointer;

        &.disabled {
            cursor: not-allowed;
        }

        &.locked {
            pointer-events: none;
        }

        ::v-deep .content {
            z-index: 1;
        }
    }
</style>
