import { TwentyThreeVideoApiFactory, VimeoVideoApiFactory } from '@eloomi/eloomi-content-persona-client/1.0';
import type { DependencyContainer } from 'tsyringe';

import { authorizeClient } from '@/api/authorize-client';

import { VideoServiceTokens as Tokens } from './di-tokens';
import { TwentyThreeVideoService } from './services/twenty-three';
import { VideoService } from './services/video';
import { VimeoVideoService } from './services/vimeo';

export function registerVideoServices(container: DependencyContainer): DependencyContainer {
    container.register(Tokens.VimeoApiClient, { useFactory: () => authorizeClient(VimeoVideoApiFactory) });
    container.register(Tokens.TwentyThreeApiClient, { useFactory: () => authorizeClient(TwentyThreeVideoApiFactory) });
    container.register(Tokens.TwentyThreeVideoService, { useClass: TwentyThreeVideoService });
    container.register(Tokens.VimeoVideoService, { useClass: VimeoVideoService });
    container.register(Tokens.VideoServiceProvider, { useClass: VideoService });

    return container;
}
