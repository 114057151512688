import { inject, injectable } from 'tsyringe';

import { AuthenticationServiceTokens as Tokens } from '../di-tokens';
import type { JWT, ResetPasswordService } from '../services';
import { SessionContainer } from '../session/session-container';
import type { LoginUseCase } from './login';
import type { LogoutUseCase } from './logout';

/**
 * User has received a password reset email and wants to reset a password.
 */
@injectable()
export class ResetPasswordUseCase {
    public constructor(
        @inject(Tokens.SessionContainer)
        private readonly sessionContainer: SessionContainer<JWT>,

        @inject(Tokens.ResetPasswordService)
        private readonly authService: ResetPasswordService,

        @inject(Tokens.LogoutUseCase)
        private readonly logoutUseCase: LogoutUseCase,

        @inject(Tokens.LoginUseCase)
        private readonly loginUseCase: LoginUseCase
    ) {}

    public async resetPasswordAndRedirectToDefaultPage(token: JWT, password: string, accountId: string) {
        if (this.sessionContainer.isLoggedIn()) {
            await this.logoutUseCase.logOut(false).catch(console.error);
        }
        await this.authService.resetPassword(token, password);
        await this.loginUseCase.execute({ accountId, password });
    }
}
