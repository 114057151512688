import { InstructorApiFactory } from '@eloomi/eloomi-event-management-persona-client/1.0';

import { authorizeClient } from '@/api/authorize-client';
import { extractAxiosData } from '@/common/services/extract-axios-data';

const sessionInstructorsApiClient = authorizeClient(InstructorApiFactory);

export function getListOfSessionsAuthors() {
    return sessionInstructorsApiClient.getInstructorSessionsAuthors().then(extractAxiosData);
}
