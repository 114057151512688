import { createComposable, createMapper, Module } from 'vuex-smart-module';

import { createUIStoreServiceFactory } from '@/di/store-factory';

import { ToastNotificationActions } from './actions';
import { ToastNotificationGetters } from './getters';
import { ToastNotificationMutations } from './mutations';
import { ToastNotificatonState } from './state';

const notificationStore = new Module({
    state: ToastNotificatonState,
    getters: ToastNotificationGetters,
    mutations: ToastNotificationMutations,
    actions: ToastNotificationActions,
});

export const useNotificationStore = createComposable(notificationStore);

export default notificationStore;
export const notificationStoreMapper = createMapper(notificationStore);

export const ToastNotificationServiceFactory = createUIStoreServiceFactory({
    ...notificationStoreMapper.mapActions(['showNotification', 'showDelayedNotification', 'clearNotifications']),
});

export type ToastNotificationService = ReturnType<typeof ToastNotificationServiceFactory>;
