<template>
    <div class="options-menu">
        <div class="menu-title">
            <MenuBackButton @click="handleBackButtonClick" />
            <h5 class="label-uppercase-info">{{ menuLabel }}</h5>
        </div>

        <elm-text-field
            v-if="searchIsEnabled"
            :value="searchQuery"
            :placeholder="searchFieldPlaceholder"
            class="search-field"
            size="small"
            @elm-input="handleSearchInput"
        >
            <elm-actions-search-icon slot="icon-left" size="18"></elm-actions-search-icon>
        </elm-text-field>

        <DropdownItem
            v-if="showSelectAll && !isSingleSelect"
            :title="trans('spa.admin.dropdown.selectAll')"
            class="select-all-option"
            :isSelected="areAllOptionsSelected"
            withCheckbox
            @click="handleSelectAll"
        ></DropdownItem>

        <div class="divider"></div>

        <ul class="options-list">
            <DropdownItem
                v-for="option of filteredOptions"
                :key="option.value"
                :title="option.label"
                :withCheckbox="!isSingleSelect"
                :class="{ 'is-single-select': isSingleSelect }"
                :isSelected="selectedValues.includes(option.value)"
                @click="handleOptionClick(option)"
            ></DropdownItem>
        </ul>

        <div v-if="withConfirmBtn" class="menu-footer">
            <elm-button size="small" color="primary" @click="handleConfirm">
                {{ trans('spa.admin.course-organization.filters-menu.confirm') }}
            </elm-button>
        </div>
    </div>
</template>

<script lang="ts">
    import '@eloomi/icons/actions/actions-search';
    import '@eloomi/components/text-field';

    import { computed, defineComponent, PropType, Ref, ref, watch } from 'vue';

    import { FilterType, Option } from '@/common/components/filter/interfaces';
    import MenuBackButton from '@/common/components/menu/MenuBackButton.vue';
    import { useTranslation } from '@/common/composables';
    import { DropdownItem } from '@/ui-kit';

    export default defineComponent({
        name: 'FilterOptionsMenu',
        components: {
            DropdownItem,
            MenuBackButton,
        },
        props: {
            options: {
                type: Array as PropType<Option[]>,
                required: true,
            },
            appliedValues: {
                type: Array as PropType<number[]>,
                required: true,
            },
            filterType: {
                type: [String, Number] as PropType<FilterType>,
                required: true,
            },
            isSingleSelect: {
                type: Boolean,
                default: false,
            },
            showSelectAll: {
                type: Boolean,
                default: true,
            },
            searchIsEnabled: {
                type: Boolean,
                default: true,
            },
            withConfirmBtn: {
                type: Boolean,
                default: true,
            },
        },
        setup(props, { emit }) {
            const { trans } = useTranslation();

            const selectedValues: Ref<Array<number | string | boolean>> = ref([]);
            const searchQuery: Ref<string> = ref('');

            const searchFieldPlaceholder = computed(() => {
                return trans('spa.admin.course-organization.filters-menu.search') + '...';
            });
            const menuLabel = computed(() => {
                const total = props.options.length;
                const selectedCount = selectedValues.value.length;
                const variables = { num: selectedCount > 0 ? `${selectedCount}/${total}` : total };
                return trans(
                    `spa.admin.course-organization.filters-menu.options.selected-label.${props.filterType}`,
                    variables
                );
            });
            const areAllOptionsSelected = computed(() => {
                return props.options.length === selectedValues.value.length;
            });
            const filteredOptions = computed(() => {
                const query = searchQuery.value.trim().toLowerCase();
                return props.options.filter(option => {
                    const label = option.label.toLowerCase();
                    return label.includes(query);
                });
            });

            watch(
                () => props.appliedValues,
                () => {
                    selectedValues.value = [...props.appliedValues];
                },
                { immediate: true }
            );

            const handleOptionClick = (option: Option) => {
                const optionIndex = selectedValues.value.indexOf(option.value);
                const isFound = optionIndex !== -1;

                if (isFound) {
                    if (props.isSingleSelect) {
                        selectedValues.value = [];
                    } else {
                        selectedValues.value.splice(optionIndex, 1);
                    }
                } else {
                    if (props.isSingleSelect) {
                        selectedValues.value = [option.value];
                    } else {
                        selectedValues.value.push(option.value);
                    }
                }
                if (!props.withConfirmBtn) {
                    handleConfirm();
                }
            };
            const handleSelectAll = () => {
                selectedValues.value = areAllOptionsSelected.value ? [] : props.options.map(option => option.value);
            };
            const handleSearchInput = (query: CustomEvent) => {
                searchQuery.value = (query.target as HTMLTextAreaElement).value;
            };
            const handleBackButtonClick = () => {
                emit('back');
            };
            const handleConfirm = () => {
                emit('confirm', [...selectedValues.value]);
            };

            return {
                trans,
                selectedValues,
                searchQuery,
                searchFieldPlaceholder,
                menuLabel,
                areAllOptionsSelected,
                filteredOptions,
                handleOptionClick,
                handleSelectAll,
                handleSearchInput,
                handleBackButtonClick,
                handleConfirm,
            };
        },
    });
</script>

<style scoped lang="less">
    .options-menu {
        display: flex;
        flex-direction: column;
        width: 320px;
        max-height: 100%;
    }

    .menu-title {
        .flex-center-content-vertically();

        padding: @spacing-20 @spacing-24 @spacing-14 @spacing-24;
    }

    .select-all-option {
        flex-shrink: 0;
        margin-top: @spacing-4;
    }

    .divider {
        height: 1px;
        margin-top: @spacing-4;
        background-color: @info-color-8;
    }

    .options-list {
        @list-item-height: 48px;

        max-height: calc(@list-item-height * 3);
        margin: 0;
        margin-top: @spacing-4;
        padding: 0;
        overflow: auto;
        list-style: none;

        @media screen and (min-height: 750px) {
            max-height: calc(@list-item-height * 5);
        }

        @media screen and (min-height: 900px) {
            max-height: calc(@list-item-height * 7);
        }
    }

    .menu-footer {
        display: flex;
        justify-content: flex-end;
        padding: @spacing-16;
    }

    .is-selected.is-single-select {
        color: @primary-color;
        background: @primary-color-8;
    }
</style>
