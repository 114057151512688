<template>
    <Popover ref="popover" portal="toast-notifications-host" placement="bottom-end" @blur="closeNotificationsDrawer">
        <template #target="{ isVisible }">
            <NotificationTrigger
                :isActive="isVisible"
                :newNotifications="newNotificationsCount"
                @click.native="toggle"
            />
        </template>
        <template #content>
            <NotificationPanel class="panel" @endReached="loadMore">
                <template v-if="notifications && notifications.length">
                    <Notification
                        v-for="notification in notifications"
                        :key="notification.id"
                        :text="getNotificationText(notification)"
                        :imageUrl="notification.image_url"
                        :isRead="notification.is_read"
                        :receivedAt="notification.created_at"
                        @click="redirectFromNotification(notification)"
                    />
                </template>
                <template v-else>
                    <div class="notifications-empty">
                        <elm-alerts-info-notification-icon size="32" />
                        <p class="notifications-empty-text">{{ trans('spa.notification.no-notifications') }}</p>
                    </div>
                </template>
            </NotificationPanel>
        </template>
    </Popover>
</template>

<script lang="ts">
    import '@eloomi/icons/alerts-info/alerts-info-notification';
    import Notification from './Notification.vue';
    import NotificationPanel from './NotificationPanel.vue';
    import NotificationTrigger from './NotificationTrigger.vue';
    import { Popover } from '@/ui-kit';
    import { defineComponent, ref, watch, onMounted } from 'vue';
    import { usePushNotifications } from '@/notifications/use-push-notifications';
    import { useTranslation } from '@/common/composables';

    export default defineComponent({
        components: { Notification, NotificationPanel, NotificationTrigger, Popover },
        setup() {
            const popover = ref<InstanceType<typeof Popover> | null>(null);
            const { store, ...notifications } = usePushNotifications();

            watch(
                () => store.state.notificationsDrawerVisible,
                () => {
                    if (store.state.notificationsDrawerVisible) {
                        popover.value?.show();
                    } else {
                        popover.value?.hide();
                    }
                }
            );

            onMounted(() => {
                if (store.state.notificationsDrawerVisible) {
                    popover.value?.show();
                }
            });

            const toggle = () => {
                if (store.state.notificationsDrawerVisible) {
                    store.mutations.closeNotificationsDrawer();
                } else {
                    store.mutations.openNotificationsDrawer();
                }
            };

            const onClose = () => {
                store.mutations.closeNotificationsDrawer();
            };

            return {
                onClose,
                popover,
                toggle,
                ...notifications,
                ...useTranslation(),
            };
        },
    });
</script>
<style lang="less" scoped>
    .panel {
        min-width: 400px;
        max-width: 496px;
    }

    .notifications-empty {
        .flex-center-content;

        flex-direction: column;
        padding: @spacing-56;
        color: @info-color;
    }

    .notifications-empty-text {
        .info-line;
        .info-color;

        margin-top: @spacing-12;
    }
</style>
