import { inject, injectable } from 'tsyringe';
import type VueRouter from 'vue-router';
import type { NavigationGuardNext, Route } from 'vue-router';

import { ServiceTokens } from '@/di/tokens';
import { ErrorRouteNames } from '@/main/errorRoutes';

import type { Permission, RouteWithPermissions } from '../domain';
import { RouteHandler, Router } from '../use-cases/ports';

export function vueRouteToRouteWithPermissions(vueRoute: Route): RouteWithPermissions {
    const permissions = new Set<Permission>();

    const addToPermissions = (permissionArray: string[]) => {
        for (const permission of permissionArray) permissions.add(permission as Permission);
    };

    for (const vueRouteRecord of vueRoute.matched) {
        if (Array.isArray(vueRouteRecord.meta.requiredPermissions)) {
            addToPermissions(vueRouteRecord.meta.requiredPermissions);
        }
    }

    addToPermissions(vueRoute.meta?.requiredPermissions ?? []);

    return {
        path: vueRoute.path,
        requiredPermissions: [...permissions],
    };
}

@injectable()
export class VueRouterAdapter implements Router {
    public constructor(@inject(ServiceTokens.VueRouter) private readonly router: VueRouter) {}

    public addBeforeEachRouteHandler(handler: RouteHandler) {
        this.router.beforeEach((vueRoute, _, goNext) => {
            const route = vueRouteToRouteWithPermissions(vueRoute);
            return handler(route, goNext);
        });
    }

    public handle404(goNext: NavigationGuardNext) {
        /**
         * Workaround with `params` for the vur-router error
         * @see https://github.com/vuejs/vue-router/issues/724
         */
        goNext({ name: ErrorRouteNames.NOT_FOUND, params: { '0': '' } });
    }
}
