import { UserProfile } from '@/common/interfaces/user-profile.interface';

const CACHE_KEY = 'user-details';
const REPORTING_MANAGER_CACHE_KEY = 'user-reporting-manager-status';

export function getCacheUserDetailsData() {
    const cachedData = localStorage.getItem(CACHE_KEY);

    return cachedData !== null ? (JSON.parse(cachedData) as UserProfile) : null;
}

export function cacheUserDetailsData(data: UserProfile) {
    const stringify = JSON.stringify(data);

    return localStorage.setItem(CACHE_KEY, stringify);
}

export function getCacheUserReportingManagerStatus() {
    const cachedData = localStorage.getItem(REPORTING_MANAGER_CACHE_KEY);

    return cachedData !== null ? (JSON.parse(cachedData) as boolean) : null;
}

export function cacheUserReportingManagerStatus(isReportingManager: boolean) {
    const stringify = JSON.stringify(isReportingManager);

    return localStorage.setItem(REPORTING_MANAGER_CACHE_KEY, stringify);
}

export function clearUserDetailsDataFromLocalStorage() {
    localStorage.removeItem(CACHE_KEY);
}
