import { UserResponse as UserInstructorResponse } from '@eloomi/eloomi-event-management-persona-client/1.0/';
import { UserResponse } from '@eloomi/eloomi-users-persona-client/1.0';

export function getFullName(
    user?: {
        firstName?: string | null;
        lastName?: string | null;
        first_name?: string | null;
        last_name?: string | null;
        author_first_name?: string | null;
        author_last_name?: string | null;
        user_first_name?: string | null;
        user_last_name?: string | null;
    } | null
) {
    return [
        user?.firstName || user?.first_name || user?.author_first_name || user?.user_first_name,
        user?.lastName || user?.last_name || user?.author_last_name || user?.user_last_name,
    ]
        .filter(Boolean)
        .join(' ');
}
export function getUsersIds(users: UserInstructorResponse[] | UserResponse[] | undefined | null) {
    return users && users.map(user => user.id ?? user.user_id);
}
