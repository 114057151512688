import { RouteConfig } from 'vue-router';

import { mobileIsUnsupportedFlag } from '@/common/services/mobile-fallback';
import { AppFeaturesList } from '@/launch-darkly/models';
import { checkIfAvailableByLaunchDarkly } from '@/launch-darkly/routes/launch-darkly.guard';
import { Permissions } from '@/permissions';

const RolesOverviewPage = () => import('@/admin/roles/pages/RolesOverviewPage.vue');
const RolesCreateEditPage = () => import('@/admin/roles/pages/CreateEditRolePage.vue');

export const enum AdminRolesRoutes {
    Roles = 'organization-roles',
    RoleEdit = 'role-edit',
    RoleCreate = 'role-create',
}

const routes: RouteConfig[] = [
    {
        path: 'roles',
        name: AdminRolesRoutes.Roles,
        meta: {
            title: 'Roles',
            ...mobileIsUnsupportedFlag,
            showNavigationSidebar: true,
            requiredPermissions: [Permissions.AdminRoles],
        },
        beforeEnter: checkIfAvailableByLaunchDarkly(AppFeaturesList.ADMIN_ROLES_NAVIGATION),
        component: RolesOverviewPage,
    },
    {
        path: 'roles/:id(\\d+)/edit',
        name: AdminRolesRoutes.RoleEdit,
        meta: {
            title: 'Edit role',
            ...mobileIsUnsupportedFlag,
            defaultBackRoute: AdminRolesRoutes.Roles,
            requiredPermissions: [Permissions.AdminRoles],
            showNavigationSidebar: false,
        },
        beforeEnter: checkIfAvailableByLaunchDarkly(AppFeaturesList.ADMIN_ROLES_NAVIGATION),
        component: RolesCreateEditPage,
    },
    {
        path: 'roles/create',
        name: AdminRolesRoutes.RoleCreate,
        meta: {
            title: 'Create Role',
            ...mobileIsUnsupportedFlag,
            defaultBackRoute: AdminRolesRoutes.Roles,
            requiredPermissions: [Permissions.AdminRoles],
        },
        beforeEnter: checkIfAvailableByLaunchDarkly(AppFeaturesList.ADMIN_ROLES_NAVIGATION),
        component: RolesCreateEditPage,
    },
];

export default routes;
