export interface Rule {
    id: number;
    user_group_id: number;
    user_group_name: string | null;
    order: number;
    definition: RuleDefinition;
}

export interface UserGroupRuleParameter {
    key: string | null;
    label: string | null;
    is_dynamic: boolean;
    operators: OperatorPair[];
    values: string[] | null;
    values_source_url: string | null;
    field_id: number | null;
}

export interface ValueCondition {
    field: string;
    field_id: number | null;
    operator: ValueOperator;
    value: {
        key: string | null;
        value: string | null;
    };
}

export interface RangeCondition {
    field: string;
    field_id: number | null;
    operator: RangeOperator;
    value: {
        key: string | null;
        value: RangeValue | null;
    };
}

export type Condition = ValueCondition | RangeCondition;

export type ConditionValue = RangeValue | string | null;
export interface OperatorPair {
    operator: Operator;
    type: InputType;
}
export type ValueOperator = 'equals' | 'notEquals' | 'contains' | 'notContains' | 'lessThan' | 'greaterThan';
export type RangeOperator = 'between' | 'notBetween';
export type Operator = ValueOperator | RangeOperator;
export type Separator = 'and' | 'or';
export type InputType = 'staticList' | 'textInput' | 'dateTimeValue' | 'dateTimeRange';
export type RangeValue = { start: string; end: string };

export interface RuleDefinition {
    separator: Separator;
    conditions: Condition[] | null;
}

export function isRangeCondition(condition: Condition): condition is RangeCondition {
    return condition.operator == 'between' || condition.operator == 'notBetween';
}

export function isValueCondition(condition: Condition): condition is ValueCondition {
    return !isRangeCondition(condition);
}

export interface UnmappedParameter {
    field: 'email';
    field_id: null;
    label: string | null;
    operators: OperatorPair[] | null;
    values: Array<string> | null;
    values_source_url?: string | null;
    is_dynamic?: boolean;
}

export interface ConditionSelectorValue {
    fieldKey: string | null;
    operator: OperatorPair | null;
    valueKey: RangeValue | string | null;
    fieldId: number | null;
}
export type ViewState = 'select-field' | 'select-value';
export type KeyMapper<T = unknown> = (value, options?: T, parameter?: OperatorPair) => string;

export interface GetRuleParametersResponse {
    parameters: UserGroupRuleParameter[] | null;
}

export function mapParameters(parameters: UnmappedParameter[]): GetRuleParametersResponse {
    return {
        parameters: parameters.map(parameter => {
            return {
                key: parameter.field ?? null,
                is_dynamic: parameter.is_dynamic ?? false,
                label: parameter.label ?? null,
                operators: parameter.operators ?? [],
                values: parameter.values ?? [],
                values_source_url: parameter.values_source_url ?? null,
                field_id: parameter.field_id ?? null,
            };
        }),
    };
}
