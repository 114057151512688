<template>
    <div class="desktop-header">
        <CompanyLogo class="logo" :link="{ path: topNavigation.logo.route }" :image="brandingLogoUrl" />
        <CoreMenu
            mode="horizontal"
            :menuItems="topNavigation.main"
            class="desktop-menu"
            data-testid="main-header-menu"
        />
        <div class="user-actions-block">
            <ChoosePlanButton v-if="isChoosePlanButtonVisible" :expirationDate="expirationDate" class="plan-button" />
            <NotificationMenu v-if="isNotificationMenuVisible" class="notification-menu" />
            <CoreUserMenu v-if="authUserProfile" class="desktop-user-menu" :menuItems="topNavigation.user" />
        </div>
    </div>
</template>

<script lang="ts">
    import { computed, defineComponent } from 'vue';

    import ChoosePlanButton from '@/admin/billing/components/ChoosePlanButton.vue';
    import { useSubscriptionStore } from '@/admin/billing/store';
    import { PredefinedAccessUserRoles } from '@/admin/users/providers/user-access-roles';
    import CompanyLogo from '@/common/components/app-header/CompanyLogo.vue';
    import { useTopNavigation } from '@/common/components/app-header/useTopNavigation';
    import CoreMenu from '@/common/components/menu/CoreMenu.vue';
    import CoreUserMenu from '@/common/components/menu/CoreUserMenu.vue';
    import { useMobile, useRoute } from '@/common/composables';
    import { useBrandingStore } from '@/common/store/branding/store';
    import { useUserProfileDetailsStore } from '@/common/store/user-details/store';
    import NotificationMenu from '@/notifications/components/NotificationMenu.vue';
    import { usePlanFeaturesStore } from '@/plan-features/store';

    export default defineComponent({
        name: 'DesktopHeader',
        components: { CompanyLogo, CoreMenu, ChoosePlanButton, NotificationMenu, CoreUserMenu },
        setup() {
            const route = useRoute();
            const { isMobile } = useMobile();
            const topNavigation = useTopNavigation();
            const brandingStore = useBrandingStore();
            const subscriptionStore = useSubscriptionStore();
            const userProfileDetailsStore = useUserProfileDetailsStore();
            const authUserProfile = computed(() => userProfileDetailsStore.state.authUserProfile);

            const isNotificationMenuVisible = computed(() => {
                return !isMobile.value;
            });

            const expirationDate = computed(() => subscriptionStore.state.expirationDate);

            const {
                getters: { checkIfFeatureEnabled },
                state: { featuresList },
            } = usePlanFeaturesStore();

            const isChoosePlanButtonVisible = computed(() => {
                if (isMobile.value || !checkIfFeatureEnabled(featuresList.BILLING)) {
                    return false;
                }
                const isAdmin = authUserProfile.value?.infiniteRoleKey === PredefinedAccessUserRoles.ADMIN;
                const isFreePlan = subscriptionStore.state.activeSubscription?.is_free_plan;
                const withExpirationDate = expirationDate.value;
                const isChoosePlanButtonVisible = route.meta?.isChoosePlanButtonVisible ?? true;
                const isVisible = isAdmin && isChoosePlanButtonVisible;
                return isVisible ? subscriptionStore.state.isPlatformFree || withExpirationDate : isFreePlan;
            });

            const brandingLogoUrl = computed(() => brandingStore.state.brandingState?.logo_url);

            return {
                topNavigation,
                isChoosePlanButtonVisible,
                isNotificationMenuVisible,
                brandingLogoUrl,
                expirationDate,
                authUserProfile,
            };
        },
    });
</script>
<style lang="less" scoped>
    .desktop-menu,
    .desktop-user-menu {
        z-index: @header-menu-items-z-index;
    }

    .desktop-user-menu {
        cursor: pointer;
    }

    .plan-button {
        margin-right: @spacing-40;
    }

    .desktop-header {
        height: @core-header-height;
        padding: @spacing-24 @spacing-48;
        background-color: @branding-color;

        .flex-center-content-vertically();
    }

    .notification-menu {
        margin-right: @spacing-16;
    }

    .logo {
        margin-right: @spacing-52;
    }

    .user-actions-block {
        display: flex;
        align-items: center;
        height: @core-header-height;
        margin-left: auto;
    }
</style>
