import type { Permission, UserWithPermissions } from '../domain';

export class UserPermissionChecker {
    public constructor(private readonly user: UserWithPermissions) {}

    public hasPermission(permission: Permission) {
        return this.user.hasPermission(permission);
    }

    public hasAnyPermission(...permissions: Permission[]) {
        return permissions.some(permission => this.user.hasPermission(permission));
    }

    public hasEveryPermission(...permissions: Permission[]) {
        return permissions.every(permission => this.user.hasPermission(permission));
    }
}

export type PermissionChecker = UserPermissionChecker;
