/* eslint-disable vue/one-component-per-file */
import { NotificationResponse } from '@eloomi/eloomi-notifications-persona-client/1.0';
import { Vue } from 'vue-property-decorator';
import { Actions, createComposable, createMapper, Getters, Module, Mutations } from 'vuex-smart-module';

import defaultFaviconImageUrl from '@/assets/img/eloomi-favicon.png';
import { makeLogger } from '@/common/services';
import brandingStore from '@/common/store/branding/store';
import { getBaseStore } from '@/main/get-base-store-router';
import { getNotificationRoute, getNotificationText } from '@/notifications/services/misc';
import translationsStore from '@/translations/store';

import { notificationsClient } from '../providers';

let logger = {
    log: _msg => {
        // not available in storybook
    },
};

try {
    logger = makeLogger('notifications');
} catch {
    console.error('makeLogger is not available');
}

const browserSupportsNotificationAPI = 'Notification' in window;

class State {
    public notifications: NotificationResponse[] = [];
    public newNotifications = 0;
    public currentPage = 1;
    public lastPage: number | null = null;
    public pageSize = 10;
    public promisePending = false;
    public notificationsInitialized = false;
    public notificationsInterval: NodeJS.Timer | null = null;
    public notificationsDrawerVisible = false;
}

class StoreGetters extends Getters<State> {
    public get notifications() {
        return this.state.notifications;
    }
    public get newNotificationsCount() {
        return this.state.newNotifications;
    }
}

class StoreMutations extends Mutations<State> {
    public openNotificationsDrawer() {
        this.state.notificationsDrawerVisible = true;
    }
    public closeNotificationsDrawer() {
        this.state.notificationsDrawerVisible = false;
    }
    public setNotificationAsRead(id: number) {
        const notificationToMartAsRead = this.state.notifications.find(n => n.id === id);
        notificationToMartAsRead && (notificationToMartAsRead.is_read = true);
    }
    public setAllNotificationAsRead() {
        for (const ntf of this.state.notifications) ntf.is_read = true;
    }
    public setNewNotificationsCount(count) {
        this.state.newNotifications = count;
    }
    public loadNotifications(notifications) {
        this.state.notifications = [...this.state.notifications, ...notifications];
    }
    public setNotifications(notifications: NotificationResponse[]) {
        this.state.notifications = notifications;
    }
    public nextPage() {
        this.state.currentPage += 1;
    }
    public setLastPage(page) {
        this.state.lastPage = page;
    }
    public setPromisePending(isPending) {
        this.state.promisePending = isPending;
    }
    public resetState() {
        this.state.currentPage = 1;
        this.state.lastPage = null;
    }
    public setNotificationsInitialized(notificationsInitialized) {
        this.state.notificationsInitialized = notificationsInitialized;
    }
    public setInterval(interval) {
        this.state.notificationsInterval = interval;
    }
}

class StoreActions extends Actions<State, StoreGetters, StoreMutations, StoreActions> {
    private async fetchNotifications() {
        const { data } = await notificationsClient.getUsersNotifications(
            undefined,
            '-created_at',
            this.state.currentPage,
            this.state.pageSize
        );
        return data;
    }

    public async markAllNotificationsAsRead() {
        try {
            await notificationsClient.updateNotificationAllRead();
            this.mutations.setNewNotificationsCount(0);
            this.mutations.setAllNotificationAsRead();
        } catch {
            logger.log('En error occurred when marking all notifications as read.');
        }
    }

    public async getNewNotificationsCount() {
        try {
            const newNotificationsCount = await notificationsClient.getUsersNotificationsNumber();
            this.mutations.setNewNotificationsCount(newNotificationsCount.data);
        } catch (error) {
            console.error(error);
        }
    }

    public async getNotifications() {
        const shouldLoadNotifications = this.state.lastPage === null || this.state.currentPage <= this.state.lastPage;
        if (shouldLoadNotifications && this.state.promisePending === false) {
            this.mutations.setPromisePending(true);
            const notifications = await this.actions.fetchNotifications();
            this.mutations.setPromisePending(false);
            this.mutations.nextPage();
            this.mutations.setLastPage(notifications.last_page);
            if (notifications.data) {
                this.mutations.loadNotifications([...notifications.data]);
            }
        }
    }

    public async resetAndGetNotifications() {
        this.mutations.resetState();
        this.mutations.setPromisePending(true);
        const notifications = await this.actions.fetchNotifications();
        if (notifications.data) {
            this.mutations.setNotifications(notifications.data);
            this.mutations.nextPage();
        }
        this.mutations.setPromisePending(false);
    }

    public async setNotificationAsRead(notification: NotificationResponse) {
        if (notification.id && notification.is_read === false) {
            await notificationsClient.updateNotificationRead(notification.id, true);
            await this.actions.getNewNotificationsCount();
            this.mutations.setNotificationAsRead(notification.id);
        }
    }
    public async initializeNotificationFeature() {
        if (this.state.notificationsInitialized === false) {
            if (browserSupportsNotificationAPI && Notification.permission !== 'granted') {
                Notification.requestPermission();
            }
            this.mutations.setNotificationsInitialized(true);
            await this.actions.getNewNotificationsCount();
            await this.actions.getNotifications();
            this.mutations.setInterval(setInterval(this.actions.checkForNewNotifications, 180_000));
        }
    }

    public notifyUser(newNotificationsCount) {
        if (browserSupportsNotificationAPI === false) {
            return;
        }
        const baseStore = getBaseStore();
        const trans = translationsStore.context(baseStore).getters.trans;
        if (Notification.permission === 'granted') {
            const notificationText =
                newNotificationsCount > 1
                    ? trans('spa.notification.new-notifications', { num: newNotificationsCount })
                    : getNotificationText(this.state.notifications[0]);
            const browserNotification = new Notification(trans('spa.notification.message-from-eloomi'), {
                icon: brandingStore.context(baseStore).state.brandingState?.favicon_url || defaultFaviconImageUrl,
                body: notificationText,
            });
            const notification = this.state.notifications[0];
            const { mutations } = this;
            browserNotification.addEventListener('click', async function () {
                window.focus();
                if (newNotificationsCount === 1) {
                    const route = getNotificationRoute(notification);
                    if (route) {
                        const router = await import('@/main/base-router');
                        router.default.push(route);
                    }
                } else {
                    mutations.openNotificationsDrawer();
                }
            });
        }
    }

    public async checkForNewNotifications() {
        try {
            const notificationsNumberResponse = await notificationsClient.getUsersNotificationsNumber();
            const newNotificationsCount = notificationsNumberResponse.data;
            const newNotificationsCountDiff = newNotificationsCount - this.getters.newNotificationsCount;

            if (newNotificationsCount !== this.getters.newNotificationsCount) {
                await this.mutations.setNewNotificationsCount(newNotificationsCount);
                await this.actions.resetAndGetNotifications();
            }
            if (newNotificationsCountDiff > 0 && document.hidden) {
                this.actions.notifyUser(newNotificationsCountDiff);
            }
        } catch (error) {
            console.error(error);
        }
    }
}

const notificationsStore = new Module({
    state: State,
    mutations: StoreMutations,
    getters: StoreGetters,
    actions: StoreActions,
});

export default notificationsStore;

export const notificationsStoreMapper = createMapper(notificationsStore);
export const NotificationsMappedStore = Vue.extend({
    computed: {
        ...notificationsStoreMapper.mapState(['notificationsInitialized', 'notificationsDrawerVisible']),
        ...notificationsStoreMapper.mapGetters(['notifications', 'newNotificationsCount']),
    },
    methods: {
        ...notificationsStoreMapper.mapActions([
            'getNewNotificationsCount',
            'getNotifications',
            'setNotificationAsRead',
            'resetAndGetNotifications',
            'initializeNotificationFeature',
            'markAllNotificationsAsRead',
        ]),
        ...notificationsStoreMapper.mapMutations([
            'setNewNotificationsCount',
            'setNotificationsInitialized',
            'openNotificationsDrawer',
            'closeNotificationsDrawer',
        ]),
    },
});

export const NotificationsFeatureInitializer = Vue.extend({
    methods: {
        ...notificationsStoreMapper.mapActions(['initializeNotificationFeature']),
    },
});

export const usePushNotificationStore = createComposable<typeof notificationsStore>(notificationsStore);
