import type { PlatformResolutionService } from '@/common/services';
import { baseContainer } from '@/di/container';
import { ServiceTokens } from '@/di/tokens';

export async function getRegionalBaseUri(): Promise<string> {
    const platformResolutionService = baseContainer.resolve<PlatformResolutionService>(
        ServiceTokens.PlatformResolutionService
    );

    const platformData = await platformResolutionService.getPlatformData();
    return platformData.base_api_url;
}
