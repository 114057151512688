import { Route, RouteConfig } from 'vue-router';

import MobileModeStore from '@/common/store/mobile-mode';
import { CoursesRouteNames } from '@/courses/route-definitions';
import { getBaseStore } from '@/main/get-base-store-router';
import PlanFeaturesList from '@/plan-features/plan-features-list';
import { checkIfRouteAvailableByPlanFeatures } from '@/plan-features/routes';

const COURSES_DASHBOARD_PAGE = () => import('./pages/CourseDashboardPage.vue');
const COURSE_PLAYER_ROOT = () => import('./pages/CoursePlayerRootPage.vue');
const COURSE_OVERVIEW_PAGE = () => import('./pages/CourseOverviewPage.vue');
const COURSE_PLAYER_PAGE = () => import('./pages/CoursePlayerPage.vue');
const COURSE_SCORM_PLAYER_ROOT = () => import('./pages/CourseScormPlayerPage.vue');
const ALL_COURSES_OVERVIEW_PAGE = () => import('./pages/all-courses-overview/AllCoursesOverviewPage.vue');
const PLAYLIST_OVERVIEW_PAGE = () => import('./pages/PlaylistOverviewPage.vue');

const isMobile = () => {
    return MobileModeStore.context(getBaseStore()).state.isMobile;
};

const getBackRouteFromPlayer = (currentRoute: Route) => {
    return isMobile()
        ? {
              name: CoursesRouteNames.ALL_COURSES_OVERVIEW,
          }
        : {
              name: CoursesRouteNames.COURSE_OVERVIEW,
              params: {
                  course_id: currentRoute.params.course_id,
              },
              query: currentRoute.query,
          };
};

const routes: RouteConfig[] = [
    {
        path: `courses/overview`,
        name: CoursesRouteNames.ALL_COURSES_OVERVIEW,
        meta: {
            title: 'Courses',
        },
        component: ALL_COURSES_OVERVIEW_PAGE,
    },
    {
        path: `courses/:course_id(\\d+)`,
        meta: {
            title: 'Course',
            defaultBackRoute: CoursesRouteNames.ALL_COURSES_OVERVIEW,
        },
        component: COURSE_PLAYER_ROOT,
        beforeEnter: checkIfRouteAvailableByPlanFeatures(PlanFeaturesList.COURSES),
        children: [
            {
                path: `overview`,
                name: CoursesRouteNames.COURSE_OVERVIEW,
                meta: {
                    title: 'Course',
                    defaultBackRoute: (currentRoute: Route) =>
                        currentRoute.query.playlist_id
                            ? {
                                  name: CoursesRouteNames.PLAYLIST_OVERVIEW,
                                  params: {
                                      playlist_id: currentRoute.query.playlist_id,
                                  },
                              }
                            : {
                                  name: CoursesRouteNames.ALL_COURSES_OVERVIEW,
                              },
                },
                alias: '',
                component: COURSE_OVERVIEW_PAGE,
            },
            {
                path: `chapter/:chapter_id(\\d+)/page/:page_id(\\d+)`,
                name: CoursesRouteNames.COURSE_PLAYER,
                meta: {
                    title: 'Page',
                    defaultBackRoute: getBackRouteFromPlayer,
                },
                component: COURSE_PLAYER_PAGE,
            },
            {
                path: `player`,
                name: CoursesRouteNames.COURSE_SCORM_PLAYER,
                meta: {
                    title: 'Course',
                    defaultBackRoute: getBackRouteFromPlayer,
                },
                component: COURSE_SCORM_PLAYER_ROOT,
            },
        ],
    },
    {
        path: `playlists/:playlist_id(\\d+)/overview`,
        name: CoursesRouteNames.PLAYLIST_OVERVIEW,
        meta: {
            title: 'Playlist',
            defaultBackRoute: CoursesRouteNames.ALL_COURSES_OVERVIEW,
        },
        component: PLAYLIST_OVERVIEW_PAGE,
        beforeEnter: checkIfRouteAvailableByPlanFeatures(PlanFeaturesList.PLAYLISTS),
    },
    {
        path: `courses`,
        name: CoursesRouteNames.COURSES_DASHBOARD,
        meta: {
            title: 'Courses',
        },
        component: COURSES_DASHBOARD_PAGE,
    },
];

export default routes;
