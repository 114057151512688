import { Vue } from 'vue-property-decorator';

import { notificationStoreMapper, useNotificationStore } from './store';
import { ToastNotificationActions } from './store/actions';

export default Vue.extend({
    computed: {
        ...notificationStoreMapper.mapState(['notifications']),
    },
    methods: {
        ...notificationStoreMapper.mapActions(['showNotification', 'showDelayedNotification', 'clearNotifications']),
    },
});
export function useNotifications() {
    const notificationStore = useNotificationStore();

    return {
        showNotification: notificationStore.actions.showNotification as InstanceType<
            typeof ToastNotificationActions
        >['showNotification'],
        showDelayedNotification: notificationStore.actions.showDelayedNotification as InstanceType<
            typeof ToastNotificationActions
        >['showDelayedNotification'],
        clearNotifications: notificationStore.actions.clearNotifications as InstanceType<
            typeof ToastNotificationActions
        >['clearNotifications'],
        notifications: notificationStore.state.notifications,
    } as const;
}
