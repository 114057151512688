import CoreCardLayout from '@/common/layouts/CoreCardLayout.vue';
import CoreFlexFullWidthLayout from '@/common/layouts/CoreFlexFullWidthLayout.vue';
import CoreMainLayout from '@/common/layouts/CoreMainLayout.vue';

export default {
    install(vue: any) {
        vue.component('CoreMainLayout', CoreMainLayout);
        vue.component('CoreCardLayout', CoreCardLayout);
        vue.component('CoreFlexFullWidthLayout', CoreFlexFullWidthLayout);
    },
};
