import {
    CourseSkillAdminApiFactory,
    CourseSkillApiFactory,
    EmsiSkillItem,
} from '@eloomi/eloomi-learning-persona-client/1.0';
import { SkillsApiFactory } from '@eloomi/eloomi-skills-persona-client/1.0';

import { authorizeClient } from '@/api/authorize-client';
import { DataRequestOptions } from '@/common/components/table';
import { buildFilter } from '@/common/services';
import { extractAxiosData } from '@/common/services/extract-axios-data';

const courseSkillClient = authorizeClient(CourseSkillApiFactory);
const courseSkillAdminClient = authorizeClient(CourseSkillAdminApiFactory);
const skillsAdminClient = authorizeClient(SkillsApiFactory);

export async function getCourseSkills(courseId: number) {
    return courseSkillClient.getCourseSkills(courseId).then(extractAxiosData);
}

export async function addCourseSkills(courseId: number, skills: EmsiSkillItem[]) {
    return courseSkillAdminClient.createCourseSkills(courseId, { skills }).then(({ data }) => data);
}

export async function removeSkill(courseId: number, skillId: number) {
    return courseSkillAdminClient.deleteCourseSkill(courseId, skillId);
}

export async function getSkillsLite(options: DataRequestOptions = { page: 1, pageSize: 10 }) {
    return skillsAdminClient
        .getSkillsLite(buildFilter('name', options.searchQuery), 'name', options.page, options.pageSize)
        .then(extractAxiosData);
}
