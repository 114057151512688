import { Capacitor } from "@capacitor/core";
import { randomId } from "../random-id";

/** Keeps track of all actions that should be performed on physical back button press. history.back() will be called only if this stack is empty. */
class BackButtonActionStack {
    private actions: ({
        id: string,
        /** Action to be called when back button is pressed. If action returns 'false', it will not be removed from the stack yet. */
        action: (() => boolean | void)
    })[] = [];

    /** Push action on the stack. Returns callback that allows for removal of the action even if it is not on the top of the stack. */
    public pushAction(cleanup: () => boolean | void): (() => void) {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        if (!Capacitor.isNativePlatform()) return () => {  };

        const uuid = randomId();
        this.actions.push({
            id: uuid, 
            action: cleanup
        });

        return () => {
            this.actions = this.actions.filter(x => x.id != uuid);
        }
    }

    /** Executes action and removes it from the stack if action return value !== false */
    public popAction() {
        if (!Capacitor.isNativePlatform()) return;

        if (this.actions.length == 0) return;

        const data = this.actions[this.actions.length - 1];
        const result = data.action();
        if (result !== false) {
            this.actions = this.actions.filter(x => x.id != data.id);
        }
    }    

    public isEmpty() {
        return this.actions.length === 0;
    }
}

/** Native-back button action stack. Overrides HTML navigation. */
export const backButtonService =  new BackButtonActionStack();