import { Location, Route } from 'vue-router';

import { InvalidTokenParamError } from '@/authentication/services/errors';
import { JWT, makeSSOOrigin } from '@/authentication/services/interfaces';
import { DEFAULT_PAGE_LOCATION, LOGIN_PAGE_LOCATION } from '@/authentication/services/locations';
import { makeLogger } from '@/common/services/debug-helper';
import { baseContainer } from '@/di/container';

import { AuthenticationServiceTokens } from '../di-tokens';
import { SessionContainer } from '../session/session-container';
import { DeepLinkRedirector } from './deep-link-redirector';

export class InstantLoginRedirectionService {
    private readonly log = makeLogger('sso');
    private deepLinkRedirector = new DeepLinkRedirector();

    private parseAuthData(route: Route) {
        const query = route.query;
        const token = query.token;
        const origin = makeSSOOrigin(query.origin);

        if (typeof token === 'string' && token.length > 0) {
            return { token, origin };
        }

        throw new InvalidTokenParamError();
    }

    redirect(currentRoute: Route): Location {
        try {
            const { token, origin } = this.parseAuthData(currentRoute);
            this.log('catched instant login of origin %o', origin);

            if (origin === 'web') {
                const sessionContainer = baseContainer.resolve<SessionContainer<JWT>>(
                    AuthenticationServiceTokens.SessionContainer
                );
                sessionContainer.startSession(token as JWT);
                return { ...DEFAULT_PAGE_LOCATION, query: {} };
            } else {
                const url = this.deepLinkRedirector.getInstantLoginLink(token);
                this.deepLinkRedirector.redirect(url);
            }
        } catch (error) {
            console.error(error);
            return { ...LOGIN_PAGE_LOCATION, query: {} };
        }

        return { ...LOGIN_PAGE_LOCATION, query: {} };
    }
}
