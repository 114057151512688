<template>
    <a
        :href="route.fullPath"
        class="back-button"
        :class="{ floating: isFloating }"
        data-testid="go-back-button"
        @click.stop.prevent="handleBackClick"
    >
        <div class="back-button-wrapper">
            <slot>
                <elm-arrows-arrow-left-bold-icon size="18" class="info-color" />
            </slot>
        </div>
    </a>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { Location, Route } from 'vue-router';
    import '@eloomi/icons/arrows/arrows-arrow-left-bold';
    import IconButton from '@/ui-kit/buttons/IconButton.vue';

    @Component({
        components: {
            IconButton,
        },
    })
    export default class GoBackButton extends Vue {
        @Prop({ default: 23 }) public backIconSize!: number;
        @Prop({}) public defaultBackRoute?: string | Location;
        @Prop({ default: false, type: Boolean }) public isFloating!: boolean;
        /** Action called on back action. Default navigation is cancelled when 'true' is returned. */
        @Prop({ default: null }) public onBackCallback!: (() => boolean | Promise<boolean>) | null;

        public async handleBackClick() {
            if (this.onBackCallback) {
                if (typeof this.onBackCallback === 'function') {
                    try {
                        const result = await this.onBackCallback();

                        if (result === true) {
                            return;
                        }
                    } catch (e) {
                        // Error in callback function should not break this component
                        console.error(e);
                    }
                } else {
                    console.warn('onBackCallback should be a function');
                }
            }
            this.$routeStack.back(this.defaultLocation);
        }

        public get defaultLocation(): Location {
            if (this.defaultBackRoute) {
                if (typeof this.defaultBackRoute === 'string') {
                    return {
                        name: this.defaultBackRoute,
                    };
                } else {
                    return this.defaultBackRoute;
                }
            } else if (this.$route.meta?.defaultBackRoute) {
                if (typeof this.$route.meta.defaultBackRoute === 'string') {
                    return {
                        name: this.$route.meta.defaultBackRoute,
                    };
                } else if (typeof this.$route.meta.defaultBackRoute === 'function') {
                    return this.$route.meta.defaultBackRoute(this.$route);
                } else {
                    return this.$route.meta.defaultBackRoute;
                }
            }
            return { path: '/' };
        }

        public get route(): Route {
            return this.$routeStack.resolvePreviousLocationHref(this.defaultLocation);
        }
    }
</script>

<style lang="less" scoped>
    .back-button {
        display: flex;
        background: none;
        border: none;
        outline: none;

        &:hover {
            color: @primary-color;
            cursor: pointer;
        }
    }

    .back-button-wrapper {
        display: flex;
    }

    .floating {
        .flex-center-content();

        position: fixed;
        top: calc(@core-header-height + @spacing-40);
        left: @spacing-40;
        z-index: 2;
        width: 40px;
        height: 40px;
        background-color: @bright-color;
        border-radius: @border-round;
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);

        .if-mobile({
            left: initial;
            right: @spacing-16;
            top: calc(@core-header-height + @spacing-16);
            width: 40px;
            height: 40px;
            z-index: 2;
        });
    }
</style>
