import { TopicApiFactory } from '@eloomi/eloomi-topics-persona-client/1.0';

import { Topic } from '@/admin/topics/models/topic';
import { authorizeClient } from '@/api/authorize-client';
import { extractAxiosData } from '@/common/services/extract-axios-data';

const topicApiClient = authorizeClient(TopicApiFactory);

export const putTopic = (topic: Topic) => {
    return topicApiClient.createOrUpdate(topic).then(extractAxiosData) as Promise<Topic>;
};
