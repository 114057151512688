import {
    CourseUserApiFactory,
    DeviceType,
    PlayerCourseApiFactory,
    PlayerPageApiFactory,
} from '@eloomi/eloomi-learning-persona-client/1.0';
import { PlayerCourseApiFactory as PlayerCourseApiFactoryV2 } from '@eloomi/eloomi-learning-persona-client/2.0';

import { authorizeClient } from '@/api/authorize-client';
import { extractAxiosData } from '@/common/services/extract-axios-data';
import { ICoursePlayerCourse, ICoursePlayerPage } from '@/courses/models';

const playerCourseApiV2 = authorizeClient(PlayerCourseApiFactoryV2);
const playerCourseApiV1 = authorizeClient(PlayerCourseApiFactory);
const courseUserApiClient = authorizeClient(CourseUserApiFactory);
const playerPageApiClient = authorizeClient(PlayerPageApiFactory);

export function getPageDetails({ courseId, chapterId, pageId }) {
    return playerPageApiClient
        .getPageWithStructure(courseId, chapterId, pageId)
        .then(extractAxiosData) as Promise<ICoursePlayerPage>;
}

export function getCourse(courseId: number) {
    return playerCourseApiV2.getCourseStructure(courseId).then(extractAxiosData) as Promise<ICoursePlayerCourse>;
}

export function completePage({ courseId, chapterId, pageId }): Promise<any> {
    return playerPageApiClient.completePage(courseId, chapterId, pageId).then(extractAxiosData);
}

export function incrementCourseViews({ courseId, userId }: { courseId: number; userId: number }) {
    return playerCourseApiV1.incrementCourseViewsCount(courseId, userId).then(extractAxiosData);
}

export function trackActivity(payload: {
    courseId: number;
    chapterId: number;
    pageId: number;
    deviceType?: DeviceType;
}) {
    return playerPageApiClient
        .trackActivity(payload.courseId, payload.chapterId, payload.pageId, {
            device_type: payload.deviceType,
        })
        .then(extractAxiosData);
}

export function selfEnrollToCourse(courseId: number) {
    return courseUserApiClient.createCourseUser(courseId);
}
