import { RulesApiFactory } from '@eloomi/eloomi-user-groups-persona-client/1.0';
import { RulePreviewQuery } from '@eloomi/eloomi-user-groups-persona-client/1.0/models';
import { RuleCreateOrUpdateCommand } from '@eloomi/eloomi-user-groups-persona-client/1.0/models/rule-create-or-update-command';
import { RulesApiFactory as RulesApiFactoryV2 } from '@eloomi/eloomi-user-groups-persona-client/2.0';
import { AxiosResponse } from 'axios';

import { authorizeClient } from '@/api/authorize-client';
import { PaginatedDataUnmapped } from '@/common/models/paginated-data';
import { FluentFilterCollection, Operator } from '@/common/services';
import { extractAxiosData } from '@/common/services/extract-axios-data';

import { GetRuleParametersResponse, mapParameters, Rule, RuleDefinition, UnmappedParameter } from '../models';

const rulesClientV2 = authorizeClient(RulesApiFactoryV2);
const rulesApiClient = authorizeClient(RulesApiFactory);

export function getRuleParameters(): Promise<GetRuleParametersResponse> {
    return rulesClientV2
        .getRuleParameters()
        .then(response => extractAxiosData<UnmappedParameter[]>(response as AxiosResponse))
        .then(mapParameters);
}

export function putRule(groupId: number, rule: RuleDefinition, ruleId: number | null = null) {
    const payload = {
        ...(ruleId === null ? {} : { id: ruleId }),
        user_group_id: groupId,
        definition: rule,
    } as RuleCreateOrUpdateCommand;

    return rulesApiClient.createOrUpdate(payload);
}

export function getRuleById(ruleId: number) {
    return rulesApiClient.getRule(ruleId).then(extractAxiosData);
}

export function getRules(
    options: { page: number; pageSize: number },
    groupId: number
): Promise<PaginatedDataUnmapped<Rule>> {
    const filters = new FluentFilterCollection().where('user_group_id').filter(Operator.IsEqualTo, groupId);

    return rulesApiClient
        .getRules(groupId, filters.build(), undefined, options.page, options.pageSize)
        .then(extractAxiosData) as Promise<PaginatedDataUnmapped<Rule>>;
}

export function previewUserCount(rule: RuleDefinition) {
    return rulesApiClient.previewRule(rule as RulePreviewQuery).then(extractAxiosData);
}

export function deleteRules(ruleIds: Array<number>) {
    return rulesApiClient.bulkDelete({ ids: ruleIds }).then(extractAxiosData);
}
