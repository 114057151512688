export type PluralKey = Partial<Record<Intl.LDMLPluralRule, string>>;

export type Dictionary = Record<string, string | PluralKey>;

export * from './types-generated';

import { Language } from './types-generated';

export const supportedLanguages: Language[] = [
    Language['da-DK'],
    Language['de-DE'],
    Language['en-GB'],
    Language['es-ES'],
    Language['fi-FI'],
    Language['fr-FR'],
    Language['is-IS'],
    Language['nl-NL'],
    Language['no-NO'],
    Language['pt-PT'],
    Language['ru-RU'],
    Language['sv-SE'],
    Language['zh-CN'],
];
