import { inject, injectable } from 'tsyringe';

import { makeLogger, makeWarnLogger } from '@/common/services';

import { PermissionsServiceTokens as Tokens } from '../di-tokens';
import { type RouteWithPermissions, isAnyPermissionRequiredForRoute } from '../domain';
import type { Router, UserWithPermissionsProvider } from './ports';

/**
 * It installs route handler and checks if a user has enough permissions to an ongoing route transition.
 * If there is no permissions it's trying to call 404 route handler.
 */

@injectable()
export class PermissionsRouteGuard {
    private readonly log = makeLogger('permissions-route-guard');
    private readonly warnLog = makeWarnLogger('permissions-route-guard');

    public constructor(
        @inject(Tokens.RouterAdapter) private readonly router: Router,
        @inject(Tokens.UserProfileProvider) private readonly userProvider: UserWithPermissionsProvider
    ) {}

    public install() {
        this.router.addBeforeEachRouteHandler(this.checkIfUserHasPermissionsForRoute.bind(this));
    }

    private async checkIfUserHasPermissionsForRoute(route: RouteWithPermissions, goNext) {
        if (!isAnyPermissionRequiredForRoute(route)) {
            this.log('passing, since no permissions required for the route');
            return goNext();
        }

        const user = await this.userProvider.getCurrentUser();

        /**
         * By default it shouldn't happen since if user is anonymous it should be intercepted
         * by authentication middleware.
         *
         * If route isn't marked with "requiresAuth" but it has "requiredPermissions"
         * then this may happen if user isn't authenticated.
         */
        if (user.isAnonymous) {
            this.warnLog('anonymous user detected, that is weird, please mark route with "requiresAuth"');
        }

        if (!user.hasAccessToRoute(route)) {
            this.warnLog('restricting the route visit since lack of permissions detected');
            return this.router.handle404(goNext);
        }

        this.log('passing the route %o by successful permission check', route.path);
        goNext();
    }
}
