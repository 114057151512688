var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition-group',{staticClass:"slide-transition-container",class:[
        ("slide-" + _vm.slideDistance),
        _vm.slideDirectionClass,
        ( _obj = {
            'with-dynamic-height': _vm.transitionHeight,
            transitioning: _vm.isTransitionInProgress
        }, _obj[("horizontal-align-" + _vm.horizontalItemAlignment)] = _vm.horizontalItemAlignment, _obj[("vertical-align-" + _vm.verticalItemAlignment)] = _vm.verticalItemAlignment, _obj ) ],style:(_vm.containerStyle),attrs:{"tag":"div","name":"slide"},on:{"before-enter":_vm.beforeEnter,"before-leave":_vm.beforeLeave,"enter":_vm.enter,"after-leave":_vm.afterLeave}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }