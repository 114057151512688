<template>
    <div class="tab-button-group" :class="`theme-${theme}`" role="tablist"><slot></slot></div>
</template>

<script lang="ts">
    import { defineComponent, PropType } from 'vue';

    import { TabsTheme } from './types';

    export default defineComponent({
        name: 'TabButtonGroup',
        props: {
            theme: { default: 'normal', type: String as PropType<TabsTheme> },
        },
    });
</script>

<style lang="less" scoped>
    .tab-button-group {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        max-width: 100%;
        padding: @spacing-4;
        background-color: @bright-color;
        border-radius: @border-radius-8;

        &.theme-dark {
            background: @info-color-8;
        }
    }
</style>
