<template>
    <button class="back-menu-button" @click="$emit('click')">
        <elm-arrows-arrow-left-bold-icon size="12" />
    </button>
</template>

<script lang="ts">
    import '@eloomi/icons/arrows/arrows-arrow-left-bold';

    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'MenuBackButton',
    });
</script>

<style scoped lang="less">
    .back-menu-button {
        .flex-center-content();

        flex: 0 0 24px;
        width: 24px;
        height: 24px;
        margin: 0 @spacing-12 0 0;
        padding: 0;
        color: @info-color;
        background-color: @info-color-8;
        border: 0;
        border-radius: @border-round;
        cursor: pointer;

        .generic-transition(~'background-color, color');
    }
</style>
