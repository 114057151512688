import { UsersAdminApiFactory, UsersApiFactory, UserSkillItem } from '@eloomi/eloomi-skills-persona-client/1.0';
import { ManagersApiFactory } from '@eloomi/eloomi-user-groups-persona-client/1.0';
import {
    EmailNotificationRules,
    UserDetailsResponse,
    UserPersonaApiFactory,
    UserSelfDetailsResponse,
} from '@eloomi/eloomi-users-persona-client/1.0';

import { authorizeClient } from '@/api/authorize-client';
import { UserProfile } from '@/common/interfaces';
import { convertBackendResponseToModel } from '@/common/services';
import { extractAxiosData } from '@/common/services/extract-axios-data';

const usersApiClient = authorizeClient(UserPersonaApiFactory);
const usersSkillsApiClient = authorizeClient(UsersApiFactory);
const userGroupManagersApiClient = authorizeClient(ManagersApiFactory);
const userAdminApiClient = authorizeClient(UsersAdminApiFactory);

function convertResponseDetailsToUserProfile(response: UserDetailsResponse | UserSelfDetailsResponse): UserProfile {
    return {
        ...convertBackendResponseToModel(response),
        emailNotificationRules: response.email_notification_rule
            ? (response.email_notification_rule.split(',').map(rule => rule.trim()) as EmailNotificationRules[])
            : [],
    };
}

export async function isReportingManager() {
    const isCurrentUserManagerPredicate = await userGroupManagersApiClient.isCurrentUserManager();
    return isCurrentUserManagerPredicate.data;
}

export async function getUserProfile(id: number) {
    const userProfileDetails = await usersApiClient.getDetails(id);
    return convertResponseDetailsToUserProfile(userProfileDetails.data);
}

export async function getSelfUserProfile() {
    const selfUserProfileDetails = await usersApiClient.getSelfDetails();
    return convertResponseDetailsToUserProfile(selfUserProfileDetails.data);
}

export async function getSelfSkillsInfo() {
    return usersSkillsApiClient.getUserSkills().then(extractAxiosData);
}

export async function getUserSkillsInfo(userId: number) {
    return userAdminApiClient.getSpecificUserSkills(userId).then(extractAxiosData);
}

export async function setSelfSkillsInfo(skills: UserSkillItem[]) {
    return usersSkillsApiClient.updateUserSkills({ skills });
}

export async function completeSkillsSetup() {
    return usersSkillsApiClient.completeUserSetup();
}
