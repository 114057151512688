import { round } from 'lodash-es';

/**
 * Inspired by {@link https://css-tricks.com/the-trick-to-viewport-units-on-mobile/}
 */

class ViewHeightCorrector {
    private prevVh = 0;

    public init() {
        this.updateVhProperty();

        window.addEventListener('resize', () => this.updateVhProperty());
    }

    private updateVhProperty() {
        requestAnimationFrame(() => {
            requestAnimationFrame(() => {
                const vh = round(document.documentElement.clientHeight * 0.01, 2);
                if (this.prevVh !== vh) {
                    this.prevVh = vh;
                    document.documentElement.style.setProperty('--vh', `${vh}px`);
                }
            });
        });
    }
}

export const viewHeightCorrector = new ViewHeightCorrector();
