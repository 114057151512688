import type { DependencyContainer } from 'tsyringe';

import { PermissionsServiceTokens as Tokens } from './di-tokens';
import { UserProfileStoreProviderAdapter } from './services/user-profile-provider';
import { VueRouterAdapter } from './services/vue-router-adapter';
import { UserPermissionChecker } from './use-cases/check-permissions';
import { PermissionsRouteGuard } from './use-cases/route-guard';

export function registerPermissionServices(container: DependencyContainer): DependencyContainer {
    container.register(Tokens.RouterAdapter, { useClass: VueRouterAdapter });
    container.register(Tokens.UserProfileProvider, { useClass: UserProfileStoreProviderAdapter });
    container.register(Tokens.PermissionChecker, {
        useFactory: async dc => {
            const provider = dc.resolve<UserProfileStoreProviderAdapter>(Tokens.UserProfileProvider);
            const user = await provider.getCurrentUser();
            return new UserPermissionChecker(user);
        },
    });

    /**
     * Here we're not using tokens since it may be used by end user code like:
     * const routeGuard = container.resolve(PermissionsRouteGuard);
     *
     * (class act as a token itself, but this is not suitable for ephemeral interfaces)
     */
    container.register(PermissionsRouteGuard, { useClass: PermissionsRouteGuard });

    return container;
}
