<template>
    <button
        role="tab"
        :aria-selected="isActive"
        class="tab-button"
        :class="[`size-${size}`, `theme-${theme}`, { active: isActive }]"
        :title="title"
        @click="handleButtonClick"
    >
        <slot>
            {{ title }}
        </slot>
    </button>
</template>

<script lang="ts">
    import { defineComponent, PropType } from 'vue';

    import { TabsSize, TabsTheme } from './types';

    export default defineComponent({
        name: 'TabButton',
        props: {
            title: { type: String },
            size: { default: 'normal', type: String as PropType<TabsSize> },
            theme: { default: 'normal', type: String as PropType<TabsTheme> },
            isActive: { default: false, type: Boolean },
        },
        methods: {
            handleButtonClick() {
                this.$emit('click');
            },
        },
    });
</script>

<style lang="less" scoped>
    .tab-button {
        position: relative;
        height: 40px;
        padding: 0 @spacing-16;
        overflow: hidden;
        color: @info-color;
        font-family: @default-font-family;
        white-space: nowrap;
        text-overflow: ellipsis;
        background-color: transparent;
        border: none;
        border-radius: @border-radius-6;
        cursor: pointer;

        .h5();
        .generic-transition(~'color');

        &.size-large {
            height: 56px;
        }

        &.size-small {
            height: 28px;
            padding: @spacing-3 @spacing-12;
            .paragraph-14-medium();

            border-radius: @border-radius-16;
        }

        &:hover {
            color: @primary-color;
        }

        &.active {
            color: @primary-color;
            background-color: @primary-color-8;

            &.theme-dark {
                background-color: @bright-color;
            }
        }

        &:focus,
        &:active {
            outline: none;
        }
    }
</style>
