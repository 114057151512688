import { onMounted, ref } from 'vue';

import infiniteLogoUrl from '@/assets/img/infinite.svg';
import { type PlatformResolutionService } from '@/common/services/platform-resolution';
import { ServiceTokens } from '@/di/tokens';
import { useDependencyContainer } from '@/di/use-injector';

export function useLogoForAuthenticationPage() {
    const logoImage = ref(infiniteLogoUrl);
    const container = useDependencyContainer();
    const platformResolutionService = container.resolve<PlatformResolutionService>(
        ServiceTokens.PlatformResolutionService
    );

    onMounted(async () => {
        const data = await platformResolutionService.getPlatformData();
        if (data.branding.login_page_image_url) {
            logoImage.value = data.branding.login_page_image_url;
        }
    });

    return {
        logoImage,
    };
}
