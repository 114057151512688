import { Module, Mutations, Actions, createMapper, Getters, createComposable } from 'vuex-smart-module';
import { Vue } from 'vue-property-decorator';

import { GetActiveSubscriptionResponse } from '@eloomi/eloomi-billing-persona-client/1.0';
import { getCurrentPlanDetails } from '@/admin/billing/providers/subscription';
import { AxiosResponse } from 'axios';

import { PlanApiFactory } from '@eloomi/eloomi-platforms-persona-client/1.0';
import { authorizeClient } from '@/api/authorize-client';
import { AxiosError } from 'axios';

const platformClient = authorizeClient(PlanApiFactory);

class State {
    public activeSubscription: GetActiveSubscriptionResponse | null = null;
    public activeSubscriptionResponseStatus?: number;
    public isPlatformFree = false;
    public expirationDate: string | null = null;
}

class StoreMutations extends Mutations<State> {
    public setActiveSubscription(subscription: AxiosResponse<GetActiveSubscriptionResponse>) {
        this.state.activeSubscription = subscription.data;
        this.state.activeSubscriptionResponseStatus = subscription.status;
    }
    public setIsPlatformFree(isPlatformFree) {
        this.state.isPlatformFree = isPlatformFree;
    }
    public setExpirationDate(expiration_date) {
        this.state.expirationDate = expiration_date;
    }
}

class StoreGetters extends Getters<State> {
    public get isFreePlan() {
        return this.state.activeSubscription?.is_free_plan;
    }
}

class StoreActions extends Actions<State, StoreGetters, StoreMutations, StoreActions> {
    public async getActiveSubscription() {
        const activeSubscription = await getCurrentPlanDetails();
        try {
            const planInfo = await platformClient.getCurrentPlan();
            this.mutations.setExpirationDate(planInfo.data.expiration_date);
            this.mutations.setIsPlatformFree(false);
        } catch (error) {
            this.mutations.setIsPlatformFree((error as AxiosError)?.response?.status === 404);
        }
        this.mutations.setActiveSubscription(activeSubscription);
    }
}

export const subscriptionStore = new Module({
    state: State,
    mutations: StoreMutations,
    getters: StoreGetters,
    actions: StoreActions,
});

export const useSubscriptionStore = createComposable(subscriptionStore);

export const subscriptionStoreMapper = createMapper(subscriptionStore);
export const SubscriptionMappedStore = Vue.extend({
    computed: {
        ...subscriptionStoreMapper.mapState([
            'activeSubscription',
            'activeSubscriptionResponseStatus',
            'isPlatformFree',
            'expirationDate',
        ]),
        ...subscriptionStoreMapper.mapGetters(['isFreePlan']),
    },
    methods: subscriptionStoreMapper.mapActions(['getActiveSubscription']),
});
