import { InstructorApiFactory } from '@eloomi/eloomi-event-management-persona-client/1.0/api';

import { authorizeClient } from '@/api/authorize-client';
import { DateFilterType, Filters, FilterType } from '@/common/components/filter';
import { CalendarTimeSpanType } from '@/common/components/filter/interfaces';
import { buildSort, FluentFilterCollection, Operator } from '@/common/services';
import { extractAxiosData } from '@/common/services/extract-axios-data';

const instructorApiClient = authorizeClient(InstructorApiFactory);

interface GetAllEventsSessionsOptions {
    page?: number;
    pageSize?: number;
    sessionTimeSpan?: CalendarTimeSpanType;
    sort: unknown;
    filters?: Filters;
}

export function getInstructorSessions(options: GetAllEventsSessionsOptions) {
    const filterData = getInstructorSessionsFiltersParam(options.filters, options.sessionTimeSpan);

    if (!options.sort && options.sessionTimeSpan) {
        options.sort = {
            ascending: options.sessionTimeSpan === 'upcoming',
            key: 'start_date',
        };
    }

    return instructorApiClient
        .getInstructorSessions(filterData, buildSort(options.sort), options.page, options.pageSize)
        .then(extractAxiosData);
}

function getInstructorSessionsFiltersParam(options: Filters | undefined, sessionTimeSpan: CalendarTimeSpanType) {
    const filters = new FluentFilterCollection();

    if (sessionTimeSpan === 'upcoming') {
        filters.where('start_date').filter(Operator.GreaterThanOrEqual, new Date());
    } else if (sessionTimeSpan === 'past') {
        filters.where('start_date').filter(Operator.LessThanOrEqual, new Date());
    }

    if (options === undefined) {
        return filters.build();
    }

    if (options[FilterType.SessionEnrollStatus]?.value && options[FilterType.SessionEnrollStatus]?.value.length) {
        filters
            .where('enrollment_closed')
            .filter(Operator.IsEqualTo, options[FilterType.SessionEnrollStatus]?.value[0]);
    }

    if (options[FilterType.SessionAuthor]?.value.length) {
        filters.where('creator_id').filter(Operator.IsEqualTo, options[FilterType.SessionAuthor]?.value.join('|'));
    }

    if (options[DateFilterType.Date]) {
        const { start, end } = options[DateFilterType.Date]!.value;
        if (start) {
            filters.where('start_date').filter(Operator.GreaterThanOrEqual, start);
        }

        if (end) {
            filters.where('start_date').filter(Operator.LessThanOrEqual, end);
        }
    }
    return filters.build();
}
