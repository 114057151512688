import type { VideoContent, VimeoUpload } from '@eloomi/eloomi-content-persona-client/1.0';
import { inject, injectable } from 'tsyringe';
import * as tus from 'tus-js-client';

import { extractAxiosData } from '@/common/services/extract-axios-data';

import { VideoServiceTokens } from '../../video/di-tokens';
import { VimeoClient } from './interfaces';

@injectable()
export class VimeoVideoService {
    public constructor(
        @inject(VideoServiceTokens.VimeoApiClient)
        private readonly vimeoClient: VimeoClient
    ) {}

    private uploadTus(uploadLink: string, file: File, onProgressChanged: (progressValue) => void) {
        const upload = new tus.Upload(file, {
            uploadUrl: uploadLink,
            retryDelays: [0, 3000, 5000, 10_000, 20_000],
            onProgress: (bytesUploaded, bytesTotal) => {
                onProgressChanged(Math.round((bytesUploaded / bytesTotal) * 100));
            },
        });

        return upload.findPreviousUploads().then(function (previousUploads) {
            if (previousUploads.length > 0) {
                upload.resumeFromPreviousUpload(previousUploads[0]);
            }

            return upload.start();
        });
    }

    public async uploadVimeoVideo(
        contentId: string,
        file: File,
        onProgressChanged: (progressValue) => void
    ): Promise<VimeoUpload> {
        const video = await this.vimeoClient
            .initUpload({
                content_id: contentId,
                size: file.size,
            })
            .then(extractAxiosData);
        await this.uploadTus(video.upload_link as string, file, onProgressChanged);

        return video;
    }

    public getUploadedVimeoVideo(id: number): Promise<VideoContent> {
        return this.vimeoClient.getVideo(id).then(extractAxiosData);
    }
}
