import { NavigationGuard, NavigationGuardNext, Route } from 'vue-router';

import { handlePageNotFound } from '@/common/services/transition-common';
import { getBaseStore } from '@/main/get-base-store-router';
import PlanFeaturesList from '@/plan-features/plan-features-list';
import planFeaturesStore from '@/plan-features/store';

export const checkIfRouteAvailableByPlanFeatures = (featureEntity: PlanFeaturesList): NavigationGuard => {
    return async (_to: Route, _from: Route, next: NavigationGuardNext) => {
        const store = getBaseStore();
        const planFeaturesStoreModule = planFeaturesStore.context(store);
        const isPlanFeaturesInitialized = planFeaturesStoreModule.state.initialized;

        if (!isPlanFeaturesInitialized) {
            await planFeaturesStoreModule.actions.initializeAllFeatures();
        }

        const isPageAvailable = planFeaturesStoreModule.getters.checkIfFeatureEnabled(featureEntity);

        if (isPageAvailable) {
            next();
        } else {
            handlePageNotFound(next);
        }
    };
};
