import {
    IntegrationMergeApiFactory,
    MergeIntegrationDetailsResponse,
    MergeIntegrationSettingsUpdateRequest
} from '@eloomi/eloomi-platforms-persona-client/1.0';
import { MergeIntegrationApiFactory } from '@eloomi/eloomi-users-persona-client/1.0';

import { authorizeClient } from '@/api/authorize-client';
import { extractAxiosData } from '@/common/services/extract-axios-data';

const integrationMergeClient = authorizeClient(IntegrationMergeApiFactory);
const integrationMergeStatusClient = authorizeClient(MergeIntegrationApiFactory);

export async function getLinkToken(integration_platform: string | null) {
    return integrationMergeClient.getLinkToken({ integration: integration_platform }).then(extractAxiosData);
}

export async function completeIntegration(token: string) {
    return integrationMergeClient.completeIntegration({ public_token: token });
}

export async function getAllIntegrations() {
    return integrationMergeClient.getSupportedHrisIntegrations().then(extractAxiosData);
}

export async function getIntegrationDetails(): Promise<MergeIntegrationDetailsResponse | undefined> {
    return integrationMergeClient.getIntegrationDetails().then(extractAxiosData);
}

export async function deleteIntegrationPlatform() {
    return integrationMergeClient.deleteIntegration();
}

export async function syncIntegration(is_enabled: boolean) {
    return integrationMergeClient.toggleIntegrationEnabled({ is_enabled: is_enabled });
}

export async function putNativeIntegrationSettings(settings: MergeIntegrationSettingsUpdateRequest) {
    return integrationMergeClient.putMergeIntegrationSettings(settings);
}

export async function getStatus() {
    return integrationMergeStatusClient.getCurrentStatus().then(extractAxiosData);
}

export async function importMergeUsers(starting_from: string | null = null) {
    return integrationMergeStatusClient.importMergeUsers({ starting_from: starting_from });
}
