import { SkillResponse } from '@eloomi/eloomi-learning-persona-client/1.0';
import { Actions } from 'vuex-smart-module';

import { getCourseSkills } from '@/admin/skills/providers/course-skills';
import { completePage, getCourse, getPageDetails, incrementCourseViews, selfEnrollToCourse } from '@/courses/providers';

import { UserCoursesGetters } from './getters';
import { UserCoursesMutations } from './mutations';
import { CoursePlayerState } from './state';

export class UserCoursesActions extends Actions<
    CoursePlayerState,
    UserCoursesGetters,
    UserCoursesMutations,
    UserCoursesActions
> {
    public async loadActiveCourse(payload: { courseId: number; getSkills?: boolean }) {
        const emptySkills = Promise.resolve<SkillResponse[]>([]);

        const requests = [
            getCourse(payload.courseId),
            payload.getSkills ? getCourseSkills(payload.courseId).catch(() => emptySkills) : emptySkills,
        ] as const;

        const [course, skills] = await Promise.all(requests);

        this.mutations.setCourse(course);
        this.mutations.setCourseSkills(skills);
    }

    public async setActivePageAndChapter(payload: {
        chapterId: number | null;
        pageId: number | null;
        courseId?: number;
    }) {
        this.mutations.setActiveChapterId(payload.chapterId);
        this.mutations.setActivePageId(payload.pageId);
        if (payload.pageId && payload.chapterId && (this.state.course || payload.courseId)) {
            this.mutations.setCoursePageLoading(true);
            if (this.state.currentPageDetails?.id !== payload.pageId) {
                this.mutations.setPageDetails(null);
                const pageDetails = await getPageDetails({
                    courseId: this.state.course?.id || payload.courseId,
                    chapterId: payload.chapterId,
                    pageId: payload.pageId,
                });

                this.mutations.setPageDetails(pageDetails);
                this.mutations.setCoursePageLoading(false);
            }
        }
    }

    public async reloadPage() {
        const { pageId, chapterId, course } = this.state;
        const pageDetails = await getPageDetails({
            courseId: course?.id,
            chapterId,
            pageId,
        });

        this.mutations.setPageDetails(pageDetails);
    }

    public resetActiveChapterAndPage() {
        this.mutations.setPageDetails(null);
        this.mutations.setActiveChapterId(null);
        this.mutations.setActivePageId(null);
    }

    public async markActivePageAsCompleted() {
        await completePage({
            courseId: this.state.course!.id,
            chapterId: this.state.chapterId,
            pageId: this.state.pageId,
        });

        return this.mutations.markActivePageAsCompleted();
    }

    public async incrementPlayerCourseViews({ courseId, userId }: { courseId: number; userId: number }) {
        await incrementCourseViews({
            courseId,
            userId,
        });
    }

    public async selfEnrollToCourse() {
        if (this.state.course) {
            await selfEnrollToCourse(this.state.course.id);
        }
    }
}
