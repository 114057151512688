import { RouteConfig } from 'vue-router';

import AdministrationRoutes from '@/admin/routes';
import AuthenticationRoutes from '@/authentication/routes';
import type { AuthRouteMeta } from '@/authentication/services';
import { handlePageNotFound } from '@/common/services/transition-common';
import ConversationsRoutes from '@/conversations/routes';
import { CoursesRouteNames } from '@/courses/route-definitions';
import CoursesRoutes from '@/courses/routes';
import EventRoutes from '@/events/routes';
import InstructorsRoutes from '@/instructors/routes';
import ManagerRoutes from '@/managers/routes';
import PersonalSpaceRoutes from '@/personal-space/personal-space.routes';
import ProfileSettingsRoutes from '@/profile-settings/routes';
import ReportingRoutes from '@/reporting/routes';

import ErrorRoutes, { ErrorRouteNames } from './errorRoutes';

const entryPage = () => import('./pages/EntryPage.vue');

const DEFAULT_ROUTE = CoursesRouteNames.COURSES_DASHBOARD;

/**
 * A basic set of application routes.
 * In most cases you may need to add new routes here.
 */
const APP_ROUTES: RouteConfig[] = [
    {
        path: '',
        component: entryPage,
        children: [
            ...AdministrationRoutes,
            ...ConversationsRoutes,
            ...CoursesRoutes,
            ...ErrorRoutes,
            ...EventRoutes,
            ...InstructorsRoutes,
            ...ManagerRoutes,
            ...PersonalSpaceRoutes,
            ...ProfileSettingsRoutes,
            ...ReportingRoutes,
            {
                path: '',
                redirect: { name: DEFAULT_ROUTE },
            },
        ],
        meta: {
            requiresAuth: true,
        } as AuthRouteMeta,
    },
];

export const routes: RouteConfig[] = [
    ...AuthenticationRoutes,
    {
        path: '',
        redirect: { name: DEFAULT_ROUTE },
    },
    {
        path: '*',
        name: ErrorRouteNames.NOT_FOUND,
        beforeEnter(_from, _to, next) {
            handlePageNotFound(next);
        },
    },
    ...APP_ROUTES,
];
