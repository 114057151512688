import { RouteConfig } from 'vue-router';

import { AppFeaturesList } from '@/launch-darkly/models';
import { checkIfAvailableByLaunchDarkly } from '@/launch-darkly/routes';

const PersonalSpacePage = () => import('./pages/PersonalSpacePage.vue');
const YourLearningPage = () => import('./pages/YourLearningPage.vue');
const UserConversationsPage = () => import('./pages/PersonalConversationsPage.vue');

export enum PersonalSpaceRouteName {
    Root = 'for-you',
    YourLearning = 'your-learning',
    Conversations = 'conversations',
}

const personalSpaceRoutes: RouteConfig[] = [
    {
        name: PersonalSpaceRouteName.Root,
        path: PersonalSpaceRouteName.Root,
        children: [
            {
                path: PersonalSpaceRouteName.YourLearning,
                name: PersonalSpaceRouteName.YourLearning,
                component: YourLearningPage,
                meta: {
                    title: 'Your learning',
                },
            },
            {
                path: PersonalSpaceRouteName.Conversations,
                name: PersonalSpaceRouteName.Conversations,
                component: UserConversationsPage,
                meta: {
                    title: 'Conversations',
                },
            },
        ],
        component: PersonalSpacePage,
        beforeEnter: checkIfAvailableByLaunchDarkly(AppFeaturesList.PERSONAL_SPACE),
    },
];

export default personalSpaceRoutes;
