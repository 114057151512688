import { Branding } from '@/common/models';

const CACHE_KEY = 'branding-data';

export function getCachedBrandingData() {
    const cachedBrandingColors = localStorage.getItem(CACHE_KEY);
    if (cachedBrandingColors !== null) {
        return JSON.parse(cachedBrandingColors) as Branding;
    }
    return null;
}

export function cacheBrandingData(data: Branding) {
    const stringified = JSON.stringify(data);
    return localStorage.setItem(CACHE_KEY, stringified);
}

export function clearBrandingDataFromLocalStorage() {
    localStorage.removeItem(CACHE_KEY);
}
