import { Capacitor } from '@capacitor/core';
import { ClientType } from '@eloomi/eloomi-users-persona-client/1.0';

export enum Platform {
    Web = 'web',
    Android = 'android',
    Ios = 'ios',
}

export function getDevicePlatform(): Platform {
    const platform = Capacitor.getPlatform();
    switch (platform) {
        case 'android':
            return Platform.Android;
        case 'ios':
            return Platform.Ios;
        default:
            return Platform.Web;
    }
}

export const platform = getDevicePlatform();

export function isAndroid() {
    return platform === Platform.Android;
}

export function isIos() {
    return platform === Platform.Ios;
}

export function isWeb() {
    return platform === Platform.Web;
}

export function isMobileApp() {
    return isAndroid() || isIos();
}

export function getClientType(): ClientType {
    return isMobileApp() ? ClientType.MobileApp : ClientType.Web;
}
