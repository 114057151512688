<template>
    <div class="card-wrapper">
        <slot></slot>
    </div>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    @Component({})
    export default class CoreCardLayout extends Vue {}
</script>

<style lang="less" scoped>
    .card-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: @spacing-8;
        background: @bright-color;
        border-radius: @border-radius-8;
        cursor: pointer;
        transition: @generic-transition;

        &:hover {
            box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
        }
    }
</style>
