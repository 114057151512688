export const AuthenticationServiceTokens = Object.freeze({
    ActivateUserService: Symbol('ActivateUserService'),
    AuthenticationService: Symbol('AuthenticationService'),
    AuthFlowStoreService: Symbol('AuthFlowStoreService'),
    ForgotPasswordApiClient: Symbol('ForgotPasswordApiClient'),
    ForgotPasswordService: Symbol('ForgotPasswordService'),
    LogoutUseCase: Symbol('LogoutUseCase'),
    RedirectService: Symbol('RedirectService'),
    ResetPasswordService: Symbol('ResetPasswordService'),
    ResetSessionDataService: Symbol('ResetSessionDataService'),
    SessionApiClient: Symbol('SessionApiClient'),
    RegularSessionApiClient: Symbol('RegularSessionApiClient'),
    UserActivationApiClient: Symbol('UserActivationApiClient'),
    SessionContainer: Symbol('SessionContainer'),
    AuthenticationSessionFabric: Symbol('AuthenticationSessionFabric'),
    HandleHttpForbiddenStatusUseCase: Symbol('HandleHttpForbiddenStatusUseCase'),
    LoginUseCase: Symbol('LoginUseCase'),
} as const);
