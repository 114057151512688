import { Actions, createMapper, Getters, Module, Mutations } from 'vuex-smart-module';
import { Vue } from 'vue-property-decorator';

import {
    Currency,
    FrequencyInterval,
    GetPlansResponse,
    PlanName,
    PlanSize,
    PriceInfo,
    PutSubscriptionRequest,
} from '@eloomi/eloomi-billing-persona-client/1.0';
import { getSubscriptionPlans } from '@/admin/billing/providers/plans';
import { capitalize, pickBy } from 'lodash';
import { BillingAction } from '@/admin/billing/models';
import { getCardLink } from '@/admin/billing/providers/card';
import { putSubscription } from '@/admin/billing/providers/subscription';

type InitialPlanData = {
    current_currency?: Currency;
    current_frequency?: FrequencyInterval;
    current_plan?: PlanName;
    current_size?: PlanSize;
};

type SelectedPlanSettings = {
    current_currency?: Currency;
    current_frequency?: FrequencyInterval;
    current_plan?: PlanName;
    current_size?: PlanSize;
};

class State {
    public plansResponse?: GetPlansResponse | null = null;
    public initialPlanData: InitialPlanData | null = null;
    public selectedPlanSettings: SelectedPlanSettings = {
        current_currency: Currency.Dkk,
        current_frequency: FrequencyInterval.Monthly,
        current_plan: PlanName.Play,
        current_size: PlanSize._50,
    };
}

enum CustomPlanNames {
    Free = 'Free',
    Infinite = 'Infinite',
}

export type PlanNamesType = PlanName | CustomPlanNames;
export const PlanNames = {
    ...CustomPlanNames,
    ...PlanName,
};

class StoreGetters extends Getters<State> {
    public get currentPlan() {
        return this.state.selectedPlanSettings?.current_plan || PlanName.Play;
    }
    public get currentSize() {
        return this.state.selectedPlanSettings?.current_size || PlanSize._50;
    }
    public get hasAccount(): boolean {
        return Boolean(this.state.plansResponse?.has_account);
    }
    public get currentFrequency() {
        return this.state.selectedPlanSettings?.current_frequency || FrequencyInterval.Monthly;
    }
    public get frequencyKey() {
        return capitalize(this.getters.currentFrequency);
    }
    public get currentCurrency() {
        return this.state.selectedPlanSettings?.current_currency || Currency.Dkk;
    }
    public get currencyKey() {
        return this.getters.currentCurrency?.toUpperCase() ?? Currency.Dkk;
    }
    public get currencySymbol() {
        const currentCurrencyWithSymbol = this.state.plansResponse?.currencies?.find(
            item => item.code?.toUpperCase() === this.getters.currencyKey
        );
        return currentCurrencyWithSymbol?.symbol;
    }
    public get currencies() {
        return this.state.plansResponse?.currencies || [];
    }
    public get seatsNumbers() {
        const { currencyKey, currentPlan } = this.getters;
        const options = this.state.plansResponse?.prices?.[currencyKey][currentPlan];
        return options ? Object.keys(options) : [];
    }
    public get paymentPlans() {
        const { currencyKey, currentSize, currentPlan } = this.getters;
        const sizesAndFrequencies = this.state.plansResponse?.prices?.[currencyKey][currentPlan][currentSize];
        const availableFrequencies = Object.keys(sizesAndFrequencies);
        return availableFrequencies.map(frequency => ({
            name: frequency,
            discount: sizesAndFrequencies[frequency].discount_percentage,
        }));
    }
    public get currentChoices() {
        return {
            subscription: {
                currency: this.getters.currentCurrency,
                frequency: this.getters.currentFrequency,
                size: this.getters.currentSize,
                plan: this.getters.currentPlan,
            },
            action: BillingAction.ChangeSubscription,
        };
    }

    public get encodedCurrentChoices() {
        return btoa(JSON.stringify(this.getters.currentChoices));
    }

    public get plans() {
        return this.state.plansResponse?.plans || [];
    }

    public get currentPriceInfo(): PriceInfo {
        if (this.state.plansResponse) {
            const { currencyKey, currentSize, currentPlan, frequencyKey } = this.getters;

            return this.state.plansResponse!.prices![currencyKey][currentPlan][currentSize][frequencyKey]! as PriceInfo;
        } else {
            return {} as PriceInfo;
        }
    }

    public get initialPriceInfo(): PriceInfo {
        try {
            const { current_currency, current_size, current_plan, current_frequency } = this.state.initialPlanData!;

            return this.state.plansResponse!.prices![(current_currency as Currency).toUpperCase()][current_plan][current_size][
                capitalize(current_frequency)
            ] as PriceInfo;
        } catch (e) {
            return {} as PriceInfo;
        }
    }

    public get pricePerBillingCycle() {
        return this.getters.currentPriceInfo.price_total_per_billing_cycle ?? 0;
    }

    public get pricePerMonth() {
        return this.getters.currentPriceInfo.price_total_per_month ?? 0;
    }

    public get discount() {
        const discounted =
            (100 -
                (this.getters.currentFrequency === FrequencyInterval.Annually
                    ? this.getters.currentPriceInfo.discount_percentage!
                    : 0)) /
            100;
        return this.getters.pricePerBillingCycle / discounted - this.getters.pricePerBillingCycle;
    }

    public get initialPricePerBillingCycle() {
        return this.getters.initialPriceInfo.price_total_per_billing_cycle ?? 0;
    }

    public get initialDiscount() {
        const discounted =
            (100 -
                (this.getters.initialFrequency === FrequencyInterval.Annually
                    ? this.getters.initialPriceInfo.discount_percentage!
                    : 0)) /
            100;
        return this.getters.initialPricePerBillingCycle / discounted - this.getters.initialPricePerBillingCycle;
    }

    public get initialFrequency(): FrequencyInterval {
        return this.state.initialPlanData?.current_frequency || FrequencyInterval.Unknown;
    }

    public get initialPlan() {
        return this.state.initialPlanData?.current_plan || PlanName.Play;
    }

    public get initialSize() {
        return this.state.initialPlanData?.current_size || PlanSize._50;
    }

    public get initialCurrency() {
        return this.state.initialPlanData?.current_currency || Currency.Dkk;
    }

    public get downgradeWarningType(): 'plan' | 'users' {
        const plans = this.getters.plans.map(plan => plan.name);
        const currentPlan = plans.indexOf(this.state.initialPlanData?.current_plan);
        const selected = plans.indexOf(this.getters.currentPlan);

        if (selected < currentPlan) {
            return 'plan';
        }

        return 'users';
    }

    public get showDowngradeWarning() {
        return (
            this.getters.downgradeWarningType === 'plan' ||
            Number(this.state.initialPlanData?.current_size) > Number(this.getters.currentSize)
        );
    }
}

class StoreMutations extends Mutations<State> {
    public setPlans(response: GetPlansResponse) {
        this.state.plansResponse = response;
    }
    public updateSelectedPlan(data: Partial<GetPlansResponse>) {
        const choice = pickBy(data);
        this.state.selectedPlanSettings = { ...this.state.selectedPlanSettings, ...choice };
    }
    public setInitialPlanData(initialPlanData: InitialPlanData) {
        this.state.initialPlanData = initialPlanData;
    }
}

class StoreActions extends Actions<State, StoreGetters, StoreMutations, StoreActions> {
    public async getPlans() {
        const plansResponse = await (await getSubscriptionPlans()).data;
        this.mutations.setPlans(plansResponse);
        const firstResponse = {
            current_currency: this.state.plansResponse?.current_currency,
            current_frequency: this.state.plansResponse?.current_frequency,
            current_plan: this.state.plansResponse?.current_plan,
            current_size: this.state.plansResponse?.current_size,
        };
        this.mutations.updateSelectedPlan(firstResponse);
        this.mutations.setInitialPlanData(firstResponse);
    }

    public async saveNewPlan(data: {
        status: string | (string | null)[];
        scheduleForNext: boolean;
        redirectUrl: string;
    }) {
        const { status, scheduleForNext, redirectUrl } = data;

        if (status === undefined) {
            const getCardLinkResponse = (
                await getCardLink({
                    returnUrl: `${window.location.origin}${redirectUrl}?state=${this.getters.encodedCurrentChoices}`,
                    plan: this.getters.currentPlan,
                    size: this.getters.currentSize,
                    frequency: this.getters.currentFrequency,
                    currency: this.getters.currentCurrency,
                })
            ).data;

            if (getCardLinkResponse.is_redirect_required) {
                window.location.href = getCardLinkResponse.redirect_url!;
                return;
            }
        }

        const payload: PutSubscriptionRequest = {
            schedule_for_next: scheduleForNext,
            ...this.getters.currentChoices.subscription,
        };

        return putSubscription(payload);
    }
}

export const plansStore = new Module({
    state: State,
    getters: StoreGetters,
    mutations: StoreMutations,
    actions: StoreActions,
});

export const plansStoreMapper = createMapper(plansStore);
export const PlansMappedStore = Vue.extend({
    computed: {
        ...plansStoreMapper.mapState(['plansResponse', 'initialPlanData', 'selectedPlanSettings']),
        ...plansStoreMapper.mapGetters([
            'currentPlan',
            'currentSize',
            'hasAccount',
            'currentFrequency',
            'frequencyKey',
            'currentCurrency',
            'currencyKey',
            'currencySymbol',
            'currencies',
            'seatsNumbers',
            'paymentPlans',
            'currentChoices',
            'encodedCurrentChoices',
            'plans',
            'pricePerBillingCycle',
            'discount',
            'currentPriceInfo',
            'initialPriceInfo',
            'downgradeWarningType',
            'showDowngradeWarning',
            'initialPricePerBillingCycle',
            'initialDiscount',
            'initialFrequency',
            'initialPlan',
            'initialSize',
            'initialCurrency',
            'pricePerMonth',
        ]),
    },
    methods: {
        ...plansStoreMapper.mapActions(['getPlans', 'saveNewPlan']),
        ...plansStoreMapper.mapMutations(['setInitialPlanData', 'updateSelectedPlan']),
    },
});
