import { RouteConfig } from 'vue-router';

import { mobileIsUnsupportedFlag } from '@/common/services/mobile-fallback';
import { getBaseStore } from '@/main/get-base-store-router';
import { Permissions } from '@/permissions';

const contentStoreModal = () => import('@/content-store/components/modal/ContentStoreModal.vue');
const contentStoreCourseDetailsPage = () => import('@/content-store/pages/ContentStoreCourseDetailsPage.vue');

interface CreateContentStoreRouteArgs {
    contentStoreRouteName: string;
    contentStoreItemRouteName: string;
    showNavigationSidebar?: boolean;
}
export enum ContentStoreRouteType {
    CONTENT_STORE,
    CONTENT_STORE_ITEM,
}
const createContentStoreRoute = ({
    contentStoreRouteName,
    contentStoreItemRouteName,
    showNavigationSidebar,
}: CreateContentStoreRouteArgs): RouteConfig => ({
    path: 'content-store',
    name: contentStoreRouteName,
    component: contentStoreModal,
    meta: {
        title: 'ContentStore',
        ...mobileIsUnsupportedFlag,
        showNavigationSidebar: showNavigationSidebar || false,
        requiredPermissions: [Permissions.AdminContentStore],
        type: ContentStoreRouteType.CONTENT_STORE,
    },
    children: [
        {
            path: 'item/:id',
            name: contentStoreItemRouteName,
            component: contentStoreCourseDetailsPage,
            meta: {
                title: 'ContentStore Course',
                ...mobileIsUnsupportedFlag,
                showNavigationSidebar: showNavigationSidebar || false,
                requiredPermissions: [Permissions.AdminCourses],
                type: ContentStoreRouteType.CONTENT_STORE_ITEM,
            },
        },
    ],
    beforeEnter: (_from, _to, next) => {
        return (
            getBaseStore()
                .dispatch('contentStore/getSubscriptionInfo')
                // eslint-disable-next-line promise/no-callback-in-promise
                .then(() => next())
        );
    },
});

export default createContentStoreRoute;
