import { StoreOptions } from 'vuex';
import { Module, Getters, Mutations, Actions, createStore } from 'vuex-smart-module';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Modules = Record<string, Module<any, any, any, any, any>>;

export function createSmartStore(
    rootModule: Module<unknown, Getters<unknown>, Mutations<unknown>, Actions<unknown>, Modules>,
    options: StoreOptions<unknown> = {}
) {
    return createStore(rootModule, {
        ...options,
    });
}
