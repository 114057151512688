import { inject, injectable } from 'tsyringe';

import type { ResetSessionDataService } from '@/authentication/services/reset-session-data';
import { ServiceTokens as BaseServiceTokens } from '@/di/tokens';

import { AuthenticationServiceTokens as Tokens } from '../di-tokens';
import type { JWT, PlatformResolver, SessionApiClient, VueRouterRedirectionService } from '../services';
import { SessionContainer } from '../session/session-container';

/**
 * The user wants to exit the application.
 */
@injectable()
export class LogoutUseCase {
    public constructor(
        @inject(BaseServiceTokens.PlatformResolutionService)
        private readonly platformResolver: PlatformResolver,

        @inject(Tokens.SessionContainer)
        private readonly sessionContainer: SessionContainer<JWT>,

        @inject(Tokens.RedirectService)
        private readonly redirectService: VueRouterRedirectionService,

        @inject(Tokens.ResetSessionDataService)
        private readonly sessionDataService: ResetSessionDataService,

        @inject(Tokens.SessionApiClient)
        private readonly sessionApiClient: SessionApiClient
    ) {}

    public async logOut(shouldRedirect = true, withBackend = true) {
        if (withBackend) {
            await this.sessionApiClient.logout();
        }

        this.platformResolver.cleanCache();
        this.sessionContainer.stopAllSessions();
        this.sessionDataService.reset();

        if (shouldRedirect) {
            await this.redirectService.redirectToLoginPage();
        }
    }
}
