export const ServiceTokens = Object.freeze({
    VueRouter: Symbol('VueRouter'),
    BaseStore: Symbol('BaseStore'),
    UserDetailsStoreService: Symbol('UserDetailsStoreService'),
    OpenInAppStoreService: Symbol('OpenInAppStoreService'),
    MobileModeStoreService: Symbol('MobileModeStoreService'),
    PlatformResolutionService: Symbol('PlatformResolutionService'),
    ToastNotificationService: Symbol('ToastNotificationService'),
    TranslationService: Symbol('TranslationService'),
} as const);
