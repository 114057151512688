import { App as CapacitorApp } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import Vue from 'vue';
import VueRouter from 'vue-router';

import { AuthenticationRouteNames } from '@/authentication/route-definitions';
import { attachMobileFallbackGuard } from '@/common/services/mobile-fallback';
import { backButtonService } from '@/common/services/mobile-fallback/native-back-button-service';
import { includeSavePageInSegment } from '@/common/services/segment';
import { syncPageTitleWithRouteTitle } from '@/common/services/sync-document-title-with-route';
import environment from '@/environment';

import { routes } from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: environment.appBaseUrl,
    routes,
});

attachMobileFallbackGuard(router);
includeSavePageInSegment(router);
syncPageTitleWithRouteTitle(router);

if (Capacitor.isNativePlatform()) {
    CapacitorApp.addListener('backButton', ({ canGoBack }) => {
        if (!backButtonService.isEmpty()) {
            backButtonService.popAction();
            return;
        }

        if (!canGoBack) {
            CapacitorApp.exitApp();
        }

        if (router.currentRoute.name === AuthenticationRouteNames.LOGIN) {
            CapacitorApp.exitApp();
        }

        window.history.back();
    });
}

export default router;
