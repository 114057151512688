<template>
    <div class="user-desktop-action-menu-container" @mouseleave="userMenuToggle(false)">
        <template v-if="menuItems && authUserProfile">
            <div
                v-if="isImpersonating"
                class="impersonation-box"
                data-testid="impersonation-plate"
                @mouseover="userMenuToggle(true)"
            >
                <span class="impersonation-title">
                    {{ trans('spa.navigation.sections.user.logged-in-as') }}
                    <span class="impersonation-username">{{ userFullName }}</span>
                </span>
                <CoreShapedImage
                    :size="40"
                    :image="imageUrl"
                    :altText="userFullName"
                    :class="{ 'trigger-active': userMenuTriggerHovered }"
                    :hasSubstate="true"
                    data-testid="user-avatar"
                />
            </div>
            <CoreShapedImage
                v-else
                :size="40"
                :image="imageUrl"
                :altText="userFullName"
                :class="{ 'trigger-active': userMenuTriggerHovered }"
                :hasSubstate="true"
                data-testid="user-avatar"
                @mouseover.native="userMenuToggle(true)"
            />
        </template>
        <CoreActionMenu
            v-show="isUserDropdownContentShown"
            :title="userFullName"
            class="user-icon-menu"
            data-testid="user-actions-menu"
            @mouseenter.native="enterDropdownContent"
            @mouseleave.native="userMenuToggle(false)"
        >
            <DropdownItem
                v-for="item in items"
                :key="item.string"
                :link="getLink(item)"
                :title="item.name"
                @click="handleClick($event, item.onClick)"
            >
                <template #icon>
                    <component :is="item.icon" />
                </template>
            </DropdownItem>
        </CoreActionMenu>
    </div>
</template>

<script lang="ts">
    import '@eloomi/icons/alerts-info/alerts-info-notification';

    import { debounce } from 'lodash-es';
    import vClickOutside from 'v-click-outside';
    import { computed, defineComponent, PropType, ref, toRefs } from 'vue';

    import CoreShapedImage from '@/common/components/icon/CoreShapedImage.vue';
    import CoreActionMenu from '@/common/components/menu/CoreActionDropdown/CoreActionMenu.vue';
    import { useTranslation } from '@/common/composables';
    import { getFullName } from '@/common/services';
    import { useUserProfileDetailsStore } from '@/common/store/user-details/store';
    import { useImpersonationStore } from '@/impersonation/store';
    import DropdownItem from '@/ui-kit/dropdown/items/DropdownItem.vue';

    import { TopNavUserInterface } from '../../interfaces';

    export default defineComponent({
        name: 'CoreUserMenu',
        components: {
            DropdownItem,
            CoreActionMenu,
            CoreShapedImage,
        },
        directives: {
            clickOutside: vClickOutside.directive,
        },
        props: {
            menuItems: {
                type: Object as PropType<TopNavUserInterface>,
                required: true,
            },
        },
        setup(props) {
            const userMenuShow = ref(false);
            const userMenuTriggerHovered = ref(false);
            const isUserDropdownContentShown = computed(() => userMenuShow.value || userMenuTriggerHovered.value);
            const enterDropdownContent = () => (userMenuTriggerHovered.value = false);

            const { trans } = useTranslation();

            const userProfileDetails = useUserProfileDetailsStore();
            const impersonationStore = useImpersonationStore();
            const { authUserProfile } = toRefs(userProfileDetails.state);
            const { isImpersonating } = toRefs(impersonationStore.state);

            const userMenuToggle = debounce(async (status: boolean) => {
                if (status === true) {
                    userMenuTriggerHovered.value = true;
                    setTimeout(() => (userMenuTriggerHovered.value = false), 2000);
                }
                userMenuShow.value = status;
            }, 200);

            const items = computed(() => {
                return props.menuItems ? Object.values(props.menuItems.subnav! || {}) : [];
            });

            const imageUrl = computed(() => props.menuItems.image_url ?? '');

            const userFullName = computed(
                (): string => getFullName(props.menuItems) ?? getFullName(authUserProfile.value)
            );

            const handleClick = (event: Event, itemClick?: () => void) => {
                if (typeof itemClick === 'function') {
                    itemClick();
                    event.preventDefault();
                }
            };

            const getLink = item => {
                if (item.route?.name) {
                    return item.route;
                }
                return typeof item.route === 'string' ? { path: item.route.replace('/app/', '/') } : undefined;
            };

            userProfileDetails.actions.loadUserProfile();

            return {
                userMenuTriggerHovered,
                isUserDropdownContentShown,
                enterDropdownContent,
                userMenuToggle,
                userFullName,
                imageUrl,
                items,
                handleClick,
                authUserProfile,
                isImpersonating,
                trans,
                getLink,
            };
        },
    });
</script>
<style lang="less" scoped>
    .user-icon-menu {
        position: absolute;
        top: 75px;
        right: 49px;
    }

    .impersonation-box {
        display: flex;
        align-items: center;
        height: 48px;
        padding: @spacing-2;
        background-color: @color-bright-8;
        border-radius: 24px;
    }

    .impersonation-title {
        display: block;
        margin-right: @spacing-16;
        margin-left: @spacing-22;
        color: @color-bright;
        .text-line();
        .font-semibold();

        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .impersonation-username {
        .font-bold();
    }

    .trigger-active {
        position: relative;

        &::before {
            position: absolute;
            top: 50%;
            right: 0;
            width: 600%;
            height: 200%;
            transform: translateY(-50%);
            content: '';
        }
    }

    .user-desktop-action-menu-container {
        padding: @spacing-24 0;
    }
</style>
