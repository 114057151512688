import { TransFunction } from '@/translations/store';
import { PRIMARY_COLORS } from '@/common/constants/color-css-variables';

export function formatCourseDuration(duration: number, trans: TransFunction) {
    const allMinutes = Math.round(duration / 60);

    if (allMinutes >= 60) {
        const hours = Math.floor(allMinutes / 60);
        const minutes = Math.round(allMinutes % 60);

        return minutes > 0
            ? trans('spa.courses-card.duration.hours-minutes', {
                  hours,
                  minutes,
              })
            : trans('spa.courses-card.duration.hours', {
                  hours,
              });
    } else {
        return trans('spa.courses-card.duration.minutes', { minutes: allMinutes });
    }
}

export function highlightSearchedText(text: string, searchText: string) {
    const regexExpression = new RegExp(searchText, 'ig');
    const match = text.match(regexExpression);
    if (match) {
        const matchedExpression = match[0];
        return text.replace(
            regexExpression,
            `<span style="color: ${PRIMARY_COLORS.PRIMARY}">${matchedExpression}</span>`
        );
    } else {
        return text;
    }
}

export function highlightTitle(course: { title?: string | null } | undefined, searchText: string) {
    return course?.title && highlightSearchedText(course.title, searchText);
}

export function highlightDescription(course: { description?: string | null } | undefined, searchText: string) {
    if (!course?.description) {
        return;
    }
    const AVAILABLE_CHARS_NUMBER = 40;
    const SEARCH_TEXT_LENGTH = searchText.length;
    const REST_AVAILABLE_SPACE = Math.floor(AVAILABLE_CHARS_NUMBER - SEARCH_TEXT_LENGTH);
    const SEARCH_TEXT_INDEX = course.description.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase());
    const DESCRIPTION_LENGTH = course.description.length;

    if (SEARCH_TEXT_INDEX < AVAILABLE_CHARS_NUMBER && DESCRIPTION_LENGTH < AVAILABLE_CHARS_NUMBER) {
        return highlightSearchedText(course.description, searchText);
    }

    if (SEARCH_TEXT_INDEX < AVAILABLE_CHARS_NUMBER && DESCRIPTION_LENGTH > AVAILABLE_CHARS_NUMBER) {
        return highlightSearchedText(course.description.slice(0, AVAILABLE_CHARS_NUMBER), searchText) + '...';
    }
    if (SEARCH_TEXT_INDEX > DESCRIPTION_LENGTH - AVAILABLE_CHARS_NUMBER) {
        return (
            '...' +
            highlightSearchedText(
                course.description.slice(DESCRIPTION_LENGTH - AVAILABLE_CHARS_NUMBER, DESCRIPTION_LENGTH),
                searchText
            )
        );
    }
    const startIndex =
        SEARCH_TEXT_INDEX - REST_AVAILABLE_SPACE / 2 < 0 ? 0 : SEARCH_TEXT_INDEX - REST_AVAILABLE_SPACE / 2;

    const text = course.description.slice(
        startIndex,
        SEARCH_TEXT_INDEX + SEARCH_TEXT_LENGTH + REST_AVAILABLE_SPACE / 2
    );
    return '...' + highlightSearchedText(text, searchText) + '...';
}
