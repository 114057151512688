import { PutSubscriptionRequest, SubscriptionPersonaApiFactory } from '@eloomi/eloomi-billing-persona-client/1.0';

import { DowngradeRestriction } from '@/admin/billing/models';
import { authorizeClient } from '@/api/authorize-client';
import { extractAxiosData } from '@/common/services/extract-axios-data';

const subscriptionClient = authorizeClient(SubscriptionPersonaApiFactory);

export function getCurrentPlanDetails() {
    return subscriptionClient.getActiveSubscription();
}

export async function deleteSubscription() {
    return subscriptionClient.deleteSubscription();
}

export async function putSubscription(payload: PutSubscriptionRequest) {
    return subscriptionClient.putSubscription(payload);
}

export function verifyLimits(size?) {
    return subscriptionClient.verifyLimits(size).then(extractAxiosData) as Promise<DowngradeRestriction[]>;
}
