import { JWT } from '../services/interfaces';
import { getJWTPayload } from '../services/payload';
import { AuthenticationSession, JWTPayload, SessionRefresher } from './interfaces';
import { SessionJWTStore } from './store';

export class AuthenticationJWTSession implements AuthenticationSession<JWT> {
    public constructor(
        private readonly sessionStore: SessionJWTStore,
        private readonly sessionRefresher: SessionRefresher
    ) {}

    /**
     * Return JWT as is.
     */
    public getData(): JWT {
        const data = this.sessionStore.read();
        // Check validity
        getJWTPayload<JWTPayload>(data, ['user_id', 'platform_id', 'nbf', 'exp', 'iat', 'iss']);
        return data as JWT;
    }

    /**
     * Check if session isn't expired and is used in a valid period of time.
     * This doesn't guarantee the validity of session token since it may be **modified** by an attacker.
     */
    public isActual() {
        const data = this.sessionStore.read();
        const payload = getJWTPayload<JWTPayload>(data, ['user_id', 'platform_id', 'nbf', 'exp', 'iat', 'iss']);
        /** Current timestamp in seconds. */
        const now = Date.now() / 1000;
        const delta = 5 * 60; // 5 minutes
        return now < payload.exp + delta && now >= payload.nbf - delta;
    }

    public destroy() {
        this.sessionRefresher.destroy();
        this.sessionStore.clear();
    }

    public renew() {
        this.sessionRefresher.refresh();
    }
}
