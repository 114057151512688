import { Module, Mutations, createMapper, createComposable } from 'vuex-smart-module';
import { Vue } from 'vue-property-decorator';

class State {
    public isSkillSetupModalVisible = false;
}

class StoreMutations extends Mutations<State> {
    public showSkillSetupModal() {
        this.state.isSkillSetupModalVisible = true;
    }

    public hideSkillSetupModal() {
        this.state.isSkillSetupModalVisible = false;
    }
}

export const skillSetupStore = new Module({
    state: State,
    mutations: StoreMutations,
});

export const skillSetupStoreMapper = createMapper(skillSetupStore);

export const useSkillSetupStore = createComposable(skillSetupStore);

export const SkillSetupStoreMixin = Vue.extend({
    computed: {
        ...skillSetupStoreMapper.mapState(['isSkillSetupModalVisible']),
    },
    methods: {
        ...skillSetupStoreMapper.mapMutations(['showSkillSetupModal', 'hideSkillSetupModal']),
    },
});

export default skillSetupStoreMapper;
