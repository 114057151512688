import { createPinia } from 'pinia';
import Vue from 'vue';

// @ts-ignore
import App from '@/App.vue';
// @ts-ignore
import PlatformDeactivatedPage from '@/authentication/page/PlatformDeactivatedPage.vue';
// @ts-ignore
import PlatformNotFoundPage from '@/authentication/page/PlatformNotFoundPage.vue';
import { getBaseRouter, getBaseStore } from '@/main/get-base-store-router';

export function renderApp({
    isPlatformFound,
    isPlatformActive,
}: {
    isPlatformFound: boolean;
    isPlatformActive: boolean;
}) {
    const elementSelector = '#app';
    const router = getBaseRouter();
    const store = getBaseStore();
    const pinia = createPinia();

    if (isPlatformFound && isPlatformActive) {
        new Vue({
            router,
            store,
            pinia,
            render: create => create(App),
        }).$mount(elementSelector);
    } else {
        const renderedPage = isPlatformFound ? PlatformDeactivatedPage : PlatformNotFoundPage;
        new Vue({
            store,
            render: create => create(renderedPage),
        }).$mount(elementSelector);
    }
}
