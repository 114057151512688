<template>
    <div @animationend="handleAnimationEnd">
        <slot />
    </div>
</template>

<script lang="ts">
    import { defineComponent, provide } from 'vue';
    import { IS_IN_MODAL } from '@/ui-kit/modal-window/context-keys';

    export default defineComponent({
        name: 'ModalWindowDialog',
        emits: ['animationEnd'],
        setup(_, context) {
            provide(IS_IN_MODAL, true);

            function handleAnimationEnd() {
                context.emit('animationEnd');
            }

            return { handleAnimationEnd };
        },
    });
</script>
