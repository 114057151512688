import { Vue } from 'vue-property-decorator';
import { Actions, createComposable, createMapper, Getters, Module, Mutations } from 'vuex-smart-module';

import { launchDarklyProvider } from '@/launch-darkly/api';
import { AppFeaturesList, FeatureTogglesMap } from '@/launch-darkly/models';
import { cacheFeaturesToggleData, getCachedFeaturesToggleData } from '@/launch-darkly/services';
import { getPredefinedFeatureToggles } from '@/launch-darkly/services/predefined-feature-toggles';
import { withSentry } from '@/sentry';

class State {
    public enabledFeatures: FeatureTogglesMap = {};
    public launchDarklyInitialized = false;
    public launchDarklyFeaturesList = AppFeaturesList;
    public initPromise: Promise<unknown> | null = null;
}

class StoreGetters extends Getters<State> {
    public checkIfLaunchDarklyFeatureEnabled(featureName: AppFeaturesList) {
        return Boolean(this.state.enabledFeatures[featureName]?.value);
    }

    public getLaunchDarklyFeatureData(featureName: AppFeaturesList) {
        return this.state.enabledFeatures[featureName]?.data || null;
    }
}

export class StoreActions extends Actions<State, StoreGetters, StoreMutations, StoreActions> {
    public async loadAllFeatureAvailable() {
        const cachedData = getCachedFeaturesToggleData();

        if (cachedData !== null) {
            this.mutations.setFeatureAvailability(cachedData);
        }

        const featuresList = await launchDarklyProvider.getFeaturesState();
        const featuresListOverrides = await getPredefinedFeatureToggles();

        const finalFeaturesList = { ...featuresList, ...featuresListOverrides };

        this.mutations.setFeatureAvailability(finalFeaturesList);

        cacheFeaturesToggleData(finalFeaturesList);
    }

    public async initializeLaunchDarkly() {
        if (!this.state.initPromise) {
            this.mutations.setInitPromise(this.actions.loadAllFeatureAvailable());
        }
        return this.state.initPromise;
    }
}

class StoreMutations extends Mutations<State> {
    public setFeatureAvailability(featureState: FeatureTogglesMap) {
        this.state.enabledFeatures = {
            ...this.state.enabledFeatures,
            ...featureState,
        };
        this.state.launchDarklyInitialized = true;
        withSentry(s => {
            s.setContext('featureAvailability', this.state.enabledFeatures);
        });
    }

    public setInitPromise(initPromise: Promise<unknown> | null) {
        this.state.initPromise = initPromise;
    }
}

const launchDarklyFeatureStore = new Module({
    state: State,
    mutations: StoreMutations,
    actions: StoreActions,
    getters: StoreGetters,
});

export const useLaunchDarklyFeatureStore = createComposable(launchDarklyFeatureStore);

export default launchDarklyFeatureStore;
export const launchDarklyFeatureStoreMapper = createMapper(launchDarklyFeatureStore);

export const LaunchDarklyFeaturesMappedStore = Vue.extend({
    computed: {
        ...launchDarklyFeatureStoreMapper.mapState(['launchDarklyFeaturesList']),
        ...launchDarklyFeatureStoreMapper.mapGetters(['checkIfLaunchDarklyFeatureEnabled']),
    },
    methods: {
        ...launchDarklyFeatureStoreMapper.mapActions(['initializeLaunchDarkly']),
    },
});
