import { inject, injectable } from 'tsyringe';

import { AuthenticationServiceTokens as Tokens } from '../di-tokens';
import { ForgotPasswordService } from '../services';

/**
 * User wants to request a password reset.
 */
@injectable()
export class ForgotPasswordUseCase {
    public constructor(
        @inject(Tokens.ForgotPasswordService)
        private readonly authService: ForgotPasswordService,

        @inject(Tokens.AuthFlowStoreService)
        private readonly navigator: { goBack(): void }
    ) {}

    public async requestPasswordReset(email: string) {
        await this.authService.requestPasswordReset(email);
        this.navigator.goBack();
    }
}
