export class FileResponse {
    public content: Blob;
    public name: string | undefined;

    constructor(content: Blob, contentDisposition: string | undefined) {
        this.content = content;
        this.name = this.getFileName(contentDisposition);
    }

    private getFileName(contentDisposition: string | undefined) {
        if (!contentDisposition) return undefined;

        const fileNames = contentDisposition.match(/filename=(.*);/);
        if (!!fileNames && fileNames.length >= 1) {
            return fileNames[1];
        }
    }
}
