export const INTERCOM_DISABLED_KEY = 'is-intercom-disabled';
export const WELCOME_POPUPS_DISABLED_KEY = 'is-welcome-popups-disabled';

export const enum E2EFeatureKeys {
    Intercom = 'is-intercom-disabled-by-e2e',
    WelcomePopups = 'is-any-welcome-popup-disabled-by-e2e',
}

export function isFeatureDisabledByE2E(flagKey: E2EFeatureKeys) {
    return localStorage.getItem(flagKey) === 'true';
}
