import { QuestionPlayerApiFactory, QuestionType } from '@eloomi/eloomi-content-persona-client/1.0';
import { PlayerPageApiFactory } from '@eloomi/eloomi-learning-persona-client/1.0';
import { PlayerCourseApiFactory } from '@eloomi/eloomi-learning-persona-client/2.0';

import { authorizeClient } from '@/api/authorize-client';
import { extractAxiosData } from '@/common/services/extract-axios-data';
import { ICoursePlayerPage } from '@/courses/models';

const playerPageApiClient = authorizeClient(PlayerPageApiFactory);
const playerCourseApiClient = authorizeClient(PlayerCourseApiFactory);
const questionPlayerApiClient = authorizeClient(QuestionPlayerApiFactory);
export function getCoursePlayerPageDetails({ courseId, chapterId, pageId }) {
    return playerPageApiClient
        .getPageWithStructure(courseId, chapterId, pageId)
        .then(extractAxiosData) as Promise<ICoursePlayerPage>;
}

export function getPlayerCourse(id: number): Promise<any> {
    return playerCourseApiClient.getCourseStructure(id).then(extractAxiosData);
}

export function getLastAttempt(guid: string, questionType: QuestionType) {
    return questionPlayerApiClient
        .getLastAttempt(questionType, guid)
        .then(extractAxiosData) as Promise<QuestionAttemptResult>;
}

export function submitAttempt(request: SubmitAttemptRequest, questionType: QuestionType) {
    return questionPlayerApiClient
        .submitAnswer(questionType, request)
        .then(extractAxiosData) as Promise<QuestionAttemptResult>;
}

export interface SubmitAttemptRequest {
    question_guid: Guid;
    options: Guid[];
}
export interface QuestionAttemptResult {
    question_guid: Guid;
    passed: boolean;
    options: Guid[];
}
export type Guid = string;
