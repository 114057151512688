import { type ForgotPasswordRequest } from '@eloomi/eloomi-users-external-client/1.0';
import axios from 'axios';
import { inject, injectable } from 'tsyringe';

import { ServiceTokens } from '@/di/tokens';

import { AuthenticationServiceTokens } from '../di-tokens';
import { InvalidAccountProvided, InvalidEmailProvided } from './errors';
import type { ForgotPasswordApiClient, PlatformResolver } from './interfaces';

const enum ForgotPasswordTypeError {
    EMAIL_DOESNT_EXIST = 'User account has no email',
    ACCOUNT_DOESNT_EXIST = 'User account not found',
}

@injectable()
export class ForgotPasswordService {
    constructor(
        @inject(ServiceTokens.PlatformResolutionService)
        private platformResolver: PlatformResolver,

        @inject(AuthenticationServiceTokens.ForgotPasswordApiClient)
        private forgotPasswordApiClient: ForgotPasswordApiClient
    ) {}

    public async requestPasswordReset(email: string) {
        const request: ForgotPasswordRequest = { user_email: email };
        const { id } = await this.platformResolver.getPlatformData();
        try {
            await this.forgotPasswordApiClient.forgotPassword(id, request);
        } catch (error) {
            if (
                axios.isAxiosError(error) &&
                error.response?.status === 400 &&
                error.response.data === ForgotPasswordTypeError.EMAIL_DOESNT_EXIST
            ) {
                throw new InvalidEmailProvided();
            }

            if (
                axios.isAxiosError(error) &&
                error.response?.status === 400 &&
                error.response.data === ForgotPasswordTypeError.ACCOUNT_DOESNT_EXIST
            ) {
                throw new InvalidAccountProvided();
            }

            throw error;
        }
    }
}
