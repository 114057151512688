import { DashboardApiFactory, UserEventApiFactory } from '@eloomi/eloomi-event-management-persona-client/1.0';

import { authorizeClient } from '@/api/authorize-client';
import { SelectedOption } from '@/common/components/table';
import { MaxPageSizeForApi } from '@/common/constants/api';
import { buildSort } from '@/common/services';
import { extractAxiosData } from '@/common/services/extract-axios-data';

const userEventApiClient = authorizeClient(UserEventApiFactory);
const dashboardApiClient = authorizeClient(DashboardApiFactory);

export function getOverviewEvents({
    page = 1,
    pageSize = 10,
    filters,
    sorts = null,
}: {
    filters?: string;
    page: number;
    pageSize: number;
    sorts?: SelectedOption | null;
}) {
    return userEventApiClient.getUserSelfEventsSearch(filters, buildSort(sorts), page, pageSize).then(extractAxiosData);
}

export function getEventsSectionsList() {
    return dashboardApiClient.getLiteDashboardSections().then(extractAxiosData);
}

export function getEventsDashboardSection({ key, page, pageSize }: { key: string; page: number; pageSize: number }) {
    return dashboardApiClient.getDashboardSection(key, undefined, undefined, page, pageSize).then(extractAxiosData);
}

export function getEventsDashboardSections({
    keys,
    page = 1,
    pageSize = 4,
}: {
    keys: string[];
    page?: number;
    pageSize?: number;
}) {
    return dashboardApiClient.getDashboardSections(keys, undefined, undefined, page, pageSize).then(extractAxiosData);
}

export function getUserEventCategories() {
    return userEventApiClient
        .getUserSelfCategories(undefined, undefined, undefined, MaxPageSizeForApi)
        .then(extractAxiosData);
}

export function getUserEventTopics() {
    return userEventApiClient
        .getUserSelfTopics(undefined, undefined, undefined, MaxPageSizeForApi)
        .then(extractAxiosData);
}
