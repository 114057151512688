<template>
    <div ref="runner" class="tab-runner" :class="`theme-${theme}`"></div>
</template>

<script lang="ts">
    import { PropType } from 'vue';
    import { Component, Emit, Prop, Ref, Vue, Watch } from 'vue-property-decorator';

    import { TabsTheme } from './types';

    @Component
    export default class TabButtonAnimation extends Vue {
        @Prop({ type: Array }) public refs!: HTMLElement[];
        @Prop({ type: Number }) public activeTabIndex!: number;
        @Prop({ default: 'normal', type: String as PropType<TabsTheme> }) public theme!: TabsTheme;
        @Ref('runner') public runner!: HTMLElement;

        private originalTransition = 'none';

        public mounted() {
            this.$nextTick(() => {
                this.runner.addEventListener('transitionend', this.handleTransitionEnd);
            });
        }

        public beforeDestroy() {
            this.runner.removeEventListener('transitionend', this.handleTransitionEnd);
        }

        @Emit('end')
        private handleTransitionEnd() {
            this.setRunnerVisible(false);
        }

        @Watch('refs')
        public handleRefsUpdate() {
            this.initStyles();
        }

        private initStyles() {
            const styles = this.getItemStyles(this.activeTabIndex);
            if (styles) {
                this.originalTransition = getComputedStyle(this.runner).transition;
                this.applyItemStyles(styles);
            }
        }

        private getItemStyles(itemIndex) {
            const el = this.refs[itemIndex];
            if (!el) {
                return null;
            }
            const rect = el.getBoundingClientRect();

            return {
                width: `${rect.width}px`,
                height: `${rect.height}px`,
                left: `${el.offsetLeft}px`,
                top: `${el.offsetTop}px`,
            };
        }

        private applyItemStyles({ width, height, left, top }) {
            this.runner.style.width = width;
            this.runner.style.height = height;
            this.runner.style.transform = `translate(${left}, ${top})`;
        }

        private setRunnerVisible(toggle: boolean) {
            this.runner.style.visibility = toggle ? 'visible' : 'hidden';
        }

        private setAnimatedTransitions(toggle: boolean) {
            this.runner.style.transition = toggle ? this.originalTransition : 'none';
        }

        @Watch('activeTabIndex')
        public handleTabIndexUpdate(newIndex: number, oldIndex: number) {
            this.setAnimatedTransitions(false);
            const styles = this.getItemStyles(oldIndex);
            if (styles) {
                this.applyItemStyles(styles);
                requestAnimationFrame(() => {
                    this.setAnimatedTransitions(true);
                    this.setRunnerVisible(true);
                    const newStyles = this.getItemStyles(newIndex);
                    if (newStyles) {
                        this.applyItemStyles(newStyles);
                    }
                });
            }
        }
    }
</script>

<style lang="less" scoped>
    .tab-runner {
        position: absolute;
        top: 0;
        left: 0;
        background-color: @primary-color-8;
        border-radius: @border-radius-6;
        transform: translate(0, 0);
        visibility: hidden;
        pointer-events: none;

        &.theme-dark {
            background-color: @bright-color;
        }

        .generic-transition(~'transform, width, height');
    }
</style>
