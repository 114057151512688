import { PluginObject } from 'vue';
import VueRouter, { RawLocation, Route } from 'vue-router';

interface RouteEntry {
    key: string;
    route: Route;
}

interface HistoryState {
    key: string;
}

export interface RouteStack {
    routeEntryList: Array<RouteEntry>;
    back(fallbackLocation: RawLocation): void;
    getPreviousRoute(): Route | null;
    resolvePreviousLocationHref(fallbackLocation?: RawLocation): Route;
}

const RouteStackManager = {
    createPlugin(router: VueRouter): PluginObject<undefined> {
        return {
            install(Vue) {
                const reactiveRouteStack = Vue.observable<RouteStack>({
                    routeEntryList: [],
                    back(fallbackLocation: RawLocation) {
                        const previousRoute = this.getPreviousRoute();
                        if (previousRoute) {
                            router.back();
                        } else {
                            reactiveRouteStack.routeEntryList = [];
                            router.replace(fallbackLocation);
                        }
                    },
                    getPreviousRoute(): Route | null {
                        if (this.routeEntryList.length < 2) return null;

                        const previousRouteEntry = this.routeEntryList[this.routeEntryList.length - 2];
                        return previousRouteEntry ? previousRouteEntry.route : null;
                    },
                    resolvePreviousLocationHref(fallbackLocation: RawLocation): Route {
                        const previousRoute = this.getPreviousRoute();
                        return previousRoute ? previousRoute : router.resolve(fallbackLocation).route;
                    },
                });

                router.afterEach(to => {
                    /** Initial route has no state */
                    const historyState: HistoryState = history.state || { key: 'initial' };

                    /** `key` is provided by `vue-router`. It's unique for routes */
                    const newEntry: RouteEntry = { key: historyState.key, route: to };

                    const foundIndex = reactiveRouteStack.routeEntryList.findIndex(
                        routeEntry => routeEntry.key === newEntry.key
                    );

                    const isRouteVisitedBefore = foundIndex !== -1;

                    if (isRouteVisitedBefore) {
                        reactiveRouteStack.routeEntryList = reactiveRouteStack.routeEntryList.slice(0, foundIndex);
                    }

                    reactiveRouteStack.routeEntryList.push(newEntry);
                });

                Vue.prototype.$routeStack = reactiveRouteStack;
            },
        };
    },
};

export default RouteStackManager;
