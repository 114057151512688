var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DatePicker',{ref:"datepicker",staticClass:"date-picker",class:_vm.additionalClass,attrs:{"mode":"single","isExpanded":"","isRange":_vm.isRange,"value":_vm.value,"minDate":_vm.minDate,"maxDate":_vm.maxDate,"popover":_vm.popover,"inputProps":_vm.inputProps,"selectAttribute":{
        highlight: {
            class: 'selected-date-highlight',
            contentClass: 'selected-date-content',
        },
    },"dragAttribute":{
        highlight: {
            class: 'selecting-date-highlight',
            contentClass: 'selecting-date-content',
        },
    },"masks":_vm.masks},on:{"input":_vm.emitInput},scopedSlots:_vm._u([(!_vm.isInline)?{key:"default",fn:function(ref){
    var inputValue = ref.inputValue;
    var updateValue = ref.updateValue;
    var showPopover = ref.showPopover;
    var hidePopover = ref.hidePopover;
return [_c('TextField',{ref:"textFieldRef",attrs:{"isInvalid":_vm.isInvalid,"isDisabled":_vm.isDisabled,"value":_vm.convertValueToText(inputValue),"placeholder":_vm.placeholder,"testId":"calendar-input","size":"small"},on:{"change":function($event){return _vm.handleChangeInputEvent(updateValue, $event)},"focus":showPopover,"blur":hidePopover},scopedSlots:_vm._u([{key:"left",fn:function(ref){
    var focus = ref.focus;
return [_vm._t("left"),(_vm.withLeftIcon)?_c('elm-date-time-calendar-icon',{staticClass:"icon-left",attrs:{"size":"18"},on:{"click":function($event){return focus()}}}):_vm._e()]}}],null,true)})]}}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }