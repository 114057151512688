export enum MISC_COLORS {
    BLUE = 'var(--color-misc-blue)',
    BLUE_80 = 'var(--color-misc-blue-80)',
    BLUE_60 = 'var(--color-misc-blue-60)',
    BLUE_40 = 'var(--color-misc-blue-40)',
    BLUE_20 = 'var(--color-misc-blue-20)',
    DANGER = 'var(--color-danger)',
    DANGER_80 = 'var(--color-danger-80)',
    DANGER_60 = 'var(--color-danger-60)',
    DANGER_40 = 'var(--color-danger-40)',
    DANGER_20 = 'var(--color-danger-20)',
    GREEN = 'var(--color-misc-green)',
    GREEN_80 = 'var(--color-misc-green-80)',
    GREEN_60 = 'var(--color-misc-green-60)',
    GREEN_40 = 'var(--color-misc-green-40)',
    GREEN_20 = 'var(--color-misc-green-20)',
    GREY = 'var(--color-misc-grey)',
    GREY_80 = 'var(--color-misc-grey-80)',
    GREY_60 = 'var(--color-misc-grey-60)',
    GREY_40 = 'var(--color-misc-grey-40)',
    GREY_20 = 'var(--color-misc-grey-20)',
    YELLOW = 'var(--color-misc-yellow)',
    YELLOW_80 = 'var(--color-misc-yellow-80)',
    YELLOW_60 = 'var(--color-misc-yellow-60)',
    YELLOW_40 = 'var(--color-misc-yellow-40)',
    YELLOW_20 = 'var(--color-misc-yellow-20)',
    SUCCESS = 'var(--color-success)',
    WARNING = 'var(--color-warning)',
    INFO = 'var(--color-info)',
    INFO_80 = 'var(--color-info-80)',
    INFO_60 = 'var(--color-info-60)',
    INFO_40 = 'var(--color-info-40)',
    INFO_20 = 'var(--color-info-20)',
    INFO_8 = 'var(--color-info-8)',
    ORANGE = 'var(--color-misc-orange)',
    PURPLE = 'var(--color-misc-purple)',
}

export enum PRIMARY_COLORS {
    PRIMARY = 'var(--color-primary)',
}
