import axios from 'axios';
import { inject, injectable } from 'tsyringe';

import type { UserProfile } from '@/common/interfaces';
import { makeLogger } from '@/common/services';
import type { UserDetailsStoreService } from '@/common/store/user-details/store';
import { ServiceTokens as BaseTokens } from '@/di/tokens';

import { AnonymousUser, UserWithPermissions } from '../domain';
import { UserWithPermissionsProvider } from '../use-cases/ports';

/**
 * This class provides an access to current user profile via vuex store module service.
 */
@injectable()
export class UserProfileStoreProviderAdapter implements UserWithPermissionsProvider {
    private log = makeLogger('user-profile-store-provider-adapter');

    public constructor(
        @inject(BaseTokens.UserDetailsStoreService) private readonly userProfileStore: UserDetailsStoreService
    ) {}

    public async getCurrentUser(): Promise<UserWithPermissions> {
        try {
            const user = (await this.userProfileStore.loadUserProfile()) as UserProfile | null;
            return new UserWithPermissions(user?.permissions ?? []);
        } catch (error) {
            if (axios.isAxiosError(error) && error.response?.status === 403) {
                this.log(
                    `unable to get authenticated user profile` +
                        `, falling back to anonymous since user profile is forbidden`
                );
                return new AnonymousUser();
            }
            throw error;
        }
    }
}
