import { usePlanFeaturesStore } from '@/plan-features/store';

export function usePlanFeatures() {
    const store = usePlanFeaturesStore();

    return {
        checkIfFeatureEnabled: store.getters.checkIfFeatureEnabled,
        featuresList: store.state.featuresList,
    };
}

export default usePlanFeatures;
