import { CourseProvider, CourseType } from '@eloomi/eloomi-learning-persona-client/2.0';
import { Getters } from 'vuex-smart-module';

import { ICoursePlayerChapter, ICoursePlayerPage } from '@/courses/models';

import { CoursePlayerState } from './state';

export class UserCoursesGetters extends Getters<CoursePlayerState> {
    public get activeChapter(): ICoursePlayerChapter | null {
        return this.getters.getChapter(this.state.chapterId);
    }

    public get activePage(): ICoursePlayerPage | null {
        return this.getters.activeChapter?.pages.find(page => page.id === this.state.pageId) || null;
    }

    public get isCurrentPageCompleted(): boolean {
        return this.getters.activePage?.is_completed || false;
    }

    public get isCoursePageLoaded() {
        return Boolean(this.state.currentPageDetails) && this.state.currentPageDetails?.id === this.state.pageId;
    }

    public getChapter(chapterId): ICoursePlayerChapter | null {
        return this.state.course?.chapters.find(chapter => chapter.id === chapterId) || null;
    }

    public activeCourseHasChapterWithPage(chapterId, pageId): boolean {
        const chapter = this.getters.getChapter(chapterId);

        return chapter?.pages.some(page => page.id === pageId) || false;
    }

    public get isOnHomePage() {
        const ids: number | null = this.state.chapterId || this.state.pageId;
        return Number.isNaN(ids) || ids == null;
    }

    public get currentPageOrder(): number {
        const activeChapter = this.getters.activeChapter;

        if (activeChapter) {
            return activeChapter.pages.findIndex(page => {
                return page.id === this.getters.activePage?.id;
            });
        }

        return 0;
    }

    public get firstUncompletedPageAndChapter(): { chapter?: ICoursePlayerChapter; page?: ICoursePlayerPage } {
        const notCompletedChapter =
            this.state.course?.chapters.find(chapter => chapter.pages.some(page => !page.is_completed)) ||
            this.state.course?.chapters[0];

        const notCompletePage =
            notCompletedChapter?.pages.find(page => !page.is_completed) || notCompletedChapter?.pages[0];

        return {
            chapter: notCompletedChapter,
            page: notCompletePage,
        };
    }

    public get isEmptyCourse(): boolean {
        if (!this.state.course) {
            return false;
        }

        if (
            this.state.course.course_type === CourseType.Scorm ||
            this.state.course.course_provider !== CourseProvider.Client
        )
            return false;

        return !this.state.course.chapters.length || !this.state.course.chapters[0].pages.length;
    }
}
