import { inject, injectable } from 'tsyringe';

import { AuthenticationServiceTokens as Tokens } from '../di-tokens';
import type { ActivateUserService, JWT, VueRouterRedirectionService } from '../services';
import { SessionContainer } from '../session/session-container';
import type { LogoutUseCase } from './logout';

/**
 * User has received an activation email and wants to setup an account.
 */
@injectable()
export class ActivateUserUseCase {
    public constructor(
        @inject(Tokens.SessionContainer)
        private readonly sessionContainer: SessionContainer<JWT>,

        @inject(Tokens.RedirectService)
        private readonly redirectService: VueRouterRedirectionService,

        @inject(Tokens.ActivateUserService)
        private readonly activateUserService: ActivateUserService,

        @inject(Tokens.LogoutUseCase)
        private readonly logoutUseCase: LogoutUseCase
    ) {}

    public async activateUser(token: JWT, password: string) {
        if (this.sessionContainer.isLoggedIn()) {
            await this.logoutUseCase.logOut(false).catch(console.error);
        }
        const jwt = await this.activateUserService.activateUser(token, password);
        this.sessionContainer.startSession(jwt);
        await this.redirectService.redirectToDefaultPage();
    }
}
