import { UserPermissionsApiFactory } from '@eloomi/eloomi-users-persona-client/1.0';

import { authorizeClient } from '@/api/authorize-client';
import { getClientType } from '@/common/services/get-device-platform';
import environment from '@/environment';

import { AppFeaturesList, FeatureTogglesMap } from '../models';

const userAdminPersonaApiClient = authorizeClient(UserPermissionsApiFactory);

export function getFeaturesState() {
    const clientType = getClientType();
    const featureList = Object.values(AppFeaturesList);

    return userAdminPersonaApiClient
        .getUserPermissions(featureList, environment.clientVersion, clientType)
        .then(({ data: flags }) => {
            const featureMapping = Object.fromEntries(
                featureList.map(key => [
                    key,
                    {
                        value: false,
                        data: null,
                    },
                ])
            );

            for (const { feature_name, value, data } of flags) {
                if (feature_name && featureMapping[feature_name]) {
                    featureMapping[feature_name].value = Boolean(value);
                    featureMapping[feature_name].data = data;
                }
            }
            return featureMapping as FeatureTogglesMap;
        });
}
