import environment from '@/environment';
import { FeatureTogglesMap } from '@/launch-darkly/models';

declare global {
    interface Window {
        getPredefinedFeatureToggles?(): Promise<FeatureTogglesMap>;
    }
}

export const PREDEFINED_FEATURE_TOGGLES_KEY = 'predefined_feature_toggles';

function getFeatureTogglesFromLocalStorage(): FeatureTogglesMap {
    try {
        const value = localStorage.getItem(PREDEFINED_FEATURE_TOGGLES_KEY);
        if (!value) return {};

        return JSON.parse(value);
    } catch (error) {
        return {};
    }
}

async function getFeatureTogglesFromWindow(): Promise<FeatureTogglesMap> {
    return window.getPredefinedFeatureToggles ? window.getPredefinedFeatureToggles() : {};
}

export async function getPredefinedFeatureToggles(): Promise<FeatureTogglesMap> {
    if (environment.type === 'production') return {};

    const featureTogglesFromStorage = getFeatureTogglesFromLocalStorage();
    const featureTogglesFromWindow = await getFeatureTogglesFromWindow();

    return { ...featureTogglesFromStorage, ...featureTogglesFromWindow };
}
