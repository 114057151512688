import { LoginSessionLoadError } from '../services/errors';
import { JWT } from '../services/interfaces';

export class SessionJWTStore {
    public constructor(private readonly storageKey: string, data?: JWT) {
        if (data) {
            this.write(data);
        }
    }

    public read(): JWT {
        const cachedData = localStorage.getItem(this.storageKey);
        if (cachedData !== null) {
            return cachedData as JWT;
        }
        throw new LoginSessionLoadError();
    }

    public clear() {
        localStorage.removeItem(this.storageKey);
    }

    public write(data: JWT) {
        localStorage.setItem(this.storageKey, data);
    }
}
