import { inject, injectable } from 'tsyringe';

import { makeWarnLogger } from '@/common/services';

import { AuthenticationServiceTokens } from '../di-tokens';
import type { SessionContainer } from '../session/session-container';

@injectable()
export class HandleHttpForbiddenStatusUseCase {
    private log = makeWarnLogger('http-forbidden-handler');

    public constructor(
        @inject(AuthenticationServiceTokens.SessionContainer)
        private readonly sessionContainer: SessionContainer
    ) {}

    public execute() {
        // Force session renewal in order to check if we still have an access (current user is deactivated / deleted)
        // So it may lead to triggering the session container fail handler (check enable-middleware.ts for that)
        this.log('403 detected, going to try session refresh');
        this.sessionContainer.forceSessionRenewal();

        return true;
    }
}
