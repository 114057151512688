import type { ActivateUserRequest } from '@eloomi/eloomi-users-external-client/1.0';
import axios from 'axios';
import { inject, injectable } from 'tsyringe';
import type { Store } from 'vuex';

import { ServiceTokens as BaseTokens } from '@/di/tokens';

import { AuthenticationServiceTokens } from '../di-tokens';
import { SessionContainer } from '../session/session-container';
import { PlatformJWTPayload, PlatformServiceBase } from './base/platform-service-base';
import { EmptyJwtTokenError } from './errors';
import { JWT, PlatformResolver, UserActivationApiClient } from './interfaces';
import { getJWTPayload } from './payload';
import { getDefaultTokenValidationResult, TokenValidationResult } from './token-manager';

export interface ActivateUserJWTPayload extends PlatformJWTPayload {
    'user-id': string;
    'user-email': string;
    'activation-code': string;
}

@injectable()
export class ActivateUserService extends PlatformServiceBase {
    constructor(
        @inject(AuthenticationServiceTokens.UserActivationApiClient)
        private readonly userActivationApiClient: UserActivationApiClient,

        @inject(BaseTokens.PlatformResolutionService)
        platformResolver: PlatformResolver,

        @inject(BaseTokens.BaseStore)
        baseStore: Store<unknown>,

        @inject(AuthenticationServiceTokens.SessionContainer)
        sessionContainer: SessionContainer<JWT>
    ) {
        super(platformResolver, baseStore, sessionContainer);
    }

    protected verifyToken = async (token: string): Promise<TokenValidationResult> => {
        let isCodeValid: boolean;
        try {
            const validationResult = await this.userActivationApiClient.verifyActivation(token);
            isCodeValid = validationResult.data.success ?? false;
        } catch {
            isCodeValid = false;
        }

        return getDefaultTokenValidationResult(isCodeValid);
    };

    public static getPayload(token: JWT): ActivateUserJWTPayload {
        return getJWTPayload<ActivateUserJWTPayload>(token, [
            'eloomi-platform-id',
            'user-id',
            'user-email',
            'activation-code',
        ]);
    }

    public async activateUser(token: JWT, password: string): Promise<JWT> {
        const payload = ActivateUserService.getPayload(token);
        await this.validatePlatform(payload);

        const request: ActivateUserRequest = { password };

        try {
            const response = await this.userActivationApiClient.activateUser(token, request);
            const jwtToken = response.data.token;

            if (!jwtToken) {
                throw new EmptyJwtTokenError();
            }

            return jwtToken as JWT;
        } catch (error) {
            if (axios.isAxiosError(error) && error.response?.status === 400) {
                /**
                 * Password validation etc should be implemented in the upcoming ticket
                 */
                throw new Error('Unknown endpoint error');
            }
            throw error;
        }
    }
}
