import { Vue } from 'vue-property-decorator';
import { Actions, createMapper, Getters, Module, Mutations } from 'vuex-smart-module';

import { createUIStoreServiceFactory } from '@/di/store-factory';
import environment from '@/environment';

class State {
    public loginState: 'login' | 'forgot-password' | 'reset-password' | 'setup-account' | 'choose-platform' =
        environment.platformHostContext ? 'login' : 'choose-platform';
}

class StoreGetters extends Getters<State> {
    public get isBackButtonShownOnLoginForm() {
        return !environment.platformHostContext;
    }
}

class StoreActions extends Actions<State, StoreGetters, StoreMutations, StoreActions> {
    public goBack() {
        if (this.state.loginState === 'login' && !environment.platformHostContext) {
            this.mutations.setChoosePlatformState();
        } else {
            this.mutations.setLoginState();
        }
    }

    public proceedToLogin() {
        if (this.state.loginState === 'choose-platform') {
            this.mutations.setLoginState();
        }
    }

    public goToForgotPassword() {
        if (this.state.loginState === 'login') {
            this.mutations.setForgotPasswordState();
        }
    }
}

class StoreMutations extends Mutations<State> {
    public setLoginState() {
        this.state.loginState = 'login';
    }

    public setForgotPasswordState() {
        this.state.loginState = 'forgot-password';
    }

    public setChoosePlatformState() {
        this.state.loginState = 'choose-platform';
    }
}

const store = new Module({
    state: State,
    actions: StoreActions,
    mutations: StoreMutations,
    getters: StoreGetters,
});

const authFlowStoreMapper = createMapper(store);

export const AuthFlowMappedStoreMixin = Vue.extend({
    computed: {
        ...authFlowStoreMapper.mapState(['loginState']),
        ...authFlowStoreMapper.mapGetters(['isBackButtonShownOnLoginForm']),
    },
    methods: {
        ...authFlowStoreMapper.mapActions(['goBack', 'goToForgotPassword', 'proceedToLogin']),
    },
});

export const AuthFlowStoreServiceFactory = createUIStoreServiceFactory({
    ...authFlowStoreMapper.mapState(['loginState']),
    ...authFlowStoreMapper.mapGetters(['isBackButtonShownOnLoginForm']),
    ...authFlowStoreMapper.mapActions(['goBack', 'goToForgotPassword', 'proceedToLogin']),
});

export type AuthFlowStoreService = ReturnType<typeof AuthFlowStoreServiceFactory>;

export default store;
