import { UsersReportingApiFactory } from '@eloomi/eloomi-learning-persona-client/1.0';

import { authorizeClient } from '@/api/authorize-client';
import { extractAxiosData } from '@/common/services/extract-axios-data';
import { AssignedCourseUserCompletionStatus } from '@/reporting/common/models';

const usersReportingClient = authorizeClient(UsersReportingApiFactory);

interface PersonalReportOptions {
    userId: number;
    filters?: string;
    sorts?: string;
    page: number;
    pageSize: number;
}

export function getPersonalCoursesReport(options: PersonalReportOptions) {
    return usersReportingClient
        .getUserCoursesReport(options.userId, options.filters, options.sorts, options.page, options.pageSize)
        .then(extractAxiosData);
}

export interface UserReportCourse {
    title: string;
    image: string;
    duration: number;
    deadline: string | null;
    status: AssignedCourseUserCompletionStatus;
    time_spent: number;
    started_at: string;
    completed_at: string;
    views: number;
    is_required: boolean;
    is_archived: boolean;
    id: number;
}
