import { BrandingApiFactory, BrandingResponse } from '@eloomi/eloomi-platforms-persona-client/1.0';

import { authorizeClient } from '@/api/authorize-client';
import { extractAxiosData } from '@/common/services/extract-axios-data';

const brandingApiClient = authorizeClient(BrandingApiFactory);

export function withDefaultValues(response: BrandingResponse): BrandingResponse {
    return {
        ...response,
        branding_color: response.branding_color ?? '#0030c0',
        primary_color: response.primary_color ?? '#00abed',
        branding_text_color: response.branding_text_color ?? '#ffffff',
    };
}

export async function getBranding() {
    const brandingResponse = await brandingApiClient.getBranding().then(extractAxiosData);
    return withDefaultValues(brandingResponse);
}
