/**
 * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
 *
 * @param text The text to be rendered.
 * @param {String} font The css font descriptor that text is to be rendered with (e.g. "14px verdana").
 *
 * @see http://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
 * @see http://jsfiddle.net/eNzjZ/70/
 */

function getTextWidthDOM(
    text,
    font = "600 14px 'Proxima Nova', -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Helvetica, Arial, sans-serif;"
) {
    const span = document.createElement('span');
    span.classList.add('test-text');
    span.style.font = font;
    span.style.float = 'left';
    span.style.whiteSpace = 'nowrap';
    span.style.lineHeight = '20px';
    span.style.letterSpacing = '0.5';
    span.style.textTransform = 'uppercase';
    span.style.fontWeight = '600';
    span.textContent = text;

    document.body.appendChild(span);

    const width = span.offsetWidth;

    document.body.removeChild(span);

    return width;
}

export function getTextWidth(
    text,
    font = "600 14px 'Proxima Nova', -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Helvetica, Arial, sans-serif;"
) {
    return getTextWidthDOM(text, font);
}
