import { RouteConfig } from 'vue-router';

import { AppFeaturesList } from '@/launch-darkly/models';
import { checkIfAvailableByLaunchDarkly } from '@/launch-darkly/routes/launch-darkly.guard';

const CONVERSATION = () => import('@/conversations/pages/ConversationPage.vue');

export enum ConversationRouteName {
    CONVERSATION = 'conversation',
}

const routes: RouteConfig[] = [
    {
        path: 'conversations/:conversation_id(\\d+)',
        name: ConversationRouteName.CONVERSATION,
        meta: {
            title: 'Conversation',
        },
        beforeEnter: checkIfAvailableByLaunchDarkly(AppFeaturesList.CONVERSATIONS),
        component: CONVERSATION,
    },
];

export default routes;
