/* eslint-disable unicorn/prefer-top-level-await */

import '@abraham/reflection';

// eslint-disable-next-line import/newline-after-import
import { baseContainer } from '@/di/container';
registerAllServices(baseContainer);

import 'focus-visible';
import 'billboard.js/dist/billboard.css';
import 'billboard.js/dist/theme/graph.css';
import '../styles/static.less';
import 'swiper/css/bundle';
import 'billboard.js/dist/theme/graph.min.css';
import '@eloomi/design-tokens/build/base.css';
import '@eloomi/design-tokens/build/colors.css';
import '@eloomi/design-tokens/build/spacings.css';
import '@eloomi/design-tokens/build/text-styles.css';
import '@eloomi/design-tokens/build/border-radius.css';
import '@eloomi/components/button';
import '@/common/directives/custom-model';

import { PiniaVuePlugin } from 'pinia';
import PortalVue from 'portal-vue';
import VCalendar from 'v-calendar';
// eslint-disable-next-line import/no-named-as-default
import VTooltip from 'v-tooltip';
import Vue from 'vue';
import InfiniteLoading from 'vue-infinite-loading';
import VueLazyload from 'vue-lazyload';
import VueTippy from 'vue-tippy';

import { applyPlatformDesign } from '@/admin/branding/services/apply-platform-design';
import { enableApplicationInsightsTelemetry } from '@/ai';
import { enableHttpRetryMechanism } from '@/api/config';
import httpClient from '@/api/http-client';
import { enableInfiniteAuthMiddleware } from '@/authentication/controllers/enable-middleware';
import { ThirdPartyServiceType } from '@/authentication/services/interfaces';
import commonPlugin from '@/common/plugins';
import { assignCapacitorPlatformDataAttribute } from '@/common/services/capacitor-css';
import { viewHeightCorrector } from '@/common/services/correct-view-height';
import { enableDebugForNamespacesFromEnv } from '@/common/services/debug-helper';
import detectBrowser from '@/common/services/detect-browser';
import { fullStory } from '@/common/services/fullstory';
import type { PlatformResolutionService } from '@/common/services/platform-resolution';
import { segment } from '@/common/services/segment';
import { subscribeForDeepLinkOpen } from '@/common/services/subscribe-for-deep-link-open';
import { registerAllServices } from '@/di/registrations';
import environment, { ApiAuthTypes } from '@/environment';
import { handleEndpointError } from '@/error-handler';
import router from '@/main/base-router';
import store from '@/main/base-store';
import { initPushNotifications } from '@/notifications/services/push-notifications';
import { installPermissionsRouteGuard } from '@/permissions/controllers';
import DependencyContainerPlugin from '@/plugins/container.plugin';
import FullStory from '@/plugins/fullstory.plugin';
import RouteStackManager from '@/plugins/route-stack.plugin';
import { renderApp } from '@/render-app';
import { initializeSentry } from '@/sentry';

import { ServiceTokens } from './di/tokens';
/**
 * Enable AI telemetry
 */
enableApplicationInsightsTelemetry(store);

/**
 * Setup an initial debug.js configuration using ENV var.
 */
enableDebugForNamespacesFromEnv();

Vue.config.productionTip = false;

/**
 * Assign platform data attribute to body tag
 */
assignCapacitorPlatformDataAttribute();

/**
 * Configure a JWT token injection, HTTP 403 error handling & route transition checks.
 */
if (environment.apiAuthMode === ApiAuthTypes.token) {
    enableInfiniteAuthMiddleware(httpClient);
}

/**
 * Install user permissions route guard
 */
installPermissionsRouteGuard(baseContainer);

Vue.use(DependencyContainerPlugin, baseContainer);
Vue.use(commonPlugin);
Vue.use(VueTippy, {
    directive: 'tippy',
    theme: 'eloomi',
    placement: 'bottom',
    onShow: options => Boolean(options.props.content),
});

Vue.use(VTooltip);
Vue.use(VueLazyload);

Vue.use(InfiniteLoading, {
    slots: {
        noMore: '',
        noResults: '',
        error: '',
    },
    system: {
        throttle: 200,
    },
});
Vue.use(VCalendar, {
    componentPrefix: 'vc',
});
Vue.use(PortalVue);
Vue.use(RouteStackManager.createPlugin(router));
Vue.use(PiniaVuePlugin);

viewHeightCorrector.init();

enableHttpRetryMechanism(store, httpClient);
handleEndpointError(router, store);
detectBrowser();

const initializeAnalyticTools = () => {
    const isFullStoryEnabled = platformResolutionService.isThirdPartyServiceIntegrationEnabled(
        ThirdPartyServiceType.FULL_STORY
    );
    const isSegmentEnabled = platformResolutionService.isThirdPartyServiceIntegrationEnabled(
        ThirdPartyServiceType.SEGMENT
    );
    const isInternalPlatform = platformResolutionService.getLocalPlatformData()!.is_internal;

    segment.init(isSegmentEnabled, isInternalPlatform);
    fullStory.init(isFullStoryEnabled);
    Vue.use(FullStory);
    initializeSentry(router);
};

const platformResolutionService = baseContainer.resolve<PlatformResolutionService>(
    ServiceTokens.PlatformResolutionService
);

platformResolutionService
    .resolvePlatform()
    .then(({ isPlatformFound, isPlatformActive }) => {
        // eslint-disable-next-line promise/always-return
        if (isPlatformFound && isPlatformActive) {
            initializeAnalyticTools();
            applyPlatformDesign();
        }

        renderApp({ isPlatformFound, isPlatformActive });
    })
    .catch(error => console.error(error));

subscribeForDeepLinkOpen();
initPushNotifications(router);
