<template>
    <div class="selector-no-options">
        <p class="message h4">No options</p>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'SelectorNoResult',
    });
</script>

<style lang="less" scoped>
    .selector-no-options {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 200px;
        .message {
            margin: 0;
        }
    }
</style>
