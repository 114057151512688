export default {
    install(vue: any) {
        vue.directive('style-background-primary', {
            bind(el: any) {
                el.style.background = 'red';
            },
        });

        vue.directive('style-color-primary', {
            bind(el: any) {
                el.style.color = '#40bbea';
            },
        });
    },
};
