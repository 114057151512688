import { NotificationResponse, TemplateKey } from '@eloomi/eloomi-notifications-persona-client/1.0';
import { RawLocation } from 'vue-router';

import { CoursesRouteNames } from '@/courses/route-definitions';
import { EventsRoutesNames } from '@/events/routes';
import { getBaseStore } from '@/main/get-base-store-router';
import translationsStore from '@/translations/store';

export function getNotificationText(notification: NotificationResponse) {
    const trans = translationsStore.context(getBaseStore()).getters.trans;
    switch (notification.template_key) {
        case TemplateKey.CourseAssigned: {
            return notification.template_data?.course_title
                ? trans('spa.notification.type.course-assigned', {
                      course_title: notification.template_data.course_title,
                  })
                : trans('spa.notification.type.course-assigned.untitled');
        }
        case TemplateKey.CourseAssignedWithDeadline: {
            return notification.template_data?.course_title
                ? trans('spa.notification.type.course-assigned-withdeadline', {
                      course_title: notification.template_data.course_title,
                  })
                : trans('spa.notification.type.course-assigned-withdeadline.untitled');
        }
        case TemplateKey.EventAssigned: {
            return notification.template_data?.event_title
                ? trans('spa.notification.type.event-assigned', {
                      event_title: notification.template_data.event_title,
                  })
                : trans('spa.notification.type.event-assigned.untitled');
        }
        case TemplateKey.PlaylistAssigned: {
            return notification.template_data?.playlist_title
                ? trans('spa.notification.type.playlist-assigned', {
                      playlist_title: notification.template_data.playlist_title,
                  })
                : trans('spa.notification.type.playlist-assigned.untitled');
        }
        case TemplateKey.PlaylistAssignedWithDeadline: {
            return notification.template_data?.playlist_title
                ? trans('spa.notification.type.playlist-assigned-withdeadline', {
                      playlist_title: notification.template_data.playlist_title,
                      playlist_deadline: notification.template_data.playlist_deadline,
                  })
                : trans('spa.notification.type.playlist-assigned-withdeadline.untitled');
        }
        case TemplateKey.SessionReminder: {
            const event_days = trans('spa.notification.type.session_reminder.event_days', {
                num: notification?.template_data?.event_days,
            });

            return notification.template_data?.event_title
                ? trans('spa.notification.type.session_reminder', {
                      event_title: notification.template_data.event_title,
                      days: event_days,
                  })
                : trans('spa.notification.type.session_reminder.untitled', {
                      days: event_days,
                  });
        }
        case TemplateKey.ActionItemAssigned: {
            return notification.template_data?.action_item_title
                ? trans('spa.notification.type.action-item-assigned', {
                      action_item_title: notification.template_data.action_item_title,
                  })
                : trans('spa.notification.type.action-item-assigned.untitled');
        }
        case TemplateKey.ActionItemAssignedWithDueDate: {
            return notification.template_data?.action_item_title
                ? trans('spa.notification.type.action-item-assigned-with-duedate', {
                      action_item_title: notification.template_data.action_item_title,
                      action_item_due_date: notification.template_data.action_item_due_date,
                  })
                : trans('spa.notification.type.action-item-assigned-with-duedate.untitled');
        }
        case TemplateKey.ActionItemDueDateApproaching: {
            return notification.template_data?.action_item_title
                ? trans('spa.notification.type.action-item-due-date-approaching', {
                      action_item_title: notification.template_data.action_item_title,
                      action_item_due_date: notification.template_data.action_item_due_date,
                  })
                : trans('spa.notification.type.action-item-due-date-approaching.untitled');
        }
        case TemplateKey.ActionItemDueDateExpired: {
            return notification.template_data?.action_item_title
                ? trans('spa.notification.type.action-item-due-date-expired', {
                      action_item_title: notification.template_data.action_item_title,
                      action_item_due_date: notification.template_data.action_item_due_date,
                  })
                : trans('spa.notification.type.action-item-due-date-expired.untitled');
        }
        default: {
            break;
        }
    }
}

export function getNotificationRoute(notification: NotificationResponse): RawLocation | null {
    switch (notification.template_key) {
        case TemplateKey.CourseAssigned:
        case TemplateKey.CourseAssignedWithDeadline: {
            if (notification.template_data?.course_id) {
                return {
                    name: CoursesRouteNames.COURSE_OVERVIEW,
                    params: { course_id: notification.template_data.course_id },
                };
            }
            break;
        }
        case TemplateKey.PlaylistAssigned:
        case TemplateKey.PlaylistAssignedWithDeadline: {
            if (notification.template_data?.playlist_id) {
                return {
                    name: CoursesRouteNames.PLAYLIST_OVERVIEW,
                    params: { playlist_id: notification.template_data.playlist_id },
                };
            }
            break;
        }
        case TemplateKey.EventAssigned:
        case TemplateKey.SessionReminder: {
            if (notification.template_data?.event_id) {
                return {
                    name: EventsRoutesNames.EVENT_OVERVIEW,
                    params: { event_id: notification.template_data.event_id },
                };
            }
            break;
        }
    }
    return null;
}
