import { type BrandingResponse } from '@eloomi/eloomi-platforms-persona-client/1.0';

import { hexToHSL } from '@/common/services';
import { HSLColor } from '@/common/types';

export interface Branding extends BrandingResponse {
    brandingText?: HSLColor;
    branding?: HSLColor;
    primary?: HSLColor;
}

export function createBranding(data: BrandingResponse): Branding {
    return {
        ...data,
        branding: hexToHSL(data.branding_color!, 0),
        brandingText: hexToHSL(data.branding_text_color!, 0),
        primary: hexToHSL(data.primary_color!, 0),
    };
}
