<template>
    <router-view></router-view>
</template>

<script lang="ts">
    import { defineComponent, getCurrentInstance } from 'vue';

    import TrackMobileModeMixin from '@/common/mixins/mobile-mode.mixin';
    import environment from '@/environment';

    import { makeLogger } from './common/services';
    import type { PlatformResolutionService } from './common/services/platform-resolution';
    import { ServiceTokens } from './di/tokens';
    import { useDependencyContainer } from './di/use-injector';

    export default defineComponent({
        name: 'App',
        mixins: [TrackMobileModeMixin],
        setup() {
            const proxy = getCurrentInstance()?.proxy;
            const fullstory = proxy?.$FullStory;
            const log = makeLogger('app');
            const container = useDependencyContainer();
            const platformResolutionService = container.resolve<PlatformResolutionService>(
                ServiceTokens.PlatformResolutionService
            );

            if (fullstory) {
                const platform = platformResolutionService.getPlatformDomain();
                fullstory.setUserVars({ environment: environment.type, platform });
            }
            log(`You are on the "%s" environment`, environment.type);
            log(`Client type is %o`, environment.clientType);
            log(`Client version is %o`, environment.clientVersion);
        },
    });
</script>
