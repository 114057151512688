import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import {
    isSupported as onScreenKeyboardIsSupported,
    subscribe as subscribeToKeyboardVisibilityChanged,
} from 'on-screen-keyboard-detector';

export type KeyboardVisibilityChangedCallback = (keyboardIsVisible: boolean) => void;
export type KeyboardVisibilityChangedCleanup = () => void;

export function detectOnscreenKeyboard(onKeyboardVisibilityChanged: KeyboardVisibilityChangedCallback) {
    let cleanup: KeyboardVisibilityChangedCleanup | null = null;
    if (Capacitor.getPlatform() === 'web') {
        if (onScreenKeyboardIsSupported()) {
            cleanup = subscribeToKeyboardVisibilityChanged(visibility => {
                onKeyboardVisibilityChanged(visibility === 'visible');
            });
        }
    } else {
        Keyboard.addListener('keyboardDidShow', info => {
            document.body.style.setProperty('--keyboard-height', info.keyboardHeight + 'px');
            onKeyboardVisibilityChanged(true);
        });

        Keyboard.addListener('keyboardDidHide', () => {
            onKeyboardVisibilityChanged(false);
        });

        cleanup = () => {
            Keyboard.removeAllListeners();
        };
    }

    return cleanup;
}
