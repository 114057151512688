<template>
    <div class="error-message">
        <div v-if="hasSlot" class="error-message__icon">
            <slot></slot>
        </div>
        <h1 class="error-message__title">{{ title }}</h1>
        <p class="error-message__message">{{ message }}</p>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    @Component({})
    export default class ErrorMessage extends Vue {
        @Prop() public title!: string;
        @Prop() public message!: string;
        @Prop({ default: false }) public useBrandingBackgroundColor!: boolean;

        public get hasSlot() {
            return !!this.$slots.default;
        }
    }
</script>
<style lang="less" scoped>
    .error-message {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        &__icon {
            align-self: center;
            margin-bottom: @spacing-28;
            color: @info-color;
        }

        &__title {
            text-align: center;
            .h3-dark();

            .if-medium-desktop-and-wider({
                .h1-dark();
                margin-bottom: @spacing-16;
            });
        }

        &__message {
            .font-regular();
            .info-color();

            padding: 0 @spacing-22;
            font-size: 16px;
            line-height: 24px;
            text-align: center;

            .if-medium-desktop-and-wider({
                .paragraph-info();
            });
        }
    }
</style>
