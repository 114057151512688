<template>
    <SkeletonTheme class="skeleton-theme" :color="color" :highlight="highlightedColor" :style="{ opacity }">
        <Skeleton
            class="skeleton"
            :style="{ '--border-radius': borderRadius }"
            :width="width"
            :tag="tag"
            :count="count"
            :height="height"
            :circle="isCircle"
        />
    </SkeletonTheme>
</template>

<script lang="ts">
    import { Skeleton, SkeletonTheme } from 'vue-loading-skeleton';
    import 'vue-loading-skeleton/dist/vue-loading-skeleton.css';
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import tinycolor from 'tinycolor2';

    @Component({
        components: { Skeleton, SkeletonTheme },
    })
    export default class SkeletonLoader extends Vue {
        @Prop({ default: undefined }) public width!: string;
        @Prop({ default: undefined }) public height!: string;
        @Prop({ default: 'div' }) public tag!: string;
        @Prop({ default: 1 }) public count!: number;
        @Prop({ default: '#8296a6' }) public color!: string;
        @Prop({ default: 0.2 }) public opacity!: number;
        @Prop({ default: false }) public isCircle!: boolean;
        @Prop({ default: '8px' }) public borderRadius!: string;

        public get highlightedColor() {
            const color = tinycolor(this.color);
            return color.lighten(25).toString();
        }
    }
</script>

<style lang="less">
    .skeleton-theme {
        width: 100%;
        height: 100%;
    }

    .skeleton {
        display: flex;
        width: 100%;
        height: 100%;
        overflow: hidden;

        & > * {
            border-radius: var(--border-radius) !important;
        }
    }

    .pu-skeleton {
        border-radius: inherit;
    }
</style>
