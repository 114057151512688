import { detectOverflow, Modifier, Offsets, Options, StrictModifiers } from '@popperjs/core';
import { computed, Ref, ref } from 'vue';

export const usePopperOptions = (
    placement: Ref<Options['placement']>,
    strategy: Ref<Options['strategy']>,
    autoMaxHeight: Ref<boolean>,
    autoHide: Ref<boolean>,
    offset?: Ref<Offsets | undefined>
) => {
    const isPopperEventListenersEnabled = ref(false);
    const actualPlacement = ref(placement.value);

    const syncPlacement = computed<Modifier<'syncPlacement', Options>>(() => ({
        name: 'syncPlacement',
        enabled: true,
        /** It's intended to place the value somewhere in the DOM later */
        phase: 'write',
        fn: ({ state }) => {
            actualPlacement.value = state.placement;
        },
    }));

    const preventOverflowModifier = computed<StrictModifiers>(() => ({
        name: 'preventOverflow',
        options: {
            padding: 8,
            /**
             * Scan reference (target) context for scrollable containers to detect boundary element.
             * By default it scans popper (popover) context which isn't suitable for us since we're using portals.
             */
            altBoundary: true,
        },
    }));

    /**
     * Popper modifier which is made by community
     * {@link https://popper.js.org/docs/v2/modifiers/community-modifiers/}
     *
     * Source:
     * {@link https://github.com/atomiks/popper.js/blob/master/src/modifiers/maxSize.js}
     *
     * Docs:
     * {@link https://github.com/atomiks/popper.js/tree/master/packages/popper-max-size-modifier}
     */
    const maxSizeModifier = computed<Modifier<'maxSize', Options>>(() => ({
        name: 'maxSize',
        enabled: true,
        phase: 'main',
        requiresIfExists: ['offset', 'preventOverflow', 'flip'],
        fn({ state, name, options }) {
            const overflow = detectOverflow(state, options);
            const { x, y } = state.modifiersData.preventOverflow || { x: 0, y: 0 };
            const { width, height } = state.rects.popper;
            const [basePlacement] = state.placement.split('-');

            const widthProp = basePlacement === 'left' ? 'left' : 'right';
            const heightProp = basePlacement === 'top' ? 'top' : 'bottom';

            const offset = 8;

            state.modifiersData[name] = {
                width: width - overflow[widthProp] - x - offset,
                height: height - overflow[heightProp] - y - offset,
            };
        },
    }));

    /**
     * Docs:
     * {@link https://github.com/atomiks/popper.js/tree/master/packages/popper-max-size-modifier}
     */
    const applyMaxSizeModifier = computed<Modifier<'applyMaxSize', Options>>(() => ({
        name: 'applyMaxSize',
        enabled: true,
        phase: 'beforeWrite',
        requires: ['maxSize'],
        fn({ state }) {
            const { height } = state.modifiersData.maxSize;
            state.styles.popper.maxHeight = `${height}px`;
        },
    }));

    const flipModifier = computed<StrictModifiers>(() => ({
        name: 'flip',
        options: {
            /**
             * The same as above.
             */
            altBoundary: true,
        },
    }));

    /** Detect if the popover is getting out of a boundary. */
    const hideModifier = computed<StrictModifiers>(() => ({
        name: 'hide',
        enabled: autoHide.value,
    }));

    const offsetModifier = computed<StrictModifiers>(() => ({
        name: 'offset',
        options: {
            offset: offset?.value ? [offset.value.x, offset.value.y] : [0, 8],
        },
    }));

    const eventListenersModifier = computed<StrictModifiers>(() => ({
        name: 'eventListeners',
        enabled: isPopperEventListenersEnabled.value,
    }));

    const options = computed<Partial<Options>>(() => ({
        strategy: strategy.value,
        placement: placement.value,
        modifiers: [
            eventListenersModifier.value,
            offsetModifier.value,
            preventOverflowModifier.value,
            flipModifier.value,
            ...(autoMaxHeight.value ? [maxSizeModifier.value, applyMaxSizeModifier.value] : []),
            hideModifier.value,
            syncPlacement.value,
        ],
    }));

    return {
        options,
        actualPlacement,
        setListenersEnabled(value: boolean) {
            isPopperEventListenersEnabled.value = value;
        },
    };
};
