import { NavigationGuardNext, Route } from 'vue-router';

export function isPageEnteredFromBrowserBackForwardButton(routeName: string) {
    return async (_to: Route, _from: Route, next: NavigationGuardNext) => {
        if (
            window.performance &&
            window.performance.navigation.type == window.performance.navigation.TYPE_BACK_FORWARD
        ) {
            next({ name: routeName });
        } else {
            return;
        }
    };
}
