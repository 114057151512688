import { EventsOverviewApiFactory } from '@eloomi/eloomi-event-management-persona-client/1.0';
import { AdminOverviewApiFactory } from '@eloomi/eloomi-learning-persona-client/1.0';

import { authorizeClient } from '@/api/authorize-client';
import { extractAxiosData } from '@/common/services/extract-axios-data';

const adminOverviewApiClient = authorizeClient(AdminOverviewApiFactory);
const eventsOverviewApiClient = authorizeClient(EventsOverviewApiFactory);

export function getListOfCourseAuthors() {
    return adminOverviewApiClient.getListOfCourseAuthors().then(extractAxiosData);
}

export function getListOfEventAuthors() {
    return eventsOverviewApiClient.getListOfEventsAuthors().then(extractAxiosData);
}
