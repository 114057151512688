<template>
    <ModalWindow
        verticalAlignment="bottom"
        appearFrom="slide-up"
        :isVisible="isVisible"
        :withBackground="withBackground"
        :isScrollable="true"
        :headerText="headerText"
        :modalWindowClass="`pinned-modal ${modalWindowClass ? modalWindowClass : ''}`"
        :additionalClassContainerWrapper="additionalClassContainerWrapper"
        :showHeader="showHeader"
        modalWindowWrapperClass="pinned-modal-wrapper"
        @request-close="$emit('request-close')"
        @close="$emit('close')"
    >
        <template #header>
            <slot name="header">
                <h2 class="header-title-text">
                    {{ headerText }}
                </h2>
            </slot>
        </template>

        <template #content>
            <slot name="custom-content">
                <ModalWindowContent>
                    <slot name="content"></slot>
                </ModalWindowContent>
            </slot>
        </template>
    </ModalWindow>
</template>

<script lang="ts">
    import '@eloomi/icons/actions/actions-close-regular';

    import { defineComponent, PropType } from 'vue';

    import ModalWindow from '@/ui-kit/modal-window/ModalWindow.vue';
    import ModalWindowContent from '@/ui-kit/modal-window/ModalWindowContent.vue';

    const modalWindow = defineComponent({
        name: 'PinnedModal',
        components: {
            ModalWindow,
            ModalWindowContent,
        },
        props: {
            headerText: {
                type: String as PropType<string | null>,
                default: null,
            },
            isVisible: Boolean as PropType<boolean>,
            modalWindowClass: {
                type: String,
                default: null,
            },
            additionalClassContainerWrapper: {
                type: String,
                default: '',
            },
            showHeader: {
                type: Boolean as PropType<boolean>,
                default: true,
            },
            withBackground: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },
        emits: ['request-close', 'close'],
    });

    export default modalWindow;
</script>

<style lang="less">
    .pinned-modal {
        width: ~'min(1350px, 100% - 120px)' !important;
        height: 100%;
        min-height: calc(100% - 56px);

        &.modal-window {
            margin: 0;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;

            & > .content {
                height: 100%;
                overflow-x: hidden;

                &.no-header {
                    max-height: calc(100vh - 40px) !important;
                }
            }
        }
    }

    .pinned-modal-wrapper.modal-window-wrapper {
        overflow: hidden;
    }
</style>
