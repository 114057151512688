<template>
    <div class="date-filter-menu">
        <div class="date-filter-title">
            <MenuBackButton @click="handleBackButtonClick" />
            <h5 class="label-uppercase-info">
                {{ trans(`spa.admin.course-organization.filters-menu.options.selected-label.${filterType}`) }}
            </h5>
        </div>
        <SlideTransition>
            <div v-if="mode === 'predefined' && !timeWithoutPredefinedRanges" key="predefined">
                <DropdownItem
                    :title="trans('spa.events-overview.filters.date.pick')"
                    :isSelected="isCustomDatesSelected"
                    withButton
                    @click="mode = 'calendar'"
                />
                <DropdownItem
                    v-for="range in predefinedRanges"
                    :key="range"
                    :title="getPredefinedLabel(range)"
                    :isSelected="isSelected(range)"
                    @click="togglePredefinedSelection(range)"
                />
            </div>
            <Calendar
                v-else
                key="calendar"
                :datesTimeSpan="datesTimeSpan"
                class="calendar"
                isInline
                isRange
                :maxDate="allowSelectionToCustomEndDate"
                :minDate="allowSelectionFromCustomStartDate"
                @input="selectInterval"
            />
        </SlideTransition>
    </div>
</template>

<script lang="ts">
    import { computed, defineComponent, PropType } from 'vue';

    import { CalendarTimeSpanType, DateFilterType, FilterType } from '@/common/components/filter';
    import MenuBackButton from '@/common/components/menu/MenuBackButton.vue';
    import SlideTransition from '@/common/components/transitions/SlideTransition.vue';
    import { useTranslation } from '@/common/composables';
    import { getDayEnd, getDayStart } from '@/common/services';
    import {
        Interval,
        PredefinedRange,
        useEventOverviewDateSelectorComposable,
    } from '@/courses/components/events/use-event-overview-date-selector.composable';
    import { Calendar, DropdownItem } from '@/ui-kit';

    export default defineComponent({
        name: 'FilterDateMenu',
        components: { Calendar, DropdownItem, SlideTransition, MenuBackButton },
        props: {
            selectedDates: { type: Object as PropType<Interval> },
            datesTimeSpan: { type: String as PropType<CalendarTimeSpanType> },
            timeWithoutPredefinedRanges: { type: Boolean, default: false },
            filterType: { type: [String, Number] as PropType<FilterType | DateFilterType>, required: true },
        },
        emits: ['back', 'date-selected'],
        setup(props) {
            const allowSelectionFromCustomStartDate = computed(() => {
                if (props.datesTimeSpan === 'upcoming') {
                    return new Date();
                }
                return null;
            });

            const allowSelectionToCustomEndDate = computed(() => {
                if (props.datesTimeSpan === 'past') {
                    return new Date();
                }
                return null;
            });

            return {
                ...useEventOverviewDateSelectorComposable(),
                ...useTranslation(),
                allowSelectionFromCustomStartDate,
                allowSelectionToCustomEndDate,
            };
        },
        created() {
            this.currentDateFrom = this.selectedDates?.start ? getDayStart(this.selectedDates.start) : null;
            this.currentDateTo = this.selectedDates?.end ? getDayEnd(this.selectedDates.end) : null;
        },
        methods: {
            handleBackButtonClick() {
                this.$emit('back');
            },
            togglePredefinedSelection(range: PredefinedRange) {
                if (this.isSelected(range)) {
                    this.selectInterval({ start: null, end: null });
                } else {
                    this.selectInterval(this.predefinedIntervals[range]);
                }
            },
            selectInterval(interval: Interval) {
                this.currentDateFrom = interval.start ? getDayStart(interval.start) : null;
                this.currentDateTo = interval.end ? getDayEnd(interval.end) : null;

                this.$emit('date-selected', {
                    start: this.currentDateFrom,
                    end: this.currentDateTo,
                });
            },
        },
    });
</script>

<style scoped lang="less">
    .date-filter-menu {
        display: flex;
        flex-direction: column;
        width: 320px;
        max-height: 100%;
    }

    .date-filter-title {
        .flex-center-content-vertically();

        padding: @spacing-20 @spacing-24 @spacing-14 @spacing-24;
    }

    .calendar {
        justify-self: center;
        min-width: auto;
    }
</style>
