import { Module, Mutations, Actions, createMapper, Getters, createComposable } from 'vuex-smart-module';
import { getIntegrationDetails } from '@/admin/skills/providers/merge-integration';
import { MergeIntegrationDetailsResponse } from '@eloomi/eloomi-platforms-persona-client/1.0';
import Vue from 'vue';

class State {
    public mergeIntegrationDetails: MergeIntegrationDetailsResponse | null = null;
}

class StoreMutations extends Mutations<State> {
    public setMergeIntegrationDetails(mergeIntegrationDetails: MergeIntegrationDetailsResponse | null) {
        this.state.mergeIntegrationDetails = mergeIntegrationDetails;
    }
}

export class StoreActions extends Actions<State, Getters<State>, StoreMutations, StoreActions> {
    public async getMergeIntegrationDetails() {
        const integrationDetails = await getIntegrationDetails();
        this.mutations.setMergeIntegrationDetails(integrationDetails || null);
    }
}

const mergeIntegrationStoreModule = new Module({
    state: State,
    mutations: StoreMutations,
    actions: StoreActions,
});

export const useMergeIntegrationStore = createComposable(mergeIntegrationStoreModule);
export default mergeIntegrationStoreModule;

export const MergeIntegrationStoreMapper = createMapper(mergeIntegrationStoreModule);
export const MergeIntegrationStoreMixin = Vue.extend({
    computed: MergeIntegrationStoreMapper.mapState(['mergeIntegrationDetails']),
    methods: MergeIntegrationStoreMapper.mapActions(['getMergeIntegrationDetails']),
});
