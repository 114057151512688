<template>
    <DatePicker
        ref="datepicker"
        class="date-picker"
        mode="single"
        isExpanded
        :isRange="isRange"
        :class="additionalClass"
        :value="value"
        :minDate="minDate"
        :maxDate="maxDate"
        :popover="popover"
        :inputProps="inputProps"
        :selectAttribute="{
            highlight: {
                class: 'selected-date-highlight',
                contentClass: 'selected-date-content',
            },
        }"
        :dragAttribute="{
            highlight: {
                class: 'selecting-date-highlight',
                contentClass: 'selecting-date-content',
            },
        }"
        :masks="masks"
        @input="emitInput"
    >
        <template v-if="!isInline" #default="{ inputValue, updateValue, showPopover, hidePopover }">
            <TextField
                ref="textFieldRef"
                :isInvalid="isInvalid"
                :isDisabled="isDisabled"
                :value="convertValueToText(inputValue)"
                :placeholder="placeholder"
                testId="calendar-input"
                size="small"
                @change="handleChangeInputEvent(updateValue, $event)"
                @focus="showPopover"
                @blur="hidePopover"
            >
                <template #left="{ focus }">
                    <slot name="left" />
                    <elm-date-time-calendar-icon v-if="withLeftIcon" size="18" class="icon-left" @click="focus()" />
                </template>
            </TextField>
        </template>
    </DatePicker>
</template>

<script lang="ts">
    import '@eloomi/icons/date-time/date-time-calendar';

    import { DatePicker } from 'v-calendar';
    import { computed, defineComponent, PropType, ref } from 'vue';

    import { useDates } from '@/common/composables';
    import TextField from '@/ui-kit/text-field/TextField.vue';

    export type CalendarValueType = Date | { end: Date; start: Date };

    export default defineComponent({
        name: 'CalendarComponent',
        components: { TextField, DatePicker },
        props: {
            placeholder: {
                type: String as PropType<string>,
                default: '',
            },
            value: {
                type: [Date, Object] as PropType<CalendarValueType>,
            },
            isInline: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            isInvalid: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            isDisabled: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            withLeftIcon: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            isRange: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            maxDate: {
                type: Date,
                default: null,
            },
            minDate: {
                type: Date,
                default: null,
            },
        },
        emits: ['input'],
        setup(props, { emit }) {
            const checkedDate = ref(null);
            const datepicker = ref<DatePicker | null>(null);
            const textFieldRef = ref<InstanceType<typeof TextField> | null>(null);

            const emitInput = (value: CalendarValueType) => {
                if (value instanceof Date && datepicker.value.$refs.calendar) {
                    datepicker.value.focusDate(value);
                }

                setTimeout(() => textFieldRef.value?.input?.blur(), 10);

                return emit('input', value);
            };

            const additionalClass = computed(() => {
                return {
                    picked: props.value && checkedDate,
                    disabled: props.isDisabled,
                    invalid: props.isInvalid,
                };
            });

            const masks = computed(() => {
                return {
                    title: 'MMMM YYYY',
                    weekdays: 'WWW',
                    navMonths: 'MMM',
                    input: ['MM/DD/YYYY'],
                    dayPopover: 'WWW, MMM D, YYYY',
                    data: ['MM/DD/YYYY'],
                };
            });

            const inputProps = computed(() => {
                return {
                    placeholder: props.placeholder,
                };
            });

            const popover = computed(() => {
                return {
                    placement: 'bottom-start',
                    visibility: 'focus',
                    modifiers: [
                        {
                            name: 'preventOverflow',
                            options: {
                                rootBoundary: 'viewport',
                                tether: false,
                                altAxis: true,
                                padding: 8,
                            },
                        },
                    ],
                };
            });

            const handleChangeInputEvent = (updateValueInDatepicker: (value: string) => void, value: string) => {
                updateValueInDatepicker(value);

                if (value) {
                    datepicker.value.focusDate(value);
                }
            };

            const { getNumericDate } = useDates();

            const convertValueToText = inputValue => {
                if (typeof inputValue === 'string') {
                    return getNumericDate(inputValue);
                } else if (inputValue.start || inputValue.end) {
                    return `${getNumericDate(inputValue.start)} - ${getNumericDate(inputValue.end)}`;
                } else {
                    return '';
                }
            };

            return {
                checkedDate,
                convertValueToText,
                emitInput,
                additionalClass,
                masks,
                inputProps,
                popover,
                handleChangeInputEvent,
                datepicker,
                textFieldRef,
            };
        },
    });
</script>
<style lang="less" scoped>
    .icon-left {
        margin-right: @spacing-16;
        color: @info-color;
    }

    .date-picker {
        &.invalid {
            .icon-left {
                color: @danger-color;
            }
        }

        &.disabled {
            .icon-left {
                color: @info-color-40;
            }
        }

        &:hover {
            cursor: pointer;
        }
    }
</style>

<style lang="less">
    /* stylelint-disable */
    .date-picker {
        border: none;

        .vc-popover-content-wrapper {
            .vc-day-content {
                padding-top: 3px;
            }
        }

        .vc-popover-content-wrapper,
        .vc-pane-layout {
            .vc-popover-caret {
                display: none;
            }

            .vc-highlight,
            .vc-day-content {
                width: 40px;
                height: 40px;
            }

            .vc-day {
                &.in-month {
                    .paragraph-semibold();
                }

                &.is-not-in-month {
                    * {
                        pointer-events: inherit;
                        opacity: 1;
                    }

                    .vc-day-content {
                        .paragraph-info();
                    }
                }

                &.is-today {
                    color: @primary-color;
                }
            }

            .vc-day-content {
                margin: 0 auto;
                .paragraph-semibold();
                .generic-transition();

                &:focus,
                &:hover {
                    background-color: transparent;
                }
            }

            .vc-day:hover {
                .vc-day-content:not(.selected-date-content) {
                    color: @primary-color;
                    background-color: @primary-color-20;
                }
                .vc-day-content.selected-date-content {
                    background-color: @bright-color-20;
                }
            }

            .selected-date-content {
                color: @bright-color;
            }

            .vc-day.is-not-in-month .selected-date-content {
                color: @bright-color-80;
            }

            .selected-date-highlight {
                background-color: @primary-color;
            }

            .selecting-date-content {
                color: @primary-color;
            }

            .selecting-date-highlight {
                background-color: @primary-color-8;
            }

            .vc-highlight.selecting-date-highlight:not(
                    .vc-highlight-base-middle,
                    .vc-highlight-base-start,
                    .vc-highlight-base-end
                ) {
                background-color: @bright-color;
                &:before {
                    content: ' ';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: @primary-color-8;
                    border-radius: 24px;
                }
            }
        }

        .vc-title {
            .h4-dark;
        }

        .vc-popover-content-wrapper {
            .vc-text-sm {
                .text-paragraph;
            }

            .vc-weekday {
                .info-line-info;
                padding: @spacing-8 0;
                margin-bottom: @spacing-16;

                border-bottom: 1px solid @info-color-20;

                &:nth-child(1) {
                    margin-left: -@spacing-16;
                    padding-left: @spacing-16;
                }

                &:nth-child(7) {
                    margin-right: -@spacing-16;
                    padding-right: @spacing-16;
                }
            }
        }

        &.picked {
            .vc-text-blue-900 {
                color: @primary-color-20;
                background-color: @primary-color-20;
            }

            .vc-bg-blue-600 {
                background-color: @primary-color-20;
                border: 1px solid @primary-color;
            }

            .vc-border-blue-700 {
                background-color: @primary-color;
                border: 1px solid @primary-color-20;
            }
        }

        .vc-svg-icon {
            transform: scale(0.75);

            path {
                fill: @info-color-40;
            }
        }

        .vc-container {
            font-family: unset;
        }

        .vc-font-medium {
            font-weight: 600;
        }

        .vc-arrows-container {
            --gray-200: @info-color-8;
            justify-content: space-between;
            padding: @spacing-28 @spacing-56;

            .vc-arrow {
                .generic-transition(~'background');
            }
        }

        .vc-header {
            padding: @spacing-32 @spacing-56 !important;
            padding-bottom: @spacing-4 !important;

            .vc-title {
                .h4;
            }
        }

        .vc-grid-cell-row-1 .vc-grid-cell-row--7 {
            margin-bottom: @spacing-10;
            border-bottom: 1px solid @dark-color-20;
        }

        .vc-weeks {
            padding: @spacing-16 !important;
        }

        .vc-opacity-0 {
            display: none;
        }
    }
</style>
