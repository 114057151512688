import { authorizeClient } from '@/api/authorize-client';
import { AccountDetails } from '@/admin/billing/models';
import { AccountPersonaApiFactory, PutAccountRequest } from '@eloomi/eloomi-billing-persona-client/1.0';

const accountClient = authorizeClient(AccountPersonaApiFactory);

export function getAccountDetails(): Promise<AccountDetails> {
    return accountClient.getAccount().then(({ data }) => data as AccountDetails);
}

export function putAccountDetails(accountDetails: AccountDetails) {
    return accountClient.putAccount(accountDetails as PutAccountRequest);
}
