<template>
    <button :disabled="isDisabled" class="flex-center-content button-icon" :class="classes" @click="emitClick">
        <slot />
    </button>
</template>

<script lang="ts">
    import { Themes } from '@/common/classes';
    import { computed, defineComponent, PropType } from 'vue';

    export default defineComponent({
        name: 'IconButton',
        props: {
            isDisabled: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            color: {
                type: String as PropType<Themes>,
                default: 'primary',
            },
            size: {
                type: String as PropType<'small' | 'medium' | 'large'>,
                default: 'medium',
            },
            hoverColor: {
                type: String as PropType<Themes> | undefined,
            },
            isActive: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },
        emits: ['click'],
        setup(props, { emit }) {
            const classes = computed(() => [
                props.size,
                props.color,
                {
                    disabled: props.isDisabled,
                    'is-active': props.isActive,
                    [`hover-${props.hoverColor}`]: Boolean(props.hoverColor),
                },
            ]);
            const emitClick = () => emit('click');
            return {
                classes,
                emitClick,
            };
        },
    });
</script>
<style lang="less" scoped>
    @small-dimension: 24px;
    @medium-dimension: 35px;
    @large-dimension: 40px;

    .color-mixin(@background-color, @icon-color, @hover-icon-color: @icon-color, @active-icon-color: @icon-color) {
        background: @background-color;
        &,
        .button-icon--icon {
            color: @icon-color;
            .generic-transition(~'color');
        }
        &:hover,
        &.is-active {
            &,
            .button-icon--icon {
                color: @hover-icon-color;
            }
        }
    }

    .button-icon {
        display: inline-flex;
        background: @info-color;
        border: none;
        border-radius: @border-round;
        outline: none;
        .generic-transition(~'background, opacity');
        &:hover,
        &.is-active {
            cursor: pointer;
            opacity: 0.8;
        }
        &.disabled {
            opacity: 0.4;
        }
        &.info {
            .color-mixin(@info-color, @bright-color);
        }
        &.primary {
            .color-mixin(@primary-color, @bright-color);
        }
        &.success {
            .color-mixin(@success-color, @bright-color);
        }
        &.warning {
            .color-mixin(@warning-color, @bright-color);
        }
        &.danger {
            .color-mixin(@danger-color, @bright-color);
        }
        &.bright {
            .color-mixin(@bright-color, @info-color-80, @primary-color);
            &:hover,
            &.is-active {
                opacity: 1;
            }
        }
        &.secondary {
            .color-mixin(@info-color-8, @info-color);
            &:hover,
            &.is-active {
                cursor: pointer;
                .color-mixin(@primary-color-8, @primary-color);

                opacity: 1;
            }
            &.disabled {
                .color-mixin(@info-color-8, @info-color-40);

                opacity: 1;
            }
        }

        &.dark-60 {
            .color-mixin(@dark-color-60, @bright-color);
            &:hover,
            &.is-active {
                cursor: pointer;
                .color-mixin(@dark-color-80, @primary-color);

                opacity: 1;
            }
            &.disabled {
                .color-mixin(@dark-color-20, @bright-color-40);

                opacity: 1;
            }
        }

        &.hover-danger:hover:not(.disabled) {
            .color-mixin(@danger-color-8, @danger-color);
        }
    }

    .small {
        width: @small-dimension;
        height: @small-dimension;
    }
    .medium {
        width: @medium-dimension;
        height: @medium-dimension;
    }
    .large {
        width: @large-dimension;
        height: @large-dimension;
    }
</style>
