import { UserTopicsResponse } from '@eloomi/eloomi-learning-persona-client/1.0';
import { SkillResponse } from '@eloomi/eloomi-learning-persona-client/1.0/models/skill-response';

import { ICoursePlayerCourse } from '../models';

export class UserCoursesState {
    public activeCourse: ICoursePlayerCourse | null = null;
    public activeChapterId: number | null = null;
    public activePageId: number | null = null;
    public selectedTopics: UserTopicsResponse[] = [];
    public selectedSkills: SkillResponse[] = [];
}
