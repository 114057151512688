<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="48"
        height="48"
        viewBox="0 0 48 48"
    >
        <path
            fill="currentColor"
            d="M40.5 0c4.14.004 7.496 3.36 7.5 7.5v24c-.004 4.14-3.36 7.496-7.5 7.5h-8.73l1 6H36c.828 0 1.5.672 1.5 1.5S36.828 48 36 48H12c-.828 0-1.5-.672-1.5-1.5S11.172 45 12 45h3.23l1-6H7.5C3.36 38.996.004 35.64 0 31.5v-24C.004 3.36 3.36.004 7.5 0h33zM28.73 39h-9.46l-1 6h11.46l-1-6zm16.006-6H3.264c.64 1.791 2.334 2.99 4.236 3h33c1.92 0 3.61-1.242 4.236-3zM40.5 3h-33C5.016 3.002 3.002 5.016 3 7.5V30h42V7.5c-.002-2.484-2.016-4.498-4.5-4.5zM17.266 19.892c1.837 1.686 4.239 2.622 6.732 2.626 2.494-.003 4.896-.94 6.734-2.626.396-.363.956-.486 1.467-.324.512.163.898.587 1.012 1.111.114.525-.061 1.07-.459 1.431-2.389 2.19-5.511 3.406-8.752 3.41-3.242-.004-6.365-1.22-8.754-3.412-.416-.356-.605-.91-.494-1.445.112-.536.506-.969 1.03-1.129.522-.16 1.091-.023 1.484.358zM18 9c.828 0 1.5.672 1.5 1.5v3c0 .828-.672 1.5-1.5 1.5s-1.5-.672-1.5-1.5v-3c0-.828.672-1.5 1.5-1.5zm12 0c.828 0 1.5.672 1.5 1.5v3c0 .828-.672 1.5-1.5 1.5s-1.5-.672-1.5-1.5v-3c0-.828.672-1.5 1.5-1.5z"
        />
    </svg>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    @Component({ components: {} })
    export default class SmileyDesktopIcon extends Vue {}
</script>
