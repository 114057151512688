import { Vue } from 'vue-property-decorator';

export default class HighlightedOptionScroller {
    private container: HTMLElement | null;
    private readonly offsetTop: number;

    constructor(options?: { offsetTop?: number }) {
        this.container = null;
        this.offsetTop = options?.offsetTop ?? 60;
    }

    public setContainer(container: HTMLElement | Element | Vue) {
        this.container = (container instanceof Element ? container : container.$el) as HTMLElement;
        return this;
    }

    public scrollToOption(index: number | null) {
        if (!this.container || index === null) return;

        const highlightedOption = this.container.querySelector(`[data-option-index="${index}"]`);

        if (!highlightedOption) return;

        this.container.scrollTo({
            top: (highlightedOption as HTMLElement).offsetTop - this.offsetTop,
        });
    }
}
