import { IntercomApiFactory, IntercomSettingsForUser } from '@eloomi/eloomi-users-persona-client/1.0';
import { authorizeClient } from '@/api/authorize-client';
import environment from '@/environment';
import { E2EFeatureKeys, isFeatureDisabledByE2E } from '@/common/services/e2e-feature-flags';

const intercomApi = authorizeClient(IntercomApiFactory);

class IntercomService {
    isBooted = false;
    isInitialized = false;

    private injectScript(appId: string) {
        if (window.Intercom) return;

        /**
         * Reference {@link https://developers.intercom.com/installing-intercom/docs/basic-javascript}
         */
        const INTERCOM_SCRIPT = `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${appId}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`;

        const scriptElement = document.createElement('script');
        scriptElement.text = INTERCOM_SCRIPT;
        document.body.appendChild(scriptElement);
    }

    private callIntercom(...args: unknown[]) {
        if (!window.Intercom) return;
        window.Intercom(...args);
    }
    private static isDisabledByEnvVariables() {
        return !environment.useIntercom || isFeatureDisabledByE2E(E2EFeatureKeys.Intercom);
    }
    async init(isIntegrationEnabled: boolean) {
        if (IntercomService.isDisabledByEnvVariables() || !isIntegrationEnabled || this.isInitialized) return;

        const intercom = await intercomApi.getSelf().then(response => response.data);

        if (intercom.enabled && intercom.settings && intercom.settings.app_id) {
            this.injectScript(intercom.settings.app_id);
            this.isInitialized = true;
            this.boot(intercom.settings);
        }
    }

    boot(settings: IntercomSettingsForUser) {
        if (!this.isInitialized) return;

        this.callIntercom('boot', settings);
        this.isBooted = true;
    }

    showNewMessage() {
        if (!this.isBooted) return;
        this.callIntercom('showNewMessage');
    }

    trackEvent(eventName: string, options?: unknown) {
        this.callIntercom('trackEvent', eventName, options);
    }

    setVisibility(isVisible: boolean) {
        this.callIntercom('update', {
            hide_default_launcher: !isVisible,
        });
    }

    shutdown() {
        if (!this.isBooted) return;
        this.callIntercom('shutdown');
    }

    show() {
        const intercomElement = window.document.getElementById('intercom-container');
        if (intercomElement) {
            intercomElement.style.display = '';
        }
    }

    hide() {
        const intercomElement = window.document.getElementById('intercom-container');
        if (intercomElement) {
            intercomElement.style.display = 'none';
        }
    }
}

export const intercom = new IntercomService();
