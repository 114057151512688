import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class WithSkeleton extends Vue {
    @Prop({ default: false, type: Boolean }) public isLoading!: boolean;

    public skeletonWidth(min, max = 100, units: 'px' | '%' = 'px') {
        return `${this.getRandomFoldNumber(min, max, 8)}${units}`;
    }

    private getRandomFoldNumber(min, max = 100, divisor = 8) {
        return Math.floor((min + Math.random() * (max - min)) / divisor) * divisor;
    }
}
