<template>
    <div class="trigger" :class="{ 'trigger--active': isActive }" @click="$emit('click')">
        <div v-if="newNotifications" class="trigger__new-notifications">{{ numberOfNewNotifications }}</div>
        <elm-alerts-info-notification-bold-icon size="18" class="trigger__icon" />
    </div>
</template>

<script lang="ts">
    import '@eloomi/icons/alerts-info/alerts-info-notification-bold';

    import { computed, defineComponent } from 'vue';

    export default defineComponent({
        name: 'NotificationTrigger',
        props: {
            newNotifications: { type: Number, default: 0 },
            isActive: { type: Boolean, default: false },
        },
        emits: ['click'],
        setup(props) {
            const numberOfNewNotifications = computed(() =>
                props.newNotifications <= 99 ? props.newNotifications : '99+'
            );
            return { numberOfNewNotifications };
        },
    });
</script>

<style lang="less" scoped>
    .trigger {
        .generic-transition(background-color);
        .flex-center-content;

        position: relative;
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        border-radius: @border-round;
        cursor: pointer;

        &--active {
            background-color: @primary-color-20;
        }

        &:hover {
            background-color: @bright-color-20;
        }

        &__icon {
            color: @branding-text-color;
            fill: @bright-color;
        }

        &__new-notifications {
            .text-label;
            .flex-center-content;

            position: absolute;
            top: -6px;
            right: -6px;
            width: 20px;
            height: 20px;
            color: @bright-color;
            background-color: @primary-color;
            border-radius: @border-round;
        }
    }
</style>
