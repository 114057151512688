import {
    TwentyThreeStatus,
    VideoContentResultContentResult,
    VimeoStatus,
} from '@eloomi/eloomi-content-persona-client/1.0';
import { inject, injectable } from 'tsyringe';

import { VideoContentElement } from '@/admin/courses/utils';
import { editVideoElement } from '@/api/providers/admin.provider';
import { PlatformResolver, ThirdPartyServiceType } from '@/authentication/services/interfaces';
import { convertTwentyThreeToEmbed } from '@/common/services/utils';
import { ServiceTokens } from '@/di/tokens';

import { VideoServiceTokens } from '../di-tokens';
import { VideoProvider, VideoProviderType, VideoResponse, VideoStatus, VideoUploadOptions } from './interfaces';
import type { TwentyThreeVideoService } from './twenty-three';
import type { VimeoVideoService } from './vimeo';

@injectable()
export class VideoService implements VideoProvider {
    constructor(
        @inject(ServiceTokens.PlatformResolutionService)
        private platformResolver: PlatformResolver,

        @inject(VideoServiceTokens.VimeoVideoService)
        private vimeoService: VimeoVideoService,

        @inject(VideoServiceTokens.TwentyThreeVideoService)
        private twentyThreeService: TwentyThreeVideoService
    ) {}

    public async getVideo(id: string | number): Promise<VideoResponse> {
        const providerType = this.resolveProvider();
        if (providerType === VideoProviderType.TwentyThree) {
            const { content_id, video_link, status } = await this.twentyThreeService.getUploadedTwentyThreeVideo(
                id.toString()
            );
            return {
                id: content_id,
                url: video_link,
                status: VideoService.mapProviderStatusToVideoStatus(status),
            };
        }

        const { vimeo_id, video_url, vimeo_status } = await this.vimeoService.getUploadedVimeoVideo(
            Number.parseInt(id.toString())
        );
        return {
            id: vimeo_id,
            url: video_url,
            status: VideoService.mapProviderStatusToVideoStatus(vimeo_status),
        };
    }

    public async uploadVideo(options: VideoUploadOptions): Promise<VideoResponse> {
        const { contentId, file, onProgressChanged } = options;
        const providerType = this.resolveProvider();
        if (providerType === VideoProviderType.TwentyThree) {
            const { content_id, upload_url } = await this.twentyThreeService.uploadTwentyThreeVideo(
                contentId,
                file,
                onProgressChanged
            );
            return {
                id: content_id,
                url: upload_url,
            };
        }

        const { vimeo_id, upload_link } = await this.vimeoService.uploadVimeoVideo(contentId, file, onProgressChanged);
        return {
            id: vimeo_id,
            url: upload_link,
        };
    }

    public editVideo(contentId: string, data: VideoContentElement): Promise<VideoContentResultContentResult> {
        return editVideoElement(contentId, data);
    }

    public convertVideoLink(link: string): string {
        return this.resolveProvider() === VideoProviderType.TwentyThree ? convertTwentyThreeToEmbed(link) : link;
    }

    private resolveProvider(): VideoProviderType {
        const isTwentyThreeEnabled = this.platformResolver.isThirdPartyServiceIntegrationEnabled(
            ThirdPartyServiceType.TWENTY_THREE
        );

        return isTwentyThreeEnabled ? VideoProviderType.TwentyThree : VideoProviderType.Vimeo;
    }

    private static mapProviderStatusToVideoStatus(status?: VimeoStatus | TwentyThreeStatus): VideoStatus {
        if (!status) {
            return VideoStatus.Unknown;
        }

        switch (status) {
            case VimeoStatus.Created: {
                return VideoStatus.Created;
            }
            case VimeoStatus.Uploading:
            case TwentyThreeStatus.Uploading: {
                return VideoStatus.Uploading;
            }
            case TwentyThreeStatus.Finalized: {
                return VideoStatus.Finalized;
            }
            case VimeoStatus.Transcoding:
            case TwentyThreeStatus.Transcoding: {
                return VideoStatus.Transcoding;
            }
            case VimeoStatus.UploadError:
            case TwentyThreeStatus.UploadError: {
                return VideoStatus.UploadError;
            }
            case VimeoStatus.TranscodingError:
            case TwentyThreeStatus.TranscodingError: {
                return VideoStatus.TranscodingError;
            }
            case VimeoStatus.Ready:
            case TwentyThreeStatus.Ready: {
                return VideoStatus.Ready;
            }
            case VimeoStatus.Removed:
            case TwentyThreeStatus.Removed: {
                return VideoStatus.Removed;
            }
            case VimeoStatus.VideoUnidentified:
            case TwentyThreeStatus.VideoUnidentified: {
                return VideoStatus.VideoUnidentified;
            }
            default: {
                return VideoStatus.Unknown;
            }
        }
    }
}
