<template>
    <component
        :is="component"
        v-tippy="{
            content: tooltip,
            trigger: tooltip ? 'mouseenter' : 'manual',
        }"
        class="selector-item"
        :class="{
            'is-selected': isSelected,
            'is-disabled': isDisabled,
            'with-checkbox': withCheckbox,
            'is-auto-height': isAutoHeight,
            danger: intention === 'danger',
        }"
        :style="itemHeight"
        data-testid="dropdown-item"
        :to="link"
        @click="onClick"
    >
        <CoreCheckbox v-if="withCheckbox" :round="roundCheckbox" class="selector-item-checkbox" :value="isSelected" />

        <div v-if="!!$slots.icon" class="selector-item-icon selector-item-icon-wrapper"><slot name="icon"></slot></div>

        <CoreShapedImage
            v-if="withAvatar"
            class="selector-item-avatar"
            shape="circle"
            :size="24"
            :image="avatar"
            :altText="title"
        />
        <div class="selector-item-text">
            <slot name="content">
                <!-- eslint-disable vue/no-v-html -->
                <span
                    :title="title"
                    v-html="$options.filters !== undefined && $options.filters.highlight(title, search, highlightColor)"
                />
            </slot>
        </div>
        <CoreCardLabel
            v-if="label"
            class="label"
            :color="labelActive ? 'primary-8' : 'info-8'"
            :value="label"
        ></CoreCardLabel>
        <!-- eslint-enable -->
        <span v-if="withButton" class="selector-item-button" @click="onButtonClick">
            <elm-arrows-arrow-right-bold-icon size="10" />
        </span>
    </component>
</template>

<script lang="ts">
    import '@eloomi/icons/arrows/arrows-arrow-right-bold';

    import { computed, defineComponent, PropType } from 'vue';
    import { RawLocation } from 'vue-router';

    import CoreCardLabel from '@/common/components/chips/CoreCardLabel.vue';
    import CoreCheckbox from '@/common/components/controls/checkbox/CoreCheckbox.vue';
    import CoreShapedImage from '@/common/components/icon/CoreShapedImage.vue';
    import { PRIMARY_COLORS } from '@/common/constants/color-css-variables';
    import { highlight } from '@/common/services';

    export default defineComponent({
        name: 'DropdownItem',
        components: { CoreCheckbox, CoreShapedImage, CoreCardLabel },
        filters: { highlight },
        props: {
            title: {
                type: String as PropType<string>,
                required: false,
                default: undefined,
            },
            isSelected: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            isDisabled: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            isAutoHeight: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            search: {
                type: String as PropType<string>,
                default: '',
            },
            withButton: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            withCheckbox: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            roundCheckbox: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            label: {
                type: [Number, String] as PropType<unknown | undefined>,
                default: undefined,
            },
            icon: {
                type: String as PropType<string | undefined>,
                default: undefined,
            },
            avatar: {
                type: String as PropType<string | undefined>,
                default: undefined,
            },
            withAvatar: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            intention: {
                type: String as PropType<'default' | 'danger'>,
                default: 'default',
            },
            tooltip: {
                type: String as PropType<string | undefined>,
                default: undefined,
            },
            labelActive: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            height: {
                type: Number,
                default: 48,
            },
            link: {
                type: [Object, String] as PropType<RawLocation>,
                required: false,
                default: undefined,
            },
        },
        emits: ['click', 'button-click'],
        setup(props, { emit }) {
            const onClick = event => {
                if (props.isDisabled) return;

                emit('click', event);
            };

            const itemHeight = computed(() => ({ '--custom-height': `${props.height}px` }));

            const onButtonClick = event => {
                if (props.isDisabled) return;

                emit('button-click', event);
            };
            const highlightColor = PRIMARY_COLORS.PRIMARY;

            const component = computed(() => (props.link ? 'router-link' : 'div'));

            return {
                onClick,
                highlightColor,
                onButtonClick,
                itemHeight,
                component,
            };
        },
    });
</script>
<style lang="less" scoped>
    .selector-item-icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .selector-item {
        .generic-transition(~'background, color');

        display: flex;
        align-items: center;
        min-width: 0;
        height: var(--custom-height);
        padding: 0 @spacing-16;
        background: @bright-color;
        border-radius: @border-radius-6;
        user-select: none;

        & .highlighted-text {
            color: @primary-color;
        }

        .selector-item-text {
            .info-line();
            .generic-transition(color);

            color: @dark-color;
            .xs-viewport-and-smaller({
                .font-semibold;
            });
        }

        &:hover:not(.is-disabled, .danger) {
            background: @primary-color-8;
        }

        &:hover:not(.is-disabled, .danger),
        &.is-selected:not(.with-checkbox, .is-disabled) {
            cursor: pointer;

            .selector-item-text {
                color: @primary-color;
            }

            .label {
                color: @primary-color;
                background: @primary-color-8;
            }

            .selector-item-icon {
                .generic-transition(color);

                color: @primary-color;
            }

            .selector-item-button {
                color: @primary-color;
                background: @primary-color-8;
            }
        }

        &.danger {
            .selector-item-text {
                color: @danger-color;
                cursor: pointer;
            }

            &:hover {
                background: @danger-color-8;
            }
        }

        &.is-disabled {
            .selector-item-text {
                color: @info-color;
            }

            &:hover {
                background: @info-color-8;
            }
        }

        &.is-auto-height {
            height: auto;
        }
    }

    .selector-item-text {
        .info-line-dark();

        flex-grow: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .highlighted-text {
        color: @primary-color;
    }

    .selector-item-checkbox {
        top: -1px;
        margin-right: @spacing-14;
    }

    .selector-item-icon {
        margin-right: @spacing-16;
        color: @dark-color;
    }

    .selector-item-avatar {
        margin-right: @spacing-16;
    }

    .selector-item-button {
        .flex-center-content();

        flex: 0 0 24px;
        height: 24px;
        margin-left: @spacing-8;
        color: @info-color;
        background-color: @info-color-8;
        border-radius: @border-round;

        .generic-transition(~'background-color, color');
    }

    .label {
        margin-left: @spacing-16;
    }
</style>
