import * as FullStory from '@fullstory/browser';
import SentryFullStory from '@sentry/fullstory';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import { Integration } from '@sentry/types';
import * as Sentry from '@sentry/vue';
import Vue from 'vue';
import VueRouter from 'vue-router';

import { LoginSessionLoadError } from '@/authentication/services/errors';
import { fullStory } from '@/common/services/fullstory';
import environment from '@/environment';

export function initializeSentry(router: VueRouter) {
    if (environment.sentryDsn && environment.sentryOrganisation) {
        const tracingOrigins = ['persona-api.staging.eloomi.io', 'persona-api.eloomi.io'];

        if (environment.clientType === 'mobileApp') {
            tracingOrigins.push('localhost');
        }

        const integrations: Array<Integration> = [
            new TracingIntegrations.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                traceFetch: true,
                traceXHR: true,
                startTransactionOnPageLoad: true,
                startTransactionOnLocationChange: true,
                tracingOrigins: tracingOrigins,
                markBackgroundTransactions: false,
            }),
        ];

        if (fullStory.isInitialized) {
            integrations.push(new SentryFullStory(environment.sentryOrganisation, { client: FullStory }));
        }

        Sentry.init({
            Vue,
            dsn: environment.sentryDsn,
            release: environment.sentryRelease,
            integrations: integrations,
            tracesSampleRate: environment.sentryTraceSampleRate,
            environment: environment.type,
            attachStacktrace: true,
            ignoreErrors: [
                'ResizeObserver loop',
                'Redirected when going from',
                new RegExp(LoginSessionLoadError.message),
            ],
        });
    }
}

/** Get initialized sentry instance. In case sentry is disabled this is noop. */
export function withSentry(func: (sentry: typeof Sentry) => void) {
    const instance = Sentry.getCurrentHub().getClient();

    if (!instance) return;

    func(Sentry);
}
