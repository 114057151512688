import { UAParser } from 'ua-parser-js';

import { wordsToKebab } from '@/common/services/utils';

export default function () {
    const parser = new UAParser();
    const os = parser.getOS();
    const browser = parser.getBrowser();

    if (browser.name && !document.body.dataset.browser) {
        document.body.dataset.browser = wordsToKebab(browser.name);
    }
    if (os.name && !document.body.dataset.os) {
        document.body.dataset.os = wordsToKebab(os.name);
    }
}
