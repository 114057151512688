<template>
    <component :is="containerTag" class="formfield" :for="generateLabelFor && !doNotUseLabel ? elementId : null">
        <slot name="title">
            <h3 class="formfield--title">
                {{ title }}
            </h3>
        </slot>
        <slot :elementId="elementId" />
        <slot name="description">
            <ul v-if="messages.length" class="formfield--description">
                <li v-for="message in messages" :key="message">
                    {{ message }}
                </li>
            </ul>
        </slot>
    </component>
</template>

<script lang="ts">
    import { defineComponent, PropType, computed } from 'vue';
    import { randomId } from '@/common/services';

    export default defineComponent({
        props: {
            title: {
                type: String as PropType<string | undefined>,
                default: '',
            },
            description: {
                type: [String, Array] as PropType<string | string[] | undefined>,
                default: undefined,
            },
            generateLabelFor: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            doNotUseLabel: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },
        setup(props) {
            const elementId = randomId();
            const messages = computed(() => {
                if (Array.isArray(props.description)) {
                    return props.description;
                } else if (props.description) {
                    return [props.description];
                } else {
                    return [];
                }
            });
            const containerTag = computed(() => {
                return props.doNotUseLabel ? 'div' : 'label';
            });

            return { containerTag, messages, elementId };
        },
    });
</script>
<style lang="less" scoped>
    .formfield--title {
        .label-uppercase();

        margin-bottom: @spacing-8;
        color: @info-color;
    }
    .formfield--description {
        .text-paragraph-small();

        margin-top: @spacing-11;
        margin-bottom: 0;
        padding: 0;
        color: @info-color;
        li {
            padding: 0;
            list-style: none;
        }
    }
</style>
