import { UserApiFactory } from '@eloomi/eloomi-learning-persona-client/1.0';
import { CategoryApiFactory } from '@eloomi/eloomi-topics-persona-client/1.0';

import { Category } from '@/admin/topics/models';
import { authorizeClient } from '@/api/authorize-client';
import { DataRequestOptions, TablePaginatedData } from '@/common/components/table/v2/interfaces';
import { buildFilter, FluentFilterCollection, Operator } from '@/common/services';
import { extractAxiosData } from '@/common/services/extract-axios-data';

const userLearningApiFactory = authorizeClient(UserApiFactory);
const categoryApiClient = authorizeClient(CategoryApiFactory);

export function getUserCategories() {
    return userLearningApiFactory.getUserCategories().then(extractAxiosData);
}

function getFilters(options?: DataRequestOptions) {
    if (!options?.searchQuery) return;

    const filter = new FluentFilterCollection();
    filter.where('name').filter(Operator.Contains, options.searchQuery);

    return filter.build();
}

export function getCategoriesNew(options?: DataRequestOptions) {
    const filters = getFilters(options);

    return categoryApiClient.get(filters, undefined, options?.page, options?.pageSize).then(extractAxiosData);
}

export function getCategories(options?: DataRequestOptions) {
    const filters = buildFilter('name', options?.searchQuery);

    return categoryApiClient
        .get(filters, undefined, options?.page, options?.pageSize)
        .then(extractAxiosData) as Promise<TablePaginatedData<Category>>;
}
