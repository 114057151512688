import { RouteConfig } from 'vue-router';

import { FALLBACK_ROUTE_NAME } from '@/common/services/mobile-fallback';

import MobileFallbackPage from './pages/MobileFallbackPage.vue';

export const ErrorRouteNames = Object.freeze({
    NOT_FOUND: 'not-found',
    MOBILE_FALLBACK: FALLBACK_ROUTE_NAME,
} as const);

const routes: RouteConfig[] = [
    {
        path: 'unsupported-mobile',
        name: ErrorRouteNames.MOBILE_FALLBACK,
        meta: {
            title: 'Content not available',
        },
        component: MobileFallbackPage,
    },
];

export default routes;
