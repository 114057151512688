import { Module, Mutations, createMapper, Getters, createComposable } from 'vuex-smart-module';
import { Vue } from 'vue-property-decorator';
import { MinWidthViewportBreakpoints, BreakpointCode } from '@/common/constants/breakpoints';

class State {
    public isMobile = false;
    public readonly breakpoints = MinWidthViewportBreakpoints;
    public readonly activeBreakpoints: Record<BreakpointCode, boolean> = {
        sm: false,
        md: false,
        lg: false,
        xl: false,
        xxl: false,
    };
}

class StoreGetters extends Getters<State> {
    public isMobile() {
        return this.state.isMobile;
    }

    // Min-width viewport detectors

    public get isViewportLargerOrEqualSm() {
        return this.state.activeBreakpoints.sm;
    }

    public get isViewportLargerOrEqualMd() {
        return this.state.activeBreakpoints.md;
    }

    public get isViewportLargerOrEqualLg() {
        return this.state.activeBreakpoints.lg;
    }

    public get isViewportLargerOrEqualXl() {
        return this.state.activeBreakpoints.xl;
    }

    public get isViewportLargerOrEqualXxl() {
        return this.state.activeBreakpoints.xxl;
    }

    // Max-width viewport detectors

    public get isViewportSmallerOrEqualSm() {
        return !this.state.activeBreakpoints.md;
    }

    public get isViewportSmallerOrEqualMd() {
        return !this.state.activeBreakpoints.lg;
    }

    public get isViewportSmallerOrEqualLg() {
        return !this.state.activeBreakpoints.xl;
    }

    public get isViewportSmallerOrEqualXl() {
        return !this.state.activeBreakpoints.xxl;
    }
}

class StoreMutations extends Mutations<State> {
    public setIsMobile(isMobile: boolean) {
        this.state.isMobile = isMobile;
    }

    public setBreakpoint({ breakpoint, state }: { breakpoint: BreakpointCode; state: boolean }) {
        this.state.activeBreakpoints[breakpoint] = state;
    }
}

const mobileModeStore = new Module({
    state: State,
    getters: StoreGetters,
    mutations: StoreMutations,
});

export const useMobileModeStore = createComposable(mobileModeStore);

const mobileModeStoreMapper = createMapper(mobileModeStore);

export const MobileModeStoreMixin = Vue.extend({
    computed: {
        ...mobileModeStoreMapper.mapState(['isMobile', 'breakpoints', 'activeBreakpoints']),
        ...mobileModeStoreMapper.mapGetters([
            'isViewportLargerOrEqualSm',
            'isViewportLargerOrEqualMd',
            'isViewportLargerOrEqualLg',
            'isViewportLargerOrEqualXl',
            'isViewportLargerOrEqualXxl',
            'isViewportSmallerOrEqualSm',
            'isViewportSmallerOrEqualMd',
            'isViewportSmallerOrEqualLg',
            'isViewportSmallerOrEqualXl',
        ]),
    },
    methods: {
        ...mobileModeStoreMapper.mapMutations(['setIsMobile', 'setBreakpoint']),
    },
});

export default mobileModeStore;
