import { UserGroupsApiFactory } from '@eloomi/eloomi-user-groups-persona-client/1.0';
import { UserAdminPersonaApiFactory, UsersPaginatedResponse } from '@eloomi/eloomi-users-persona-client/1.0';

import { User } from '@/admin/groups/models/GroupUser';
import { authorizeClient } from '@/api/authorize-client';
import { DataRequestOptions, TablePaginatedData } from '@/common/components/table/v2/interfaces';
import { buildFilter, FluentFilterCollection, isNotEmpty, Operator } from '@/common/services';
import { extractAxiosData } from '@/common/services/extract-axios-data';

const userAdminPersonaApi = authorizeClient(UserAdminPersonaApiFactory);
const userGroupsApiClient = authorizeClient(UserGroupsApiFactory);
export function getUsers(options: DataRequestOptions, groupId: number) {
    const filters = buildFilter('full_name', options.searchQuery);

    return userGroupsApiClient
        .getUserGroupUsers(groupId, filters, undefined, options.page, options.pageSize)
        .then(extractAxiosData) as Promise<TablePaginatedData<User>>;
}

export function getUsersForSelector(options: DataRequestOptions): Promise<UsersPaginatedResponse> {
    const filters = isNotEmpty(options.searchQuery)
        ? new FluentFilterCollection()
              .where('first_name', 'last_name')
              .filter(Operator.Contains, options.searchQuery || '')
        : undefined;

    return userAdminPersonaApi
        .getAll(filters?.toQueryStringSearch(), undefined, options.page, options.pageSize)
        .then(extractAxiosData);
}
