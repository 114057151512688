import { InfiniteUserRolesAdminPersonaApiFactory } from '@eloomi/eloomi-users-persona-client/1.0/api';

import { authorizeClient } from '@/api/authorize-client';
import { DataRequestOptions } from '@/common/components/table';
import { buildFilter } from '@/common/services';
import { getBaseStore } from '@/main/get-base-store-router';
import translationsStore from '@/translations/store';

const userRolesClient = authorizeClient(InfiniteUserRolesAdminPersonaApiFactory);

export enum PredefinedAccessUserRoles {
    ADMIN = 'admin',
    USER = 'user',
}

/**
 * Retrieves all available custom user-roles.
 * Roles which can be added by admin to be more restrict what should be visible for specific users.
 * Number of data will not be very large
 */
export function getAllAccessUserRoles(options: DataRequestOptions) {
    const trans = translationsStore.context(getBaseStore()).getters.trans;
    const filterStr = buildFilter('Name', options.searchQuery);

    return userRolesClient.getAll(filterStr, undefined, options.page, options.pageSize).then(({ data }) => {
        if (data?.data) {
            for (const item of data.data) {
                if (item.key == PredefinedAccessUserRoles.ADMIN || item.key == PredefinedAccessUserRoles.USER) {
                    item.description = trans(
                        `spa.admin.users-organization.user-access-roles.user-role.${item.key}.description`
                    );
                    item.name = trans(`spa.admin.users-organization.user-access-roles.user-role.${item.key}.name`);
                }
            }
        }

        return data;
    });
}
