/* eslint-disable unicorn/no-array-callback-reference,unicorn/no-array-method-this-argument */
import {
    BuilderContentApiFactory,
    PutImageContentRequest,
    PutQuizFileUploadRequest,
    PutQuizOpenTextRequest,
    PutQuizRatingScaleRequest,
    QuizApiFactory,
    QuizChoiceQuestionContentBuilderContract,
    StoreQuizAttemptRequest,
} from '@eloomi/eloomi-content-persona-client/1.0';
import {
    ChoiceQuestionContentBuilderContract,
    PutIframeContentRequest,
    PutPdfContentRequest,
    PutPodcastContentRequest,
} from '@eloomi/eloomi-content-persona-client/1.0/models';
import {
    AdminOverviewApiFactory as AdminOverviewApiFactoryV1,
    BuilderChapterApiFactory,
    BuilderCourseApiFactory,
    BuilderPageApiFactory,
    BuilderScormCourseApiFactory,
    CourseType,
    DuplicateCourseRequest,
    PlayerQuizApiFactory,
    UserApiFactory,
} from '@eloomi/eloomi-learning-persona-client/1.0';
import { UpdateCourseRequest, UpdatePageRequest } from '@eloomi/eloomi-learning-persona-client/1.0/models';
import { AdminOverviewApiFactory, CoursesPaginatedResponse } from '@eloomi/eloomi-learning-persona-client/2.0';
import { Content } from '@tiptap/vue-2';
import { debounce } from 'lodash-es';

import { UnmappedAdminCourse } from '@/admin/courses/models/admin-course';
import { BuilderCourse, CoursePage } from '@/admin/courses/store/interfaces';
import { AttachmentContentElement, VideoContentElement } from '@/admin/courses/utils';
import { authorizeClient } from '@/api/authorize-client';
import { EnrollmentType, Filters, FilterType } from '@/common/components/filter';
import { DataRequestOptions } from '@/common/components/table';
import { PaginatedDataUnmapped } from '@/common/models/paginated-data';
import { buildFilter, FluentFilterCollection, Operator } from '@/common/services';
import { extractAxiosData } from '@/common/services/extract-axios-data';
import { Filter } from '@/reporting/segments/models/segment.model';

/* eslint-disable @typescript-eslint/no-explicit-any */
const builderCourseApiClient: any = authorizeClient(BuilderCourseApiFactory);
const builderChapterApiClient: any = authorizeClient(BuilderChapterApiFactory);
const builderPageApiClient: any = authorizeClient(BuilderPageApiFactory);
const builderContentApiClient = authorizeClient(BuilderContentApiFactory);
const quizApiClient = authorizeClient(QuizApiFactory);
const playerQuizApiClient = authorizeClient(PlayerQuizApiFactory);
const builderScormCourseApiClient = authorizeClient(BuilderScormCourseApiFactory);
const adminOverviewApiClient = authorizeClient(AdminOverviewApiFactory);
const adminOverviewApiClientV1 = authorizeClient(AdminOverviewApiFactoryV1);
const userApiClient = authorizeClient(UserApiFactory);
/* eslint-enable @typescript-eslint/no-explicit-any */

interface GetAllCoursesOptions {
    courseNameSearchQuery?: string;
    page?: number;
    pageSize?: number;
    filter?: Filter;
}

export interface CourseListRequestFilters extends Filters {
    courseTitle?: {
        value: string;
    };

    courseId?: {
        value: Array<number>;
        mode: 'inclusive' | 'exclusive';
    };
}

export type CourseListRequestOptions = DataRequestOptions<CourseListRequestFilters>;

export function getCourse(id: number): Promise<BuilderCourse> {
    return builderCourseApiClient
        .getCourseWithStructure(id)
        .then(extractAxiosData)
        .then(data => data as BuilderCourse);
}

export function editCourse(id: number, course: UpdateCourseRequest) {
    return builderCourseApiClient.editCourse(id, course).then(extractAxiosData);
}

export function createChapter(id, chapter) {
    return builderChapterApiClient.addChapter(id, chapter).then(extractAxiosData);
}

export function createPage(courseId, chapterId, page): Promise<CoursePage> {
    return builderPageApiClient
        .createPage(courseId, chapterId, page)
        .then(extractAxiosData)
        .then(data => data as CoursePage);
}

export function updatePage({
    courseId,
    chapterId,
    pageId,
    page,
}: {
    courseId: number;
    chapterId: number;
    pageId: number;
    page: CoursePage;
}) {
    return builderPageApiClient
        .updatePage(courseId, chapterId, pageId, page as UpdatePageRequest)
        .then(extractAxiosData);
}

export function getPageWithContent({ courseId, chapterId, pageId }) {
    return builderPageApiClient
        .getPage(courseId, chapterId, pageId)
        .then(extractAxiosData)
        .then(data => data as CoursePage);
}

export function deletePage({ courseId, chapterId, pageId }) {
    return builderPageApiClient.deletePage(courseId, chapterId, pageId);
}

export function getAllCourses(options: GetAllCoursesOptions = {}) {
    const filters = new FluentFilterCollection();

    if (options.courseNameSearchQuery !== undefined) {
        filters.where('title').filter(Operator.Contains, options.courseNameSearchQuery);
    }

    const pageNumber = options.page ?? 1;
    const pageSize = options.pageSize ?? 10;

    if (options.filter?.mode) {
        if (options.filter.mode === 'inclusive') {
            filters.where('id').filter(Operator.IsEqualTo, options.filter?.selectedIds.join('|'));
        } else {
            for (const id of options.filter.selectedIds) {
                filters.where('id').filter(Operator.IsNotEqualTo, id);
            }
        }
    }

    if (options.filter?.doNotShowArchived) {
        filters.where('archived_at').filter(Operator.IsEqualTo, 'null');
    }
    return adminOverviewApiClientV1
        .getListOfCourses(filters.build(), undefined, pageNumber, pageSize)
        .then(extractAxiosData) as Promise<PaginatedDataUnmapped<UnmappedAdminCourse>>;
}

function getCourseListFiltersParam(options: CourseListRequestFilters) {
    const filters = new FluentFilterCollection();

    if (options.courseTitle && options.courseTitle.value) {
        filters.where('title').filter(Operator.Contains, options.courseTitle.value);
    }

    if (options.courseId && options.courseId.value.length > 0) {
        if (options.courseId.mode === 'inclusive') {
            filters.where('id').filter(Operator.IsEqualTo, options.courseId.value.join('|'));
        }

        if (options.courseId.mode === 'exclusive') {
            for (const id of options.courseId.value) {
                filters.where('id').filter(Operator.IsNotEqualTo, id);
            }
        }
    }

    if (options[FilterType.Topic]?.value.length) {
        filters.where('topic_id').filter(Operator.Have, options[FilterType.Topic]!.value.join('|'));
    }

    if (options[FilterType.Category]?.value.length) {
        filters.where('category_id').filter(Operator.Have, options[FilterType.Category]!.value.join('|'));
    }

    if (options[FilterType.CourseAuthor]?.value.length) {
        filters.where('author_id').filter(Operator.Have, options[FilterType.CourseAuthor]!.value.join('|'));
    }

    if (options[FilterType.EventAuthor]?.value.length) {
        filters.where('author_id').filter(Operator.Have, options[FilterType.EventAuthor]!.value.join('|'));
    }

    if (options[FilterType.CourseType]?.value.length) {
        filters
            .where('course_type_provider')
            .filter(Operator.IsEqualTo, options[FilterType.CourseType]!.value.join('|'));
    }

    if (options[FilterType.Skill]?.value.length) {
        filters.where('skill_ids').filter(Operator.Have, options[FilterType.Skill]!.value.join('|'));
    }

    if (options[FilterType.CourseEnrollmentStatus]?.value.length) {
        if (options[FilterType.CourseEnrollmentStatus]?.value.includes(EnrollmentType.ManagerEnrollment)) {
            filters.where('manager_enrollment').filter(Operator.IsEqualTo, true);
        }
        if (options[FilterType.CourseEnrollmentStatus]?.value.includes(EnrollmentType.SelfEnrollment)) {
            filters.where('self_enrollment').filter(Operator.IsEqualTo, true);
        }
    }

    return filters.build();
}

export function createCourse({ courseType }: { courseType: CourseType }) {
    return builderCourseApiClient.postCourse({ course_type: courseType }).then(extractAxiosData);
}

export function editIframeElement(guid, data: PutIframeContentRequest) {
    return builderContentApiClient.putIframeContent(guid, data).then(extractAxiosData);
}

export function editPdfElement(guid, data: PutPdfContentRequest) {
    return builderContentApiClient.putPdfContent(guid, data).then(extractAxiosData);
}

export function editVideoElement(guid, data: VideoContentElement) {
    return builderContentApiClient
        .putVideoContent(guid, { file_url: data.video_url, filename: data.filename })
        .then(extractAxiosData);
}
export function editImageElement(guid, data: PutImageContentRequest) {
    return builderContentApiClient.putImageContent(guid, data).then(extractAxiosData);
}
export function editPodcastElement(guid, data: PutPodcastContentRequest) {
    return builderContentApiClient.putPodcastContent(guid, data).then(extractAxiosData);
}

export function editTextElement(guid, data: Content) {
    return builderContentApiClient.putTextContent(guid, data).then(extractAxiosData);
}

export function editQuizChoiceElement(guid, data: QuizChoiceQuestionContentBuilderContract) {
    if (!data.question) data.question = '';
    if (!data.description) data.description = '';

    if (data.choice_options) {
        data.choice_options = data.choice_options.filter(x => x.option !== '');

        if (!data.multiple_answers && data.choice_options.filter(x => x.is_correct).length > 1) return;
    }

    return builderContentApiClient.putQuizChoiceQuestionContent(guid, data).then(extractAxiosData);
}

export function editQuizRatingElement(guid: string, data: PutQuizRatingScaleRequest) {
    if (!data.question) {
        return;
    }
    return builderContentApiClient.putQuizRatingScaleContent(guid, data).then(extractAxiosData);
}

function _editChoiceListElement(guid, data: ChoiceQuestionContentBuilderContract) {
    if (!data.question) data.question = '';
    if (!data.description) data.description = '';

    if (data.choice_options) {
        data.choice_options = data.choice_options.filter(x => x.option !== '');

        if (!data.multiple_answers && data.choice_options.filter(x => x.is_correct).length > 1) return;
    }

    return builderContentApiClient.putChoiceQuestionContent(guid, data).then(extractAxiosData);
}

export function storeQuizAttempt(attempt: StoreQuizAttemptRequest) {
    return quizApiClient.storeQuizAttempt(attempt);
}
export function getQuizAttempts(attemptId: number) {
    return quizApiClient.getQuizAttempt(attemptId).then(extractAxiosData);
}

export function getAllCoursesNew(options: CourseListRequestOptions) {
    const filters = options.filters ? getCourseListFiltersParam(options.filters) : undefined;
    const pageNumber = options.page ?? 1;
    const pageSize = options.pageSize ?? 10;

    return adminOverviewApiClient
        .getListOfCourses(filters, undefined, pageNumber, pageSize)
        .then(extractAxiosData) as Promise<CoursesPaginatedResponse>;
}

interface SubmitAttemptPayload {
    courseId: number;
    chapterId: number;
    pageId: number;
}

export function submitAttempt({ courseId, chapterId, pageId }: SubmitAttemptPayload) {
    return playerQuizApiClient.submitAttempt(courseId, chapterId, pageId).then(extractAxiosData);
}

interface CreateNewAttemptPayload {
    courseId: number;
    chapterId: number;
    pageId: number;
}

interface SubmitSingleQuizAttempt extends CreateNewAttemptPayload {
    questionGuid: string;
}

export function createNewAttempt({ courseId, chapterId, pageId }: CreateNewAttemptPayload) {
    return playerQuizApiClient.createNewAttempt(courseId, chapterId, pageId).then(extractAxiosData);
}

export function submitQuestion({ courseId, chapterId, pageId, questionGuid }: SubmitSingleQuizAttempt) {
    return playerQuizApiClient
        .submitSingleQuizAttempt(courseId, chapterId, pageId, questionGuid)
        .then(extractAxiosData);
}

export const editChoiceListElement = debounce(_editChoiceListElement, 300);

export function editQuizOpenTextElement(guid: string, data: PutQuizOpenTextRequest) {
    if (!data.question) {
        return;
    }
    return builderContentApiClient.putQuizOpenTextContent(guid, data).then(extractAxiosData);
}

export function editQuizFileUploadElement(guid: string, data: PutQuizFileUploadRequest) {
    if (!data.question) {
        return;
    }
    return builderContentApiClient.putQuizFileUploadContent(guid, data).then(extractAxiosData);
}

export function editAttachmentElement(guid, data: AttachmentContentElement) {
    return builderContentApiClient
        .putAttachmentContent(guid, {
            data: data.attachment_content_items!.map(item => {
                return {
                    ...item,
                    id: item.id!,
                    file_size: item.file_size!,
                    filename: item.file_name!,
                };
            }),
        })
        .then(extractAxiosData);
}

export function deleteCoursesBulk(coursesIds: number[]) {
    return builderCourseApiClient.deleteCourses({
        course_ids: coursesIds,
    });
}

export function duplicateCourse(payload: DuplicateCourseRequest) {
    return builderCourseApiClient.duplicate(payload);
}

export function archiveCoursesBulk(coursesIds: (number | undefined)[]) {
    if (coursesIds.includes(undefined)) {
        return;
    }
    return builderCourseApiClient.archiveCourses({ course_ids: coursesIds });
}

export function unArchiveCoursesBulk(coursesIds: (number | undefined)[]) {
    if (coursesIds.includes(undefined)) {
        return;
    }
    return builderCourseApiClient.unArchiveCourses({ course_ids: coursesIds });
}

export function updateCourseAuthor(courseId: number, userId: number) {
    return builderCourseApiClient.updateCourseAuthor(courseId, { author_id: userId });
}

interface GetUsersQueryExclusiveSelection {
    excluded_user_ids: number[];
}
interface GetUsersQueryInclusiveSelection {
    included_user_ids: number[];
}

export type GetUsersQuerySmartSelection = GetUsersQueryInclusiveSelection | GetUsersQueryExclusiveSelection;

export function getUsersBySmartSelection({
    selection,
    page = 1,
    pageSize = 10,
    searchQuery,
}: {
    selection: GetUsersQuerySmartSelection;
    page: number;
    pageSize: number;
    searchQuery?: string;
}) {
    const filters = buildFilter('full_name', searchQuery);

    return userApiClient.get(filters, undefined, page, pageSize, selection).then(extractAxiosData);
}

export interface AddAssignmentQueryExclusiveSelection {
    mode: 'exclusive';
    queryArgs: {
        excluded_user_ids: number[];
    };
}
export interface AddAssignmentQueryInclusiveSelection {
    mode: 'inclusive';
    queryArgs: {
        user_ids: number[];
    };
}

export type AddAssignmentQuerySelection = AddAssignmentQueryInclusiveSelection | AddAssignmentQueryExclusiveSelection;

export function getAdminOverviewReport() {
    return adminOverviewApiClientV1.getReport({ responseType: 'blob' }).then(extractAxiosData);
}

export function resetScormProgress(courseId: number) {
    return builderScormCourseApiClient.resetProgress(courseId);
}
