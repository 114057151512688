import { Topic } from '@/admin/topics/models/topic';
import { DataRequestOptions, TablePaginatedData } from '@/common/components/table/v2/interfaces';
import { FluentFilterCollection, isNotEmpty, Operator } from '@/common/services';
import { Filter } from '@/reporting/segments/models/segment.model';

export interface TopicSearchOptions extends DataRequestOptions {
    category_id?: number;
    topic_ids?: number[];
    filter?: Filter;
}

import { TopicApiFactory } from '@eloomi/eloomi-topics-persona-client/1.0';

import { authorizeClient } from '@/api/authorize-client';
import { extractAxiosData } from '@/common/services/extract-axios-data';

const topicApiClient = authorizeClient(TopicApiFactory);

export function getTopics(options: TopicSearchOptions) {
    const filters = new FluentFilterCollection();

    if (isNotEmpty(options.searchQuery)) {
        filters.where('name').filter(Operator.Contains, options.searchQuery!);
    }

    if (options.category_id) {
        filters.where('category_id').filter(Operator.Have, options.category_id);
    }

    if (options.topic_ids) {
        filters.where('id').filter(Operator.IsEqualTo, options.topic_ids.join('|'));
    }

    if (options.filter?.mode) {
        if (options.filter.mode === 'inclusive') {
            filters.where('id').filter(Operator.IsEqualTo, options.filter?.selectedIds.join('|'));
        } else {
            for (const id of options.filter.selectedIds) {
                filters.where('id').filter(Operator.IsNotEqualTo, id);
            }
        }
    }

    return topicApiClient.get(filters.build(), '-id', options.page, options.pageSize).then(extractAxiosData) as Promise<
        TablePaginatedData<Topic>
    >;
}
