import { injectBrandedCSSVariables } from '@eloomi/design-tokens';
import tokens from '@eloomi/design-tokens/build/colors';
import { Branding } from '@/common/models';

export type SetBrandingCallback = (data: Branding) => void;

type OverriddenBrandingColors = {
    branding: string;
    primary: string;
};

export function mapBrandingToDSColors(brandingData: Branding): OverriddenBrandingColors {
    return {
        branding: brandingData.branding_color!,
        primary: brandingData.primary_color!,
    };
}

export function restoreDefaultDesignSystemColorsWithBranding() {
    const colors: OverriddenBrandingColors = {
        branding: tokens.color.branding.value as string,
        primary: tokens.color.primary.value as string,
    };
    injectBrandedCSSVariables(colors);
}

export function overrideDesignSystemColorsWithBranding(data: Branding) {
    const colors = mapBrandingToDSColors(data);
    injectBrandedCSSVariables(colors);
}
