import { Getters } from 'vuex-smart-module';

import { UserCoursesState } from './state';

export class UserCoursesGetters extends Getters<UserCoursesState> {
    public get activeChapter() {
        if (this.state.activeCourse !== null) {
            return this.state.activeCourse.chapters.find(chapter => chapter.id === this.state.activeChapterId);
        }
    }

    public get activePage() {
        const currentChapter = this.getters.activeChapter;
        if (currentChapter !== undefined) {
            return currentChapter.pages.find(page => page.id === this.state.activePageId);
        }
    }

    public get isActivePageCompleted() {
        if (this.getters.activePage !== undefined) {
            return this.getters.activePage.is_completed;
        }
    }

    public getChapter(chapterId) {
        if (this.state.activeCourse !== null) {
            return this.state.activeCourse.chapters.find(chapter => chapter.id === chapterId);
        }
    }

    public activeCourseHasChapterWithPage(chapterId, pageId) {
        const chapter = this.getters.getChapter(chapterId);
        if (chapter !== undefined) {
            return chapter.pages.some(page => page.id === pageId);
        }
        return false;
    }

    public get getSelectedTopics() {
        return this.state.selectedTopics;
    }
}
