import Vue from 'vue';
import Vuex from 'vuex';
import { Module } from 'vuex-smart-module';

import { plansStore, subscriptionStore } from '@/admin/billing/store';
import EmailsStore from '@/admin/emails/store';
import userGroupStore from '@/admin/groups/store';
import customFieldsStore from '@/admin/settings/store';
import mergeIntegrationStoreModule from '@/admin/skills/store';
import toastNotifications from '@/common/components/toast/store';
import { createSmartStore } from '@/common/services/create-smart-store';
import brandingStore from '@/common/store/branding/store';
import httpStore from '@/common/store/http';
import mobileModeStore from '@/common/store/mobile-mode';
import userProfileDetails from '@/common/store/user-details/store';
import contentStore from '@/content-store/store';
import UserCoursesStore from '@/courses/store';
import CoursePlayerStore from '@/courses/store/course-player';
import { eventsOverviewStoreModule } from '@/courses/store/events-overview';
import { learningOverviewStoreModule } from '@/courses/store/learning-overview';
import environment from '@/environment';
import impersonationStore from '@/impersonation/store';
import launchDarklyFeatures from '@/launch-darkly/store';
import notificationsStore from '@/notifications/store';
import openInAppStore from '@/open-in-app/store';
import planFeaturesStore from '@/plan-features/store';
import { skillSetupStore } from '@/profile-settings/components/self-skills-setup/SkillSetupStore';
import translationsStore from '@/translations/store';
import whatsNewStore from '@/whatsnew/store';

Vue.use(Vuex);

export const smartRootModule = new Module({
    modules: {
        brandingStore,
        contentStore,
        coursePlayer: CoursePlayerStore,
        courses: UserCoursesStore,
        customFieldsStore,
        EmailsStore,
        eventsOverview: eventsOverviewStoreModule,
        httpStore,
        impersonation: impersonationStore,
        launchDarklyFeatureStore: launchDarklyFeatures,
        learningOverview: learningOverviewStoreModule,
        mergeIntegrationStoreModule,
        mobileMode: mobileModeStore,
        notificationsStore,
        openInAppStore,
        planFeatures: planFeaturesStore,
        plansStore,
        skillSetupStore,
        subscriptionStore,
        toastNotifications,
        translationsStore,
        userGroup: userGroupStore,
        userProfileDetails,
        whatsnew: whatsNewStore,
    },
});

const store = createSmartStore(smartRootModule, {
    strict: !environment.isProduction,
});

export default store;
