import { inject, injectable } from 'tsyringe';

import { clearBrandingDataFromLocalStorage } from '@/common/services';
import { ServiceTokens } from '@/di/tokens';
import { clearFeaturesToggleDataFromLocalStorage } from '@/launch-darkly/services';

export interface StoreCleaner {
    clearUserDetails(): void;
}

@injectable()
export class ResetSessionDataService {
    public constructor(@inject(ServiceTokens.UserDetailsStoreService) private readonly storeCleaner: StoreCleaner) {}

    reset() {
        this.resetUserData();
        this.resetFeaturesToggleData();
        this.resetBrandingData();
    }

    resetUserData() {
        this.storeCleaner.clearUserDetails();
    }

    resetFeaturesToggleData() {
        clearFeaturesToggleDataFromLocalStorage();
    }

    resetBrandingData() {
        clearBrandingDataFromLocalStorage();
    }
}
