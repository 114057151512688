import { render, staticRenderFns } from "./CoreFlexFullWidthLayout.vue?vue&type=template&id=569d09f0&scoped=true&"
import script from "./CoreFlexFullWidthLayout.vue?vue&type=script&lang=ts&"
export * from "./CoreFlexFullWidthLayout.vue?vue&type=script&lang=ts&"
import style0 from "./CoreFlexFullWidthLayout.vue?vue&type=style&index=0&id=569d09f0&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "569d09f0",
  null
  
)

export default component.exports