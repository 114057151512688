<template>
    <svg
        class="progress"
        :width="boundingSize"
        :height="boundingSize"
        :viewPort="`0 0 ${boundingSize} ${boundingSize}`"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        :style="rootStyle"
    >
        <circle
            class="circle bg-circle"
            fill="none"
            :r="radius"
            :cx="centerPoint"
            :cy="centerPoint"
            :stroke-dasharray="strokeDashArray"
        ></circle>
        <circle
            class="circle progress-value"
            :class="progressClasses"
            fill="none"
            :r="radius"
            :cx="centerPoint"
            :cy="centerPoint"
            :stroke-dasharray="strokeDashArray"
            :stroke-dashoffset="strokeDashOffset"
        ></circle>
    </svg>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    type Size = 'small' | 'medium' | 'large';
    type SizeMap = { [k in Size]: number };

    @Component({})
    export default class CoreCircleProgress extends Vue {
        @Prop({ default: 'large' }) public size!: Size;
        @Prop({ default: 50 }) public progress!: number;
        @Prop({ default: false, type: Boolean }) public isSpinning!: boolean;

        public get diameter() {
            const sizeMap: SizeMap = { small: 24, medium: 36, large: 48 };
            return sizeMap[this.size];
        }

        public get radius(): number {
            return this.diameter / 2;
        }

        public get boundingSize() {
            return this.diameter + this.strokeWidth;
        }

        public get centerPoint() {
            return this.boundingSize / 2;
        }

        public get strokeWidth() {
            const sizeMap: SizeMap = { small: 4, medium: 6, large: 6 };
            return sizeMap[this.size];
        }

        public get strokeDashArray(): number {
            return 2 * Math.PI * this.radius;
        }

        public get strokeDashOffset(): number {
            return this.strokeDashArray - (this.progress / 100) * this.strokeDashArray;
        }

        public get progressClasses() {
            return { spinning: this.isSpinning };
        }

        public get rootStyle() {
            return {
                '--stroke-width': this.strokeWidth,
            };
        }
    }
</script>

<style lang="less" scoped>
    .progress {
        transform: rotate(-90deg);
    }

    .circle {
        stroke-width: var(--stroke-width);
        stroke-linecap: round;

        &.bg-circle {
            stroke: @primary-color-8;
        }

        &.progress-value {
            .generic-transition(stroke-dashoffset);

            stroke: @primary-color;
        }

        &.spinning {
            transform-origin: center;
            animation: spin 0.8s infinite ease-out;
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        10% {
            transform: rotate(5deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
</style>
