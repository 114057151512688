<template>
    <MainThemeProvider>
        <CoreMainLayout>
            <template #sticky-header>
                <AppHeader withNotifications withBackButton />
            </template>
            <template #content>
                <MobileFallback class="mobile-fallback-page__fallback" />
            </template>
        </CoreMainLayout>
    </MainThemeProvider>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    import MobileFallback from '@/common/components/error/MobileFallback.vue';
    import MainThemeProvider from '@/main/MainThemeProvider.vue';
    import AppHeader from '@/common/components/app-header/AppHeader.vue';

    @Component({
        components: { MobileFallback, MainThemeProvider, AppHeader },
    })
    export default class CoursePlayerRootPage extends Vue {}
</script>

<style lang="less" scoped>
    .mobile-fallback-page__fallback {
        padding-top: @core-header-height;
        background-color: @branding-color-4;
    }
    ::v-deep .content-slot {
        height: 100%;
    }
</style>
