<template>
    <router-link :to="link">
        <img :src="image || eloomiLogoImageUrl" class="logo" alt="" />
    </router-link>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import eloomiLogoImageUrl from '@/assets/img/eloomi-logo.png';

    @Component
    export default class CompanyLogo extends Vue {
        @Prop() image?: string;
        @Prop() link?: string;

        eloomiLogoImageUrl = eloomiLogoImageUrl;
    }
</script>

<style lang="less" scoped>
    .logo {
        max-width: 200px;
        max-height: 40px;
        .sm-viewport-and-smaller({
            max-height: 32px;
        });
    }
</style>
