import { authorizeClient } from '@/api/authorize-client';
import { TopicApiFactory } from '@eloomi/eloomi-event-management-persona-client/1.0';

const processedTopicsIds = topicsIds => {
    return { topic_ids: topicsIds };
};

export const topicApiClient = authorizeClient(TopicApiFactory);

export const unassignTopic = (topicsId: number[], eventId: number) => {
    return topicApiClient.deleteEventTopics(eventId, topicsId);
};

export const syncTopics = (topicIds: number[], eventId: number) => {
    return topicApiClient.updateEventTopics(eventId, processedTopicsIds(topicIds));
};

export const assignTopics = (topicIds: number[], eventIds: number[]) => {
    const payload = {
        event_ids: eventIds,
        topic_ids: topicIds,
    };

    return topicApiClient.addEventTopics(payload);
};
