<template>
    <ModalWindow
        :isVisible="isVisible"
        :withBackground="false"
        :isScrollable="true"
        additionalClassContainerWrapper="modal-fullscreen-overlay-wrapper"
        modalWindowClass="modal-fullscreen-overlay"
        @request-close="$emit('request-close')"
        @contentClick="$emit('request-close')"
        @close="$emit('close')"
    >
        <template #header>
            <slot name="header"></slot>
        </template>
        <template #content>
            <ModalWindowContent>
                <div class="overlay" :style="overlayStyle" @click.self="$emit('close')">
                    <slot name="content"></slot>
                </div>
            </ModalWindowContent>
        </template>
    </ModalWindow>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import ModalWindow from '@/ui-kit/modal-window/ModalWindow.vue';
    import ModalWindowContent from '@/ui-kit/modal-window/ModalWindowContent.vue';

    @Component({
        components: {
            ModalWindow,
            ModalWindowContent,
        },
    })
    export default class ModalWindowFullscreenOverlay extends Vue {
        @Prop({ default: false }) public isVisible!: boolean;
        @Prop({ default: () => ({}) }) public overlayStyle!: Partial<CSSStyleDeclaration>;
    }
</script>

<style lang="less">
    @background-header-color: #061725;
    @modal-fullscreen-header-height: 80px;

    .modal-window-container.modal-fullscreen-overlay-wrapper {
        width: 100%;
        height: 100vh;
        max-height: 100%;
        margin: 0;
        background: none;
        background-image: radial-gradient(circle at 50% 33%, rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0.5) 80%),
            linear-gradient(to bottom, rgba(1, 7, 17, 0.8), rgba(1, 7, 17, 0.8));
        background-blend-mode: soft-light, normal;

        .overlay {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            padding: @spacing-56;
        }

        .sm-viewport-and-smaller({
            .overlay {
                padding: 0;

                .device-border {
                    border: none;
                    border-radius: 0;
                }
            }
        });

        .modal-fullscreen-overlay {
            &.modal-window {
                width: 100% !important;
                height: 100vh;
                max-height: 100%;
                margin: 0 !important;
                padding-top: var(--top-notch-header-padding, 0);
                background: none;
            }
            .header {
                display: flex;
                flex-direction: column;
                align-items: baseline;
                justify-content: center;
                padding-left: @spacing-48;
                overflow: hidden;
                color: @bright-color;
                white-space: nowrap;
                word-break: break-all;
                background-color: @background-header-color;
                border-radius: 0;

                h3 {
                    color: @bright-color;
                }

                .sm-viewport-and-smaller({
                    width: 60%;
                });
            }
            .close-icon {
                position: fixed;
                top: calc(29px + var(--top-notch-header-padding, 0px));
                right: 48px;
            }
            .content {
                display: flex;
                justify-content: center;
                height: 100vh !important;
                max-height: calc(100vh - @modal-fullscreen-header-height - var(--top-notch-header-padding, 0px));
                background: none;
            }
            .modal-window-content {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                padding: 0;
                overflow: auto;
            }
        }
    }
</style>
