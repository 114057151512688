<template>
    <div class="selector">
        <Popover
            ref="dropdown"
            :closeOnOutsideClick="closeOnOutsideClick"
            autoMaxHeight
            sameWidth
            :isTargetInline="false"
            :renderToPortal="renderToPortal"
            :portal="portal"
            :preventOutsideClickPropagation="preventOutsideClickPropagation"
            :popoverTestName="popupTestName"
        >
            <template #target="{ isVisible, hide, show, toggle }">
                <slot
                    name="input"
                    :searchTerm="searchTerm"
                    :isOpen="isVisible"
                    :close="hide"
                    :open="show"
                    :toggle="toggle"
                    :input="inputSearchTerm"
                    :isLoading="isLoading"
                ></slot>
            </template>
            <template v-if="showDropdownContainer" #content>
                <BasicDropdownContainer
                    ref="optionsContainer"
                    class="dropdown-container"
                    :class="{ 'with-header': $slots.header, 'with-footer': $slots.footer }"
                    :style="contentStyles"
                    :overlapContent="true"
                >
                    <slot name="header" />
                    <FadeTransition>
                        <template v-if="filteredOptions.length === 0 && isLoading === false">
                            <div>
                                <slot name="no-options" />
                            </div>
                        </template>

                        <template v-else-if="isLoading && showLoaderItem">
                            <slot name="loader-element">
                                <div>
                                    <DropdownItem
                                        class="list-loader"
                                        icon="reset"
                                        :title="trans('spa.label.selector.loading')"
                                    >
                                        <template #icon>
                                            <elm-date-time-reset-icon />
                                        </template>
                                    </DropdownItem>
                                </div>
                            </slot>
                        </template>
                        <div
                            v-else-if="filteredOptions.length > 0"
                            class="items-container"
                            :class="{ 'with-scroll': $slots.header || $slots.footer }"
                        >
                            <!-- eslint-enable vue/no-deprecated-v-on-native-modifier -->
                            <template v-for="(option, index) in filteredOptions">
                                <slot
                                    name="option"
                                    :searchTerm="searchTerm"
                                    :option="option"
                                    :select="select"
                                    :isSelected="isSelected(option)"
                                    :index="index"
                                    :isLoading="isLoading"
                                />
                            </template>
                            <slot
                                name="options"
                                :options="filteredOptions"
                                :select="select"
                                :isSelected="isSelected"
                                :searchTerm="searchTerm"
                                :isLoading="isLoading"
                            />
                            <div v-observe-visibility="onScrolledToBottom" />
                            <slot v-if="isLoadingMore" name="loader-more-element">
                                <DropdownItem
                                    class="list-loader"
                                    icon="reset"
                                    :title="trans('spa.label.selector.loading')"
                                >
                                    <template #icon>
                                        <elm-date-time-reset-icon />
                                    </template>
                                </DropdownItem>
                            </slot>
                        </div>
                    </FadeTransition>
                    <slot name="footer" />
                </BasicDropdownContainer>
            </template>
        </Popover>
    </div>
</template>

<script lang="ts">
    import '@eloomi/icons/date-time/date-time-reset';

    import { get, isArray, isObject } from 'lodash-es';
    import { computed, defineComponent, onMounted, PropType, Ref, ref, watch } from 'vue';
    import { ObserveVisibility } from 'vue-observe-visibility';

    import { useTranslation } from '@/common/composables';
    import BasicDropdownContainer from '@/ui-kit/dropdown/containers/BasicDropdownContainer.vue';
    import DropdownContainer from '@/ui-kit/dropdown/containers/DropdownContainer.vue';
    import DropdownItem from '@/ui-kit/dropdown/items/DropdownItem.vue';
    import Popover from '@/ui-kit/popover/Popover.vue';
    import FadeTransition from '@/common/components/transitions/FadeTransition.vue';

    export default defineComponent({
        name: 'AppSelector',
        components: { BasicDropdownContainer, DropdownItem, FadeTransition, Popover },
        directives: { ObserveVisibility },
        props: {
            position: {
                type: String as PropType<'top' | 'bottom'>,
                default: 'bottom',
            },
            value: {
                type: Array as PropType<any[]>,
                default: () => [],
            },
            clearOnSelect: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            preventOutsideClickPropagation: {
                type: Boolean as PropType<boolean>,
                default: true,
            },
            renderToPortal: {
                type: Boolean as PropType<boolean>,
                default: true,
            },
            closeOnSelect: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            closeOnOutsideClick: {
                type: Boolean as PropType<boolean>,
                default: true,
            },
            isLoading: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            isLoadingMore: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            options: {
                type: Array as PropType<any[]>,
                default: () => [],
            },
            maxHeight: {
                type: Number as PropType<number>,
                default: 300,
            },
            filterOutSelected: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            showLoaderItem: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            trackBy: {
                type: String as PropType<string | undefined>,
            },
            searchWithin: {
                type: [String, Array] as PropType<string | string[] | undefined>,
            },
            contentOffset: {
                type: Number as PropType<number>,
                default: 0,
            },
            openOnMount: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            openOnMountDelayMs: {
                type: Number as PropType<number>,
                default: 0,
            },
            overlapContent: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            portal: {
                type: String as PropType<string>,
            },
            popupTestName: {
                type: String as PropType<string>,
            },
            showDropdownContainer: {
                type: Boolean as PropType<boolean>,
                default: true,
            },
        },
        emits: ['open', 'close', 'input', 'remove', 'select', 'inputSearchTerm', 'scrollEnd', 'before-open-on-mount'],
        setup(props, { emit }) {
            // Elements
            const dropdown = ref<InstanceType<typeof Popover> | null>(null);
            const optionsContainer = ref<InstanceType<typeof DropdownContainer> | null>(null);

            // Refs

            const searchTerm = ref('');
            const isOpen = computed(() => dropdown.value?.isVisible ?? false);
            const options = computed(() => props.options);
            const selectedOptions = computed(() => props.value);

            const filteredOptions = computed(() => {
                return options.value.filter(filterBySearchTerm).filter(filterOutSelected);
            });

            // SelectorType

            const optionsType: Ref<'object' | 'plain'> = computed(() => {
                return props.options.every(option => isObject(option)) ? 'object' : 'plain';
            });

            // Filter Functions

            const filterBySearchTerm = option => {
                if (searchTerm.value === '') {
                    return true;
                }
                if (optionsType.value === 'object') {
                    if (props.searchWithin === undefined) {
                        throw new Error('Please specify searchWithin prop when using objects as option items.');
                    }
                    if (isArray(props.searchWithin)) {
                        const valuesToCheck = props.searchWithin.map(key => get(option, key));

                        return valuesToCheck.some(value =>
                            String(value).toLowerCase().includes(searchTerm.value.toLowerCase())
                        );
                    } else {
                        return get(option, props.searchWithin).toLowerCase().includes(searchTerm.value.toLowerCase());
                    }
                } else {
                    return option.toLowerCase().includes(searchTerm.value.toLowerCase());
                }
            };

            const filterOutSelected = option => (props.filterOutSelected ? !props.value.includes(option) : true);

            // Utils

            const findOptionIndex = option => {
                if (option === undefined) {
                    return -1;
                }

                if (optionsType.value === 'object' && option !== undefined) {
                    const trackBy = props.trackBy;
                    if (trackBy === undefined) {
                        throw new Error('Please specify trackBy prop when using objects as option items.');
                    }
                    return props.value.findIndex(o => get(o, trackBy) === get(option, trackBy));
                }
                return props.value.indexOf(option);
            };

            const isSelected = option => {
                if (optionsType.value === 'object') {
                    return findOptionIndex(option) !== -1;
                }
                return props.value.includes(option);
            };

            // Watchers

            watch(isOpen, () => (dropdown.value?.isVisible ? emit('open') : emit('close')));
            watch(selectedOptions, () => props.value.length && emit('input', selectedOptions.value));

            // Functionalities

            const deselect = option => {
                const optionIndex = findOptionIndex(option);
                if (optionIndex !== -1) {
                    emit(
                        'input',
                        props.value.filter((_, index) => index !== optionIndex)
                    );
                }
            };

            const clearSearch = () => {
                searchTerm.value = '';
            };

            const select = option => {
                if (isSelected(option)) {
                    deselect(option);
                    emit('remove', option);
                } else {
                    emit('input', [...props.value, option]);
                    emit('select', option);
                }
                if (props.closeOnSelect) {
                    dropdown.value?.hide();
                }
                if (props.clearOnSelect) {
                    clearSearch();
                    emit('inputSearchTerm', searchTerm.value);
                }
            };

            const scrollOptionsContainerToTop = () => {
                optionsContainer.value?.$el.scrollTo(0, 0);
            };

            const inputSearchTerm = search => {
                searchTerm.value = search;
                emit('inputSearchTerm', search);
                if (dropdown.value?.isVisible === false) {
                    dropdown.value?.show();
                }
            };
            const reset = () => emit('input', []);

            const close = () => dropdown.value?.hide();

            const onScrolledToBottom = isScrolledToBottom => {
                if (isScrolledToBottom) {
                    emit('scrollEnd');
                }
            };

            const contentStyles = computed(() => {
                return {
                    maxHeight: `${props.maxHeight}px`,
                };
            });

            onMounted(() => {
                if (props.openOnMount && dropdown.value) {
                    setTimeout(() => {
                        emit('before-open-on-mount');
                        dropdown.value!.show();
                    }, props.openOnMountDelayMs);
                }
            });

            const { trans } = useTranslation();

            return {
                dropdown,
                optionsContainer,
                isSelected,
                searchTerm,
                filteredOptions,
                inputSearchTerm,
                select,
                deselect,
                reset,
                onScrolledToBottom,
                isOpen,
                close,
                scrollOptionsContainerToTop,
                clearSearch,
                contentStyles,
                trans,
            };
        },
    });
</script>

<style lang="less" scoped>
    .selector {
        display: block;
    }

    .no-content {
        margin: 0;
        padding: @spacing-8;
        color: @dark-color;
    }

    .items-container {
        display: flex;
        flex-direction: column;

        &.with-scroll {
            max-height: 450px;
            overflow-y: auto;
        }
    }

    .dropdown-container {
        grid-template-rows: auto auto auto;

        &.with-header > :not(:first-child) {
            grid-row: 2;
        }

        &.with-footer > :last-child {
            grid-row: 3;
        }
    }

    .list-loader {
        pointer-events: none;
    }
</style>
