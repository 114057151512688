import axios, { AxiosInstance } from 'axios';

import { LoginSessionLoadError } from './errors';

export class AxiosAccessDeniedErrorHandler {
    constructor(private readonly httpClient: AxiosInstance) {}
    private responseInterceptorId?: number;

    private isErrorAlreadyHandled = false;

    private errorHandler?: () => boolean;

    private readonly forbiddenErrorHandler = error => {
        if (axios.isAxiosError(error)) {
            const response = error?.response;

            if (response?.status === 403 && this.handleForbiddenError()) {
                return;
            }
        } else if (error instanceof LoginSessionLoadError && this.handleForbiddenError()) {
            return;
        }

        throw error;
    };

    private handleForbiddenError() {
        if (this.errorHandler !== undefined && !this.isErrorAlreadyHandled) {
            this.isErrorAlreadyHandled = this.errorHandler();
        }
        return this.isErrorAlreadyHandled;
    }

    public install(handler: () => boolean) {
        if (this.responseInterceptorId !== undefined) {
            this.httpClient.interceptors.response.eject(this.responseInterceptorId);
        }
        this.errorHandler = handler;
        this.responseInterceptorId = this.httpClient.interceptors.response.use(r => r, this.forbiddenErrorHandler);
    }

    public uninstall() {
        if (this.responseInterceptorId === undefined) {
            return;
        }
        this.httpClient.interceptors.response.eject(this.responseInterceptorId);
        this.responseInterceptorId = undefined;
    }
}
