<template>
    <div
        class="tabs"
        :class="[
            { 'with-floating-buttons': withFloatingButtons },
            { 'center-tabs-buttons': withFloatingButtons && centerFloatButtons },
            { 'with-border': withBorder },
        ]"
        data-testid="tabs"
    >
        <TabButtonGroup v-if="showButtons" class="button-group" :class="`button-group-${size}`" :theme="theme">
            <TabButtonAnimation
                :key="showButtons"
                :refs="buttonRefs"
                :activeTabIndex="activeTabIndex"
                :theme="theme"
                @end="syncVisibleActive"
            />
            <template v-if="hasTabButtonSlot">
                <slot
                    v-for="(tabKey, index) in tabKeys"
                    name="tabButton"
                    :title="titles[index]"
                    :tabKey="tabKey"
                    :tabIndex="index"
                    :setActiveTab="setActiveTab"
                    :isTabActive="isTabActiveVisible"
                />
            </template>
            <template v-for="(title, index) in titles" v-else>
                <TabButton
                    :key="tabKeys[index]"
                    ref="buttonRefs"
                    :data-testid="`tab-button-${tabKeys[index]}`"
                    :title="title"
                    :theme="theme"
                    :size="size"
                    :isActive="isTabActiveVisible(tabKeys[index])"
                    @click="handleTabButtonClick(tabKeys[index], index)"
                ></TabButton>
            </template>
        </TabButtonGroup>

        <slot name="default" :activeTab="activeTab" :isTabActive="isTabActive"></slot>
    </div>
</template>

<script lang="ts">
    import { PropType } from 'vue';
    import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';

    import TabButton from './TabButton.vue';
    import TabButtonAnimation from './TabButtonAnimation.vue';
    import TabButtonGroup from './TabButtonGroup.vue';
    import { TabsSize, TabsTheme } from './types';

    @Component({
        components: { TabButtonGroup, TabButton, TabButtonAnimation },
    })
    export default class Tabs extends Vue {
        @Prop({ type: Array, default: () => [] }) public titles!: string[];
        @Prop({ type: Array }) public tabKeys!: string[];
        @Prop({ type: String }) public value?: string;
        @Prop({ default: true, type: Boolean }) public showButtons!: boolean;
        @Prop({ default: false, type: Boolean }) public centerFloatButtons!: boolean;
        @Prop({ default: false, type: Boolean }) public withFloatingButtons!: boolean;
        @Prop({ default: 'normal', type: String as PropType<TabsTheme> }) public theme!: TabsTheme;
        @Prop({ default: 'normal', type: String as PropType<TabsSize> }) public size!: TabsSize;
        @Prop({ default: false, type: Boolean }) public withBorder!: boolean;

        public activeTab = '';
        public activeTabVisible: string | null = null;
        public buttonRefs: HTMLElement[] = [];
        public $refs!: {
            buttonRefs: Vue[];
        };

        public created() {
            this.activeTab = this.value ?? this.tabKeys[0];
            this.syncVisibleActive();
        }

        @Watch('tabKeys')
        setButtonRefs() {
            this.$nextTick(() => {
                if (this.$refs.buttonRefs !== undefined) {
                    this.buttonRefs = this.$refs.buttonRefs.map(v => v.$el as HTMLElement);
                }
            });
        }

        public mounted() {
            this.$nextTick(() => {
                if (this.$refs.buttonRefs === undefined) {
                    this.buttonRefs = [...this.$el.querySelectorAll<HTMLElement>('.tab-button')];
                } else {
                    this.buttonRefs = this.$refs.buttonRefs.map(v => v.$el as HTMLElement);
                }
            });
        }

        public get hasTabButtonSlot() {
            return Boolean(this.$scopedSlots.tabButton);
        }

        public get activeTabIndex() {
            return Math.max(this.tabKeys.indexOf(this.activeTab), 0);
        }

        public syncVisibleActive() {
            this.activeTabVisible = this.activeTab;
        }

        @Watch('tabKeys')
        @Watch('value')
        public setDefaultTab() {
            this.activeTab = this.value ?? this.tabKeys[0];
            this.activeTabVisible = null;
        }

        public isTabActive(tabKey: string) {
            return this.activeTab === tabKey;
        }

        public isTabActiveVisible(tabKey: string) {
            return this.activeTabVisible === tabKey;
        }

        public handleTabButtonClick(tabKey: string, _tabIndex: number) {
            if (this.activeTab !== tabKey) {
                return this.setActiveTab(tabKey);
            }
        }

        @Emit('input')
        public setActiveTab(tabKey: string) {
            this.activeTab = tabKey;
            this.activeTabVisible = null;
            return tabKey;
        }
    }
</script>

<style lang="less" scoped>
    .tabs {
        display: flex;
        flex-direction: column;
        width: 100%;

        &.with-floating-buttons {
            position: relative;

            .button-group {
                position: absolute;
                left: 0;
            }
        }

        &.center-tabs-buttons {
            .button-group {
                left: 50%;
                transform: translateX(-50%);
            }
        }

        &.with-border {
            display: inline-flex;
            width: auto;
            border: 1px solid @info-color-20;
            border-radius: @border-radius-8;
        }
    }

    .button-group {
        position: relative;
        align-self: center;

        &.button-group-small {
            height: 32px;
            padding: @spacing-1;
            border-radius: @border-radius-16;
        }
    }
</style>
