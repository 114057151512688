import axios from 'axios';
import { inject, injectable } from 'tsyringe';

import type { JWT } from '@/authentication/services/interfaces';
import { extractAxiosData } from '@/common/services/extract-axios-data';

import { ImpersonationServiceTokens as Tokens } from '../di-tokens';
import { SelfImpersonationError } from '../errors';
import type { ImpersonationService, UserImpersonationApi } from '../interfaces';

@injectable()
export class UserImpersonationService implements ImpersonationService<JWT> {
    public constructor(
        @inject(Tokens.UserImpersonationApi)
        private readonly impersonationApi: UserImpersonationApi
    ) {}

    public async impersonateUser(userId: number): Promise<JWT> {
        try {
            const token = await this.impersonationApi.createSession({ id: userId }).then(extractAxiosData);
            return token as JWT;
        } catch (error) {
            if (axios.isAxiosError(error) && error.response?.status === 412) {
                throw new SelfImpersonationError();
            }
            throw error;
        }
    }

    public async stopImpersonationSession() {
        return await this.impersonationApi.closeSession().then(extractAxiosData);
    }
}
