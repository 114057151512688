import { NotificationResponse } from '@eloomi/eloomi-notifications-persona-client/1.0';
import { computed } from 'vue';
import { debounce } from 'lodash-es';
import { getNotificationText, getNotificationRoute } from '@/notifications/services/misc';
import { usePushNotificationStore } from './store';
import { useRouter } from '@/common/composables';

export function usePushNotifications() {
    const store = usePushNotificationStore();
    const router = useRouter();

    const loadMore = debounce(store.actions.getNotifications, 200);

    const redirectFromNotification = (notification: NotificationResponse) => {
        store.actions.setNotificationAsRead(notification);
        const route = getNotificationRoute(notification);
        if (route) {
            router.push(route);
        }

        store.mutations.closeNotificationsDrawer();
    };

    const notifications = computed(() => store.getters.notifications);
    const newNotificationsCount = computed(() => store.getters.newNotificationsCount);
    const { markAllNotificationsAsRead } = store.actions;
    const { closeNotificationsDrawer, openNotificationsDrawer } = store.mutations;

    return {
        closeNotificationsDrawer,
        getNotificationText,
        loadMore,
        markAllNotificationsAsRead,
        newNotificationsCount,
        notifications,
        openNotificationsDrawer,
        redirectFromNotification,
        store,
    };
}
