import {
    CreateEventUsersRequest,
    DeleteEventUsersRequest,
    EventUserAdminApiFactory,
    EventUserGroupAdminApiFactory,
    UpdateEventUserGroupsRequest,
} from '@eloomi/eloomi-event-management-persona-client/1.0';
import {
    BulkCreateEventUserGroupsRequest,
    BulkDeleteEventUserGroupsRequest,
} from '@eloomi/eloomi-event-management-persona-client/1.0/models';

import { GetAssignmentsOptions } from '@/admin/courses/components/assignment/interfaces';
import { authorizeClient } from '@/api/authorize-client';
import { buildFilter } from '@/common/services';
import { extractAxiosData } from '@/common/services/extract-axios-data';

const eventUserAdminApiClient = authorizeClient(EventUserAdminApiFactory);
const eventUserGroupAdminApiClient = authorizeClient(EventUserGroupAdminApiFactory);

type EventId = {
    eventId: number;
};

export function getEventAssignedUsers({
    eventId,
    searchQuery,
    sorts,
    page,
    pageSize,
}: GetAssignmentsOptions & EventId) {
    const filters = buildFilter('full_name', searchQuery);
    return eventUserAdminApiClient.getAllEventUsers(eventId, filters, sorts, page, pageSize).then(extractAxiosData);
}

export function getEventAssignableUsers({
    eventId,
    searchQuery,
    sorts,
    page,
    pageSize,
}: GetAssignmentsOptions & EventId) {
    const filters = buildFilter('full_name', searchQuery);
    return eventUserAdminApiClient
        .getAssignableEventUsers(eventId, filters, sorts, page, pageSize)
        .then(extractAxiosData);
}

export function deleteEventUsersAssignments(options: DeleteEventUsersRequest & EventId) {
    const { eventId, ...deleteOptions } = options;
    if (options.ids?.length === 1) {
        return eventUserAdminApiClient.deleteEventUser(options.ids[0]);
    } else {
        return eventUserAdminApiClient.deleteEventUsers(eventId, deleteOptions);
    }
}

export function createEventUsersAssignments(options: CreateEventUsersRequest & EventId) {
    const { eventId, ...createOptions } = options;
    return eventUserAdminApiClient.createEventUsers(eventId, createOptions);
}

export function getEventAssignableUserGroups({
    eventId,
    searchQuery,
    sorts,
    page,
    pageSize,
}: GetAssignmentsOptions & EventId) {
    const filters = buildFilter('name', searchQuery);
    return eventUserGroupAdminApiClient
        .getAssignableEventUserGroups(eventId, filters, sorts, page, pageSize)
        .then(extractAxiosData);
}

export function createBulkEventGroupsAssignments(options: BulkCreateEventUserGroupsRequest) {
    return eventUserGroupAdminApiClient.bulkCreateEventUserGroups(options);
}

export function updateEventGroupAssignment(options: UpdateEventUserGroupsRequest & EventId) {
    const { eventId, ...params } = options;
    return eventUserGroupAdminApiClient.updateEventUserGroups(eventId, params);
}

export function getEventAssignedUserGroups({
    eventId,
    searchQuery,
    sorts,
    page,
    pageSize,
}: GetAssignmentsOptions & EventId) {
    const filters = buildFilter('name', searchQuery);
    return eventUserGroupAdminApiClient
        .getEventUserGroups(eventId, filters, sorts, page, pageSize)
        .then(extractAxiosData);
}

export function deleteEventUserGroupsAssignments(options: BulkDeleteEventUserGroupsRequest & EventId) {
    const { eventId, ...deleteOptions } = options;
    return eventUserGroupAdminApiClient.bulkDeleteEventUserGroups(eventId, deleteOptions);
}
