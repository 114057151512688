import { ResetUserPasswordRequest } from '@eloomi/eloomi-users-external-client/1.0';
import axios from 'axios';
import { inject, injectable } from 'tsyringe';
import type { Store } from 'vuex';

import { ServiceTokens as BaseTokens } from '@/di/tokens';

import { AuthenticationServiceTokens } from '../di-tokens';
import { SessionContainer } from '../session/session-container';
import { PlatformJWTPayload, PlatformServiceBase } from './base/platform-service-base';
import type { ForgotPasswordApiClient, JWT, PlatformResolver } from './interfaces';
import { getJWTPayload } from './payload';
import { getDefaultTokenValidationResult, TokenValidationResult } from './token-manager';

export interface ResetPasswordJWTPayload extends PlatformJWTPayload {
    'user-id': string;
    'user-email': string;
}

@injectable()
export class ResetPasswordService extends PlatformServiceBase {
    constructor(
        @inject(AuthenticationServiceTokens.ForgotPasswordApiClient)
        private readonly forgotPasswordApiClient: ForgotPasswordApiClient,

        @inject(BaseTokens.PlatformResolutionService)
        platformResolver: PlatformResolver,

        @inject(BaseTokens.BaseStore)
        baseStore: Store<unknown>,

        @inject(AuthenticationServiceTokens.SessionContainer)
        sessionContainer: SessionContainer<JWT>
    ) {
        super(platformResolver, baseStore, sessionContainer);
    }

    protected verifyToken = async (token: string): Promise<TokenValidationResult> => {
        let isCodeValid: boolean;
        try {
            const validationResult = await this.forgotPasswordApiClient.verifyPasswordReset(token);
            isCodeValid = validationResult.data.success ?? false;
        } catch {
            isCodeValid = false;
        }

        return getDefaultTokenValidationResult(isCodeValid);
    };

    public static getPayload(token: JWT): ResetPasswordJWTPayload {
        return getJWTPayload<ResetPasswordJWTPayload>(token, ['eloomi-platform-id', 'user-id', 'user-email']);
    }

    public async resetPassword(token: JWT, password: string) {
        const payload = ResetPasswordService.getPayload(token);
        await this.validatePlatform(payload);

        const request: ResetUserPasswordRequest = { user_id: Number(payload['user-id']), password };

        try {
            await this.forgotPasswordApiClient.resetPassword(token, request);
        } catch (error) {
            if (axios.isAxiosError(error) && error.response?.status === 400) {
                /**
                 * Password validation etc should be implemented in the upcoming ticket
                 */
                throw new Error('Unknown endpoint error');
            }
            throw error;
        }
    }
}
