<template>
    <div class="group-icon" :class="[sizeClass]" :style="{ ...iconSize, background: colorDefault }">
        <span class="group-name bright-color">
            {{ groupName }}
        </span>
    </div>
</template>

<script lang="ts">
    import { computed, defineComponent, PropType } from 'vue';

    export type IconSize = 'small' | 'large' | 'extra-small' | number;

    export default defineComponent({
        props: {
            size: {
                type: [String, Number] as PropType<IconSize>,
                default: 'small',
            },
            name: {
                type: String as PropType<string>,
            },
            color: {
                type: String as PropType<string>,
            },
        },
        setup(props) {
            const groupName = computed(() => props.name?.slice(0, props.size === 'extra-small' ? 1 : 3));
            const colorDefault = computed(() => props.color || 'rgba(130, 150, 166, 0.2)');
            const iconSize = computed(() => {
                if (typeof props.size !== 'number') {
                    return;
                }
                return {
                    width: `${props.size}px`,
                    height: `${props.size}px`,
                    flexBasis: `${props.size}px`,
                };
            });
            const sizeClass = computed(() => {
                if (typeof props.size === 'number') {
                    return props.size > 100 ? 'large' : 'small';
                } else {
                    return props.size;
                }
            });
            return { groupName, colorDefault, iconSize, sizeClass };
        },
    });
</script>

<style lang="less" scoped>
    .group-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: @info-color-40;
        .text-uppercase();

        &.extra-small {
            flex-basis: 24px;
            width: 24px;
            height: 24px;
            padding-top: @spacing-1;
            border-radius: @border-radius-6;
            .label-uppercase();
            .font-extrabold();
        }

        &.small {
            flex-basis: 40px;
            width: 40px;
            height: 40px;
            padding-top: @spacing-2;
            border-radius: @border-radius-12;
            .label-uppercase();
            .font-extrabold();
        }

        &.large {
            flex-basis: 144px;
            width: 144px;
            height: 144px;
            border-radius: @border-radius-12;
            .h2-display();
            .text-uppercase();
        }
    }
</style>
