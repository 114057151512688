import { inject, injectable } from 'tsyringe';
import type VueRouter from 'vue-router';
import type { Location } from 'vue-router';

import { APP_SCHEME } from '@/common/constants/app-scheme';
import { makeLogger } from '@/common/services';
import { convertUrlToLocation } from '@/common/services/convert-url-to-location';
import { PlatformResolutionService } from '@/common/services/platform-resolution';
import { ServiceTokens } from '@/di/tokens';

import { getLoginSsoErrorStatus, showErrorMessageIfSSOErrorDetected } from '../services/login-sso-handler';

const log = makeLogger('sso');

function getDomainFromUrl(url: string) {
    try {
        return new URL(url).hostname;
    } catch {
        return null;
    }
}

/**
 * Since URL() constructor doesn't support custom schemes well we have to put in something more suitable.
 */
function prepareUrl(url: string) {
    return url.replace(APP_SCHEME, 'https://');
}

type CallbackRouteHandler = (url: string, location: Location, router: VueRouter) => void;

const handleSSOError: CallbackRouteHandler = (_url, location) => {
    const statusCode = getLoginSsoErrorStatus(location.query);
    return showErrorMessageIfSSOErrorDetected(statusCode);
};

const handleSSOSuccess: CallbackRouteHandler = (url, location, router) => {
    const openUrlDomain = getDomainFromUrl(url);
    const platformResolutionService = new PlatformResolutionService();

    if (location && openUrlDomain !== null) {
        log('going to set platform and push location', openUrlDomain, location);
        platformResolutionService.forceSetPlatformDomain(openUrlDomain);
        router.push(location);
    }
};

const HandlerMap = {
    '/login': handleSSOError,
    '/instant-login': handleSSOSuccess,
} as const;

type HandlerPath = keyof typeof HandlerMap;

function isHandlerRegisteredFor(path?: string): path is HandlerPath {
    return path !== undefined && path in HandlerMap;
}

@injectable()
export class HandleDeepLinkUseCase {
    public constructor(
        @inject(ServiceTokens.VueRouter)
        private readonly router: VueRouter
    ) {}

    private pickHandler(location: Location) {
        const path = location?.path;
        if (isHandlerRegisteredFor(path)) {
            return HandlerMap[path];
        }
        throw new Error('No corresponding handler provided for given deep link url');
    }

    public async execute(callbackUrl: string) {
        const url = prepareUrl(callbackUrl);
        const location = convertUrlToLocation(url);

        if (!location) {
            throw new Error('Invalid deep link URL provided');
        }

        /** Drop origin since we don't need it anymore */
        if (location?.query) {
            delete location.query.origin;
        }

        const handler = this.pickHandler(location);
        log('found the handler for deep link URL', handler);

        return handler(url, location, this.router);
    }
}
