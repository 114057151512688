import { render, staticRenderFns } from "./ModalWindowContent.vue?vue&type=template&id=6fa15bb0&scoped=true&"
import script from "./ModalWindowContent.vue?vue&type=script&lang=ts&"
export * from "./ModalWindowContent.vue?vue&type=script&lang=ts&"
import style0 from "./ModalWindowContent.vue?vue&type=style&index=0&id=6fa15bb0&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fa15bb0",
  null
  
)

export default component.exports