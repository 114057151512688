/* eslint-disable unicorn/filename-case */
import '@eloomi/icons/actions/actions-log-out';
import '@eloomi/icons/actions/actions-platform-switch';
import '@eloomi/icons/actions/actions-user';
import '@eloomi/icons/actions/actions-users';
import '@eloomi/icons/actions/actions-people-performance';
import '@eloomi/icons/products-pages/products-and-pages-course';
import '@eloomi/icons/products-pages/products-and-pages-billing';
import '@eloomi/icons/products-pages/products-and-pages-branding';
import '@eloomi/icons/products-pages/products-and-pages-course-builder-chapter';
import '@eloomi/icons/products-pages/products-and-pages-segments';
import '@eloomi/icons/products-pages/products-and-pages-settings';
import '@eloomi/icons/products-pages/products-and-pages-topic';
import '@eloomi/icons/miscellaneous/miscellaneous-access-token';
import '@eloomi/icons/statistics-hierarchy/statistics-and-hierarchy-integration';
import '@eloomi/icons/statistics-hierarchy/statistics-and-hierarchy-organizational-structure';
import '@eloomi/icons/products-pages/products-and-pages-skills-regular';
import '@eloomi/icons/products-pages/products-and-pages-playlist';

import { computed, ComputedRef } from 'vue';

import { useSidebarNavigation } from '@/admin/composables/use-sidebar-navigation';
import { LogoutUseCase } from '@/authentication/use-cases';
import { useLaunchDarkly, useMobile, useTranslation } from '@/common/composables';
import { TopNavInterface, TopNavItemInterface, TopNavUserInterface } from '@/common/interfaces';
import { useUserProfileDetailsStore } from '@/common/store/user-details/store';
import { useDependencyContainer } from '@/di/use-injector';
import { StopImpersonationSessionUseCase } from '@/impersonation/use-cases/stop-impersonation-session';
import { InstructorRouteNames } from '@/instructors/routes';
import { getSessionContainer } from '@/main/get-session-store';
import { ManagerRouteName } from '@/managers/routes';
import { Permissions } from '@/permissions';
import { usePermissions } from '@/permissions/composables/use-permissions';
import { PersonalSpaceRouteName } from '@/personal-space/personal-space.routes';
import PlanFeaturesList from '@/plan-features/plan-features-list';
import { usePlanFeaturesStore } from '@/plan-features/store';
import { useSkillSetupStore } from '@/profile-settings/components/self-skills-setup/SkillSetupStore';
import { Names, Names as ReportingRouteName } from '@/reporting/routes';

function useUserNavigation(): ComputedRef<TopNavUserInterface> {
    const { trans } = useTranslation();
    const userProfileDetailsStore = useUserProfileDetailsStore();
    const authUserProfile = computed(() => userProfileDetailsStore.state.authUserProfile);

    const planFeaturesStore = usePlanFeaturesStore();
    const featuresList = planFeaturesStore.state.featuresList;
    const checkIfFeatureEnabled = planFeaturesStore.getters.checkIfFeatureEnabled;

    const container = useDependencyContainer();
    const logoutUseCase = container.resolve(LogoutUseCase);
    const stopImpersonationSession = container.resolve(StopImpersonationSessionUseCase);
    const sessionContainer = getSessionContainer();

    function handleSignOutClick() {
        logoutUseCase.logOut().catch(console.error);
    }

    function handleSwitchBackClick() {
        stopImpersonationSession.execute().catch(console.error);
    }

    const skillSetupStore = useSkillSetupStore();

    function showSkillSetupModal() {
        skillSetupStore.mutations.showSkillSetupModal();
    }

    return computed<TopNavUserInterface>(() => {
        const isImpersonating = sessionContainer.isImpersonating();

        return {
            first_name: authUserProfile.value?.firstName,
            last_name: authUserProfile.value?.lastName,
            image_url: authUserProfile.value?.imageUrl,
            subnav: {
                ...(isImpersonating
                    ? {
                          switch_back: {
                              access: true,
                              string: 'switch_back',
                              name: trans('spa.navigation.sections.user.switch-back'),
                              route: null,
                              icon: 'elm-actions-platform-switch-icon',
                              onClick: handleSwitchBackClick,
                          },
                      }
                    : {}),
                skills: {
                    access: checkIfFeatureEnabled(featuresList.SKILLS),
                    string: 'my_skills',
                    name: trans('spa.navigation.sections.user.my-skills'),
                    icon: 'elm-products-and-pages-skills-regular-icon',
                    route: null,
                    onClick: showSkillSetupModal,
                },
                settings: {
                    access: true,
                    string: 'spa_settings',
                    name: trans('spa.navigation.sections.user.settings'),
                    route: '/profile-settings',
                    icon: 'elm-products-and-pages-settings-icon',
                    vueRoute: true,
                },
                ...(isImpersonating
                    ? {}
                    : {
                          sign_out: {
                              access: true,
                              string: 'sign_out',
                              name: trans('spa.navigation.sections.user.sign-out'),
                              route: null,
                              icon: 'elm-actions-log-out-icon',
                              onClick: handleSignOutClick,
                          },
                      }),
            },
        };
    });
}

function useAdminNavigationItem(): ComputedRef<TopNavItemInterface> {
    const { trans } = useTranslation();
    const { isMobile } = useMobile();

    const { hasPermission, hasAnyPermission, hasEveryPermission } = usePermissions();

    const { administrationContent, adminSettingsItem } = useSidebarNavigation();
    const { checkIfLaunchDarklyFeatureEnabled } = useLaunchDarkly();

    const planFeaturesStore = usePlanFeaturesStore();
    const featuresList = planFeaturesStore.state.featuresList;
    const checkIfFeatureEnabled = planFeaturesStore.getters.checkIfFeatureEnabled;

    const canAccessLearningRoutes = computed(() => {
        return (
            (checkIfFeatureEnabled(featuresList.COURSES) ||
                checkIfFeatureEnabled(featuresList.EVENTS) ||
                checkIfFeatureEnabled(featuresList.PLAYLISTS)) &&
            hasAnyPermission(Permissions.AdminCourses, Permissions.AdminEvents, Permissions.AdminPlaylists)
        );
    });

    const hasAnyAdminMenuItem = computed(() => {
        return (
            hasAnyPermission(
                Permissions.AdminUsers,
                Permissions.AdminGroups,
                Permissions.AdminSkills,
                Permissions.AdminCategoriesTopics,
                Permissions.AdminBilling,
                Permissions.AdminBranding,
                Permissions.AdminApi
            ) || canAccessLearningRoutes.value
        );
    });

    function getSidebarRoutesBasedOnParentName(
        name: 'learning' | 'organization' | 'skills'
    ): string | { name: string } {
        const sidebarItem = administrationContent.value.find(item => item.name === name);

        if (sidebarItem?.children) {
            const defaultRoute = sidebarItem.children.find(child => isPageAccessible(child)) || null;

            if (defaultRoute?.location) {
                return { name: defaultRoute.location };
            }
        }

        return '/';
    }

    const firstAvailableSidebarRouteForSettings = computed(() => {
        const children = adminSettingsItem.value.children || [];

        const possibleRoutes = children.filter(
            item =>
                (item.featureName ? checkIfFeatureEnabled(item.featureName) : true) &&
                (item.launchDarklyFeatureName ? checkIfLaunchDarklyFeatureEnabled(item.launchDarklyFeatureName) : true)
        );

        if (possibleRoutes.length > 0) {
            return `/admin/settings/${possibleRoutes[0].location}`;
        }

        return '/';
    });

    function isPageAccessible({
        featureName,
        access,
    }: {
        access?: boolean;
        featureName?: PlanFeaturesList | PlanFeaturesList[];
    }) {
        return (!featureName || (featureName && checkIfFeatureEnabled(featureName))) && (access ?? true);
    }

    const integrationRoute = computed(() => {
        if (checkIfFeatureEnabled(featuresList.NATIVE_INTEGRATION)) {
            return '/admin/native-integrations';
        }

        if (checkIfFeatureEnabled(featuresList.API)) {
            return '/admin/api-integrations';
        }

        return '/admin/single-sign-on';
    });

    return computed(() => {
        const subnav = {
            organization: {
                access: hasAnyPermission(Permissions.AdminUsers, Permissions.AdminGroups),
                name: trans('spa.navigation.sections.admin.organization'),
                route: getSidebarRoutesBasedOnParentName('organization'),
                icon: 'elm-actions-users-icon',
                vueRoute: true,
            },
            learning: {
                access: canAccessLearningRoutes.value,
                name: trans('spa.navigation.sections.admin.learning'),
                route: getSidebarRoutesBasedOnParentName('learning'),
                icon: 'elm-products-and-pages-course-builder-chapter-icon',
                vueRoute: true,
            },
            skills: {
                access: checkIfFeatureEnabled(featuresList.SKILLS) && hasPermission(Permissions.AdminSkills),
                name: trans('spa.navigation.sections.admin.skills'),
                route: getSidebarRoutesBasedOnParentName('skills'),
                icon: 'elm-products-and-pages-skills-regular-icon',
                vueRoute: true,
            },
            categoriesAndTopics: {
                access:
                    checkIfFeatureEnabled(featuresList.CATEGORIES_TOPICS) &&
                    hasPermission(Permissions.AdminCategoriesTopics),
                name: trans('spa.navigation.sections.admin.categories-and-topics'),
                route: '/admin/topics',
                icon: 'elm-products-and-pages-topic-icon',
                vueRoute: true,
            },
            billing: {
                access: checkIfFeatureEnabled(featuresList.BILLING) && hasPermission(Permissions.AdminBilling),
                name: trans('spa.navigation.sections.admin.billing'),
                route: '/admin/billing',
                icon: 'elm-products-and-pages-billing-icon',
                vueRoute: true,
            },
            integrations: {
                access: hasPermission(Permissions.AdminApi),
                name: trans('spa.navigation.sections.admin.integrations'),
                route: integrationRoute.value,
                icon: 'elm-statistics-and-hierarchy-integration-icon',
                vueRoute: true,
            },
            settings: {
                access:
                    checkIfFeatureEnabled(featuresList.CUSTOM_FIELDS) &&
                    hasEveryPermission(Permissions.AdminApi, Permissions.AdminBranding),
                name: trans('spa.navigation.sections.admin.settings'),
                route: firstAvailableSidebarRouteForSettings.value,
                icon: 'elm-products-and-pages-settings-icon',
                vueRoute: true,
            },
        };

        const route = Object.values(subnav).find(i => i.access)?.route ?? '/';

        return {
            access: hasAnyAdminMenuItem.value && !isMobile.value,
            name: trans('spa.navigation.sections.main.admin'),
            route,
            vueRoute: true,
            subnav,
        };
    });
}

export function useTopNavigation(): ComputedRef<TopNavInterface> {
    const { trans } = useTranslation();
    const { checkIfLaunchDarklyFeatureEnabled, launchDarklyFeaturesList } = useLaunchDarkly();

    const planFeaturesStore = usePlanFeaturesStore();
    const { hasPermission, canAccessGroupReport, canAccessInstructorPage, canAccessManagerGroupsPage } =
        usePermissions();
    const { isMobile } = useMobile();

    const userNavigation = useUserNavigation();
    const adminNavigationItem = useAdminNavigationItem();

    const featuresList = planFeaturesStore.state.featuresList;

    const checkIfFeatureEnabled = planFeaturesStore.getters.checkIfFeatureEnabled;

    const isPersonalSpaceFeatureEnabled = computed(() =>
        checkIfLaunchDarklyFeatureEnabled(launchDarklyFeaturesList.PERSONAL_SPACE)
    );

    const isGroupReportAvailableInReportsSection = computed(
        () =>
            checkIfFeatureEnabled(featuresList.USER_PERMISSIONS) &&
            canAccessGroupReport.value &&
            !checkIfLaunchDarklyFeatureEnabled(launchDarklyFeaturesList.MANAGERS)
    );

    const rootAdminReportRoute = computed(() => {
        if (isMobile.value) {
            return { name: ReportingRouteName.MOBILE_ROOT };
        } else if (isPersonalSpaceFeatureEnabled.value) {
            return { name: Names.COURSES_REPORT };
        } else {
            return { name: ReportingRouteName.MY_ADMIN_REPORT };
        }
    });

    const adminUserReportsSubnav = computed<Record<string, TopNavItemInterface>>(() => ({
        myLearning: {
            access: !isPersonalSpaceFeatureEnabled.value,
            name: trans('spa.navigation.sections.reports.my-learning'),
            route: '/admin/report',
            icon: 'elm-actions-user-icon',
            vueRoute: true,
        },
        events: {
            access: checkIfFeatureEnabled(featuresList.EVENTS) && hasPermission(Permissions.Reporting),
            name: trans('spa.navigation.sections.reports.events'),
            route: '/admin/report/events',
            icon: 'elm-date-time-calendar-icon',
            vueRoute: true,
        },
        courses: {
            access: checkIfFeatureEnabled(featuresList.COURSES) && hasPermission(Permissions.Reporting),
            name: trans('spa.navigation.sections.reports.courses'),
            route: '/admin/report/courses',
            icon: 'elm-products-and-pages-course-icon',
            vueRoute: true,
        },
        users: {
            access: hasPermission(Permissions.Reporting),
            name: trans('spa.navigation.sections.reports.users'),
            route: '/admin/report/users',
            icon: 'elm-actions-users-icon',
            vueRoute: true,
        },
        groups: {
            access:
                checkIfFeatureEnabled(featuresList.USER_PERMISSIONS) &&
                checkIfFeatureEnabled(featuresList.GROUPS) &&
                canAccessGroupReport.value,
            name: trans('spa.navigation.sections.reports.groups'),
            route: '/admin/report/group',
            icon: 'elm-statistics-and-hierarchy-organizational-structure-icon',
            vueRoute: true,
        },
        segmentReporting: {
            access: checkIfFeatureEnabled(featuresList.SEGMENT_REPORTING) && hasPermission(Permissions.Reporting),
            name: trans('spa.navigation.sections.reports.segments'),
            route: '/admin/report/segment-overview',
            icon: 'elm-products-and-pages-segments-icon',
            vueRoute: true,
        },
        skillsReport: {
            access: checkIfFeatureEnabled(featuresList.SKILLS) && hasPermission(Permissions.Reporting),
            name: trans('spa.navigation.sections.reports.skills'),
            route: '/admin/report/skills',
            icon: 'elm-products-and-pages-skills-regular-icon',
            vueRoute: true,
        },
        playlistsReport: {
            access: checkIfFeatureEnabled(featuresList.PLAYLISTS) && hasPermission(Permissions.Reporting),
            name: trans('spa.navigation.sections.reports.playlists'),
            route: '/admin/report/playlists',
            icon: 'elm-products-and-pages-playlist-icon',
            vueRoute: true,
        },
    }));

    const routes = computed<TopNavInterface>(() => ({
        logo: {
            route: '/courses',
        },
        main: {
            home: {
                access: true,
                name: trans('spa.navigation.sections.main.home'),
                route: '/courses',
                vueRoute: true,
            },
            learn: {
                access: true,
                name: trans('spa.navigation.sections.main.learn'),
                route: '/courses/overview',
                vueRoute: true,
            },
            userReports: {
                access:
                    checkIfFeatureEnabled(featuresList.REPORTING) &&
                    !hasPermission(Permissions.Reporting) &&
                    (isGroupReportAvailableInReportsSection.value || !isPersonalSpaceFeatureEnabled.value),
                name: trans('spa.navigation.sections.main.reports'),
                route: isPersonalSpaceFeatureEnabled.value
                    ? { name: ReportingRouteName.MY_GROUP_REPORT }
                    : { name: ReportingRouteName.MY_REPORT },
                vueRoute: true,
                subnav:
                    checkIfFeatureEnabled(featuresList.USER_PERMISSIONS) && canAccessGroupReport.value
                        ? {
                              myLearning: {
                                  access: !isPersonalSpaceFeatureEnabled.value,
                                  name: trans('spa.navigation.sections.reports.my-learning'),
                                  route: '/report',
                                  icon: 'elm-actions-user-icon',
                                  vueRoute: true,
                              },
                              groups: {
                                  access: isGroupReportAvailableInReportsSection.value,
                                  name: trans('spa.navigation.sections.reports.groups'),
                                  route: '/report/group',
                                  icon: 'elm-statistics-and-hierarchy-organizational-structure-icon',
                                  vueRoute: true,
                              },
                          }
                        : undefined,
            },
            adminUserReports: {
                access: checkIfFeatureEnabled(featuresList.REPORTING) && hasPermission(Permissions.Reporting),
                name: trans('spa.navigation.sections.main.reports'),
                route: rootAdminReportRoute.value,
                vueRoute: true,
                subnav: isMobile.value ? undefined : adminUserReportsSubnav.value,
            },
            instructorPage: {
                access: checkIfFeatureEnabled(featuresList.EVENTS) && canAccessInstructorPage.value,
                name: trans('spa.navigation.sections.main.instructor'),
                route: { name: InstructorRouteNames.InstructorRoot },
                vueRoute: true,
            },
            managerPage: {
                access:
                    checkIfFeatureEnabled(featuresList.MANAGERS) &&
                    checkIfLaunchDarklyFeatureEnabled(launchDarklyFeaturesList.MANAGERS) &&
                    canAccessManagerGroupsPage.value,
                name: trans('spa.navigation.sections.main.manager'),
                route: checkIfLaunchDarklyFeatureEnabled(launchDarklyFeaturesList.MANAGER_IMPROVED_NAVIGATION)
                    ? { name: ManagerRouteName.ManagerSpace }
                    : { name: ManagerRouteName.ManagerGroups },
                vueRoute: true,
            },
            yourPage: {
                access: isPersonalSpaceFeatureEnabled.value,
                name: trans('spa.navigation.sections.main.for-you'),
                route: { name: PersonalSpaceRouteName.Conversations },
                vueRoute: true,
            },
            admin: adminNavigationItem.value,
        },
        user: userNavigation.value,
    }));

    return routes;
}
