import { Getters } from 'vuex-smart-module';

import { NotificationItem } from '../notification.interface';

import { ToastNotificatonState } from './state';

export class ToastNotificationGetters extends Getters<ToastNotificatonState> {
    public get notifications(): Array<NotificationItem> {
        return this.state.notifications;
    }
}
