import { inject, injectable } from 'tsyringe';

import { AuthenticationServiceTokens as Tokens } from '../di-tokens';
import { InvalidSessionFound } from '../services/errors';
import { JWT } from '../services/interfaces';
import { AuthenticationJWTSession } from './authentication-session';
import type { AuthenticationSession, AuthenticationSessionFabric, JwtApiClient } from './interfaces';
import { SessionRefresherJWT } from './session-refresher';
import { SessionJWTStore } from './store';

export const SessionSettings = Object.freeze({
    regular: {
        storageKey: 'INFINITE_JWT',
        refreshPeriod: 60, // in minutes
    },

    impersonation: {
        storageKey: 'INFINITE_IMPERSON_JWT',
        refreshPeriod: 5, // in minutes
    },
} as const);

@injectable()
export class AuthenticationJWTSessionFabric implements AuthenticationSessionFabric<JWT> {
    public constructor(
        @inject(Tokens.SessionApiClient)
        private readonly autoApiClient: JwtApiClient,

        @inject(Tokens.RegularSessionApiClient)
        private readonly regularApiClient: JwtApiClient
    ) {}

    public createSession(
        type: 'regular' | 'impersonation',
        data: JWT,
        failHandler: (error?: unknown) => void
    ): AuthenticationSession<JWT> {
        const settings = SessionSettings[type];

        const apiClient = type === 'regular' ? this.regularApiClient : this.autoApiClient;

        const sessionStore = new SessionJWTStore(settings.storageKey, data);
        const sessionRefresher = new SessionRefresherJWT(apiClient, sessionStore, settings.refreshPeriod * 60 * 1000);
        sessionRefresher.setFailHandler(failHandler);

        return new AuthenticationJWTSession(sessionStore, sessionRefresher);
    }

    public restoreSession(
        type: 'regular' | 'impersonation',
        failHandler: (error?: unknown) => void
    ): AuthenticationSession<JWT> {
        const settings = SessionSettings[type];
        const apiClient = type === 'regular' ? this.regularApiClient : this.autoApiClient;
        const sessionStore = new SessionJWTStore(settings.storageKey);

        // This will throw an error if no session is found in local storage
        sessionStore.read();

        const sessionRefresher = new SessionRefresherJWT(apiClient, sessionStore, settings.refreshPeriod * 60 * 1000);
        sessionRefresher.setFailHandler(failHandler);
        const session = new AuthenticationJWTSession(sessionStore, sessionRefresher);

        if (!session.isActual()) {
            throw new InvalidSessionFound();
        }

        return session;
    }
}
