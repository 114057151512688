import {
    FailReasonCode,
    LoginRequest,
    PlatformLoginApiFactory,
    TemporaryPasswordResetRequest,
    UserForgotPasswordApiFactory,
} from '@eloomi/eloomi-users-external-client/1.0';
import axios from 'axios';
import { inject, injectable } from 'tsyringe';

import { initClientWithoutAuth } from '@/api/authorize-client';
import { ServiceTokens } from '@/di/tokens';

import { InvalidCredentialsError, PasswordChangeRequiredError, TooManyLoginAttemptsError } from './errors';
import { JWT, PlatformResolver } from './interfaces';

const PlatformLoginApiClient = initClientWithoutAuth(PlatformLoginApiFactory);
const UserForgotPasswordApiClient = initClientWithoutAuth(UserForgotPasswordApiFactory);

@injectable()
export class JWTAuthenticationService {
    constructor(@inject(ServiceTokens.PlatformResolutionService) private platformResolver: PlatformResolver) {}

    public async logIn(account_id: string, password: string) {
        const request: LoginRequest = { account_id, password };
        const { id } = await this.platformResolver.getPlatformData(true);
        try {
            const response = await PlatformLoginApiClient.doLogin(id, request);
            const token = response.data as JWT;
            return {
                data: { token },
            };
        } catch (error) {
            if (axios.isAxiosError(error)) {
                switch (error.response?.status) {
                    case 401: {
                        throw new InvalidCredentialsError(error.response.data as FailReasonCode);
                    }
                    case 412: {
                        throw new PasswordChangeRequiredError();
                    }
                    case 429: {
                        throw new TooManyLoginAttemptsError();
                    }
                    // No default
                }
            }
            throw error;
        }
    }

    public async resetTemporaryPassword(accountId: string, oldPassword: string, password: string) {
        const request: TemporaryPasswordResetRequest = {
            old_password: oldPassword,
            new_password: password,
            account_id: accountId,
        };
        const { id } = await this.platformResolver.getPlatformData(true);
        try {
            const response = await UserForgotPasswordApiClient.resetTemporaryPassword(id, request);
            return {
                data: { token: response.data.token as JWT },
            };
        } catch (error) {
            if (axios.isAxiosError(error)) {
                switch (error.response?.status) {
                    case 401: {
                        throw new InvalidCredentialsError();
                    }
                    case 429: {
                        throw new TooManyLoginAttemptsError();
                    }
                    // No default
                }
            }
            throw error;
        }
    }
}
