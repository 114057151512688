<template>
    <div class="notification" :class="isRead && 'notification--read'" @click="emitClickEvent">
        <div class="notification__is-read-indicator"></div>
        <div class="notification__image">
            <slot name="image">
                <CorePicture
                    class="image"
                    :src="imageUrl"
                    :size="[40, 40]"
                    borderRadius="small"
                    placeholderSize="small"
                />
            </slot>
        </div>
        <slot>
            <p class="notification__text">{{ text }}</p>
        </slot>
        <p v-if="receivedTimeFormated" class="notification__received-at">{{ receivedTimeFormated }}</p>
    </div>
</template>

<script lang="ts">
    import CorePicture from '@/common/components/elements/media-objects/CorePicture.vue';
    import { Component, Prop, Vue } from 'vue-property-decorator';

    @Component({ components: { CorePicture } })
    export default class Notification extends Vue {
        @Prop({ type: String }) imageUrl?: string;
        @Prop({ type: String }) text?: string;
        @Prop({ default: false, type: Boolean }) isRead!: boolean;
        @Prop({ type: [String, Number, Date] }) receivedAt?: number | string;

        get receivedTimeFormated() {
            if (!this.receivedAt) {
                return undefined;
            }
            const start = typeof this.receivedAt === 'number' ? this.receivedAt : new Date(this.receivedAt).getTime();
            const end = new Date(Date.now()).getTime();

            const diff = end - start;
            const years = Math.floor(diff / (1000 * 60 * 60 * 24 * 30.4 * 12));
            if (years > 0) return `${years} y`;
            const months = Math.floor(diff / (1000 * 60 * 60 * 24 * 30.4));
            if (months > 0) return `${months} m`;
            const weeks = Math.floor(diff / (1000 * 60 * 60 * 24 * 7));
            if (weeks > 0) return `${weeks} w`;
            const days = Math.floor(diff / (1000 * 60 * 60 * 24));
            if (days > 0) return `${days} d`;
            const hours = Math.floor(diff / (1000 * 60 * 60));
            if (hours > 0) return `${hours} h`;
            const minutes = Math.floor(diff / (1000 * 60));
            if (minutes > 0) return `${minutes} m`;
            const seconds = Math.floor(diff / 1000);
            if (seconds > 0) return `${seconds} s`;
            return undefined;
        }

        emitClickEvent() {
            this.$emit('click');
        }
    }
</script>

<style lang="less" scoped>
    .notification {
        @root: notification;

        display: flex;
        align-items: center;
        width: 100%;
        min-height: 80px;
        padding-right: @spacing-12;
        padding-left: @spacing-16;
        background-color: @bright-color;
        border-radius: @border-radius-8;
        .generic-transition(background-color);

        &__is-read-indicator {
            flex-shrink: 0;
            width: @spacing-12;
            height: @spacing-12;
            margin-right: @spacing-16;
            background-color: @primary-color;
            border-radius: @border-round;
        }

        &__image {
            flex-shrink: 0;
            width: 40px;
            height: 40px;
            margin-right: @spacing-16;
            border-radius: @spacing-6;
            .flex-center-content;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: @spacing-6;
            }
        }

        &__text {
            .text-line;
            .dark-color;
            .generic-transition(color);

            display: -webkit-box;
            margin: 0;
            margin-right: @spacing-8;
            overflow: hidden;
            overflow-wrap: break-word;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        &__received-at {
            flex-shrink: 0;
            width: 40px;
            margin: 0;
            margin-left: auto;
            text-align: center;
            .text-label();
        }

        &--read {
            .@{root}__is-read-indicator {
                background-color: @transparent;
            }
            .@{root}__text {
                .info-color;
            }
        }

        &:hover {
            background-color: @primary-color-8;
            cursor: pointer;
            .@{root}__text {
                color: @primary-color;
            }
        }
    }
</style>
