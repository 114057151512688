<template>
    <div class="basic-dropdown-container" :class="{ 'overlap-content': overlapContent }">
        <slot />
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    @Component
    export default class BasicDropdownContainer extends Vue {
        @Prop({ default: false, type: Boolean }) public overlapContent!: boolean;

        public mounted() {
            this.$emit('mounted');
        }
    }
</script>

<style lang="less" scoped>
    .basic-dropdown-container {
        &,
        &::v-deep * {
            .styled-scrollbar(4px);
        }

        flex-grow: 1;
        min-width: 140px;
        max-height: 450px;
        padding: @spacing-8;
        overflow-y: auto;
        background: @bright-color;
        border: 1px solid @info-color-20;
        border-radius: @border-radius-8;
        box-shadow: 0 2px 40px 0 @dark-color-8;

        &.overlap-content {
            display: grid;
            padding: 0;

            & ::v-deep > * {
                grid-row: 1;
                grid-column: 1;
                min-width: 0;
                padding: @spacing-8;
            }
        }
    }
</style>
