import type VueRouter from 'vue-router';
import { TapHandler } from '@/notifications/services/push-notifications';
import { convertUrlToLocation } from '@/common/services/convert-url-to-location';

export function getTapHandler(router: VueRouter): TapHandler {
    return function handleTap(event) {
        if (!event.action_url) return;

        const location = convertUrlToLocation(event.action_url);

        if (location) {
            router.push(location);
        }
    };
}
