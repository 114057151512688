import { createComposable, createMapper, Module } from 'vuex-smart-module';

import { UserCoursesActions } from './actions';
import { UserCoursesGetters } from './getters';
import { UserCoursesMutations } from './mutations';
import { CoursePlayerState } from './state';

const CoursePlayerStore = new Module({
    state: CoursePlayerState,
    getters: UserCoursesGetters,
    mutations: UserCoursesMutations,
    actions: UserCoursesActions,
});

export const CoursePlayerStoreMapper = createMapper(CoursePlayerStore);
export const useCoursePlayerStore = createComposable(CoursePlayerStore);
export default CoursePlayerStore;
