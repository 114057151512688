export enum AppFeaturesList {
    ADMIN_EMAIL_SETTINGS = 'admin-email-settings',
    ADMIN_ROLES_NAVIGATION = 'admin-roles-navigation',
    CERTIFICATIONS = 'certifications',
    CERTIFICATIONS_COURSES = 'certifications-courses',
    CHANGE_API_INTEGRATION_AUTHOR = 'change-api-integration-author',
    CHANGE_COURSE_AUTHOR = 'change-course-author',
    CHANGE_CUSTOM_FIELD_AUTHOR = 'change-custom-field-author',
    CHANGE_EVENT_AUTHOR = 'change-event-author',
    CHANGE_PLAYLIST_AUTHOR = 'change-playlist-author',
    CHARGEBEE_PORTAL = 'chargebee-portal',
    CONVERSATIONS = 'conversations',
    COURSE_BUILDER_REWORK = 'course-builder-rework',
    DUPLICATE_COURSE = 'duplicate-course',
    FILTERS_ON_ADMIN_USERS = 'filters-on-admin-users',
    MANAGERS = 'managers',
    MANAGER_DEADLINE_EMAIL_SETTINGS = 'manager-deadline-email-settings',
    MANAGER_EVENT_ASSIGNMENT = 'manager-event-assignments',
    MANAGER_IMPROVED_NAVIGATION = 'manager-improved-navigation',
    MERGE_INTEGRATION_SUPPORTED_PLATFORMS = 'merge-integration-supported-platforms',
    OPTION_TO_SELECT_ALL_USERS_IN_ADMIN_USERS = 'option-to-select-all-users-in-admin-users',
    PERSONAL_SPACE = 'user-for-you-space',
    QUIZ_FILE_UPLOAD_QUESTION = 'quiz-file-upload-question',
    SSO_SETTINGS_ID_PROVIDERS = 'sso-settings-id-providers',
}
