import { UserGroupManagersApiFactory } from '@eloomi/eloomi-user-groups-persona-client/1.0';
import { authorizeClient } from '@/api/authorize-client';

const userGroupManagersClient = authorizeClient(UserGroupManagersApiFactory);

export function getUserGroupManagers(groupId: number) {
    return userGroupManagersClient.getUserGroupManagers(groupId).then(({ data }) => data);
}

export function addUserGroupManager(groupId: number, userId: number) {
    return userGroupManagersClient.createUserGroupManager(groupId, { user_id: userId }).then(({ data }) => data);
}

export function deleteUserGroupManager(groupId: number, userId: number) {
    return userGroupManagersClient.deleteUserGroupManager(groupId, userId).then(({ data }) => data);
}
