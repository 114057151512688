<template>
    <transition name="fade" v-bind="$attrs" v-on="$listeners">
        <slot />
    </transition>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';

    @Component({})
    export default class FadeTransition extends Vue {}
</script>
<style lang="less" scoped>
    .fade-enter-active,
    .fade-leave-active {
        .generic-transition(opacity);
    }
    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
</style>
