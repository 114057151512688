<template>
    <div class="weekday-selector" :class="classes">
        <div class="weekday-selector-content">
            <div
                v-for="(weekDayIndex, index) in weekDaysIndexes"
                :key="index"
                class="week-day"
                :class="{ 'is-selected': isSelected(weekDayIndex) }"
                @click="toggleSelection(weekDayIndex)"
            >
                {{ weekDaysNamesShort[index] }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { computed, defineComponent, PropType } from 'vue';

    import { useWeekdays } from '@/common/composables/use-weekdays';

    export default defineComponent({
        name: 'WeekdaySelector',
        props: {
            value: {
                type: Array as PropType<number[]>,
                required: true,
            },
        },
        emit: ['input'],
        setup(props, { emit }) {
            const { weekDaysIndexes, weekDaysNamesShort } = useWeekdays();

            const toggleSelection = index => {
                let newValue: number[];
                if (!isSelected(index)) {
                    newValue = [...props.value, index];
                } else if (canDeselect.value) {
                    newValue = props.value.filter(el => el !== index);
                } else {
                    return;
                }
                emit('input', newValue.sort());
            };

            const isSelected = index => {
                return props.value.includes(index);
            };

            const canDeselect = computed(() => props.value.length > 1);

            const classes = computed(() => ({
                'can-deselect': canDeselect.value,
            }));

            return {
                classes,
                weekDaysIndexes,
                weekDaysNamesShort,
                toggleSelection,
                isSelected,
            };
        },
    });
</script>

<style scoped lang="less">
    .weekday-selector {
        padding: @spacing-4;
        background: @info-color-12;
        border-radius: @border-radius-20;

        .weekday-selector-content {
            display: flex;
            gap: @spacing-10;
            padding: @spacing-6;
            background: @bright-color;
            .effects-bright-card;

            border-radius: @border-radius-16;
        }

        .week-day {
            display: flex;
            flex-basis: 0;
            flex-grow: 1;
            align-items: center;
            justify-content: center;
            height: 36px;
            border-radius: @border-radius-18;
            transition: @generic-transition;
            user-select: none;

            .paragraph-14-medium;
            .dark-color-60;

            &.is-selected {
                background: @primary-color-8;
                .primary-color;
            }
        }

        &.can-deselect .week-day,
        &:not(.can-deselect) .week-day:not(.is-selected) {
            cursor: pointer;

            &:hover {
                background: @info-color-8;
            }

            &:active {
                background: @info-color-16;
            }
        }
    }
</style>
