import { RouteConfig } from 'vue-router';

import { checkUserData } from '@/common/services/redirectIf.guard';
import { AppFeaturesList } from '@/launch-darkly/models';
import { checkIfAvailableByLaunchDarkly } from '@/launch-darkly/routes';
import { guards } from '@/main/multipleGuardsHandler';
import PlanFeaturesList from '@/plan-features/plan-features-list';
import { checkIfRouteAvailableByPlanFeatures } from '@/plan-features/routes';

const managerGroups = () => import('@/managers/pages/ManagerGroupsPage.vue');
const managerGroup = () => import('@/managers/pages/ManagerGroupPage.vue');
const ManagerSpace = () => import('@/managers/pages/ManagerSpacePage.vue');
const ManagerSpaceGroupPage = () => import('@/managers/pages/ManagerSpaceGroupPage.vue');
const ManagerSpaceUserPage = () => import('@/managers/pages/ManagerSpaceUserPage.vue');

export enum ManagerRouteName {
    ManagerGroups = 'manager-groups',
    ManagerGroup = 'manager-group',
    ManagerSpace = 'manager-space',
    ManagerSpaceGroup = 'manager-space-group',
    ManagerSpaceUser = 'manager-space-user',
}

const enum Paths {
    Manager = 'manager-groups',
    ManagerSpace = 'manager-space',
}

const checkIfManagerIsAllowedToAccessRoute = () =>
    guards([
        checkIfRouteAvailableByPlanFeatures(PlanFeaturesList.MANAGERS),
        checkUserData(store => store.state.isReportingManager),
    ]);

const routes: RouteConfig[] = [
    {
        path: `${Paths.Manager}`,
        name: ManagerRouteName.ManagerGroups,
        meta: {
            title: 'Manager',
        },
        beforeEnter: checkIfManagerIsAllowedToAccessRoute(),
        component: managerGroups,
    },
    {
        path: `${Paths.Manager}/:group_id(\\d+)`,
        name: ManagerRouteName.ManagerGroup,
        meta: {
            title: 'Manager Group',
        },
        component: managerGroup,
        beforeEnter: checkIfManagerIsAllowedToAccessRoute(),
    },
    {
        path: `${Paths.ManagerSpace}`,
        name: ManagerRouteName.ManagerSpace,
        meta: {
            title: 'Manager Groups',
        },
        children: [
            {
                path: 'group/:group_id(\\d+)/:tab?',
                name: ManagerRouteName.ManagerSpaceGroup,
                component: ManagerSpaceGroupPage,
                meta: {
                    title: 'Manager Group',
                },
            },
            {
                path: 'group/:group_id(\\d+)/user/:user_id(\\d+)/:tab?',
                name: ManagerRouteName.ManagerSpaceUser,
                component: ManagerSpaceUserPage,
                meta: {
                    title: 'Manager User',
                },
            },
        ],
        component: ManagerSpace,
        beforeEnter: guards([
            checkIfManagerIsAllowedToAccessRoute(),
            checkIfAvailableByLaunchDarkly(AppFeaturesList.MANAGER_IMPROVED_NAVIGATION),
        ]),
    },
];

export default routes;
