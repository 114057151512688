/* eslint-disable vue/one-component-per-file */
import { EmsiSkillItem } from '@eloomi/eloomi-learning-persona-client/1.0';
import { SkillOrigin, UserInfoResponse, UserSkillOrigin } from '@eloomi/eloomi-skills-persona-client/1.0';
import { xorBy } from 'lodash-es';
import { Vue } from 'vue-property-decorator';
import { Actions, Context, createComposable, createMapper, Getters, Module, Mutations } from 'vuex-smart-module';

import { PredefinedAccessUserRoles } from '@/admin/users/providers/user-access-roles';
import { usersProvider } from '@/api/providers';
import { UserProfile } from '@/common/interfaces';
import {
    cacheUserDetailsData,
    cacheUserReportingManagerStatus,
    clearUserDetailsDataFromLocalStorage,
    getCacheUserDetailsData,
    getCacheUserReportingManagerStatus,
} from '@/common/services';
import { segment } from '@/common/services/segment';
import { createUIStoreServiceFactory } from '@/di/store-factory';
import { isInstructor } from '@/instructors/providers';

export type AssignableSkills = { ownedSkills: EmsiSkillItem[]; wantedSkills: EmsiSkillItem[] };

let userDetailsPromise: Promise<UserProfile> | null = null;

class State {
    public authUserProfile: UserProfile | null = null;
    public appInsightsPlatformData: {
        userId: number;
        platformId: number;
    } | null = null;
    public ownedSkills: EmsiSkillItem[] | null = null;
    public businessTitleSkills: EmsiSkillItem[] | null = null;
    public wantedSkills: EmsiSkillItem[] | null = null;
    public skillsSetupCompleted: boolean | null = null;
    public isReportingManager = false;
    public isInstructor = false;
}

class StoreGetters extends Getters<State> {
    public userDetails() {
        return this.state.authUserProfile;
    }

    public get userSkills() {
        return [...(this.state.ownedSkills || []), ...(this.state.businessTitleSkills || [])];
    }

    public get isAuthUserAdmin() {
        const roles = [PredefinedAccessUserRoles.ADMIN.toString()];
        const userRole = this.state.authUserProfile?.infiniteRoleKey;
        return userRole ? roles.includes(userRole) : false;
    }
}

class StoreActions extends Actions<State, StoreGetters, StoreMutations, StoreActions> {
    public async loadUserProfile() {
        const cachedUserDetailsData = getCacheUserDetailsData();
        const cachedUserReportingStatusData = getCacheUserReportingManagerStatus();

        if (cachedUserDetailsData !== null && this.state.authUserProfile === null) {
            this.mutations.setUserProfile(cachedUserDetailsData);
        }

        this.mutations.setUserReportingManagerStatus(cachedUserReportingStatusData ?? false);

        if (userDetailsPromise === null) {
            await this.actions.forceReloadUserProfile();
        }
        return userDetailsPromise;
    }

    public async forceReloadUserProfile() {
        userDetailsPromise = usersProvider.getSelfUserProfile();
        this.actions.loadUserReportingManagerStatus();
        this.actions.loadUserInstructorStatus();
        const authUserData: UserProfile | null = await userDetailsPromise;
        if (authUserData) {
            try {
                segment.identify(authUserData);
            } catch (error) {
                console.error(error);
            }

            this.mutations.setUserProfile(authUserData);
            this.mutations.setAppInsightData({
                userId: authUserData.id,
                platformId: authUserData.platformId,
            });

            cacheUserDetailsData(authUserData);
        }
    }

    public clearUserDetails() {
        clearUserDetailsDataFromLocalStorage();
        this.mutations.clearUserDetails();
        userDetailsPromise = null;
    }

    public async loadUserSkills() {
        const skills = await usersProvider.getSelfSkillsInfo();
        this.mutations.setUserSkillsInfo(skills);
    }

    public async loadUserReportingManagerStatus() {
        const isReportingManager = await usersProvider.isReportingManager();
        this.mutations.setUserReportingManagerStatus(isReportingManager);
        cacheUserReportingManagerStatus(isReportingManager);
    }

    public async loadUserInstructorStatus() {
        const userInstructorStatus = await isInstructor();
        this.mutations.setUserInstructorStatus(userInstructorStatus);
    }

    public async saveUserSkills(skills: AssignableSkills) {
        if (
            xorBy(this.state.ownedSkills || [], skills.ownedSkills, 'unique_id').length > 0 ||
            xorBy(this.state.wantedSkills || [], skills.wantedSkills, 'unique_id').length > 0
        ) {
            await usersProvider.setSelfSkillsInfo([
                ...skills.ownedSkills.map(skill => ({ ...skill, origin: SkillOrigin.OwnedSkills })),
                ...skills.wantedSkills.map(skill => ({ ...skill, origin: SkillOrigin.WantedSkills })),
            ]);
            this.mutations.updateUserSkills(skills);
        }

        if (!this.state.skillsSetupCompleted) {
            await usersProvider.completeSkillsSetup();
            this.mutations.setSkillsSetupCompletion(true);
        }
    }
}

class StoreMutations extends Mutations<State> {
    public setUserReportingManagerStatus(isReportingManager: boolean) {
        this.state.isReportingManager = isReportingManager;
    }

    public setUserProfile(data: UserProfile) {
        this.state.authUserProfile = data;
    }

    public setUserInstructorStatus(isInstructor: boolean) {
        this.state.isInstructor = isInstructor;
    }

    public setAppInsightData(data: { userId: number; platformId: number }) {
        this.state.appInsightsPlatformData = data;
    }

    public clearUserDetails() {
        this.state.authUserProfile = null;
        this.state.ownedSkills = null;
        this.state.businessTitleSkills = null;
        this.state.wantedSkills = null;
        this.state.skillsSetupCompleted = null;
        this.state.appInsightsPlatformData = null;
    }

    public setUserSkillsInfo(info: UserInfoResponse) {
        this.state.skillsSetupCompleted = info.setup_completed;
        this.state.ownedSkills = info.skills.filter(skill => skill.origin === UserSkillOrigin.OwnedSkills);
        this.state.businessTitleSkills = info.skills.filter(skill => skill.origin === UserSkillOrigin.BusinessTitle);
        this.state.wantedSkills = info.skills.filter(skill => skill.origin === UserSkillOrigin.WantedSkills);
    }

    public updateUserSkills(skills: AssignableSkills) {
        this.state.ownedSkills = [...skills.ownedSkills];
        this.state.wantedSkills = [...skills.wantedSkills];
    }

    public setSkillsSetupCompletion(isCompleted: boolean) {
        this.state.skillsSetupCompleted = isCompleted;
    }
}

const userStore = new Module({
    state: State,
    getters: StoreGetters,
    actions: StoreActions,
    mutations: StoreMutations,
});

export const useUserProfileDetailsStore = createComposable(userStore) as () => Context<typeof userStore>;

export const userProfileDetailsStoreMapper = createMapper(userStore);

export const UserProfileDetailsStoreMixin = Vue.extend({
    computed: {
        ...userProfileDetailsStoreMapper.mapState([
            'authUserProfile',
            'appInsightsPlatformData',
            'skillsSetupCompleted',
            'isReportingManager',
            'isInstructor',
        ]),
        ...userProfileDetailsStoreMapper.mapGetters(['isAuthUserAdmin']),
    },
    methods: {
        ...userProfileDetailsStoreMapper.mapActions([
            'loadUserProfile',
            'forceReloadUserProfile',
            'clearUserDetails',
            'loadUserSkills',
        ]),
    },
});

export const UserSkillsStoreMixin = Vue.extend({
    computed: {
        ...userProfileDetailsStoreMapper.mapState(['ownedSkills', 'businessTitleSkills', 'wantedSkills']),
        ...userProfileDetailsStoreMapper.mapGetters(['userSkills']),
    },
});

export const SelfSkillsStoreMixin = Vue.extend({
    computed: {
        ...userProfileDetailsStoreMapper.mapState(['ownedSkills', 'businessTitleSkills', 'wantedSkills']),
    },
});

export const UserDetailsUIStoreServiceFactory = createUIStoreServiceFactory({
    ...userProfileDetailsStoreMapper.mapState(['authUserProfile', 'appInsightsPlatformData']),
    ...userProfileDetailsStoreMapper.mapActions(['loadUserProfile', 'forceReloadUserProfile', 'clearUserDetails']),
});

export type UserDetailsStoreService = ReturnType<typeof UserDetailsUIStoreServiceFactory>;

export default userStore;
