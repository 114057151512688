import { TwentyThreeInitUploadResult, TwentyThreeVideoResult } from '@eloomi/eloomi-content-persona-client/1.0';
import Resumable from 'resumablejs';
import { inject, injectable } from 'tsyringe';

import { extractAxiosData } from '@/common/services/extract-axios-data';

import { VideoServiceTokens } from '../../video/di-tokens';
import { TwentyThreeClient } from './interfaces';

@injectable()
export class TwentyThreeVideoService {
    public constructor(
        @inject(VideoServiceTokens.TwentyThreeApiClient)
        private readonly twentyThreeClient: TwentyThreeClient
    ) {}

    public uploadResumable(url: string, token: string, file: File, onProgressChanged: (progressValue) => void) {
        const uploadItem = new Resumable({
            target: url,
            maxChunkRetries: 5,
            chunkRetryInterval: 3000,
            testChunks: false,
            forceChunkSize: false,
            uploadMethod: 'POST',
            method: 'multipart',
            simultaneousUploads: 3,
            query: { upload_token: token },
        });

        uploadItem.on('progress', () => {
            onProgressChanged(Math.round(uploadItem.progress() * 100));
        });

        uploadItem.on('fileAdded', (_file, _event) => {
            uploadItem.upload();
        });

        uploadItem.addFile(file);
    }

    public async uploadTwentyThreeVideo(
        contentId: string,
        file: File,
        onProgressChanged: (progressValue) => void
    ): Promise<TwentyThreeInitUploadResult> {
        const video = await this.twentyThreeClient.initUpload({ content_id: contentId }).then(extractAxiosData);
        const { upload_url, upload_token } = video;

        this.uploadResumable(upload_url as string, upload_token as string, file, onProgressChanged);

        return video;
    }

    public getUploadedTwentyThreeVideo(id: string): Promise<TwentyThreeVideoResult> {
        return this.twentyThreeClient.getVideo(id).then(extractAxiosData);
    }
}
