import { AxiosResponse } from 'axios';

import { FileResponse } from '@/common/models';

export function extractAxiosData<T>(response: AxiosResponse<T>): T {
    return response.data;
}

export function extractAxiosDataAsRequired<T>(response: AxiosResponse<T>): Required<T> {
    return response.data as Required<T>;
}

export function extractFileResponse(response: AxiosResponse) {
    return new FileResponse(response.data as Blob, response.headers['content-disposition']);
}
