import { App as CapacitorApp } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import VueRouter, { Route } from 'vue-router';

import { AuthenticationServiceTokens } from '@/authentication/di-tokens';
import { AuthenticationRouteNames } from '@/authentication/route-definitions';
import { SessionContainer } from '@/authentication/session/session-container';
import { CoursesRouteNames } from '@/courses/route-definitions';
import { baseContainer } from '@/di/container';

import { MOBILE_UNSUPPORTED_FLAG_NAME } from './mobile-is-unsupported-flag';

export const FALLBACK_ROUTE_NAME = 'mobile-fallback' as const;
export const COMFORTABLE_VIEWPORT_WIDTH = 1224;

export function isPossiblyMobileDeviceWithSmallScreen(viewportWidth = COMFORTABLE_VIEWPORT_WIDTH) {
    const isMobiInUserAgent = /mobi/i.test(window.navigator.userAgent);

    return isMobiInUserAgent && window.innerWidth <= viewportWidth;
}

function isMobileUnsupportedOnRoute(route: Route) {
    return route.meta && route.meta[MOBILE_UNSUPPORTED_FLAG_NAME] === true;
}

function replaceLocationInTheAddressBar(url: string) {
    window.history.replaceState(null, '', url);
}

function getLocationHref(router: VueRouter, url: string) {
    const resolution = router.resolve(url);
    return resolution.resolved.fullPath === url ? resolution.href : undefined;
}

export function attachMobileFallbackGuard(router: VueRouter) {
    router.beforeEach((to, from, next) => {
        if (Capacitor.isNativePlatform()) {
            const sessionContainer = baseContainer.resolve<SessionContainer>(
                AuthenticationServiceTokens.SessionContainer
            );

            if (
                to.name === AuthenticationRouteNames.LOGIN &&
                from.name === CoursesRouteNames.COURSES_DASHBOARD &&
                sessionContainer.isLoggedIn()
            ) {
                CapacitorApp.exitApp();
            }
        }

        if (isMobileUnsupportedOnRoute(to) && isPossiblyMobileDeviceWithSmallScreen(to.meta?.viewportWidth)) {
            return next({ name: FALLBACK_ROUTE_NAME, query: { from: to.fullPath }, replace: true });
        } else {
            return next();
        }
    });

    router.afterEach(to => {
        const isFromArgAvailable = 'from' in to.query && to.query.from.length > 0;

        if (to.name === FALLBACK_ROUTE_NAME && isFromArgAvailable) {
            const fromUrl = to.query.from as string;
            const href = getLocationHref(router, fromUrl);

            if (href === undefined) {
                return;
            }

            replaceLocationInTheAddressBar(href);
        }
    });
}
