<template>
    <transition-group class="notification-area" name="list" tag="div">
        <ToastNotification
            v-for="item in notifications"
            :key="item.id"
            class="notification-area--item"
            :theme="item.theme"
            :message="item.message"
            :isCompact="item.isCompact"
            :style="getNotificationItemStyle(item)"
        />
    </transition-group>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { NotificationItem } from './notification.interface';
    import ToastNotification from './ToastNotification.vue';

    @Component({
        components: { ToastNotification },
    })
    export default class ToastNotificationArea extends Vue {
        @Prop({ default: () => [] }) public notifications!: NotificationItem[];

        public getNotificationItemStyle(item: NotificationItem) {
            return {
                zIndex: item.id,
            };
        }
    }
</script>

<style lang="less" scoped>
    @top-margin: @spacing-48;
    @toast-height: 56px;

    @animation-offset: -@top-margin - @toast-height;
    @animation-duration: 0.5s;

    .notification-area {
        position: fixed;
        z-index: 10;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        height: 100%;
        padding-top: @top-margin;
        overflow: hidden;
        pointer-events: none;

        &--item {
            flex-shrink: 0;
            margin-bottom: @spacing-12;
            transition: all @animation-duration cubic-bezier(0.16, 1, 0.3, 1);
        }
    }

    .list-enter,
    .list-leave-to {
        transform: scale(0.8) translateY(@animation-offset);
        opacity: 0;
    }

    .list-leave-active {
        position: absolute;
    }
</style>
