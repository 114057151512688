export enum ApiAuthTypes {
    key = 'apikey',
    token = 'platform-token',
    hash = 'hash',
}

export type enviromentType = 'staging' | 'production' | 'local';

interface Environment {
    isProduction: boolean;
    publicPath: string;
    apiBaseUrl: string;
    apiKey?: string;
    apiAuthMode: ApiAuthTypes;
    appBaseUrl?: string;
    applicationInsightsKey?: string;
    appName: string;
    fullstoryOrgId?: string;
    googleMapsApiKey?: string;
    type: enviromentType;
    segmentApiKey: string;
    chargebeeSite: string;
    sentryDsn: string | undefined;
    sentryOrganisation: string | undefined;
    sentryRelease: string | undefined;
    stagingRemoteUrl?: string;
    platformDomain?: string;
    sentryTraceSampleRate: number;
    platformHostContext: boolean;
    platformParentDomain: string;
    useIntercom: boolean;
    clientType?: string;
    clientVersion?: string;
    debugNamespaces?: string;
}

const environment: Environment = {
    chargebeeSite: process.env.VUE_APP_CHARGEBEE_SITE ?? 'eloomi-eu',
    clientType: process.env.VUE_APP_CLIENT_TYPE,
    clientVersion: process.env.VUE_APP_CLIENT_VERSION,
    isProduction: process.env.NODE_ENV === 'production',
    publicPath: process.env.VUE_APP_PUBLIC_PATH || '/',
    apiBaseUrl: process.env.VUE_APP_API_URL as string,
    apiKey: process.env.VUE_APP_API_KEY,
    apiAuthMode: (process.env.VUE_APP_API_AUTH_TYPE as ApiAuthTypes) || ApiAuthTypes.key,
    appBaseUrl: process.env.VUE_APP_BASE_URL || process.env.STORYBOOK_BASE_URL,
    applicationInsightsKey: (process.env.VUE_APP_INSIGHTS_KEY as string) || undefined,
    appName: 'eloomi-spa-core',
    fullstoryOrgId: process.env.VUE_APP_FULLSTORY_ORG_ID,
    sentryDsn: process.env.VUE_APP_SENTRY_DSN,
    sentryRelease: process.env.VUE_APP_SENTRY_RELEASE,
    googleMapsApiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY || process.env.STORYBOOK_GOOGLE_MAPS_API_KEY,
    type: process.env.VUE_APP_ENVIRONMENT_TYPE || 'local',
    segmentApiKey: process.env.VUE_APP_SEGMENT_API || '',
    stagingRemoteUrl: process.env.VUE_APP_STAGING_URL,
    sentryOrganisation: 'eloomi',
    platformDomain: process.env.VUE_APP_PLATFORM_DOMAIN,
    platformHostContext: String(process.env?.VUE_APP_PLATFORM_HOST_CONTEXT).toLowerCase() !== 'false',
    platformParentDomain: process.env.VUE_APP_PLATFORM_PARENT_DOMAIN ?? '.eloomi.io',
    sentryTraceSampleRate: sentryTraceSampleRate(),
    useIntercom: String(process.env?.VUE_APP_INTERCOM_ENABLED).toLowerCase() !== 'false',
    debugNamespaces: process.env.VUE_APP_DEBUG_NAMESPACES || '',
};

function sentryTraceSampleRate() {
    if (!process.env.VUE_APP_SENTRY_TRACE_SAMPLE_RATE) {
        console.warn('SENTRY_TRACE_SAMPLE_RATE is not set but expected. Defaulting to 0.');
        return 0;
    }

    return Number.parseFloat(process.env.VUE_APP_SENTRY_TRACE_SAMPLE_RATE);
}

export default environment;
