<template>
    <transition appear mode="out-in" name="fade-from-top">
        <div class="menu-items" :style="actionMenuWidth">
            <div class="close-dropdown" @click="closeDropdown">
                <elm-actions-close-bold-icon
                    size="18"
                    :class="triggerColor == 'primary' ? 'primary-color' : 'info-color'"
                />
            </div>
            <h3 v-if="title" class="title">{{ title }}</h3>

            <p v-if="label" class="group-title">
                {{ label }}
            </p>

            <ul class="display-flex flex-column">
                <slot></slot>
            </ul>
        </div>
    </transition>
</template>

<script lang="ts">
    import '@eloomi/icons/actions/actions-close-bold';

    import { Component, Mixins, Prop } from 'vue-property-decorator';

    import { Themes } from '@/common/classes/themes';
    import { MobileModeStoreMixin } from '@/common/store/mobile-mode';

    @Component({})
    export default class CoreActionMenu extends Mixins(MobileModeStoreMixin) {
        @Prop({ required: false }) public title!: string;
        @Prop({ required: false }) public label!: string;
        @Prop({ default: '270px' }) public menuWidth!: string;
        @Prop({ default: 18 }) public iconSize!: number;
        @Prop({ default: '' }) public triggerColor!: Themes;

        public closeDropdown() {
            this.$parent?.$emit('closeDropdown');
        }

        public get actionMenuWidth() {
            if (this.isMobile) {
                return `width: 100%`;
            } else {
                return `width: ${this.menuWidth}`;
            }
        }
    }
</script>

<style lang="less" scoped>
    .menu-items {
        position: relative;
        max-width: 100vw;
        margin: 0;
        padding: @spacing-8;
        background-color: @bright-color;
        border-radius: @border-radius-8;
        .effects-box-shadow;

        .sm-viewport-and-smaller({
            max-height: calc(100% - 70px);
        });

        .close-dropdown {
            display: none;
            color: @bright-color;

            @media screen and (max-width: 768px) {
                position: absolute;
                top: -40px;
                right: 24px;
                display: block;
            }
        }

        .title {
            .paragraph-bold();

            display: none;
            margin: 0;
            padding: @spacing-20 @spacing-24;
            color: @info-color;

            @media screen and (max-width: 768px) {
                display: block;
            }
        }

        .group-title {
            .paragraph-small();

            display: flex;
            align-items: center;
            justify-content: left;
            margin: 0;
            padding-top: @spacing-12;
            padding-bottom: @spacing-4;
            padding-left: @spacing-12;
            color: @info-color;
        }

        ul {
            margin: 0;
            padding: 0;
            .sm-viewport-and-smaller({
                height: 100%;
                overflow: auto;
            });
        }

        @media screen and (max-width: 768px) {
            padding: @spacing-32 0;
            border-radius: @border-radius-16 @border-radius-16 0 0;
        }
    }

    .fade-from-top-enter-active,
    .fade-from-top-leave-active {
        transition: @generic-transition;
    }

    .fade-from-top-enter,
    .fade-from-top-leave-to {
        transform: scaleY(0.9);
        transform-origin: top;
        opacity: 0;
    }
</style>
