import { UserGroupsApiFactory } from '@eloomi/eloomi-user-groups-persona-client/1.0';

import { authorizeClient } from '@/api/authorize-client';
import { FluentFilterCollection, Operator } from '@/common/services';
import { extractAxiosData } from '@/common/services/extract-axios-data';

const userGroupsApiFactory = authorizeClient(UserGroupsApiFactory);

export function getUsersNumber(groupId: number) {
    const filters = new FluentFilterCollection().where('id').filter(Operator.IsEqualTo, groupId);
    return userGroupsApiFactory.getUserGroupUsersNumber(filters.build()).then(extractAxiosData) as Promise<number>;
}
