import { Branding } from '@/common/models';
import { FilterValue } from '@/common/services/IFluentFilterCollection';

export function convertSearchResult(result: string): string {
    return result.replace(/\s+/g, '|');
}

export function searchTextToSearchTerms(text?: string): string[] {
    if (!text) return [];

    return text.split(/\s+/).filter(x => x);
}

export function filterIdsToNumberIds(ids: FilterValue) {
    return typeof ids === 'string' && ids.includes('|') ? ids.split('|').map(Number) : [Number(ids)];
}

export function brandingThemeToCssString(theme: Branding) {
    return `:root {
                --color-branding-text: ${theme.brandingText};
            }`;
}

export function brandingThemeToVariablesObject(theme: Branding): { [key: string]: string } {
    return {
        '--color-branding-text': theme.brandingText || '',
    };
}
