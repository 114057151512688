import { Module, Mutations, createMapper } from 'vuex-smart-module';

class State {
    retryingHttpRequest = false;
    lastError: string | null = null;
}

class StoreMutations extends Mutations<State> {
    public setLastError(lastError: string | null) {
        if (this.state.retryingHttpRequest) return;

        if (this.state.lastError === null || lastError === null) {
            this.state.lastError = lastError;
        }
    }

    public setRetryingHttpState(state: boolean) {
        this.state.retryingHttpRequest = state;
    }
}

const storeModule = new Module({
    state: State,
    mutations: StoreMutations,
});

export const httpStoreMapper = createMapper(storeModule);
export default storeModule;
