import { differenceInHours, differenceInMinutes, differenceInSeconds } from 'date-fns';
import { computed } from 'vue';

import useTranslation from '@/common/composables/use-translation';
import { DateInAnyForm, getDate } from '@/common/services';

export type DateFormattingOptions = {
    /** should add day of week, default: false */
    withDayOfWeek?: false | 'short' | 'long';
    /** should month name be in long format, default: false */
    withLongMonth?: boolean;
    /** should include year, default: true */
    withYear?: boolean;
};

export function useDates() {
    const { currentLanguage } = useTranslation();

    const hourFormat = computed(
        () => Intl.DateTimeFormat(currentLanguage.value, { hour: 'numeric' }).resolvedOptions().hourCycle
    );

    /**
     * get date in "Jan 24, 2022" format
     * or "Monday, Jan 24, 2022" /  "Jan 24" / Mon, January 24, 2022 and other combinations
     */
    const getLocalizedDate = (date?: DateInAnyForm, options?: DateFormattingOptions) => {
        const normalizedDate = getDate(date);
        if (!normalizedDate) {
            return '';
        }
        const withDayOfWeek = options?.withDayOfWeek ?? false;
        const withLongMonth = options?.withLongMonth ?? false;
        const withYear = options?.withYear ?? true;

        const day = normalizedDate.getDate().toString();
        const month = normalizedDate.toLocaleDateString(currentLanguage.value, {
            month: withLongMonth ? 'long' : 'short',
        });
        const parts: string[] = [];

        if (withDayOfWeek) {
            parts.push(normalizedDate.toLocaleDateString(currentLanguage.value, { weekday: withDayOfWeek }));
        }
        parts.push(`${month} ${day}`);
        if (withYear) {
            parts.push(normalizedDate.toLocaleDateString(currentLanguage.value, { year: 'numeric' }));
        }

        return parts.join(', ');
    };

    /**
     * get date and time in  "08:15", "15:33" / "8:15am", "3:30pm" format
     */
    const getLocalizedTime = (date?: DateInAnyForm) => {
        const normalizedDate = getDate(date);
        if (!normalizedDate) {
            return '';
        }

        return normalizedDate
            .toLocaleTimeString(currentLanguage.value, {
                hour: ['h24', 'h23'].includes(hourFormat.value!) ? '2-digit' : 'numeric',
                minute: '2-digit',
                hourCycle: hourFormat.value,
            })
            .replace(/\s/, '');
    };

    /**
     * get date and time in "Monday, Jan 24, 2022 · 15:30" format
     */
    const getLocalizedDateTime = (date?: DateInAnyForm, options?: DateFormattingOptions) => {
        const nativeDate = getDate(date);
        if (!nativeDate) {
            return '';
        }

        return `${getLocalizedDate(nativeDate, options)} · ${getLocalizedTime(nativeDate)}`;
    };

    /**
     * Get date in "07/12/23" format
     */
    const getNumericDate = (date?: DateInAnyForm) => {
        const normalizedDate = getDate(date);
        if (!normalizedDate) {
            return '';
        }

        return normalizedDate.toLocaleDateString(currentLanguage.value, {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
        });
    };

    /**
     * Returns date in "June 2021" format
     */
    const getLocalizedMonth = (date?: DateInAnyForm) => {
        const normalizedDate = getDate(date);
        if (!normalizedDate) {
            return '';
        }

        return normalizedDate.toLocaleString(currentLanguage.value, {
            month: 'long',
            year: 'numeric',
        });
    };

    /**
     * Split colon-separated time into unit-keyed number values
     * @param time {string} in format hh:mm:ss
     */
    const splitColonSeparatedTimeStringToNumbers = (
        time: string
    ): { hours: number; minutes: number; seconds: number } => {
        const [hours, minutes, seconds] = time.split(':');
        return {
            hours: Number(hours),
            minutes: Number(minutes),
            seconds: Number(seconds),
        };
    };

    const getTimeDifference = (laterDate: DateInAnyForm, earlierDate: DateInAnyForm) => {
        const nativeLaterDate = getDate(laterDate);
        const nativeEarlierDate = getDate(earlierDate);
        if (!nativeLaterDate || !nativeEarlierDate) {
            return '';
        }
        return {
            hours: differenceInHours(nativeLaterDate, nativeEarlierDate),
            minutes: differenceInMinutes(nativeLaterDate, nativeEarlierDate),
            seconds: differenceInSeconds(nativeLaterDate, nativeEarlierDate),
        };
    };

    const getApproximateIntervalLength = (time: {
        hours: number;
        minutes: number;
        seconds: number;
    }): { num: number; units: 'hours' | 'minutes' | 'seconds' } => {
        if (time.hours > 0) {
            return { units: 'hours', num: time.hours };
        } else if (time.minutes > 0) {
            return { units: 'minutes', num: time.minutes };
        } else {
            return { units: 'seconds', num: time.seconds };
        }
    };

    return {
        getApproximateIntervalLength,
        getNumericDate: getNumericDate,
        getLocalizedDate,
        getLocalizedDateTime,
        getLocalizedMonth,
        getLocalizedTime,
        getTimeDifference,
        splitColonSeparatedTimeStringToNumbers,
    };
}
