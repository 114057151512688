<template>
    <RawTag v-bind="$props" :class="[action, { 'action-tag': action }]" @click="onClick">
        <template #right-icon>
            <slot name="icon">
                <component :is="icon" v-tippy="{ content: tooltipActionText, onShow: () => action }" :size="iconSize" />
            </slot>
        </template>
        <template #content>
            <slot name="content"> </slot>
        </template>
        <template #left-icon>
            <slot name="left-icon" />
        </template>
    </RawTag>
</template>
<script lang="ts">
    import '@eloomi/icons/menu/menu-action-icon';
    import '@eloomi/icons/actions/actions-close-bold';
    import '@eloomi/icons/actions/actions-add-bold';
    import '@eloomi/icons/products-pages/products-and-pages-course-builder-lock-bold';

    import { computed, defineComponent, PropType } from 'vue';

    import { useTranslation } from '@/common/composables';

    import RawTag from './RawTag.vue';

    export default defineComponent({
        name: 'TagComponent',

        components: {
            RawTag,
        },
        props: {
            title: {
                type: String,
                required: true,
            },
            variant: {
                type: String as PropType<'topic' | 'category' | 'skill' | 'button'>,
                default: 'topic',
            },
            size: {
                type: String as PropType<'small' | 'large' | 'medium'>,
                default: 'small',
            },
            isActive: {
                type: Boolean,
                default: false,
            },
            highlightOnHover: {
                type: Boolean,
                default: false,
            },
            action: {
                type: String as PropType<'delete' | 'add' | 'more' | 'lock'>,
                default: undefined,
            },
            intention: {
                type: String as PropType<'danger'>,
                default: undefined,
            },
        },
        setup(props, { emit }) {
            const { trans } = useTranslation();
            const icon = computed(() => {
                switch (props.action) {
                    case 'more': {
                        return 'elm-menu-action-icon-icon';
                    }
                    case 'delete': {
                        return 'elm-actions-close-bold-icon';
                    }
                    case 'add': {
                        return 'elm-actions-add-bold-icon';
                    }
                    case 'lock': {
                        return 'elm-products-and-pages-course-builder-lock-bold-icon';
                    }
                    default: {
                        return '';
                    }
                }
            });

            const tooltipActionText = computed(() => {
                return props.action == 'delete' ? trans('spa.tooltip.remove') : '';
            });

            const onClick = () => {
                emit('click');
            };

            const iconSize = computed(() => {
                if (props.size === 'large' && props.action === 'more') {
                    return 20;
                }

                if (props.size === 'medium' && props.action === 'delete') {
                    return 12;
                }

                if (props.action === 'add') {
                    return 16;
                }

                if (props.action === 'lock') {
                    return 12;
                }

                if (props.size === 'large' && props.action === 'delete') {
                    return 10;
                }
                return 10;
            });

            return { iconSize, icon, onClick, tooltipActionText };
        },
    });
</script>

<style lang="less" scoped>
    &.action-tag {
        user-select: none;
    }
</style>
