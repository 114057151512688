import { RouteConfig } from 'vue-router';

import { checkIfRouteAvailableByRole } from '@/admin/access-feature.guard';
import { mobileIsUnsupportedFlag } from '@/common/services/mobile-fallback';
import { AppFeaturesList } from '@/launch-darkly/models';
import { checkIfAvailableByLaunchDarkly } from '@/launch-darkly/routes/launch-darkly.guard';
import { guards } from '@/main/multipleGuardsHandler';
import { Permissions } from '@/permissions';
import PlanFeaturesList from '@/plan-features/plan-features-list';
import { checkIfRouteAvailableByPlanFeatures } from '@/plan-features/routes';

const CustomFieldsAdministrationPage = () => import('./pages/CustomFieldsAdministrationPage.vue');
const brandingAdministrationPage = () => import('@/admin/branding/pages/BrandingAdministrationPage.vue');
const emailsAdministrationPage = () => import('@/admin/emails/pages/Emails.vue');

export const enum AdminSettingsRoutes {
    CUSTOM_FIELDS = 'custom-fields',
    BRANDING = 'branding',
    EMAILS = 'emails',
}

const routes: RouteConfig[] = [
    {
        path: `settings/${AdminSettingsRoutes.BRANDING}`,
        name: AdminSettingsRoutes.BRANDING,
        meta: {
            title: 'Branding Administration',
            ...mobileIsUnsupportedFlag,
            showNavigationSidebar: true,
            requiredPermissions: [Permissions.AdminBranding],
        },
        component: brandingAdministrationPage,
        beforeEnter: checkIfRouteAvailableByPlanFeatures(PlanFeaturesList.BRANDING),
    },
    {
        path: `settings/${AdminSettingsRoutes.CUSTOM_FIELDS}`,
        name: AdminSettingsRoutes.CUSTOM_FIELDS,
        meta: {
            title: 'Custom Fields',
            ...mobileIsUnsupportedFlag,
            showNavigationSidebar: true,
            requiredPermissions: [Permissions.AdminCustomFields],
        },
        component: CustomFieldsAdministrationPage,
        beforeEnter: guards([
            checkIfRouteAvailableByPlanFeatures(PlanFeaturesList.CUSTOM_FIELDS),
            checkIfRouteAvailableByRole(),
        ]),
    },
    {
        path: `settings/${AdminSettingsRoutes.EMAILS}`,
        name: AdminSettingsRoutes.EMAILS,
        meta: {
            title: 'Emails',
            ...mobileIsUnsupportedFlag,
            showNavigationSidebar: true,
            requiredPermissions: [Permissions.AdminEmails],
        },
        component: emailsAdministrationPage,
        beforeEnter: guards([checkIfAvailableByLaunchDarkly(AppFeaturesList.ADMIN_EMAIL_SETTINGS)]),
    },
];

export default routes;
