import { inject, injectable } from 'tsyringe';

import { makeLogger } from '@/common/services';
import type { SessionContainer as ImpersonationSessionContainer } from '@/impersonation/interfaces';

import { AuthenticationServiceTokens as Tokens } from '../di-tokens';
import { AnonymousUserError, ImpossibleToRestoreSessionError } from '../services/errors';
import type { AuthenticationSession, AuthenticationSessionFabric } from './interfaces';

@injectable()
export class SessionContainer<AuthData = unknown> implements ImpersonationSessionContainer<AuthData> {
    private log = makeLogger('session-container');
    private userSession?: AuthenticationSession<AuthData>;
    private failHandler: (error: unknown) => void = e => this.log('unhandled error has happened', e);
    private impersonationSession?: AuthenticationSession<AuthData>;

    public constructor(
        @inject(Tokens.AuthenticationSessionFabric)
        private readonly sessionFabric: AuthenticationSessionFabric<AuthData>
    ) {}

    public isLoggedIn() {
        return Boolean(this.userSession?.isActual());
    }

    public setFailHandler(handler: (error: unknown) => void) {
        this.failHandler = handler;
    }

    public isImpersonating() {
        return Boolean(this.impersonationSession);
    }

    public get activeSession() {
        return this.impersonationSession ?? this.userSession;
    }

    public getRegularSession() {
        if (this.userSession) {
            return this.userSession;
        }
        throw new AnonymousUserError();
    }

    public getImpersonationSession() {
        if (this.impersonationSession) {
            return this.impersonationSession;
        }
        throw new AnonymousUserError();
    }

    public getData(): AuthData {
        if (this.activeSession) {
            return this.activeSession.getData();
        }
        throw new AnonymousUserError();
    }

    public startSession(data: AuthData) {
        this.stopAllSessions();
        this.userSession = this.sessionFabric.createSession('regular', data, this.failHandler);
        this.log('started regular authentication session');
    }

    public stopActiveSession() {
        if (this.impersonationSession) {
            this.stopImpersonationSession();
        } else if (this.userSession) {
            this.stopAllSessions();
        }
    }

    public stopAllSessions() {
        this.impersonationSession?.destroy();
        this.impersonationSession = undefined;
        this.userSession?.destroy();
        this.userSession = undefined;
    }

    public startImpersonationSession(data: AuthData) {
        this.impersonationSession?.destroy();
        this.impersonationSession = this.sessionFabric.createSession('impersonation', data, this.failHandler);
        this.log('started impersonation session');
    }

    public stopImpersonationSession() {
        this.impersonationSession?.destroy();
        this.impersonationSession = undefined;
        this.log('stopped impersonation session');
    }

    public forceSessionRenewal() {
        if (this.activeSession) {
            this.log('forced to renew session');
            return this.activeSession.renew();
        }
    }

    public restoreSession() {
        try {
            if (this.userSession) {
                throw new ImpossibleToRestoreSessionError();
            }

            this.userSession = this.sessionFabric.restoreSession('regular', this.failHandler);
            this.log('restored regular user session');
            try {
                this.impersonationSession = this.sessionFabric.restoreSession('impersonation', this.failHandler);
                this.log('restored impersonation session');
            } catch {
                this.log('no impersonation session is found');
            }
        } catch (error) {
            this.log('failed to restore a user session');

            if (this.failHandler) {
                this.failHandler(error);
            }
        }
    }
}
