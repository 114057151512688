import { FeatureApiFactory } from '@eloomi/eloomi-platforms-persona-client/1.0/api/feature-api';
import { Vue } from 'vue-property-decorator';
import { Actions, createComposable, createMapper, Getters, Module, Mutations } from 'vuex-smart-module';

import { authorizeClient } from '@/api/authorize-client';
import PlanFeaturesList from '@/plan-features/plan-features-list';

const platformFeaturesClient = authorizeClient(FeatureApiFactory);

class State {
    public features: Record<PlanFeaturesList, boolean> | {} = {
        [PlanFeaturesList.SCORM]: true,
        [PlanFeaturesList.COURSES]: true,
        [PlanFeaturesList.PLAYLISTS]: true,
        [PlanFeaturesList.CATEGORIES_TOPICS]: true,
        [PlanFeaturesList.CUSTOM_FIELDS]: true,
        [PlanFeaturesList.GROUPS]: true,
        [PlanFeaturesList.REPORTING]: true,
        [PlanFeaturesList.BRANDING]: true,
        [PlanFeaturesList.SEGMENT_REPORTING]: true,
        [PlanFeaturesList.USER_PERMISSIONS]: true,
        [PlanFeaturesList.BILLING]: true,
        [PlanFeaturesList.SKILLS]: true,
        [PlanFeaturesList.NATIVE_INTEGRATION]: true,
    };
    public initialized = false;
    public initializeRequestPromise: Promise<void> | null = null;
    public featuresList = PlanFeaturesList;
}

class StoreGetters extends Getters<State> {
    public checkIfFeatureEnabled(featureName: PlanFeaturesList | PlanFeaturesList[]) {
        const featureNameArray = Array.isArray(featureName) ? featureName : [featureName];
        return featureNameArray.every(feature => this.state.features[feature]);
    }
}

export class StoreActions extends Actions<State, StoreGetters, StoreMutations, StoreActions> {
    public async initializeAllFeatures() {
        if (this.state.initializeRequestPromise === null) {
            this.mutations.setInitializePromise(
                platformFeaturesClient.getPlatformFeatureSettings().then(response => {
                    return this.mutations.setFeatureState(response.data as Record<PlanFeaturesList, boolean>);
                })
            );
        }

        await this.state.initializeRequestPromise!;
    }
}

class StoreMutations extends Mutations<State> {
    public setFeatureState(featureState: Record<PlanFeaturesList, boolean>) {
        this.state.features = {
            ...this.state.features,
            ...featureState,
        };

        this.state.initialized = true;
        this.state.initializeRequestPromise = null;
    }

    public setInitializePromise(promise: Promise<void>) {
        this.state.initializeRequestPromise = promise;
    }
}

const planFeaturesStore = new Module({
    state: State,
    mutations: StoreMutations,
    actions: StoreActions,
    getters: StoreGetters,
});

export const usePlanFeaturesStore = createComposable(planFeaturesStore);

export default planFeaturesStore;
export const planFeaturesStoreMapper = createMapper(planFeaturesStore);
export const PlanFeaturesMappedStore = Vue.extend({
    computed: {
        ...planFeaturesStoreMapper.mapState(['features', 'initialized', 'featuresList']),
        ...planFeaturesStoreMapper.mapGetters(['checkIfFeatureEnabled']),
    },
    methods: planFeaturesStoreMapper.mapActions(['initializeAllFeatures']),
});
