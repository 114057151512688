/* eslint-disable simple-import-sort/imports */
import {
    ApplicationInsights,
    DistributedTracingModes,
    ICustomProperties,
    ITelemetryItem,
} from '@microsoft/applicationinsights-web';
import environment from './environment';
import httpStore from '@/common/store/http';
import userStore from '@/common/store/user-details/store';
import type { Store } from 'vuex';

export function enableApplicationInsightsTelemetry(store: Store<unknown>) {
    if (environment.applicationInsightsKey) {
        const appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: environment.applicationInsightsKey,
                loggingLevelTelemetry: 2,
                enableAutoRouteTracking: true,
                distributedTracingMode: DistributedTracingModes.AI_AND_W3C,
                disableInstrumentationKeyValidation: true,
                enableCorsCorrelation: true,
                correlationHeaderDomains: ['*.eloomi.io'],
            },
        });

        appInsights.loadAppInsights();

        const telemetryInitializer = (envelope: ITelemetryItem) => {
            if (envelope?.baseData?.responseCode >= 500) {
                const traceId = envelope?.ext?.trace?.traceID ?? 'unknown';
                httpStore.context(store).mutations.setLastError(traceId);
            }

            let dataToSend: ICustomProperties = {
                ServiceName: environment.appName,
            };

            const userDetailsStoreModule = userStore.context(store);
            const userId = userDetailsStoreModule.state.appInsightsPlatformData?.userId;
            const platformId = userDetailsStoreModule.state.appInsightsPlatformData?.platformId;

            if (userId !== undefined) {
                dataToSend = {
                    ...dataToSend,

                    UserId: userId,
                };
            }

            if (platformId !== undefined) {
                dataToSend = {
                    ...dataToSend,

                    PlatformId: platformId,
                };
            }

            envelope.data = { ...dataToSend };
        };

        appInsights.addTelemetryInitializer(telemetryInitializer);
        appInsights.trackPageView();
    }
}
