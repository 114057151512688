<template>
    <label class="wrapper" :class="{ checked: value, disabled: isDisabled, large: !isSmall }">
        <input type="checkbox" :value="value" :disabled="isDisabled" @input="toggle" />
        <elm-alerts-info-approve-checkmark-icon size="10" class="radio" />
        <span v-if="hasAdditionalLabel" class="description">{{ label }}</span>
        <slot />
    </label>
</template>

<script lang="ts">
    import '@eloomi/icons/alerts-info/alerts-info-approve-checkmark';

    import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

    @Component({})
    export default class RadioButton extends Vue {
        @Prop({ default: '' }) public label?: string;
        @Prop({ default: 'small' }) public size?: 'small' | 'large';
        @Prop({ default: false, type: Boolean }) public isDisabled!: boolean;
        @Prop({ default: false, type: Boolean }) public value!: boolean;

        public get iconSize() {
            return this.isSmall ? 8 : 10;
        }

        public get isSmall() {
            return this.size === 'small';
        }

        public get hasAdditionalLabel() {
            return this.isSmall && this.label;
        }

        @Emit('input')
        public toggle(event: { target: HTMLInputElement }) {
            return event.target.checked;
        }
    }
</script>

<style lang="less" scoped>
    .wrapper {
        display: flex;
        gap: @spacing-16;
        align-items: center;
        height: 18px;

        &:hover {
            cursor: pointer;
        }

        &:hover .radio {
            color: @info-color-40;
        }

        &.large {
            height: 24px;

            .wrapper &:hover {
                cursor: pointer;
            }

            .radio {
                width: 24px;
                height: 24px;
            }
        }

        input {
            display: none;
        }
    }

    .wrapper.checked {
        .radio {
            color: @bright-color;
            background-color: @primary-color;
            border-color: @primary-color;
        }
    }

    .wrapper.disabled {
        &:hover {
            cursor: default;
        }

        &:hover .radio {
            color: transparent;
        }

        .radio {
            color: transparent;
            background-color: @info-color-8;
            border-color: @info-color-40;
            pointer-events: none;
        }
    }

    .wrapper.checked.disabled {
        .radio {
            color: @bright-color;
            background-color: @info-color;
            border-color: @info-color;
            pointer-events: none;
        }
    }

    .radio {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 18px;
        height: 18px;
        color: transparent;
        border: 1px solid @info-color-40;
        border-radius: @border-round;
        cursor: pointer;
        transition: @generic-transition;
    }

    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: @spacing-2;
    }

    .description {
        display: flex;
        align-items: center;
        padding-top: @spacing-2;
        color: @info-color;
        .text-line();

        user-select: none;
    }
</style>
