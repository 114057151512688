import { RouteConfig } from 'vue-router';

import { isPageEnteredFromBrowserBackForwardButton } from '@/common/services/back-button-accessing-page.guard';
import { mobileIsUnsupportedFlag } from '@/common/services/mobile-fallback';
import { redirectIfGuard } from '@/common/services/redirectIf.guard';
import { getBaseStore } from '@/main/get-base-store-router';
import { guards } from '@/main/multipleGuardsHandler';
import { Permissions } from '@/permissions';
import PlanFeaturesList from '@/plan-features/plan-features-list';
import { checkIfRouteAvailableByPlanFeatures } from '@/plan-features/routes';

import { plansStore } from './store';

const BILLING_CONFIRM_PAGE = () => import('./pages/BillingConfirmationPage.vue');
const BILLING_ADMINISTRATION_PAGE = () => import('./pages/BillingAdministrationPage.vue');
const BILLING_ROOT_PAGE = () => import('./pages/BillingRootPage.vue');
const CHOOSE_BILLING_PAGE = () => import('./pages/ChooseBillingPlanPage.vue');
const MODIFY_BILLING_PAGE = () => import('./pages/ModifyBillingConfirmationPage.vue');

export const enum AdminBillingRoutes {
    BILLING = 'billing',
    CONFIRM = 'confirm',
    BILLING_CONFIRM = 'billing-confirm',
    CHANGE = 'change',
    MODIFY = 'modify',
}

const doesUserHasAccount = () => {
    const plansModule = plansStore.context(getBaseStore());

    return Boolean(plansModule.state.plansResponse?.has_account);
};

const routes: RouteConfig[] = [
    {
        path: `${AdminBillingRoutes.BILLING}`,
        meta: {
            title: 'Billing',
            ...mobileIsUnsupportedFlag,
            showNavigationSidebar: true,
            requiredPermissions: [Permissions.AdminBilling],
        },
        component: BILLING_ROOT_PAGE,
        beforeEnter: checkIfRouteAvailableByPlanFeatures(PlanFeaturesList.BILLING),
        children: [
            {
                path: ``,
                name: AdminBillingRoutes.BILLING,
                meta: {
                    title: 'Billing',
                    ...mobileIsUnsupportedFlag,
                    showNavigationSidebar: true,
                    isChoosePlanButtonVisible: false,
                },
                component: BILLING_ADMINISTRATION_PAGE,
                beforeEnter: checkIfRouteAvailableByPlanFeatures(PlanFeaturesList.BILLING),
            },
            {
                path: `${AdminBillingRoutes.CHANGE}`,
                name: AdminBillingRoutes.CHANGE,
                meta: {
                    title: 'Billing',
                    ...mobileIsUnsupportedFlag,
                    isChoosePlanButtonVisible: false,
                },
                component: CHOOSE_BILLING_PAGE,
                beforeEnter: checkIfRouteAvailableByPlanFeatures(PlanFeaturesList.BILLING),
            },
            {
                path: `${AdminBillingRoutes.CHANGE}/${AdminBillingRoutes.CONFIRM}`,
                name: AdminBillingRoutes.BILLING_CONFIRM,
                meta: {
                    title: 'Billing',
                    ...mobileIsUnsupportedFlag,
                    defaultBackRoute: AdminBillingRoutes.CHANGE,
                    isChoosePlanButtonVisible: false,
                },
                component: BILLING_CONFIRM_PAGE,
                beforeEnter: guards([
                    redirectIfGuard(() => doesUserHasAccount(), AdminBillingRoutes.MODIFY),
                    isPageEnteredFromBrowserBackForwardButton(AdminBillingRoutes.CHANGE),
                    checkIfRouteAvailableByPlanFeatures(PlanFeaturesList.BILLING),
                ]),
            },
            {
                path: `${AdminBillingRoutes.CHANGE}/${AdminBillingRoutes.MODIFY}`,
                name: AdminBillingRoutes.MODIFY,
                meta: {
                    title: 'Billing',
                    ...mobileIsUnsupportedFlag,
                    defaultBackRoute: AdminBillingRoutes.CHANGE,
                    isChoosePlanButtonVisible: false,
                },
                component: MODIFY_BILLING_PAGE,
                beforeEnter: guards([
                    redirectIfGuard(() => !doesUserHasAccount(), AdminBillingRoutes.BILLING_CONFIRM),
                    checkIfRouteAvailableByPlanFeatures(PlanFeaturesList.BILLING),
                ]),
            },
        ],
    },
];

export default routes;
