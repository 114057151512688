<template>
    <div class="toast-notification" :class="toastClasses">
        <div class="toast-notification__icon-area">
            <div class="toast-notification__icon-outer-circle" :class="themeClasses"></div>
            <component :is="icon" class="toast-notification__icon" size="18" :class="themeClasses" />
        </div>
        <div class="toast-notification__message">
            {{ message }}
        </div>
    </div>
</template>

<script lang="ts">
    import '@eloomi/icons/alerts-info/alerts-info-info-bold';
    import '@eloomi/icons/alerts-info/alerts-info-checkmark-bold';
    import '@eloomi/icons/alerts-info/alerts-info-warning-bold';
    import '@eloomi/icons/alerts-info/alerts-info-close-bold';
    import '@eloomi/icons/actions/actions-close-bold';

    import { Component, Prop, Vue } from 'vue-property-decorator';

    import { NotificationTheme } from './notification.interface';

    @Component({})
    export default class ToastNotification extends Vue {
        @Prop({ default: 'info' }) public theme!: NotificationTheme;
        @Prop({ default: '' }) public message!: string;
        @Prop({ default: false }) public isCompact!: boolean;

        public get icon() {
            const iconMap: { [key in NotificationTheme]: string } = {
                info: 'elm-alerts-info-info-bold-icon',
                success: 'elm-alerts-info-checkmark-bold-icon',
                warning: 'elm-alerts-info-warning-bold-icon',
                danger: 'elm-alerts-info-close-bold-icon',
            };

            return iconMap[this.theme];
        }

        public get themeClasses() {
            return [`with-theme-${this.theme}`];
        }

        public get toastClasses() {
            return [{ 'toast-notification--is-compact': this.isCompact }];
        }
    }
</script>

<style lang="less" scoped>
    @toast-height: 56px;

    @keyframes icon-spin {
        0% {
            transform: rotate(270deg);
            opacity: 0;
        }

        100% {
            transform: rotate(360deg);
            opacity: 1;
        }
    }

    @keyframes pulsing {
        0% {
            opacity: 1;
        }

        50% {
            opacity: 0.5;
        }

        100% {
            opacity: 1;
        }
    }

    .toast-notification {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        height: @toast-height;
        padding-right: @spacing-24;
        background-color: @bright-color;
        border-radius: @toast-height / 2;
        .effects-box-shadow;

        &--is-compact {
            max-width: 320px;
        }

        &__icon-area {
            position: relative;
            display: flex;
            flex-shrink: 0;
            align-items: center;
            width: @spacing-64;
            padding-left: @spacing-8;
        }

        &__icon-outer-circle {
            flex-shrink: 0;
            width: 40px;
            height: 40px;
            border-radius: 20px;
            animation-name: pulsing;
            animation-duration: 1.2s;
            animation-delay: 0.3s;
            animation-iteration-count: infinite;

            &.with-theme-info {
                background-color: @primary-color-8;
            }

            &.with-theme-success {
                background-color: @success-color-8;
            }

            &.with-theme-warning {
                background-color: @warning-color-8;
            }

            &.with-theme-danger {
                background-color: @danger-color-8;
            }
        }

        &__icon {
            position: absolute;
            top: 11px;
            left: 19px;
            animation: icon-spin;
            animation-duration: 0.4s;
            animation-iteration-count: 1;

            &.with-theme-info {
                color: @primary-color;
            }

            &.with-theme-success {
                color: @success-color;
            }

            &.with-theme-warning {
                color: @warning-color;
            }

            &.with-theme-danger {
                color: @danger-color;
            }
        }

        &__message {
            flex-grow: 1;
            max-height: 100%;
            overflow: hidden;
            text-align: center;
            text-overflow: ellipsis;
            user-select: none;
            .info-line-dark;
            .font-semibold;
        }
    }
</style>
