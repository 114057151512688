<template>
    <button class="tag-button" :disabled="disabled" :class="{ disabled }" @click="onClick">
        <div v-if="$slots.icon" class="tag-button-icon">
            <slot name="icon" />
        </div>
        <span class="tag-button-text">
            {{ title }}
        </span>
    </button>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'TagButton',
        props: {
            title: {
                type: String,
                required: true,
            },
            icon: {
                type: String,
                default: undefined,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },
        setup(_, { emit }) {
            const onClick = () => {
                emit('click');
            };

            return {
                onClick,
            };
        },
    });
</script>

<style lang="less" scoped>
    @tag-button-height: 46px;
    @tag-button-mobile-height: 30px;
    .tag-button {
        display: inline-flex;
        align-items: center;
        height: @tag-button-height;
        padding: 0 @spacing-20;
        color: @info-color;
        background-color: @info-color-8;
        border: none;
        border-radius: @border-radius-23;
        cursor: pointer;
        .info-line();
        .generic-transition(~'background-color, color');

        &:focus,
        &:active {
            outline: none;
        }

        &:hover:not(.disabled) {
            color: @primary-color;
            background-color: @primary-color-8;

            .tag-button-icon {
                color: @primary-color;
            }
        }

        &.disabled {
            cursor: not-allowed;
            opacity: 0.6;
            pointer-events: none;
        }

        .sm-viewport-and-smaller({
            .text-label();
            height: @tag-button-mobile-height;
            padding: @spacing-6 @spacing-11 @spacing-4;
            border-radius: @border-radius-15;
        });
    }
    .tag-button-text {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        .info-line();
        .font-semibold();

        .sm-viewport-and-smaller({
            .text-label();
        });
    }
    .tag-button-icon {
        .flex-center-content;
        margin-right: @spacing-12;
        color: @info-color;
    }
</style>
