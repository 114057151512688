import { RouteConfig } from 'vue-router';

const adminRootPage = () => import('./pages/AdminRootPage.vue');

import AdministrationBillingRoutes from '@/admin/billing/routes';
import AdministrationCourseRoutes from '@/admin/courses/routes';
import AdministrationEventRoutes from '@/admin/events/routes';
import groupsAdminRoutes from '@/admin/groups/routes';
import AdministrationApiRoutes from '@/admin/integrations/routes';
import playlistsAdminRoutes from '@/admin/playlists/routes';
import AdminRolesRoutes from '@/admin/roles/routes';
import AdministrationSettingsRoutes from '@/admin/settings/routes';
import AdminSkillsRoutes from '@/admin/skills/routes';
import topicsAdminRoutes from '@/admin/topics/routes';
import AdministrationUsersRoutes from '@/admin/users/routes';
import { adminReportRoutes } from '@/reporting/routes';

export const enum AdminRoutes {
    ROOT_ADMIN = 'root_admin',
}

const routes: RouteConfig[] = [
    {
        path: 'admin',
        name: AdminRoutes.ROOT_ADMIN,
        component: adminRootPage,
        children: [
            ...AdminSkillsRoutes,
            ...AdministrationApiRoutes,
            ...AdministrationBillingRoutes,
            ...AdministrationCourseRoutes,
            ...AdministrationEventRoutes,
            ...AdministrationSettingsRoutes,
            ...AdministrationUsersRoutes,
            ...adminReportRoutes,
            ...AdminRolesRoutes,
            ...groupsAdminRoutes,
            ...playlistsAdminRoutes,
            ...topicsAdminRoutes,
        ],
    },
];

export default routes;
