import { EmailSettingApiFactory, EmailSettingsResponse } from '@eloomi/eloomi-emails-persona-client/1.0';

import { extractAxiosData } from '@/common/services/extract-axios-data';

import { authorizeClient } from '../authorize-client';

const emailSettingsClient = authorizeClient(EmailSettingApiFactory);

export const getEmailSettings = (): Promise<EmailSettingsResponse> => {
    return emailSettingsClient.getEmailSettings().then(extractAxiosData);
};

export const setEmailSettings = (updatedSetting): Promise<EmailSettingsResponse> => {
    return emailSettingsClient.setEmailSettings(updatedSetting).then(extractAxiosData);
};
