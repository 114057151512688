import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';

let isSplashScreenVisible = true;
/** Has effect on native platforms only (ios, android) */
export async function hideSplashScreen(delayMs?: number) {
    if (!isSplashScreenVisible) return;

    if (Capacitor.isNativePlatform()) {
        return new Promise<void>((resolve, _reject) => {
            setTimeout(async () => {
                await SplashScreen.hide();
                resolve();
            }, delayMs ?? 0);

            isSplashScreenVisible = false;
        });
    }
}

/** Has effect on native platforms only (ios, android) */
export async function showSplashScreen() {
    if (isSplashScreenVisible) return;

    if (Capacitor.isNativePlatform()) {
        await SplashScreen.show();
        isSplashScreenVisible = true;
    }
}
