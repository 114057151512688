import { snakeToCamel } from './utils';

type SnakeToCamelCase<S extends string | number | symbol> = S extends `${infer T}_${infer U}`
    ? `${T}${Capitalize<SnakeToCamelCase<U>>}`
    : S;

type AsCamel<InputType> = { [K in keyof InputType as SnakeToCamelCase<K>]: InputType[K] };

/**
 * Convert all the object keys to camelCase and mark all fields as required.
 *
 * It supposes that there is no undefined fields in the backend response.
 * Since such fields are marked as `nullable` and should contain `null`.
 */
export function convertBackendResponseToModel<T extends object>(dataObject: T): Required<AsCamel<T>> {
    const newObj: Record<string, unknown> = {};
    for (const key of Object.keys(dataObject)) {
        if (dataObject[key] !== undefined) newObj[snakeToCamel(key)] = dataObject[key];
    }

    return newObj as Required<AsCamel<T>>;
}
