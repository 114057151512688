import { Vue } from 'vue-property-decorator';
import { Actions, createComposable, createMapper, Getters, Module, Mutations } from 'vuex-smart-module';

import { getBranding } from '@/api/providers/branding.provider';
import { type Branding, createBranding } from '@/common/models/branding.model';
import { cacheBrandingData, getCachedBrandingData } from '@/common/services/cached-branding';

class State {
    public brandingState: Branding | null = null;
}

class StoreActions extends Actions<State, Getters<State>, StoreMutations, StoreActions> {
    public async getBranding() {
        const cachedBrandingData = getCachedBrandingData();

        if (cachedBrandingData !== null) {
            this.mutations.setBranding(cachedBrandingData);
        }

        const brandingResponse = await getBranding();
        const newBrandingData = createBranding(brandingResponse);
        this.mutations.setBranding(newBrandingData);
        this.dispatch('cacheBranding');
    }

    public setBranding(data: Branding) {
        this.mutations.setBranding(data);
    }

    public cacheBranding() {
        if (this.state.brandingState !== null) {
            cacheBrandingData(this.state.brandingState);
        }
    }
}

class StoreMutations extends Mutations<State> {
    public setBranding(data: Branding) {
        this.state.brandingState = data;
    }
}

const brandingStore = new Module({
    state: State,
    actions: StoreActions,
    mutations: StoreMutations,
});

export const useBrandingStore = createComposable(brandingStore);

const brandingStoreMapper = createMapper(brandingStore);

export const BrandingMappedStoreMixin = Vue.extend({
    computed: {
        ...brandingStoreMapper.mapState(['brandingState']),
    },
    methods: {
        ...brandingStoreMapper.mapActions(['getBranding', 'setBranding', 'cacheBranding']),
    },
});

export default brandingStore;
