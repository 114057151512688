import {
    type TwentyThreeVideoApiFactory,
    type VimeoVideoApiFactory,
    VideoContentResultContentResult,
} from '@eloomi/eloomi-content-persona-client/1.0';

export type TwentyThreeClient = ReturnType<typeof TwentyThreeVideoApiFactory>;
export type VimeoClient = ReturnType<typeof VimeoVideoApiFactory>;

import { type VideoContentElement } from '@/admin/courses/utils';

export enum VideoProviderType {
    Vimeo,
    TwentyThree,
}

export enum VideoStatus {
    Unknown = 'unknown',
    Created = 'created',
    Finalized = 'finalized',
    Uploading = 'uploading',
    Transcoding = 'transcoding',
    UploadError = 'uploadError',
    TranscodingError = 'transcodingError',
    Ready = 'ready',
    Removed = 'removed',
    VideoUnidentified = 'videoUnidentified',
}

export interface VideoResponse {
    id?: string | number;
    url?: string | null;
    status?: VideoStatus;
}

export interface VideoUploadOptions {
    contentId: string;
    file: File;
    onProgressChanged: (progressValue) => void;
}

export interface VideoProvider {
    getVideo(id: string | number): Promise<VideoResponse>;
    uploadVideo(options: VideoUploadOptions): Promise<VideoResponse>;
    editVideo(contentId: string, data: VideoContentElement): Promise<VideoContentResultContentResult>;
    /**
     To be tight with People implementation we want to show twenty three video link as embedded one, so why this function has been mostly prepared.
     We need to stay on backend with real URL for video, thought about preparing all on backend, at least adding new property like video_link_embedded, however we still
     need the same implementation on infinite for others iframing controls. So remembering about changing those on both sides will be too much effort for maintaining.
     */
    convertVideoLink(link: string): string;
}
