import { Dictionary } from '@/translations';

export type TranslationVariables = { [key: string]: string | number | null | undefined };

function replaceAll(phrase: string, searchValue: RegExp | string, replaceValue: string): string {
    const regExp = new RegExp(searchValue, 'g');
    return phrase.replace(regExp, replaceValue);
}

function injectVariables(phrase: string, variables: TranslationVariables): string {
    return Object.keys(variables).reduce((resultPhrase, currentKey) => {
        const value = variables[currentKey];
        const normalizedValue = value === undefined ? currentKey : String(value);

        return replaceAll(resultPhrase, `(#${currentKey}#)|({{${currentKey}}})`, normalizedValue);
    }, phrase);
}

function getPhrase(key: string, locale?: string, dictionary?: Dictionary, variables?: TranslationVariables) {
    if (!dictionary || dictionary[key] === undefined) {
        return;
    }
    const entry = dictionary[key];

    if (typeof entry === 'string') {
        return entry;
    }

    if (variables && 'num' in variables && typeof variables.num === 'number' && locale) {
        if (variables.num === 0 && entry['zero']) {
            return entry['zero'];
        }
        const category = new Intl.PluralRules(locale).select(variables.num);
        if (entry[category]) {
            return entry[category];
        }
    }

    return entry['other'] || entry['many'] || entry['one'] || undefined;
}

export type TranslateKeyParams = {
    key: string;
    dictionary: Dictionary;
    variables?: TranslationVariables;
    locale?: string;
    fallbackLocale?: string;
    fallbackDictionary?: Dictionary;
};

export function translateKey({
    key,
    dictionary,
    variables,
    locale,
    fallbackLocale,
    fallbackDictionary,
}: TranslateKeyParams): string {
    if (document.cookie.includes('translation_keys=1')) {
        return key;
    }

    let phrase =
        getPhrase(key, locale, dictionary, variables) || getPhrase(key, fallbackLocale, fallbackDictionary, variables);

    if (phrase && variables) {
        phrase = injectVariables(phrase, variables);
    }

    if (
        phrase === undefined &&
        (document.cookie.includes('warn_missing_keys=1') || process.env.NODE_ENV === 'development')
    ) {
        console.warn(`[Translation] There's no value for key: ${key}`);
    }

    return phrase || key;
}
