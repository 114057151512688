var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Selector',{class:{ disabled: _vm.isDisabled, locked: _vm.isLocked },attrs:{"options":_vm.options,"renderToPortal":_vm.renderToPortal,"preventOutsideClickPropagation":_vm.preventOutsideClickPropagation,"closeOnSelect":_vm.closeOnSelect,"popupTestName":_vm.testName},on:{"select":function($event){return _vm.$emit('select', $event)}},scopedSlots:_vm._u([{key:"input",fn:function(ref){
var toggle = ref.toggle;
var isOpen = ref.isOpen;
return [_vm._t("input",function(){return [_c('TextField',{staticClass:"selector",attrs:{"size":_vm.size,"value":_vm.stringValue,"iconRight":_vm.getIconRight(isOpen),"placeholder":_vm.placeholder,"isReadOnly":"","iconLeft":_vm.iconLeft,"isSelectable":false,"color":_vm.color,"isDisabled":_vm.isDisabled,"testId":("selector-field-" + _vm.testName)},nativeOn:{"click":function($event){$event.stopPropagation();$event.preventDefault();return toggle.apply(null, arguments)}}})]},{"toggle":toggle,"isOpen":isOpen})]}},{key:"option",fn:function(ref){
var option = ref.option;
var select = ref.select;
var index = ref.index;
return [_c('DropdownItem',{key:option,attrs:{"isSelected":option === _vm.value,"title":_vm.getItemTitle(option, index)},on:{"click":function($event){return select(option)}}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }