import { DashboardApiFactory } from '@eloomi/eloomi-learning-persona-client/2.0';
import { DashboardApiFactory as NewDashboardApiFactory } from '@eloomi/eloomi-learning-persona-client/3.0';

import { authorizeClient } from '@/api/authorize-client';
import { SelectedOption } from '@/common/components/table';
import { buildSort } from '@/common/services';
import { extractAxiosData } from '@/common/services/extract-axios-data';
import { UnmappedUserCourse } from '@/courses/models';

export interface CourseOverviewResponse {
    data: UnmappedUserCourse[];
    total: number;
    last_page: number;
}
const dashboardClient = authorizeClient(DashboardApiFactory);
const newDashboardClient = authorizeClient(NewDashboardApiFactory);

export function getUserLearningItems(
    { page = 1, pageSize = 10 } = {},
    filters?: string,
    sorts?: SelectedOption | null
) {
    return dashboardClient
        .getUserLearningItems(filters, buildSort(sorts), page, pageSize)
        .then(response => response.data);
}

export function getSectionsList() {
    return newDashboardClient.getLiteDashboardSections().then(({ data }) => data);
}

export function getDashboardSectionsLearningItems(keys: string[], pageSize: number, page = 1) {
    return newDashboardClient
        .getDashboardSectionsLearningItems(keys, undefined, undefined, page, pageSize)
        .then(extractAxiosData);
}

export function getDashboardSection(key: string, { page = 1, pageSize = 4 } = {}) {
    return newDashboardClient.getDashboardSection(key, undefined, undefined, page, pageSize).then(({ data }) => data);
}

export interface DashboardSearchSingleResult {
    id: number;
    image_url: string | null;
    title: string;
    description: string;
    duration: number;
    type?: 'topic' | 'course' | 'playlist';
}

export const DASHBOARD_PAGE_SIZE = 6;

export const dashboardSearch = searchTerm => {
    return dashboardClient.search(searchTerm, DASHBOARD_PAGE_SIZE).then(response => response.data);
};
