<template>
    <keep-alive>
        <component :is="isShown ? 'TabSlotWrapper' : undefined" class="tab-content" role="tabpanel">
            <slot></slot>
        </component>
    </keep-alive>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import TabSlotWrapper from './TabSlotWrapper.vue';

    @Component({
        components: { TabSlotWrapper },
    })
    export default class TabContent extends Vue {
        @Prop({ default: true, type: Boolean }) public isShown!: boolean;
    }
</script>
