<template>
    <div class="loader" :class="classes"></div>
</template>

<script lang="ts">
    import { Component, Vue, Prop } from 'vue-property-decorator';

    @Component({
        components: {},
    })
    export default class CircleSpinner extends Vue {
        @Prop({ default: 'info' }) public variant!: 'info' | 'primary';
        @Prop({ default: 'medium' }) public size!: 'small' | 'medium' | 'large';

        public get classes() {
            return [`loader--${this.variant}`, `loader--${this.size}`];
        }
    }
</script>

<style lang="less" scoped>
    .border-color(@plate, @circle) {
        border-top-color: @circle;
        border-right-color: @circle;
        border-bottom-color: @circle;
        border-left-color: @plate;
    }

    .size(@size) {
        width: @size;
        height: @size;
    }

    .loader {
        position: relative;
        border-style: solid;
        transform: translateZ(0);
        border-radius: 50%;
        animation: load8 1.1s infinite linear;

        &--info {
            .border-color(@info-color, @info-color-20);
        }

        &--primary {
            .border-color(@primary-color, @primary-color-20);
        }

        &--small {
            .size(16px);
            border-width: 2px;
        }

        &--medium {
            .size(24px);
            border-width: 3px;
        }

        &--large {
            .size(32px);
            border-width: 4px;
        }
    }

    @keyframes load8 {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
</style>
