// tslint:disable: max-classes-per-file
import { Module, Mutations, createMapper } from 'vuex-smart-module';

class State {
    public isModalShown = false;
}

class WhatsnewMutations extends Mutations<State> {
    public showModal() {
        this.state.isModalShown = true;
    }

    public hideModal() {
        this.state.isModalShown = false;
    }
}

const whatsNewStore = new Module({
    state: State,
    mutations: WhatsnewMutations,
});

export const whatsNewStoreMapper = createMapper(whatsNewStore);
export default whatsNewStore;
