<template>
    <CoreMobileBottomDrawer
        :isVisible="visible"
        isFullscreen
        :isClosable="false"
        className="mobile-selector"
        :preventTouchScroll="false"
    >
        <template #header>
            <div class="selector-header">
                <MobileHeader :withTitle="false">
                    <template #left>
                        <elm-actions-close-bold-icon size="20" class="close-button" @click="onClose" />
                    </template>
                    <template #right>
                        <elm-button color="primary" @click="onConfirm">
                            {{ confirmText || trans('spa.button.confirm') }}
                        </elm-button>
                    </template>
                </MobileHeader>
                <elm-text-field
                    class="selector-input"
                    :placeholder="placeholder || trans('spa.uikit.search.placeholder')"
                    :value="filter"
                    @elm-input="onInput"
                >
                    <elm-actions-search-icon slot="icon-left" size="18" />
                    <elm-actions-close-regular-icon
                        v-if="filter"
                        slot="icon-right"
                        class="clear-button"
                        size="18"
                        @click="clearFilter"
                    />
                </elm-text-field>
            </div>
        </template>
        <div v-if="!filter" class="selected-options">
            <div v-if="title" class="h5 dark-color selected-title">
                {{ title }}
            </div>
            <template v-for="option in currentValue">
                <slot name="selected-option" :option="option" :remove="remove">
                    <Tag
                        :key="option[trackBy]"
                        :title="option[displayField]"
                        class="selected-option"
                        variant="skill"
                        action="delete"
                        intention="danger"
                        isActive
                        size="medium"
                        @click="remove(option)"
                    />
                </slot>
            </template>
            <div v-if="!currentValue.length" class="no-items-text info-line info-color">
                {{ noItemsText || trans('spa.uikit.selector.no-items-selected') }}
            </div>
        </div>
        <div v-else class="all-options">
            <template v-for="option in filteredOptions">
                <slot
                    name="option"
                    :option="option"
                    :isSelected="isSelected(option)"
                    :toggleSelection="toggleSelection"
                    :select="select"
                    :remove="remove"
                >
                    <Tag
                        :key="option[trackBy]"
                        :title="option[displayField]"
                        class="available-option"
                        :isActive="isSelected(option)"
                        :action="isSelected(option) ? 'delete' : 'add'"
                        variant="skill"
                        intention="danger"
                        size="medium"
                        @click="toggleSelection(option)"
                    />
                </slot>
            </template>
            <div v-observe-visibility="onScrolledToBottom" />
            <slot v-if="isLoading" name="loader">
                <div class="selector-loader">
                    <elm-date-time-reset-icon size="16" />
                    <div class="loader-text info-line">{{ trans('spa.label.selector.loading') }}</div>
                </div>
            </slot>
            <slot v-else-if="!filteredOptions.length" name="no-results">
                <div class="no-results info-color">
                    <elm-alerts-info-viewable-published-no-icon size="32" />
                    <div class="no-results-text info-line">{{ trans('spa.uikit.selector.no-search-results') }}</div>
                </div>
            </slot>
        </div>
    </CoreMobileBottomDrawer>
</template>

<script lang="ts">
    import { Prop, Emit, Component, Mixins, Watch } from 'vue-property-decorator';
    import '@eloomi/icons/actions/actions-close-bold';
    import '@eloomi/icons/actions/actions-search';
    import '@eloomi/icons/alerts-info/alerts-info-viewable-published-no';
    import '@eloomi/icons/date-time/date-time-reset';
    import CoreMobileBottomDrawer from '@/common/components/modal/CoreMobileBottomDrawer.vue';
    import MobileHeader from '@/common/components/app-header/MobileHeader.vue';
    import Tag from '@/ui-kit/tag/Tag.vue';
    import { ObserveVisibility } from 'vue-observe-visibility';
    import { TranslationMixin } from '@/common/mixins';
    import { sleep } from '@/common/services';

    @Component({
        components: {
            Tag,
            MobileHeader,
            CoreMobileBottomDrawer,
        },
        directives: {
            ObserveVisibility,
        },
    })
    export default class BasicSelector extends Mixins(TranslationMixin) {
        @Prop({ default: () => [] }) value!: any[];
        @Prop({ default: () => [], required: true }) options!: any[];
        @Prop({ type: Boolean, default: false }) filterLocally!: boolean;
        @Prop({ type: Boolean, default: false }) isLoading!: boolean;
        @Prop({ type: Boolean, default: false }) showSelected!: boolean;
        @Prop({ type: Boolean, default: false }) visible!: boolean;
        @Prop({ type: String }) confirmText?: string;
        @Prop({ type: String }) noItemsText?: string;
        @Prop({ type: String }) placeholder?: string;
        @Prop({ type: String }) title?: string;
        @Prop({ type: String, default: 'id' }) trackBy!: string;
        @Prop({ type: String, default: 'name' }) displayField!: string;

        public currentValue: any[] = [];
        public filter = '';
        public isScrolledToEnd = false;

        @Watch('value', { immediate: true })
        public onValueChanged() {
            this.currentValue = [...this.value];
        }

        @Watch('options', { deep: true })
        public async onOptionsChanged() {
            if (this.isScrolledToEnd) {
                await sleep(100);
                if (this.isScrolledToEnd) {
                    this.onReachedEnd();
                }
            }
        }

        @Watch('currentValue', { deep: true })
        @Emit('change')
        onCurrentValueChange() {
            return this.currentValue;
        }

        public isSelected(option: any) {
            return Boolean(this.selectedIds.includes(option[this.trackBy]));
        }

        public remove(option: any) {
            this.currentValue = this.currentValue.filter(el => el !== option);
            this.clearFilter();
        }

        public select(option: any) {
            if (!this.isSelected(option)) {
                this.currentValue.push(option);
            }
            this.clearFilter();
        }

        public toggleSelection(option: any) {
            if (this.isSelected(option)) {
                this.remove(option);
            } else {
                this.select(option);
            }
        }

        public clearFilter() {
            this.filter = '';
        }

        get lowercaseFilter() {
            return this.filter.toLowerCase();
        }

        get selectedIds() {
            return this.currentValue.map(el => el[this.trackBy]);
        }

        public get filteredOptions() {
            let result = this.options;
            if (this.filterLocally) {
                result = result.filter(el => el[this.displayField].toLowerCase().includes(this.lowercaseFilter));
            }
            if (!this.showSelected) {
                result = result.filter(el => !this.selectedIds.includes(el[this.trackBy]));
            }
            return result;
        }

        public onScrolledToBottom(isVisible: boolean) {
            this.isScrolledToEnd = isVisible;
            if (isVisible) {
                this.onReachedEnd();
            }
        }

        @Emit('close')
        public onClose() {
            this.currentValue = [...this.value];
        }

        @Emit('search-input')
        public onInput(event) {
            this.filter = event.target.value;
            return this.filter;
        }

        @Emit('confirm')
        public onConfirm() {
            return this.currentValue;
        }

        @Emit('reached-end')
        public onReachedEnd() {
            return;
        }
    }
</script>

<style lang="less">
    .mobile-selector {
        &.mobile-drawer-layout {
            background-color: @bright-color;

            .scrollable-wrapper {
                background-color: @info-color-8;
            }
        }

        .selector-header {
            display: flex;
            flex-direction: column;
            align-self: stretch;
        }

        .mobile-header {
            padding-top: calc(@spacing-16 + @spacing-40);
            background: transparent;
        }

        .mobile-drawer {
            background: transparent;
        }

        .close-button {
            color: @info-color;
            cursor: pointer;
        }

        .selector-input {
            padding: @spacing-32 @spacing-16 @spacing-24 @spacing-16;
        }

        .selected-options,
        .all-options {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0 8px;

            .selected-title {
                margin-top: @spacing-8;
                margin-bottom: @spacing-12;
            }

            .selected-option,
            .available-option {
                margin-bottom: @spacing-8;
            }

            .available-option {
                flex-direction: row;
            }

            .available-option .action-icon {
                margin-right: 0;
                margin-left: @spacing-8;
            }

            .selector-loader {
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 38px;
                color: @info-color;
            }

            .loader-text {
                margin-left: @spacing-8;
            }
        }

        .clear-button {
            cursor: pointer;
            transition: @generic-transition;

            &:hover {
                color: @primary-color;
            }
        }

        .no-results {
            display: flex;
            flex-direction: column;
            align-items: center;
            align-self: stretch;

            .no-results-text {
                margin-top: @spacing-16;
            }
        }
    }
</style>
