<template>
    <MainThemeProvider class="main-container">
        <div class="authentication-wrapper">
            <button v-if="withBackButton" type="button" class="back-button" @click="handleBackButtonClick">
                <elm-arrows-arrow-left-bold-icon size="medium"></elm-arrows-arrow-left-bold-icon>
            </button>

            <div class="authentication-section">
                <slot />
            </div>
            <div class="background-section" :style="backgroundStyles">
                <picture v-if="isFallbackImageUsed">
                    <source :srcset="loginImageLarge" type="image/png" media="(min-width: 992px)" />
                    <source :srcset="loginImageSmall" type="image/png" />
                    <img alt="" class="fallback-image" />
                </picture>

                <video
                    v-else
                    ref="videoElement"
                    class="motion-graphics"
                    playsinline
                    autoplay
                    muted
                    disableRemotePlayback
                >
                    <source :src="loginVideoH265" type="video/mp4; codecs=hvc1" />
                    <source :src="loginVideoVP9" type="video/webm; codecs=vp9" />
                </video>
            </div>
            <portal-target class="modals" name="modal-host" :multiple="true"></portal-target>
        </div>
    </MainThemeProvider>
</template>

<script lang="ts">
    import '@eloomi/icons/arrows/arrows-arrow-left-bold';

    import { Component, Emit, Mixins, Prop, Ref } from 'vue-property-decorator';

    import loginBgImage from '@/assets/img/login-bg.svg';
    import loginImageLarge from '@/assets/img/login-fallback-large.png';
    import loginImageSmall from '@/assets/img/login-fallback-small.png';
    import loginVideoH265Large from '@/assets/video/login.hevc.mp4';
    import loginVideoVP9Large from '@/assets/video/login.vp9.webm';
    import loginVideoH265Small from '@/assets/video/login-small.hevc.mp4';
    import loginVideoVP9Small from '@/assets/video/login-small.vp9.webm';
    import Notifications from '@/common/components/toast/notifications.mixin';
    import { makeLogger } from '@/common/services';
    import { MobileModeStoreMixin } from '@/common/store/mobile-mode';
    import MainThemeProvider from '@/main/MainThemeProvider.vue';

    @Component({
        components: {
            MainThemeProvider,
        },
    })
    export default class AuthenticationPage extends Mixins(Notifications, MobileModeStoreMixin) {
        @Prop({ default: false, type: Boolean }) public readonly withBackButton!: boolean;
        @Ref('videoElement') public videoElement!: HTMLVideoElement;

        private readonly log = makeLogger('login-page-layout');
        private readonly FORCE_AUTOPLAY_LAG = 100;

        public readonly loginVideoH265Large = loginVideoH265Large;
        public readonly loginVideoVP9Large = loginVideoVP9Large;
        public readonly loginVideoH265Small = loginVideoH265Small;
        public readonly loginVideoVP9Small = loginVideoVP9Small;

        public readonly loginImageLarge = loginImageLarge;
        public readonly loginImageSmall = loginImageSmall;

        private readonly loginBgImage = loginBgImage;

        public isFallbackImageUsed = false;

        @Emit('back-button-click')
        public handleBackButtonClick() {
            return;
        }

        public mounted() {
            setTimeout(this.tryForcePlayingVideo, this.FORCE_AUTOPLAY_LAG);
        }

        public tryForcePlayingVideo() {
            if (!this.isFallbackImageUsed && typeof this.videoElement?.play === 'function') {
                const result = this.videoElement.play();
                Promise.resolve(result).catch(error => {
                    this.log('Cannot autoplay video, falling to static image', error);
                    this.isFallbackImageUsed = true;
                });
            }
        }

        public get loginVideoH265() {
            return this.isViewportSmallerOrEqualMd ? loginVideoH265Small : loginVideoH265Large;
        }

        public get loginVideoVP9() {
            return this.isViewportSmallerOrEqualMd ? loginVideoVP9Small : loginVideoVP9Large;
        }

        public get backgroundStyles() {
            return { '--image-src-url': `url(${this.loginBgImage})` };
        }
    }
</script>

<style lang="less" scoped>
    .main-container {
        max-height: 100%;
    }

    .authentication-wrapper {
        position: relative;
        display: flex;
        width: 100%;

        .md-viewport-and-smaller({
            flex-direction: column-reverse;
            align-items: center;
        });
    }

    .authentication-wrapper .authentication-section {
        display: flex;
        flex-direction: column;
        flex-grow: 0;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        width: 600px;
        background: @bright-color;

        .md-viewport-and-smaller({
            margin-top: -@spacing-16;
            width: 100%;
            z-index: 1;
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;
            overflow: hidden;
            flex-grow: 1;
        });

        .sm-viewport-and-smaller({
            flex-grow: 0;
        });
    }

    .background-section {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background-image: var(--image-src-url);
        background-position: 50% 50%;
        background-size: cover;

        .md-viewport-and-smaller({
            flex-grow: 1;
            max-height: 50%;
        });

        .sm-viewport-and-smaller({
            max-height: none;
        });

        .lg-viewport-and-larger({
            max-height: 100vh;
            overflow: hidden;
        });

        picture {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
        }
    }

    .motion-graphics {
        width: 100%;
        height: 100%;
        object-fit: contain;

        .sm-viewport-and-larger({
            object-fit: cover;
        });

        .lg-viewport-and-larger({
            object-fit: contain;
        });

        &::-internal-media-controls-overlay-cast-button {
            display: none;
        }
    }

    .fallback-image {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;

        .lg-viewport-and-larger({
            max-width: 1024px;
            max-height: 768px;
            width: 100%;
            height: 100%;
        });
    }

    .back-button {
        position: absolute;
        top: @spacing-24;
        left: @spacing-16;
        z-index: 1;
        display: none;
        padding: @spacing-11;
        color: @color-info-60;
        background-color: @color-bright;
        border: 0;
        border-radius: 50%;
        .effects-box-shadow;

        .md-viewport-and-smaller({
            display: inline-flex;
        });
    }
</style>
