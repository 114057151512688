import { SkillResponse } from '@eloomi/eloomi-learning-persona-client/1.0';
import { Mutations } from 'vuex-smart-module';

import { ICoursePlayerCourse, ICoursePlayerPage } from '@/courses/models';
import { CoursePlayerState } from '@/courses/store/course-player/state';

export class UserCoursesMutations extends Mutations<CoursePlayerState> {
    public setCourse(course: ICoursePlayerCourse) {
        this.state.course = course;
    }

    public setCourseSkills(courseSkills: SkillResponse[]) {
        this.state.courseSkills = courseSkills;
    }

    public setPageDetails(pageDetails: ICoursePlayerPage | null) {
        this.state.currentPageDetails = pageDetails;
    }

    public setQuizAttemptId(id) {
        if (this.state.currentPageDetails?.quiz) {
            this.state.currentPageDetails.quiz.quiz_attempt_id = id;
        }
    }

    public setCoursePageLoading(value: boolean) {
        this.state.isCoursePageLoading = value;
    }

    public reset() {
        this.state.course = null;
        this.state.currentPageDetails = null;
        this.state.chapterId = null;
        this.state.pageId = null;
    }

    public setActiveChapterId(chapterId: number | null) {
        this.state.chapterId = chapterId;
    }

    public setActivePageId(pageId: number | null) {
        this.state.pageId = pageId;
    }

    public markActivePageAsCompleted() {
        const activeChapter = this.state.course?.chapters.find(chapter => chapter.id === this.state.chapterId) || null;
        const activePage = activeChapter?.pages.find(page => page.id === this.state.pageId) || null;

        if (activePage) {
            activePage.is_completed = true;
        }
    }
}
