import { useLaunchDarklyFeatureStore } from '@/launch-darkly/store';

export function useLaunchDarkly() {
    const store = useLaunchDarklyFeatureStore();

    return {
        checkIfLaunchDarklyFeatureEnabled: store.getters.checkIfLaunchDarklyFeatureEnabled,
        launchDarklyFeaturesList: store.state.launchDarklyFeaturesList,
        getLaunchDarklyFeatureData: store.getters.getLaunchDarklyFeatureData,
    };
}

export default useLaunchDarkly;
