<template>
    <ThemeProvider :theme="brandingState">
        <slot></slot>
    </ThemeProvider>
</template>
<script lang="ts">
    import { defineComponent } from 'vue';

    import ThemeProvider from '@/common/components/theme-provider/ThemeProvider.vue';
    import { BrandingMappedStoreMixin } from '@/common/store/branding/store';

    export default defineComponent({
        name: 'MainThemeProvider',
        components: { ThemeProvider },
        mixins: [BrandingMappedStoreMixin],
    });
</script>
