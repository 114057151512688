<template>
    <div
        :class="[
            'authentication-flow-panel',
            { 'center-vertically': centerVertically, 'sso-only-login': isSsoOnlyLoginMode },
        ]"
    >
        <div class="logo">
            <img title="logo" :srcset="logoImage" />
        </div>
        <form class="authentication-flow-form" @submit.prevent="$emit('submit', $event)">
            <h1 v-if="title || withBackButton" :class="['title', ' h1-dark', { 'with-back-button': withBackButton }]">
                <button
                    v-if="withBackButton"
                    class="back-icon"
                    type="button"
                    @click="$emit('back-button-click', $event)"
                >
                    <elm-arrows-arrow-left-regular-icon size="20" />
                </button>
                {{ title }}
            </h1>

            <p v-if="description" class="paragraph-info instructions">{{ description }}</p>

            <slot></slot>
        </form>
    </div>
</template>

<script lang="ts">
    import '@eloomi/icons/arrows/arrows-arrow-left-regular';

    import { defineComponent, PropType } from 'vue';

    import { useLogoForAuthenticationPage } from '../composables/use-logo-for-auth-page';

    export default defineComponent({
        name: 'AuthenticationFlowPanel',
        props: {
            title: {
                type: String as PropType<string | undefined>,
                required: false,
                default: undefined,
            },
            description: {
                type: String as PropType<string | undefined>,
                required: false,
                default: undefined,
            },
            withBackButton: {
                type: Boolean,
                default: false,
            },
            isSsoOnlyLoginMode: {
                type: Boolean,
                default: false,
            },
            centerVertically: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['submit', 'back-button-click'],
        setup() {
            const { logoImage } = useLogoForAuthenticationPage();
            return {
                logoImage,
            };
        },
    });
</script>

<style lang="less" scoped>
    .authentication-flow-panel {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        max-width: 391px;
        min-height: 496px;

        .md-viewport-and-smaller({
            min-height: auto;
        });

        &.center-vertically {
            justify-content: center;
        }

        &.sso-only-login {
            justify-content: center;
        }
    }

    .authentication-flow-form {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: @spacing-48 @spacing-24 @spacing-40 @spacing-24;
        background-color: @bright-color;
    }

    .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 343px;
        height: 88px;

        .md-viewport-and-smaller({
            display: none;
        });

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .title {
        display: flex;
        align-items: center;
        justify-content: center;

        &.with-back-button {
            justify-content: flex-start;
        }
    }

    .back-icon {
        @invisible-padding: @spacing-8;

        display: inline-flex;
        margin: 0;
        margin: (-@invisible-padding) (-@invisible-padding);
        margin-right: @spacing-24 - @invisible-padding;
        padding: 0;
        padding: @invisible-padding;
        color: @color-info-40;
        background-color: transparent;
        border: none;
        cursor: pointer;
        .generic-transition(color);

        &:hover {
            color: @primary-color;
        }

        .md-viewport-and-smaller({
            display: none;
        });
    }

    .instructions {
        margin: 0;
        margin-top: @spacing-24;
    }
</style>
