import { render, staticRenderFns } from "./NotificationMenu.vue?vue&type=template&id=04a606b2&scoped=true&"
import script from "./NotificationMenu.vue?vue&type=script&lang=ts&"
export * from "./NotificationMenu.vue?vue&type=script&lang=ts&"
import style0 from "./NotificationMenu.vue?vue&type=style&index=0&id=04a606b2&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04a606b2",
  null
  
)

export default component.exports