<template>
    <Tabs :tabKeys="keys" :titles="titles" :value="selectedKey" withBorder @input="onInput" />
</template>

<script lang="ts">
    import { computed, defineComponent } from 'vue';

    import { useTranslation } from '@/common/composables';
    import { ElementType } from '@/common/types';
    import Tabs from '@/ui-kit/tabs/Tabs.vue';

    const keys = ['no', 'yes'] as const;
    type Key = ElementType<typeof keys>;

    export default defineComponent({
        name: 'YesNoSwitch',
        components: { Tabs },
        props: {
            value: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['input'],
        setup(props, { emit }) {
            const { trans } = useTranslation();

            const titles = keys.map(key => trans(`spa.label.${key}`));

            const onInput = (key: Key) => {
                emit('input', key === 'yes');
                return key;
            };

            const selectedKey = computed<Key>(() => (props.value ? 'yes' : 'no'));

            return { keys, onInput, selectedKey, titles };
        },
    });
</script>
