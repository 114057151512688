<template>
    <SkeletonLoader class="selector-input-skeleton" v-if="isLoading" height="56px" />
    <div v-else class="selector-input" @click="clickHandler">
        <elm-actions-search-icon />
    </div>
</template>

<script lang="ts">
    import '@eloomi/icons/actions/actions-search';
    import { defineComponent, PropType } from 'vue';
    import { SkeletonLoader } from '@/common/components';

    export default defineComponent({
        name: 'SelectorInput',
        props: {
            value: {
                type: String as PropType<string>,
            },
            isLoading: {
                type: Boolean as PropType<boolean>,
            },
        },
        components: { SkeletonLoader },
        setup(_props, { emit }) {
            const clickHandler = () => emit('click');
            return {
                clickHandler,
            };
        },
    });
</script>

<style lang="less" scoped>
    .selector-input {
        display: flex;
        align-items: center;
        width: 100%;
        height: 56px;
        padding: @spacing-16;
        background-color: @info-color-20;
        border-radius: @border-radius-8;
        &:hover {
            cursor: pointer;
        }
    }
    .selector-input-skeleton {
        max-width: inherit;
    }
</style>
