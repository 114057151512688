import { getCurrentInstance } from 'vue';

export function useRouter() {
    const vm = getCurrentInstance();
    if (!vm) {
        throw new Error('You must use this function within the "setup()" method');
    }

    return vm.proxy.$router;
}
