<template>
    <div class="picture-wrapper" :style="wrapperStyle" :class="wrapperClasses">
        <SkeletonLoader
            v-if="isLoading && isImageAvailable"
            class="picture-skeleton"
            :width="width"
            :height="height"
            borderRadius="0"
        />
        <img
            v-if="isImageAvailable"
            class="picture"
            :class="additionalClassesOnPicture"
            :src="src"
            :width="width"
            :height="height"
            :alt="alt"
            @load="handleImageLoad"
            @error="handleImageLoadError"
        />
        <div v-else-if="showNoImageIcon" class="icon-offset-fix">
            <elm-products-and-pages-course-builder-image-icon class="info-color-40" :size="iconSize" />
        </div>

        <div v-if="hasSlot" class="content" :class="invisibleWhileLoadingClass">
            <slot :isLoading="isLoading && isImageAvailable"></slot>
        </div>
        <div v-if="withShadowOverlay && isImageAvailable" class="shadow-overlay"></div>
    </div>
</template>

<script lang="ts">
    import '@eloomi/icons/products-pages/products-and-pages-course-builder-image';
    import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
    import { SkeletonLoader } from '@/common/components';
    import { PropType } from 'vue';

    type PictureSize = [width: number | string, height: number | string];

    const addPxIfNumber = (val: number | string) => {
        if (typeof val === 'number') {
            return `${val}px`;
        }
        return val.endsWith('%') ? val : `${parseInt(val, 10)}px`;
    };

    @Component({
        components: { SkeletonLoader },
    })
    export default class CorePicture extends Vue {
        @Prop({ default: () => ['0', '0'], type: Array }) public size!: PictureSize;
        @Prop({ type: String }) public src!: string | null;
        @Prop({ default: undefined, type: String }) public alt?: string;
        @Prop({ default: 'small', type: String as PropType<'small' | 'medium' | 'large'> }) public placeholderSize!:
            | 'small'
            | 'medium'
            | 'large';
        @Prop({ default: 'medium', type: String as PropType<'none' | 'small' | 'medium' | 'large'> })
        public borderRadius!: 'none' | 'small' | 'medium' | 'large';
        @Prop({ default: 'info', type: String as PropType<'info' | 'primary'> }) public theme!: 'info' | 'primary';
        @Prop({ default: false, type: Boolean }) public isStretched!: boolean;
        @Prop({ default: false, type: Boolean }) public isGreyedOut!: boolean;
        @Prop({ default: true, type: Boolean }) public showNoImageIcon!: boolean;
        @Prop({ default: false, type: Boolean }) public isArchived!: boolean;
        @Prop({ default: false, type: Boolean }) public withShadowOverlay!: boolean;
        @Prop({ default: false, type: Boolean }) public skipLoadingOnUpdate!: boolean;

        public failedToLoad = false;
        private isLoading = true;

        public get hasSlot() {
            return Boolean(this.$slots.default);
        }

        @Watch('src')
        public handleSrcChange(newValue, oldValue) {
            this.failedToLoad = false;
            if (!(this.skipLoadingOnUpdate && newValue && oldValue)) {
                this.isLoading = true;
            }
        }

        public get wrapperClasses() {
            return {
                [`with-radius-${this.borderRadius}`]: this.borderRadius !== 'none',
                [`background-${this.theme}`]: true,
                'greyed-out': this.isGreyedOut,
                archived: this.isArchived,
            };
        }

        public get wrapperStyle() {
            if (this.isStretched) {
                const width = parseInt(`${this.size[0]}`, 10);
                const height = parseInt(`${this.size[1]}`, 10);
                return {
                    width: '100%',
                    height: '0',
                    paddingTop: `${(height / width) * 100}%`,
                };
            }

            return {
                width: this.width,
                height: this.height,
                minWidth: this.width,
            };
        }

        public get additionalClassesOnPicture() {
            return {
                invisible: this.isLoading && this.isImageAvailable,
            };
        }

        public get invisibleWhileLoadingClass() {
            return {
                invisible: this.isLoading && this.isImageAvailable,
            };
        }

        public get width() {
            return this.isStretched ? '100%' : addPxIfNumber(this.size[0]);
        }

        public get height() {
            return this.isStretched ? '100%' : addPxIfNumber(this.size[1]);
        }

        public get iconSize() {
            switch (this.placeholderSize) {
                case 'small':
                    return 12;
                case 'medium':
                    return 18;
                case 'large':
                    return 32;
                default:
                    return 12;
            }
        }

        @Emit('error')
        public handleImageLoadError() {
            this.isLoading = false;
            this.failedToLoad = true;
        }

        @Emit('load')
        public handleImageLoad() {
            this.isLoading = false;
        }

        public get isImageAvailable() {
            return this.src !== null && this.src !== '' && !this.failedToLoad;
        }
    }
</script>

<style lang="less" scoped>
    .picture-wrapper {
        position: relative;
        overflow: hidden;
        color: @info-color-40;

        &.with-radius-small {
            border-radius: @border-radius-6;
        }

        &.with-radius-medium {
            border-radius: @border-radius-8;
        }

        &.with-radius-large {
            border-radius: @border-radius-12;
        }

        &.background-primary {
            background-color: @primary-color-8;
        }

        &.background-info {
            background-color: @info-color-8;
        }

        &.greyed-out {
            opacity: 0.5;
            filter: saturate(0);

            &::after {
                position: absolute;
                width: 100%;
                height: 100%;
                background: @info-color-20;
                content: '';
            }
        }

        &.archived {
            opacity: 0.5;

            &::after {
                position: absolute;
                width: 100%;
                height: 100%;
                background: @info-color-20;
                content: '';
            }
        }
    }

    .icon-offset-fix {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    .picture {
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        .animated-visible();

        &.invisible {
            .animated-hidden();
        }
    }

    .content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        .animated-visible();

        &.invisible {
            .animated-hidden();
        }
    }

    .picture-skeleton {
        position: absolute;
        top: 0;
        left: 0;
        max-width: 100%;
        max-height: 100%;
    }

    .shadow-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: radial-gradient(
            50% 54.43% at 50% 40.57%,
            rgba(0, 0, 0, 0) 62.4%,
            rgba(0, 0, 0, 0.2) 186.7%,
            rgba(0, 0, 0, 0.2) 0,
            rgba(0, 0, 0, 0.2) 100%
        );
    }
</style>
