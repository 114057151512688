import { AxiosInstance } from 'axios';
import * as axiosRetry from 'retry-axios';
import type { Store } from 'vuex';

import httpStore from '@/common/store/http';

const RETRIES_COUNT = 3;

export function enableHttpRetryMechanism(store: Store<unknown>, httpClient: AxiosInstance) {
    httpClient.defaults.raxConfig = {
        instance: httpClient,
        retry: RETRIES_COUNT,
        retryDelay: 1000,
        httpMethodsToRetry: ['GET'],
        statusCodesToRetry: [[500, 599]],
        onRetryAttempt: err => {
            const { currentRetryAttempt } = axiosRetry.getConfig(err) ?? { currentRetryAttempt: 0 };

            httpStore
                .context(store)
                .mutations.setRetryingHttpState(
                    Boolean(currentRetryAttempt && currentRetryAttempt > 0 && currentRetryAttempt < RETRIES_COUNT)
                );
        },
    };
    axiosRetry.attach(httpClient);
}
