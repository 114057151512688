<script lang="ts">
    import { computed, CreateElement, defineComponent, onUpdated, PropType } from 'vue';

    import { useTranslation } from '@/common/composables';
    import { TranslationKey } from '@/translations';

    export default defineComponent({
        name: 'SlottedTranslation',
        props: {
            translationKey: { type: String as PropType<TranslationKey>, required: true },
            localTrans: { type: Function as PropType<(string) => string> },
        },
        emits: ['update'],
        setup(props, { emit }) {
            const { trans } = useTranslation();
            onUpdated(() => emit('update'));
            return {
                translatedString: computed(() =>
                    props.localTrans ? props.localTrans(props.translationKey) : trans(props.translationKey)
                ),
            };
        },
        render(createElement: CreateElement) {
            return createElement(
                'span',
                {},
                this.translatedString.split(/(#\w+#)|({{\w+}})/).map(el => {
                    const match = /^(#|{{)(\w+)(#|}})$/.exec(el);
                    return match ? this.$slots[match[2]] : el;
                })
            );
        },
    });
</script>
