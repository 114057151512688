import {
    CreateSessionRequest,
    EventsTrackingApiFactory,
    SessionApiFactory,
    SessionDateResponse,
    SessionPaginatedResponse,
    SessionResponse,
    SessionUserAdminApiFactory,
    UpdateSessionRequest,
} from '@eloomi/eloomi-event-management-persona-client/1.0';

import { Session, SessionDate, SessionPaginatedData } from '@/admin/events/models';
import { authorizeClient } from '@/api/authorize-client';
import { DataRequestOptions } from '@/common/components/table';
import { FluentFilterCollection, getUsersIds, Operator } from '@/common/services';
import { extractAxiosData } from '@/common/services/extract-axios-data';

const sessionsClient = authorizeClient(SessionApiFactory);
const sessionUsersClient = authorizeClient(SessionUserAdminApiFactory);
const eventsTrackingClient = authorizeClient(EventsTrackingApiFactory);

export function createSession(eventId: number, session: CreateSessionRequest) {
    return sessionsClient.createSession(eventId, session).then(({ data }) => data);
}

export function getEventSessions(
    eventId: number,
    { page, pageSize, start, end }: { page?: number; pageSize?: number; start?: Date; end?: Date }
) {
    const builder = new FluentFilterCollection();

    if (start) {
        builder.where('start_date').filter(Operator.GreaterThanOrEqual, start);
    }

    if (end) {
        builder.where('start_date').filter(Operator.LessThanOrEqual, end);
    }

    const filters = builder.build();
    return sessionsClient
        .adminGetSessionsForEvent(eventId, filters, undefined, page, pageSize)
        .then(({ data }) => sessionPaginatedResponseToSessionPaginatedData(data));
}

export function getSession(sessionId: number): Promise<Session> {
    return sessionsClient.getSession(sessionId).then(extractAxiosData).then(sessionResponseToSession);
}

export function getSessionEnrolledUsersNumber(sessionId: number): Promise<number> {
    return sessionUsersClient.getSessionUsersNumber(sessionId).then(({ data }) => data);
}

function hasId({ id }: { id?: number }) {
    return id !== undefined;
}

function SessionDateResponseToSessionDate(sessionDate: SessionDateResponse): SessionDate {
    return {
        ...sessionDate,
        id: sessionDate.id!,
        start: sessionDate.start ? new Date(sessionDate.start) : undefined,
        end: sessionDate.end ? new Date(sessionDate.end) : undefined,
    };
}

export function sessionDateToSessionDateResponse(sessionDate: SessionDate): SessionDateResponse {
    return {
        ...sessionDate,
        start: sessionDate.start ? sessionDate.start.toISOString() : undefined,
        end: sessionDate.end ? sessionDate.end.toISOString() : undefined,
    };
}

export function sessionToSessionRequest(session: Omit<Session, 'id' | 'event_id'>) {
    const { session_dates, instructors, ...restFields } = session;
    return {
        ...restFields,
        session_dates: session_dates.map(sessionDateToSessionDateResponse),
        instructor_ids: getUsersIds(instructors),
    };
}

function sessionResponseToSession(sessionResponse: SessionResponse): Session {
    return {
        ...sessionResponse,
        id: sessionResponse.id!,
        session_dates: sessionResponse.session_dates?.filter(hasId).map(SessionDateResponseToSessionDate) || [],
    };
}

export function sessionPaginatedResponseToSessionPaginatedData(
    response: SessionPaginatedResponse
): SessionPaginatedData {
    return {
        total: response.total || 0,
        last_page: response.last_page || 0,
        data: response.data?.filter(hasId).map(sessionResponseToSession) || [],
    };
}

export function deleteSession(sessionId: number, notify?: boolean) {
    return sessionsClient.deleteSession(sessionId, notify);
}

export function deleteSessionBulk(sessionIds: number[], notify?: boolean) {
    return sessionsClient.deleteSessionBulk(notify, sessionIds);
}

export function updateSession(sessionId: number, session: UpdateSessionRequest) {
    return sessionsClient.updateSession(sessionId, session).then(axiosResponse => axiosResponse.data);
}

export function getSessionLocalizableReport(sessionId: number, options: DataRequestOptions) {
    return eventsTrackingClient
        .getSessionLocalizableReport(sessionId, undefined, undefined, options.page, options.pageSize)
        .then(axiosResponse => axiosResponse.data);
}

export function getSessionExcelReport(sessionId: number) {
    return eventsTrackingClient
        .getSessionExcelReport(sessionId, { responseType: 'blob' })
        .then(axiosResponse => axiosResponse.data);
}
