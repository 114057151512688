import { type MaybeElement, Fn, tryOnUnmounted, unrefElement, useEventListener } from '@vueuse/core';
import type { Ref } from 'vue';

const events = ['click', 'touchstart'] as const;
type EventType = WindowEventMap[typeof events[number]];

export function onClickOutside(targets: Ref<MaybeElement>[], handler: (evt: EventType) => void, capture = false) {
    const listener = (event: EventType) => {
        const els = targets.map(unrefElement);

        if (els.some(el => el && (el === event.target || event.composedPath().includes(el)))) {
            return;
        }

        handler(event);
    };

    let disposables: Fn[] = events.map(event => useEventListener(window, event, listener, { passive: true, capture }));

    const stop = () => {
        for (const stop of disposables) stop();
        disposables = [];
    };

    tryOnUnmounted(stop);

    return stop;
}
