<template>
    <Popover
        ref="popover"
        :placement="placement"
        :closeOnContentClick="closeOnContentClick"
        :popoverTestName="testName"
        :positionStrategy="positionStrategyPopover"
        :autoMaxHeight="autoMaxHeightPopover"
        @hide="handlePopoverHide"
    >
        <template #target="{ toggle, isVisible }">
            <elm-button
                :active="isVisible || isButtonActive"
                :size="size"
                :color="color"
                :type="isCompact ? 'icon' : 'icon-right'"
                :disabled="isDisabled"
                :loading="isLoading"
                :data-testid="buttonTestId"
                @click="toggle"
            >
                <template v-if="!isCompact">
                    {{ title }}

                    <elm-arrows-chevron-up-bold-icon
                        v-if="isVisible"
                        slot="icon"
                        :size="size === 'large' ? '18' : '16'"
                    />
                    <elm-arrows-chevron-down-bold-icon v-else slot="icon" :size="size === 'large' ? '18' : '16'" />
                </template>
                <slot v-else name="compact-view"></slot>
            </elm-button>
        </template>
        <template #content>
            <slot name="custom-dropdown-container">
                <BasicDropdownContainer>
                    <slot></slot>
                </BasicDropdownContainer>
            </slot>
        </template>
    </Popover>
</template>

<script lang="ts">
    import { Component, Prop, Emit, Vue } from 'vue-property-decorator';

    import { Themes } from '@/common/classes';
    import '@eloomi/icons/arrows/arrows-chevron-down-bold';
    import '@eloomi/icons/arrows/arrows-chevron-up-bold';

    import BasicDropdownContainer from '../dropdown/containers/BasicDropdownContainer.vue';
    import Popover from '../popover/Popover.vue';

    @Component({
        components: { BasicDropdownContainer, Popover },
    })
    export default class DropdownButton extends Vue {
        @Prop({ required: true, type: String }) public title!: string;
        @Prop({ default: 'primary', type: String }) public color!: Themes;
        @Prop({ default: 'large', type: String }) public size!: 'small' | 'large';
        @Prop({ default: false, type: Boolean }) public isDisabled!: boolean;
        @Prop({ default: true, type: Boolean }) public closeOnContentClick!: boolean;
        @Prop({ default: false, type: Boolean }) public isLoading!: boolean;
        @Prop({ default: 'bottom-start', type: String }) public placement!: string;
        @Prop({ default: undefined, type: String }) public testName?: string;
        @Prop({ default: false, type: Boolean }) public isButtonActive?: boolean;
        @Prop({ default: 'absolute', type: String }) public positionStrategyPopover!: string;
        @Prop({ default: false, type: Boolean }) public autoMaxHeightPopover?: boolean;
        @Prop({ default: false, type: Boolean }) public isCompact?: boolean;

        public isActive = false;

        public get buttonTestId() {
            return this.testName ? `dropdown-button-${this.testName}` : undefined;
        }

        public handlePopoverShow() {
            this.isActive = true;
        }

        @Emit('blur')
        public handlePopoverHide() {
            this.isActive = false;
        }

        public hidePopover() {
            (this.$refs.popover as any).hide();
        }

        public updatePopover() {
            (this.$refs.popover as any).update();
        }
    }
</script>

<style lang="less">
    .elm-dropdown-button {
        outline: none;

        &[x-placement^='bottom'] {
            .popover-inner {
                margin-left: -@spacing-8;
            }
        }
    }
</style>
