import analytics from '@segment/analytics.js-core';
import SegmentIntegration from '@segment/analytics.js-integration-segmentio';
import VueRouter from 'vue-router';

import userDetailsStore from '@/common/store/user-details/store';
import environment from '@/environment';
import { getBaseStore } from '@/main/get-base-store-router';

import { UserProfile } from '../interfaces';

const isAnalyticsEnabled = () => {
    /**
     * This is how we should get baseStore in any place outside of .vue files to prevent circular references.
     */
    const store = getBaseStore();
    return userDetailsStore.context(store).state.authUserProfile?.analyticsEnabled ?? false;
};

class SegmentService {
    isInitialized = false;
    isLoaded = false;

    init(isIntegrationEnabled: boolean, isInternalPlatform: boolean) {
        const useAnalytics = Boolean(environment.segmentApiKey);
        if (!useAnalytics || !isIntegrationEnabled || this.isInitialized) return;

        const integrationSettings = {
            'Segment.io': {
                apiKey: environment.segmentApiKey,
                retryQueue: true,
                addBundledMetadata: true,
                unbundledIntegrations: ['Mixpanel'],
            },
        };

        analytics.use(SegmentIntegration);
        if (!isInternalPlatform) {
            analytics.initialize(integrationSettings);
            this.isInitialized = true;
        }

        this.isLoaded = true;
    }

    identify(user: UserProfile) {
        if (!this.isLoaded || !user.analyticsEnabled) {
            return;
        }

        analytics.identify(`${user.platformId}:${user.id}`, {
            name: `${user.firstName} ${user.lastName}`,
        });
    }

    triggerPage() {
        if (!this.isLoaded || !isAnalyticsEnabled()) {
            return;
        }

        analytics.page();
    }
}

export const segment = new SegmentService();

export const includeSavePageInSegment = (router: VueRouter) => {
    router.beforeEach((_to, _from, next) => {
        segment.triggerPage();
        return next();
    });
};
