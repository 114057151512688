import { BASE_URL } from '@/common/constants/base-path';
import { Location } from 'vue-router';

interface QueryParams {
    [key: string]: string;
}

function convertSearchParamsToQuery(searchParams: URLSearchParams): QueryParams {
    const params: QueryParams = {};

    searchParams.forEach((value, key) => {
        params[key] = value;
    });

    return params;
}

/**
 * Get route location from url
 *
 * Examples:
 * "https://{platform.Domain}/app/courses/{command.CourseId}/overview" -> { path: "/courses/{command.CourseId}/overview", query: {} }
 * "https://{platform.Domain}/app/instant-login?token=12345" -> { path: "/instant-login", query: { token: "12345" } }
 */
export function convertUrlToLocation(absoluteUrl: string): Location | null {
    try {
        const url = new URL(absoluteUrl);
        const path = url.pathname.slice(BASE_URL.length);
        const query = convertSearchParamsToQuery(url.searchParams);
        return { path, query };
    } catch (error) {
        console.error(error);
        return null;
    }
}
