<template>
    <transition-group
        tag="div"
        class="slide-transition-container"
        :class="[
            `slide-${slideDistance}`,
            slideDirectionClass,
            {
                'with-dynamic-height': transitionHeight,
                transitioning: isTransitionInProgress,
                [`horizontal-align-${horizontalItemAlignment}`]: horizontalItemAlignment,
                [`vertical-align-${verticalItemAlignment}`]: verticalItemAlignment,
            },
        ]"
        :style="containerStyle"
        name="slide"
        @before-enter="beforeEnter"
        @before-leave="beforeLeave"
        @enter="enter"
        @after-leave="afterLeave"
    >
        <slot></slot>
    </transition-group>
</template>

<script lang="ts">
    import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
    import { PropType } from 'vue';

    type SlideDistance = 'small' | 'medium' | 'large';
    type HorizontalItemAlignment = 'stretch' | 'left' | 'center' | 'right';
    type VerticalItemAlignment = 'stretch' | 'top' | 'center' | 'bottom';

    @Component
    export default class SlideTransition extends Vue {
        @Prop({ type: String as PropType<SlideDistance>, default: 'large' }) public slideDistance!: SlideDistance;
        @Prop({ type: String as PropType<HorizontalItemAlignment> })
        public horizontalItemAlignment?: HorizontalItemAlignment;
        @Prop({ type: String as PropType<VerticalItemAlignment> }) public verticalItemAlignment?: VerticalItemAlignment;
        @Prop({ type: Boolean, default: false }) public transitionHeight!: boolean;
        @Prop({ type: String, default: undefined }) public forceDirection!: 'left' | 'right' | undefined;

        private leavingIndex: number | null = null;
        private enteringIndex: number | null = null;
        private height: number | null = null;
        public isTransitionInProgress = false;

        private getDirectionClassFromSingleIndex(index: number, action: 'entering' | 'leaving') {
            switch (index) {
                case 0:
                    return action === 'entering' ? 'slide-right' : 'slide-left';
                case this.$el.children.length - 1:
                    return action === 'entering' ? 'slide-left' : 'slide-right';
                default:
                    return null;
            }
        }

        public get slideDirectionClass() {
            if (this.forceDirection) {
                return `slide-${this.forceDirection}`;
            } else {
                if (this.hasBothIndexes) {
                    return (this.enteringIndex as number) <= (this.leavingIndex as number)
                        ? 'slide-right'
                        : 'slide-left';
                } else if (this.enteringIndex !== null) {
                    return this.getDirectionClassFromSingleIndex(this.enteringIndex, 'entering');
                } else if (this.leavingIndex !== null) {
                    return this.getDirectionClassFromSingleIndex(this.leavingIndex, 'leaving');
                } else {
                    return null;
                }
            }
        }

        get hasBothIndexes() {
            return this.enteringIndex !== null && this.leavingIndex !== null;
        }

        private getChildIndex(el: HTMLElement): number {
            let index = el.dataset.index && parseInt(el.dataset.index);
            if (!Number.isInteger(index)) {
                const siblings = Array.prototype.slice.call(this.$el.children);
                index = siblings.indexOf(el);
            }
            return index !== -1 ? (index as number) : this.$el.children.length;
        }

        public prepareTransitionClasses() {
            this.isTransitionInProgress = true;

            if (this.slideDirectionClass && !this.$el.classList.contains(this.slideDirectionClass)) {
                // this.$mount(); rerenders component immediately. That hack is required to update container's
                // slide direction class before elements are inserted to trigger correct animation
                this.$mount();
            }
        }

        public beforeEnter(el: HTMLElement) {
            this.enteringIndex = this.getChildIndex(el);
            this.prepareTransitionClasses();
        }

        public beforeLeave(el: HTMLElement) {
            if (this.transitionHeight) {
                this.height = el.clientHeight;
            }
            this.leavingIndex = this.getChildIndex(el);
            this.prepareTransitionClasses();
        }
        @Emit('enter')
        public enter(el: HTMLElement) {
            if (this.transitionHeight) {
                this.height = el.clientHeight;
            }
        }

        public afterLeave() {
            this.isTransitionInProgress = false;
            this.leavingIndex = this.enteringIndex = null;
        }

        public get containerStyle() {
            if (!this.transitionHeight || this.height === null) {
                return null;
            }
            return { height: this.height + 'px' };
        }
    }
</script>
<style lang="less" scoped>
    .slide-transition-container {
        .overlapped-layout();

        &.with-dynamic-height {
            .generic-transition(~'height');
        }

        & > ::v-deep * {
            .generic-transition(~'opacity, transform');

            &.slide-enter,
            &.slide-leave-to {
                opacity: 0;
            }
        }

        &.horizontal-align-stretch > ::v-deep * {
            justify-self: stretch;
        }
        &.horizontal-align-left > ::v-deep * {
            justify-self: start;
        }
        &.horizontal-align-center > ::v-deep * {
            justify-self: center;
        }
        &.horizontal-align-right > ::v-deep * {
            justify-self: end;
        }

        &.vertical-align-stretch > ::v-deep * {
            align-self: stretch;
        }
        &.vertical-align-top > ::v-deep * {
            align-self: start;
        }
        &.vertical-align-center > ::v-deep * {
            align-self: center;
        }
        &.vertical-align-bottom > ::v-deep * {
            align-self: end;
        }

        .slide-styles(@slide-distance) {
            &.slide-left > ::v-deep .slide-enter {
                transform: translateX(@slide-distance) !important;
            }

            &.slide-left > ::v-deep .slide-leave-to {
                transform: translateX(-@slide-distance) !important;
            }

            &.slide-right > ::v-deep .slide-enter {
                transform: translateX(-@slide-distance) !important;
            }

            &.slide-right > ::v-deep .slide-leave-to {
                transform: translateX(@slide-distance) !important;
            }
        }

        &.slide-small {
            .slide-styles(@spacing-24);
        }

        &.slide-medium {
            .slide-styles(@spacing-48);
        }

        &.slide-large {
            .slide-styles(@spacing-96);
        }

        &.transitioning {
            overflow: hidden;
        }
    }
</style>
