import axios from 'axios';
import environment from '../environment';

const regex = /http[s]?:[/\\]+[A-Za-z0-9./\\-]+eloomi\.(com|io)(?:[\\/]+platform)?[\\/]+files[\\/]+([0-9]+)[^"]*/gm;
const envUrl = getEnvUrl();

// NOTE: This is a tool for working on localhost.
// This shouldn't be used on prod due to security reasons (exposing apikey in URL).

function getEnvUrl() {
    if (environment?.apiBaseUrl != null && typeof environment?.apiBaseUrl === 'string')
        return environment.apiBaseUrl.endsWith('/') ? environment.apiBaseUrl : environment.apiBaseUrl + '/';
    return '';
}

function transformFileUrlsInResponse(data) {
    // Rewrites http://...eloomi.com/files/123123
    // to http://...eloomi.com/platform/files/123123?apikey=xxx
    if (typeof data === 'string')
        data = data.replace(regex, (_, __, p1) => {
            const newStr = `${envUrl}platform/files/${p1}?apikey=${environment.apiKey}`;
            return newStr;
        });

    if (axios.defaults.transformResponse) {
        if (Array.isArray(axios.defaults.transformResponse)) {
            return axios.defaults.transformResponse.reduce((acc, tr) => tr.call(axios.defaults, acc), data);
        } else if (axios.defaults.transformResponse != null) {
            return axios.defaults.transformResponse(data);
        }
    }

    return data;
}

function transformFileUrlsInRequest(data, headers) {
    // Rewrites back http://...eloomi.com/platform/files/123123?apikey=xxx urls to
    // http://...eloomi.com/files/123123
    if (axios.defaults.transformRequest) {
        if (Array.isArray(axios.defaults.transformRequest)) {
            data = axios.defaults.transformRequest.reduce((acc, tr) => tr(acc, headers), data);
        } else if (axios.defaults.transformRequest != null) {
            data = axios.defaults.transformRequest(data);
        }
    }

    if (!data) return data;

    if (typeof data === 'string') {
        data = data.replace(regex, (_, p1) => {
            const newStr = `https://release.eloomi.com/files/${p1}`;
            return newStr;
        });
    }

    return data;
}

export default {
    transformResponse: transformFileUrlsInResponse,
    transformRequest: transformFileUrlsInRequest,
};
