import { IFilterTerm, Operator, IFluentFilterCollection, FilterValue } from './IFluentFilterCollection';
import { CoreEntitiesFilter } from '../classes/entites-filter';

// tslint:disable:max-classes-per-file
/** Fluent builder for constructing filters with multiple rules. */
export class FluentFilterCollection implements IFluentFilterCollection, CoreEntitiesFilter {
    public toQueryStringSearch() {
        return this.build();
    }
    public toQueryStringSort() {
        return undefined;
    }

    public filters: IFilterTerm[] = [];

    /** Specify name of the property. When more than one property is specified, OR logic is applied (e.g. if 'prop1' is 1 or 'prop2' is 1) */
    public where(...property: string[]) {
        const self = this;

        const saveTerm = (term: IFilterTerm) => {
            self.filters.push(term);
        };

        return new FilterTermBuilder(this, saveTerm, ...property);
    }

    public static where(...property: string[]) {
        const newCollection = new FluentFilterCollection();

        return newCollection.where(...property);
    }

    public build(): string {
        return (
            this.filters
                .map(filterTerm => filterTerm.build())
                // tslint:disable-next-line
                .filter(builtString => builtString != null)
                .join(',')
        );
    }

    /** Combine multiple builders into one, so single filter string can be generated. */
    public static mergeBuilders(...builders: IFluentFilterCollection[]): IFluentFilterCollection {
        const validBuilders = builders.filter(builder => builder);

        if (validBuilders.length === 0) return new FluentFilterCollection(); // in case null builders/empty buidlers were passed

        return validBuilders.reduce((acc, x) => {
            acc.filters = acc.filters.concat(x.filters);
            return acc;
        }, new FluentFilterCollection());
    }

    /** Serializes filter object to string. It is URL safe. */
    public serialize() {
        if (this.filters.length === 0) return '';

        const jsonObj = JSON.stringify(this.filters);
        return encodeURI(jsonObj);
    }

    public getFilterTermForProperty(property: string, operator?: Operator) {
        const applicableTerms = this.filters
            .filter(x => x.names.includes(property) && (operator == null || x.operator === operator))
            .map(x => x.values)
            .reduce((acc, x) => [...acc, ...x], []);

        return applicableTerms;
    }

    public static deserialize(serializedContent: string): FluentFilterCollection {
        const obj = new FluentFilterCollection();

        if (serializedContent == null || serializedContent === '') return obj;

        try {
            const decoded = decodeURI(serializedContent);
            obj.filters = JSON.parse(decoded);
            return obj;
        } catch (e) {
            console.error('Unable to deserialize filter object.');
            console.error(e);

            return new FluentFilterCollection();
        }
    }
}

class FilterTermBuilder {
    private parentBuilder: FluentFilterCollection;
    private filterProperties: string[];
    private saveTermFunc: (builtTerm: IFilterTerm) => void;

    constructor(
        parentBuilder: FluentFilterCollection,
        saveTermFunc: (builtTerm: IFilterTerm) => void,
        ...filterProperties: string[]
    ) {
        this.parentBuilder = parentBuilder;
        this.filterProperties = filterProperties;
        this.saveTermFunc = saveTermFunc;
    }

    /** Specify filter for previous property. When multiple values are provided they are treated as OR operator (or equivalent of SQL value IN (...)) */
    public filter(operator: Operator, ...values: FilterValue[]) {
        const term = new FilterTerm();
        term.operator = operator;
        term.names = this.filterProperties;
        term.values = values;

        this.saveTermFunc(term);
        return this.parentBuilder;
    }
}

class FilterTerm implements IFilterTerm {
    public names: string[] = [];
    public values: FilterValue[] = [];
    public operator: Operator = Operator.IsEqualTo;

    public build(): string | null {
        if (this.names.length === 0) {
            console.warn('FilterTerm does not have any properties specified. Skipping the term.');
            return null;
        }

        const leftSide = this.names.length === 1 ? this.names[0] : `(${this.names.join('|')})`;

        if (this.values.length === 0) {
            console.warn(
                `No values were specified for ${leftSide} filter. It will be omitted in resulting filter string.`
            );
            return null;
        }

        const mappedValues = this.values.map(x => {
            if (x instanceof Date) {
                return x.toISOString();
            }

            if (typeof x === 'boolean') return x.toString();

            return x;
        });

        const rightSide = mappedValues.join('|');

        return `${leftSide}${this.operator}${rightSide}`;
    }
}

export function buildFilter(fieldName, filter, operator: Operator = Operator.Contains) {
    const builder = new FluentFilterCollection();

    return filter ? builder.where(fieldName).filter(operator, filter).build() : undefined;
}

export function buildSort(data: unknown | { key: string; ascending: boolean }) {
    if (!data) {
        return;
    }
    const sortData: { key: string; ascending: boolean } = data as { key: string; ascending: boolean };
    if (data) {
        const operator = sortData.ascending === true ? '' : Operator.Descend;
        return `${operator}${sortData.key}`;
    }
}
