import { render, staticRenderFns } from "./PlatformDeactivatedPage.vue?vue&type=template&id=9e9f6ac2&scoped=true&"
import script from "./PlatformDeactivatedPage.vue?vue&type=script&lang=ts&"
export * from "./PlatformDeactivatedPage.vue?vue&type=script&lang=ts&"
import style0 from "./PlatformDeactivatedPage.vue?vue&type=style&index=0&id=9e9f6ac2&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9e9f6ac2",
  null
  
)

export default component.exports