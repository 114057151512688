<template>
    <div class="form-group" :class="{ spacing: addSpacingBetweenItems }">
        <slot :isValid="isValid" :errors="errors"></slot>
    </div>
</template>

<script lang="ts">
    import { defineComponent, PropType, ref, onUpdated, reactive, nextTick } from 'vue';
    import { Validator } from '@/common/components/controls/input/validators/abstract-validator';

    export default defineComponent({
        props: {
            validators: {
                type: Array as PropType<Validator[]>,
                default: () => [],
            },
            validateOnChange: {
                type: Boolean as PropType<boolean>,
                default: true,
            },
            addSpacingBetweenItems: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },
        emits: ['onValidate'],
        setup(props, { emit }) {
            const isValid = ref<boolean | undefined>(undefined);
            const errors = reactive<{ [key: string]: string }>({});

            const validateFormAndSaveErrors = (validator: Validator) => {
                if (validator.valueGetter) {
                    if (validator.validate(validator.valueGetter())) {
                        if (errors[validator.fieldName!] === validator.errorMessage) {
                            delete errors[validator.fieldName!];
                        }

                        return true;
                    } else {
                        if (validator.fieldName) {
                            errors[validator.fieldName] = validator.errorMessage;
                        } else {
                            console.error(
                                '[Form.vue]',
                                'You must supply `fieldName` string when creating Validator instance in the constructor'
                            );
                        }

                        return false;
                    }
                } else {
                    console.error(
                        '[Form.vue]',
                        'You must supply `valueGetter` callback when creating Validator instance in the constructor'
                    );

                    return false;
                }
            };

            const validate = () => {
                isValid.value = props.validators.reduce(
                    (valid, validator) => validateFormAndSaveErrors(validator) && valid,
                    true as boolean
                );

                emit('onValidate', isValid.value);

                return isValid.value;
            };

            onUpdated(() => {
                if (props.validateOnChange) {
                    nextTick(() => validate());
                }
            });

            return {
                isValid,
                errors,
                validate,
            };
        },
    });
</script>

<style scoped lang="less">
    .form-group {
        width: 100%;

        &.spacing {
            display: flex;
            flex-direction: column;
            gap: 32px;
        }
    }
</style>
