import { registerPlugin } from '@capacitor/core';

export interface IosSsoLoginResult {
    callbackUrl?: string;
}

export interface SingleSignOnPlugin {
    redirectToIdentityProvider<T = unknown>(options: { url: string }): Promise<T>;
}

export const SingleSignOn = registerPlugin<SingleSignOnPlugin>('SingleSignOn');
