import { CategoryApiFactory } from '@eloomi/eloomi-topics-persona-client/1.0';

import { Category } from '@/admin/topics/models';
import { authorizeClient } from '@/api/authorize-client';
import { extractAxiosData } from '@/common/services/extract-axios-data';

const categoryApiClient = authorizeClient(CategoryApiFactory);
export function createEditCategory(category: Category) {
    return categoryApiClient.createOrUpdate(category).then(extractAxiosData) as Promise<Category>;
}
