import '@eloomi/icons/products-pages/products-and-pages-topic';
import '@eloomi/icons/products-pages/products-and-pages-billing';
import '@eloomi/icons/products-pages/products-and-pages-course';
import '@eloomi/icons/products-pages/products-and-pages-segments';
import '@eloomi/icons/products-pages/products-and-pages-playlist';
import '@eloomi/icons/actions/actions-user';
import '@eloomi/icons/actions/actions-users';

import { computed } from 'vue';

import { AdminBillingRoutes } from '@/admin/billing/routes';
import { AdminCoursesRoutes } from '@/admin/courses/routes';
import { AdminEventsRoutes } from '@/admin/events/routes';
import { AdminGroupsRoutes } from '@/admin/groups/routes';
import { AdminApiRoutes } from '@/admin/integrations/routes';
import { AdminPlaylistsRoutes } from '@/admin/playlists/routes';
import { AdminRolesRoutes } from '@/admin/roles/routes';
import { AdminSettingsRoutes } from '@/admin/settings/routes';
import { AdminSkillsRoutes } from '@/admin/skills/routes';
import { AdminTopicsRoutes } from '@/admin/topics/routes';
import { AdminUsersRoutes } from '@/admin/users/routes';
import { NavigationSidebarItem } from '@/common/components/navigation/sidebar/model';
import { useLaunchDarkly, useRoute, useTranslation } from '@/common/composables';
import { Permissions } from '@/permissions';
import { usePermissions } from '@/permissions/composables/use-permissions';
import PlanFeaturesList from '@/plan-features/plan-features-list';
import { usePlanFeaturesStore } from '@/plan-features/store';
import { Names, Paths } from '@/reporting/routes';

export function useSidebarNavigation() {
    const route = useRoute();
    const { trans } = useTranslation();
    const { hasPermission, hasAnyPermission, canAccessGroupReport } = usePermissions();

    const { launchDarklyFeaturesList } = useLaunchDarkly();

    const {
        state: { featuresList },
        getters: { checkIfFeatureEnabled },
    } = usePlanFeaturesStore();

    const reportingContent = computed<NavigationSidebarItem[]>(() => {
        return [
            {
                title: trans('spa.navigation.sections.reports.my-learning'),
                icon: 'elm-actions-user-icon',
                location: hasPermission(Permissions.Reporting) ? Names.MY_ADMIN_REPORT : Names.MY_REPORT,
                order: 1,
                access: true,
            },
            {
                title: trans('spa.navigation.sections.reports.courses'),
                icon: 'elm-products-and-pages-course-icon',
                order: 2,
                location: Names.COURSES_REPORT,
                featureName: PlanFeaturesList.COURSES,
                access: hasPermission(Permissions.Reporting),
            },
            {
                title: trans('spa.navigation.sections.reports.events'),
                icon: 'elm-date-time-calendar-icon',
                order: 3,
                location: Names.EVENTS_REPORT,
                access: hasPermission(Permissions.Reporting),
                featureName: PlanFeaturesList.EVENTS,
            },
            {
                title: trans('spa.navigation.sections.reports.users'),
                icon: 'elm-actions-users-icon',
                order: 4,
                location: Names.USERS_REPORT,
                access: hasPermission(Permissions.Reporting),
            },
            {
                title: trans('spa.navigation.sections.reports.groups'),
                pageTitle: trans('spa.navigation.sections.reports.groups-report'),
                location: hasPermission(Permissions.Reporting) ? Names.GROUPS_REPORT : Names.MY_GROUP_REPORT,
                icon: 'elm-statistics-and-hierarchy-organizational-structure-icon',
                featureName: [PlanFeaturesList.USER_PERMISSIONS, PlanFeaturesList.GROUPS],
                order: 5,
                access: canAccessGroupReport.value,
            },
            {
                title: trans('spa.navigation.sections.reports.segments'),
                icon: 'elm-products-and-pages-segments-icon',
                order: 6,
                location: Names.SEGMENT_OVERVIEW,
                featureName: PlanFeaturesList.SEGMENT_REPORTING,
                access: hasPermission(Permissions.Reporting),
            },
            {
                title: trans('spa.navigation.sections.reports.skills'),
                icon: 'elm-products-and-pages-skills-regular-icon',
                order: 7,
                location: Names.SKILLS_REPORT,
                featureName: PlanFeaturesList.SKILLS,
                access: hasPermission(Permissions.Reporting),
            },
            {
                title: trans('spa.navigation.sections.reports.playlists'),
                icon: 'elm-products-and-pages-playlist-icon',
                order: 8,
                location: Names.PLAYLISTS_REPORT,
                featureName: PlanFeaturesList.PLAYLISTS,
                access: hasPermission(Permissions.Reporting) && checkIfFeatureEnabled(PlanFeaturesList.PLAYLISTS),
            },
        ];
    });

    const adminSettingsItem = computed<NavigationSidebarItem>(() => {
        return {
            name: 'settings',
            title: trans('spa.navigation.sections.admin.settings'),
            icon: 'elm-products-and-pages-settings-icon',
            order: 6,
            access: hasAnyPermission(Permissions.AdminBranding, Permissions.AdminCustomFields, Permissions.AdminEmails),
            children: [
                {
                    name: 'branding',
                    title: trans('spa.navigation.sections.admin.branding'),
                    order: 1,
                    location: AdminSettingsRoutes.BRANDING,
                    featureName: PlanFeaturesList.BRANDING,
                },
                {
                    name: 'custom-fields',
                    title: trans('spa.navigation.sections.admin.custom-user-fields'),
                    order: 3,
                    location: AdminSettingsRoutes.CUSTOM_FIELDS,
                    featureName: PlanFeaturesList.CUSTOM_FIELDS,
                    access: hasPermission(Permissions.AdminCustomFields),
                },
                {
                    name: 'emails',
                    title: trans('spa.navigation.sections.admin.emails'),
                    order: 5,
                    location: AdminSettingsRoutes.EMAILS,
                    launchDarklyFeatureName: launchDarklyFeaturesList.ADMIN_EMAIL_SETTINGS,
                    access: hasPermission(Permissions.AdminEmails),
                },
            ],
        };
    });

    const administrationContent = computed<NavigationSidebarItem[]>(() => {
        return [
            {
                name: 'organization',
                title: trans('spa.navigation.sections.admin.organization'),
                icon: 'elm-actions-people-performance-icon',
                order: 1,
                access: hasAnyPermission(Permissions.AdminGroups, Permissions.AdminUsers),
                children: [
                    {
                        title: trans('spa.navigation.sections.admin.users'),
                        order: 1,
                        location: AdminUsersRoutes.USERS,
                        fallbackIcon: 'users',
                        access: hasPermission(Permissions.AdminUsers),
                    },
                    {
                        title: trans('spa.navigation.sections.admin.groups'),
                        order: 2,
                        location: AdminGroupsRoutes.GROUPS,
                        featureName: PlanFeaturesList.GROUPS,
                        access: hasPermission(Permissions.AdminGroups),
                    },
                    {
                        title: trans('spa.navigation.sections.admin.roles'),
                        order: 3,
                        location: AdminRolesRoutes.Roles,
                        launchDarklyFeatureName: launchDarklyFeaturesList.ADMIN_ROLES_NAVIGATION,
                        access: hasPermission(Permissions.AdminRoles),
                    },
                ],
            },
            {
                name: 'learning',
                title: trans('spa.navigation.sections.admin.learning'),
                icon: 'elm-products-and-pages-course-builder-chapter-icon',
                order: 2,
                access: hasAnyPermission(Permissions.AdminCourses, Permissions.AdminEvents, Permissions.AdminPlaylists),
                children: [
                    {
                        name: 'courses',
                        title: trans('spa.navigation.sections.admin.courses'),
                        order: 1,
                        location: AdminCoursesRoutes.COURSES_ADMINISTRATION,
                        featureName: PlanFeaturesList.COURSES,
                        access: hasPermission(Permissions.AdminCourses),
                    },
                    {
                        name: 'events',
                        title: trans('spa.navigation.sections.admin.events'),
                        order: 3,
                        location: AdminEventsRoutes.EVENTS_OVERVIEW,
                        featureName: PlanFeaturesList.EVENTS,
                        access: hasPermission(Permissions.AdminEvents),
                    },
                    {
                        name: 'playlists',
                        title: trans('spa.navigation.sections.admin.playlists'),
                        order: 4,
                        location: AdminPlaylistsRoutes.PLAYLISTS_OVERVIEW,
                        featureName: PlanFeaturesList.PLAYLISTS,
                        access: hasPermission(Permissions.AdminPlaylists),
                    },
                ],
            },
            {
                name: 'skills',
                title: trans('spa.navigation.sections.admin.skills'),
                icon: 'elm-products-and-pages-skills-regular-icon',
                order: 3,
                access: checkIfFeatureEnabled(featuresList.SKILLS) && hasPermission(Permissions.AdminSkills),
                children: [
                    {
                        name: 'business-titles',
                        title: trans('spa.navigation.sections.admin.business-titles'),
                        order: 1,
                        location: AdminSkillsRoutes.BUSINESS_TITLES,
                        featureName: PlanFeaturesList.SKILLS,
                    },
                    {
                        name: 'skills-library',
                        title: trans('spa.navigation.sections.admin.skills-library'),
                        order: 2,
                        location: AdminSkillsRoutes.SKILLS_LIBRARY,
                        featureName: PlanFeaturesList.SKILLS,
                    },
                ],
            },
            {
                title: trans('spa.navigation.sections.admin.categories-and-topics'),
                icon: 'elm-products-and-pages-topic-icon',
                order: 4,
                location: AdminTopicsRoutes.TOPICS,
                featureName: PlanFeaturesList.CATEGORIES_TOPICS,
                access: hasPermission(Permissions.AdminCategoriesTopics),
            },
            {
                title: trans('spa.navigation.sections.admin.billing'),
                icon: 'elm-products-and-pages-billing-icon',
                order: 5,
                location: AdminBillingRoutes.BILLING,
                featureName: PlanFeaturesList.BILLING,
                featureHideContent: true,
                access: hasPermission(Permissions.AdminBilling),
            },
            {
                title: trans('spa.navigation.sections.admin.integrations'),
                icon: 'elm-statistics-and-hierarchy-integration-icon',
                order: 6,
                access: hasPermission(Permissions.AdminApi),
                children: [
                    {
                        name: 'open-sesame',
                        title: trans('spa.admin.integrations.open-sesame.title'),
                        order: 1,
                        location: AdminApiRoutes.OpenSesame,
                        featureName: PlanFeaturesList.OPEN_SESAME,
                        access: checkIfFeatureEnabled(featuresList.OPEN_SESAME),
                    },
                    {
                        name: 'native-integrations',
                        title: trans('spa.navigation.sections.admin.native-integrations'),
                        order: 2,
                        location: AdminApiRoutes.NativeIntegrations,
                        featureName: PlanFeaturesList.NATIVE_INTEGRATION,
                    },
                    {
                        name: 'api-integrations',
                        title: trans('spa.navigation.sections.admin.api-integrations'),
                        order: 3,
                        location: AdminApiRoutes.ApiIntegrations,
                    },
                    {
                        name: 'single-sign-on',
                        title: trans('spa.navigation.sections.admin.single-sign-on'),
                        order: 4,
                        location: AdminApiRoutes.SingleSignOn,
                    },
                ],
            },
            adminSettingsItem.value,
        ];
    });

    const pageType = computed<'report' | 'administration'>(() => {
        return route.path.includes(Paths.REPORT) ? 'report' : 'administration';
    });

    const content = computed<NavigationSidebarItem[]>(() => {
        return pageType.value === 'report' ? reportingContent.value : administrationContent.value;
    });

    return { content, administrationContent, adminSettingsItem };
}
