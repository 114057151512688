import { queryStringWithTokenParam } from '@/authentication/services/interfaces';
import { InvalidTokenParamError } from '@/authentication/services/errors';
import { Route } from 'vue-router';

export function parseTokenParamFromRoute(route: Route): string {
    const query = route.query as queryStringWithTokenParam;
    const token = query.token;

    if (typeof token === 'string' && token.length > 0) {
        return token;
    }

    throw new InvalidTokenParamError();
}
