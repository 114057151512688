import { APP_SCHEME } from '@/common/constants/app-scheme';
import { PlatformResolutionService } from '@/common/services/platform-resolution';

export class DeepLinkRedirector {
    public platformService = new PlatformResolutionService();
    private domain = this.platformService.getPlatformDomain();

    private get baseUrl() {
        return `${APP_SCHEME}${this.domain}/app`;
    }

    public getInstantLoginLink(token: string) {
        return `${this.baseUrl}/instant-login?token=${token}`;
    }

    public getLoginBackRedirectLink(status: string | null) {
        return status ? `${this.baseUrl}/login?status=${status}` : `${this.baseUrl}/login`;
    }

    public redirect(url: string) {
        window.location.replace(url);
    }
}
