import { inject, injectable } from 'tsyringe';

import { AuthenticationServiceTokens } from '@/authentication/di-tokens';
import type { VueRouterRedirectionService } from '@/authentication/services/redirection';
import type { ToastNotificationService } from '@/common/components/toast/store';
import type { UserDetailsStoreService } from '@/common/store/user-details/store';
import { ServiceTokens } from '@/di/tokens';
import type { TranslationService } from '@/translations/store';

import { ImpersonationServiceTokens as Tokens } from '../di-tokens';
import { SelfImpersonationError } from '../errors';
import type { ImpersonationService, SessionContainer } from '../interfaces';
import type { ImpersonationStoreService } from '../store';
import { StopImpersonationSessionUseCase } from './stop-impersonation-session';

@injectable()
export class ImpersonateUserUseCase<AuthData = unknown> {
    public constructor(
        @inject(Tokens.UserImpersonationService)
        private readonly impersonationService: ImpersonationService<AuthData>,

        @inject(AuthenticationServiceTokens.SessionContainer)
        private readonly sessionContainer: SessionContainer<AuthData>,

        @inject(ServiceTokens.UserDetailsStoreService)
        private readonly userDetails: UserDetailsStoreService,

        @inject(ServiceTokens.ToastNotificationService)
        private readonly toastNotifications: ToastNotificationService,

        @inject(ServiceTokens.TranslationService)
        private readonly transService: TranslationService,

        @inject(AuthenticationServiceTokens.RedirectService)
        private readonly redirectService: VueRouterRedirectionService,

        @inject(Tokens.ImpersonationStoreService)
        private readonly impersonationStore: ImpersonationStoreService,

        @inject(StopImpersonationSessionUseCase)
        private readonly stopImpersonationUseCase: StopImpersonationSessionUseCase
    ) {}

    public async execute(userId: number) {
        const waitTime = 1000;

        this.impersonationStore.setRequestPending(true);
        try {
            const authData = await this.impersonationService.impersonateUser(userId);
            this.sessionContainer.startImpersonationSession(authData);
            await this.userDetails.forceReloadUserProfile();
            this.impersonationStore.setImpersonationState(true);

            this.toastNotifications.showNotification({
                theme: 'success',
                message: this.transService.trans()('spa.admin.impersonation.toast-success'),
                duration: waitTime,
            });

            setTimeout(() => this.redirectService.redirectToDefaultPage(true), waitTime);
        } catch (error) {
            if (error instanceof SelfImpersonationError) {
                /**
                 * Here we receive the special type of an error from the backend.
                 * It tells us that session was already destroyed since we were trying to impersonate ourselves
                 * via 3rd party impersonated admin user.
                 *
                 * So we're stopping local impersonation session without touching the backend.
                 */
                this.stopImpersonationUseCase.execute({ withRemoteStop: false });
            } else {
                this.toastNotifications.showNotification({
                    theme: 'danger',
                    message: this.transService.trans()('spa.admin.impersonation.toast-error'),
                    duration: waitTime,
                });
                throw error;
            }
        } finally {
            this.impersonationStore.setRequestPending(false);
        }
    }
}
