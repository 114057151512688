<template>
    <span class="tag" :title="title" :class="classes" data-testid="tag" @click="onClick">
        <slot name="left-icon" />
        <span
            class="tag-text"
            :class="{
                'with-left-icon': $slots['left-icon'],
                'with-right-icon': $slots['right-icon'],
            }"
        >
            <slot name="content">
                {{ title }}
            </slot>
        </span>
        <slot name="right-icon" />
    </span>
</template>
<script lang="ts">
    import { computed, defineComponent, PropType } from 'vue';

    export default defineComponent({
        name: 'RawTag',
        props: {
            title: {
                type: String,
                required: true,
            },
            variant: {
                type: String as PropType<'button' | 'topic' | 'category' | 'skill' | 'filter'>,
                default: 'topic',
            },
            size: {
                type: String as PropType<'small' | 'large' | 'medium'>,
                default: 'small',
            },
            isActive: {
                type: Boolean,
                default: false,
            },
            intention: {
                type: String as PropType<'danger' | 'neutral'>,
                default: undefined,
            },
        },

        setup(props, { emit }) {
            const classes = computed(() => {
                return [props.variant, props.size, { 'is-active': props.isActive }, props.intention];
            });

            const onClick = () => {
                emit('click');
            };

            return { classes, onClick };
        },
    });
</script>
<style lang="less" scoped>
    @small-tag-height: 30px;
    @medium-tag-height: 38px;
    @large-tag-height: 46px;

    .tag {
        display: inline-flex;
        align-items: center;
        max-width: 150px;
        background: @info-color-8;

        .info-color();
        .generic-transition(~'background-color, color');
        .tag-text {
            display: inline-block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            cursor: pointer;
            .generic-transition(color);

            &.with-left-icon {
                margin-left: @spacing-8;
            }

            &.with-right-icon {
                margin-right: @spacing-8;
            }
        }

        &.small {
            height: @small-tag-height;
            &.topic,
            &.filter {
                padding: 0 @spacing-12;
                border-radius: @border-radius-18;
            }
            &.category {
                padding: 0 @spacing-8;
                border-radius: @border-radius-6;
            }
        }

        &.large {
            height: @large-tag-height;
            .info-line();
            .font-semibold();
            &.topic,
            &.filter {
                padding: 0 @spacing-20;
                border-radius: @border-radius-23;
            }
            &.category {
                padding: 0 @spacing-16;
                border-radius: @border-radius-8;
            }
        }

        &.medium {
            height: @medium-tag-height;
            padding: 0 @spacing-16;
            border-radius: @border-radius-23;
        }

        &.skill {
            .info-line();
            .font-semibold();

            max-width: 300px;
        }

        &.skill:not(.neutral):not(.is-active) {
            &,
            &:hover {
                .dark-color();

                background-color: @bright-color;
            }

            .action-icon {
                color: @info-color-40;
                .generic-transition(~'background-color, color');
            }

            &:hover .action-icon {
                color: @info-color-80;
            }

            .tag-text {
                &:hover {
                    color: @primary-color;
                    .generic-transition(color);
                }
            }
        }

        &.button {
            color: @info-color;
            background-color: @color-info-8;

            .info-line();
            .font-semibold();

            &:hover {
                color: @primary-color;
                background-color: @primary-color-8;
            }
        }

        &.is-active,
        &:hover {
            color: @primary-color;
            background-color: @primary-color-8;
            cursor: pointer;
            &.danger {
                color: @danger-color;
                background-color: @danger-color-8;
            }

            &.skill {
                color: @bright-color;
                // Hardcoded color should be replaced with DS colors, once it's updated.
                // Discussion about that is here: https://zpl.io/2pW17e8
                background-color: #0075ff; /* stylelint-disable-line */
            }

            &.lock {
                cursor: default;
            }

            &.filter {
                border-color: @primary-color-8;
            }

            &.lock .action-icon {
                color: @bright-color-40;
            }
        }

        &.is-active {
            &.filter {
                border-color: @color-primary-20;
            }
        }
    }
</style>
