import { CreateUserRequest, UserAdminPersonaApiFactory } from '@eloomi/eloomi-users-persona-client/1.0';
import { AxiosError } from 'axios';

import { authorizeClient } from '@/api/authorize-client';
import { extractAxiosData } from '@/common/services/extract-axios-data';

import { UserDetails } from '../models/user-details';

const userAdminPersonaApiClient = authorizeClient(UserAdminPersonaApiFactory);

export interface UserLimitResponse {
    limitExceeded: boolean;
    limitParams?: {
        reason: string;
        return_url: string;
        max_allowed: string;
    };
}

export const convertDetailsToCreateUserCommand = (
    details: UserDetails,
    isCustomFieldsFeatureEnabled: boolean,
    password?: string
): CreateUserRequest => {
    return {
        email: details.email || null,
        first_name: details.first_name,
        last_name: details.last_name,
        job_title: details.job_title,
        image: details.image?.blobVirtualLink || details.image?.url || null,
        infinite_role_key: details.infinite_role_key,
        custom_data: isCustomFieldsFeatureEnabled ? details.custom_data : undefined,
        account_id: details.account_id,
        ...(password ? { password } : {}),
    };
};

export function adminCreateUser(command: CreateUserRequest) {
    return userAdminPersonaApiClient.create(command).then(extractAxiosData);
}

export const adminVerifyUserLimit = async () => {
    try {
        await userAdminPersonaApiClient.verifyLimit().then(extractAxiosData);
    } catch (error) {
        const response = (error as AxiosError).response;
        if (response && response.status === 400 && response.data?.error_code === 'platform_quota_exceeded') {
            return {
                limitExceeded: true,
                limitParams: {
                    reason: response.data.reason as string,
                    return_url: response.data.return_url as string,
                    max_allowed: response.data.max_allowed as string,
                },
            };
        } else {
            return { limitExceeded: undefined };
        }
    }
    return { limitExceeded: false };
};
