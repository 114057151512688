<template>
    <div class="dropdown-message">
        {{ message }}
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'DropdownMessage',
        props: {
            message: { type: String },
        },
    });
</script>

<style scoped lang="less">
    .dropdown-message {
        display: flex;
        align-items: center;
        height: 48px;
        padding: 0 @spacing-16;
        .text-line();
        .info-color();
    }
</style>
