export const Permissions = Object.freeze({
    AdminApi: 'admin-api',
    AdminBilling: 'admin-billing',
    AdminBranding: 'admin-branding',
    AdminCategoriesTopics: 'admin-categories-topics',
    AdminContentStore: 'admin-content-store',
    AdminCourses: 'admin-courses',
    AdminCustomFields: 'admin-custom-fields',
    AdminEmails: 'admin-emails',
    AdminEvents: 'admin-events',
    AdminGroups: 'admin-groups',
    AdminNativeIntegrations: 'admin-native-integrations',
    AdminPlaylists: 'admin-playlists',
    AdminRoles: 'admin-roles',
    AdminSkills: 'admin-skills',
    AdminUsers: 'admin-users',
    Reporting: 'reporting',
    Unknown: 'unknown',
} as const);

export type Permission = (typeof Permissions)[keyof typeof Permissions];

export interface RouteWithPermissions {
    path: string;
    requiredPermissions?: Permission[];
}

export function isAnyPermissionRequiredForRoute(route: RouteWithPermissions): route is Required<RouteWithPermissions> {
    if (route.requiredPermissions === undefined || route.requiredPermissions.length === 0) {
        return false;
    }
    return true;
}

export class UserWithPermissions {
    private readonly permissions: Set<Permission>;

    public constructor(permissions: Permission[]) {
        this.permissions = new Set(permissions);
    }

    public hasPermission(permission: Permission) {
        return this.permissions.has(permission);
    }

    public hasAccessToRoute(route: RouteWithPermissions) {
        return isAnyPermissionRequiredForRoute(route)
            ? route.requiredPermissions.every(permission => this.hasPermission(permission))
            : true;
    }

    public get isAnonymous() {
        return false;
    }
}

export class AnonymousUser extends UserWithPermissions {
    public constructor() {
        super([]);
    }

    public get isAnonymous() {
        return true;
    }
}
