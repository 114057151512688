import { makeLogger } from '@/common/services';

import { SessionRenewalFailed } from '../services/errors';
import type { JWT } from '../services/interfaces';
import type { JwtApiClient, SessionRefresher } from './interfaces';
import type { SessionJWTStore } from './store';

export class SessionRefresherJWT implements SessionRefresher {
    private refreshTimer?: number;
    private failHandler?: (e: unknown) => void;
    private readonly log = makeLogger('session-refresher');

    public constructor(
        private readonly apiClient: JwtApiClient,
        private readonly sessionStore: SessionJWTStore,
        private readonly refreshPeriod: number
    ) {
        this.scheduleRefresh();
    }

    public setFailHandler(handler: (e: unknown) => void) {
        this.failHandler = handler;
    }

    public destroy() {
        if (this.refreshTimer !== undefined) {
            window.clearTimeout(this.refreshTimer);
            this.refreshTimer = undefined;
        }
    }

    public refresh() {
        this.destroy();
        this.scheduleRefresh(0);
    }

    private scheduleRefresh(period = this.refreshPeriod) {
        this.log('session refresh is scheduled in %o minutes', period / 1000 / 60);
        this.refreshTimer = window.setTimeout(async () => {
            await this.refreshToken();
        }, period);
    }

    private async refreshToken() {
        try {
            // eslint-disable-next-line unicorn/no-await-expression-member
            const jwt = (await this.apiClient.getToken()).data as JWT;
            this.sessionStore.write(jwt);

            if (this.refreshTimer !== undefined) {
                this.scheduleRefresh();
            }
        } catch {
            this.destroy();

            if (this.failHandler !== undefined) {
                this.failHandler(new SessionRenewalFailed());
            }
        }
    }
}
