import { RouteConfig } from 'vue-router';
import { mobileIsUnsupportedFlag } from '@/common/services/mobile-fallback';
import { checkIfRouteAvailableByPlanFeatures } from '@/plan-features/routes';
import PlanFeaturesList from '@/plan-features/plan-features-list';
import { Permissions } from '@/permissions';

const topicsAdministrationPage = () => import('./pages/TopicsAdministrationPage.vue');

export const enum AdminTopicsRoutes {
    TOPICS = 'topics',
}

const routes: RouteConfig[] = [
    {
        path: AdminTopicsRoutes.TOPICS,
        name: AdminTopicsRoutes.TOPICS,
        meta: {
            title: 'Topics Administration',
            ...mobileIsUnsupportedFlag,
            showNavigationSidebar: true,
            requiredPermissions: [Permissions.AdminCategoriesTopics],
        },
        component: topicsAdministrationPage,
        beforeEnter: checkIfRouteAvailableByPlanFeatures(PlanFeaturesList.CATEGORIES_TOPICS),
    },
];

export default routes;
