<template>
    <portal to="modal-host">
        <Fade>
            <div
                v-if="isVisible"
                class="mobile-drawer-layout"
                :class="classes"
                @click.self="handleCloseClick"
                @touchend.self="handleCloseClick"
                @touchmove="handleTouchMove"
            >
                <div
                    v-if="isClosable"
                    class="mobile-drawer-header"
                    @click.self="handleCloseClick"
                    @touchend.self="handleCloseClick"
                >
                    <elm-actions-close-bold-icon class="close-button" size="18" @click="handleCloseClick" />
                </div>

                <transition name="slide-up">
                    <div
                        class="scrollable-wrapper"
                        :class="{ 'with-bottom-padding': isKeyboardVisible }"
                        @click="onContentClick"
                    >
                        <slot name="header" />

                        <div
                            ref="drawer"
                            class="mobile-drawer"
                            :class="{ 'no-footer': !hasFooter, 'no-padding': noPadding }"
                        >
                            <slot />

                            <div v-if="hasFooter" class="mobile-drawer-footer">
                                <slot name="footer" />
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </Fade>
    </portal>
</template>

<script lang="ts">
    import { Component, Prop, Emit, Ref, Vue, Watch } from 'vue-property-decorator';
    import Fade from '@/common/components/transitions/FadeTransition.vue';
    import { backButtonService } from '@/common/services/mobile-fallback/native-back-button-service';
    import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
    import { detectOnscreenKeyboard } from '@/common/services';
    import '@eloomi/icons/actions/actions-close-bold';

    @Component({
        components: {
            Fade,
        },
    })
    export default class CoreMobileBottomDrawer extends Vue {
        @Prop({ default: false, type: Boolean }) public isVisible!: boolean;
        @Prop({ default: true, type: Boolean }) public preventTouchScroll!: boolean;
        @Prop({ default: false, type: Boolean }) public noPadding!: boolean;
        @Prop({ default: false, type: Boolean }) public isFullscreen!: boolean;
        @Prop({ default: true, type: Boolean }) public isClosable!: boolean;
        @Prop({ default: '', type: String }) public className!: string;

        public isKeyboardVisible = false;

        @Ref() readonly drawer!: HTMLDivElement;

        private nativeBackButtonActionCleanup: (() => void) | null = null;
        private keyboardVisibilityChangedCleanup: (() => void) | null = null;
        private bodyScrollDisableCleanup: (() => void) | null = null;

        public mounted() {
            this.handleIsVisibleChange();
        }

        public created() {
            this.keyboardVisibilityChangedCleanup = detectOnscreenKeyboard(isKeyboardVisible => {
                this.isKeyboardVisible = isKeyboardVisible;
            });
        }

        public beforeDestroy() {
            if (this.keyboardVisibilityChangedCleanup) {
                this.keyboardVisibilityChangedCleanup();
            }
            if (this.nativeBackButtonActionCleanup) {
                this.nativeBackButtonActionCleanup();
            }
            if (this.bodyScrollDisableCleanup) {
                this.bodyScrollDisableCleanup();
            }
        }

        @Watch('isVisible')
        public handleIsVisibleChange() {
            if (this.isVisible) {
                this.$nextTick(() => {
                    this.$nextTick(() => {
                        const drawer = this.drawer;
                        disableBodyScroll(drawer);
                        this.bodyScrollDisableCleanup = () => enableBodyScroll(drawer);
                    });
                });
                this.nativeBackButtonActionCleanup = backButtonService.pushAction(() => {
                    this.handleCloseClick();
                });
            } else {
                if (this.bodyScrollDisableCleanup) {
                    this.bodyScrollDisableCleanup();
                    this.bodyScrollDisableCleanup = null;
                }
                if (this.nativeBackButtonActionCleanup) {
                    this.nativeBackButtonActionCleanup();
                    this.nativeBackButtonActionCleanup = null;
                }
            }
        }

        public get hasFooter() {
            return Boolean(this.$slots['footer']);
        }

        public get hasHeader() {
            return Boolean(this.$slots['header']);
        }

        public get classes() {
            return {
                'mobile-drawer-layout__fullscreen': this.isFullscreen,
                'mobile-drawer-layout__with-header-slot': this.hasHeader,
                'mobile-drawer-layout__closable': this.isClosable,
                [this.className]: Boolean(this.className),
            };
        }

        public handleCloseClick() {
            if (this.isClosable) {
                this.emitCloseEvent();
            }
        }

        @Emit('close')
        public emitCloseEvent() {
            return;
        }

        public handleTouchMove(event) {
            if (this.preventTouchScroll) {
                event.preventDefault();
            }
        }

        @Emit('content-click')
        public onContentClick() {
            return;
        }
    }
</script>

<style lang="less" scoped>
    @overlay-bg-color: rgba(1, 7, 17, 0.64);
    @shadower-height: 24px;
    @shadower-start-color: @bright-color;
    @shadower-end-color: @bright-color-8;
    @top-layer-z-index: 1;

    .slide-up-enter-active,
    .slide-up-leave-active {
        .generic-transition(transform);
    }

    .slide-up-enter,
    .slide-up-leave-to {
        transform: translate(0, 40px);
    }

    .mobile-drawer-layout {
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 100%;
        height: 100%;
        background-color: @overlay-bg-color;

        &__fullscreen {
            z-index: @header-z-index + 1;

            .scrollable-wrapper {
                align-items: center;
                height: 100vh;
                border-radius: 0;
            }

            .mobile-drawer {
                display: flex;
                flex: auto;
                flex-direction: column;
                justify-content: space-between;
            }
        }

        &__with-header-slot {
            .mobile-drawer {
                z-index: @top-layer-z-index;
                margin-top: -@border-radius-16;
                border-top-left-radius: @border-radius-16;
                border-top-right-radius: @border-radius-16;
            }
        }

        &__closable {
            z-index: @header-z-index + 1;

            .scrollable-wrapper {
                border-top-left-radius: @border-radius-8;
                border-top-right-radius: @border-radius-8;
            }
        }
    }

    .scrollable-wrapper {
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        border-top-left-radius: @border-radius-8;
        border-top-right-radius: @border-radius-8;
    }

    .mobile-drawer {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: @spacing-32 @spacing-24 0 @spacing-24;
        overflow-y: auto;
        background-color: @bright-color;

        &.no-footer {
            padding-bottom: @spacing-16;
        }

        &.no-padding {
            padding: 0;
        }
    }

    .mobile-drawer-header {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: flex-end;
        height: @core-header-height-mobile;
    }

    .mobile-drawer-footer {
        position: sticky;
        bottom: 0;
        display: grid;
        grid-gap: @spacing-8;
        grid-template: auto / 100%;
        padding: @spacing-24 0;
        background-color: @bright-color;

        &::before {
            position: absolute;
            top: -@shadower-height;
            left: 0;
            width: 100%;
            height: @shadower-height;
            background: linear-gradient(0deg, @shadower-start-color 0%, @shadower-end-color 100%);
            content: '';
            pointer-events: none;
        }
    }

    .close-button {
        padding: @spacing-16 @spacing-20;
        color: @bright-color;
        .generic-transition;

        &:hover {
            color: @primary-color;
        }
    }

    body[data-os='ios'] .scrollable-wrapper {
        & > :last-child::after {
            flex-shrink: 0;
            height: 0;
            transition: @generic-transition;
            content: '';
        }

        &.with-bottom-padding > :last-child::after {
            height: var(--keyboard-height);
        }
    }

    @media (orientation: landscape) {
        .mobile-drawer-footer {
            place-items: center;

            ::v-deep elm-button {
                width: 50%;
            }
        }
    }
</style>
