import { FrequencyInterval } from '@eloomi/eloomi-billing-persona-client/1.0';

export interface SubscriptionDetails {
    currency: string | CurrencyWithSymbol;
    next_payment_date: Date;
    plan: string;
    price_per_user: number;
    users: number;
    frequency: string;
    color: string;
    total_price: number;
    is_free_plan: boolean;
}

export interface Plan {
    name: Plans;
    icon: string;
    color: string;
    active: boolean;
    users_included: number;
    free_courses_included: number;
    free_users_included: number;
}
export type Plans = 'Play' | 'Learn' | 'Engage' | 'Develop';
export type PaymentFrequency = 'Monthly' | 'Quarterly' | 'Annually';
export type UsersAmount = '50' | '100' | '250' | '500' | '1000' | '2500' | '5000' | '10000';
export type Currency = 'DKK' | 'EUR' | 'USD' | 'GBP';
export interface CurrencyWithSymbol {
    code: string;
    symbol: string;
}
export interface FrequencyAndDiscount {
    name: FrequencyInterval;
    discount: number;
}
export interface PlansResponse {
    prices: {
        [key in Currency]: {
            // tslint:disable-next-line:no-shadowed-variable
            [key in Plans]: {
                // tslint:disable-next-line:no-shadowed-variable
                [key in UsersAmount]: {
                    // tslint:disable-next-line:no-shadowed-variable
                    [key in PaymentFrequency]: {
                        price_total_per_month: number;
                        price_per_user_per_month: number;
                        discount_percentage: number;
                        price_total_per_billing_cycle: number;
                    };
                };
            };
        };
    };
    currencies: CurrencyWithSymbol[];
    has_account: boolean;
    current_plan: Plans;
    current_currency: null | string;
    current_frequency: null | string;
    current_size: null | UsersAmount;
    plans: Plan[];
}

export interface AccountDetails {
    company_name: string;
    address: string;
    city: string;
    zip: string;
    country: string;
    state: string | null;
    vat: string;
    recipient_name: string;
    recipient_email: string;
}

export interface GetCardLinkPayload {
    return_url: string;
    currency: Currency;
    frequency: PaymentFrequency;
    size: UsersAmount;
    plan: Plans;
}

export interface GetCardLinkResponse {
    is_redirect_required: boolean;
    redirect_url: string;
}

export interface PutSubscriptionPayload {
    plan: Plans;
    size: UsersAmount;
    frequency: PaymentFrequency;
    currency: Currency;
    schedule_for_next: boolean;
}

export interface GetCardLinkEditResponse {
    redirect_url: string;
    is_redirect_required: boolean;
}

export interface DowngradeRestriction {
    type: 'general_user_max' | 'general_course_max';
    current_count: number;
    scheduled_count: number;
}
export interface DowngradeRestrictionResponse {
    error?: string;
    reason?: string;
    scheduled_restrictions_details: DowngradeRestriction[];
}
export interface DeleteSubscriptionResponse {
    last_date_of_subscription: string;
}

export enum BillingAction {
    ChangeSubscription = 'changeSubscription',
    EditCard = 'editCard',
}
