import { useRouter } from '@/common/composables/useRouter';
import { getCurrentInstance, reactive } from 'vue';
import { Route } from 'vue-router';

let currentRoute: Route;

export function useRoute() {
    const router = useRouter();

    if (!currentRoute) {
        const vm = getCurrentInstance();
        if (!vm) {
            throw new Error('You must use this function within the "setup()" method');
        }

        currentRoute = reactive({ ...vm.proxy.$route });
        router.afterEach(to => Object.assign(currentRoute, to));
    }

    return currentRoute;
}
