export interface ModalControllerParams<Data> {
    onOpen?: (modalAction: string) => void;
    onClose?: (modalAction: string) => void;
    modalAction?: string;
    modalActionGetter?: (data: Data | null) => string;
}

/**
 *  Helper for managing modal visibility state.
 */
export class ModalController<Data = unknown> {
    private modalOpen = false;

    public data: Data | null;
    public isMounted = false;
    public resolveClosePromise: () => void;

    private modalAction: string | null = null;
    private modalActionGetter: ((data: Data | null) => string) | null = null;
    private onOpenHandler: ((modalAction: string) => void) | null = null;
    private onCloseHandler: ((modalAction: string) => void) | null = null;

    constructor(params?: ModalControllerParams<Data>) {
        this.onOpenHandler = params?.onOpen ?? null;
        this.onCloseHandler = params?.onClose ?? null;
        this.modalAction = params?.modalAction ?? null;
        this.modalActionGetter = params?.modalActionGetter ?? null;

        this.data = null;
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.resolveClosePromise = () => {};
    }

    public getData(fallback: Data): Data {
        return this.data === null ? fallback : this.data;
    }

    public useData<RetVal>(callback: (data: Data) => RetVal, fallback?: RetVal): RetVal | undefined {
        if (this.isOpen && this.data !== null) {
            return callback(this.data);
        }
        if (fallback === undefined) {
            console.warn(
                'modalController.useData() is called without fallback provided, but modal is not opened or data is unavailable'
            );
        }
        return fallback;
    }

    public get isOpen() {
        return this.modalOpen;
    }

    public open(data?: Data) {
        if (this.isOpen) {
            console.warn('modalController.open() is called, when modal is still open!');
        }

        if (this.onOpenHandler) {
            this.onOpenHandler(this.modalActionName);
        }

        this.isMounted = true;
        this.modalOpen = true;

        if (data !== undefined) {
            this.data = data;
        }
    }

    public close() {
        this.modalOpen = false;

        if (this.onCloseHandler) {
            this.onCloseHandler(this.modalActionName);
        }

        return new Promise(resolve => {
            this.resolveClosePromise = () => resolve(null);
        });
    }

    public unmount() {
        this.isMounted = false;
        this.data = null;
        this.resolveClosePromise();
    }

    public get handlers() {
        return {
            'request-close': this.close.bind(this),
            close: this.unmount.bind(this),
        };
    }

    private get modalActionName() {
        if (this.modalActionGetter) {
            return this.modalActionGetter(this.data);
        } else {
            return this.modalAction ?? '';
        }
    }
}

export interface DefaultModalData<T> {
    title: string;
    description?: string;
    data: T;
}
