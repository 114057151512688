import { Component, Mixins } from 'vue-property-decorator';
import { MobileModeStoreMixin } from '@/common/store/mobile-mode';
import { getMaxWidthMedia } from '@/common/services';
import { MinWidthViewportBreakpoints, BreakpointCode } from '@/common/constants/breakpoints';

type MQListener = (e: MediaQueryListEvent) => void;

function createMobileMediaQueryList() {
    return window.matchMedia(getMaxWidthMedia(768));
}

export function getIsMobile(): boolean {
    const mql = createMobileMediaQueryList();
    return mql.matches;
}

@Component({})
export default class TrackMobileModeMixin extends Mixins(MobileModeStoreMixin) {
    public mql = createMobileMediaQueryList();
    private mediaQueries: { listener: MQListener | null; mql: MediaQueryList }[] = [];

    public mounted() {
        this.initMobileViewportListener();
        this.initViewportListeners();
    }

    private initMobileViewportListener() {
        if (this.mql.addEventListener) {
            this.mql.addEventListener('change', this.handleMqlChangeEvent);
        }
        this.setIsMobile(this.mql.matches);
    }

    private dropMobileViewportListener() {
        if (this.mql.removeEventListener) {
            this.mql.removeEventListener('change', this.handleMqlChangeEvent);
        }
    }

    public destroyed() {
        this.dropMobileViewportListener();
        this.dropViewportListeners();
    }

    private handleMqlChangeEvent(event: MediaQueryListEvent) {
        this.setIsMobile(event.matches);
    }

    private initViewportListeners() {
        this.mediaQueries = Object.entries(MinWidthViewportBreakpoints).map(([code, width]) => {
            const breakpoint = code as BreakpointCode;
            const mql = window.matchMedia(`(min-width: ${width}px)`);

            this.setBreakpoint({ breakpoint, state: mql.matches });

            let listener: ((event: MediaQueryListEvent) => any) | null = null;

            if (mql.addEventListener) {
                listener = (event: MediaQueryListEvent) => {
                    this.setBreakpoint({ breakpoint, state: event.matches });
                };
                mql.addEventListener('change', listener);
            }

            return { listener, mql };
        });
    }

    private dropViewportListeners() {
        while (this.mediaQueries.length > 0) {
            const { mql, listener } = this.mediaQueries.pop()!;
            if (listener) {
                mql.removeEventListener('change', listener);
            }
        }
    }
}
