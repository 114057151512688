import { UserApiFactory } from '@eloomi/eloomi-learning-persona-client/1.0';

import { authorizeClient } from '@/api/authorize-client';
import { extractAxiosData } from '@/common/services/extract-axios-data';

const userLearningApiFactory = authorizeClient(UserApiFactory);

export function getUserTopics() {
    return userLearningApiFactory.getUserTopics().then(extractAxiosData);
}
