import { AxiosInstance } from 'axios';
import VueRouter, { NavigationGuardNext, Route } from 'vue-router';

import { AuthenticationServiceTokens } from '@/authentication/di-tokens';
import { makeLogger } from '@/common/services';
import type { PlatformResolutionService } from '@/common/services/platform-resolution';
import { baseContainer } from '@/di/container';
import { ServiceTokens } from '@/di/tokens';
import { ImpersonationSessionStoreSyncUseCase } from '@/impersonation/use-cases/handle-session-restore';

import {
    AxiosAccessDeniedErrorHandler,
    AxiosSessionInjector,
    JWT,
    VueRouterRedirectionService,
    VueRouteTransitionGuardService,
} from '../services';
import { SessionContainer } from '../session/session-container';
import { HandleHttpForbiddenStatusUseCase } from '../use-cases/handle-http-forbidden';

export function enableInfiniteAuthMiddleware(httpClient: AxiosInstance) {
    const router = baseContainer.resolve<VueRouter>(ServiceTokens.VueRouter);
    const platformService = baseContainer.resolve<PlatformResolutionService>(ServiceTokens.PlatformResolutionService);
    const sessionContainer = baseContainer.resolve<SessionContainer<JWT>>(AuthenticationServiceTokens.SessionContainer);
    const log = makeLogger('auth-middleware');

    const sessionInjector = new AxiosSessionInjector(httpClient, sessionContainer);
    const redirectService = new VueRouterRedirectionService(router);
    const accessDeniedErrorHandler = new AxiosAccessDeniedErrorHandler(httpClient);
    const impersonationSessionStoreSync = baseContainer.resolve(ImpersonationSessionStoreSyncUseCase);

    sessionInjector.install();

    /**
     * For each of the application pages that requires authorization
     * we want to check if a user is allowed to visit it.
     */
    router.beforeEach((to, _from, next) => {
        checkIfNavigationAllowed(to, next);
    });

    /**
     * React on session renewal / restoration failure
     */
    sessionContainer.setFailHandler(error => {
        log('session restoration / renewal: %s', error);

        // Drop current session if renewal failed to prevent infinite loops
        sessionContainer.stopActiveSession();

        // Sync store with the session status
        impersonationSessionStoreSync.execute();

        // Have to wait a bit until router will switch to final route.
        // Can't use router.onReady since it won't be called if app is broken due lots of 403.
        setTimeout(() => {
            if (redirectService.isAuthRequiredForCurrentRoute()) {
                log('going to handle error: redirecting to login page...');
                platformService.forceSsoAutomaticLoginEnabled(false);
                redirectService.redirectToLoginPage(true);
            } else {
                log('will not handle a session error: auth is not required on the route');
            }
        }, 200);
    });

    /**
     * Try restore an authentication session from the local storage.
     */
    sessionContainer.restoreSession();

    /**
     * Sync store with the session status
     */
    impersonationSessionStoreSync.execute();

    /**
     * Install 403 handler for http-client (old providers only) that will try refreshing the user session.
     */
    accessDeniedErrorHandler.install(() => {
        const handler = baseContainer.resolve<HandleHttpForbiddenStatusUseCase>(
            AuthenticationServiceTokens.HandleHttpForbiddenStatusUseCase
        );
        return handler.execute();
    });
}

/**
 * For each of the application pages that requires authorization
 * we want to check if a user is allowed to visit it.
 */
function checkIfNavigationAllowed(routeTo: Route, goNext: NavigationGuardNext) {
    const sessionContainer = baseContainer.resolve<SessionContainer<JWT>>(AuthenticationServiceTokens.SessionContainer);
    const guardService = new VueRouteTransitionGuardService(routeTo, goNext);

    if (guardService.isAuthRequiredForTargetRoute() && !sessionContainer.isLoggedIn()) {
        guardService.changeTransitionToLoginPage();
        return;
    }

    if (guardService.isTargetRouteForUnauthorizedOnly() && sessionContainer.isLoggedIn()) {
        guardService.changeTransitionToDefaultPage();
        return;
    }

    guardService.acceptCurrentTransition();
}
