export type FilterValue = string | number | Date | boolean;

/** Fluent builder for constructing filters with multiple rules. */
export interface IFluentFilterCollection {
    filters: IFilterTerm[];
    build(): string;
}

export interface IFilterTerm {
    names: string[];
    values: FilterValue[];
    operator: Operator;

    build(): string | null;
}

export enum Operator {
    IsEqualTo = '==',
    IsNotEqualTo = '!=',
    GreaterThan = '>',
    GreaterThanOrEqual = '>=',
    LessThan = '<',
    LessThanOrEqual = '<=',
    Contains = '@=*',
    Have = '@=',
    Descend = '-',
    // Add Biarity/Sieve operators as needed
}
