<template>
    <div class="mobile-header-wrapper">
        <div class="corner left-corner" />
        <div class="corner right-corner" />
        <div class="mobile-header">
            <div class="header-item left">
                <slot name="left">
                    <GoBackButton v-if="withBackButton">
                        <elm-arrows-arrow-left-bold-icon class="header-arrow" size="20" />
                    </GoBackButton>
                </slot>
            </div>
            <div class="center header-item">
                <slot v-if="withTitle" name="center">
                    <h4 v-if="title" class="header-title">{{ title }}</h4>
                    <router-link v-else class="logo-link" to="/">
                        <img class="logo" alt="logo of the company" :src="imageUrl" />
                    </router-link>
                </slot>
            </div>
            <div class="header-item right">
                <slot name="right">
                    <NotificationTrigger
                        v-if="withNotifications"
                        :newNotifications="newNotificationsCount"
                        @click="openNotificationsDrawer"
                    />
                </slot>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import '@eloomi/icons/arrows/arrows-arrow-left-bold';

    import { Component, Mixins, Prop } from 'vue-property-decorator';

    import eloomiLogoImageUrl from '@/assets/img/eloomi-logo.png';
    import GoBackButton from '@/common/components/routing/GoBackButton.vue';
    import { BrandingMappedStoreMixin } from '@/common/store/branding/store';
    import NotificationTrigger from '@/notifications/components/NotificationTrigger.vue';
    import { NotificationsMappedStore } from '@/notifications/store';

    @Component({ components: { GoBackButton, NotificationTrigger } })
    export default class MobileHeader extends Mixins(BrandingMappedStoreMixin, NotificationsMappedStore) {
        @Prop() title?: string;
        @Prop({ default: false, type: Boolean }) withBackButton!: boolean;
        @Prop({ default: false, type: Boolean }) withNotifications!: boolean;
        @Prop({ default: true, type: Boolean }) withTitle!: boolean;

        public get imageUrl() {
            return this.brandingState?.logo_url || eloomiLogoImageUrl;
        }
    }
</script>

<style scoped lang="less">
    .mobile-header {
        display: flex;
        width: 100%;
        height: @core-header-height-mobile;
        padding: @spacing-16;
        padding-top: calc(@spacing-16 + var(--top-notch-header-padding, 0px));
        background-color: @branding-color;
    }

    .header-title {
        .h4-bright;

        white-space: nowrap;
        text-align: center;
    }

    .header-arrow {
        color: @branding-text-color;
    }

    .header-item {
        display: flex;
        flex: 1;
        align-items: center;
    }

    .left {
        justify-content: flex-start;
    }

    .center {
        justify-content: center;

        .logo-link {
            max-height: 32px;
        }

        .logo {
            max-width: 50vw;
            max-height: 32px;
            cursor: pointer;
        }
    }

    .right {
        justify-content: flex-end;
    }

    .corner {
        position: absolute;
        bottom: -24px;
        width: 24px;
        height: 24px;
        overflow: hidden;

        &::before {
            position: absolute;
            top: 0;
            width: 100px;
            height: 100px;
            border-radius: @border-radius-12;
            box-shadow: 0 0 0 10px @branding-color;
            content: '';
        }
    }

    .left-corner {
        left: 0;

        &::before {
            left: 0;
        }
    }

    .right-corner {
        right: 0;

        &::before {
            left: -76px;
        }
    }
</style>
