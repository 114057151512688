const lastLocation = { x: 0, y: 0 };

import { throttle } from 'lodash-es';

export function getViewportMouseLocation(): { x: number; y: number } {
    return { ...lastLocation };
}

const debouncedEventHandler = throttle(e => {
    lastLocation.x = e.clientX;
    lastLocation.y = e.clientY;
}, 100);

document.addEventListener('mousemove', debouncedEventHandler);
