import { UserActivationApiFactory, UserForgotPasswordApiFactory } from '@eloomi/eloomi-users-external-client/1.0';
import { JwtApiFactory } from '@eloomi/eloomi-users-persona-client/1.0';
import { DependencyContainer, Lifecycle } from 'tsyringe';

import { authorizeClient, initClientWithoutAuth } from '@/api/authorize-client';
import { LoginWithTemporaryPasswordUseCase } from '@/authentication/use-cases/login-with-temporary-password';

import { AuthenticationServiceTokens as Tokens } from './di-tokens';
import {
    ActivateUserService,
    ForgotPasswordService,
    JWTAuthenticationService,
    ResetPasswordService,
    VueRouterRedirectionService,
} from './services';
import { ResetSessionDataService } from './services/reset-session-data';
import { SessionContainer } from './session/session-container';
import { AuthenticationJWTSessionFabric } from './session/session-fabric';
import { AuthFlowStoreServiceFactory } from './store';
import {
    ActivateUserUseCase,
    ChoosePlatformUseCase,
    ForgotPasswordUseCase,
    HandleDeepLinkUseCase,
    LoginSsoUseCase,
    LoginUseCase,
    LogoutUseCase,
    ResetPasswordUseCase,
} from './use-cases';
import { HandleHttpForbiddenStatusUseCase } from './use-cases/handle-http-forbidden';

export { AuthenticationServiceTokens as Tokens } from './di-tokens';

export function registerAuthenticationServices(container: DependencyContainer): DependencyContainer {
    container.register(Tokens.AuthenticationService, { useClass: JWTAuthenticationService });
    container.register(Tokens.ResetSessionDataService, { useClass: ResetSessionDataService });
    container.register(Tokens.RedirectService, { useClass: VueRouterRedirectionService });

    // This one will use regular or impersonated JWT depending on the mode
    container.register(Tokens.SessionApiClient, { useFactory: () => authorizeClient(JwtApiFactory) });

    // This one will strictly use regular JWT, not impersonated one
    container.register(Tokens.RegularSessionApiClient, { useFactory: () => authorizeClient(JwtApiFactory, 'regular') });

    container.register(
        Tokens.AuthenticationSessionFabric,
        { useClass: AuthenticationJWTSessionFabric },
        { lifecycle: Lifecycle.Singleton }
    );

    container.register(Tokens.SessionContainer, { useClass: SessionContainer }, { lifecycle: Lifecycle.Singleton });

    container.register(Tokens.UserActivationApiClient, {
        useFactory: () => initClientWithoutAuth(UserActivationApiFactory),
    });

    container.register(Tokens.ForgotPasswordApiClient, {
        useFactory: () => initClientWithoutAuth(UserForgotPasswordApiFactory),
    });

    container.register(
        Tokens.HandleHttpForbiddenStatusUseCase,
        { useClass: HandleHttpForbiddenStatusUseCase },
        { lifecycle: Lifecycle.Singleton }
    );

    container.register(
        Tokens.ActivateUserService,
        { useClass: ActivateUserService },
        { lifecycle: Lifecycle.Singleton }
    );

    container.register(
        Tokens.ForgotPasswordService,
        { useClass: ForgotPasswordService },
        { lifecycle: Lifecycle.Singleton }
    );

    container.register(
        Tokens.ResetPasswordService,
        { useClass: ResetPasswordService },
        { lifecycle: Lifecycle.Singleton }
    );

    /**
     * Sometimes we can register use cases with tokens since it maybe used by other use cases via interfaces
     */
    container.register(Tokens.LoginUseCase, { useClass: LoginUseCase });

    container.register(Tokens.LogoutUseCase, { useClass: LogoutUseCase });

    container.register(Tokens.AuthFlowStoreService, { useFactory: AuthFlowStoreServiceFactory });

    /**
     * We're not using tokens here to simplify work with use cases (entry code for clients).
     */
    container.register(LoginUseCase, { useClass: LoginUseCase });
    container.register(LoginWithTemporaryPasswordUseCase, { useClass: LoginWithTemporaryPasswordUseCase });
    container.register(LogoutUseCase, { useClass: LogoutUseCase });
    container.register(ResetPasswordUseCase, { useClass: ResetPasswordUseCase });
    container.register(LoginSsoUseCase, { useClass: LoginSsoUseCase });
    container.register(HandleDeepLinkUseCase, { useClass: HandleDeepLinkUseCase });
    container.register(ForgotPasswordUseCase, { useClass: ForgotPasswordUseCase });
    container.register(ChoosePlatformUseCase, { useClass: ChoosePlatformUseCase });
    container.register(ActivateUserUseCase, { useClass: ActivateUserUseCase });

    return container;
}
