import type { Route } from 'vue-router';

import { getBaseStore } from '@/main/get-base-store-router';
import translationsStore from '@/translations/store';

import { JWT, queryStringWithTokenParam } from './interfaces';

export type TokenValidationResult = {
    isValid: boolean;
    message: string;
};

export interface TokenValidator {
    (token: string): Promise<TokenValidationResult>;
}

/**
 * Silently remove token from the query string
 */
export function removeParamFromQueryString(key: string) {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete(key);

    const loc = window.location;
    const params = searchParams.toString();
    const queryString = params.length > 0 ? `?${params}` : '';
    const newUrl = `${loc.protocol}//${loc.host}${loc.pathname}${queryString}`;

    window.history.replaceState({ path: newUrl }, '', newUrl);
}

export function popTokenFromQueryString(activeRoute: Route) {
    const { token } = activeRoute.query as queryStringWithTokenParam;
    removeParamFromQueryString('token');
    return token as JWT;
}

/**
 * Get default error message for query param token when is invalid
 */
function getTokenExpirationMessage(): string {
    const trans = translationsStore.context(getBaseStore()).getters.trans;
    return trans('spa.authentication.login.token-link-expired');
}

export function getDefaultTokenValidationResult(isValid: boolean): TokenValidationResult {
    return {
        isValid,
        message: isValid ? '' : getTokenExpirationMessage(),
    };
}
