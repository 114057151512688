<template>
    <div class="no-result">
        <h4 class="h4 dark-color">
            {{ title }}
        </h4>
        <div class="info-line info-color message">
            {{ message }}
        </div>
        <elm-button
            v-if="showCreateNewButton"
            class="create-new-button"
            color="primary"
            size="small"
            @click="emitButtonClick"
        >
            {{ buttonText }}
        </elm-button>
    </div>
</template>

<script lang="ts">
    import { TranslationMixin } from '@/common/mixins';
    import { Component, Emit, Mixins, Prop } from 'vue-property-decorator';

    @Component
    export default class SelectorNoResults extends Mixins(TranslationMixin) {
        @Prop() public title!: string;
        @Prop() public message!: string;
        @Prop() public buttonText!: string;
        @Prop({ default: true, type: Boolean }) public showCreateNewButton!: boolean;

        @Emit('buttonClick')
        public emitButtonClick() {
            return;
        }
    }
</script>

<style lang="less" scoped>
    .no-result {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: auto;
        padding: @spacing-48;
    }
    .message {
        margin-top: @spacing-8;
        text-align: center;
    }
    .create-new-button {
        margin-top: @spacing-30;
    }
</style>
