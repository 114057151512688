<template>
    <div class="location-selector">
        <Popover ref="popover" :sameWidth="true" :isTargetInline="false" :preventOutsideClickPropagation="false">
            <template #target>
                <TextField
                    ref="textField"
                    v-model="text"
                    :placeholder="placeholder || trans('spa.label.location-selector.placeholder')"
                    :isInvalid="isInvalid"
                    :isDisabled="isDisabled"
                    iconLeft="elm-miscellaneous-onsite-icon"
                    testId="location-input"
                    size="small"
                    @keydown="handleHotkey"
                    @focus="onTextFieldFocus"
                    @blur="onTextFieldBlur"
                >
                    <template #right>
                        <transition name="fade">
                            <CoreCircleProgress v-if="isLoading" size="small" :isSpinning="true" />
                        </transition>
                    </template>
                </TextField>
            </template>
            <template #content>
                <BasicDropdownContainer>
                    <DropdownItem
                        v-for="(item, index) in items"
                        :key="item.place_id"
                        :title="item.description"
                        :isSelected="index === highlightedIndex"
                        :search="text"
                        @click="selectItem(item)"
                    />
                </BasicDropdownContainer>
            </template>
        </Popover>
    </div>
</template>

<script lang="ts">
    import '@eloomi/icons/miscellaneous/miscellaneous-onsite';

    // @ts-ignore
    import { google, Loader } from 'google-maps';
    import { debounce } from 'lodash-es';
    import { defineComponent, ref, watch } from 'vue';

    import CoreCircleProgress from '@/common/components/progress/CoreCircleProgress.vue';
    import { useTranslation } from '@/common/composables';
    import environment from '@/environment';
    import BasicDropdownContainer from '@/ui-kit/dropdown/containers/BasicDropdownContainer.vue';
    import DropdownItem from '@/ui-kit/dropdown/items/DropdownItem.vue';
    import Popover from '@/ui-kit/popover/Popover.vue';
    import TextField from '@/ui-kit/text-field/TextField.vue';

    type AutocompletePrediction = google.maps.places.AutocompletePrediction;
    type GeocoderResult = google.maps.GeocoderResult;

    const BLUR_HANDLER_DELAY = 200;
    const FOCUS_HANDLER_DELAY = 300;
    const FLAGS_RESET_DELAY = 500;

    export type LocationInfo = {
        address: string;
        location?: {
            latitude: number;
            longitude: number;
        };
        fullAddress?: string;
    };

    const googleMapsLoaders: Record<string, Promise<any>> = {};
    let autocompleteService: google.maps.places.AutocompleteService | null = null;
    let geocoder: google.maps.Geocoder | null = null;

    const loadGoogleMaps = (language: string) => {
        if (!googleMapsLoaders[language]) {
            googleMapsLoaders[language] = new Promise((resolve, reject) => {
                const loader = new Loader(environment.googleMapsApiKey, {
                    libraries: ['geocoder', 'places'],
                    language,
                });

                return loader
                    .load()
                    .then(({ maps }) => {
                        autocompleteService = new maps.places.AutocompleteService();
                        geocoder = new maps.Geocoder();
                        return geocoder;
                    })
                    .then(resolve)
                    .catch(reject);
            });
        }
        return googleMapsLoaders[language];
    };

    export default defineComponent({
        name: 'LocationSelector',
        components: {
            BasicDropdownContainer,
            CoreCircleProgress,
            DropdownItem,
            Popover,
            TextField,
        },
        props: {
            selectedAddress: { type: String, default: '' },
            minimalQueryLength: { type: Number, default: 5 },
            language: { type: String, default: 'en' },
            isInvalid: { type: Boolean, default: false },
            isDisabled: { type: Boolean, default: false },
            placeholder: { type: String },
        },
        setup(props, { emit }) {
            const popover = ref<InstanceType<typeof Popover>>();
            const textField = ref<InstanceType<typeof TextField>>();
            const text = ref('');
            const isLoading = ref(false);
            const items = ref<AutocompletePrediction[]>([]);
            const highlightedIndex = ref<number | null>(null);
            const isApiReady = ref(false);

            let shouldNotSelectOnBlur = false;
            let textFieldWasBlurred = false;

            loadGoogleMaps(props.language)
                .then(() => (isApiReady.value = true))
                .catch(() => console.error('Unable to load Google Maps'));

            const handleAutocompleteResult = (results: AutocompletePrediction[]) => {
                items.value = results || [];
                isLoading.value = false;
                highlightedIndex.value = null;
                if (items.value.length > 0) {
                    popover.value?.show();
                } else {
                    popover.value?.hide();
                }
            };

            const makeRequest = () => {
                highlightedIndex.value = null;
                if (text.value.length >= props.minimalQueryLength && isApiReady.value) {
                    isLoading.value = true;

                    const currentRequest = text.value;

                    autocompleteService?.getPlacePredictions(
                        {
                            input: currentRequest,
                            types: ['address'],
                        },
                        results => {
                            if (currentRequest === text.value) {
                                handleAutocompleteResult(results);
                            }
                        }
                    );
                } else {
                    popover.value?.hide();
                }
            };

            const onInputChangedDebounced = debounce(makeRequest, 300);

            const onLocationSelected = (info: LocationInfo) => {
                emit('locationSelected', info);
            };

            watch(
                () => props.selectedAddress,
                () => (text.value = props.selectedAddress),
                { immediate: true }
            );

            watch(
                () => text.value,
                () => {
                    selectCurrentText();
                    if (textField.value?.isFocused) {
                        onInputChangedDebounced();
                    }
                }
            );

            const handleHotkey = (event: KeyboardEvent) => {
                switch (event.key) {
                    case 'Down':
                    case 'ArrowDown': {
                        highlightNext();
                        break;
                    }
                    case 'Up':
                    case 'ArrowUp': {
                        highlightPrevious();
                        break;
                    }
                    case 'Enter': {
                        onEnterPressed();
                        break;
                    }
                    case 'Esc':
                    case 'Escape': {
                        cancelInput();
                        break;
                    }
                    case 'Tab': {
                        setTextFromSuggestion(event);
                        break;
                    }
                }
            };

            const onTextFieldBlur = () => {
                textFieldWasBlurred = true;
                highlightedIndex.value = null;
                setTimeout(() => {
                    if (!shouldNotSelectOnBlur) {
                        popover.value?.hide();
                        selectCurrentText();
                    }
                }, BLUR_HANDLER_DELAY);

                setTimeout(() => {
                    shouldNotSelectOnBlur = false;
                    textFieldWasBlurred = false;
                }, FLAGS_RESET_DELAY);
            };

            const selectCurrentText = () => {
                const address = text.value.trim();
                if (address !== props.selectedAddress) {
                    onLocationSelected({ address });
                }
            };

            const onEnterPressed = () => {
                if (highlightedIndex.value !== null) {
                    selectItem(items.value[highlightedIndex.value]);
                }
                textField.value?.input?.blur();
            };

            const setTextFromSuggestion = (event: KeyboardEvent) => {
                if (highlightedIndex.value !== null) {
                    event.preventDefault();
                    text.value = items.value[highlightedIndex.value].description + ' ';
                    highlightedIndex.value = null;
                }
            };

            const cancelInput = () => {
                text.value = props.selectedAddress;
                shouldNotSelectOnBlur = true;
                popover.value?.hide();
                textField.value?.input?.blur();
            };

            const onTextFieldFocus = () => {
                setTimeout(() => {
                    if (!textFieldWasBlurred) {
                        makeRequest();
                    }
                }, FOCUS_HANDLER_DELAY);
            };

            const highlightNext = () => {
                let newIndex: number | null = highlightedIndex.value === null ? 0 : highlightedIndex.value + 1;
                if (newIndex >= items.value.length) {
                    newIndex = null;
                }
                highlightedIndex.value = newIndex;
            };

            const highlightPrevious = () => {
                let newIndex: number | null =
                    highlightedIndex.value === null ? items.value.length - 1 : highlightedIndex.value - 1;
                if (newIndex < 0) {
                    newIndex = null;
                }
                highlightedIndex.value = newIndex;
            };

            const handleGeocoderResult = (place: AutocompletePrediction, geocodeResults: GeocoderResult[] | null) => {
                const info: LocationInfo = {
                    address: place.description,
                };
                if (geocodeResults && geocodeResults.length > 0) {
                    if (geocodeResults[0].geometry) {
                        info.location = {
                            latitude: geocodeResults[0].geometry?.location.lat(),
                            longitude: geocodeResults[0].geometry?.location.lng(),
                        };
                    }
                    info.fullAddress = geocodeResults[0].formatted_address;
                }
                onLocationSelected(info);
            };

            const selectItem = (item: AutocompletePrediction) => {
                shouldNotSelectOnBlur = true;
                popover.value?.hide();
                text.value = item.description;

                geocoder?.geocode({ placeId: item.place_id }, results => handleGeocoderResult(item, results));
            };

            return {
                popover,
                textField,
                text,
                isLoading,
                items,
                highlightedIndex,
                handleHotkey,
                isApiReady,
                onInputChangedDebounced,
                onTextFieldFocus,
                onTextFieldBlur,
                selectItem,
                ...useTranslation(),
            };
        },
    });
</script>

<style lang="less" scoped>
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.2s;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
</style>
