import { Dictionary } from '@/translations';

export function mergeTranslations(...args: Dictionary[]) {
    return args.reduce((acc, x) => mergeTranslationCollections(acc, x), {});
}

function mergeTranslationCollections(collectionA: Dictionary, collectionB: Dictionary) {
    if (process.env.NODE_ENV === 'development') {
        for (const keyA in collectionA)
            for (const keyB in collectionB) {
                if (keyA === keyB) {
                    console.error(
                        `Translation key '${keyA}' is overriden: '${collectionA[keyA]}' becomes '${collectionB[keyB]}'`
                    );
                }

                if (!keyA.startsWith('spa')) {
                    console.error(`'${keyA}' should be prefixed with 'spa.'`);
                }
            }
    }

    return { ...collectionA, ...collectionB };
}
