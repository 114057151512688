import { NavigationGuardNext, Route } from 'vue-router';

import { LocationQuery, makeSSOOrigin } from '@/authentication/services/interfaces';
import notificationStore from '@/common/components/toast/store';
import { makeLogger } from '@/common/services/debug-helper';
import { getBaseStore } from '@/main/get-base-store-router';
import translationsStore from '@/translations/store';

import { DeepLinkRedirector } from './deep-link-redirector';

const log = makeLogger('sso');

type queryStringWithStatusParam = LocationQuery & {
    status?: string;
};

export enum StatusCodes {
    BadRequest = '400',
    Unauthorized = '401',
    NotFound = '404',
    ToManyLoginAttempts = '429',
}

export function getLoginSsoErrorStatus(query?: queryStringWithStatusParam): StatusCodes | null {
    if (!query) {
        return null;
    }

    return query.status ? (query.status as StatusCodes) : null;
}

export function showErrorMessageIfSSOErrorDetected(status: StatusCodes | null) {
    const trans = translationsStore.context(getBaseStore()).getters.trans;
    let message: string | null = null;

    if (status) {
        switch (status) {
            case StatusCodes.Unauthorized: {
                message = trans(`spa.authentication.login.sso.unauthorized`);
                break;
            }
            case StatusCodes.BadRequest: {
                message = trans(`spa.authentication.login.sso.bad-request`);
                break;
            }
            case StatusCodes.NotFound: {
                message = trans(`spa.authentication.login.sso.not-found`);
                break;
            }
            case StatusCodes.ToManyLoginAttempts: {
                message = trans(`spa.authentication.login.sso.to-many-attempts`);
                break;
            }
        }
    }

    if (message) {
        notificationStore.context(getBaseStore()).actions.showDelayedNotification({
            theme: 'warning',
            message: message,
            duration: 3000,
        });
    }
}

const handleRegularSSOLoginAttempt = (to: Route, _from: Route, next: NavigationGuardNext) => {
    const status = getLoginSsoErrorStatus(to.query);
    next();
    showErrorMessageIfSSOErrorDetected(status);
};

const handleMobileSSOLoginAttempt = (to: Route, _from: Route, next: NavigationGuardNext) => {
    const deepLinkRedirector = new DeepLinkRedirector();
    const status = getLoginSsoErrorStatus(to.query);
    const url = deepLinkRedirector.getLoginBackRedirectLink(status);
    deepLinkRedirector.redirect(url);
    next(false);
};

export const checkForMobileOrRegularLoginAttempt = (to: Route, _from: Route, next: NavigationGuardNext) => {
    const origin = makeSSOOrigin(to.query.origin);

    if (origin === 'web') {
        log('handling web sso login attempt');
        return handleRegularSSOLoginAttempt(to, _from, next);
    }

    log('handling mobile sso login attempt');
    return handleMobileSSOLoginAttempt(to, _from, next);
};
