import { Mutations } from 'vuex-smart-module';

import { ICoursePlayerCourse } from '../models';
import { UserCoursesState } from './state';

export class UserCoursesMutations extends Mutations<UserCoursesState> {
    public setActiveCourse(course: ICoursePlayerCourse) {
        this.state.activeCourse = course;
    }

    public unsetActiveCourse() {
        this.state.activeCourse = null;
        this.state.activeChapterId = null;
        this.state.activePageId = null;
    }

    public setActiveChapterId(chapterId: number) {
        this.state.activeChapterId = chapterId;
    }

    public setActivePageId(pageId: number) {
        this.state.activePageId = pageId;
    }

    public markActivePageAsCompleted() {
        const activeChapter = this.state.activeCourse?.chapters.find(
            chapter => chapter.id === this.state.activeChapterId
        );
        const activePage = activeChapter?.pages.find(page => page.id === this.state.activePageId);

        if (activePage !== undefined) {
            activePage.is_completed = true;
        }
    }
}
