import { inject, injectable } from 'tsyringe';

import { getDevicePlatform } from '@/common/services/get-device-platform';
import { ServiceTokens as BaseServiceTokens } from '@/di/tokens';

import { AuthenticationServiceTokens as Tokens } from '../di-tokens';
import { PlatformResolver, VueRouterRedirectionService } from '../services';

export interface LoginPageStateNavigator {
    proceedToLogin(): void;
}

/**
 * User uses mobile app and just before login screen have to choose a platform first.
 */
@injectable()
export class ChoosePlatformUseCase {
    public constructor(
        @inject(BaseServiceTokens.PlatformResolutionService)
        private readonly platformService: PlatformResolver,

        @inject(Tokens.AuthFlowStoreService)
        private readonly navigator: LoginPageStateNavigator,

        @inject(Tokens.RedirectService)
        private redirectService: VueRouterRedirectionService
    ) {}

    public async choosePlatform(subDomain: string) {
        await this.platformService.forceSetPlatformDomain(subDomain);
        const platform = await this.platformService.getPlatformData(true);

        if (this.platformService.isSsoOnlyLoginMode()) {
            await this.redirectService.redirectToLoginSsoPage(platform.id, { devicePlatform: getDevicePlatform() });
        } else {
            this.navigator.proceedToLogin();
        }
    }

    public getPlatformParentDomain() {
        return this.platformService.getPlatformParentDomain();
    }
}
