import { RouteConfig } from 'vue-router';

import { mobileIsUnsupportedFlag, viewportIsUnsupportedFlag } from '@/common/services/mobile-fallback';
import { MinWidthViewportBreakpoints } from '@/common/constants/breakpoints';
import PlanFeaturesList from '@/plan-features/plan-features-list';
import { checkIfRouteAvailableByPlanFeatures } from '@/plan-features/routes';
import { Permissions } from '@/permissions';
import createContentStoreRoute from '@/content-store/routes';

const courseBuilderRootPage = () => import('./pages/CourseBuilderRootPage.vue');
const coursesAdministration = () => import('./pages/CoursesAdministration.vue');

export enum AdminCoursesRoutes {
    COURSE_BUILDER = 'course-builder',
    COURSES_ADMINISTRATION = 'courses-administration',
    COURSES_ADMINISTRATION_CONTENT_STORE = 'courses-administration-content-store',
    COURSES_ADMINISTRATION_CONTENT_STORE_ITEM = 'courses-administration-content-store-item',
    COURSES_COURSE_BUILDER_CONTENT_STORE = 'courses-course-builder-content-store',
    COURSES_COURSE_BUILDER_CONTENT_STORE_ITEM = 'courses-course-builder-content-store-item',
}

const routes: RouteConfig[] = [
    {
        path: 'courses',
        name: AdminCoursesRoutes.COURSES_ADMINISTRATION,
        meta: {
            title: 'Courses Administration',
            ...mobileIsUnsupportedFlag,
            showNavigationSidebar: true,
            requiredPermissions: [Permissions.AdminCourses],
        },
        children: [
            createContentStoreRoute({
                contentStoreRouteName: AdminCoursesRoutes.COURSES_ADMINISTRATION_CONTENT_STORE,
                contentStoreItemRouteName: AdminCoursesRoutes.COURSES_ADMINISTRATION_CONTENT_STORE_ITEM,
                showNavigationSidebar: true,
            }),
        ],
        component: coursesAdministration,
        beforeEnter: checkIfRouteAvailableByPlanFeatures(PlanFeaturesList.COURSES),
    },
    {
        path: 'course/builder/:course_id',
        name: AdminCoursesRoutes.COURSE_BUILDER,
        meta: {
            title: 'Course Builder',
            ...viewportIsUnsupportedFlag(MinWidthViewportBreakpoints.lg),
            defaultBackRoute: AdminCoursesRoutes.COURSES_ADMINISTRATION,
            requiredPermissions: [Permissions.AdminCourses],
        },
        component: courseBuilderRootPage,
        beforeEnter: checkIfRouteAvailableByPlanFeatures(PlanFeaturesList.COURSES),
        children: [
            createContentStoreRoute({
                contentStoreRouteName: AdminCoursesRoutes.COURSES_COURSE_BUILDER_CONTENT_STORE,
                contentStoreItemRouteName: AdminCoursesRoutes.COURSES_COURSE_BUILDER_CONTENT_STORE_ITEM,
            }),
        ],
    },
];

export default routes;
