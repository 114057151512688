import Vue from 'vue';
import VueRouter from 'vue-router';
import type { Store } from 'vuex';

import { EntityNotFoundEndpointError } from '@/authentication/services/errors';
import { DEFAULT_PAGE_LOCATION } from '@/authentication/services/locations';
import notificationStore from '@/common/components/toast/store';
import environment from '@/environment';
import translationsStore from '@/translations/store';

export function handleEndpointError(router: VueRouter, store: Store<unknown>) {
    Vue.config.errorHandler = async function (err) {
        if (!environment.isProduction) {
            console.error(err);
        }
        if (err instanceof EntityNotFoundEndpointError) {
            await router.push(DEFAULT_PAGE_LOCATION);
            notificationStore.context(store).actions.showDelayedNotification({
                theme: 'warning',
                message: translationsStore.context(store).getters.trans('spa.navigation.sections.page-not-found'),
                duration: 3000,
            });
        }
    };
}
