<template>
    <div class="modal-window-content">
        <slot />
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'ModalWindowContent',
    });
</script>

<style lang="less" scoped>
    .modal-window-content {
        padding: @spacing-32 @spacing-24 @spacing-36 @spacing-24;
    }
</style>
