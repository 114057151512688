<template>
    <div class="choose-plan">
        <p class="days-remaining">{{ topText }}</p>
        <router-link class="link" :to="billingPage">
            {{ trans('spa.billing.plan-button.choose') }}
        </router-link>
    </div>
</template>

<script lang="ts">
    import { TranslationMixin } from '@/common/mixins';
    import { Component, Mixins, Prop } from 'vue-property-decorator';
    import { AdminBillingRoutes } from '@/admin/billing/routes';

    @Component({})
    export default class ChoosePlanButton extends Mixins(TranslationMixin) {
        @Prop() expirationDate?: string | null;

        get topText() {
            if (!this.expirationDate || !this.daysRemaining) {
                return this.trans('spa.billing.plans.free-version');
            }
            return this.trans('spa.billing.plans.free-version.days-remaining', { num: this.daysRemaining });
        }
        get daysRemaining() {
            if (!this.expirationDate) {
                return 0;
            }
            const total = Date.parse(this.expirationDate) - Date.parse(new Date().toString());
            return Math.floor(total / (1000 * 60 * 60 * 24));
        }

        get billingPage() {
            return { name: AdminBillingRoutes.BILLING };
        }
    }
</script>
<style lang="less" scoped>
    .choose-plan {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        padding: @spacing-24;
        background: @primary-color-8;
    }
    .days-remaining {
        .info-line-bright;

        margin: 0;
    }
    .link {
        .h4-primary;

        display: block;
    }
</style>
