import { App } from '@capacitor/app';

import { HandleDeepLinkUseCase } from '@/authentication/use-cases/handle-deep-link';
import { makeLogger } from '@/common/services/debug-helper';
import { baseContainer } from '@/di/container';

const log = makeLogger('mobile', 'deep-link');

export function subscribeForDeepLinkOpen() {
    App.addListener('appUrlOpen', function deepLinkCallback(event) {
        log('appUrlOpen fired', event);

        const useCase = baseContainer.resolve(HandleDeepLinkUseCase);
        useCase.execute(event.url);
    });
}
