export enum FilterType {
    Active = 'active',
    AssignmentType = 'assignment-type',
    Category = 'category',
    CertificationStatus = 'certification-status',
    CourseAuthor = 'course-author',
    CourseEnrollmentStatus = 'course-enrollment-status',
    CourseType = 'course-type',
    EventActivityStatus = 'event-activity-status',
    EventAuthor = 'event-author',
    EventCategory = 'event-category',
    EventEnrollmentStatus = 'event-enrollment-status',
    Group = 'group',
    Instructor = 'instructor',
    RoleType = 'role-type',
    Roles = 'roles',
    SessionAuthor = 'session-author',
    SessionEnrollStatus = 'session-enroll-status',
    Skill = 'skill',
    Topic = 'topic',
    Visibility = 'visibility',
}

export const isFilterType = (type: FilterType | DateFilterType): type is FilterType => {
    return (Object.values(FilterType) as string[]).includes(type);
};

export enum RoleType {
    Custom = 'custom',
    Standard = 'standard',
}

export enum EnrollmentType {
    ManagerEnrollment = 'manager-enrollment',
    SelfEnrollment = 'self-enrollment',
}

export enum VisibilityFilterOptions {
    FutureEvents = 'future-events',
    PastEvents = 'past-events',
}

export type CalendarTimeSpanType = 'upcoming' | 'past' | undefined;

export enum DateFilterType {
    CertificateExpiryDate = 'certificate-expiry-date',
    Date = 'date',
}

export enum FilterMode {
    Exclusive = 'exclusive',
    Inclusive = 'inclusive',
}

export type Interval = { end: Date | null; start: Date | null };

export type ArrayFilterValue = { mode?: FilterMode; value: Array<number | string> };
export type DateFilterValue = { value: Interval };
export type FilterValue = ArrayFilterValue | DateFilterValue;

export function isArrayFilterValue(item: FilterValue): item is ArrayFilterValue {
    return Array.isArray(item.value);
}

export function isDateFilterValue(item: FilterValue): item is DateFilterValue {
    const value = item.value as any;
    return value.start || value.end;
}

export type Filters = Partial<Record<FilterType, ArrayFilterValue>> & {
    [DateFilterType.Date]?: DateFilterValue;
};

export type Option = {
    label: string;
    value: number | string | boolean;
};

export type DropdownSettings = {
    isSingleSelect: boolean;
    searchIsEnabled: boolean;
    showSelectAll: boolean;
    withConfirmBtn: boolean;
};
