import { RouteConfig } from 'vue-router';
import { CoursesRouteNames } from '@/courses/route-definitions';

const profileSettingsPage = () => import('./pages/ProfileSettingsPage.vue');

export const enum ProfileSettingsRoutes {
    PROFILE_SETTINGS = 'profile-settings',
}

const routes: RouteConfig[] = [
    {
        path: `${ProfileSettingsRoutes.PROFILE_SETTINGS}`,
        name: ProfileSettingsRoutes.PROFILE_SETTINGS,
        meta: {
            title: 'Profile Settings',
            defaultBackRoute: CoursesRouteNames.COURSES_DASHBOARD,
        },
        component: profileSettingsPage,
    },
];

export default routes;
