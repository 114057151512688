<template>
    <ErrorMessage
        :title="trans('spa.error.content-not-available')"
        :message="trans('spa.error.use-desktop-not-mobile')"
    >
        <SmileyDesktopImage />
    </ErrorMessage>
</template>

<script lang="ts">
    import { Component, Mixins } from 'vue-property-decorator';

    import { TranslationMixin } from '@/common/mixins';

    import ErrorMessage from './ErrorMessage.vue';
    import SmileyDesktopImage from './SmileyDesktopImage.vue';

    @Component({ components: { ErrorMessage, SmileyDesktopImage } })
    export default class MobileFallback extends Mixins(TranslationMixin) {}
</script>
