<template>
    <div class="dropdown-container">
        <div v-if="hasTitle || withBackButton" class="header">
            <IconButton
                v-if="withBackButton"
                class="back-button"
                color="secondary"
                size="small"
                @click="handleBackButtonClick"
            >
                <elm-arrows-arrow-left-bold-icon size="10" />
            </IconButton>
            <span v-if="hasTitle" class="title">{{ title }}</span>
        </div>
        <div class="body">
            <slot />
        </div>
        <div v-if="hasFooter" class="footer">
            <slot name="footer" />
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
    import IconButton from '../../buttons/IconButton.vue';
    import '@eloomi/icons/arrows/arrows-arrow-left-bold';

    @Component({
        components: {
            IconButton,
        },
    })
    export default class DropdownContainer extends Vue {
        @Prop({ default: undefined, type: String }) public title?: string;
        @Prop({ default: false, type: Boolean }) public withBackButton!: boolean;

        public get hasTitle() {
            return this.title !== undefined && this.title !== '';
        }

        public get hasFooter() {
            return Boolean(this.$slots.footer);
        }

        @Emit('backButtonClick')
        public handleBackButtonClick() {
            return;
        }
    }
</script>

<style lang="less" scoped>
    .dropdown-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-width: 200px;
        max-height: inherit;
        padding: @spacing-8;
        overflow-y: auto;
        background: @bright-color;
        border: 1px solid @info-color-20;
        border-radius: @border-radius-8;
        box-shadow: 0 2px 40px 0 @dark-color-8;

        &,
        &::v-deep * {
            .styled-scrollbar(4px);
        }
    }

    .header {
        display: flex;
        align-items: center;
        padding: @spacing-16 @spacing-16 @spacing-14 @spacing-16;
    }

    .back-button {
        margin-right: @spacing-12;
    }

    .body {
        flex-grow: 1;
    }

    .title {
        .label-uppercase-info();
    }

    .footer {
        display: flex;
        align-items: center;
        padding: @spacing-16;
    }
</style>
