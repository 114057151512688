import { computed } from 'vue';

import { useMobileModeStore } from '@/common/store/mobile-mode';

export function useMobile() {
    const mobileModeStore = useMobileModeStore();

    const isMobile = computed(() => mobileModeStore.state.isMobile);
    const breakpoints = computed(() => mobileModeStore.state.breakpoints);

    const isViewportLargerOrEqualXl = computed(() => mobileModeStore.getters.isViewportLargerOrEqualXl);
    const isViewportLargerOrEqualXxl = computed(() => mobileModeStore.getters.isViewportLargerOrEqualXxl);
    const isViewportSmallerOrEqualMd = computed(() => mobileModeStore.getters.isViewportSmallerOrEqualMd);
    const isViewportSmallerOrEqualSm = computed(() => mobileModeStore.getters.isViewportSmallerOrEqualSm);

    return {
        breakpoints,
        isMobile,
        isViewportLargerOrEqualXl,
        isViewportLargerOrEqualXxl,
        isViewportSmallerOrEqualMd,
        isViewportSmallerOrEqualSm,
    };
}

export default useMobile;
