import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import { PushNotifications } from '@capacitor/push-notifications';
import {
    DeviceType,
    NotificationsApiFactory,
    RegisterDeviceRequest,
} from '@eloomi/eloomi-notifications-persona-client/1.0';
import { nanoid } from 'nanoid';
import type VueRouter from 'vue-router';

import { authorizeClient } from '@/api/authorize-client';
import { makeLogger } from '@/common/services/debug-helper';

import { getTapHandler } from './notification-tap-handler';

const notificationsApi = authorizeClient(NotificationsApiFactory);

export class PermissionDeniedError extends Error {
    constructor() {
        super(`User denied permissions for notifications.`);

        Object.setPrototypeOf(this, PermissionDeniedError.prototype);
    }
}

interface NotificationData {
    action_url: string | null;
    template_key:
        | 'mobile_course_assigned'
        | 'mobile_course_assigned_with_deadline'
        | 'mobile_course_deadline_reminder'
        | 'mobile_course_deadline_missed'
        | 'mobile_playlist_assigned'
        | 'mobile_playlist_assigned_with_deadline';
}

export interface TapHandler {
    (event: NotificationData): void;
}

interface RegistrationStatus {
    success: boolean;
}

class PushNotificationsService {
    private installationIdKey = 'installation-id';
    private installationId = '';
    private tapHandlers: Array<TapHandler> = [];

    /**
     * On iOS it contains the APNS token.
     * On Android it contains the FCM token.
     */
    private registrationToken = '';
    private registrationPromise: Promise<RegistrationStatus> = Promise.resolve({ success: false });

    private isInitialized = false;

    private log = makeLogger('push-notifications');

    public async init() {
        if (!Capacitor.isNativePlatform() || this.isInitialized) return;

        this.log('Initialization...');

        this.isInitialized = true;

        this.installationId = await this.initInstallationId();

        this.log('Installation id: ', this.installationId);

        await this.addListeners();
        this.registrationPromise = this.registerOnPNS();
    }

    public subscribe() {
        return this.registerOnBackend();
    }

    private async registerOnBackend() {
        const registrationStatus = await this.registrationPromise;

        if (!registrationStatus.success) return;

        const body: RegisterDeviceRequest = {
            handle: this.registrationToken,
            device_type: Capacitor.getPlatform() === 'ios' ? DeviceType.IOs : DeviceType.Android,
            installation_id: this.installationId,
            iana_timezone: this.getTimeZone(),
        };

        try {
            await notificationsApi.register(body);
            this.log('Registration on backend: Successful');
        } catch (error) {
            this.log('Registration on backend: Failed', error);
        }
    }

    private async initInstallationId() {
        let installationId = await this.getInstallationId();

        if (!installationId) {
            installationId = nanoid();
            await this.setInstallationId(installationId);
        }

        return installationId;
    }

    private async getInstallationId() {
        const installationId = await Preferences.get({ key: this.installationIdKey });
        return installationId.value;
    }

    private setInstallationId(installationId: string) {
        return Preferences.set({ key: this.installationIdKey, value: installationId });
    }

    private async addListeners() {
        await PushNotifications.addListener('pushNotificationReceived', notification => {
            this.log('Push notification received: ', notification);
        });

        await PushNotifications.addListener('pushNotificationActionPerformed', event => {
            this.log('Push notification action performed', event);

            if (event.actionId === 'tap') {
                const data = event.notification.data as NotificationData;

                for (const handler of this.tapHandlers) handler(data);
            }
        });
    }

    private async getPermissionStatus() {
        const status = await PushNotifications.checkPermissions();

        if (status.receive === 'prompt') {
            return PushNotifications.requestPermissions();
        }

        return status;
    }

    private getTimeZone() {
        try {
            return Intl.DateTimeFormat().resolvedOptions().timeZone;
        } catch {
            console.warn(`Unable to read user timezone.`);
            return null;
        }
    }

    private registerOnPNS() {
        return new Promise<RegistrationStatus>(resolve => {
            PushNotifications.addListener('registration', token => {
                this.log('Registration token: ', token.value);
                this.registrationToken = token.value;
                resolve({ success: true });
            });

            PushNotifications.addListener('registrationError', err => {
                this.log('Registration error: ', err.error);
                resolve({ success: false });
            });

            this.getPermissionStatus()
                .then(permissionStatus => {
                    this.log('Permissions status: ', permissionStatus.receive);

                    if (permissionStatus.receive !== 'granted') {
                        throw new PermissionDeniedError();
                    }

                    return PushNotifications.register();
                })
                .catch(() => resolve({ success: false }));
        });
    }

    public addTapHandler(handler: TapHandler) {
        this.tapHandlers.push(handler);
    }
}

export const pushNotificationsService = new PushNotificationsService();

export function initPushNotifications(router: VueRouter) {
    pushNotificationsService.addTapHandler(getTapHandler(router));

    return pushNotificationsService.init();
}
