import axios, { AxiosInstance } from 'axios';

import { getRegionalBaseUri } from '@/api/regional-url-provider';
import { AuthenticationServiceTokens } from '@/authentication/di-tokens';
import { AxiosAccessDeniedErrorHandler } from '@/authentication/services/forbidden-handler';
import type { HandleHttpForbiddenStatusUseCase } from '@/authentication/use-cases/handle-http-forbidden';
import { makeWarnLogger } from '@/common/services/debug-helper';
import { baseContainer } from '@/di/container';
import environment from '@/environment';
import { getSessionContainer } from '@/main/get-session-store';

const warnLog = makeWarnLogger('authorize-client');
const axiosInstance = axios.create();

/**
 * Install 403 handler for new API clients (eloomi packages) that will try refreshing the user session.
 */
const accessDeniedErrorHandler = new AxiosAccessDeniedErrorHandler(axiosInstance);
accessDeniedErrorHandler.install(() => {
    const handler = baseContainer.resolve<HandleHttpForbiddenStatusUseCase>(
        AuthenticationServiceTokens.HandleHttpForbiddenStatusUseCase
    );
    return handler.execute();
});

/** Creates authorized client from generated client factory function. */
export function authorizeClient<T>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    factoryFunction: (configuration?: any, basePath?: string | (() => Promise<string>), axios?: AxiosInstance) => T,
    sessionType: 'auto' | 'regular' | 'impersonation' = 'auto'
) {
    return factoryFunction(
        {
            apiKey: (keyName: string) => {
                if (keyName === 'apikey') return environment.apiKey;
                return '';
            },
            accessToken: async () => {
                const sessionContainer = getSessionContainer();
                try {
                    if (sessionType === 'auto') {
                        return sessionContainer.getData();
                    } else if (sessionType === 'regular') {
                        return sessionContainer.getRegularSession().getData();
                    } else {
                        return sessionContainer.getImpersonationSession().getData();
                    }
                } catch {
                    warnLog("couldn't load token using SessionContainer");
                }
            },
        },
        getRegionalBaseUri,
        axiosInstance
    );
}

export function initClientWithoutAuth<T>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    factoryFunction: (configuration?: any, basePath?: string | (() => Promise<string>), axios?: AxiosInstance) => T
) {
    return factoryFunction({}, getRegionalBaseUri, axios.create());
}

/**
 * Initializes client without auth to point to default region (in our case EU). This is used only to resolve
 * platform details and configure rest of the client.
 * */
export function initClientWithoutAuthDefaultRegion<T>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    factoryFunction: (configuration?: any, basePath?: string | (() => Promise<string>), axios?: AxiosInstance) => T
) {
    let apiBaseUrl = environment.apiBaseUrl || '';

    if (apiBaseUrl.endsWith('/')) {
        apiBaseUrl = apiBaseUrl.slice(0, -1);
    }

    return factoryFunction({}, apiBaseUrl, axios.create());
}
