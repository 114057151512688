import { computed } from 'vue';

import { TranslationKey } from '@/translations';
import { TransFunction, useTranslationsStore } from '@/translations/store';
import { TranslationVariables } from '@/translations/store/translate-key';

export type Suffix<T extends string, U extends string> = { [P in T]: P extends `${U}.${infer K}` ? K : never }[T];

export function useTranslation() {
    const store = useTranslationsStore();

    function getLocalTrans<T extends string>(prefix: T) {
        return function (suffix: Suffix<TranslationKey, T>, variables?: TranslationVariables) {
            return store.getters.trans(`${prefix}.${suffix}` as TranslationKey, variables);
        };
    }

    return {
        getLocalTrans,
        trans: store.getters.trans as TransFunction,
        currentLanguage: computed(() => store.state.currentLanguage),
    };
}

export default useTranslation;
