import {
    CardPersonaApiFactory,
    Currency,
    FrequencyInterval,
    PlanName,
    PlanSize,
} from '@eloomi/eloomi-billing-persona-client/1.0';

import { GetCardLinkEditResponse } from '@/admin/billing/models';
import { authorizeClient } from '@/api/authorize-client';
import { extractAxiosData } from '@/common/services/extract-axios-data';

const cardClient = authorizeClient(CardPersonaApiFactory);

type GetCardLinkPayload = {
    returnUrl: string;
    plan: PlanName;
    size: PlanSize;
    frequency: FrequencyInterval;
    currency: Currency;
};

export function getCardLink(GetCardLinkPayload: GetCardLinkPayload) {
    return cardClient.getCards(
        GetCardLinkPayload.returnUrl,
        GetCardLinkPayload.plan,
        GetCardLinkPayload.size,
        GetCardLinkPayload.frequency,
        GetCardLinkPayload.currency
    );
}

export function getCardLinkEdit(returnUrl: string) {
    return cardClient.getCards_1(returnUrl).then(extractAxiosData) as Promise<GetCardLinkEditResponse>;
}

export function dibsStatus(payload) {
    return cardClient.addDibsStatus(payload);
}
