<template>
    <LoginPageLayout>
        <AuthenticationFlowPanel :title="trans('title')" :centerVertically="true">
            <p class="paragraph-info instructions">
                <SlottedTranslation translationKey="spa.authentication.platform-deactivated.description">
                    <template #domain>
                        <strong>{{ platformDomain }}</strong>
                    </template>
                </SlottedTranslation>
            </p>
        </AuthenticationFlowPanel>
    </LoginPageLayout>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    import { useTranslation } from '@/common/composables';
    import type { PlatformResolutionService } from '@/common/services/platform-resolution';
    import { ServiceTokens } from '@/di/tokens';
    import { useDependencyContainer } from '@/di/use-injector';
    import SlottedTranslation from '@/translations/components/SlottedTranslation.vue';

    import AuthenticationFlowPanel from '../components/AuthenticationFlowPanel.vue';
    import LoginPageLayout from '../components/LoginPageLayout.vue';

    export default defineComponent({
        name: 'PlatformDeactivatedPage',
        components: { LoginPageLayout, AuthenticationFlowPanel, SlottedTranslation },

        setup() {
            const { getLocalTrans } = useTranslation();
            const trans = getLocalTrans('spa.authentication.platform-deactivated');
            const container = useDependencyContainer();
            const platformResolutionService = container.resolve<PlatformResolutionService>(
                ServiceTokens.PlatformResolutionService
            );
            const platformDomain = platformResolutionService.getPlatformDomain();

            return {
                trans,
                platformDomain,
            };
        },
    });
</script>

<style lang="less" scoped>
    .instructions {
        margin: 0;
        margin-top: @spacing-24;
    }
</style>
