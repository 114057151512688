import { Location } from 'vue-router';
import { AuthenticationRouteNames } from '@/authentication/route-definitions';
import { CoursesRouteNames } from '@/courses/route-definitions';

export const DEFAULT_PAGE_LOCATION: Location = {
    name: CoursesRouteNames.COURSES_DASHBOARD,
};

export const LOGIN_PAGE_LOCATION: Location = {
    name: AuthenticationRouteNames.LOGIN,
};
