import {
    EventAddOrUpdateRequest,
    EventApiFactory,
    EventsTrackingApiFactory,
    ManagerEventsApiFactory,
} from '@eloomi/eloomi-event-management-persona-client/1.0';
import { EventApiFactory as NewEventApiFactory } from '@eloomi/eloomi-event-management-persona-client/2.0';

import { authorizeClient } from '@/api/authorize-client';
import { DateFilterType, EnrollmentType, FilterType, VisibilityFilterOptions } from '@/common/components/filter';
import { DataRequestOptions } from '@/common/components/table';
import { buildSort, FluentFilterCollection, Operator } from '@/common/services';
import { extractAxiosData } from '@/common/services/extract-axios-data';

const eventsClient = authorizeClient(EventApiFactory);
const newEventsClient = authorizeClient(NewEventApiFactory);
const eventsTrackingClient = authorizeClient(EventsTrackingApiFactory);
const eventsManagerClient = authorizeClient(ManagerEventsApiFactory);

export function getEvents(options: DataRequestOptions) {
    const filterBuider = new FluentFilterCollection();
    const filters = options.filters;

    if (options.searchQuery) {
        filterBuider.where('title').filter(Operator.Contains, options.searchQuery);
    }

    if (
        filters?.[FilterType.Visibility]?.value.length &&
        filters?.[FilterType.Visibility]!.value[0] === VisibilityFilterOptions.FutureEvents
    ) {
        filterBuider
            .where('event_start')
            .filter(Operator.GreaterThan, filters[FilterType.Visibility]!.value[0], new Date());
    }

    if (
        filters?.[FilterType.Visibility]?.value.length &&
        filters?.[FilterType.Visibility]!.value[0] === VisibilityFilterOptions.PastEvents
    ) {
        filterBuider
            .where('event_end')
            .filter(Operator.LessThanOrEqual, filters[FilterType.Visibility]!.value[0], new Date());
    }

    if (filters?.[FilterType.Topic]?.value.length) {
        filterBuider.where('topic_id').filter(Operator.Have, filters[FilterType.Topic]!.value.join('|'));
    }

    if (filters?.[FilterType.CourseAuthor]?.value.length) {
        filterBuider.where('author_id').filter(Operator.IsEqualTo, filters[FilterType.CourseAuthor]!.value.join('|'));
    }

    if (filters?.[FilterType.EventAuthor]?.value.length) {
        filterBuider.where('author_id').filter(Operator.IsEqualTo, filters[FilterType.EventAuthor]!.value.join('|'));
    }

    if (filters?.[FilterType.Category]?.value.length) {
        filterBuider.where('category_id').filter(Operator.Have, filters[FilterType.Category]!.value.join('|'));
    }

    if (filters?.[FilterType.Instructor]?.value.length) {
        filterBuider.where('instructor_ids').filter(Operator.Have, filters[FilterType.Instructor]!.value.join('|'));
    }

    if (filters?.[FilterType.EventActivityStatus]?.value && filters?.[FilterType.EventActivityStatus]?.value.length) {
        filterBuider
            .where('is_archived')
            .filter(Operator.IsEqualTo, filters?.[FilterType.EventActivityStatus]?.value[0]);
    }

    if (filters?.[DateFilterType.Date]) {
        const { start, end } = filters[DateFilterType.Date]!.value;
        if (start) {
            filterBuider.where('event_start').filter(Operator.GreaterThanOrEqual, start);
        }

        if (end) {
            filterBuider.where('event_end').filter(Operator.LessThanOrEqual, end);
        }
    }

    if (
        filters?.[FilterType.EventEnrollmentStatus]?.value.length &&
        filters[FilterType.EventEnrollmentStatus]?.value.includes(EnrollmentType.ManagerEnrollment)
    ) {
        filterBuider.where('manager_enrollment').filter(Operator.IsEqualTo, true);
    }

    return newEventsClient
        .getEventsPaginated(filterBuider.build(), buildSort(options.sort), options.page, options.pageSize)
        .then(({ data }) => data);
}

export async function deleteEvents(eventIds: number[], notify: boolean) {
    await eventsClient.deleteEventBulk(notify, eventIds);
}

export async function archiveEvents(eventIds: number[]) {
    await eventsClient.bulkArchiveEvent(eventIds);
}

export async function unarchiveEvents(eventIds: number[]) {
    await eventsClient.bulkUnarchiveEvent(eventIds);
}

export function updateEvent(id: number, event: EventAddOrUpdateRequest) {
    return eventsClient.updateEvent(id, event).then(axiosResponse => axiosResponse.data);
}

export function getEvent(eventId: number) {
    return eventsClient.getEventAdmin(eventId).then(({ data }) => data);
}

export function createEvent(event: EventAddOrUpdateRequest) {
    return eventsClient.createEvent(event).then(({ data }) => data);
}

export function updateEventAuthor(eventlistId: number, authorId: number) {
    return eventsClient.updateEventAuthor(eventlistId, { author_id: authorId });
}

export function getEventLocalizableReport(eventId: number, options: DataRequestOptions) {
    return eventsTrackingClient
        .getEventLocalizableReport(eventId, undefined, undefined, options.page, options.pageSize)
        .then(extractAxiosData);
}

export function getEventExcelReport(eventId: number) {
    return eventsTrackingClient.getEventExcelReport(eventId, { responseType: 'blob' }).then(extractAxiosData);
}

export function setEventsManagerEnrollment({ eventIds, isManagerEnrollmentEnabled }) {
    return eventsManagerClient.setCoursesManagerEnrollment({
        event_ids: eventIds,
        manager_enrollment: isManagerEnrollmentEnabled,
    });
}
