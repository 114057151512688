/* eslint-disable vue/one-component-per-file */
import { Vue } from 'vue-property-decorator';
import { createComposable, createMapper, Module, Mutations } from 'vuex-smart-module';

import { createUIStoreServiceFactory } from '@/di/store-factory';

class State {
    public isImpersonating = false;
    public isRequestPending = false;
}

class StoreMutations extends Mutations<State> {
    public setImpersonationState(value: boolean) {
        this.state.isImpersonating = value;
    }

    public setRequestPending(value: boolean) {
        this.state.isRequestPending = value;
    }
}

const store = new Module({
    state: State,
    mutations: StoreMutations,
});

export const useImpersonationStore = createComposable(store);
export const ImpersonationStoreMapper = createMapper(store);

export const ImpersonationMappedStore = Vue.extend({
    computed: {
        ...ImpersonationStoreMapper.mapState(['isImpersonating']),
    },
});

export const ImpersonationStoreServiceFactory = createUIStoreServiceFactory({
    ...ImpersonationStoreMapper.mapState(['isImpersonating']),
    ...ImpersonationStoreMapper.mapMutations(['setImpersonationState', 'setRequestPending']),
});

export type ImpersonationStoreService = ReturnType<typeof ImpersonationStoreServiceFactory>;

export default store;
