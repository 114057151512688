import { Module, Mutations, Actions, createMapper, Getters, createComposable } from 'vuex-smart-module';
import { Vue } from 'vue-property-decorator';
import { getEmailSettings, setEmailSettings } from '@/api/providers/emails.provider';
import { EmailSettingsResponse } from '@eloomi/eloomi-emails-persona-client/1.0';

class State {
    public emailSettings: EmailSettingsResponse | null = null;
}

class StoreActions extends Actions<State, Getters<State>, StoreMutations, StoreActions> {
    public async getEmailSettingOptions() {
        const data = await getEmailSettings();
        this.mutations.setEmailSettingOptions(data);
    }
    public async setEmailSettingOptions(payload: Partial<EmailSettingsResponse>) {
        const data = {
            ...this.state.emailSettings,
            ...payload,
        };
        const res = await setEmailSettings(data);
        const emailSettingKey = Object.keys(payload)[0];
        this.mutations.updateEmailData({ key: emailSettingKey, value: res[emailSettingKey] });
    }
}

class StoreMutations extends Mutations<State> {
    setEmailSettingOptions(data) {
        this.state.emailSettings = data;
    }
    updateEmailData(payload) {
        if (this.state.emailSettings) {
            this.state.emailSettings[payload.key] = payload.value;
        }
    }
}

const EmailsStore = new Module({
    state: State,
    actions: StoreActions,
    mutations: StoreMutations,
});

export const useEmailsStore = createComposable(EmailsStore);

const emailStoreMapper = createMapper(EmailsStore);

export const EmailStoreMixin = Vue.extend({
    computed: {
        ...emailStoreMapper.mapState(['emailSettings']),
    },
    methods: {
        ...emailStoreMapper.mapActions(['getEmailSettingOptions']),
    },
});

export default EmailsStore;
