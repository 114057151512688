<template>
    <LoginPageLayout>
        <AuthenticationFlowPanel
            :title="trans('spa.authentication.platform-not-found.title')"
            :description="trans('spa.authentication.platform-not-found.description', { domain: platformDomain })"
            :centerVertically="true"
        ></AuthenticationFlowPanel>
    </LoginPageLayout>
</template>

<script lang="ts">
    import { Component, Mixins } from 'vue-property-decorator';

    import { TranslationMixin } from '@/common/mixins';
    import TrackMobileModeMixin from '@/common/mixins/mobile-mode.mixin';
    import type { PlatformResolutionService } from '@/common/services/platform-resolution';
    import { ServiceTokens } from '@/di/tokens';

    import AuthenticationFlowPanel from '../components/AuthenticationFlowPanel.vue';
    import LoginPageLayout from '../components/LoginPageLayout.vue';

    @Component({
        components: {
            LoginPageLayout,
            AuthenticationFlowPanel,
        },
    })
    export default class PlatformNotFoundPage extends Mixins(TrackMobileModeMixin, TranslationMixin) {
        private platformResolutionService = this.$dependencyContainer.resolve<PlatformResolutionService>(
            ServiceTokens.PlatformResolutionService
        );

        platformDomain = '';

        created() {
            this.platformDomain = this.platformResolutionService.getPlatformDomain();
        }
    }
</script>
