import { InstructorApiFactory } from '@eloomi/eloomi-event-management-persona-client/1.0/api';

import { authorizeClient } from '@/api/authorize-client';
import { extractAxiosData } from '@/common/services/extract-axios-data';

const instructorApiClient = authorizeClient(InstructorApiFactory);

export const isInstructor = () => {
    return instructorApiClient.isInstructor().then(extractAxiosData);
};
