<template>
    <div class="flex-full-width-layout" :style="{ flexDirection: direction }">
        <slot></slot>
    </div>
</template>

<script lang="ts">
    import { Component, Vue, Prop } from 'vue-property-decorator';

    @Component({})
    export default class CoreFlexFullWidthLayout extends Vue {
        @Prop({ default: 'row' }) public direction!: 'row' | 'column';
    }
</script>

<style lang="less" scoped>
    .flex-full-width-layout {
        display: flex;
        width: 100%;
        align-items: flex-end;
        flex-direction: column;
    }
</style>
