import { CertificationStatus } from '@eloomi/eloomi-learning-persona-client/1.0/models/certification-status';
import { UserAssignmentType } from '@eloomi/eloomi-learning-persona-client/1.0/models/user-assignment-type';
import { computed } from 'vue';

import { getListOfCourseAuthors, getListOfEventAuthors } from '@/admin/courses/providers/authors';
import { getInstructors } from '@/admin/events/providers';
import { getGroups } from '@/admin/groups/providers';
import { getAllRolesWithUserCount } from '@/admin/roles/providers/roles.provider';
import { getSkillsLite } from '@/admin/skills/providers/course-skills';
import { getCategoriesNew, getTopics } from '@/admin/topics/providers';
import {
    EnrollmentType,
    FilterType,
    Option,
    RoleType,
    VisibilityFilterOptions,
} from '@/common/components/filter/interfaces';
import { useLaunchDarkly, usePlanFeatures, useTranslation } from '@/common/composables';
import { MaxPageSizeForApi } from '@/common/constants/api';
import { getFullName } from '@/common/services';
import { getListOfSessionsAuthors } from '@/instructors/providers/sessions-authors';
import { getUserEventsCategories } from '@/reporting/events/events/providers/get-events-categories.provider';

export function useFiltersProvider() {
    const { trans } = useTranslation();
    const { checkIfLaunchDarklyFeatureEnabled, launchDarklyFeaturesList } = useLaunchDarkly();
    const { checkIfFeatureEnabled, featuresList } = usePlanFeatures();

    const isManagerEnrollmentEnabled = computed(() =>
        checkIfLaunchDarklyFeatureEnabled(launchDarklyFeaturesList.MANAGERS)
    );

    const getOptionsList = async (type: FilterType) => {
        let options: Option[] = [];
        let searchIsEnabled = true;
        let isSingleSelect = false;
        let showSelectAll = true;
        let withConfirmBtn = true;

        switch (type) {
            case FilterType.CourseAuthor: {
                const result = await getListOfCourseAuthors();
                options = result.map(author => ({
                    value: Number(author.id),
                    label: getFullName(author),
                }));
                break;
            }

            case FilterType.EventAuthor: {
                const result = await getListOfEventAuthors();
                options = result.map(author => ({
                    value: Number(author.id),
                    label: getFullName(author),
                }));
                break;
            }

            case FilterType.SessionAuthor: {
                const result = await getListOfSessionsAuthors();
                options = result.map(author => ({
                    value: Number(author.creator_id),
                    label: getFullName(author),
                }));
                break;
            }

            case FilterType.Visibility: {
                options = [
                    {
                        value: VisibilityFilterOptions.PastEvents,
                        label: trans('spa.report.events.events-report.events-table-filters.visibility.past-events'),
                    },
                    {
                        value: VisibilityFilterOptions.FutureEvents,
                        label: trans('spa.report.events.events-report.events-table-filters.visibility.future-events'),
                    },
                ];
                searchIsEnabled = false;
                isSingleSelect = true;
                withConfirmBtn = false;
                break;
            }

            case FilterType.SessionEnrollStatus: {
                options = [
                    {
                        value: false,
                        label: trans(
                            'spa.instructors.events.events-sessions.events-sessions-table-filters.status.open'
                        ),
                    },
                    {
                        value: true,
                        label: trans(
                            'spa.instructors.events.events-sessions.events-sessions-table-filters.status.closed'
                        ),
                    },
                ];
                searchIsEnabled = false;
                isSingleSelect = true;
                withConfirmBtn = false;
                break;
            }

            case FilterType.Category: {
                const result = await getCategoriesNew({ page: 1, pageSize: MaxPageSizeForApi });
                options = (result.data || []).map(
                    category =>
                        ({
                            value: category.id!,
                            label: category.name || '',
                        } as Option)
                );
                break;
            }

            case FilterType.EventCategory: {
                const result = await getUserEventsCategories({ page: 1, pageSize: MaxPageSizeForApi });
                options = (result.data || []).map(
                    category =>
                        ({
                            value: category.id!,
                            label: category.name || '',
                        } as Option)
                );
                break;
            }

            case FilterType.EventActivityStatus: {
                options = [
                    {
                        value: false,
                        label: trans('spa.admin.events.filters.status.active'),
                    },
                    {
                        value: true,
                        label: trans('spa.admin.events.filters.status.archived'),
                    },
                ];
                searchIsEnabled = false;
                isSingleSelect = true;
                withConfirmBtn = false;
                break;
            }

            case FilterType.RoleType: {
                options = [
                    {
                        value: RoleType.Custom,
                        label: trans('spa.admin.users-organization.filters.role-type.custom'),
                    },
                    {
                        value: RoleType.Standard,
                        label: trans('spa.admin.users-organization.filters.role-type.standard'),
                    },
                ];
                searchIsEnabled = false;
                break;
            }

            case FilterType.Roles: {
                const result = await getAllRolesWithUserCount({ page: 1, pageSize: MaxPageSizeForApi });
                options = (result.data || []).map(
                    role =>
                        ({
                            value: role.id!,
                            label: role.name || '',
                        } as Option)
                );
                break;
            }

            case FilterType.Active: {
                options = [
                    {
                        value: true,
                        label: trans('spa.admin.users-organization.filters.active.yes'),
                    },
                    {
                        value: false,
                        label: trans('spa.admin.users-organization.filters.active.no'),
                    },
                ];
                searchIsEnabled = false;
                break;
            }

            case FilterType.CourseType: {
                options.push(
                    {
                        value: 'eloomi',
                        label: 'eloomi',
                    },
                    {
                        value: 'scorm',
                        label: 'SCORM',
                    },
                    {
                        value: 'contentStore',
                        label: checkIfFeatureEnabled(featuresList.OPEN_SESAME)
                            ? trans('spa.admin.course-organization.filters-menu.options.course-type.open-sesame')
                            : trans('spa.admin.course-organization.filters-menu.options.course-type.content-store'),
                    }
                );

                searchIsEnabled = false;
                break;
            }
            case FilterType.Group: {
                const result = await getGroups({ page: 1, pageSize: MaxPageSizeForApi });
                options = (result.data || []).map(group => ({
                    value: Number(group.id),
                    label: group.name || '',
                }));
                break;
            }
            case FilterType.Instructor: {
                const result = await getInstructors();
                options = result.map(instructor => ({
                    value: Number(instructor.user_id),
                    label: getFullName(instructor),
                }));
                break;
            }
            case FilterType.Skill: {
                const result = await getSkillsLite({ page: 1, pageSize: MaxPageSizeForApi });
                options = (result.data || []).map(skill => ({
                    value: skill.id,
                    label: skill.name || '',
                }));
                break;
            }
            case FilterType.Topic: {
                const result = await getTopics({ page: 1, pageSize: MaxPageSizeForApi });
                options = (result.data || []).map(topic => ({
                    value: topic.id,
                    label: topic.name || '',
                }));
                break;
            }
            case FilterType.EventEnrollmentStatus: {
                if (isManagerEnrollmentEnabled.value) {
                    options.push({
                        value: EnrollmentType.ManagerEnrollment,
                        label: trans('spa.admin.course-organization.filters-menu.options.enrollment.manager'),
                    });
                }
                searchIsEnabled = false;
                showSelectAll = false;
                break;
            }
            case FilterType.CourseEnrollmentStatus: {
                options.push({
                    value: EnrollmentType.SelfEnrollment,
                    label: trans('spa.admin.course-organization.filters-menu.options.enrollment.self'),
                });

                if (isManagerEnrollmentEnabled.value) {
                    options.push({
                        value: EnrollmentType.ManagerEnrollment,
                        label: trans('spa.admin.course-organization.filters-menu.options.enrollment.manager'),
                    });
                }

                searchIsEnabled = false;
                break;
            }
            case FilterType.CertificationStatus: {
                options = [
                    CertificationStatus.Certified,
                    CertificationStatus.Expired,
                    CertificationStatus.Renewing,
                    CertificationStatus.Uncertified,
                ].map(v => ({
                    value: v,
                    label: trans(`spa.report.playlist.certification.status-${v}`),
                }));

                searchIsEnabled = false;
                withConfirmBtn = false;
                showSelectAll = false;
                break;
            }
            case FilterType.AssignmentType: {
                options = Object.values(UserAssignmentType).map(v => ({
                    value: v,
                    label: trans(`spa.report.playlist.certification.status-${v}`),
                }));

                searchIsEnabled = false;
                withConfirmBtn = false;
                showSelectAll = false;
                break;
            }
        }
        return { options, isSingleSelect, showSelectAll, searchIsEnabled, withConfirmBtn };
    };

    return { getOptionsList };
}
