import {
    CategoryTopicsResponse,
    EventStatus,
    UserEventSearchPaginatedResponse,
    UserTopicsResponse,
} from '@eloomi/eloomi-event-management-persona-client/1.0';
import { UserEventWithSessionsSearchResponse } from '@eloomi/eloomi-event-management-persona-client/1.0/models/user-event-with-sessions-search-response';
import { Actions, createComposable, createMapper, Getters, Module, Mutations } from 'vuex-smart-module';

import { getOverviewEvents, getUserEventCategories, getUserEventTopics } from '@/admin/events/providers';
import { SelectedOption } from '@/common/components/table';
import {
    datesAreEqual,
    filterIdsToNumberIds,
    FluentFilterCollection,
    getDate,
    getDayStart,
    Operator,
} from '@/common/services';

export class EventsOverviewQueryParams {
    pageSize = 12;
    page = 1;
    searchQuery = '';
    start: Date | null = getDayStart();
    end: Date | null = null;
    selectedCategory: CategoryTopicsResponse | null = null;
    selectedTopics: UserTopicsResponse[] = [];
    status: EventStatus | null = null;
}

export class EventsOverviewState extends EventsOverviewQueryParams {
    eventsData: UserEventSearchPaginatedResponse | null = null;
    mobileItemsList: UserEventWithSessionsSearchResponse[] = [];
    eventsCategories: CategoryTopicsResponse[] | null = null;
    eventsTopics: UserTopicsResponse[] | null = null;
    loading = false;
    sorting = null as SelectedOption | null;
}

export class EventsOverviewGetters extends Getters<EventsOverviewState> {
    public get loaded() {
        return this.state.eventsData !== null;
    }

    get filteredCourseTopics() {
        const topics = this.state.eventsTopics || [];

        if (this.state.selectedCategory) {
            const categoryTopicsIds = this.state.selectedCategory.topic_ids;

            return topics.filter(topic => categoryTopicsIds.includes(topic.topic_id!));
        } else {
            return topics;
        }
    }

    get filters() {
        const builder = new FluentFilterCollection();

        if (this.state.searchQuery) {
            builder.where('title').filter(Operator.Contains, this.state.searchQuery);
        }

        if (this.state.start) {
            builder.where('start_date').filter(Operator.GreaterThanOrEqual, this.state.start);
        }

        if (this.state.end) {
            builder.where('end_date').filter(Operator.LessThanOrEqual, this.state.end);
        }

        if (this.state.selectedCategory) {
            builder.where('category_id').filter(Operator.Have, this.state.selectedCategory.id);
        }

        if (this.state.selectedTopics.length > 0) {
            const selectedTopicsIds = this.state.selectedTopics.map(topic => topic.topic_id).join('|');
            builder.where('topic_id').filter(Operator.Have, selectedTopicsIds);
        }

        if (this.state.status) {
            builder.where('status').filter(Operator.IsEqualTo, this.state.status);
        }

        return builder;
    }

    get filtersSearchQuery() {
        return this.getters.filters.build();
    }

    get hasDateFilters() {
        return Boolean(this.state.start || this.state.end);
    }

    get hasNonDateFilters() {
        return (
            this.state.searchQuery !== '' ||
            this.state.selectedCategory !== null ||
            this.state.selectedTopics.length > 0 ||
            this.state.status !== null
        );
    }

    get hasFilters() {
        return this.getters.hasNonDateFilters || this.getters.hasDateFilters;
    }

    get hasNotDefaultFilters() {
        return (
            this.getters.hasNonDateFilters || !datesAreEqual(this.state.start, getDayStart()) || this.state.end !== null
        );
    }

    get numberOfFilters() {
        return (
            (this.getters.hasDateFilters ? 1 : 0) +
            (this.state.selectedTopics.length > 0 ? 1 : 0) +
            (this.state.status ? 1 : 0)
        );
    }
}

export class EventsOverviewMutations extends Mutations<EventsOverviewState> {
    setEventsData({
        data,
        appendNextPage = false,
    }: {
        data: UserEventSearchPaginatedResponse | null;
        appendNextPage?: boolean;
    }) {
        this.state.eventsData = data;
        const newMobileItems = [...(data?.data || [])];
        if (this.state.page === 1) {
            this.state.mobileItemsList = newMobileItems;
        } else if (appendNextPage) {
            this.state.mobileItemsList = [...this.state.mobileItemsList, ...newMobileItems];
        }
    }

    updateFilter(newFilter: Partial<EventsOverviewQueryParams>) {
        for (const key in newFilter) {
            if (newFilter[key] !== undefined) {
                this.state[key] = newFilter[key];
            }
        }
    }

    setCategories(categories: CategoryTopicsResponse[]) {
        this.state.eventsCategories = categories;
    }

    setTopics(topics: UserTopicsResponse[]) {
        this.state.eventsTopics = topics;
    }

    setSelectedEventsTopics(selectedTopics: UserTopicsResponse[]) {
        this.state.selectedTopics = selectedTopics;
    }

    setLoading(loading: boolean) {
        this.state.loading = loading;
    }

    setSearchQueryFromQuery(filtersCollection: FluentFilterCollection) {
        const filters = filtersCollection.getFilterTermForProperty('title', Operator.Contains);
        if (filters.length > 0) {
            this.state.searchQuery = filters.join(' ');
        }
    }

    setTopicFilterFromQuery(filtersCollection: FluentFilterCollection) {
        const topicsIdsString = filtersCollection.getFilterTermForProperty('topic_id')[0];

        if (topicsIdsString && this.state.eventsTopics) {
            const topicsIds = filterIdsToNumberIds(topicsIdsString);
            const topics = this.state.eventsTopics.filter(topic => topicsIds.includes(topic.topic_id!));
            this.state.selectedTopics = topics;
        }
    }

    setCategoryFilterFromQuery(filtersCollection: FluentFilterCollection) {
        const ids = filtersCollection.getFilterTermForProperty('category_id')[0] ?? false;

        if (ids && this.state.eventsCategories) {
            const formattedIds = filterIdsToNumberIds(ids);
            const selectedCategory =
                this.state.eventsCategories.find(category => formattedIds.includes(category.id!)) || null;

            this.state.selectedCategory = selectedCategory;
        }
    }

    setStartEndFromQuery(filtersCollection: FluentFilterCollection) {
        const startFilter = filtersCollection.getFilterTermForProperty('start_date')[0];
        const endFilter = filtersCollection.getFilterTermForProperty('end_date')[0];

        if (startFilter) {
            this.state.start = getDate(startFilter as string) || getDayStart();
        }
        if (endFilter) {
            this.state.end = getDate(endFilter as string);
        }
    }

    setStatusFromQuery(filtersCollection: FluentFilterCollection) {
        this.state.status = (filtersCollection.getFilterTermForProperty('status')[0] as EventStatus) || null;
    }

    setSorting(value: SelectedOption | null) {
        this.state.sorting = value;
        this.state.page = 1;
    }
}

export class EventsOverviewActions extends Actions<
    EventsOverviewState,
    EventsOverviewGetters,
    EventsOverviewMutations
> {
    async getEvents(sorts = null as SelectedOption | null) {
        if (this.state.loading) {
            return;
        }
        this.mutations.setLoading(true);
        const data = await getOverviewEvents({
            page: this.state.page,
            pageSize: this.state.pageSize,
            filters: this.getters.filtersSearchQuery,
            sorts: sorts,
        });
        this.mutations.setEventsData({ data });
        this.mutations.setLoading(false);
    }

    async getEventsNextPage() {
        if (this.state.loading) {
            return;
        }
        this.mutations.setLoading(true);
        this.mutations.updateFilter({ page: this.state.page + 1 });
        const data = await getOverviewEvents({
            page: this.state.page,
            pageSize: this.state.pageSize,
            filters: this.getters.filtersSearchQuery,
        });
        this.mutations.setEventsData({ data, appendNextPage: true });
        this.mutations.setLoading(false);
    }

    async getEventCategoriesAndTopics() {
        const [categories, topics] = await Promise.all([getUserEventCategories(), getUserEventTopics()]);
        this.mutations.setCategories(categories.data);
        this.mutations.setTopics(topics.data);
    }

    setSelectedCategory(category: CategoryTopicsResponse) {
        const updates: {
            selectedCategory: CategoryTopicsResponse | null;
            selectedTopics?: UserTopicsResponse[];
        } = { selectedCategory: null };

        if (!category || this.state.selectedCategory?.id === category.id) {
            updates.selectedCategory = null;
        } else {
            updates.selectedCategory = category;
            updates.selectedTopics = this.state.selectedTopics.filter(topic =>
                category.topic_ids.includes(topic.topic_id!)
            );
        }

        this.mutations.updateFilter(updates);
    }

    clearStore() {
        this.mutations.updateFilter({
            page: 1,
            pageSize: 12,
            searchQuery: '',
            selectedCategory: null,
            selectedTopics: [],
            start: getDayStart(),
            end: null,
        });
        this.mutations.setEventsData({ data: null });
        this.mutations.setSorting(null);
    }

    setFilters(query: string) {
        const filtersCollection = FluentFilterCollection.deserialize(query || '');

        this.mutations.setSearchQueryFromQuery(filtersCollection);
        this.mutations.setCategoryFilterFromQuery(filtersCollection);
        this.mutations.setTopicFilterFromQuery(filtersCollection);
        this.mutations.setStartEndFromQuery(filtersCollection);
        this.mutations.setStatusFromQuery(filtersCollection);
    }
}

export const eventsOverviewStoreModule = new Module({
    state: EventsOverviewState,
    getters: EventsOverviewGetters,
    mutations: EventsOverviewMutations,
    actions: EventsOverviewActions,
});

export const useEventsOverviewStore = createComposable(eventsOverviewStoreModule);
export const EventsOverviewStoreMapper = createMapper(eventsOverviewStoreModule);
