import { RouteConfig } from 'vue-router';

import { checkUserData } from '@/common/services/redirectIf.guard';
import { guards } from '@/main/multipleGuardsHandler';
import PlanFeaturesList from '@/plan-features/plan-features-list';
import { checkIfRouteAvailableByPlanFeatures } from '@/plan-features/routes';

const INSTRUCTOR_ROOT = () => import('@/instructors/pages/InstructorEventPage.vue');

export enum InstructorRouteNames {
    InstructorRoot = 'instructor-root',
}

const routes: RouteConfig[] = [
    {
        path: 'instructor',
        name: InstructorRouteNames.InstructorRoot,
        meta: {
            title: 'Instructor',
        },
        beforeEnter: guards([
            checkIfRouteAvailableByPlanFeatures(PlanFeaturesList.EVENTS),
            checkUserData(store => store.state.isInstructor),
        ]),
        component: INSTRUCTOR_ROOT,
    },
];

export default routes;
