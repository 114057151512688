<template>
    <TippyComponent
        v-if="!isMobile || (isMobile && !$slots['triggerMobile'])"
        class="tippy"
        theme="eloomi"
        :placement="placement"
        :animation="animation"
        :interactive="interactive"
        :delay="delay"
        :followCursor="followCursor"
        :trigger="trigger"
        :enabled="!isMobile && hasContent && !isDisabled"
        :visible="visible"
        boundary="window"
    >
        <template #trigger>
            <slot name="trigger" />
        </template>

        <template v-if="hasContent" #default>
            <slot name="content">
                {{ text }}
            </slot>
        </template>
    </TippyComponent>

    <TippyComponent
        v-else
        theme="eloomi"
        :placement="placement"
        :animation="animation"
        :interactive="interactive"
        :delay="delay"
        :followCursor="followCursor"
        :trigger="'manual'"
    >
        <template #trigger>
            <slot name="triggerMobile" />
        </template>
    </TippyComponent>
</template>

<script lang="ts">
    import { Component, Prop, Mixins } from 'vue-property-decorator';
    import { TippyComponent } from 'vue-tippy';
    import { MobileModeStoreMixin } from '@/common/store/mobile-mode';

    @Component({
        components: {
            TippyComponent,
        },
    })
    export default class CoreToolTip extends Mixins(MobileModeStoreMixin) {
        @Prop({ default: 'top' }) public placement!:
            | 'top'
            | 'bottom'
            | 'left'
            | 'right'
            | 'top-start'
            | 'top-end'
            | 'bottom-end'
            | 'bottom-start';
        @Prop({ default: 'shift-away' }) public animation!:
            | 'shift-away'
            | 'shift-toward'
            | 'scale'
            | 'fade'
            | 'perspective';
        @Prop({ default: 'mouseenter' }) public trigger!: 'mouseenter' | 'manual';
        @Prop({ default: true, type: Boolean }) public interactive!: boolean;
        @Prop({ default: 0 }) public delay!: number;
        @Prop({ default: false, type: Boolean }) public followCursor!: boolean;
        @Prop({ default: false, type: Boolean }) public visible!: boolean;
        @Prop({ default: false, type: Boolean }) public isDisabled!: boolean;
        @Prop({ default: '' }) public text!: string;

        public get hasContent() {
            return Boolean(this.text) || Boolean(this.$slots.content);
        }
    }
</script>
<style lang="less" scoped>
    .tippy > ::v-deep div {
        display: flex;
        outline: none;
    }
</style>
