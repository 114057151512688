import { NavigationGuardNext } from 'vue-router';

import { DEFAULT_PAGE_LOCATION } from '@/authentication/services/locations';
import notificationStore from '@/common/components/toast/store';
import { getBaseStore } from '@/main/get-base-store-router';
import translationsStore from '@/translations/store';

export const handlePageNotFound = (next: NavigationGuardNext) => {
    const trans = translationsStore.context(getBaseStore()).getters.trans;
    const notFoundMessage = trans('spa.navigation.sections.page-not-found');

    goToDefaultPage(next);

    notificationStore.context(getBaseStore()).actions.showDelayedNotification({
        theme: 'warning',
        message: notFoundMessage,
        duration: 3000,
    });
};

export const goToDefaultPage = (next: NavigationGuardNext) => {
    next(DEFAULT_PAGE_LOCATION);
};
