import { FailReasonCode } from '@eloomi/eloomi-users-external-client/1.0';

export class InvalidCredentialsError extends Error {
    private _code?: FailReasonCode;

    constructor(code?: FailReasonCode) {
        super(`Couldn't log in using provided credentials`);
        this._code = code;

        Object.setPrototypeOf(this, InvalidCredentialsError.prototype);
    }

    get code(): FailReasonCode | undefined {
        return this._code;
    }
}

export class PasswordChangeRequiredError extends Error {
    constructor() {
        super(`Password needs to be changed`);

        Object.setPrototypeOf(this, PasswordChangeRequiredError.prototype);
    }
}

export class InvalidValidationProvided extends Error {
    constructor() {
        super(`Only latin letters, digits, spaces within and @_-. chars allowed`);

        Object.setPrototypeOf(this, InvalidValidationProvided.prototype);
    }
}

export class InvalidEmailProvided extends Error {
    constructor() {
        super(`User account has no email`);

        Object.setPrototypeOf(this, InvalidEmailProvided.prototype);
    }
}

export class InvalidAccountProvided extends Error {
    constructor() {
        super(`User account not found`);

        Object.setPrototypeOf(this, InvalidAccountProvided.prototype);
    }
}

export class InvalidPlatformError extends Error {
    constructor() {
        super(`The provided platform id is different to active platform.`);

        Object.setPrototypeOf(this, InvalidPlatformError.prototype);
    }
}

export class PasswordMismatchError extends Error {
    constructor() {
        super(`Given passwords do not match.`);

        Object.setPrototypeOf(this, PasswordMismatchError.prototype);
    }
}

export class EmptyPasswordError extends Error {
    constructor() {
        super(`Passwords are empty.`);

        Object.setPrototypeOf(this, EmptyPasswordError.prototype);
    }
}

export class ValidationPasswordError extends Error {
    constructor() {
        super(`Your password does not meet the criteria.`);

        Object.setPrototypeOf(this, ValidationPasswordError.prototype);
    }
}

export class EmptyJwtTokenError extends Error {
    constructor() {
        super(`JWT token is empty.`);

        Object.setPrototypeOf(this, EmptyJwtTokenError.prototype);
    }
}

export class LoginSessionLoadError extends Error {
    constructor() {
        super(LoginSessionLoadError.message);
        Object.setPrototypeOf(this, LoginSessionLoadError.prototype);
    }

    static get message() {
        return `Couldn't load a session from local storage: it's undefined.`;
    }
}

export class InvalidTokenParamError extends Error {
    constructor() {
        super(`Token parameter is invalid.`);

        Object.setPrototypeOf(this, EmptyJwtTokenError.prototype);
    }
}

export class EntityNotFoundEndpointError extends Error {
    constructor() {
        super(`Endpoint error entity not found.`);

        Object.setPrototypeOf(this, EntityNotFoundEndpointError.prototype);
    }
}

export class TooManyLoginAttemptsError extends Error {
    constructor() {
        super(`Too many login attempts.`);

        Object.setPrototypeOf(this, TooManyLoginAttemptsError.prototype);
    }
}

export class AnonymousUserError extends Error {
    constructor() {
        super(`The user is anonymous and was not authenticated.`);

        Object.setPrototypeOf(this, AnonymousUserError.prototype);
    }
}

export class ImpossibleToRestoreSessionError extends Error {
    constructor() {
        super(`The session restore makes sense if no new sessions were started.`);

        Object.setPrototypeOf(this, ImpossibleToRestoreSessionError.prototype);
    }
}

export class InvalidSessionFound extends Error {
    constructor() {
        super(`Tried to restore a session but it's invalid.`);

        Object.setPrototypeOf(this, InvalidSessionFound.prototype);
    }
}

export class SessionRenewalFailed extends Error {
    constructor() {
        super(`Unable to renew a session.`);

        Object.setPrototypeOf(this, SessionRenewalFailed.prototype);
    }
}

export class PlatformDeactivatedError extends Error {
    constructor(domain: string) {
        super(`The platform "${domain}" is deactivated`);
        Object.setPrototypeOf(this, PlatformDeactivatedError.prototype);
    }
}
