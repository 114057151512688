/* eslint-disable vue/one-component-per-file */
import { createComposable, createMapper, Module, Mutations } from 'vuex-smart-module';

import { createUIStoreServiceFactory } from '@/di/store-factory';

class State {
    isOpenInAppNotificationShown = false;
}

class StoreMutations extends Mutations<State> {
    public showNotification() {
        this.state.isOpenInAppNotificationShown = true;
    }

    public hideNotification() {
        this.state.isOpenInAppNotificationShown = false;
    }
}

const store = new Module({
    state: State,
    mutations: StoreMutations,
});

export const useOpenInAppStore = createComposable(store);
export const openInAppStoreMapper = createMapper(store);

export const OpenInAppStoreServiceFactory = createUIStoreServiceFactory({
    ...openInAppStoreMapper.mapState(['isOpenInAppNotificationShown']),
    ...openInAppStoreMapper.mapMutations(['showNotification', 'hideNotification']),
});

export type OpenInAppStoreService = ReturnType<typeof OpenInAppStoreServiceFactory>;

export default store;
