import { Module, createMapper, createComposable } from 'vuex-smart-module';

import { UserCoursesActions } from './actions';
import { UserCoursesGetters } from './getters';
import { UserCoursesMutations } from './mutations';
import { UserCoursesState } from './state';

const UserCoursesStore = new Module({
    state: UserCoursesState,
    getters: UserCoursesGetters,
    mutations: UserCoursesMutations,
    actions: UserCoursesActions,
});
export const useUserCoursesStore = createComposable(UserCoursesStore);
export const userCoursesMapper = createMapper(UserCoursesStore);
export default UserCoursesStore;
