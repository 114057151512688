import { withDefaultValues } from '@/api/providers/branding.provider';
import { createBranding } from '@/common/models/branding.model';
import { overrideDesignSystemColorsWithBranding } from '@/common/services';
import type { PlatformResolutionService } from '@/common/services/platform-resolution';
import { baseContainer } from '@/di/container';
import { ServiceTokens } from '@/di/tokens';

export async function applyPlatformDesign() {
    const platformResolutionService = baseContainer.resolve<PlatformResolutionService>(
        ServiceTokens.PlatformResolutionService
    );
    const platformData = await platformResolutionService.getPlatformData();
    const brandingColors = createBranding(withDefaultValues(platformData.branding));

    overrideDesignSystemColorsWithBranding(brandingColors);
}
