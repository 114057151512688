<template>
    <div
        v-if="!isLoading"
        class="label"
        :class="[color, size, `label--${mode}`, { transparent }]"
        @click="$emit('click')"
    >
        <span class="text-label" data-testid="core-card-label">{{ value }}</span>
    </div>

    <SkeletonLoader v-else :width="skeletonWidth(45, 55)" height="24px" borderRadius="18px" class="skeleton-chip" />
</template>

<script lang="ts">
    import { computed, defineComponent, PropType } from 'vue';

    import { SkeletonLoader } from '@/common/components';
    import { CoreThemeMixin } from '@/common/mixins';
    import WithSkeleton from '@/common/mixins/with-skeleton.mixin';

    export type Sizes = 'small' | 'medium' | 'large';
    export type IconPosition = 'left' | 'right';

    export default defineComponent({
        components: { SkeletonLoader },
        mixins: [CoreThemeMixin, WithSkeleton],
        props: {
            value: { type: [String, Number], default: '' },
            transparent: { type: Boolean, default: false },
            size: { type: String as PropType<Sizes>, default: 'small' },
            iconPosition: { type: String as PropType<IconPosition>, default: 'left' },
            mode: { type: String as PropType<'default' | 'button'>, default: 'default' },
        },
        emits: ['click'],
        setup(props) {
            const iconSize = computed(() => {
                const sizes = { small: 10, medium: 16, large: 16 };
                return sizes[props.size] ?? 10;
            });
            return { iconSize };
        },
    });
</script>

<style lang="less" scoped>
    // eslint-disable vue-scoped-css/no-unused-selector

    .card-label-mixin(@color, @hover-color) {
        background-color: @color;

        &.label--button:hover {
            background-color: @hover-color;
        }
    }

    .card-label-mixin-no-background(@color) {
        color: @color;
    }

    .label {
        display: inline-flex;
        align-items: center;
        font-family: var(--font-family-standard);
        white-space: nowrap;
        .bright-color;

        .generic-transition(background-color);

        &--button {
            cursor: pointer;
            user-select: none;
        }

        &.small {
            padding: 3px @spacing-10 1px;
            border-radius: @border-radius-18;
        }

        &.medium {
            padding: @spacing-4 @spacing-24;
            border-radius: @border-radius-20;

            span {
                .h4;
            }
        }

        &.large {
            padding: @spacing-8 @spacing-24;
            border-radius: @border-radius-20;

            span {
                .h4;
            }
        }

        span {
            display: flex;
            align-items: center;
            height: 100%;
        }

        &.info {
            .card-label-mixin(@info-color, @info-color-80);
        }

        &.success {
            .card-label-mixin(@success-color, @success-color-80);
        }

        &.warning {
            .card-label-mixin(@warning-color, @warning-color-80);
        }

        &.danger {
            .card-label-mixin(@danger-color, @danger-color-80);
        }

        &.dark {
            .card-label-mixin(@dark-color, @dark-color-80);
        }

        &.bright {
            .card-label-mixin(@bright-color, @bright-color-80);
        }

        &.branding {
            .card-label-mixin(@branding-color, @branding-color-80);
        }

        &.primary {
            .card-label-mixin(@primary-color, @primary-color-80);
        }

        &.primary-8 {
            color: @primary-color;
            background-color: @primary-color-8;
        }

        &.info-8,
        &.secondary {
            color: @info-color;
            background-color: @info-color-8;
        }

        &.misc-orange {
            color: @bright-color;
            background-color: @misc-orange-color;
        }

        &.misc-blue-8 {
            color: @color-misc-blue;
            background-color: @misc-blue-color-8;
        }

        &.misc-green-8 {
            color: @misc-green-color;
            background-color: @misc-green-color-8;
        }

        &.misc-grey-8 {
            color: @color-misc-grey;
            background-color: @color-misc-grey-8;
        }

        &.transparent {
            padding: 0;
            color: @info-color;
            background-color: unset;

            &.dark {
                .card-label-mixin-no-background(@dark-color);
            }
        }
    }
</style>
