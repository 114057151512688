<template>
    <div class="shaped-image-wrapper">
        <span v-if="shouldHaveSubstate" class="background-substate" :class="avatarShape"></span>
        <span class="shaped-image" :class="imageClass" :style="avatarStyleWithBackground">
            <span
                v-if="!isImageAvailable"
                class="shaped-text-string"
                :class="additionalClass"
                :style="avatarStringStyle"
            >
                {{ alt }}
            </span>
            <template v-else-if="isLazy">
                <div v-if="asBackground" v-lazy:background-image="image" class="image-as-background"></div>
                <img v-else v-lazy="image" class="image" :class="avatarShape" @error="fieldToLoadImage" />
            </template>
            <template v-else>
                <div v-if="asBackground" class="image-as-background" :style="{ 'background-image': image }"></div>
                <img v-else class="image" :src="image" :class="avatarShape" @error="fieldToLoadImage" />
            </template>
        </span>
    </div>
</template>

<script lang="ts">
    import { color } from 'd3-color';
    import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

    import { getInitials } from '@/common/services';

    @Component({})
    export default class CoreShapedImage extends Vue {
        @Prop({ default: 'circle' }) public shape!: 'circle' | 'square';
        @Prop({ default: '' }) public image!: string | null;
        @Prop({ default: 32 }) public size!: number;
        @Prop({ default: '' }) public altText!: string | number;
        @Prop() public id!: number;
        @Prop({ default: '' }) public additionalClass!: string;
        @Prop({ default: true, type: Boolean }) public asBackground!: boolean;
        @Prop({ default: false, type: Boolean }) public withHoverBorder!: boolean;
        @Prop({ default: true, type: Boolean }) public isLazy!: boolean;
        @Prop({ default: false, type: Boolean }) public hasSubstate!: boolean;

        public failedToLoad = false;

        private colors: string[] = ['#06AED5', '#20BF55', '#FF8811', '#602ABA', '#FFCA0A', '#62929E', '#242325'];

        public mounted() {
            if (!this.isLazy) return;

            this.$Lazyload.$on('error', image => {
                if (image.src === this.image) {
                    this.fieldToLoadImage();
                }
            });
        }

        public get shouldHaveSubstate(): boolean {
            return this.hasSubstate && !this.image;
        }

        public get alt(): string {
            return typeof this.altText === 'number' || (typeof this.altText !== 'number' && this.altText.includes('+'))
                ? this.altText.toString()
                : getInitials(this.altText);
        }

        public get color() {
            return this.id ? this.pickColorFromId : this.pickColorFromText;
        }

        public get backgroundColor() {
            const bgcColor = color(this.color);
            if (bgcColor) {
                bgcColor.opacity = 0.08;
                return bgcColor?.formatRgb();
            } else {
                return this.color;
            }
        }

        public get pickColorFromId() {
            return this.colors[this.id % this.colors.length];
        }

        public get pickColorFromText() {
            return typeof this.altText === 'string'
                ? this.colors[this.altText.length % this.colors.length]
                : this.colors[this.altText % this.colors.length];
        }

        public get fontSize() {
            if (this.size < 80) {
                return 12;
            } else if (this.size < 120) {
                return 24;
            } else {
                return this.size * 0.3;
            }
        }

        public get avatarShape() {
            return this.shape === 'circle' ? 'circle' : 'square';
        }

        public get imageClass() {
            return {
                [this.avatarShape]: true,
                'circle-with-hover-border': this.withHoverBorder,
            };
        }

        public get avatarStringStyle() {
            return {
                fontSize: this.fontSize + 'px',
                color: this.color,
                fontWeight: this.size >= 80 ? 800 : 600,
            };
        }

        public get avatarStyleWithBackground() {
            return {
                width: this.size + 'px',
                height: this.size + 'px',
                backgroundColor: this.backgroundColor,
            };
        }

        @Emit('error')
        public fieldToLoadImage() {
            this.failedToLoad = true;
        }

        public get isImageAvailable() {
            return this.image && !this.failedToLoad;
        }

        public beforeDestroy() {
            this.$Lazyload.$off('error');
        }
    }
</script>

<style lang="less" scoped>
    .image-as-background {
        position: relative;
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
    }

    .shaped-image-wrapper {
        position: relative;
    }

    .shaped-image {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        overflow: hidden;
        text-align: center;
        text-transform: uppercase;

        & .shaped-text-string {
            position: relative;
            left: 0;
            color: @bright-color;
            line-height: 1;
            text-transform: uppercase;
            transform: translateX(0%) !important;
        }
    }

    .background-substate {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: @bright-color;
    }

    .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .circle-with-hover-border:hover {
        box-sizing: border-box;
        outline: 4px solid @primary-color-20;
    }

    .circle {
        border-radius: @border-round;
    }

    .square {
        border-radius: @border-radius-6;
    }
</style>
