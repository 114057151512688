import { SelectedOption, SortingOption, TablePaginatedData } from '@/common/components/table';
import { TranslationKey } from '@/translations';

export const IMPORT_TOPICS_STORAGE_KEY = 'import-course-topics';

export type AssignableGridItems = TablePaginatedData<AssignableGridObject>;

export interface AssignableGridObject {
    id?: number;
    title?: string | null;
    image?: string | null;
    duration?: number;
}

export type LanguageInfo = {
    key: string;
    value: TranslationKey;
    additionalKeys?: string[];
};

export const languagesMapped = [
    {
        key: 'ar',
        value: 'spa.courses-admin.content-store.filters.language.arabic',
    },
    {
        key: 'zh',
        value: 'spa.courses-admin.content-store.filters.language.chinese',
    },
    {
        key: 'hr',
        value: 'spa.courses-admin.content-store.filters.language.croatian',
    },
    {
        key: 'cs',
        value: 'spa.courses-admin.content-store.filters.language.czech',
    },
    {
        key: 'da',
        value: 'spa.courses-admin.content-store.filters.language.danish',
    },
    {
        key: 'nl',
        value: 'spa.courses-admin.content-store.filters.language.dutch',
    },
    {
        key: 'en',
        additionalKeys: ['English', 'UK'],
        value: 'spa.courses-admin.content-store.filters.language.english',
    },
    {
        key: 'fi',
        value: 'spa.courses-admin.content-store.filters.language.finnish',
    },
    {
        key: 'fr',
        value: 'spa.courses-admin.content-store.filters.language.french',
    },
    {
        key: 'fr-ca',
        value: 'spa.courses-admin.content-store.filters.language.french-canada',
    },
    {
        key: 'de',
        additionalKeys: ['German', 'De'],
        value: 'spa.courses-admin.content-store.filters.language.german',
    },
    {
        key: 'el',
        value: 'spa.courses-admin.content-store.filters.language.greek',
    },
    {
        key: 'hi',
        value: 'spa.courses-admin.content-store.filters.language.hindi',
    },
    {
        key: 'hu',
        value: 'spa.courses-admin.content-store.filters.language.hungarian',
    },
    {
        key: 'ms',
        value: 'spa.courses-admin.content-store.filters.language.malaysian',
    },
    {
        key: 'nb',
        value: 'spa.courses-admin.content-store.filters.language.norwegian',
    },
    {
        key: 'id',
        value: 'spa.courses-admin.content-store.filters.language.indonesian',
    },
    {
        key: 'it',
        value: 'spa.courses-admin.content-store.filters.language.italian',
    },
    {
        key: 'ja',
        value: 'spa.courses-admin.content-store.filters.language.japanese',
    },
    {
        key: 'ko',
        value: 'spa.courses-admin.content-store.filters.language.korean',
    },
    {
        key: 'pl',
        value: 'spa.courses-admin.content-store.filters.language.polish',
    },
    {
        key: 'pt',
        value: 'spa.courses-admin.content-store.filters.language.portuguese',
    },
    {
        key: 'pt-br',
        value: 'spa.courses-admin.content-store.filters.language.portuguese-brazil',
    },
    {
        key: 'ro',
        value: 'spa.courses-admin.content-store.filters.language.romanian',
    },
    {
        key: 'ru',
        value: 'spa.courses-admin.content-store.filters.language.russian',
    },
    {
        key: 'sk',
        value: 'spa.courses-admin.content-store.filters.language.slovak',
    },
    {
        key: 'es',
        additionalKeys: ['es-es'],
        value: 'spa.courses-admin.content-store.filters.language.spanish',
    },
    {
        key: 'es-do',
        value: 'spa.courses-admin.content-store.filters.language.spanish-dominican-republic',
    },
    {
        key: 'sv',
        value: 'spa.courses-admin.content-store.filters.language.swedish',
    },
    {
        key: 'th',
        value: 'spa.courses-admin.content-store.filters.language.thai',
    },
    {
        key: 'tr',
        value: 'spa.courses-admin.content-store.filters.language.turkish',
    },
    {
        key: 'vi',
        value: 'spa.courses-admin.content-store.filters.language.vietnamese',
    },
] as LanguageInfo[];

export type SortField =
    | 'created'
    | 'popularity'
    | 'relevance'
    | 'title'
    | 'created_at'
    | 'full_name'
    | 'event_start_date'
    | 'sessions_number';

export type Sorting = SelectedOption<SortField>;

export type SortOption = SortingOption<SortField>;
