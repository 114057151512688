<template>
    <div class="elm-popover">
        <div ref="targetRef" :class="targetClasses">
            <slot name="target" :isVisible="isVisible" :show="show" :hide="hide" :toggle="toggle" />
        </div>

        <template v-if="renderToPortal">
            <portal :to="portal">
                <div
                    v-if="isMounted"
                    v-show="isContentVisible"
                    ref="contentRef"
                    :key="keyInPortal"
                    :style="popoverStyles"
                    class="content"
                    :class="contentClass"
                    :data-placement="actualPlacement"
                    @click="handleContentClick"
                >
                    <transition name="sliding-fade" @after-leave="handleLeaveAnimationEnd">
                        <div v-if="isVisible" class="animation-wrapper" :data-testid="popoverContentTestId">
                            <slot v-if="isContentVisible" name="content" :hide="hide" :toggle="toggle" />
                        </div>
                    </transition>
                </div>
            </portal>
        </template>
        <template v-else>
            <div
                v-show="isContentVisible"
                ref="contentRef"
                :style="popoverStyles"
                class="content"
                :class="contentClass"
                :data-placement="actualPlacement"
                @click="handleContentClick"
            >
                <transition name="sliding-fade" @after-leave="handleLeaveAnimationEnd">
                    <div v-show="isVisible" class="animation-wrapper" :data-testid="popoverContentTestId">
                        <slot v-if="isContentVisible" name="content" :hide="hide" :toggle="toggle" />
                    </div>
                </transition>
            </div>
        </template>
    </div>
</template>

<script lang="ts">
    import { Offsets, Options } from '@popperjs/core';
    import { nanoid } from 'nanoid';
    import { computed, ComputedRef, defineComponent, inject, onMounted, PropType, ref, toRef, watch } from 'vue';

    import { IS_IN_MODAL } from '@/ui-kit/modal-window/context-keys';

    import { onClickOutside, useElementSize, usePopper } from '../composables';
    import { usePopperOptions } from './options';

    export default defineComponent({
        name: 'AppPopover',
        props: {
            autoMaxHeight: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            closeOnContentClick: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            closeOnOutsideClick: {
                type: Boolean as PropType<boolean>,
                default: true,
            },
            sameWidth: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            isTargetInline: {
                type: Boolean as PropType<boolean>,
                default: true,
            },
            positionStrategy: {
                type: String as PropType<Options['strategy']>,
                default: 'absolute',
            },
            placement: {
                type: String as PropType<Options['placement']>,
                default: 'bottom',
            },
            portal: {
                type: String as PropType<string>,
                default: 'modal-host',
            },
            renderToPortal: {
                type: Boolean as PropType<boolean>,
                default: true,
            },
            preventOutsideClickPropagation: {
                type: Boolean as PropType<boolean>,
                default: true,
            },
            value: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            targetClass: {
                type: String as PropType<string | undefined>,
                default: undefined,
            },
            autoHideOnOverflow: {
                type: Boolean as PropType<boolean>,
                default: true,
            },
            popoverTestName: {
                type: String as PropType<string>,
                default: '',
            },
            offset: {
                type: Object as PropType<Offsets | undefined>,
                default: undefined,
            },
        },
        emits: ['show', 'hide', 'blur', 'input'],
        setup(props, { emit }) {
            const placement = toRef(props, 'placement');
            const positionStrategy = toRef(props, 'positionStrategy');
            const isTargetInline = toRef(props, 'isTargetInline');
            const value = toRef(props, 'value');
            const autoMaxHeight = toRef(props, 'autoMaxHeight');
            const autoHide = toRef(props, 'autoHideOnOverflow');
            const offset = toRef(props, 'offset');
            const keyInPortal = nanoid();

            const isVisible = ref(false);
            const isContentVisible = ref(false);
            const isMounted = ref(false);
            const isInModal = inject<boolean>(IS_IN_MODAL, false);
            let isVModelModeEnabled = false;

            const { options, setListenersEnabled, actualPlacement } = usePopperOptions(
                placement,
                positionStrategy,
                autoMaxHeight,
                autoHide,
                offset
            );
            const { targetRef, contentRef, update } = usePopper(options);

            const popoverContentTestId = computed(() =>
                props.popoverTestName ? `popover-content-${props.popoverTestName}` : undefined
            );

            // Animations

            const handleLeaveAnimationEnd = () => {
                isContentVisible.value = isVisible.value;
            };

            // v-model

            watch(value, newValue => {
                isVModelModeEnabled = true;
                setPopoverVisibility(newValue);
            });

            // Inline styles

            const targetClasses = computed(() => [
                props.targetClass,
                {
                    'target-inline': isTargetInline.value,
                },
            ]);

            const contentClass = computed(() => {
                if (isInModal) {
                    return ['popover-in-modal'];
                } else {
                    return null;
                }
            });

            // Use same width as a target

            let popoverStyles: ComputedRef<{ width?: string }> | null = null;

            if (props.sameWidth) {
                const { width } = useElementSize(targetRef);

                popoverStyles = computed(() => {
                    const widthValue = width.value;

                    if (typeof widthValue === 'number' && props.sameWidth) {
                        return { width: `${widthValue}px` };
                    }

                    return {};
                });

                // listen for "realtime" changes and correct popper position
                watch(width, update);
            }

            // Close on content click

            const handleContentClick = () => {
                if (props.closeOnContentClick) {
                    hide();
                }
            };

            // Close on outside click

            let removeClickOutsideHandlers: (() => void) | null = null;

            const setClickOutsideHandlers = () => {
                removeClickOutsideHandlers = onClickOutside(
                    [targetRef, contentRef],
                    event => {
                        /** We use combination of capture and stopPropagation to prevent all other clicks */
                        if (props.preventOutsideClickPropagation) {
                            event.stopPropagation();
                        }
                        if (props.closeOnOutsideClick) {
                            hide();
                        }
                        emit('blur');
                    },
                    true
                );
            };

            const disposeClickOutsideHandlers = () => {
                if (removeClickOutsideHandlers !== null) {
                    removeClickOutsideHandlers();
                    removeClickOutsideHandlers = null;
                }
            };

            // Private

            const showPopover = () => {
                if (isVisible.value) {
                    return;
                }
                isContentVisible.value = true;
                isVisible.value = true;
                setListenersEnabled(true);
                setClickOutsideHandlers();
                // Wait a bit until portal make its work
                requestAnimationFrame(update);
                emit('show');
            };

            const hidePopover = () => {
                if (!isVisible.value) {
                    return;
                }
                isVisible.value = false;
                disposeClickOutsideHandlers();
                setListenersEnabled(false);
                emit('hide');
            };

            const setPopoverVisibility = (value: boolean) => {
                if (value) {
                    showPopover();
                } else {
                    hidePopover();
                }
            };

            // Public

            const show = () => {
                if (isVModelModeEnabled) {
                    emit('input', true);
                } else {
                    showPopover();
                }
            };

            const hide = () => {
                if (isVModelModeEnabled) {
                    emit('input', false);
                } else {
                    hidePopover();
                }
            };

            const setVisible = (value: boolean) => {
                if (value) {
                    show();
                } else {
                    hide();
                }
            };

            const toggle = () => {
                setVisible(!isVisible.value);
            };

            onMounted(() => {
                isMounted.value = true;
            });

            return {
                targetRef,
                contentRef,
                isVisible,
                isContentVisible,
                isMounted,
                keyInPortal,
                targetClasses,
                contentClass,
                actualPlacement,
                popoverStyles,
                handleLeaveAnimationEnd,
                handleContentClick,
                show,
                hide,
                setVisible,
                toggle,
                options,
                update,
                popoverContentTestId,
            };
        },
    });
</script>

<style lang="less" scoped>
    @offset: @spacing-8;

    .animation-wrapper {
        display: flex;
        max-height: inherit;
    }

    .target-inline {
        display: inline-block;
    }

    .content[data-popper-reference-hidden],
    .content[data-popper-escaped] {
        .animated-hidden();
    }

    .sliding-fade-enter-active,
    .sliding-fade-leave-active {
        .generic-transition(~'opacity, transform');
    }

    .sliding-fade-enter,
    .sliding-fade-leave-to {
        opacity: 0;
    }

    .content[data-placement^='bottom'] {
        .sliding-fade-enter,
        .sliding-fade-leave-to {
            transform: translateY(-@offset);
        }
    }

    .content[data-placement^='top'] {
        .sliding-fade-enter,
        .sliding-fade-leave-to {
            transform: translateY(@offset);
        }
    }

    .content[data-placement^='left'] {
        .sliding-fade-enter,
        .sliding-fade-leave-to {
            transform: translateX(@offset);
        }
    }

    .content[data-placement^='right'] {
        .sliding-fade-enter,
        .sliding-fade-leave-to {
            transform: translateX(-@offset);
        }
    }

    .content.popover-in-modal {
        z-index: @popover-in-modal-z-index;
    }
</style>
