<template>
    <div class="menu">
        <span class="label-uppercase-info menu-label">{{ menuLabel }}</span>
        <DropdownItem
            v-for="option of options"
            :key="option.value"
            :title="option.label"
            :label="option.selectedValuesCount"
            withButton
            @click="handleOptionClick(option)"
        />
    </div>
</template>

<script lang="ts">
    import { Component, Emit, Mixins, Prop } from 'vue-property-decorator';
    import { TranslationMixin } from '@/translations/store';
    import DropdownButton from '@/ui-kit/dropdown-button/DropdownButton.vue';
    import { DropdownItem } from '@/ui-kit';
    import { FilterType } from '@/common/components/filter/interfaces';

    export type FilterTypeOption = {
        value: FilterType;
        label: string;
        selectedValuesCount: number;
    };

    @Component({
        components: { DropdownButton, DropdownItem },
    })
    export default class FilterTypeMenu extends Mixins(TranslationMixin) {
        @Prop({ required: true, type: Array }) public options!: Array<FilterTypeOption>;
        @Prop({ required: true, type: Number }) public activeFiltersCount!: number;

        public get menuLabel() {
            return this.trans('spa.admin.course-organization.filters-menu.choose-filter', {
                num: this.activeFiltersCount,
            });
        }

        @Emit('select')
        public handleOptionClick(option: FilterTypeOption) {
            return option;
        }
    }
</script>

<style scoped lang="less">
    .menu {
        width: 192px;
    }

    .menu-label {
        display: block;
        padding: @spacing-16 @spacing-16 @spacing-12 @spacing-16;
    }
</style>
