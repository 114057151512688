import { Vue } from 'vue-property-decorator';
import { Actions, createMapper, Getters, Module, Mutations } from 'vuex-smart-module';

import { AccountDetails, DowngradeRestriction, DowngradeRestrictionResponse } from '@/admin/billing/models';
import { getAccountDetails, putAccountDetails } from '@/admin/billing/providers/account';
import { verifyLimits } from '@/admin/billing/providers/subscription';
import { checkIsAxiosError } from '@/common/services/check-is-axios-error';

interface ModalState<T> {
    visible: boolean;
    data: T | null;
}

interface DowngradeWarningData {
    type: 'users' | 'plan';
    oldUsersNumber: number | string;
    newUsersNumber: number | string;
    oldPlan: string;
    newPlan: string;
}

class State {
    public userLimitReachedModalShown = false;
    public closePlanModalShown = false;
    public billingDetailsModalShown = false;
    public accountDetails: AccountDetails = {
        company_name: '',
        vat: '',
        address: '',
        city: '',
        zip: '',
        state: '',
        country: '',
        recipient_name: '',
        recipient_email: '',
    };
    public cancelPlanModal: ModalState<DowngradeRestrictionResponse> = {
        visible: false,
        data: null,
    };
    public downgradeModal: ModalState<DowngradeRestriction[]> = {
        visible: false,
        data: null,
    };
    public downgradeWarningModal: ModalState<DowngradeWarningData> = {
        visible: false,
        data: null,
    };
    public contactUsModal: ModalState<null> = {
        visible: false,
        data: null,
    };
}

class StoreMutations extends Mutations<State> {
    public showLimitReachedModal() {
        this.state.userLimitReachedModalShown = true;
    }

    public closeLimitReachedModal() {
        this.state.userLimitReachedModalShown = false;
    }

    public showClosePlanModal() {
        this.state.closePlanModalShown = true;
    }

    public closeClosePlanModal() {
        this.state.closePlanModalShown = false;
    }

    public showCancelPlanModal(data: DowngradeRestrictionResponse) {
        this.state.cancelPlanModal.data = data;
        this.state.cancelPlanModal.visible = true;
    }

    public closeCancelPlanModal() {
        this.state.cancelPlanModal.data = null;
        this.state.cancelPlanModal.visible = false;
    }

    public setAccountDetails(details: AccountDetails) {
        this.state.accountDetails = details;
    }

    public updateAccountDetails(details: Partial<AccountDetails>) {
        this.state.accountDetails = {
            ...this.state.accountDetails,
            ...(details as AccountDetails),
        };
    }

    public showDowngradeModal(data: DowngradeRestriction[]) {
        this.state.downgradeModal.data = data;
        this.state.downgradeModal.visible = true;
    }

    public closeDowngradeModal() {
        this.state.downgradeModal.data = null;
        this.state.downgradeModal.visible = false;
    }

    public showDowngradeWarningModal(data: DowngradeWarningData) {
        this.state.downgradeWarningModal.data = data;
        this.state.downgradeWarningModal.visible = true;
    }

    public closeDowngradeWarningModal() {
        this.state.downgradeWarningModal.data = null;
        this.state.downgradeWarningModal.visible = false;
    }

    public showContactUsModal() {
        this.state.contactUsModal.visible = true;
    }

    public closeContactUsModal() {
        this.state.contactUsModal.visible = false;
    }

    public showBillingDetailsModal() {
        this.state.billingDetailsModalShown = true;
    }

    public closeBillingDetailsModal() {
        this.state.billingDetailsModalShown = false;
    }
}

class StoreGetters extends Getters<State> {}

class StoreActions extends Actions<State, StoreGetters, StoreMutations, StoreActions> {
    public async openCancelPlanModal() {
        const limitsResponse = await verifyLimits();
        const allowedToDowngrade = limitsResponse.length === 0;

        if (allowedToDowngrade) {
            this.mutations.showClosePlanModal();
        } else {
            this.mutations.showCancelPlanModal({
                scheduled_restrictions_details: limitsResponse,
            });
        }
    }

    public async loadAccountDetails() {
        try {
            this.mutations.setAccountDetails(await getAccountDetails());
        } catch {
            console.error('Unable to fetch account details');
        }
    }

    public async saveAccountDetails() {
        try {
            putAccountDetails(this.state.accountDetails!);
        } catch {
            console.error('Unable to save company details');
        }
    }

    public catchSavingError(error) {
        if (checkIsAxiosError<DowngradeRestrictionResponse>(error)) {
            const scheduledRestrictionError = error.response?.data.reason === 'scheduledRestrictions';

            if (error.response?.status === 400 && scheduledRestrictionError) {
                this.mutations.showDowngradeModal(error.response.data.scheduled_restrictions_details);
                return;
            }
        }
    }
}

export const store = new Module({
    state: State,
    mutations: StoreMutations,
    getters: StoreGetters,
    actions: StoreActions,
});

export const billingAdministrationStoreMapper = createMapper(store);
export const BillingAdministrationMappedStore = Vue.extend({
    computed: {
        ...billingAdministrationStoreMapper.mapState([
            'userLimitReachedModalShown',
            'closePlanModalShown',
            'billingDetailsModalShown',
            'accountDetails',
            'downgradeModal',
            'downgradeWarningModal',
            'contactUsModal',
            'cancelPlanModal',
        ]),
    },
    methods: {
        ...billingAdministrationStoreMapper.mapMutations([
            'showLimitReachedModal',
            'closeLimitReachedModal',
            'showClosePlanModal',
            'closeClosePlanModal',
            'showCancelPlanModal',
            'closeCancelPlanModal',
            'showBillingDetailsModal',
            'closeBillingDetailsModal',
            'updateAccountDetails',
            'showDowngradeModal',
            'closeDowngradeModal',
            'showDowngradeWarningModal',
            'closeDowngradeWarningModal',
            'showContactUsModal',
            'closeContactUsModal',
        ]),
        ...billingAdministrationStoreMapper.mapActions([
            'openCancelPlanModal',
            'loadAccountDetails',
            'saveAccountDetails',
            'catchSavingError',
        ]),
    },
});
