import { render, staticRenderFns } from "./CoreCheckbox.vue?vue&type=template&id=50bb03ee&scoped=true&"
import script from "./CoreCheckbox.vue?vue&type=script&lang=ts&"
export * from "./CoreCheckbox.vue?vue&type=script&lang=ts&"
import style0 from "./CoreCheckbox.vue?vue&type=style&index=0&id=50bb03ee&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50bb03ee",
  null
  
)

export default component.exports