import { defaultLanguage, Language, supportedLanguages } from '@/translations';

export function getSupportedLanguage(language?: string | null) {
    if (!language) {
        language = navigator.language;
    }

    if ((supportedLanguages as string[]).includes(language)) {
        return language as Language;
    }

    const [languageCode] = language.split('-');

    const languageCodeMatch = supportedLanguages.find(l => l.startsWith(languageCode));

    return languageCodeMatch || defaultLanguage;
}
