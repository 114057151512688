<template>
    <label
        tabindex="0"
        class="checkbox-wrapper"
        :class="{ checked: innerValue, disabled, hovered }"
        @keyup.space.enter="toggle"
    >
        <input type="checkbox" :disabled="disabled" :value="value" />

        <span class="core-checkbox" :style="checkboxStyles" @click.stop="toggle">
            <slot v-show="innerValue" name="icon">
                <elm-alerts-info-approve-checkmark-icon v-show="innerValue === true" class="icon" size="10" />
            </slot>
        </span>

        <span :class="{ 'checkbox-label': label }" @click.stop="toggle">
            <slot>{{ label }}</slot>
        </span>
    </label>
</template>

<script lang="ts">
    import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';

    @Component({})
    export default class CoreCheckbox extends Vue {
        @Prop({ default: false }) public defaultValue!: boolean;
        @Prop({ default: false, type: Boolean }) public disabled!: boolean;
        @Prop({ default: '' }) public label!: string;
        @Prop() public value!: boolean;
        @Prop() public color!: string;
        @Prop({ default: false }) public reset?: boolean;
        @Prop({ default: 18 }) public size?: number;
        @Prop({ default: false }) public useStrictSize?: boolean;
        @Prop({ default: false, type: Boolean }) public round?: boolean;
        @Prop({ default: false }) public prevent!: boolean;
        @Prop({ default: false }) public transparent!: boolean;
        @Prop({ default: false, type: Boolean }) public hovered!: boolean;

        public innerValue = false;

        public toggle() {
            if (this.disabled || this.prevent) {
                return;
            }
            this.innerValue = !this.innerValue;
            this.onChange();
            this.onInput();
        }

        public get checkboxStyles() {
            const styles: {
                width: string;
                height: string;
                'min-width'?: string;
                'min-height'?: string;
                backgroundColor?: string;
                borderColor?: string;
            } = {
                width: `${this.size}px`,
                height: `${this.size}px`,
            };
            if (this.color) {
                styles.backgroundColor = this.color;
                styles.borderColor = this.color;
            }
            if (this.useStrictSize) {
                styles['min-height'] = `${this.size}px`;
                styles['min-width'] = `${this.size}px`;
            }
            if (this.round) {
                styles['border-radius'] = '50%';
            }
            if (this.transparent && !this.innerValue) {
                styles.backgroundColor = 'transparent';
            }
            return styles;
        }

        @Watch('value')
        public valueWatcher(e) {
            this.innerValue = e;
        }

        @Emit('change')
        public onChange() {
            return this.innerValue;
        }

        @Emit('input')
        public onInput() {
            return this.innerValue;
        }

        public mounted() {
            this.innerValue = this.defaultValue || this.value;
        }

        @Watch('reset')
        public resetValue() {
            if (this.innerValue) {
                this.innerValue = false;
            }
        }
    }
</script>

<style lang="less" scoped>
    @checkbox-dimension: 18px;
    @checkbox-border-radius: @border-radius-4;
    @checkbox-icon-size: 10px;

    .checkbox-dimension {
        width: @checkbox-dimension;
        height: @checkbox-dimension;
        border-radius: @checkbox-border-radius;
    }

    .checkbox-wrapper {
        display: inline-flex;
        align-items: center;

        &:hover:not(.checked, .disabled),
        &.hovered:not(.checked, .disabled) {
            outline: none;

            .core-checkbox {
                border-color: @info-color-60;

                .icon {
                    display: flex !important;
                    color: @info-color-40;

                    &::before {
                        line-height: 1;
                    }
                }
            }
        }

        input {
            display: none;
        }

        .checkbox-label {
            margin-left: @spacing-12;
            cursor: pointer;
        }

        .core-checkbox {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: @checkbox-dimension;
            height: @checkbox-dimension;
            color: @bright-color;
            background-color: @bright-color;
            border: 1px solid @info-color-40;
            border-radius: @checkbox-border-radius;
            cursor: pointer;
            transition: @generic-transition;
        }

        &.checked {
            .core-checkbox {
                background-color: @primary-color;
                border-color: @primary-color;

                .icon {
                    color: @bright-color;
                }
            }
        }

        &.disabled {
            .core-checkbox {
                background-color: @info-color-8;
                border-color: @info-color-40;
                pointer-events: none;
            }

            .core-checkbox .icon {
                color: @info-color-40;
            }
        }
    }
</style>
