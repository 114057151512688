import { CustomFieldBaseRequest, UserSchemaFieldResponse } from '@eloomi/eloomi-users-persona-client/1.0';
import { Actions, createComposable, createMapper, Getters, Module, Mutations } from 'vuex-smart-module';
import {
    createCustomField,
    deleteCustomField,
    getCustomFields,
    updateCustomField,
    getExtendedCustomFields,
} from '@/admin/settings/providers/custom-fields';
import { Vue } from 'vue-property-decorator';
import { DataRequestOptions } from '@/common/components/table';

class State {
    public customFieldsLite: UserSchemaFieldResponse[] | null = null;
}

class StoreMutations extends Mutations<State> {
    public setCustomFieldsLite(customFields: UserSchemaFieldResponse[] | null) {
        this.state.customFieldsLite = customFields;
    }
}

class StoreActions extends Actions<State, Getters<State>, StoreMutations, StoreActions> {
    public async initCustomFieldsLite() {
        this.mutations.setCustomFieldsLite((await getExtendedCustomFields()));
    }

    public async createCustomField(customField: CustomFieldBaseRequest) {
        await createCustomField(customField);
        this.mutations.setCustomFieldsLite(null);
    }

    public async updateCustomField({ id, customField }: { id: number; customField: CustomFieldBaseRequest }) {
        await updateCustomField(id, customField);
        this.mutations.setCustomFieldsLite(null);
    }

    public async deleteCustomField(id: number) {
        await deleteCustomField(id);
        this.mutations.setCustomFieldsLite(null);
    }

    public async getCustomFields(options: DataRequestOptions) {
        return getCustomFields(options);
    }
}

const storeModule = new Module({
    state: State,
    mutations: StoreMutations,
    actions: StoreActions,
});

export const useCustomFieldsStore = createComposable(storeModule);

export const storeMapper = createMapper(storeModule);
export const CustomFieldsMappedStore = Vue.extend({
    computed: {
        ...storeMapper.mapState(['customFieldsLite']),
    },
    methods: {
        ...storeMapper.mapActions([
            'createCustomField',
            'deleteCustomField',
            'getCustomFields',
            'initCustomFieldsLite',
            'updateCustomField',
        ]),
    },
});

export default storeModule;
