<template>
    <div v-if="isMobile">
        <slot name="mobile">
            <MobileHeader :title="title" :withBackButton="withBackButton" :withNotifications="withNotifications">
                <template #left>
                    <slot name="mobile-left" />
                </template>
                <template #right>
                    <slot name="mobile-right" />
                </template>
            </MobileHeader>
        </slot>
    </div>
    <div v-else>
        <slot name="desktop">
            <DesktopHeader />
        </slot>
    </div>
</template>

<script lang="ts">
    import { Component, Mixins, Prop } from 'vue-property-decorator';
    import DesktopHeader from '@/common/components/app-header//DesktopHeader.vue';
    import MobileHeader from '@/common/components/app-header/MobileHeader.vue';
    import { MobileModeStoreMixin } from '@/common/store/mobile-mode';

    @Component({ components: { DesktopHeader, MobileHeader } })
    export default class AppHeader extends Mixins(MobileModeStoreMixin) {
        @Prop() title?: string;
        @Prop({ default: false, type: Boolean }) withBackButton?: boolean;
        @Prop({ default: false, type: Boolean }) withNotifications?: boolean;
    }
</script>
