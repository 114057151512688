import {
    EventUserApiFactory,
    SessionApiFactory,
    SessionUserAdminApiFactory,
    SessionUserApiFactory,
    SessionUserStatus,
    SignUpToSessionRequest,
} from '@eloomi/eloomi-event-management-persona-client/1.0';
import { UpdateSessionSettingsRequest } from '@eloomi/eloomi-event-management-persona-client/1.0/models';

import { GetAssignmentsOptions } from '@/admin/courses/components/assignment/interfaces';
import { authorizeClient } from '@/api/authorize-client';
import { buildFilter } from '@/common/services';
import { extractAxiosData } from '@/common/services/extract-axios-data';

const sessionUserAdminApiClient = authorizeClient(SessionUserAdminApiFactory);
const sessionUserApiClient = authorizeClient(SessionUserApiFactory);
const eventUserApiClient = authorizeClient(EventUserApiFactory);
const sessionApiClient = authorizeClient(SessionApiFactory);

export function getEventSignup(eventId: number) {
    return eventUserApiClient.getUserSessions(eventId).then(extractAxiosData);
}

export function getEventSessionsUsersNumber(eventId: number) {
    return sessionUserAdminApiClient.getEventSessionsUsersNumber(eventId).then(extractAxiosData);
}

export function getSessionUsersNumber(sessionId: number) {
    return sessionUserAdminApiClient.getSessionUsersNumber(sessionId).then(extractAxiosData);
}

export function signUpToSession(sessionId: number, options?: SignUpToSessionRequest) {
    return sessionUserApiClient.signUpToSession(sessionId, options);
}

export function cancelSessionSignUp(sessionId: number) {
    return sessionUserApiClient.cancelSessionSignUp(sessionId);
}

export function getSessionUsers({
    sessionId,
    searchQuery,
    sorts,
    page,
    pageSize,
}: GetAssignmentsOptions & { sessionId: number }) {
    const filters = buildFilter('full_name', searchQuery);
    return sessionUserAdminApiClient.getSessionUsers(sessionId, filters, sorts, page, pageSize).then(extractAxiosData);
}

export function updateSessionUsers(sessionId: number, user_ids: number[], status: SessionUserStatus) {
    return sessionUserAdminApiClient.updateSessionUsers(sessionId, { user_ids, status }).then(extractAxiosData);
}

export function cancelUsersSignUp(sessionId: number, userIds: number[]) {
    return sessionUserAdminApiClient.updateSessionUsers(sessionId, {
        user_ids: userIds,
        status: SessionUserStatus.Cancelled,
    });
}

export function updateSessionSettings(sessionId: number, updateSessionSettingsRequest: UpdateSessionSettingsRequest) {
    return sessionApiClient.updateSessionSettings(sessionId, updateSessionSettingsRequest);
}
