import { ElementType } from '@/common/types';

export enum FilterDropdownMode {
    FilterSelect,
    Duration,
    Skill,
    Topic,
    Date,
    Status,
}

export enum DurationFilterType {
    Any,
    D_0_15,
    D_15_30,
    D_30_plus,
}

export enum TypeFilterType {
    ShowAll,
    Deadline,
    Required,
    Completed,
}

export interface DurationFilter {
    label: string;
    icon: string;
    type: DurationFilterType;
    from?: number;
    to?: number;
}

export interface TypeFilter {
    label: string;
    required?: boolean;
    type: TypeFilterType;
}

export const availableDurationFilterTypes: readonly DurationFilter[] = Object.freeze([
    {
        label: 'spa.label.show-all-text',
        icon: 'timer',
        type: DurationFilterType.Any,
    },
    {
        label: 'spa.label.0-15-minutes',
        icon: 'timer',
        type: DurationFilterType.D_0_15,
        from: 0,
        to: 15 * 60,
    },
    {
        label: 'spa.label.15-30-minutes',
        icon: 'timer',
        type: DurationFilterType.D_15_30,
        from: 15 * 60,
        to: 30 * 60,
    },
    {
        label: 'spa.label.more-than-30-minutes',
        icon: 'timer',
        type: DurationFilterType.D_30_plus,
        from: 30 * 60,
    },
]);

export const availableTypeFilterTypes: readonly TypeFilter[] = Object.freeze([
    {
        label: 'spa.label.show-all-text',
        type: TypeFilterType.ShowAll,
    },
    {
        label: 'spa.label.completed',
        type: TypeFilterType.Completed,
    },
    {
        label: 'spa.label.required',
        type: TypeFilterType.Required,
    },
    {
        label: 'spa.label.deadline',
        type: TypeFilterType.Deadline,
    },
]);

export enum FilterProperties {
    titleProperty = 'Title',
    descriptionProperty = 'Description',
    durationProperty = 'Duration',
    requiredProperty = 'is_required',
    deadlineProperty = 'deadline_date',
    completedProperty = 'is_completed',
    topicId = 'topic_id',
    skillId = 'skill_ids',
    topicsProperty = 'Topics',
    categoryId = 'category_id',
}

export const learningItemTypes = ['courses', 'events'] as const;
export type LearningItemType = ElementType<typeof learningItemTypes>;
