import { UserImpersonationApiFactory } from '@eloomi/eloomi-users-persona-client/1.0';
import { DependencyContainer, Lifecycle } from 'tsyringe';

import { authorizeClient } from '@/api/authorize-client';

import { ImpersonationServiceTokens as Tokens } from './di-tokens';
import { UserImpersonationService } from './services/impersonation';
import { ImpersonationStoreServiceFactory } from './store';
import { CheckIfImpersonationAllowedUseCase } from './use-cases/check-if-allowed';
import { ImpersonationSessionStoreSyncUseCase } from './use-cases/handle-session-restore';
import { ImpersonateUserUseCase } from './use-cases/impersonate-user';
import { StopImpersonationSessionUseCase } from './use-cases/stop-impersonation-session';

export function registerImpersonationServices(container: DependencyContainer): DependencyContainer {
    container.register(Tokens.UserImpersonationApi, { useFactory: () => authorizeClient(UserImpersonationApiFactory) });
    container.register(Tokens.ImpersonationStoreService, { useFactory: ImpersonationStoreServiceFactory });
    container.register(Tokens.UserImpersonationService, { useClass: UserImpersonationService });

    container.register(StopImpersonationSessionUseCase, { useClass: StopImpersonationSessionUseCase });
    container.register(ImpersonateUserUseCase, { useClass: ImpersonateUserUseCase });
    container.register(
        CheckIfImpersonationAllowedUseCase,
        { useClass: CheckIfImpersonationAllowedUseCase },
        { lifecycle: Lifecycle.Singleton }
    );
    container.register(ImpersonationSessionStoreSyncUseCase, {
        useClass: ImpersonationSessionStoreSyncUseCase,
    });

    return container;
}
