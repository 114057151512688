import type { DependencyContainer } from 'tsyringe';
import type { PluginObject } from 'vue';

declare module 'vue/types/vue' {
    interface Vue {
        $dependencyContainer: DependencyContainer;
    }
}

export default {
    install(Vue, dependencyContainer: DependencyContainer) {
        Vue.prototype.$dependencyContainer = dependencyContainer;
    },
} as PluginObject<DependencyContainer>;
