<template>
    <div class="progress-bar" :class="`label-${labelPosition}`">
        <slot name="customLabel">
            <div v-if="withLabel" class="percentage-label" :style="computedLabelStyles">{{ formattedValue }}</div>
        </slot>

        <div class="bar-wrapper">
            <div class="empty" :class="{ 'with-background': withBackground }"></div>
            <div
                v-if="horizontalBar !== undefined && horizontalBar !== null"
                class="horizontal-bar"
                :style="{ left: `calc(${horizontalBar}% + 8px)` }"
            />
            <div class="filled" :style="computedStyles"></div>
        </div>
    </div>
</template>

<script lang="ts">
    import { computed, defineComponent, PropType } from 'vue';

    import { Themes } from '@/common/classes/themes';

    const colorRanges = [
        {
            range: [75, 100],
            color: 'color-success',
        },
        {
            range: [50, 75],
            color: 'color-success-80',
        },
        {
            range: [25, 50],
            color: 'color-warning',
        },
        {
            range: [0, 25],
            color: 'color-danger',
        },
    ];

    export default defineComponent({
        props: {
            value: {
                type: Number as PropType<number>,
                default: 0,
            },
            withLabel: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            showPercentsOnLabel: {
                type: Boolean as PropType<boolean>,
                default: true,
            },
            showDecimalsOnLabel: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            theme: {
                type: String as PropType<Themes>,
                default: null,
            },
            labelPosition: {
                type: String as PropType<'top' | 'left'>,
                default: 'top',
            },
            horizontalBar: {
                type: Number,
                required: false,
                default: undefined,
            },
            withBackground: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            colorRanges: {
                type: Array as PropType<
                    {
                        range: number[];
                        color: string;
                    }[]
                >,
                required: false,
                default: undefined,
            },
        },
        setup(props) {
            const computedValue = computed(() => {
                const value = props.value ?? 0;
                return Math.max(0, Math.min(100, value));
            });

            const calculatedFillColor = computed(() => {
                if (props.theme) {
                    return `var(--color-${props.theme})`;
                } else {
                    const colorRangesArray = props.colorRanges ?? colorRanges;
                    const foundColor =
                        colorRangesArray.find(
                            colorRange =>
                                colorRange.range[0] < computedValue.value && computedValue.value <= colorRange.range[1]
                        )?.color ?? 'color-info-40';

                    return `var(--${foundColor})`;
                }
            });

            const computedStyles = computed(() => {
                return {
                    width: `calc(${computedValue.value}% + 8px)`,
                    background: calculatedFillColor.value,
                };
            });

            const computedLabelStyles = computed(() => {
                return {
                    color: calculatedFillColor.value,
                };
            });

            const formattedValue = computed(() => {
                const value = props.showDecimalsOnLabel ? props.value : Math.round(props.value);

                return props.showPercentsOnLabel ? `${value}%` : value;
            });

            return {
                computedStyles,
                computedLabelStyles,
                formattedValue,
            };
        },
    });
</script>

<style lang="less" scoped>
    @bar-height: 12px;
    @min-width: 8px;
    @side-padding: @spacing-2;

    .progress-bar {
        width: 100%;

        &.label-left {
            position: relative;
            display: flex;
            align-items: center;

            .percentage-label {
                position: absolute;
                right: 100%;
                margin-right: @spacing-12;
            }
        }
    }

    .bar-wrapper {
        position: relative;
        width: calc(100% - @min-width - 2 * @side-padding);
        height: @bar-height;
    }

    .filled,
    .empty {
        height: @bar-height;
        border-radius: @border-radius-6;
    }

    .filled {
        position: relative;
        top: @side-padding;
        left: @side-padding;
        height: calc(@bar-height - 2 * @side-padding);
        box-shadow: inset 0 -2px 1px 0 rgba(15, 35, 67, 0.16);
        transform: translateY(-@bar-height);
        .generic-transition(~'width, background');
    }

    .empty {
        width: calc(100% + @min-width + 2 * @side-padding);
        box-shadow: inset -0.5px -1.5px 1px 0.5px rgba(15, 35, 67, 0.06);
    }

    .percentage-label {
        .generic-transition(~'color');
        .info-line-bold();
    }

    .horizontal-bar {
        position: absolute;
        top: 50%;
        width: 2px;
        height: 16px;
        background: @info-color-40;
        border-radius: @border-radius-24;
        transform: translate(-50%, -50%);
    }

    .with-background {
        background: @info-color-20;
    }
</style>
