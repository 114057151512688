import { computed, ref } from 'vue';

const openedModalIds = ref<string[]>([]);

export function useModalStack() {
    const lastOpenedModal = computed(() => {
        return openedModalIds.value[openedModalIds.value.length - 1];
    });

    const addLastOpenedModal = (modalId: string) => {
        openedModalIds.value.push(modalId);
    };

    const removeLastOpenedModal = () => {
        return openedModalIds.value.pop();
    };

    const removeIdFromList = (id: string) => {
        openedModalIds.value = openedModalIds.value.filter(openedId => id !== openedId);
    };

    const hasOpenedModals = computed(() => {
        return openedModalIds.value.length > 0;
    });

    return {
        addLastOpenedModal,
        removeLastOpenedModal,
        lastOpenedModal,
        removeIdFromList,
        hasOpenedModals,
    };
}
