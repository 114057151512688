<template>
    <portal to="modal-host">
        <transition :name="directionName">
            <div v-if="visible" class="overlay-popup" :class="[{ 'has-subheader': hasSubheader }, className]">
                <slot></slot>
            </div>
        </transition>
    </portal>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { PropType } from 'vue';

    export type Directions = 'bottom-top' | 'right-left';

    @Component({})
    export default class OverlayModal extends Vue {
        @Prop({ default: false }) public visible!: boolean;
        @Prop({ default: true }) public hasSubheader!: boolean;
        @Prop({ default: '' }) public className!: string;
        @Prop({ default: 'bottom-top', type: String as PropType<Directions> }) public direction!: Directions;

        public get directionName() {
            return `visibility-${this.direction}`;
        }
    }
</script>

<style lang="less" scoped>
    .overlay-popup {
        position: fixed;
        top: @core-subheader-height;
        z-index: 1;
        width: 100%;
        height: calc(100% - @core-subheader-height);
        overflow: auto;
        background: @bright-color;
        border-radius: 8px;

        &.has-subheader {
            top: @core-subheader-height-mobile;
            height: calc(100% - @core-subheader-height-mobile);
        }
    }

    .visibility-bottom-top-enter-active,
    .visibility-bottom-top-leave-active {
        .generic-transition(transform);
    }

    .visibility-bottom-top-enter,
    .visibility-bottom-top-leave-to {
        transform: translateY(100%);
    }

    .visibility-right-left-enter-active,
    .visibility-right-left-leave-active {
        .generic-transition(transform);
    }

    .visibility-right-left-enter,
    .visibility-right-left-leave-to {
        transform: translateX(100%);
    }
</style>
