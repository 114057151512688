import debug from 'debug';

import environment from '@/environment';

type DebugModules =
    | 'app'
    | 'auth-middleware'
    | 'authorize-client'
    | 'billing'
    | 'dynamic-store'
    | 'http-forbidden-handler'
    | 'login-page-layout'
    | 'mobile'
    | 'modal-window'
    | 'notifications'
    | 'permissions-route-guard'
    | 'push-notifications'
    | 'session-container'
    | 'session-refresher'
    | 'sso'
    | 'stop-impersonation'
    | 'user-profile-store-provider-adapter';

/**
 * Here we create initial key for triggering specific module for debug.js
 * In params we are passing names of the modules and subModules.
 * At the end we got a key for for specific modules
 *
 * Example. nameSpace = course_dashboard, args = [playlist, assignments]
 *
 * @method makeLogger('course_dashboard','playlist','assignments')
 * @return {string} => eloomi:course_dashboard:playlist:assignments
 *
 *
 * In order to use debug.js in a browser, complete next steps:
 *
 * 1. Generate a new key for debug.js using makeLogger()
 * 2. Open browser => Web inspector => Console
 * 3. In console tab, type localStorage.debug(${yourGeneratedKey}) => Enter
 * 4. Refresh the page
 * 5. That's it!
 *
 *
 * @param {String} [namespace] Module name.
 * @param {String[]} [args] Submodules names
 */

export const makeLogger = (namespace: DebugModules, ...args: string[]) => {
    const parts = [namespace, ...args];
    const name = `eloomi:${parts.join(':')}`;
    return debug(name);
};

export const makeWarnLogger = (namespace: DebugModules, ...args: string[]) => {
    const logger = makeLogger(namespace, ...args);
    logger.log = console.warn.bind(console);
    return logger;
};

/**
 * Reference: {@link https://github.com/debug-js/debug#browser-support}
 *
 * Can be useful for debugging some stuff on app startup
 */
export function enableDebugForNamespacesFromEnv() {
    if (!environment.debugNamespaces) return;

    localStorage.setItem('debug', environment.debugNamespaces);
}
