<template>
    <SkeletonLoader v-if="isLoading" class="textfield--skeleton" :height="skeletonHeight" />
    <div v-else class="textfield" :class="classes" @mouseenter="isHovered = true" @mouseleave="isHovered = false">
        <slot name="left" :focus="focus">
            <CoreShapedImage
                v-if="withAvatar"
                class="textfield--avatar"
                shape="circle"
                :size="24"
                :image="avatarImage"
                :altText="avatarAltText"
            />
            <component :is="iconLeft" size="18" class="textfield--icon textfield--icon-left" />
        </slot>
        <input
            ref="input"
            class="textfield--input"
            :class="inputClass"
            :type="type"
            v-bind="$attrs"
            :value="value"
            :placeholder="placeholder"
            :disabled="isDisabled"
            :readonly="isReadOnly"
            :data-testid="testId"
            @input="onInput"
            @focus="onFocus"
            @blur="onBlur"
            @change="onChange"
            @keydown="onKeyDown"
        />
        <slot name="right" :focus="focus">
            <component :is="iconRight" size="18" class="textfield--icon textfield--icon-right" />
            <span v-if="label" class="label" :class="labelClass">{{ label }}</span>
            <IconButton
                v-if="withButton"
                class="textfield--button"
                size="small"
                :color="buttonColor"
                @click="onButtonClick"
            >
                <elm-arrows-arrow-right-bold-icon size="10" />
            </IconButton>
        </slot>
    </div>
</template>

<script lang="ts">
    import '@eloomi/icons/arrows/arrows-arrow-right-bold';

    import { debounce } from 'lodash-es';
    import { computed, defineComponent, onMounted, PropType, ref } from 'vue';

    import { Themes } from '@/common/classes';
    import { SkeletonLoader } from '@/common/components';
    import IconButton from '@/ui-kit/buttons/IconButton.vue';

    import CoreShapedImage from '../../common/components/icon/CoreShapedImage.vue';

    export default defineComponent({
        name: 'TextField',
        components: { IconButton, SkeletonLoader, CoreShapedImage },
        props: {
            value: {
                type: String,
                required: true,
                default: '',
            },
            size: {
                type: String as PropType<'small' | 'large'>,
                default: 'large',
            },
            type: {
                type: String as PropType<'text' | 'password' | 'email' | 'search' | 'tel' | 'url' | 'number'>,
                default: 'text',
            },
            isActive: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            color: {
                type: String as PropType<Themes | 'default'>,
                default: 'default',
            },
            isDisabled: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            isSelectable: {
                type: Boolean as PropType<boolean>,
                default: true,
            },
            placeholder: {
                type: String as PropType<string>,
                default: '',
            },
            isLoading: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            iconRight: {
                type: String as PropType<string>,
                default: undefined,
            },
            iconLeft: {
                type: String as PropType<string | undefined>,
                default: undefined,
            },
            label: {
                type: [String, Number] as PropType<string | number>,
                default: '',
            },
            avatarImage: {
                type: String as PropType<string>,
                default: '',
            },
            avatarAltText: {
                type: String as PropType<string>,
                default: '',
            },
            withButton: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            isReadOnly: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            autofocus: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            isInvalid: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            debounce: {
                type: Number as PropType<number>,
                default: 0,
            },
            testId: {
                type: String as PropType<string>,
                default: undefined,
            },
            inputClass: {
                type: String as PropType<string | undefined>,
                default: undefined,
            },
        },
        emits: ['input', 'focus', 'blur', 'click:button', 'change', 'keydown'],
        setup(props, { emit }) {
            const input = ref<HTMLInputElement | null>(null);
            const isFocused = ref(false);
            const isHovered = ref(false);
            const onInput = debounce(
                (e: InputEvent) => emit('input', (e.target as HTMLInputElement).value),
                props.debounce
            );
            const onChange = (e: InputEvent) => emit('change', (e.target as HTMLInputElement).value);
            const onFocus = (e: InputEvent) => {
                isFocused.value = true;
                emit('focus', e);
            };
            const onBlur = (e: InputEvent) => {
                isFocused.value = false;
                emit('blur', e);
            };
            const onButtonClick = (e: Event) => emit('click:button', e);
            const onKeyDown = (e: KeyboardEvent) => emit('keydown', e);

            const classes = computed(() => {
                return [
                    `textfield--${props.size}`,
                    `textfield--${props.color}`,
                    {
                        'textfield--is-active': props.isActive,
                        'textfield--is-focused': isFocused.value,
                        'textfield--is-disabled': props.isDisabled,
                        'textfield--is-invalid': props.isInvalid,
                        'textfield--is-read-only': props.isReadOnly,
                        'textfield--non-selectable': !props.isSelectable,
                    },
                ];
            });

            const buttonColor = computed(() => {
                if (props.isInvalid) return 'danger';
                if (props.color === 'default' && (props.isActive || isFocused.value)) return 'primary';
                if (props.color === 'bright') return 'secondary';
                if (props.color === 'default') return 'secondary';
                return props.color;
            });

            const labelClass = computed(() => {
                if (props.isInvalid) return 'danger';
                if (props.color === 'default' && (props.isDisabled || props.isActive)) {
                    return 'info';
                }
                if (props.color === 'default') return 'primary';
                if (props.color === 'bright') return 'info';
                return props.color;
            });

            const focus = () => {
                input.value?.focus();
            };

            const skeletonHeight = computed(() => (props.size === 'large' ? '56px' : '49px'));
            const withAvatar = computed(() => props.avatarImage || props.avatarAltText);

            onMounted(() => {
                if (props.autofocus) {
                    focus();
                }
            });

            return {
                input,
                isHovered,
                isFocused,
                buttonColor,
                classes,
                withAvatar,
                onInput,
                onFocus,
                onBlur,
                onChange,
                onButtonClick,
                onKeyDown,
                skeletonHeight,
                labelClass,
                focus,
            };
        },
    });
</script>
<style lang="less" scoped>
    .color-mixin(@background-color, @hover-background-color, @text-color, @placeholder-color: @text-color) {
        .generic-transition(~'background color');

        background: @background-color;

        .textfield--input {
            .generic-transition(~'color');

            min-width: 0;
            color: @text-color;
        }

        ::placeholder {
            color: @placeholder-color;
        }

        &:hover,
        &--is-focused,
        &--is-active {
            background: @hover-background-color;
        }

        .textfield--icon {
            color: @placeholder-color;
            pointer-events: none;
        }
    }

    .textfield {
        display: flex;
        align-items: center;
        width: 100%;
        overflow: hidden;
        background: @info-color-8;
        border-radius: @border-radius-8;

        &--large {
            height: 56px;
            padding: 0 @spacing-16;
        }

        &--small {
            height: 49px;
            padding: 0 @spacing-14;
        }

        &--default {
            .color-mixin(@info-color-8, @info-color-20, @dark-color, @info-color);

            &--is-active .textfield--input,
            &--is-focused .textfield--input {
                color: @dark-color;
            }
        }

        &--info {
            .color-mixin(@info-color-8, @info-color-20, @info-color, @info-color);
        }

        &--primary {
            .color-mixin(@primary-color-8, @primary-color-20, @primary-color);
        }

        &--success {
            .color-mixin(@success-color-8, @success-color-20, @success-color);
        }

        &--warning {
            .color-mixin(@warning-color-8, @warning-color-20, @warning-color);
        }

        &--bright {
            .color-mixin(@bright-color, @info-color-8, @dark-color, @info-color);

            &--is-disabled {
                .color-mixin(@bright-color-40, @bright-color-40, @info-color-40);
            }

            &--is-active .textfield--input,
            &--is-focused .textfield--input {
                color: @dark-color;
            }
        }

        &--is-read-only {
            .textfield--input {
                cursor: pointer;
            }
        }

        &--non-selectable {
            .textfield--input {
                user-select: none;
            }
        }

        &--danger,
        &--is-invalid {
            .color-mixin(@danger-color-8, @danger-color-20, @danger-color);
        }

        .textfield--icon-left {
            margin-right: @spacing-16;
        }

        .textfield--icon-right {
            margin-left: @spacing-16;
        }

        .textfield--button {
            margin-left: @spacing-16;
        }

        &--is-disabled {
            .color-mixin(@info-color-20, @info-color-20, @info-color);

            pointer-events: none;
        }
    }

    .textfield--input {
        flex: 1;
        max-width: 100%;
        padding: 0;
        text-overflow: ellipsis;
        background-color: @transparent;
        border: none;
        outline: none;
        .font-regular();
        .paragraph();
    }

    .textfield--avatar {
        margin-right: @spacing-16;
    }

    .label {
        .label();

        display: flex;
        align-items: center;
        height: 24px;
        margin-left: @spacing-16;
        padding: 3px @spacing-10 1px;
        color: @bright-color;
        border-radius: @border-radius-18;

        &--primary {
            background: @primary-color;
        }

        &--info {
            background: @info-color;
        }

        &--success {
            background: @success-color;
        }

        &--warning {
            background: @warning-color;
        }

        &--danger {
            background: @danger-color;
        }
    }

    .textfield--skeleton {
        min-width: 100%;
    }
</style>
