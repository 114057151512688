import { flow } from 'lodash-es';
import { type DependencyContainer, Lifecycle } from 'tsyringe';

import { registerAuthenticationServices } from '@/authentication/di';
import { ToastNotificationServiceFactory } from '@/common/components/toast/store';
import { PlatformResolutionService } from '@/common/services';
import { UserDetailsUIStoreServiceFactory } from '@/common/store/user-details/store';
import { registerImpersonationServices } from '@/impersonation/di';
import baseRouter from '@/main/base-router';
import baseStore from '@/main/base-store';
import { registerPermissionServices } from '@/permissions/di';
import { TranslationServiceFactory } from '@/translations/store';
import { registerVideoServices } from '@/video/di';

import { ServiceTokens } from './tokens';

export function registerBaseServices(container: DependencyContainer): DependencyContainer {
    container.register(ServiceTokens.VueRouter, { useValue: baseRouter });
    container.register(ServiceTokens.BaseStore, { useValue: baseStore });
    container.register(ServiceTokens.UserDetailsStoreService, { useFactory: UserDetailsUIStoreServiceFactory });
    container.register(ServiceTokens.ToastNotificationService, { useFactory: ToastNotificationServiceFactory });
    container.register(ServiceTokens.TranslationService, { useFactory: TranslationServiceFactory });
    container.register(
        ServiceTokens.PlatformResolutionService,
        { useClass: PlatformResolutionService },
        { lifecycle: Lifecycle.Singleton }
    );

    return container;
}

export const registerAllServices = flow(
    registerBaseServices,
    registerAuthenticationServices,
    registerVideoServices,
    registerPermissionServices,
    registerImpersonationServices
);
