import { UserEventApiFactory } from '@eloomi/eloomi-event-management-persona-client/1.0/api/user-event-api';

import { authorizeClient } from '@/api/authorize-client';
import { extractAxiosData } from '@/common/services/extract-axios-data';

export const eventUserCategoriesApiClient = authorizeClient(UserEventApiFactory);

export const getUserEventsCategories = options => {
    return eventUserCategoriesApiClient
        .getUserSelfCategories(undefined, undefined, options.page, options.pageSize)
        .then(extractAxiosData);
};
