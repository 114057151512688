import { SkillResponse } from '@eloomi/eloomi-learning-persona-client/1.0';

import { ICoursePlayerCourse, ICoursePlayerPage } from '@/courses/models';

export class CoursePlayerState {
    public course: ICoursePlayerCourse | null = null;
    public courseSkills: SkillResponse[] | null = null;
    public chapterId: number | null = null;
    public pageId: number | null = null;
    public currentPageDetails: ICoursePlayerPage | null = null;
    public isCoursePageLoading = false;
}
