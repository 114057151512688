import { JWT } from './interfaces';

/**
 * Taken from `jose` internals.
 *
 * @see {@link https://github.com/panva/jose/blob/main/src/runtime/browser/base64url.ts}
 *
 */
export const decodeBase64 = input => {
    let encoded = input;
    encoded = encoded.replace(/-/g, '+').replace(/_/g, '/').replace(/\s/g, '');
    try {
        const textDecoder = new TextDecoder();
        return textDecoder.decode(
            new Uint8Array(
                atob(encoded)
                    .split('')
                    .map(c => c.charCodeAt(0))
            )
        );
    } catch {
        throw new TypeError('The input to be decoded is not correctly encoded.');
    }
};

export function getJWTPayload<PayloadType extends {}>(jwt: JWT, requiredFields: (keyof PayloadType)[]): PayloadType {
    if (!isJwtToken(jwt)) {
        jwt = decodeBase64Url(jwt) as JWT;
    }

    const [_header, payload, _signature] = jwt.split('.');

    const data = JSON.parse(decodeBase64(payload));
    if (!requiredFields.every(field => field in data && data[field] != null)) {
        throw new TypeError('Malformed JWT payload detected');
    }

    return data as PayloadType;
}

function isJwtToken(token: string) {
    return token.includes('ey') && token.includes('.');
}

function decodeBase64Url(input: string) {
    input = input.replace(/-/g, '+').replace(/_/g, '/');

    const pad = input.length % 4;
    if (pad) {
        if (pad === 1) {
            throw new Error('InvalidLengthError: Input base64url string is the wrong length to determine padding');
        }
        input += new Array(5 - pad).join('=');
    }

    return decodeBase64(input);
}
