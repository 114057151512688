import { cloneDeep } from 'lodash-es';

export function doesStringHasHtml(value?: string | null) {
    if (!value) {
        return false;
    }

    const indexOfOpeningTag = value.indexOf('<');
    const indexOfClosingTag = value.indexOf('>');

    return indexOfOpeningTag !== -1 && indexOfClosingTag !== -1 && indexOfOpeningTag < indexOfClosingTag;
}

export function getInitials(value: string | number): string {
    if (typeof value === 'string') {
        if (value === '–') return value;
        const parsedText = value.replaceAll(/[^ A-Za-z]/g, '');
        const textParts = parsedText.split(' ');
        return `${(textParts[0] || '').charAt(0)}${(textParts[1] || '').charAt(0)}`.toUpperCase();
    } else {
        return `${value}`;
    }
}

export function isNotNullOrUndefined(obj: any): boolean {
    return obj !== undefined && obj !== null;
}

export function isNotEmpty(obj: any): boolean {
    return isNotNullOrUndefined(obj) && JSON.stringify(obj) !== '{}' && JSON.stringify(obj) !== '[]' && obj !== '';
}

export function stringIsNotEmpty(s: string) {
    return isNotNullOrUndefined(s) && s !== '';
}

export function capitalize(s: string) {
    return s[0].toUpperCase() + s.slice(1);
}

export function addHashToLocation(context: any, hash: string) {
    context.$router.push({
        name: context.$route.name,
        hash,
    });
}

export function deepCopy<T>(objectToCopy: T): T {
    return cloneDeep(objectToCopy);
}

export function convertToJson(jsonString: string) {
    try {
        return JSON.parse(jsonString);
    } catch {
        return null;
    }
}

export function convertToJsonAndExtractField(jsonString: string, field: string) {
    const json = convertToJson(jsonString);

    return json ? objectGetFieldByPath(json, field) : null;
}

export function objectGetFieldByPath(object: any, path: string) {
    // https://eloomi.atlassian.net/browse/BLUE-1040
    // eslint-disable-next-line unicorn/no-array-reduce
    return path.split('.').reduce((currentObject, currentKey) => {
        return currentObject && Object.prototype.hasOwnProperty.call(currentObject, currentKey)
            ? currentObject[currentKey]
            : null;
    }, object);
}

export function objectToArrayReversed(object) {
    const items = [] as any[];

    for (const item in object) {
        items.push(object[item]);
    }

    return items.reverse();
}

export function objectHasProperty(object: any, path: string) {
    // https://eloomi.atlassian.net/browse/BLUE-1040
    // eslint-disable-next-line unicorn/no-array-reduce
    return path.split('.').reduce((currentObject, currentKey) => {
        return currentObject && Object.prototype.hasOwnProperty.call(currentObject, currentKey)
            ? currentObject[currentKey]
            : null;
    }, object);
}

export function getShortList(array: any[], itemCountShow: number) {
    return array.length > itemCountShow ? array.slice(0, -(array.length - itemCountShow)) : array;
}

export function getPruningNumber(array: any[], itemCountShow: number) {
    return array.length - itemCountShow + ' +';
}

export function getRemainingNumber(total: number, itemCountShow: number) {
    return '+' + (total - itemCountShow);
}

export function parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replaceAll('-', '+').replaceAll('_', '/');
    const jsonPayload = decodeURIComponent(
        [...atob(base64)]
            .map(c => {
                // https://eloomi.atlassian.net/browse/BLUE-1040
                // eslint-disable-next-line unicorn/prefer-code-point
                return `%${('00' + c.charCodeAt(0).toString(16)).slice(-2)}`;
            })
            .join('')
    );

    return convertToJson(jsonPayload);
}

export function isRichTextEmpty(json: any) {
    return Boolean(
        json &&
            isNotEmpty(json) &&
            json.content &&
            isNotEmpty(json.content) &&
            json.content.every(paragraph => !paragraph.content)
    );
}

export function generatePointNeighborhood(point: number, padding: number): number[] {
    return [
        ...Array.from({ length: padding }).map((_, i) => point - padding + i),
        point,
        ...Array.from({ length: padding }).map((_, i) => point + i + 1),
    ];
}

export function range(start: number, end: number): readonly number[] {
    return Array.from({ length: end - start + 1 }).map((_, i) => i + start);
}

export function filenameEllipsis(filename: string, maxLength: number) {
    if (filename.includes('.')) {
        const pathParts = filename.split('.');
        const extension = pathParts.at(-1);

        const remainingPath = pathParts.slice(0, -1).join('.');

        if (remainingPath.length > maxLength) {
            const endPart = `[...].${extension}`;
            return remainingPath.slice(0, Math.max(0, maxLength - endPart.length)) + endPart;
        }
    }

    return filename;
}

export const hashCode = (s: string): number => {
    let h = 0;
    const l = s.length;
    let i = 0;
    // https://eloomi.atlassian.net/browse/BLUE-1040
    // eslint-disable-next-line unicorn/prefer-code-point,unicorn/prefer-math-trunc
    if (l > 0) while (i < l) h = ((h << 5) - h + s.charCodeAt(i++)) | 0;
    return h;
};

export const formatBytes = (bytes: number, decimals = 2): string => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return Number.parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const fileName = (text: string): string => {
    return text ? text.slice(0, text.lastIndexOf('.')) : '';
};

export const fileExtension = (text: string): string => {
    return text ? text.slice(text.lastIndexOf('.') + 1, text.length) : '';
};

export function findLast<T>(array: T[], predicate: (value: T, index: number, obj: T[]) => boolean): T | undefined {
    let index = array.length - 1;
    for (; index >= 0; index--) {
        if (predicate(array[index], index, array)) {
            return array[index];
        }
    }
}

export function shuffle(array: any[]) {
    let currentIndex = array.length;
    let temporaryValue;
    let randomIndex;

    while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
}

export function getLink(link: string, regex: RegExp, hostingLink: string): string {
    const hitList = link.match(regex);

    if (hitList !== null && hitList[1]) {
        const id = hitList[1];
        return hostingLink + id;
    } else {
        return link;
    }
}

export function convertYoutubeToEmbed(link: string): string {
    return getLink(
        link,
        /(?:www\.)?youtu(?:\.be\/|be.com\/\S*(?:watch|embed)(?:(?:(?=\/[^\s&?]+(?!\S))\/)|(?:\S*v=|v\/)))([^\s&?]+)/,
        'https://www.youtube.com/embed/'
    );
}

export function convertVimeoToEmbed(link: string): string {
    return getLink(
        link,
        /(?:http|https)?:?\/?\/?(?:www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/[^/]*\/videos\/|video\/|)(\d+)(?:|\/\?)/,
        'https://player.vimeo.com/video/'
    );
}

export function isHttpsUrl(link: string): boolean {
    const httpsRegex = /^(https):\/\//i;

    return httpsRegex.test(link);
}

/**
 Twenty three exposes video externally according to following pattern http/https://domain/playerId or secret/photoId/token/....
 */
export function convertTwentyThreeToEmbed(link: string): string {
    if (!link.includes('twentythree') || !link.includes('video.eloomi')) {
        return link;
    }
    const embeddedPart = '/v.ihtml/player.html?';
    if (link.includes(embeddedPart)) {
        return link;
    }

    const urlParts = link.split('/');
    if (urlParts.length < 6) {
        return link;
    }

    const domain = urlParts[2];
    const photoId = urlParts[4];
    const token = urlParts[5];

    return `https://${domain}${embeddedPart}token=${token}&source=embed&photo_id=${photoId}&backgroundColor=white&showLogo=0`;
}

/**
 * Set user clipboard content.
 * @param content text content
 */
export function setClipboard(content: string) {
    const textArea = document.createElement('textarea');
    textArea.style.opacity = '1';

    document.body.append(textArea);
    textArea.value = content;

    textArea.select();
    document.execCommand('copy');

    textArea.remove();
}

export type KebabCase<S> = S extends `${infer C}${infer T}`
    ? KebabCase<T> extends infer U
        ? U extends string
            ? T extends Uncapitalize<T>
                ? `${Uncapitalize<C>}${U}`
                : `${Uncapitalize<C>}-${U}`
            : never
        : never
    : S;

// "Mozilla Firefox" -> "mozilla-firefox"
// "Mac OS" -> "mac-os"
export function wordsToKebab(str: string, separator = ' ') {
    return str.replace(separator, '-').toLowerCase();
}

export const snakeToCamel = (str: string): string => str.replaceAll(/([_-]\w)/g, g => g[1].toUpperCase());
export const wordsToCamel = (str: string): string =>
    str
        .split(' ')
        .map((oneWord, idx) => (idx > 0 ? capitalize(oneWord.toLowerCase()) : oneWord))
        .join('');
export const camelToSnake = (str: string): string => str.replaceAll(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
export const camelToKebab = <T extends string>(str: T) =>
    str.replaceAll(/[A-Z]/g, letter => `-${letter.toLowerCase()}`) as KebabCase<T>;

const convertObjectKeys = (obj: any, objectKeyMappingFunction: (key: string) => string): any => {
    const newObj = {};
    for (const key of Object.keys(obj)) {
        if (obj[key] !== undefined) newObj[objectKeyMappingFunction(key)] = obj[key];
    }

    return newObj;
};

export const objectPropsSnakeToCamel = (obj: any): any => {
    return convertObjectKeys(obj, snakeToCamel);
};

export const objectPropsCamelToSnake = (obj: any): any => {
    return convertObjectKeys(obj, camelToSnake);
};

export const formatBigNumber = value => {
    if (value >= 1_000_000) {
        return (value / 1_000_000).toFixed(1) + 'M';
    }
    if (value >= 1000) {
        return (value / 1000).toFixed(1) + 'k';
    }
    return value;
};

/**
 * Returns either true or false if item is in range(s)
 * @param value - Value to check
 * @param ranges - Array with boundaries or array with multiple arrays with boundaries
 */
export function isNumberInRange(value: number, ranges: number[] | number[][]): boolean {
    if (Array.isArray(ranges[0])) {
        let isInRange = false;

        for (let i = 0; i < ranges.length; i++) {
            if (isNumberInRange(value, (ranges as number[][])[i])) {
                isInRange = true;
            }
        }

        return isInRange;
    } else {
        return value > ranges[0] && value < ranges[1];
    }
}

export function sumArray(arr: number[]) {
    return arr.reduce((sum, value) => sum + value, 0);
}
/**
 * Returns closest divisible number
 * @param a - number to check
 * @param b - divisor
 * @param greater - if true then searches for a greater value, if false, then smaller
 */
export const closestDivisibleBy = (a: number, b: number, greater = true) => (greater ? a + b - (a % b) : a - (a % b));

export function isViewportWidthLessThan(width: number): boolean {
    return window.innerWidth < width;
}

export function getMaxWidthMedia(maxWidth: number): string {
    return `(max-width: ${maxWidth - 0.02}px)`;
}

export type TextCalculationMetrics = {
    width: number;
};

export function calculateTextMetrics(
    value: string,
    font: string | null,
    htmlElement: Element | null = null
): TextCalculationMetrics | null {
    let localFont = '';
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (!context) {
        return null;
    }

    if (font) {
        localFont = font;
    } else {
        if (!font && htmlElement) {
            const computedMetrics = getComputedStyle(htmlElement);
            localFont = computedMetrics.font;
        }
    }

    if (!localFont) {
        return null;
    }

    context.font = localFont;
    const textMetrics = context.measureText(value);
    return {
        width: textMetrics.width,
    };
}

// may look a bit hackish, but read this issue:
// https://stackoverflow.com/questions/44969852/javascript-number-tolocalestring-currency-without-currency-sign
export function formatPriceAndSeparateSymbol(number: number, currencyCode: string) {
    const partsToIgnore = ['literal', 'currency'];

    if (Number.isSafeInteger(number)) {
        partsToIgnore.push('fraction', 'decimal');
    }

    let symbol = '';
    const price = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currencyCode,
    })
        .formatToParts(number)
        .map(p => {
            if (p.type === 'currency') {
                symbol = p.value;
            }

            return partsToIgnore.includes(p.type) ? '' : p.value;
        })
        .join('');

    return {
        symbol,
        price,
    };
}
export function formatPercentage(
    number: number,
    // https://eloomi.atlassian.net/browse/BLUE-1040
    // eslint-disable-next-line unicorn/no-object-as-default-parameter
    options: { fromDecimals?: boolean; precision?: number; round?: boolean } = {
        round: false,
        precision: 2,
        fromDecimals: false,
    }
) {
    const numberToFormat = options.fromDecimals ? number * 100 : number;

    if (options.round) {
        return `${Math.round(numberToFormat)}%`;
    }
    return `${Number(numberToFormat.toFixed(options.precision))}%`;
}

export function extractTextFromHTML(html: string | null | undefined) {
    if (!html) {
        return '';
    }
    const span = document.createElement('span');
    span.innerHTML = html;

    // https://eloomi.atlassian.net/browse/BLUE-1040
    // eslint-disable-next-line unicorn/prefer-dom-node-text-content
    return span.textContent || span.innerText;
}

export function loadScript(src: string) {
    return new Promise<void>(function (resolve, reject) {
        if (document.querySelector('script[src="' + src + '"]')) {
            resolve();
            return;
        }
        const el = document.createElement('script');
        el.type = 'text/javascript';
        el.async = true;
        el.src = src;
        el.addEventListener('load', () => resolve());
        el.addEventListener('error', reject);
        el.addEventListener('abort', reject);
        document.head.append(el);
    });
}

export function isBrowserLocale24h(locale: string) {
    return Intl.DateTimeFormat(locale, { hour: 'numeric' }).resolvedOptions().hourCycle === 'h23';
}

export function filterNonNumericCharacters(event: KeyboardEvent) {
    if (
        event.key !== 'ArrowLeft' &&
        event.key !== 'ArrowRight' &&
        event.key !== 'Backspace' &&
        event.key !== 'Delete' &&
        event.key !== 'Tab' &&
        (event.key < '0' || event.key > '9')
    ) {
        event.preventDefault();
    }
}

export function convertStringToBoolean(value: string) {
    return value.toLowerCase() == 'true';
}
