<template>
    <SkeletonLoader v-if="isLoading" class="selector-input-skeleton" height="56px" />
    <elm-text-field
        v-else
        :value="value"
        :size="size"
        :placeholder="placeholder"
        :disabled="disabled"
        :invalid="invalid"
        @elm-input="input"
        @elm-focus="focus"
        @elm-blur="blur"
    >
        <elm-actions-search-icon slot="icon-left" class="info-color" />
        <slot name="icon-right">
            <elm-actions-close-regular-icon
                v-if="hasClearButton"
                slot="icon-right"
                v-tippy="{ content: clearButtonTooltip }"
                class="clear-button"
                @click.stop="onClearClick"
            />
        </slot>
    </elm-text-field>
</template>

<script lang="ts">
    import { defineComponent, ref, PropType, onMounted } from 'vue';
    import '@eloomi/components/text-field';
    import '@eloomi/icons/actions/actions-close-regular';
    import '@eloomi/icons/actions/actions-search';
    import { SkeletonLoader } from '@/common/components';

    export default defineComponent({
        name: 'SelectorSearchInput',
        props: {
            value: {
                type: String as PropType<string>,
            },
            isLoading: {
                type: Boolean as PropType<boolean>,
            },
            disabled: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            autofocus: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            invalid: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            placeholder: {
                type: String as PropType<string>,
                default: '',
            },
            size: {
                type: String as PropType<'small' | 'large'>,
                default: 'large',
            },
            hasClearButton: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            clearButtonTooltip: {
                type: String as PropType<string>,
                default: '',
            },
        },
        components: { SkeletonLoader },
        setup(_props, { emit }) {
            const inputEl = ref<HTMLInputElement | null>(null);
            const isActive = ref(false);
            const focus = () => {
                isActive.value = true;
                emit('focus');
            };
            const input = event => emit('input', event.target.value);
            const blur = () => {
                isActive.value = false;
                emit('blur');
            };
            onMounted(() => {
                if (_props.autofocus) {
                    inputEl.value?.focus();
                }
            });
            const onClearClick = () => emit('clear');
            return {
                blur,
                focus,
                input,
                inputEl,
                isActive,
                onClearClick,
            };
        },
    });
</script>

<style lang="less" scoped>
    .selector-input-skeleton {
        max-width: inherit;
    }

    .clear-button {
        cursor: pointer;
        transition: @generic-transition;

        &:hover {
            color: @primary-color;
        }
    }
</style>
