import { ref, watch, type Ref } from 'vue';
import { createPopper, Options } from '@popperjs/core';
import { Instance } from '@popperjs/core';

import { MaybeElement, unrefElement } from '@vueuse/core';

export function usePopper(options: Ref<Partial<Options>>) {
    const targetRef: Ref<MaybeElement> = ref(null);
    const contentRef: Ref<MaybeElement> = ref(null);
    let popper: Instance | null = null;

    watch(
        [targetRef, contentRef],
        ([target, content], _, onInvalidate) => {
            const targetEl = unrefElement(target);
            const popperEl = unrefElement(content);

            if (!(targetEl instanceof HTMLElement)) {
                return;
            }
            if (!(popperEl instanceof HTMLElement)) {
                return;
            }

            popper = createPopper(targetEl, popperEl, options.value);
            onInvalidate(popper.destroy);
        },
        { immediate: true }
    );

    watch(options, options => {
        popper?.setOptions(options);
    });

    return {
        targetRef,
        contentRef,
        update() {
            popper?.update();
        },
    };
}
