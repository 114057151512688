import { computed, ref } from 'vue';

import { useUserProfileDetailsStore } from '@/common/store/user-details/store';
import { useDependencyContainer } from '@/di/use-injector';
import { Permission, PermissionChecker, Permissions, UserPermissionChecker } from '@/permissions';

import { PermissionsServiceTokens as Tokens } from '../di-tokens';

export function usePermissions() {
    const userProfileDetails = useUserProfileDetailsStore();
    const container = useDependencyContainer();
    const permissionCheckerPromise = container.resolve<Promise<UserPermissionChecker>>(Tokens.PermissionChecker);

    const checker = ref<PermissionChecker | null>(null);

    // eslint-disable-next-line promise/catch-or-return
    permissionCheckerPromise.then(permissionChecker => {
        checker.value = permissionChecker;
        return checker;
    });

    function hasPermission(permission: Permission) {
        return Boolean(checker.value?.hasPermission(permission));
    }

    function hasAnyPermission(...permissions: Permission[]) {
        return Boolean(checker.value?.hasAnyPermission(...permissions));
    }

    function hasEveryPermission(...permissions: Permission[]) {
        return Boolean(checker.value?.hasEveryPermission(...permissions));
    }

    const canAccessGroupReport = computed(() => {
        return hasPermission(Permissions.Reporting) || userProfileDetails.state.isReportingManager;
    });

    const canAccessInstructorPage = computed(() => {
        return userProfileDetails.state.isInstructor;
    });

    const canAccessManagerGroupsPage = computed(() => {
        return userProfileDetails.state.isReportingManager;
    });

    return {
        hasPermission,
        hasAnyPermission,
        hasEveryPermission,
        canAccessGroupReport,
        canAccessInstructorPage,
        canAccessManagerGroupsPage,
    };
}
