import { inject, injectable } from 'tsyringe';

import type { UserDetailsStoreService } from '@/common/store/user-details/store';
import { ServiceTokens } from '@/di/tokens';
import type { TranslationService } from '@/translations/store';

import { User } from '../interfaces';

@injectable()
export class CheckIfImpersonationAllowedUseCase {
    public constructor(
        @inject(ServiceTokens.UserDetailsStoreService)
        private readonly userDetails: UserDetailsStoreService,

        @inject(ServiceTokens.TranslationService)
        private readonly transService: TranslationService
    ) {}

    public execute(user: User): boolean {
        return Boolean(user.active && this.userDetails.authUserProfile()?.id !== user.id);
    }

    public getReason(user: User) {
        if (!user.active) {
            return this.transService.trans()('spa.admin.impersonation.login-as-inactive');
        }

        if (this.userDetails.authUserProfile()?.id === user.id) {
            return this.transService.trans()('spa.admin.impersonation.login-as-yourself');
        }

        return null;
    }
}
