import { inject, injectable } from 'tsyringe';
import type { NavigationGuard, NavigationGuardNext, Route } from 'vue-router';
import type { Store } from 'vuex';

import { AuthenticationServiceTokens } from '@/authentication/di-tokens';
import type { SessionContainer } from '@/authentication/session/session-container';
import { ServiceTokens as BaseTokens } from '@/di/tokens';

import { InvalidPlatformError } from '../errors';
import type { JWT, PlatformResolver } from '../interfaces';
import type { TokenValidator } from '../token-manager';
import { VueRouteTokenTransitionGuardService } from '../transition-guard';

export interface PlatformJWTPayload {
    'eloomi-platform-id': string;
}

@injectable()
export class PlatformServiceBase {
    constructor(
        @inject(BaseTokens.PlatformResolutionService)
        private readonly platformResolver: PlatformResolver,

        @inject(BaseTokens.BaseStore)
        private readonly baseStore: Store<unknown>,

        @inject(AuthenticationServiceTokens.SessionContainer)
        private readonly sessionContainer: SessionContainer<JWT>
    ) {}

    protected verifyToken?: TokenValidator;

    protected async validatePlatform(payload: PlatformJWTPayload) {
        let isPlatformValid = false;
        if (this.platformResolver) {
            const { id } = await this.platformResolver.getPlatformData();
            isPlatformValid = id === Number(payload['eloomi-platform-id']);
        }

        if (!isPlatformValid) {
            throw new InvalidPlatformError();
        }
    }

    public makeTokenValidatorRouteGuard(): NavigationGuard {
        if (!this.verifyToken) {
            throw new Error('Child class should implement verifyToken() method.');
        }

        const tokenValidator = this.verifyToken;

        return (to: Route, _: Route, next: NavigationGuardNext) => {
            const guardService = new VueRouteTokenTransitionGuardService(to, next);
            guardService.verifyTokenTransition(tokenValidator, this.baseStore, this.sessionContainer);
        };
    }
}
