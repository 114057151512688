import { inject, injectable } from 'tsyringe';

import { AuthenticationServiceTokens } from '@/authentication/di-tokens';

import { ImpersonationServiceTokens as Tokens } from '../di-tokens';
import type { SessionContainer } from '../interfaces';
import type { ImpersonationStoreService } from '../store';

@injectable()
export class ImpersonationSessionStoreSyncUseCase {
    public constructor(
        @inject(AuthenticationServiceTokens.SessionContainer)
        private readonly sessionContainer: SessionContainer<unknown>,

        @inject(Tokens.ImpersonationStoreService)
        private readonly impersonationStore: ImpersonationStoreService
    ) {}

    public async execute() {
        const state = this.sessionContainer.isImpersonating();
        this.impersonationStore.setImpersonationState(state);
    }
}
