import { Module, Mutations, Actions, createMapper, Getters } from 'vuex-smart-module';

import { Rule } from '../models';
import { getGroup } from '../providers';
import { sleep } from '@/common/services';

class State {
    public isAddRuleModalShown = false;
    public isEditRuleModalShown = false;
    public activeRule: Rule | null = null;
    public hasRuleModifications = false;
    public rulesAreBeingProcessed = false;
    public groupId: number | null = null;
}

class StoreMutations extends Mutations<State> {
    public showAddRuleModal() {
        this.state.isAddRuleModalShown = true;
    }

    public hideAddRuleModal() {
        this.state.isAddRuleModalShown = false;
    }

    public showEditRuleModal(rule: Rule) {
        this.state.isEditRuleModalShown = true;
        this.state.activeRule = rule;
    }

    public hideEditRuleModal() {
        this.state.isEditRuleModalShown = false;
        this.state.activeRule = null;
    }

    public markRuleModifications(groupId: number) {
        this.state.hasRuleModifications = true;
        this.state.groupId = groupId;
    }

    public cleanRuleModificationsMark() {
        this.state.hasRuleModifications = false;
        this.state.groupId = null;
    }

    public flagRulesProcessing(value: boolean) {
        this.state.rulesAreBeingProcessed = value;
    }
}

export class StoreActions extends Actions<State, Getters<State>, StoreMutations, StoreActions> {
    public static POLLING_PERIOD = 2000;

    private scheduleNextStatusRequest = async (groupId: number, immediate = false) => {
        return new Promise<void>((resolve, reject) => {
            setTimeout(
                () => {
                    getGroup(groupId)
                        .then(group => {
                            if (group.all_rules_processed) {
                                return resolve();
                            }
                            resolve(this.scheduleNextStatusRequest(groupId));
                        })
                        .catch(reject);
                },
                immediate ? 0 : StoreActions.POLLING_PERIOD
            );
        });
    };

    public async checkIfRulesAreProcessedOrPoll() {
        if (this.state.hasRuleModifications) {
            await this.dispatch('pollForRuleProcessingStatus', this.state.groupId!);
            this.mutations.cleanRuleModificationsMark();
            return true;
        }
        return false;
    }

    public async pollForRuleProcessingStatus(groupId: number) {
        this.mutations.flagRulesProcessing(true);
        try {
            // Wait at least 1 second until notification will hide
            await Promise.all([this.scheduleNextStatusRequest(groupId, true), sleep(1000)]);
        } finally {
            this.mutations.flagRulesProcessing(false);
        }
    }
}

const storeModule = new Module({
    state: State,
    mutations: StoreMutations,
    actions: StoreActions,
});

export const userGroupStoreMapper = createMapper(storeModule);
export default storeModule;
