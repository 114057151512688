import { Module, Actions, Mutations, Getters, createMapper, createComposable } from 'vuex-smart-module';
import { getSubscriptionInfo } from '../providers';
import { Vue } from 'vue-property-decorator';

class State {
    public isPremium: boolean | undefined;
}

class StoreMutations extends Mutations<State> {
    public setIsPremium(isPremium: boolean) {
        this.state.isPremium = isPremium;
    }
}

export class StoreActions extends Actions<State, Getters<State>, StoreMutations, StoreActions> {
    public async getSubscriptionInfo(): Promise<boolean> {
        if (this.state.isPremium !== undefined) {
            return this.state.isPremium;
        }
        const subscriptionInfo = await getSubscriptionInfo();
        const isPremium = subscriptionInfo.is_premium ?? false;
        this.mutations.setIsPremium(isPremium);

        return isPremium;
    }
}

const storeModule = new Module({
    state: State,
    mutations: StoreMutations,
    actions: StoreActions,
});

export const useContentStore = createComposable(storeModule);

export const contentStoreMapper = createMapper(storeModule);
export const ContentStoreMappedStore = Vue.extend({
    computed: {
        ...contentStoreMapper.mapState(['isPremium']),
    },
    methods: contentStoreMapper.mapActions(['getSubscriptionInfo']),
});

export default storeModule;
