import { Actions } from 'vuex-smart-module';

import { UserCoursesGetters } from './getters';
import { UserCoursesMutations } from './mutations';
import { UserCoursesState } from './state';

import { getPlayerCourse } from '@/api/providers/course-player.provider';

export class UserCoursesActions extends Actions<
    UserCoursesState,
    UserCoursesGetters,
    UserCoursesMutations,
    UserCoursesActions
> {
    public async loadActiveCourse(payload: { courseId: number }) {
        console.debug('Loading active course');
        const course = await getPlayerCourse(payload.courseId);
        this.mutations.setActiveCourse(course);
    }

    public setActivePageAndChapter(payload: { activeChapterId: number; activePageId: number }) {
        this.mutations.setActiveChapterId(payload.activeChapterId);
        this.mutations.setActivePageId(payload.activePageId);
    }

    public async markActivePageAsCompleted() {
        return this.mutations.markActivePageAsCompleted();
    }
}
