import { inject, injectable } from 'tsyringe';
import VueRouter from 'vue-router';

import { getRegionalBaseUri } from '@/api/regional-url-provider';
import { DEFAULT_PAGE_LOCATION } from '@/authentication/services/locations';
import { type IosSsoLoginResult, SingleSignOn } from '@/authentication/services/single-sign-on-plugin';
import { makeLogger } from '@/common/services/debug-helper';
import { Platform } from '@/common/services/get-device-platform';
import { baseContainer } from '@/di/container';
import { ServiceTokens } from '@/di/tokens';
import environment from '@/environment';

import { AuthenticationRouteNames } from '../route-definitions';
import { HandleDeepLinkUseCase } from '../use-cases/handle-deep-link';
import type { SSOOrigin } from '../use-cases/login-sso';
import { queryStringWithRedirectParam } from './interfaces';
import { isAuthRequiredForAnyMatchedRoute } from './transition-guard';

// TODO: Have to be fully revised and rewritten (https://eloomi.atlassian.net/browse/BLUE-1023)
@injectable()
export class VueRouterRedirectionService {
    public constructor(@inject(ServiceTokens.VueRouter) private readonly router: VueRouter) {}

    public hasRedirectParamInQuery(): boolean {
        const query = this.router.currentRoute.query as queryStringWithRedirectParam;
        return typeof query.redirect === 'string';
    }

    public isOnLoginPage() {
        return this.router.currentRoute.name === AuthenticationRouteNames.LOGIN;
    }

    public isAuthRequiredForCurrentRoute() {
        return isAuthRequiredForAnyMatchedRoute(this.router.currentRoute.matched);
    }

    public async redirectToPagePassedInQuery() {
        const query = this.router.currentRoute.query as queryStringWithRedirectParam;
        await this.router.push({ path: query.redirect });
    }

    public async redirectToDefaultPage(isHard = false) {
        if (isHard) {
            const route = this.router.resolve(DEFAULT_PAGE_LOCATION);
            window.location.replace(route.href);
        } else {
            await this.router.push(DEFAULT_PAGE_LOCATION);
        }
    }

    public async redirectToLoginPage(isHard = false) {
        if (isHard) {
            const route = this.router.resolve({ name: AuthenticationRouteNames.LOGIN });
            window.location.replace(route.href);
        } else {
            await this.router.push({ name: AuthenticationRouteNames.LOGIN });
        }
    }

    // TODO: It should be a use case, not service method (https://eloomi.atlassian.net/browse/BLUE-1023)
    public async redirectToLoginSsoPage(platformId: number, origin: SSOOrigin) {
        const apiBaseUrl = await getRegionalBaseUri();

        const baseUrl = apiBaseUrl.endsWith('/') ? apiBaseUrl : apiBaseUrl + '/';
        const log = makeLogger('sso');

        // eslint-disable-next-line unicorn/consistent-destructuring
        let url = environment.isProduction
            ? `${baseUrl}saml2/${platformId}/init`
            : `${baseUrl}users/external/platform-sso/${platformId}/init`;

        if ([Platform.Android, Platform.Ios].includes(origin.devicePlatform)) {
            url += `/?origin=${origin.devicePlatform}`;
        }

        log('redirecting to', url);

        if (origin.devicePlatform === Platform.Android) {
            await SingleSignOn.redirectToIdentityProvider({ url });
        } else if (origin.devicePlatform === Platform.Ios) {
            try {
                const { callbackUrl } = await SingleSignOn.redirectToIdentityProvider<IosSsoLoginResult>({ url });

                if (callbackUrl) {
                    const useCase = baseContainer.resolve(HandleDeepLinkUseCase);
                    useCase.execute(callbackUrl);
                } else {
                    throw new Error('Unable to authorize using SSO');
                }
            } catch {
                console.error('Unable to auth using SSO');
            }
        } else {
            window.location.replace(url);
        }
    }
}
