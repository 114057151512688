import { NavigationGuardNext, Route } from 'vue-router';
import { Context } from 'vuex-smart-module';

import { handlePageNotFound } from '@/common/services/transition-common';
import userStore from '@/common/store/user-details/store';
import userDetailsStore from '@/common/store/user-details/store';
import { getBaseStore } from '@/main/get-base-store-router';

export function redirectIfGuard(condition: () => boolean, routeName: string) {
    return function (_to, _from, next) {
        if (condition()) {
            next({
                name: routeName,
            });
        } else {
            next();
        }
    };
}

export const checkUserData = (userDataToCheck: (store: Context<typeof userStore>) => boolean) => {
    return async (_to: Route, _from: Route, next: NavigationGuardNext) => {
        const store = userDetailsStore.context(getBaseStore());
        await store.actions.loadUserProfile();

        if (userDataToCheck(store)) {
            next();
        } else {
            handlePageNotFound(next);
        }
    };
};
