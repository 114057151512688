<template>
    <div id="themeProvider">
        <slot></slot>
    </div>
</template>

<script lang="ts">
    import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
    import { Branding } from '@/common/models';
    import { brandingThemeToCssString } from '@/common/services';
    import defaultFaviconImageUrl from '@/assets/img/eloomi-favicon.png';
    import { getCachedBrandingData } from '@/common/services';
    import { hideSplashScreen } from '@/common/services/splashscreen';

    @Component({})
    export default class ThemeProvider extends Vue {
        @Prop() public theme?: Branding;
        @Prop() public defaultTheme?: Branding;

        public cacheBranding: Branding | null = null;

        public created() {
            this.cacheBranding = getCachedBrandingData();
            hideSplashScreen(250); // assumption that all areas utilize ThemeProvider at some level
        }

        @Watch('theme') public onChildChanged() {
            const favicon = document.getElementById('favicon') as HTMLLinkElement;

            if (this.defaultTheme && !this.theme) {
                this.theme = this.defaultTheme;
            }

            if (favicon) {
                favicon.href = this.theme?.favicon_url || this.cacheBranding?.favicon_url || defaultFaviconImageUrl;
            }

            if (this.theme) {
                const css = brandingThemeToCssString(this.theme);
                const head = document.head;
                const style = document.createElement('style') as HTMLStyleElement;
                style.appendChild(document.createTextNode(css));
                head.appendChild(style);
            }
        }

        public mounted() {
            this.onChildChanged();
        }
    }
</script>

<style lang="less" scoped>
    #themeProvider {
        display: flex;
        flex: 1;
        width: 100%;
        max-width: 100%;
        min-height: 100%;
    }
</style>
