import { NavigationGuardNext, Route, RouteRecord } from 'vue-router';
import { Store } from 'vuex';

import { LOGIN_PAGE_LOCATION } from '@/authentication/services/locations';
import { parseTokenParamFromRoute } from '@/authentication/services/parse-token-param-from-route';
import { showDelayedNotification } from '@/common/services';
import { goToDefaultPage } from '@/common/services/transition-common';

import { SessionContainer } from '../session/session-container';
import type { JWT, queryStringWithRedirectParam, RouteRecordWithAuthMeta } from './interfaces';
import { TokenValidator } from './token-manager';

export function isAuthRequiredForAnyMatchedRoute(routes: RouteRecord[]) {
    return routes.some(record => (record as RouteRecordWithAuthMeta).meta.requiresAuth);
}

export function isAnyMatchedRouteForUnauthorizedOnly(routes: RouteRecord[]) {
    return routes.some(record => (record as RouteRecordWithAuthMeta).meta.onlyUnauthorized);
}

export class VueRouteTransitionGuardService {
    public constructor(private readonly routeTo: Route, private readonly goNext: NavigationGuardNext) {}

    public isAuthRequiredForTargetRoute(): boolean {
        return isAuthRequiredForAnyMatchedRoute(this.routeTo.matched);
    }

    public isTargetRouteForUnauthorizedOnly(): boolean {
        return isAnyMatchedRouteForUnauthorizedOnly(this.routeTo.matched);
    }

    public parseTokenParamFromRoute(): string {
        return parseTokenParamFromRoute(this.routeTo);
    }

    public changeTransitionToLoginPage(redirectWithQueryParams = true) {
        this.goNext({
            ...LOGIN_PAGE_LOCATION,
            query: redirectWithQueryParams ? ({ redirect: this.routeTo.fullPath } as queryStringWithRedirectParam) : {},
        });
    }

    public changeTransitionToDefaultPage() {
        goToDefaultPage(this.goNext);
    }

    public acceptCurrentTransition() {
        this.goNext();
    }
}

export class VueRouteTokenTransitionGuardService extends VueRouteTransitionGuardService {
    public changeTransitionToInitialPage(sessionContainer: SessionContainer<JWT>) {
        if (sessionContainer.isLoggedIn()) {
            this.changeTransitionToDefaultPage();
        } else {
            this.changeTransitionToLoginPage(false);
        }
    }

    public async verifyTokenTransition(
        validator: TokenValidator,
        baseStore: Store<unknown>,
        sessionContainer: SessionContainer<JWT>
    ) {
        try {
            const token = this.parseTokenParamFromRoute();
            const tokenValidationResult = await validator(token);

            if (tokenValidationResult.isValid) {
                this.acceptCurrentTransition();
                return;
            }

            this.changeTransitionToInitialPage(sessionContainer);

            await showDelayedNotification(baseStore, {
                theme: 'warning',
                message: tokenValidationResult.message,
                duration: 2000,
            });
        } catch (error) {
            console.warn(error);
            this.changeTransitionToInitialPage(sessionContainer);
        }
    }
}
