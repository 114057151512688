/** Debounce promise returning function. Newest arguments will be used to call function.
 *
 * usage: let debounced = debouncePromise(1000, someFunction);
 */
export function debouncePromise<T extends (...args: any[]) => Promise<any>>(time: number, func: T): T {
    // TODO: add type guards (https://eloomi.atlassian.net/browse/BLUE-955)

    let lastArguments;
    let timeoutId;
    let existingPromise;
    let resolvePromise;
    let rejectPromise;

    // tslint:disable-next-line
    return function (...args: any[]) {
        if (!existingPromise) {
            existingPromise = new Promise((resolve, reject) => {
                resolvePromise = resolve;
                rejectPromise = reject;
            });
        }

        // tslint:disable-next-line
        if (timeoutId != null) {
            clearTimeout(timeoutId);
        }

        lastArguments = args;

        timeoutId = setTimeout(() => {
            const resolve = resolvePromise;
            const reject = rejectPromise;
            const lastArgs = lastArguments;

            lastArguments = undefined;
            timeoutId = undefined;
            existingPromise = undefined;
            resolvePromise = undefined;
            rejectPromise = undefined;

            func(...lastArgs)
                .then((...thenArgs: any[]) => {
                    resolve(...thenArgs);
                })
                .catch((...catchArgs: any[]) => {
                    reject(...catchArgs);
                });
        }, time);

        return existingPromise;
    } as any;
}
