/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig } from 'axios';

import { getRegionalBaseUri } from '@/api/regional-url-provider';
import environment, { ApiAuthTypes } from '@/environment';

import devFileTransformers from './dev-file-transformers';

let axiosConfig: AxiosRequestConfig = {
    baseURL: environment.apiBaseUrl,
};

if (environment.apiAuthMode === ApiAuthTypes.key && environment.apiKey) {
    axiosConfig = {
        ...axiosConfig,
        headers: {
            apiKey: environment.apiKey,
        },
        ...(process.env.NODE_ENV === 'development' ? devFileTransformers : {}),
    };
}

const httpClient = axios.create(axiosConfig);

httpClient.interceptors.request.use(async config => {
    config.baseURL = await getRegionalBaseUri();
    return config;
});

export default httpClient;
