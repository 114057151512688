<template>
    <div class="modal-window-footer" :class="classes">
        <slot />
    </div>
</template>

<script lang="ts">
    import { computed, defineComponent, PropType } from 'vue';

    export default defineComponent({
        name: 'ModalWindowFooter',
        props: {
            align: {
                type: String as PropType<'left' | 'right' | 'space-between'>,
                default: 'right',
            },
        },
        setup: props => ({
            classes: computed(() => [props.align]),
        }),
    });
</script>

<style lang="less" scoped>
    .modal-window-footer {
        display: flex;
        align-items: center;
        width: 100%;
        padding: @spacing-24;

        &.left {
            justify-content: flex-start;
        }

        &.right {
            justify-content: flex-end;
        }
        &.space-between {
            justify-content: space-between;
        }

        > :not(:last-child) {
            margin-right: @spacing-16;
        }
    }
</style>
