<template>
    <Popover
        closeOnContentClick
        :isTargetInline="true"
        placement="bottom-end"
        :preventOutsideClickPropagation="false"
        class="tag-popover"
        portal="popover-options"
        popoverTestName="tag-actions"
        @show="onShow"
        @hide="onHide"
    >
        <template #target="{ toggle }">
            <RawTag
                v-bind="$props"
                :isActive="isOpenOrActive"
                class="dropdown-tag"
                :class="{ active: isOpenOrActive }"
                @click="$emit('click')"
            >
                <template #right-icon>
                    <div class="dropdown-trigger" data-testid="tag-actions-button" @click.stop="toggle">
                        <elm-menu-action-icon-icon :size="iconSize" />
                    </div>
                </template>
            </RawTag>
        </template>

        <template #content>
            <BasicDropdownContainer class="content">
                <slot />
            </BasicDropdownContainer>
        </template>
    </Popover>
</template>
<script lang="ts">
    import { computed, defineComponent, PropType, ref } from 'vue';

    import BasicDropdownContainer from '@/ui-kit/dropdown/containers/BasicDropdownContainer.vue';
    import Popover from '@/ui-kit/popover/Popover.vue';

    import RawTag from './RawTag.vue';

    export default defineComponent({
        components: {
            RawTag,
            BasicDropdownContainer,
            Popover,
        },
        props: {
            title: {
                type: String,
                required: true,
            },
            variant: {
                type: String as PropType<'topic' | 'category'>,
                default: 'topic',
            },
            size: {
                type: String as PropType<'small' | 'large'>,
                default: 'small',
            },
            isActive: {
                type: Boolean,
                default: false,
            },
            action: {
                type: String as PropType<'delete' | 'more'>,
                default: undefined,
            },
            intention: {
                type: String,
                default: undefined,
            },
            offset: {
                type: Number,
                default: 8,
            },
        },
        setup(props) {
            const optionsVisible = ref(false);
            const isDropdownOpen = ref(false);

            const onShow = () => {
                isDropdownOpen.value = true;
            };

            const onHide = () => {
                isDropdownOpen.value = false;
            };

            const isContentVisible = () => {
                return optionsVisible.value || isDropdownOpen.value;
            };

            const showOptionsIcon = (value: boolean) => {
                optionsVisible.value = value;
            };

            const iconSize = computed(() => {
                if (props.size === 'large') {
                    return 20;
                }
                return 10;
            });

            const isOpenOrActive = computed(() => {
                return props.isActive || isDropdownOpen.value;
            });

            return {
                optionsVisible,
                isDropdownOpen,
                onShow,
                onHide,
                isContentVisible,
                showOptionsIcon,
                iconSize,
                isOpenOrActive,
            };
        },
    });
</script>

<style lang="less" scoped>
    @min-tag-width: 90px;
    @gradient-offset-start: 28px;
    @gradient-offset-end: 18px;

    .tag-popover {
        display: inline-flex;
    }

    .dropdown-tag {
        position: relative;
        justify-content: center;
        min-width: @min-tag-width;
        overflow: hidden;
        user-select: none;

        &:hover,
        &.active {
            .dropdown-trigger {
                .animated-visible();
            }

            & ::v-deep.tag-text {
                width: 100%;
                mask-image: linear-gradient(
                    to right,
                    currentcolor 0%,
                    currentcolor calc(100% - @gradient-offset-start),
                    @transparent calc(100% - @gradient-offset-end),
                    @transparent 100%
                );
            }
        }
    }

    .content {
        min-width: 156px;
    }

    .dropdown-trigger {
        .animated-hidden();

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        height: 100%;
        padding: @spacing-16 @spacing-16 @spacing-16 0;

        .small & {
            padding: @spacing-12 @spacing-12 @spacing-12 0;
        }
    }
</style>
