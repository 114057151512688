import Vue from 'vue';

import { useDates } from '@/common/composables';

// Remove once all components that use it moved to composition API

export default Vue.extend({
    setup() {
        return {
            ...useDates(),
        };
    },
});
