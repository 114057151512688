import { DataRequestOptions } from '@/common/components/table/v2/interfaces';
import { FluentFilterCollection, Operator } from '@/common/services';

import { authorizeClient } from '@/api/authorize-client';

import { UserGroupsApiFactory, UserGroupRequest } from '@eloomi/eloomi-user-groups-persona-client/1.0';

const userGroupsClient = authorizeClient(UserGroupsApiFactory);

export function getGroups(
    options: DataRequestOptions,
    groups?: { mode: 'inclusive' | 'exclusive'; selectedIds: (number | string)[] }
) {
    let filterStr: string | undefined;

    if (options.searchQuery || groups?.mode) {
        const filters = new FluentFilterCollection();

        if (options.searchQuery) {
            filters.where('Name').filter(Operator.Contains, options.searchQuery);
        }

        if (groups?.mode) {
            if (groups.mode === 'inclusive') {
                filters.where('id').filter(Operator.IsEqualTo, groups?.selectedIds.join('|'));
            } else {
                groups.selectedIds.forEach(id => {
                    filters.where('id').filter(Operator.IsNotEqualTo, id);
                });
            }
        }

        filterStr = filters.build();
    }

    return userGroupsClient
        .getUserGroups(filterStr, undefined, options.page, options.pageSize)
        .then(({ data }) => data);
}

export function getGroup(groupId: number) {
    return userGroupsClient.getUserGroup(groupId).then(({ data }) => data);
}

export function createUpdateGroup(group: UserGroupRequest) {
    return userGroupsClient.createOrUpdateUserGroup(group).then(({ data }) => data);
}
