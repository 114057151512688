<template>
    <div
        class="condition-field-wrapper"
        :class="{ active: isActive, 'with-deletion': withDeletion, 'with-click': hasClickHandler }"
        @click="handleClick"
    >
        <div class="condition-pointer">
            <span class="condition-statement">
                {{ statement }}
            </span>
        </div>
        <div class="conditions-description">
            <span v-if="isEmpty" class="conditions-placeholder">
                {{ placeholder }}
            </span>
            <span v-if="firstOperand" :title="firstOperand" class="condition-first-operand">
                {{ firstOperand }}
            </span>
            <span v-if="operator" class="condition-operator">
                {{ operator }}
            </span>
            <span v-if="secondOperand" :title="secondOperand" class="condition-second-operand">
                {{ secondOperand }}
            </span>
        </div>
        <button v-if="withDeletion" class="delete-condition" @click.stop="handleDeleteConditionClick">
            <elm-actions-delete-remove-icon class="icon remove-icon" size="18" />
        </button>
    </div>
</template>

<script lang="ts">
    import '@eloomi/icons/actions/actions-delete-remove';

    import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

    @Component({})
    export default class ConditionField extends Vue {
        @Prop({ default: '' }) public statement!: string;
        @Prop({ default: '' }) public operator!: string;
        @Prop({ default: '' }) public firstOperand!: string;
        @Prop({ default: '' }) public secondOperand!: string;
        @Prop({ default: '' }) public placeholder!: string;
        @Prop({ default: false, type: Boolean }) public withDeletion!: boolean;
        @Prop({ default: false, type: Boolean }) public isActive!: boolean;

        public get isEmpty() {
            return !this.firstOperand && !this.operator && !this.secondOperand;
        }

        @Emit('delete')
        public handleDeleteConditionClick() {
            return;
        }

        public get hasClickHandler() {
            return this.$listeners.click !== undefined;
        }

        @Emit('click')
        public handleClick() {
            return;
        }
    }
</script>

<style lang="less" scoped>
    .condition-field-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        height: 56px;
        padding: 0 @spacing-16 0 @spacing-8;
        background: @info-color-8;
        border-radius: @border-radius-8;
        transition: @generic-transition;
        user-select: none;

        &.active {
            background: @info-color-20;
        }

        &.with-deletion {
            padding-right: 0;
        }

        &.with-click {
            cursor: pointer;

            &:hover {
                background: @info-color-20;
            }
        }

        &:hover .delete-condition {
            visibility: visible;
            opacity: 1;
            transition: @generic-transition;
        }
    }

    .condition-pointer {
        display: flex;
        flex-shrink: 0;
        min-width: 70px;
        height: 40px;
        margin-right: @spacing-16;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        .effects-box-shadow;
    }

    .condition-statement {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: @spacing-11 @spacing-16 @spacing-8 @spacing-12;
        color: @info-color;
        font-size: 15px;
        font-style: normal;
        line-height: 24px;
        letter-spacing: 0;
        background: @bright-color;
        border-radius: @border-radius-4;
        clip-path: polygon(0% 0%, calc(100% - 10px) 0%, 100% 50%, calc(100% - 10px) 100%, 0% 100%);
    }

    .condition-statement,
    .condition-first-operand,
    .condition-second-operand {
        font-weight: 600;
    }

    .conditions-placeholder,
    .condition-first-operand,
    .condition-operator,
    .condition-second-operand {
        padding-top: @spacing-4;
        font-size: 17px;
        line-height: 28px;
        letter-spacing: 0;
    }

    .conditions-description {
        display: flex;
        flex-shrink: 1;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        color: @info-color;
    }

    .condition-operator {
        margin-right: @spacing-5;
        margin-left: @spacing-5;
        white-space: nowrap;
        text-transform: lowercase;
    }

    .condition-first-operand,
    .condition-second-operand {
        flex-shrink: 1;
        overflow: hidden;
        color: @dark-color;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .delete-condition {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        padding: @spacing-12 @spacing-16 @spacing-12 @spacing-12;
        background: none;
        border: none;
        outline: none;
        visibility: hidden;
        opacity: 0;
        transition: @generic-transition;

        &:hover {
            cursor: pointer;
        }

        &:hover .icon {
            color: @danger-color;
        }
    }

    .remove-icon {
        color: @info-color;
        .generic-transition(color);
    }
</style>
