import { computed } from 'vue';

import useTranslation from '@/common/composables/use-translation';
import { DateInAnyForm, getDate } from '@/common/services';

export function useWeekdays() {
    const { currentLanguage } = useTranslation();

    // Returns whether week starts on Monday according to current locale
    const weekStartsOnMonday = computed(() => {
        const locale = new Intl.Locale(currentLanguage.value) as any;
        if (locale.weekInfo?.firstDay) {
            return locale.weekInfo.firstDay === 1;
        } else {
            return true;
        }
    });

    // Returns 7 dates for each day of week, which date is on
    const getWeek = (date?: DateInAnyForm) => {
        const refinedDate = getDate(date) ?? new Date();
        const year = refinedDate.getFullYear(),
            month = refinedDate.getMonth(),
            day = refinedDate.getDate();
        const weekDay = refinedDate.getDay();
        let offset = 0;
        if (weekStartsOnMonday.value) {
            offset = weekDay === 0 ? -6 : 1;
        }
        return [...Array.from({ length: 7 }).keys()].map(i => new Date(year, month, day - weekDay + i + offset));
    };

    // Returns 7 dates for each day of current week
    const currentWeek = computed(() => getWeek());

    // Get week day name - Monday, Tuesday, etc
    const getWeekDayName = (date: DateInAnyForm) => {
        const refinedDate = getDate(date);
        return refinedDate ? refinedDate.toLocaleDateString(currentLanguage.value, { weekday: 'long' }) : null;
    };

    // Get week day name short - Mon, Tue, etc
    const getWeekDayNameShort = (date: DateInAnyForm) => {
        const refinedDate = getDate(date);
        return refinedDate ? refinedDate.toLocaleDateString(currentLanguage.value, { weekday: 'short' }) : null;
    };

    // Array of week day names: ['Monday', 'Tuesday', etc ]
    const weekDaysNames = computed(() => currentWeek.value.map(getWeekDayName));

    // Array of short week day names: ['Mon', 'Tue', etc ]
    const weekDaysNamesShort = computed(() => currentWeek.value.map(getWeekDayNameShort));

    // Array of week indexes [1, 2, 3, 4, 5, 6, 0] or [0, 1, 2, 3, 4, 5, 6] if week starts with Sunday
    const weekDaysIndexes = computed(() => currentWeek.value.map(date => date.getDay()));

    // Get week day name by index - 0 -> 'Sunday', 1 -> 'Monday', etc
    const getWeekDayNameByIndex = (index: number) => weekDaysNames.value[weekDaysIndexes.value.indexOf(index)] || '';

    // Get date's week day number in month. If date is, for example, wednesday,
    // then it returns 0 if it's first wednesday this month, 1 - if it's second wednesday, etc
    const getWeekDayNumber = (date?: DateInAnyForm) => {
        const refinedDate = getDate(date) ?? new Date();
        return Math.floor((refinedDate.getDate() - 1) / 7);
    };

    return {
        currentWeek,
        getWeekDayName,
        getWeekDayNameByIndex,
        getWeekDayNameShort,
        getWeekDayNumber,
        weekDaysIndexes,
        weekDaysNames,
        weekDaysNamesShort,
    };
}
