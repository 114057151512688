import { Mutations } from 'vuex-smart-module';

import { ToastNotificatonState } from './state';
import { NotificationItem } from '../notification.interface';

type NotificationPayload = Omit<NotificationItem, 'id'>;

export class ToastNotificationMutations extends Mutations<ToastNotificatonState> {
    private incrementCounter = () => {
        this.state.counter = (this.state.counter + 1) % 10000;
        // tslint:disable-next-line: semicolon
    };

    public pushNotification(payload: NotificationPayload) {
        this.incrementCounter();
        const notification: NotificationItem = {
            id: this.state.counter,
            ...payload,
        };
        this.state.notifications.push(notification);
    }

    public removeNotification(itemId: number) {
        const itemIndex = this.state.notifications.findIndex(({ id }) => id === itemId);
        this.state.notifications.splice(itemIndex, 1);
    }

    public clearAllNotifications() {
        this.state.notifications = [];
    }
}
