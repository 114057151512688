import { RouteConfig } from 'vue-router';
import { mobileIsUnsupportedFlag } from '@/common/services/mobile-fallback';
import { Permissions } from '@/permissions';

const groupsAdministrationPage = () => import('./pages/GroupsAdministration.vue');
const createEditGroupPage = () => import('./pages/CreateEditGroupPage.vue');

export const enum AdminGroupsRoutes {
    GROUPS = 'organization-groups',
    GROUP_CREATE = 'group-create',
    GROUP_EDIT = 'group-edit',
}

const routes: RouteConfig[] = [
    {
        path: 'groups',
        name: AdminGroupsRoutes.GROUPS,
        meta: {
            title: 'Groups',
            ...mobileIsUnsupportedFlag,
            showNavigationSidebar: true,
            requiredPermissions: [Permissions.AdminGroups],
        },
        component: groupsAdministrationPage,
    },
    {
        path: 'groups/create',
        name: AdminGroupsRoutes.GROUP_CREATE,
        meta: {
            title: 'Groups',
            ...mobileIsUnsupportedFlag,
            defaultBackRoute: AdminGroupsRoutes.GROUPS,
            requiredPermissions: [Permissions.AdminGroups],
        },
        component: createEditGroupPage,
    },
    {
        path: 'groups/:id(\\d+)/edit',
        name: AdminGroupsRoutes.GROUP_EDIT,
        meta: {
            title: 'Groups',
            ...mobileIsUnsupportedFlag,
            defaultBackRoute: AdminGroupsRoutes.GROUPS,
            requiredPermissions: [Permissions.AdminGroups],
        },
        component: createEditGroupPage,
    },
];

export default routes;
