import { type PlatformResponse, PlatformApiFactory } from '@eloomi/eloomi-platforms-external-client/1.0';
import { injectable } from 'tsyringe';

import { initClientWithoutAuthDefaultRegion } from '@/api/authorize-client';
import { PlatformDeactivatedError } from '@/authentication/services/errors';
import type { PlatformResolver, ThirdPartyServiceType } from '@/authentication/services/interfaces';
import { isAndroid, isIos, isWeb } from '@/common/services/get-device-platform';
import environment from '@/environment';

const PlatformApiClient = initClientWithoutAuthDefaultRegion(PlatformApiFactory);

export class PlatformResolutionError extends Error {
    constructor(domain: string) {
        super(`Couldn't resolve a platform for domain "${domain}"`);
        Object.setPrototypeOf(this, PlatformResolutionError.prototype);
    }
}

class PlatformDomainStorage {
    private readonly STORAGE_KEY = 'platformDomain';

    public clean(): void {
        localStorage.removeItem(this.STORAGE_KEY);
    }

    public load() {
        return localStorage.getItem(this.STORAGE_KEY);
    }

    public save(domain: string) {
        localStorage.setItem(this.STORAGE_KEY, domain);
    }
}

type PlatformData = Required<PlatformResponse>;
@injectable()
export class PlatformResolutionService implements PlatformResolver {
    private platformData?: PlatformData;
    private activePromise?: Promise<PlatformData>;
    private allowSsoAutomaticLogin = true;
    private readonly CACHE_KEY = 'platformData_v2';
    private readonly platformParentDomain = environment.platformParentDomain;
    private readonly platformDomainStorage = new PlatformDomainStorage();

    private cachePlatformData(data: PlatformData) {
        const stringified = JSON.stringify(data);
        return localStorage.setItem(this.CACHE_KEY, stringified);
    }

    private getCachedPlatformData() {
        const cachedData = localStorage.getItem(this.CACHE_KEY);
        if (cachedData !== null) {
            return JSON.parse(cachedData) as PlatformData;
        }
        return null;
    }

    public getPlatformDomain(): string {
        return this.getPlatformDomainIfSetForced() ?? (environment.platformDomain || location.hostname);
    }

    private getPlatformDomainIfSetForced() {
        return this.platformDomainStorage.load();
    }

    public getPlatformParentDomain() {
        return this.platformParentDomain;
    }

    public async forceSetPlatformDomain(subDomain: string) {
        const parentDomain = this.getPlatformParentDomain();
        const platformDomain = subDomain.endsWith(parentDomain) ? subDomain : `${subDomain}${parentDomain}`;
        // Validate provided domain
        await this.resolveDomain(platformDomain);
        this.platformDomainStorage.save(platformDomain);
    }

    public cleanStoredPlatformDomain() {
        this.platformDomainStorage.clean();
    }

    private async resolveDomain(domain: string) {
        return PlatformApiClient.resolveDomain(domain).then(response => {
            if (response.status !== 200) {
                throw new PlatformResolutionError(domain);
            }

            const data = response.data as PlatformData;

            if (!data.active) {
                throw new PlatformDeactivatedError(domain);
            }

            return data;
        });
    }

    public async getPlatformData(ignoreCache = false) {
        if (this.activePromise !== undefined && this.platformData === undefined) {
            return this.activePromise;
        }

        if (!ignoreCache) {
            if (this.platformData !== undefined) {
                return this.platformData;
            }

            const cachedData = this.getCachedPlatformData();
            if (cachedData !== null) {
                this.platformData = cachedData;
                return cachedData;
            }
        }

        this.activePromise = this.resolveDomain(this.getPlatformDomain());
        this.platformData = await this.activePromise;
        this.cachePlatformData(this.platformData);

        return this.platformData;
    }

    public getLocalPlatformData(): PlatformData | null {
        return this.platformData ?? this.getCachedPlatformData();
    }

    public async resolvePlatform() {
        try {
            this.platformData = await this.resolveDomain(this.getPlatformDomain());
            this.cachePlatformData(this.platformData);

            return { isPlatformFound: true, isPlatformActive: true };
        } catch (error) {
            if (error instanceof PlatformDeactivatedError) {
                return { isPlatformFound: true, isPlatformActive: false };
            }
            return { isPlatformFound: false, isPlatformActive: false };
        }
    }

    /**
     * Which platforms will show the Login with SSO button.
     */
    private isDevicePlatformAllowedForSso() {
        return isWeb() || isAndroid() || isIos();
    }

    public isSsoLoginEnabled(): boolean {
        const data = this.getLocalPlatformData();
        return Boolean(data?.sso_enabled) && this.isDevicePlatformAllowedForSso();
    }

    public isSsoOnlyLoginMode(): boolean {
        const data = this.getLocalPlatformData();
        return Boolean(data?.sso_enabled && data?.sso_only_login) && this.isDevicePlatformAllowedForSso();
    }

    public isSsoAutomaticLoginEnabled(): boolean {
        return this.isSsoOnlyLoginMode() && this.allowSsoAutomaticLogin;
    }

    public cleanCache(ssoLogin?: boolean) {
        localStorage.removeItem(this.CACHE_KEY);
        this.cleanStoredPlatformDomain();
        this.forceSsoAutomaticLoginEnabled(ssoLogin ?? false);
    }

    public forceSsoAutomaticLoginEnabled(enable: boolean) {
        if (!this.isSsoOnlyLoginMode()) {
            return;
        }

        this.allowSsoAutomaticLogin = Boolean(enable);
    }

    public isThirdPartyServiceIntegrationEnabled(serviceType: ThirdPartyServiceType): boolean {
        const data = this.getLocalPlatformData();
        return data?.third_party_services ? Boolean(data.third_party_services[serviceType]) : false;
    }
}
