import { TopicApiFactory } from '@eloomi/eloomi-topics-persona-client/1.0';

import { Topic } from '@/admin/topics/models/topic';
import { authorizeClient } from '@/api/authorize-client';

const topicApiClient = authorizeClient(TopicApiFactory);

export function createEditTopic(topic: Topic) {
    return topicApiClient.createOrUpdate(topic);
}
