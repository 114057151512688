/* eslint-disable vue/one-component-per-file */
import { Vue } from 'vue-property-decorator';
import { Actions, Context, createComposable, createMapper, Getters, Module, Mutations } from 'vuex-smart-module';

import { createUIStoreServiceFactory } from '@/di/store-factory';
import { defaultDictionary, defaultLanguage, Dictionary, Language, languages, TranslationKey } from '@/translations';
import { translateKey, TranslationVariables } from '@/translations/store/translate-key';

export type TransFunction = (key: TranslationKey, variables?: TranslationVariables) => string;

class State {
    public dictionaries: { [key in Language]?: Dictionary } = {
        [defaultLanguage]: defaultDictionary,
    };
    public currentLanguage: Language = defaultLanguage;
    public lastRequestedLanguage?: Language;
    public loaders: { [key in Language]?: Promise<void> } = {};
}

class StoreGetters extends Getters<State> {
    public get languages() {
        return languages;
    }

    public get currentDictionary(): Dictionary {
        return this.state.dictionaries[this.state.currentLanguage]!;
    }

    public get fallbackLanguage() {
        return defaultLanguage;
    }

    public get fallbackDictionary() {
        return this.state.dictionaries[this.getters.fallbackLanguage]!;
    }

    public trans(key: TranslationKey, variables?: TranslationVariables) {
        return translateKey({
            key,
            locale: this.state.currentLanguage,
            dictionary: this.getters.currentDictionary,
            variables,
            fallbackLocale: this.getters.fallbackLanguage,
            fallbackDictionary: this.getters.fallbackDictionary,
        });
    }
}

export class StoreMutations extends Mutations<State> {
    public addDictionary({ language, dictionary }: { dictionary: Dictionary; language: Language }) {
        Vue.set(this.state.dictionaries, language, Object.freeze(dictionary));
    }

    public setLoader({ language, loader }: { language: Language; loader?: Promise<void> }) {
        if (loader) {
            this.state.loaders[language] = loader;
        } else {
            delete this.state.loaders[language];
        }
    }

    public setLanguage(language: Language) {
        this.state.currentLanguage = language;
    }

    public setLastRequestedLanguage(language: Language) {
        this.state.lastRequestedLanguage = language;
    }
}

export class StoreActions extends Actions<State, StoreGetters, StoreMutations, StoreActions> {
    public async setLanguage(language: Language) {
        if (!this.getters.languages.includes(language)) {
            return;
        }

        this.mutations.setLastRequestedLanguage(language);

        if (language === this.state.currentLanguage) {
            return;
        }

        if (this.state.dictionaries[language]) {
            this.mutations.setLanguage(language);
            return;
        }

        if (this.state.loaders[language]) {
            await this.state.loaders[language];
        } else {
            const loader = (async () => {
                const module = await import(/* webpackMode: "lazy" */ `@/translations/modules/${language}.ts`);
                this.mutations.setLoader({ language });
                this.mutations.addDictionary({ language, dictionary: module.default });
            })();
            this.mutations.setLoader({ language, loader });
            await loader;
        }

        if (this.state.lastRequestedLanguage === language) {
            this.mutations.setLanguage(language);
        }
    }
}

const translationsStore = new Module({
    state: State,
    getters: StoreGetters,
    mutations: StoreMutations,
    actions: StoreActions,
});

export const useTranslationsStore = createComposable(translationsStore) as () => Context<typeof translationsStore>;

export default translationsStore;
export const translationsStoreMapper = createMapper(translationsStore);
export const TranslationsStoreMixin = Vue.extend({
    computed: {
        ...translationsStoreMapper.mapState(['currentLanguage']),
        ...translationsStoreMapper.mapGetters(['currentDictionary', 'fallbackDictionary']),
    },
    methods: translationsStoreMapper.mapActions(['setLanguage']),
});

export const TranslationMixin = Vue.extend({
    computed: {
        ...translationsStoreMapper.mapGetters(['trans']),
        ...translationsStoreMapper.mapState(['currentLanguage']),
    },
});

export const TranslationServiceFactory = createUIStoreServiceFactory(translationsStoreMapper.mapGetters(['trans']));

export type TranslationService = ReturnType<typeof TranslationServiceFactory>;
