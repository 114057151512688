import { inject, injectable } from 'tsyringe';

import { AuthenticationServiceTokens } from '@/authentication/di-tokens';
import type { VueRouterRedirectionService } from '@/authentication/services/redirection';
import type { ToastNotificationService } from '@/common/components/toast/store';
import { makeLogger } from '@/common/services';
import { UserDetailsStoreService } from '@/common/store/user-details/store';
import { ServiceTokens } from '@/di/tokens';
import { TranslationService } from '@/translations/store';

import { ImpersonationServiceTokens as Tokens } from '../di-tokens';
import type { ImpersonationService, SessionContainer } from '../interfaces';
import { ImpersonationStoreService } from '../store';

@injectable()
export class StopImpersonationSessionUseCase {
    private readonly log = makeLogger('stop-impersonation');
    public constructor(
        @inject(Tokens.UserImpersonationService)
        private readonly impersonationService: ImpersonationService<unknown>,

        @inject(AuthenticationServiceTokens.SessionContainer)
        private readonly sessionContainer: SessionContainer<unknown>,

        @inject(ServiceTokens.UserDetailsStoreService)
        private readonly userDetails: UserDetailsStoreService,

        @inject(ServiceTokens.ToastNotificationService)
        private readonly toastNotifications: ToastNotificationService,

        @inject(ServiceTokens.TranslationService)
        private readonly transService: TranslationService,

        @inject(AuthenticationServiceTokens.RedirectService)
        private readonly redirectService: VueRouterRedirectionService,

        @inject(Tokens.ImpersonationStoreService)
        private readonly impersonationStore: ImpersonationStoreService
    ) {}

    public async execute(options?: { withRemoteStop: boolean }) {
        const { withRemoteStop } = { withRemoteStop: true, ...options };
        try {
            if (withRemoteStop) {
                await this.impersonationService.stopImpersonationSession();
            }
        } catch {
            this.log('Could not stop impersonation session on the backend');
        }
        this.sessionContainer.stopImpersonationSession();

        const waitTime = 1000;

        await this.userDetails.forceReloadUserProfile();
        this.impersonationStore.setImpersonationState(false);

        this.toastNotifications.showNotification({
            theme: 'success',
            message: this.transService.trans()('spa.admin.impersonation.toast-stop-success'),
            duration: waitTime,
        });

        setTimeout(() => this.redirectService.redirectToDefaultPage(true), waitTime);
    }
}
