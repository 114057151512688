<template>
    <div
        id="eloomi-main-layout"
        class="core-main-layout"
        :class="{
            'has-left-sidebar': canShowLeftSidebar,
            'left-sidebar-is-compact': canShowLeftSidebar && leftSidebarIsCompact,
        }"
    >
        <div class="sticky-header">
            <portal-target name="sticky-header" />
            <slot name="sticky-header" />
        </div>
        <slot name="header"></slot>

        <div class="content-slot">
            <div
                v-if="$scopedSlots['left-sidebar']"
                v-show="hasLeftSidebar"
                class="left-sidebar-slot"
                @mouseover="onSidebarMouseOver"
                @mouseleave="onSidebarMouseLeave"
            >
                <slot name="left-sidebar" :isSidebarExpanded="isSidebarExpanded"></slot>
            </div>
            <slot name="content"></slot>
        </div>

        <slot name="overlay"></slot>
    </div>
</template>

<script lang="ts">
    import { computed, defineComponent, onMounted, onUnmounted, ref } from 'vue';

    import { SidebarCollapseBreakpoint } from '@/common/constants/breakpoints';
    import { getMaxWidthMedia } from '@/common/services';

    export default defineComponent({
        name: 'CoreMainLayout',
        props: {
            hasLeftSidebar: { type: Boolean, default: false },
            leftSidebarIsCompact: { type: Boolean, default: false },
        },
        setup(props, { slots }) {
            const canBeCollapsed = ref(false);
            const canShowLeftSidebar = computed(() => slots['left-sidebar'] && props.hasLeftSidebar);

            const mql = window.matchMedia(getMaxWidthMedia(SidebarCollapseBreakpoint));

            const handleMQLChanged = () => {
                canBeCollapsed.value = mql.matches;
            };

            onMounted(() => {
                handleMQLChanged();
                if (mql.addEventListener) {
                    mql.addEventListener('change', handleMQLChanged);
                }
            });
            onUnmounted(() => {
                if (mql.removeEventListener) {
                    mql.removeEventListener('change', handleMQLChanged);
                }
            });

            const isMouseOver = ref(false);

            const onSidebarMouseOver = () => {
                isMouseOver.value = true;
            };

            const onSidebarMouseLeave = () => {
                isMouseOver.value = false;
            };

            const isSidebarExpanded = computed(() => {
                const isSidebarCollapsed = canBeCollapsed.value || props.leftSidebarIsCompact;
                return (isSidebarCollapsed && isMouseOver.value) || !isSidebarCollapsed;
            });

            return { onSidebarMouseOver, onSidebarMouseLeave, isSidebarExpanded, canShowLeftSidebar };
        },
    });
</script>

<style lang="less" scoped>
    .expanded-sidebar-style() {
        width: var(--left-sidebar-width);

        &::before {
            .animated-visible();
        }
    }

    .expanded-sidebar-style(withShadow) {
        .expanded-sidebar-style();

        .effects-box-shadow;
    }

    .core-main-layout {
        @sidebar-default-width: @spacing-96 * 4;

        --left-sidebar-width: @sidebar-default-width;
        --left-sidebar-collapsed-width: 120px;

        display: flex;
        flex: 1;
        flex-direction: column;
        width: 100%;
        max-width: 100%;

        &.has-left-sidebar {
            .content-slot {
                margin-left: var(--left-sidebar-collapsed-width);
            }
        }

        &.has-left-sidebar:not(.left-sidebar-is-compact) {
            .content-slot {
                .xl-viewport-and-larger({
                    margin-left: var(--left-sidebar-width);
                }, 200);
            }
        }

        .left-sidebar-slot {
            position: fixed;
            left: 0;
            z-index: 2;
            width: var(--left-sidebar-collapsed-width);
            height: calc(100% - @core-header-height);
            overflow-y: auto;
            background-color: @bright-color;
            .generic-transition(~'width, box-shadow');
        }

        &:not(.left-sidebar-is-compact) {
            .left-sidebar-slot {
                .lg-viewport-and-smaller({
                    &:hover {
                        .expanded-sidebar-style(withShadow);
                    }
                }, 200);

                .xl-viewport-and-larger({
                    .expanded-sidebar-style();
                }, 200);
            }
        }

        &.left-sidebar-is-compact .left-sidebar-slot:hover {
            .expanded-sidebar-style(withShadow);
        }

        .content-slot {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            .generic-transition(margin);
        }
    }
</style>
