import { Capacitor } from '@capacitor/core';

/**
 * Assign Capacitor platform data attribute to body tag.
 *
 * Possible cases are:
 *   - data-platform="ios"
 *   - data-platform="android"
 *   - data-platform="web"
 */
export function assignCapacitorPlatformDataAttribute() {
    document.body.dataset.platform = Capacitor.getPlatform();
}
