import { render, staticRenderFns } from "./Tag.vue?vue&type=template&id=0ef80e33&scoped=true&"
import script from "./Tag.vue?vue&type=script&lang=ts&"
export * from "./Tag.vue?vue&type=script&lang=ts&"
import style0 from "./Tag.vue?vue&type=style&index=0&id=0ef80e33&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ef80e33",
  null
  
)

export default component.exports