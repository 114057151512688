import { Actions } from 'vuex-smart-module';

import { NotificationItem } from '../notification.interface';

import { ToastNotificationGetters } from './getters';
import { ToastNotificationMutations } from './mutations';
import { ToastNotificatonState } from './state';

type NotificationPayload = Omit<NotificationItem, 'id'>;

export interface OptionalNotificationPayload extends Partial<NotificationPayload> {
    message: string;
    waitForPrevious?: boolean;
}

type PromiseReject = (reason?: any) => void;
class NotificationHandle {
    public constructor(private readonly reject: PromiseReject, private readonly timer: number) {}
    public cancel() {
        this.reject();
        clearTimeout(this.timer);
    }
}
let lastNotificationPromise: Promise<number | void> | null = null;
let activeNotificationHandle: NotificationHandle | null = null;

const rejectStub = () => Promise.resolve(0);
const ANIMATION_DURATION = 500;

// tslint:disable-next-line: max-classes-per-file
export class ToastNotificationActions extends Actions<
    ToastNotificatonState,
    ToastNotificationGetters,
    ToastNotificationMutations,
    ToastNotificationActions
> {
    private _pushNotification = (payload: NotificationPayload): Promise<number | void> => {
        this.mutations.pushNotification(payload);
        const notificationId = this.state.counter;

        return new Promise((resolve, reject) => {
            const timer = window.setTimeout(() => {
                this.mutations.removeNotification(notificationId);
                resolve(notificationId);
            }, payload.duration + ANIMATION_DURATION);

            activeNotificationHandle = new NotificationHandle(reject, timer);
        });
        // tslint:disable-next-line: semicolon
    };

    public async showNotification(payload: OptionalNotificationPayload) {
        const actualPayload = {
            theme: payload.theme ?? 'info',
            isCompact: payload.isCompact ?? false,
            duration: payload.duration ?? 1000,
            message: payload.message,
            waitForPrevious: payload.waitForPrevious ?? false,
        };

        if (actualPayload.waitForPrevious && lastNotificationPromise !== null) {
            lastNotificationPromise = lastNotificationPromise.then(() => this._pushNotification(actualPayload));

            return lastNotificationPromise.catch(rejectStub);
        }

        lastNotificationPromise = this._pushNotification(actualPayload);
        return lastNotificationPromise.catch(rejectStub);
    }

    public async showDelayedNotification(payload: OptionalNotificationPayload, delay = 300) {
        return setTimeout(() => this.dispatch('showNotification', payload), delay);
    }

    public clearNotifications() {
        activeNotificationHandle?.cancel();
        lastNotificationPromise = null;
        this.mutations.clearAllNotifications();
    }
}
