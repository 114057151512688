enum PlanFeaturesList {
    QUIZZES = 'quizzes',
    API = 'api',
    BILLING = 'billing',
    BRANDING = 'branding',
    CATEGORIES_TOPICS = 'categories-topics',
    COURSES = 'courses',
    CUSTOM_FIELDS = 'custom-fields',
    DEADLINES = 'deadlines',
    EVENTS = 'events',
    GROUPS = 'groups',
    MANAGERS = 'managers',
    NATIVE_INTEGRATION = 'native-integration',
    OPEN_SESAME = 'open-sesame',
    PLAYLISTS = 'playlists',
    REPORTING = 'reporting',
    SCORM = 'scorm',
    SEGMENT_REPORTING = 'segment-reporting',
    SKILLS = 'skills',
    SSO = 'sso',
    USER_PERMISSIONS = 'user-permissions',
    CERTIFICATIONS = 'certifications',
}

export default PlanFeaturesList;
