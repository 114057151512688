const f = (val: number) => (Math.floor(val / 10) > 0 ? `${val}` : `0${val}`);

export function formatDuration(totalSeconds: number) {
    const withoutSeconds = formatDurationWithoutSeconds(totalSeconds);
    const seconds = totalSeconds % 60;

    return `${withoutSeconds}:${f(seconds)}`;
}

export function formatDurationWithoutSeconds(totalSeconds: number) {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds - hours * 3600) / 60);

    return `${f(hours)}:${f(minutes)}`;
}

export function addZeroesToTime(time: number | string) {
    return ('0' + time).slice(-2);
}

export function extractMinutes(time: number): number | null {
    return (time && Math.floor((time % 3600) / 60)) || null;
}

export function extractHours(time: number): number | null {
    return (time && Math.floor(time / 3600)) || null;
}
/**
 * Returns formated time
 * @param time - time in seconds like: 12h 23m
 * @param config - takes arguments for translation
 */
export function formatTime(
    time: number,
    config?: { week?: string; day?: string; hour?: string; minute?: string; second?: string }
) {
    const second = 1;
    const minute = second * 60;
    const hour = minute * 60;
    const day = hour * 24;
    const week = day * 7;

    const formattedWeeks = Math.floor(time / week);
    const formattedDays = Math.floor((time % week) / day);
    const formattedHours = Math.floor(((time % week) % day) / hour);
    const formattedMinutes = Math.floor((((time % week) % day) % hour) / minute);
    const formattedSeconds = Math.floor(((((time % week) % day) % hour) % minute) / second);

    if (!formattedWeeks && !formattedDays && !formattedHours && !formattedMinutes && !time) {
        return '—';
    }

    const w = formattedWeeks ? `${formattedWeeks}${config?.week || 'w'}` : '';
    const d = formattedDays ? `${formattedDays}${config?.day || 'd'}` : '';
    const h = formattedHours ? `${formattedHours}${config?.hour || 'h'}` : '';
    const m = formattedMinutes ? `${formattedMinutes}${config?.minute || 'm'}` : '';
    const s = time ? `${formattedSeconds}${config?.second || 's'}` : '';

    return [w, d, h, m, s]
        .filter(x => x)
        .slice(0, 2)
        .join(' ');
}
