import { MinWidthViewportBreakpoints } from '@/common/constants/breakpoints';

export const MOBILE_UNSUPPORTED_FLAG_NAME = 'mobileIsUnsupported';
export const mobileIsUnsupportedFlag = {
    [MOBILE_UNSUPPORTED_FLAG_NAME]: true,
};

export const viewportIsUnsupportedFlag = (viewportWidth: number = MinWidthViewportBreakpoints.xl) => {
    return {
        [MOBILE_UNSUPPORTED_FLAG_NAME]: true,
        viewportWidth,
    };
};
