import { RouteConfig } from 'vue-router';

import { mobileIsUnsupportedFlag } from '@/common/services/mobile-fallback';

import { AdminBillingRoutes } from '../billing/routes';
import { adminVerifyUserLimit } from './providers/create-user';

const userIndexPage = () => import('./pages/UserIndexPage.vue');
const userCreateEditPage = () => import('./pages/UserCreateEditPage.vue');
import { Permissions } from '@/permissions';

const title = 'User Administration';

export const enum AdminUsersRoutes {
    USERS = 'organization-users',
    USER_CREATE = 'organization-user_create',
    USER_EDIT = 'organization-user_edit',
}

const routes: RouteConfig[] = [
    {
        path: 'users',
        name: AdminUsersRoutes.USERS,
        meta: {
            title,
            ...mobileIsUnsupportedFlag,
            showNavigationSidebar: true,
            requiredPermissions: [Permissions.AdminUsers],
        },
        component: userIndexPage,
        props: {
            user: undefined,
            new: undefined,
        },
    },
    {
        path: 'users/create',
        name: AdminUsersRoutes.USER_CREATE,
        meta: {
            title,
            ...mobileIsUnsupportedFlag,
            defaultBackRoute: AdminUsersRoutes.USERS,
            requiredPermissions: [Permissions.AdminUsers],
        },
        beforeEnter: async (_to, _from, next) => {
            const userVerified = await adminVerifyUserLimit();
            if (userVerified.limitExceeded && userVerified.limitParams) {
                next({
                    name: AdminBillingRoutes.BILLING,
                    query: userVerified.limitParams,
                });
            } else if (userVerified.limitExceeded === false) {
                next();
            } else if (userVerified.limitExceeded === undefined) {
                console.error('Unable to get user limit verification');
                next(false);
            }
        },
        component: userCreateEditPage,
    },
    {
        path: 'users/:id(\\d+)/edit',
        name: AdminUsersRoutes.USER_EDIT,
        meta: {
            title,
            ...mobileIsUnsupportedFlag,
            defaultBackRoute: AdminUsersRoutes.USERS,
            requiredPermissions: [Permissions.AdminUsers],
        },
        component: userCreateEditPage,
    },
];

export default routes;
