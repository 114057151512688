import { RouteConfig } from 'vue-router';

import type { ActivateUserService } from '@/authentication/services/activate-user';
import { InstantLoginRedirectionService } from '@/authentication/services/instant-login-redirection';
import type { AuthRouteMeta } from '@/authentication/services/interfaces';
import { checkForMobileOrRegularLoginAttempt } from '@/authentication/services/login-sso-handler';
import type { ResetPasswordService } from '@/authentication/services/reset-password';
import { baseContainer } from '@/di/container';

import { AuthenticationServiceTokens } from './di-tokens';
import { AuthenticationRouteNames } from './route-definitions';

// @ts-ignore
const authenticationPage = () => import('./page/AuthenticationPage.vue');
// @ts-ignore
const resetPasswordPage = () => import('./page/ResetPasswordPage.vue');
// @ts-ignore
const activateUserPage = () => import('./page/ActivateUserPage.vue');

const routes: RouteConfig[] = [
    {
        name: AuthenticationRouteNames.LOGIN,
        path: '/login',
        meta: {
            title: 'Login',
            onlyUnauthorized: true,
        } as AuthRouteMeta,
        component: authenticationPage,
        beforeEnter: checkForMobileOrRegularLoginAttempt,
    },
    {
        name: AuthenticationRouteNames.RESET_PASSWORD,
        path: '/reset-password',
        meta: {
            title: 'Reset password',
        },
        component: resetPasswordPage,
        beforeEnter: (...args) => {
            // We're using it here to avoid circular reference issue
            const resetPasswordService = baseContainer.resolve<ResetPasswordService>(
                AuthenticationServiceTokens.ResetPasswordService
            );
            const checkIfCorrectResetTokenPassed = resetPasswordService.makeTokenValidatorRouteGuard();
            return checkIfCorrectResetTokenPassed(...args);
        },
    },
    {
        name: AuthenticationRouteNames.ACTIVATE_USER,
        path: '/activate-user',
        meta: {
            title: 'Setup account',
        },
        component: activateUserPage,
        beforeEnter: (...args) => {
            // We're using it here to avoid circular reference issue
            const activateUserService = baseContainer.resolve<ActivateUserService>(
                AuthenticationServiceTokens.ActivateUserService
            );
            const checkIfCorrectActivateTokenPassed = activateUserService.makeTokenValidatorRouteGuard();
            return checkIfCorrectActivateTokenPassed(...args);
        },
    },
    {
        name: AuthenticationRouteNames.INSTANT_LOGIN,
        path: '/instant-login',
        meta: {
            title: 'Login',
        },
        redirect: route => {
            const instantLoginService = new InstantLoginRedirectionService();
            return instantLoginService.redirect(route);
        },
    },
];

export default routes;
