import {
    Go1FacetType,
    Go1SubscriptionInfo,
    Go1Type,
    LearningObjectsApiFactory,
    PlanApiFactory,
    PremiumLearningObjectsApiFactory,
} from '@eloomi/eloomi-learning-persona-client/2.0';

import { authorizeClient } from '@/api/authorize-client';
import { extractAxiosData } from '@/common/services/extract-axios-data';
import { languagesMapped, Sorting } from '@/content-store/constants';

export type { Go1LearningObject, Go1Type } from '@eloomi/eloomi-learning-persona-client/2.0';

const apiClient = authorizeClient(LearningObjectsApiFactory);
const premiumApiClient = authorizeClient(PremiumLearningObjectsApiFactory);
const planApiClient = authorizeClient(PlanApiFactory);

export interface GetLearningObjectsParams {
    page?: number;
    pageSize?: number;
    durationMin?: Array<number>;
    durationMax?: Array<number>;
    type?: Go1Type[];
    topics?: string[];
    keyword?: string;
    language?: string[];
    provider?: string[];
    sort?: Sorting;
}

const DEFAULT_PARAMS: GetLearningObjectsParams = {
    page: 1,
    pageSize: 12,
};

export function getSubscriptionInfo(): Promise<Go1SubscriptionInfo> {
    return planApiClient.getGo1SubscriptionInfo().then(extractAxiosData);
}

export function getLearningObjects(isPremium: boolean | undefined, params: GetLearningObjectsParams = DEFAULT_PARAMS) {
    let maxDuration: number[] | undefined;
    let minDuration: number[] | undefined;

    if (params.durationMin && params.durationMin.length > 0) {
        minDuration = [Math.min(...params.durationMin)];
    }

    if (params.durationMax && params.durationMax.at(-1) !== undefined) {
        maxDuration = [Math.max(...params.durationMax)];
    }

    let sortBy = params.keyword ? 'relevance:desc,popularity:desc' : 'popularity:desc';
    if (params.sort) {
        sortBy = params.sort?.key + (params.sort?.ascending === false ? ':desc' : '');
    }

    const apiMethod = isPremium ? premiumApiClient.getLearningObjects : apiClient.getLearningObjects;

    const language = params.language
        ?.map(lang => {
            const info = languagesMapped.find(l => l.key === lang);
            if (info?.additionalKeys) {
                return [lang, ...info.additionalKeys];
            }
            return lang;
        })
        .flat();

    const provider = params.provider?.join(',');

    return apiMethod(
        params.page ?? 1,
        params.pageSize ?? 12,
        undefined,
        undefined,
        minDuration,
        maxDuration,
        undefined,
        undefined,
        undefined,
        [Go1FacetType.Language, Go1FacetType.Topics, Go1FacetType.Instance],
        params.keyword,
        language,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        provider,
        sortBy,
        undefined,
        undefined,
        undefined,
        params.type,
        params.topics
    ).then(extractAxiosData);
}

export type ImportLearningObjectsOptions = {
    importTopics: boolean;
    openSelfEnrollment?: boolean;
    openManagerEnrollment?: boolean;
};

export function importLearningObjects(ids, options: ImportLearningObjectsOptions) {
    return premiumApiClient
        .bulkImportLearningObjects(options.importTopics, options.openSelfEnrollment, options.openManagerEnrollment, {
            learning_object_ids: ids,
        })
        .then(extractAxiosData);
}

export function checkStatus(id: string) {
    return premiumApiClient.learningObjectsImportStatus(id).then(extractAxiosData);
}

export async function getLearningObjectDetails(isPremium: boolean | undefined, id: number) {
    const apiMethod = isPremium ? premiumApiClient.getLearningObjectDetails : apiClient.getLearningObjectDetails;
    return await apiMethod(id).then(extractAxiosData);
}

export async function importLearningObject(id: number, options: ImportLearningObjectsOptions) {
    return await premiumApiClient
        .importLearningObject(id, options.importTopics, options.openSelfEnrollment, options.openManagerEnrollment)
        .then(extractAxiosData);
}

export async function getLearningObjectPreview(isPremium: boolean | undefined, id: number) {
    const apiMethod = isPremium ? premiumApiClient.getLearningObjectPreview : apiClient.getLearningObjectPreview;
    return await apiMethod(id).then(extractAxiosData);
}

export function getLearningObjectTopics(isPremium: boolean | undefined) {
    const apiMethod = isPremium ? premiumApiClient.getLearningObjectTopics : apiClient.getLearningObjectTopics;
    return apiMethod().then(extractAxiosData);
}

export function getLearningObjectProviders(isPremium: boolean | undefined) {
    const apiMethod = isPremium ? premiumApiClient.getLearningObjectProviders : apiClient.getLearningObjectProviders;
    return apiMethod().then(extractAxiosData);
}
