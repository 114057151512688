import { UserGroupsApiFactory } from '@eloomi/eloomi-user-groups-persona-client/1.0';

import { authorizeClient } from '@/api/authorize-client';

const userGroupsApiClient = authorizeClient(UserGroupsApiFactory);
export function unassignUsers(groupID: number, userIds: number[]) {
    return userGroupsApiClient.unassignUsersFromUserGroup({
        group_id: groupID,
        user_ids: userIds,
    });
}
