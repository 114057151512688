import * as FullStory from '@fullstory/browser';

import environment from '@/environment';

class FullStoryService {
    isInitialized = false;

    init(isIntegrationEnabled: boolean) {
        if (!environment.fullstoryOrgId || !isIntegrationEnabled || this.isInitialized) return;

        FullStory.init({ orgId: environment.fullstoryOrgId });
        this.isInitialized = true;
    }
}

export const fullStory = new FullStoryService();
