export const MinWidthViewportBreakpoints = {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1224,
    xxl: 1500,
} as const;

export const SidebarCollapseBreakpoint = 1424;

export type BreakpointCode = keyof typeof MinWidthViewportBreakpoints;
