/**
 * Format decimal number to percent represenation.
 * @param value
 * @returns
 */
export function formatToPercent(value: number | null | undefined) {
    if (value === null || value === undefined) {
        return value;
    }
    return Math.round(value * 100);
}
