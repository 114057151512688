import * as FullStory from '@fullstory/browser';

import { fullStory } from '@/common/services/fullstory';

interface SnippetOptions {
    orgId: string;
    namespace?: string;
    debug?: boolean;
    host?: string;
    script?: string;
    recordCrossDomainIFrames?: boolean;
    recordOnlyThisIFrame?: boolean; // see README for details
    devMode?: boolean;
}

interface UserVars {
    displayName?: string;
    email?: string;
    [key: string]: any;
}

type LogLevel = 'log' | 'info' | 'warn' | 'error' | 'debug';

declare module 'vue/types/vue' {
    interface Vue {
        $FullStory: {
            anonymize(): void;
            consent(userConsents?: boolean): void;
            event(eventName: string, eventProperties: { [key: string]: any }): void;
            identify(uid: string, customVars?: UserVars): void;
            init(options: SnippetOptions): void;
            log(level: LogLevel, msg: string): void;
            log(msg: string): void;
            restart(): void;
            setUserVars(customVars: UserVars): void;
            shutdown(): void;
        };
    }
}

export default {
    install(Vue: any) {
        if (fullStory.isInitialized) {
            Vue.prototype.$FullStory = FullStory;
        }
    },
};
