<template>
    <DropdownButton
        v-if="!isMobile"
        ref="dropdownButtonRef"
        :title="trans('spa.admin.course-organization.menu.filters')"
        color="secondary"
        :closeOnContentClick="false"
        class="filters-dropdown"
        :isButtonActive="isFilterSet"
        :isDisabled="isDisabled"
        :isCompact="isCompact"
        positionStrategyPopover="fixed"
        :autoMaxHeightPopover="true"
        @blur="backToFilterTypesMenu"
    >
        <FilterTypeMenu
            v-if="currentMenu === 'TYPES'"
            :options="filterTypeOptions"
            :activeFiltersCount="activeFiltersCount"
            @select="handleFilterTypeSelect"
        />
        <FilterOptionsMenu
            v-else-if="!isDateFilterType"
            :options="filterOptions"
            :appliedValues="selectedValues"
            :filterType="currentMenu"
            :isSingleSelect="dropdownSettings.isSingleSelect"
            :showSelectAll="dropdownSettings.showSelectAll"
            :searchIsEnabled="dropdownSettings.searchIsEnabled"
            :withConfirmBtn="dropdownSettings.withConfirmBtn"
            @back="backToFilterTypesMenu"
            @confirm="handleFilterChangesConfirm"
        />
        <FilterDateMenu
            v-else
            :selectedDates="selectedValues"
            :datesTimeSpan="datesTimeSpan"
            :filterType="currentMenu"
            :timeWithoutPredefinedRanges="timeWithoutPredefinedRanges"
            @back="backToFilterTypesMenu"
            @date-selected="handleFilterChangesConfirm"
        />
        <template #compact-view>
            <elm-actions-add-filter-icon slot="icon" class="display-flex" />
        </template>
    </DropdownButton>
    <div v-else>
        <CoreTableIconButton
            icon="add-filter"
            :itemSize="16"
            class="icon-button"
            :color="isDrawerVisible ? 'primary' : 'info'"
            :class="{ active: isDrawerVisible }"
            @click="onTriggerClick"
        >
            <template #icon>
                <elm-actions-add-filter-icon />
            </template>
        </CoreTableIconButton>
        <CoreMobileBottomDrawer :isVisible="isDrawerVisible" :preventTouchScroll="false" noPadding @close="closeDrawer">
            <FilterTypeMenu
                v-if="currentMenu === 'TYPES'"
                class="filter-type-menu-mobile-box"
                :options="filterTypeOptions"
                :activeFiltersCount="activeFiltersCount"
                @select="handleFilterTypeSelect"
            />
            <FilterOptionsMenu
                v-else-if="!isDateFilterType"
                class="filter-options-menu-mobile"
                :options="filterOptions"
                :appliedValues="selectedValues"
                :filterType="currentMenu"
                :isSingleSelect="dropdownSettings.isSingleSelect"
                :showSelectAll="dropdownSettings.showSelectAll"
                :searchIsEnabled="dropdownSettings.searchIsEnabled"
                :withConfirmBtn="dropdownSettings.withConfirmBtn"
                @back="backToFilterTypesMenu"
                @confirm="handleFilterChangesConfirm"
            />
            <FilterDateMenu
                v-else
                :selectedDates="selectedValues"
                :datesTimeSpan="datesTimeSpan"
                :filterType="currentMenu"
                :timeWithoutPredefinedRanges="timeWithoutPredefinedRanges"
                @back="backToFilterTypesMenu"
                @date-selected="handleFilterChangesConfirm"
            />
        </CoreMobileBottomDrawer>
    </div>
</template>

<script lang="ts">
    import '@eloomi/icons/actions/actions-add-filter';

    import { defineComponent, PropType, ref } from 'vue';

    import { CalendarTimeSpanType } from '@/common/components/filter';
    import FilterDateMenu from '@/common/components/filter/FilterDateMenu.vue';
    import {
        DateFilterType,
        DropdownSettings,
        Filters,
        FilterType,
        FilterValue,
        isArrayFilterValue,
        isDateFilterValue,
        isFilterType,
        Option,
    } from '@/common/components/filter/interfaces';
    import { useFiltersProvider } from '@/common/components/filter/use-filters-provider';
    import { useLaunchDarkly, useMobile, useTranslation } from '@/common/composables';
    import { deepCopy } from '@/common/services';
    import DropdownButton from '@/ui-kit/dropdown-button/DropdownButton.vue';

    import FilterOptionsMenu from './FilterOptionsMenu.vue';
    import FilterTypeMenu, { FilterTypeOption } from './FilterTypeMenu.vue';

    type FiltersMenuType = FilterType | DateFilterType | 'TYPES';
    import CoreMobileBottomDrawer from '@/common/components/modal/CoreMobileBottomDrawer.vue';
    import CoreTableIconButton from '@/common/components/table/v2/CoreTableIconButton.vue';

    export default defineComponent({
        name: 'FiltersDropdown',
        components: {
            DropdownButton,
            FilterDateMenu,
            FilterTypeMenu,
            FilterOptionsMenu,
            CoreMobileBottomDrawer,
            CoreTableIconButton,
        },
        props: {
            isLoading: {
                type: Boolean,
                default: false,
            },
            isDisabled: {
                type: Boolean,
                default: false,
            },
            datesTimeSpan: {
                type: String as PropType<CalendarTimeSpanType>,
            },
            timeWithoutPredefinedRanges: {
                type: Boolean,
                default: false,
            },
            supportedFilters: {
                type: Array as PropType<(FilterType | DateFilterType)[]>,
                required: true,
            },
            initialFilters: {
                type: Object as PropType<Filters | null>,
                default: null,
            },
            isCompact: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['change'],

        setup() {
            const dropdownButtonRef = ref<InstanceType<typeof DropdownButton>>();

            return {
                dropdownButtonRef,
                ...useFiltersProvider(),
                ...useLaunchDarkly(),
                ...useTranslation(),
                ...useMobile(),
            };
        },

        data: () => ({
            options: {} as Record<FilterType, Option[]>,
            currentFilters: {} as Filters,
            currentMenu: 'TYPES' as FiltersMenuType,
            DateFilterType,
            FilterType,
            settings: {} as DropdownSettings,
            isDrawerVisible: false,
        }),

        computed: {
            isFilterSet() {
                return this.activeFiltersCount > 0;
            },

            dropdownSettings() {
                return this.settings[this.currentMenu];
            },

            activeFiltersCount() {
                return Object.values(this.currentFilters).filter(this.getFilterCount).length;
            },

            filterTypeOptions() {
                return this.supportedFilters.map(
                    filterType =>
                        ({
                            value: filterType,
                            label: this.trans(`spa.admin.course-organization.filters-menu.options.label.${filterType}`),
                            selectedValuesCount: this.getFilterCount(this.currentFilters[filterType]),
                        } as FilterTypeOption)
                );
            },

            filterOptions(): Array<Option> {
                return this.currentMenu === 'TYPES' ? this.filterTypeOptions : this.options[this.currentMenu];
            },

            selectedValues() {
                const menuType = this.currentMenu;
                return this.currentFilters[menuType]?.value || (menuType === DateFilterType.Date ? {} : []);
            },

            isDateFilterType() {
                return Object.values(DateFilterType).includes(this.currentMenu as DateFilterType);
            },
        },

        created() {
            this.loadAllOptions();
            if (this.initialFilters) {
                this.currentFilters = deepCopy(this.initialFilters);
                this.emitChangeEvent();
            }
        },

        methods: {
            getFilterCount(item?: FilterValue) {
                if (!item) {
                    return 0;
                }
                if (isArrayFilterValue(item)) {
                    return item.value.length;
                } else if (isDateFilterValue(item)) {
                    return item.value.start || item.value.end ? 1 : 0;
                } else {
                    return 0;
                }
            },

            onTriggerClick() {
                this.isDrawerVisible = true;
            },
            closeDrawer() {
                this.isDrawerVisible = false;
            },

            async loadAllOptions() {
                for (const type of this.supportedFilters) {
                    if (isFilterType(type)) {
                        this.getOptionsList(type)
                            .then(data => {
                                this.$set(this.options, type, data.options);
                                this.settings[type] = {
                                    isSingleSelect: data.isSingleSelect,
                                    showSelectAll: data.showSelectAll,
                                    searchIsEnabled: data.searchIsEnabled,
                                    withConfirmBtn: data.withConfirmBtn,
                                };
                            })
                            .catch();
                    }
                }
            },

            updateMenuType(menuType: FiltersMenuType) {
                this.currentMenu = menuType;

                /** Since we change content of the popover, we should refresh its position */
                this.$nextTick(() => {
                    this.dropdownButtonRef?.updatePopover();
                });
            },

            handleFilterTypeSelect(filterTypeOption: FilterTypeOption) {
                this.updateMenuType(filterTypeOption.value);
            },

            updateFilterValues(value: Array<number | string>) {
                if (this.currentMenu !== 'TYPES') {
                    this.$set(this.currentFilters, this.currentMenu, { value });
                }
            },

            backToFilterTypesMenu() {
                this.updateMenuType('TYPES');
            },

            hidePopover() {
                this.dropdownButtonRef?.hidePopover();
            },

            handleFilterChangesConfirm(selectedValues: Array<number | string>) {
                this.updateFilterValues(selectedValues);
                this.emitChangeEvent();
                this.hidePopover();
                this.backToFilterTypesMenu();
            },

            emitChangeEvent() {
                this.$emit('change', this.currentFilters);
            },
        },
    });
</script>

<style lang="less" scoped>
    .filter-type-menu-mobile-box,
    .filter-options-menu-mobile {
        width: 100%;
    }
</style>
