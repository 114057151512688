import type { DependencyContainer } from 'tsyringe';
import type { Store } from 'vuex';

import { ServiceTokens } from './tokens';

/**
 * Create a Factory that will provide access to vuex smart module in a class-based service manner.
 *
 * Actually it relies on a dirty assumption that mapXxxx smart vuex functions
 * are bound to Vue Component instance $store field (and it's true).
 */
export function createUIStoreServiceFactory<T extends {}>(
    properties: T
): {
    (dependencyContainer: DependencyContainer): T;
} {
    function makeStore(dependencyContainer: DependencyContainer) {
        const store = dependencyContainer.resolve<Store<unknown>>(ServiceTokens.BaseStore);
        return {
            $store: store,
            ...properties,
        };
    }
    return makeStore;
}
